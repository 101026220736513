import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import SCard from 'components/Standard/SCard';
import SRow from 'components/Standard/SRow';
import SCol from 'components/Standard/SCol';
import STable from 'components/Standard/STable';
import SText from 'components/Standard/SText';
import { useHistory } from 'react-router-dom';
import { isEqual, get } from 'lodash';
import { APPEALS_STATUSES, CLIENT_INTERACTIONS_TYPES_LITERALS } from 'core/utils/constants';
import moment from 'core/moment';
import UserPreview from 'components/UserPreview';
import { actions } from 'redux/lists/appellationsListReducer';
import SButton from 'components/Standard/SButton';
import { Typography } from 'antd';

const AppealsList = ({ userPageView }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();

  const { updatePage, setSorting } = actions;

  const { ids, totalCount, page, loading } = useSelector(state => state.appellationsList);

  const appeallsByIds = useSelector(state => state.appellationsResource.byIds, isEqual);
  const appeallsActionsByIds = useSelector(
    state => state.appellationActionsResource.byIds,
    isEqual
  );
  const appellationObjectionsByIds = useSelector(
    state => state.appellationObjectionsResource.byIds,
    isEqual
  );
  const questionsByIds = useSelector(state => state.questionsResource.byIds, isEqual);

  const appeals = ids.map(id => appeallsByIds[id]);

  const handleTableChange = useCallback(
    (pagination, filters, sorter) => {
      dispatch(
        updatePage({
          size: pagination.pageSize.toString(),
          number: pagination.current.toString()
        })
      );
      dispatch(setSorting(sorter));
    },
    [dispatch]
  );

  const tableColumns = [
    {
      title: t('general.applicationDate'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      ellipsis: true,
      width: 120,
      sorter: true,
      render: text => {
        return <SText>{moment(text).format('DD/MM/YYYY')}</SText>;
      }
    },

    {
      title: t('general.applicator'),
      dataIndex: 'appellantId',
      key: 'appellantId',
      ellipsis: true,
      render: text => {
        return <UserPreview userId={text} showAvatar showUnit disabled />;
      }
    },

    {
      title: t('appealsPage.appealsList.appealBy'),
      dataIndex: 'reviewerId',
      key: 'reviewerId',
      ellipsis: true,
      render: text => {
        return <UserPreview userId={text} showAvatar showUnit disabled />;
      }
    },

    {
      title: t('general.clientInteractionType'),
      dataIndex: 'clientInteractionType',
      key: 'clientInteractionType',
      ellipsis: true,
      width: 140,
      render: text => {
        return <SText>{t(CLIENT_INTERACTIONS_TYPES_LITERALS[text])}</SText>;
      }
    },

    {
      title: t('general.criteries'),
      dataIndex: 'questionsNames',
      key: 'questionsNames',
      ellipsis: false,
      width: 350,
      render: (text, record) => {
        const questionNames = get(record, 'objectionsIds', [])
          .map(
            objectionId => questionsByIds[appellationObjectionsByIds[objectionId]?.questionId]?.name
          )
          .join(', ');

        return (
          <Typography.Paragraph
            style={{ width: '330px', marginBottom: '0px' }}
            ellipsis={{ rows: 2 }}
          >
            {questionNames}
          </Typography.Paragraph>
        );
      }
    },

    {
      title: t('general.status'),
      dataIndex: 'status',
      key: 'status',
      ellipsis: true,
      width: 200,
      render: text => (
        <SText color={APPEALS_STATUSES[text]?.color}>{t(APPEALS_STATUSES[text]?.title)}</SText>
      )
    },

    {
      title: t('general.executor'),
      dataIndex: 'assignedToId',
      key: 'assignedToId',
      ellipsis: true,
      render: text => {
        return <UserPreview userId={text} showAvatar showEmail disabled />;
      }
    },

    {
      title: t('general.changed'),
      dataIndex: 'lastActionCreatedAt',
      key: 'lastActionCreatedAt',
      ellipsis: true,
      sorter: true,
      width: 170,
      align: 'center',
      render: (text, record) => {
        const lastAction = appeallsActionsByIds[record?.lastActionId];
        if (!lastAction) return '-';
        return <SText>{moment(lastAction?.createdAt).format('DD/MM/YYYY, HH:mm')}</SText>;
      }
    },

    {
      title: t('appealsPage.appealsList.changedBy'),
      dataIndex: 'actionCreator',
      key: 'actionCreator',
      ellipsis: true,
      render: (text, record) => {
        const lastAction = appeallsActionsByIds[record?.lastActionId];
        return <UserPreview userId={lastAction?.creatorId} showAvatar showUnit disabled />;
      }
    },

    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      align: 'right',
      fixed: 'right',
      width: 120,
      render: text => (
        <SButton type="primary" onClick={() => history.push(`/appeals/${text}`)}>
          {t('general.view')}
        </SButton>
      )
    }
  ];

  const tableScrollYIndent = userPageView ? '287px' : '255px';

  return (
    <SRow type="flex">
      <SCol span={24}>
        <SCard bordered shadowed bodyPadding="14px 20px">
          <STable
            dataSource={appeals}
            columns={tableColumns}
            loading={loading}
            border
            pagination={{
              pageSize: parseInt(page.size),
              current: parseInt(page.number),
              total: totalCount,
              showSizeChanger: true,
              pageSizeOptions: ['10', '25', '50'],
              size: 'small'
            }}
            onChange={handleTableChange}
            fixSorterColumns
            scroll={{
              y: `calc(100vh - ${tableScrollYIndent})`,
              x: 'max-content'
            }}
            size="small"
          />
        </SCard>
      </SCol>
    </SRow>
  );
};

export default AppealsList;
