import { Select } from 'antd';
import RangeInputs from 'components/Inputs/RangeInputs';
import TimeSpecificSelector from 'components/Inputs/TimeSpecificSelector';
import SInput from 'components/Standard/SInput';
import SSelect from 'components/Standard/SSelect';
import { CUSTOM_FIELDS_TYPES } from 'core/utils/constants';
import { selectSearch } from 'core/utils/selectSearch';
import { find, isEmpty, some, sortBy } from 'lodash';
import React from 'react';

const { Option } = Select;

const CustomFieldFilter = ({
  customField,
  disabled,
  onChange,
  value,
  customFieldsByIds,
  customFieldFiltersByKeys
}) => {
  const { id, fieldType, key, name, options, dependsOnId, settings } = customField;

  if (fieldType === CUSTOM_FIELDS_TYPES.STRING || fieldType === CUSTOM_FIELDS_TYPES.STRING_ARRAY) {
    return (
      <SInput
        disabled={disabled}
        value={value?.contains || undefined}
        onChange={e => onChange({ contains: e.target.value })}
        placeholder={name}
        size="large"
        width="260px"
      />
    );
  }

  if (fieldType === CUSTOM_FIELDS_TYPES.NUMBER || fieldType === CUSTOM_FIELDS_TYPES.NUMBER_ARRAY) {
    return (
      <RangeInputs
        value={value}
        precision={0}
        onChange={({ from, to }) => onChange({ from, to })}
        size="large"
        style={{ width: '260px' }}
      />
    );
  }

  if (fieldType === CUSTOM_FIELDS_TYPES.DATETIME) {
    return (
      <TimeSpecificSelector
        disabled={disabled}
        placeholder={name}
        style={{ width: '260px' }}
        datePickerDirection="right"
        onChange={value => onChange(value)}
        value={value || {}}
        size="large"
      />
    );
  }

  if (!isEmpty(settings)) {
    // id, fieldType, key, name, options, dependsOnId, settings
    const parentKey = customFieldsByIds[dependsOnId]?.key;
    const parentSelectedValue = customFieldFiltersByKeys[parentKey]?.eq;

    // * allow options on parent key or allow all
    const options = parentSelectedValue
      ? settings.filter(({ parentKey }) => parentKey === parentSelectedValue)
      : settings;

    const setControlledValueToUndefined = () => {
      if (customFieldFiltersByKeys[key]) {
        onChange(undefined);
      }

      const childKey = find(customFieldsByIds, field => field.dependsOnId === id)?.key;
      if (childKey && customFieldFiltersByKeys[childKey]) {
        onChange({ [childKey]: { eq: undefined } });
      }

      return undefined;
    };

    // * if value should be controlled - check if that option exists. if not - set to undefined
    const controlledValue =
      some(options, ({ key: allowedKey }) => allowedKey === customFieldFiltersByKeys[key]) ||
      !parentKey
        ? customFieldFiltersByKeys[key]?.eq
        : undefined;

    if (controlledValue !== customFieldFiltersByKeys[key]?.eq) {
      setControlledValueToUndefined();
    }

    return (
      <SSelect
        disabled={disabled}
        showSearch
        allowClear
        optionLabelProp="label"
        placeholder={name}
        value={
          parentSelectedValue ? controlledValue : customFieldFiltersByKeys[key]?.eq || undefined
        }
        onChange={value => onChange(value)}
        filterOption={(input, option) => selectSearch({ input, option, searchProp: 'children' })}
        size="large"
        width="260px"
      >
        {sortBy(options, ['text']).map(({ key, text }) => (
          <Option key={key} value={key} label={text}>
            {text}
          </Option>
        ))}
      </SSelect>
    );
  }

  if (!isEmpty(options)) {
    return (
      <SSelect
        disabled={disabled}
        showSearch
        allowClear
        optionLabelProp="label"
        placeholder={name}
        value={value || undefined}
        onChange={value => onChange(value)}
        filterOption={(input, option) => selectSearch({ input, option, searchProp: 'children' })}
        size="large"
        width="260px"
      >
        {options.map(option => (
          <Option key={option} value={option} label={option}>
            {option}
          </Option>
        ))}
      </SSelect>
    );
  }

  return (
    <SInput
      disabled={disabled}
      value={value || undefined}
      onChange={e => onChange(e.target.value)}
      placeholder={name}
      size="large"
      width="260px"
    />
  );
};

export default React.memo(CustomFieldFilter);
