import { Modal, Skeleton } from 'antd';
import CopyLinkButton from 'components/CopyLinkButton';
import AttachTag from 'components/PhoneCallPlayer/RecordPlayer/AttachTag';
import SCard from 'components/Standard/SCard';
import SCol from 'components/Standard/SCol';
import SRow from 'components/Standard/SRow';
import SText from 'components/Standard/SText';
import { CHECKLIST_MANAGER_STATES } from 'core/utils/constants';
import { getScore } from 'core/utils/ratingsCalculations';
import { debounce, get, isEmpty, isEqual, pick } from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import {
  getChecklistDefinitionQuestionGroupsWithQuestions,
  getChecklistDefinitionQuestions
} from 'redux/selectors/checklistItems/checklistItems';
import { getChecklistsDefinitionsByIds } from 'redux/selectors/checklists';
import { getCurrentUser } from 'redux/selectors/users';
import { updateCurrentChecklist } from 'redux/ui/checklistManager/reducer';
import { setAddingComment } from 'redux/ui/clientInteractionPage/reducer';
import Editor from 'components/Comments/Editor';
import DOMPurify from 'dompurify';

import SButton from 'components/Standard/SButton';
import { Send, Edit3 } from 'react-feather';
import Icon from 'components/Icon';
import styled from 'styled-components';
import QuestionsContainer from './QuestionsContainer';
import {
  ChecklistDefinitionContainer,
  Score,
  ScoreContainer,
  StyledCollapse,
  StyledPanel
} from '../styled';

const ChecklistBody = ({
  title,
  customCommunicationView,
  review,
  addingComment,
  onAutoFail,
  comments,
  onDeleteComment,
  onUpdateComment,
  allowCommenting,
  handleCommentSave
}) => {
  const [isOpenCommentEditor, setOpenCommentEditor] = useState(false);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { checklistManagerState, currentChecklist, questionIdToAnswerValue } = useSelector(
    state =>
      pick(state.uiChecklistManager, [
        'checklistManagerState',
        'currentChecklist',
        'questionIdToAnswerValue'
      ]),
    isEqual
  );

  const loading = useSelector(
    state => state.uiChecklistManager.loading || state.checklistDefinitionsResource.loading
  );

  const checklistDefinition = useSelector(
    state => getChecklistsDefinitionsByIds(state)[currentChecklist?.checklistDefinitionId],
    isEqual
  );

  const questionGroups = useSelector(
    state => getChecklistDefinitionQuestionGroupsWithQuestions(state, checklistDefinition),
    isEqual
  );

  const questionsWithValuesAndBindings = useSelector(
    state =>
      getChecklistDefinitionQuestions(state, checklistDefinition).map(question => ({
        ...question,
        value: questionIdToAnswerValue[question.id]
      })),
    isEqual
  );

  const reviewAuthorId = review?.reviewerId;
  const currentUserId = useSelector(state => getCurrentUser(state)?.id);

  const disabledByState =
    checklistManagerState === CHECKLIST_MANAGER_STATES.SAVED ||
    (review && reviewAuthorId !== currentUserId);
  // const proccessingAppealObjections

  const handleCommentChange = debounce(e => {
    dispatch(updateCurrentChecklist({ comment: e.text }));
  }, 200);

  const score = getScore({
    checklistManagerState,
    checklistDefinition,
    checklist: currentChecklist,
    questionsWithValuesAndBindings
  });

  const comment = currentChecklist?.comment || '';

  if (customCommunicationView) {
    return !isEmpty(currentChecklist) ? (
      <SCard loading={loading}>
        <SRow>
          <SCol span={24}>
            <SRow>
              <ChecklistDefinitionContainer>
                <SRow type="flex" justify="space-between" align="middle" style={{ width: '100%' }}>
                  <SCol>
                    <SText strong>{title}</SText>
                  </SCol>
                  <SCol>
                    <SRow
                      type="flex"
                      justify="space-between"
                      align="middle"
                      gutter={[8, 8]}
                      style={{ margin: '-4px' }}
                    >
                      <SCol>
                        <AttachTag block={false} reviewId={review?.id} />
                      </SCol>
                      {review?.id && (
                        <SCol>
                          <CopyLinkButton reviewId={review?.id} />
                        </SCol>
                      )}
                      <SCol>
                        <ScoreContainer>
                          <span>{t('components.checklistManager.totalScore')}</span>
                          <Score>{score}</Score>
                        </ScoreContainer>
                      </SCol>
                    </SRow>
                  </SCol>
                </SRow>
              </ChecklistDefinitionContainer>
            </SRow>
          </SCol>

          {checklistDefinition?.isGroupable ? (
            questionGroups.map(({ name, id, questions = [] }) => (
              <StyledCollapse bordered={false} defaultActiveKey="key" key={id}>
                <StyledPanel header={<SText strong>{name}</SText>} key="key" style={{ border: 0 }}>
                  <SRow gutter={[16, 16]}>
                    <QuestionsContainer
                      questions={questions}
                      customCommunicationView={customCommunicationView}
                      disabled={disabledByState}
                      onAutoFail={onAutoFail}
                      comments={comments}
                      checklistDefinition={checklistDefinition}
                      onDeleteComment={onDeleteComment}
                      onUpdateComment={onUpdateComment}
                      allowCommenting={allowCommenting}
                      handleCommentSave={handleCommentSave}
                    />
                  </SRow>
                </StyledPanel>
              </StyledCollapse>
            ))
          ) : (
            <SRow gutter={[16, 16]}>
              <QuestionsContainer
                questions={get(questionGroups[0], 'questions')}
                customCommunicationView={customCommunicationView}
                disabled={disabledByState}
                onAutoFail={onAutoFail}
                comments={comments}
                checklistDefinition={checklistDefinition}
                onDeleteComment={onDeleteComment}
                onUpdateComment={onUpdateComment}
                allowCommenting={allowCommenting}
                handleCommentSave={handleCommentSave}
              />
            </SRow>
          )}
        </SRow>
        <Modal
          title={t('components.checklistManager.generalCommentModal.title')}
          visible={addingComment}
          onCancel={() => dispatch(setAddingComment(false))}
          cancelButtonProps={{ style: { display: 'none' } }}
          okText={t('components.checklistManager.generalCommentModal.ok')}
          cancelText={t('components.checklistManager.generalCommentModal.cancel')}
          onOk={() => dispatch(setAddingComment(false))}
        >
          {disabledByState ? (
            <SCard>
              <span
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(comment, {
                    ALLOWED_ATTR: ['target', 'href']
                  })
                }}
                style={{ overflowWrap: 'break-word' }}
              />
            </SCard>
          ) : (
            <Editor commentState={{ text: comment }} setCommentState={handleCommentChange} />
          )}
        </Modal>
      </SCard>
    ) : null;
  }

  return loading ? (
    <SCard bordered shadowed>
      <Skeleton active />
    </SCard>
  ) : (
    <SRow>
      <SCol span={24}>
        {questionGroups.map(({ questions, name }) => (
          <SRow gutter={[8, 8]}>
            <SCol span={24}>
              {checklistDefinition?.isGroupable && (
                <SText fontSize="16px" strong>
                  {name}
                </SText>
              )}
            </SCol>
            <QuestionsContainer
              questions={questions}
              customCommunicationView={customCommunicationView}
              disabled={disabledByState}
              onAutoFail={onAutoFail}
              comments={comments}
              checklistDefinition={checklistDefinition}
              onDeleteComment={onDeleteComment}
              onUpdateComment={onUpdateComment}
              allowCommenting={allowCommenting}
              handleCommentSave={handleCommentSave}
            />
          </SRow>
        ))}
      </SCol>

      <SCol span={24}>
        {disabledByState ? (
          <SCard bordered shadowed>
            <span
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(comment, {
                  ALLOWED_ATTR: ['target', 'href']
                })
              }}
              style={{ overflowWrap: 'break-word' }}
            />
          </SCard>
        ) : (
          <SCard bordered shadowed bodyPadding="10px 20px 20px 20px" marginBottom="81px">
            {isOpenCommentEditor ? (
              <SRow>
                <SCol
                  width="calc(100% - 38px)"
                  type="flex"
                  justify="space-between"
                  padding="0px 4px 10px 4px"
                >
                  <Editor commentState={{ text: comment }} setCommentState={handleCommentChange} />
                </SCol>
                <SCol width="38px" paddingTop="56px" display="flex" justifyContent="flex-end">
                  <SButton
                    size="icon"
                    icon={<Icon icon={Send} size="24px" />}
                    border="none"
                    boxShadow="none"
                    color="var(--blue_6)"
                    onClick={() => {
                      setOpenCommentEditor(!isOpenCommentEditor);
                    }}
                  />
                </SCol>
                <SCol span={24} display="flex" justifyContent="flex-start">
                  <SButton
                    onClick={() => {
                      setOpenCommentEditor(!isOpenCommentEditor);
                    }}
                  >
                    {t('general.cancel')}
                  </SButton>
                </SCol>
              </SRow>
            ) : (
              <SRow paddingTop="10px">
                <SCol span={22}>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(comment, {
                        ALLOWED_ATTR: ['target', 'href']
                      })
                    }}
                    style={{ overflowWrap: 'break-word' }}
                  />
                </SCol>
                <SCol span={2}>
                  <SRow type="flex" align="end">
                    <SCol>
                      <StyledButton
                        size="icon"
                        icon={<Icon icon={Edit3} size="16px" />}
                        color="var(--black_2)"
                        backgroundColor="transparent"
                        onClick={() => {
                          setOpenCommentEditor(!isOpenCommentEditor);
                        }}
                      />
                    </SCol>
                  </SRow>
                </SCol>
              </SRow>
            )}
          </SCard>
        )}
      </SCol>
    </SRow>
  );
};

const StyledButton = styled(SButton)`
  &.ant-btn {
    border: none;
    box-shadow: none;
    background-color: transparent;
    width: 24px !important;
  }

  &.ant-btn:hover {
    background-color: transparent;
  }
`;

export default ChecklistBody;
