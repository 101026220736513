import { Col, Row, Select } from 'antd';
import { COLOR_PALETTE_6 } from 'core/utils/colors';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { updateTaskDefinition } from 'redux/ui/taskDefinition/reducer';
import styled from 'styled-components';

export const ColorSquare = styled.div`
  width: 20px;
  height: 20px;
  margin: 4px;
  background-color: ${props => props.color};
`;

const { Option } = Select;

const ColorPicker = ({ updateTaskDefinition, labelColor }) => {
  const changeColor = value => {
    updateTaskDefinition({ labelColor: value });
  };
  const { t } = useTranslation();

  const colors = ({ labelColor }) => {
    const standartColors = Object.values(COLOR_PALETTE_6);
    const availableColors = standartColors.includes(labelColor)
      ? standartColors
      : [...standartColors, labelColor];
    return labelColor ? availableColors : standartColors;
  };

  return (
    <Row>
      <Col span={24}>
        <span>{t('components.colorPicker.name')}</span>
      </Col>
      <Col span={24} style={{ height: 32 }}>
        <Select
          onChange={changeColor}
          placeholder={t('components.colorPicker.namePlaceholder')}
          value={labelColor || ''}
          style={{ width: 70 }}
        >
          {colors({ labelColor }).map(color => (
            <Option value={color} color={color} key={color}>
              <ColorSquare color={color} />
            </Option>
          ))}
        </Select>
      </Col>
    </Row>
  );
};

const mapStateToProps = state => {
  const { labelColor } = state.uiTaskDefinition;
  return { labelColor };
};

const mapDispatchToProps = {
  updateTaskDefinition
};

export default connect(mapStateToProps, mapDispatchToProps)(ColorPicker);
