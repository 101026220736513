import { createAction, createReducer } from '@reduxjs/toolkit';

const defaultState = {
  timeRange: {
    clientInteractionTimeFrom: undefined,
    clientInteractionTimeTo: undefined,
    clientInteractionSymbolicTimeRange: undefined
  }
};

export const setTimeRange = createAction('uiCalibrationsPage/setTimeRange');

export const uiCalibrationsPageReducer = createReducer(defaultState, {
  [setTimeRange]: (state, { payload }) => ({ ...state, timeRange: payload })
});
