import {
  loadQuestionGroupsAnalytics,
  loadQuestionGroupsAnalyticsHistory
} from 'redux/entities/analytics/questionGroups/operations';
import {
  loadQuestionsAnalytics,
  loadQuestionsAnalyticsHistory
} from 'redux/entities/analytics/questions/operations';

import { reviewsResource } from 'redux/resources/reviews';
import { usersResource } from 'redux/resources/users';
import { omit } from 'lodash';
import { ANALYTICS_WIDGETS_NAMES } from 'core/utils/constants';
import {
  loadAnalyticsWidgets,
  updateAnalyticsWidgets
} from 'redux/entities/analytics/widgets/operations';
import { setCurrentUser, setChecklistDefinitionsIds, setReviewsIds, skillsLoaded } from './reducer';

export const updateUserChecklistAnalytics = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const {
      currentUser,
      currentChecklistDefinitionId,
      currentTimeRange
    } = state.uiUserAnalyticsPage;

    if (!currentUser || !currentChecklistDefinitionId) {
      return;
    }

    const filters = {
      checklistDefinitionsIds:
        currentChecklistDefinitionId !== 'all-questions' ? currentChecklistDefinitionId : 'all',
      operatorsIds: currentUser.id,
      ...currentTimeRange
    };

    // TODO: compose widget names based on checklistdefinitionId

    const widgetNamesByChecklistDefinitionFilter = {
      all: [
        ANALYTICS_WIDGETS_NAMES.REVIEWS_COUNT_BY_CHECKLIST_DEFINITIONS,
        ANALYTICS_WIDGETS_NAMES.AVERAGE_SCORE_BY_CHECKLIST_DEFINITIONS,
        ANALYTICS_WIDGETS_NAMES.RATING_FLAGS_COUNT_BY_CHECKLIST_DEFINITIONS,
        ANALYTICS_WIDGETS_NAMES.CHECKLIST_SCORES_COLOR_ZONE_BY_CHECKLIST_DEFINITIONS
      ],
      'all-questions': [
        ANALYTICS_WIDGETS_NAMES.REVIEWED_CLIENT_INTERACTION_COUNT_BY_OPERATORS,
        ANALYTICS_WIDGETS_NAMES.CHECKLIST_DEFINITION_COLOR_ZONES_BY_OPERATORS,
        ANALYTICS_WIDGETS_NAMES.RATING_FLAGS_COUNT_BY_OPERATORS,
        ANALYTICS_WIDGETS_NAMES.REVIEWS_COUNT_BY_OPERATORS,
        ANALYTICS_WIDGETS_NAMES.ANSWER_SCORES_COLOR_ZONE_BY_QUESTIONS,
        ANALYTICS_WIDGETS_NAMES.ANSWER_SCORES_AVERAGE_BY_QUESTIONS,
        ANALYTICS_WIDGETS_NAMES.ANSWER_SCORES_COUNT_BY_QUESTIONS
      ],
      default: [
        ANALYTICS_WIDGETS_NAMES.REVIEWS_COUNT_BY_CHECKLIST_DEFINITIONS,
        ANALYTICS_WIDGETS_NAMES.AVERAGE_SCORE_BY_CHECKLIST_DEFINITIONS,
        ANALYTICS_WIDGETS_NAMES.RATING_FLAGS_COUNT_BY_CHECKLIST_DEFINITIONS,
        ANALYTICS_WIDGETS_NAMES.CHECKLIST_SCORES_COLOR_ZONE_BY_CHECKLIST_DEFINITIONS,
        ANALYTICS_WIDGETS_NAMES.REVIEWED_CLIENT_INTERACTION_COUNT_BY_OPERATORS,
        ANALYTICS_WIDGETS_NAMES.CHECKLIST_DEFINITION_COLOR_ZONES_BY_OPERATORS,
        ANALYTICS_WIDGETS_NAMES.RATING_FLAGS_COUNT_BY_OPERATORS,
        ANALYTICS_WIDGETS_NAMES.REVIEWS_COUNT_BY_OPERATORS,
        ANALYTICS_WIDGETS_NAMES.ANSWER_SCORES_COLOR_ZONE_BY_QUESTIONS,
        ANALYTICS_WIDGETS_NAMES.ANSWER_SCORES_AVERAGE_BY_QUESTIONS,
        ANALYTICS_WIDGETS_NAMES.ANSWER_SCORES_COUNT_BY_QUESTIONS,
        ANALYTICS_WIDGETS_NAMES.AVERAGE_SCORE_BY_QUESTION_GROUPS
      ]
    };

    const widgetNames =
      widgetNamesByChecklistDefinitionFilter[currentChecklistDefinitionId] ||
      widgetNamesByChecklistDefinitionFilter.default;

    await dispatch(loadAnalyticsWidgets({ widgetNames, filters }));
  };
};

export const loadQuestionAnalyticsHistory = questionId => async (dispatch, getState) => {
  const state = getState();
  const { currentUser, currentChecklistDefinitionId, currentTimeRange } = state.uiUserAnalyticsPage;

  if (!currentUser || !currentChecklistDefinitionId) {
    return;
  }

  const filters = {
    checklistDefinitionsIds:
      currentChecklistDefinitionId !== 'all-questions' ? currentChecklistDefinitionId : 'all',
    operatorsIds: currentUser.id,
    questionsIds: questionId,
    ...currentTimeRange
  };

  const widgetNames = [
    ANALYTICS_WIDGETS_NAMES.ANSWER_SCORES_COLOR_ZONE_BY_QUESTIONS_HISTORY,
    ANALYTICS_WIDGETS_NAMES.REVIEWS_COUNT_BY_OPERATORS_HISTORY
  ];

  await dispatch(updateAnalyticsWidgets({ widgetNames, filters }));
};

export const loadChecklistAnalyticsHistory = checklistDefinitionId => async (
  dispatch,
  getState
) => {
  const state = getState();
  const { currentUser, currentChecklistDefinitionId, currentTimeRange } = state.uiUserAnalyticsPage;
  const filters = {
    checklistDefinitionsIds:
      currentChecklistDefinitionId !== 'all-questions' ? checklistDefinitionId : 'all',
    operatorsIds: currentUser.id,
    ...currentTimeRange
  };

  const widgetNames = [
    ANALYTICS_WIDGETS_NAMES.CHECKLIST_SCORES_COLOR_ZONE_BY_CHECKLIST_DEFINITIONS_HISTORY
  ];

  await dispatch(updateAnalyticsWidgets({ widgetNames, filters }));
};

export const loadCurrentUserById = ({ userId }) => {
  return async dispatch => {
    const user = await dispatch(
      usersResource.operations.loadById({
        id: userId,
        include:
          'checklist_definitions.question_groups.question_to_group_bindings.question,unit,role.levels,level'
      })
    );

    if (!user) {
      return;
    }

    await dispatch(setCurrentUser(user));
    dispatch(setChecklistDefinitionsIds(user.checklistDefinitionsIds));
  };
};

export const loadCurrentUserAnalytics = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const {
      currentUser,
      currentChecklistDefinitionId,
      currentTimeRange
    } = state.uiUserAnalyticsPage;

    if (!currentUser || !currentChecklistDefinitionId) {
      return;
    }

    const requestParams = {
      checklistDefinitionId: currentChecklistDefinitionId,
      userId: currentUser.id,
      ...currentTimeRange
    };

    const questionGroupsData = await Promise.all([
      dispatch(loadQuestionGroupsAnalytics(requestParams)),
      dispatch(loadQuestionGroupsAnalyticsHistory(requestParams))
    ]);

    const questionsData = await Promise.all([
      dispatch(loadQuestionsAnalytics(requestParams)),
      dispatch(loadQuestionsAnalyticsHistory(requestParams))
    ]);

    await dispatch(
      skillsLoaded({
        questions: {
          analytics: questionsData[0],
          history: questionsData[1]
        },
        questionGroups: {
          analytics: questionGroupsData[0],
          history: questionGroupsData[1]
        }
      })
    );
  };
};

export const loadCurrentUserReviews = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const {
      currentUser,
      currentChecklistDefinitionId,
      currentTimeRange
    } = state.uiUserAnalyticsPage;

    if (!currentUser || !currentChecklistDefinitionId) {
      return;
    }

    const requestParams = {
      checklistDefinitionId: currentChecklistDefinitionId,
      userId: currentUser.id,
      ...currentTimeRange
    };
    const reviewsByIds = await dispatch(
      reviewsResource.operations.load({
        include:
          'client_interaction,reviewer,client_interaction.operator,comments.author,checklist.answers',
        ...requestParams
      })
    );

    dispatch(setReviewsIds(Object.keys(reviewsByIds)));
  };
};

export const omitAllQuestionsFilter = filters =>
  filters.checklistDefinitionsIds === 'all-questions'
    ? omit(filters, 'checklistDefinitionsIds')
    : filters;
