import { get, put } from 'redux-bees';

const dashboardPagesEndpoints = apiPath => ({
  getAppellationsDashboard: { method: get, path: `${apiPath}/dashboards/pages/appellations` },
  getAppellationsDashboardAnalytics: {
    method: get,
    path: `${apiPath}/analytics/dashboards/pages/appellations`
  },
  updateAppellationsDashboard: { method: put, path: `${apiPath}/dashboards/pages/appellations` }
});

export default dashboardPagesEndpoints;
