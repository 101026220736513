import { Col, Select, Typography } from 'antd';
import ChecklistDefinitionsSelect from 'components/Checklist/ChecklistDefinitionsSelect';
import TimeSpecificSelector from 'components/Inputs/TimeSpecificSelector';
import {
  CALLS_DIRECTIONS,
  DATES_VIEW_FORMAT,
  REVIEW_CALL_STATUSES,
  WEEKEND_VIEW_STATUSES
} from 'core/utils/constants';
import { selectSearch } from 'core/utils/selectSearch';
import { isEmpty } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import SpecificUsersSelect from 'components/Inputs/SpecificUsersSelect';
import { StyledSelect } from '../styled';
import { disabledDayOption, getDefaultTimeRange, getTimeRanges } from '../utils';

const { Option } = Select;
const { Text } = Typography;

const FilterForm = ({ filterData }) => {
  const {
    unitsIds,
    symbolicTimeRange,
    timeFrom,
    timeTo,
    operatorsIds = [],
    reviewersIds,
    checklistDefinitionsIds,
    isReviewed,
    taskDefinitionsIds,
    direction,
    clientInteractionDirection,
    clientInteractionTimeFrom,
    clientInteractionTimeTo,
    clientInteractionSymbolicTimeRange,
    reviewTimeFrom,
    reviewTimeTo,
    reviewSymbolicTimeRange,
    isWeekendView,
    historyGroupingPeriod
  } = filterData.widgetModalData.filters;

  const {
    filtersDefinition,
    filtersData,
    widgetModalData,
    unitsByIds,
    reviewers,
    operators,
    checklistDefinitions,
    taskDefinitionsByIds,
    updateUiWidgetFilters
  } = filterData;

  const { t } = useTranslation();

  const filterTypeToComponent = {
    clientInteractionDateSelector: (data, type) => (
      <Col span={24}>
        <Text strong>{t('dashboardPage.widget.modal.form.communicationDate')}</Text>
        <TimeSpecificSelector
          style={{ width: '100%', margin: ' 4px 0px 8px 0px' }}
          datePickerDirection="right"
          onChange={({ symbolicTimeRange, timeTo, timeFrom }) => {
            updateUiWidgetFilters({
              clientInteractionTimeFrom: timeFrom,
              clientInteractionTimeTo: timeTo,
              clientInteractionSymbolicTimeRange:
                symbolicTimeRange === 'all' ? undefined : symbolicTimeRange
            });
          }}
          value={{
            symbolicTimeRange: clientInteractionSymbolicTimeRange,
            timeFrom: clientInteractionTimeFrom,
            timeTo: clientInteractionTimeTo
          }}
          timeRanges={getTimeRanges({ widgetType: type })}
          defaultValue={getDefaultTimeRange({ widgetType: type })}
        />
      </Col>
    ),

    isWeekendView: () => (
      <Col span={24}>
        <Text strong>{t('dashboardPage.widget.modal.form.isWeekendView')}</Text>
        <Select
          style={{ width: '100%', margin: ' 4px 0px 8px 0px' }}
          placeholder={t('dashboardPage.widget.modal.form.isWeekendViewPlaceholder')}
          onChange={value =>
            updateUiWidgetFilters({
              isWeekendView: value
            })
          }
          value={isWeekendView}
        >
          {Object.values(WEEKEND_VIEW_STATUSES).map(item => (
            <Option value={item.value}>{t(item.title)}</Option>
          ))}
        </Select>
      </Col>
    ),

    reviewDateSelector: (data, type) => (
      <Col span={24}>
        <Text strong>{t('dashboardPage.widget.modal.form.reviewDate')}</Text>
        <TimeSpecificSelector
          style={{ width: '100%', margin: ' 4px 0px 8px 0px' }}
          datePickerDirection="right"
          onChange={({ symbolicTimeRange, timeTo, timeFrom }) => {
            updateUiWidgetFilters({
              reviewTimeFrom: timeFrom,
              reviewTimeTo: timeTo,
              reviewSymbolicTimeRange: symbolicTimeRange === 'all' ? undefined : symbolicTimeRange
            });
          }}
          value={{
            symbolicTimeRange: reviewSymbolicTimeRange,
            timeFrom: reviewTimeFrom,
            timeTo: reviewTimeTo
          }}
          timeRanges={getTimeRanges({ widgetType: type })}
          defaultValue={getDefaultTimeRange({ widgetType: type })}
        />
      </Col>
    ),

    dateSelector: () => (
      <Col span={24}>
        <Text strong>{t('dashboardPage.widget.modal.form.date')}</Text>

        <TimeSpecificSelector
          value={{
            timeFrom,
            timeTo,
            symbolicTimeRange
          }}
          style={{ width: '100%', margin: ' 4px 0px 8px 0px' }}
          datePickerDirection="right"
          onChange={({ timeFrom, timeTo, symbolicTimeRange }) => {
            updateUiWidgetFilters({
              timeFrom,
              timeTo,
              symbolicTimeRange: symbolicTimeRange === 'all' ? undefined : symbolicTimeRange
            });
          }}
        />
      </Col>
    ),

    unitsIds: () => (
      <Col span={24}>
        <Text strong>{t('dashboardPage.widget.modal.form.unit')}</Text>
        <StyledSelect
          showSearch
          placeholder={t('dashboardPage.widget.modal.form.unitPlaceholder')}
          mode="multiple"
          onChange={value =>
            updateUiWidgetFilters({
              unitsIds: value,
              operatorsIds: operatorsIds.reduce((acc, current) => {
                if (value.includes(operators[current]?.unitId)) {
                  return [...acc, current];
                }
                return acc;
              }, [])
            })
          }
          value={unitsIds}
          filterOption={(input, option) => selectSearch({ input, option, searchProp: 'children' })}
        >
          {!isEmpty(unitsByIds)
            ? Object.values(unitsByIds).map(unit => (
                <Option key={unit.id} value={unit.id}>
                  {unit.name}
                </Option>
              ))
            : ''}
        </StyledSelect>
      </Col>
    ),

    requiredOperatorsIds: () => (
      <Col span={24}>
        <Text strong>
          {t('dashboardPage.widget.modal.form.operators')}
          {<Text type="danger">{t('dashboardPage.widget.modal.messages.requiredField')}</Text>}
        </Text>
        <SpecificUsersSelect
          placeholder={t('dashboardPage.widget.modal.form.operatorsPlaceHolder')}
          mode="multiple"
          onChange={value => updateUiWidgetFilters({ operatorsIds: value })}
          value={operatorsIds}
          filterOption={(input, option) => selectSearch({ input, option, searchProp: 'label' })}
          style={{ width: '100%', margin: ' 4px 0px 8px 0px' }}
          usersToSelect={Object.values(operators).filter(o => o.active)}
        />
      </Col>
    ),

    operatorsIds: () => {
      return (
        <Col span={24}>
          <Text strong>{t('dashboardPage.widget.modal.form.operators')}</Text>
          <SpecificUsersSelect
            placeholder={t('dashboardPage.widget.modal.form.operatorsPlaceHolder')}
            mode="multiple"
            onChange={value => updateUiWidgetFilters({ operatorsIds: value })}
            value={operatorsIds}
            filterOption={(input, option) => selectSearch({ input, option, searchProp: 'label' })}
            style={{ width: '100%', margin: ' 4px 0px 8px 0px' }}
            usersToSelect={Object.values(operators).filter(o => o.active)}
          />
        </Col>
      );
    },

    reviewersIds: () => {
      return (
        <Col span={24}>
          <Text strong>{t('dashboardPage.widget.modal.form.reviewers')}</Text>
          <SpecificUsersSelect
            placeholder={t('dashboardPage.widget.modal.form.reviewersPlaceHolder')}
            mode="multiple"
            onChange={value => updateUiWidgetFilters({ reviewersIds: value })}
            value={reviewersIds}
            filterOption={(input, option) => selectSearch({ input, option, searchProp: 'label' })}
            style={{ width: '100%', margin: ' 4px 0px 8px 0px' }}
            usersToSelect={Object.values(reviewers).filter(r => r.active)}
            hidden
          />
        </Col>
      );
    },

    singleChecklistDefinitionId: () => (
      <Col span={24}>
        <Text strong>
          {t('dashboardPage.widget.modal.form.checklistDefinition')}
          <Text type="danger">{t('dashboardPage.widget.modal.messages.requiredField')}</Text>
        </Text>
        <ChecklistDefinitionsSelect
          placeholder={t('dashboardPage.widget.modal.form.checklistDefinitionPlaceHolder')}
          checklistDefinitions={checklistDefinitions}
          onChange={value => updateUiWidgetFilters({ checklistDefinitionsIds: value })}
          value={checklistDefinitionsIds}
          disabled={isReviewed === 'false'}
          showSearch
          filterOption={(input, option) =>
            selectSearch({ input, option, searchProp: 'searchValue' })
          }
          style={{
            width: '100%',
            margin: '4px 0px 8px 0px'
          }}
        />
      </Col>
    ),
    multipleChecklistDefinitionsIds: () => (
      <Col span={24}>
        <Text strong>{t('dashboardPage.widget.modal.form.checklistDefinition')}</Text>
        <ChecklistDefinitionsSelect
          placeholder={t('dashboardPage.widget.modal.form.checklistDefinitionPlaceHolder')}
          mode="multiple"
          checklistDefinitions={checklistDefinitions}
          onChange={value => updateUiWidgetFilters({ checklistDefinitionsIds: value })}
          value={checklistDefinitionsIds}
          disabled={isReviewed === 'false'}
          filterOption={(input, option) =>
            selectSearch({ input, option, searchProp: 'searchValue' })
          }
          style={{
            width: '100%',
            margin: '4px 0px 8px 0px'
          }}
        />
      </Col>
    ),
    requiredMultipleChecklistDefinitionsIds: () => (
      <Col span={24}>
        <Text strong>
          {t('dashboardPage.widget.modal.form.checklistDefinition')}
          <Text type="danger">{t('dashboardPage.widget.modal.messages.requiredField')}</Text>
        </Text>
        <ChecklistDefinitionsSelect
          placeholder={t('dashboardPage.widget.modal.form.checklistDefinitionPlaceHolder')}
          mode="multiple"
          checklistDefinitions={checklistDefinitions}
          onChange={value => updateUiWidgetFilters({ checklistDefinitionsIds: value })}
          value={checklistDefinitionsIds}
          disabled={isReviewed === 'false'}
          filterOption={(input, option) =>
            selectSearch({ input, option, searchProp: 'searchValue' })
          }
          style={{
            width: '100%',
            margin: '4px 0px 8px 0px'
          }}
        />
      </Col>
    ),
    taskDefinitionsIds: () => (
      <Col span={24}>
        <Text strong>{t('dashboardPage.widget.modal.form.taskDefinitions')}</Text>
        <StyledSelect
          placeholder={t('dashboardPage.widget.modal.form.taskDefinitionsPlaceHolder')}
          mode="multiple"
          onChange={value => updateUiWidgetFilters({ taskDefinitionsIds: value })}
          value={taskDefinitionsIds}
          filterOption={(input, option) => selectSearch({ input, option, searchProp: 'children' })}
        >
          {!isEmpty(taskDefinitionsByIds)
            ? Object.values(taskDefinitionsByIds).map(taskDefinition => (
                <Option key={taskDefinition.id} value={taskDefinition.id}>
                  {taskDefinition.labelName}
                </Option>
              ))
            : ''}
        </StyledSelect>
      </Col>
    ),
    isReviewed: () => (
      <Col span={24}>
        <Text strong>{t('dashboardPage.widget.modal.form.isReviewed')}</Text>
        <StyledSelect
          placeholder={t('dashboardPage.widget.modal.form.isReviewedPlaceHolder')}
          onChange={value => updateUiWidgetFilters({ isReviewed: value })}
          value={isReviewed}
        >
          {Object.values(REVIEW_CALL_STATUSES).map(item => (
            <Option key={item.value} value={item.value}>
              {t(item.title)}
            </Option>
          ))}
        </StyledSelect>
      </Col>
    ),

    historyGroupingPeriodSelector: (data, type) => (
      <Col span={24}>
        <Text strong>{t('dashboardPage.widget.modal.form.dateFormat')}</Text>
        <StyledSelect
          placeholder={t('dashboardPage.widget.modal.form.dateFormatPlaceHolder')}
          onChange={value => updateUiWidgetFilters({ historyGroupingPeriod: value })}
          value={historyGroupingPeriod}
        >
          {Object.values(DATES_VIEW_FORMAT).map(item => (
            <Option
              key={item.value}
              value={item.value}
              disabled={disabledDayOption({
                timeFrom:
                  type === 'table_reviews_count_by_reviewers'
                    ? reviewTimeFrom
                    : clientInteractionTimeFrom,
                timeTo:
                  type === 'table_reviews_count_by_reviewers'
                    ? reviewTimeTo
                    : clientInteractionTimeTo,
                symbolicTimeRange:
                  type === 'table_reviews_count_by_reviewers'
                    ? reviewSymbolicTimeRange
                    : clientInteractionSymbolicTimeRange,
                widgetType: type,
                currentOption: item.value,
                historyGroupingPeriod,
                updateUiWidgetFilters
              })}
            >
              {t(item.title)}
            </Option>
          ))}
        </StyledSelect>
      </Col>
    ),

    direction: () => (
      <Col span={24}>
        <Text strong>{t('dashboardPage.widget.modal.form.direction')}</Text>
        <StyledSelect
          placeholder={t('dashboardPage.widget.modal.form.directionPlaceHolder')}
          onChange={value => updateUiWidgetFilters({ direction: value })}
          value={direction}
        >
          {Object.values(CALLS_DIRECTIONS).map(item => (
            <Option key={item.value} value={item.value}>
              {t(item.title)}
            </Option>
          ))}
        </StyledSelect>
      </Col>
    ),
    clientInteractionDirection: () => (
      <Col span={24}>
        <Text strong>{t('dashboardPage.widget.modal.form.direction')}</Text>
        <StyledSelect
          placeholder={t('dashboardPage.widget.modal.form.directionPlaceHolder')}
          onChange={value => updateUiWidgetFilters({ clientInteractionDirection: value })}
          value={clientInteractionDirection}
          allowClear
        >
          <Option key={CALLS_DIRECTIONS.INCOMING.value} value={CALLS_DIRECTIONS.INCOMING.value}>
            {t(CALLS_DIRECTIONS.INCOMING.title)}
          </Option>
          <Option key={CALLS_DIRECTIONS.OUTCOMING.value} value={CALLS_DIRECTIONS.OUTCOMING.value}>
            {t(CALLS_DIRECTIONS.OUTCOMING.title)}
          </Option>
        </StyledSelect>
      </Col>
    )
  };
  return (
    <Col span={24}>
      {filterTypeToComponent[filtersDefinition.type](filtersData, widgetModalData.type)}
    </Col>
  );
};

export default FilterForm;
