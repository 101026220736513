import { Card, Skeleton } from 'antd';
import { COMMUNICATION_TYPES, PERMISSIONS } from 'core/utils/constants';
import { get, isEmpty } from 'lodash';
import CustomCommunicationReviewPage from 'pages/CustomCommunicationReviewPage';
import Forbidden from 'pages/ErrorPages/Forbidden';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getCurrentUser } from 'redux/selectors/users';
import { loadReviewById } from 'redux/ui/clientInteractionPage/operations';
import * as actions from 'redux/ui/clientInteractionPage/reducer';
import ClientInteractionPage from './ClientInteractionPage';
import contentTypeToComponent from './contentTypeToComponent';

const ReviewPage = ({
  loadReviewById,
  loading,
  id,
  setInitialState,
  contentType,
  clientInteraction,
  review,
  currentUser,
  offsetTop,
  affixTarget,
  shouldLoad = true,
  fromDrawer = false
}) => {
  useEffect(() => {
    const loadData = async () => {
      await loadReviewById({
        id,
        shouldLoad,
        include:
          'reviewer,custom_communication,text_communication,phone_call.phone_call_transcription.parts,client_interaction.operator.unit,client_interaction.text_communication_parts.operator,checklist.answers.question,checklist.checklist_definition.question_groups.question_to_group_bindings.question,tasks.task_definition'
      });
    };

    loadData();

    return () => setInitialState();
  }, []);

  const { t } = useTranslation();

  if (loading) {
    return (
      <Card>
        <Skeleton active />
      </Card>
    );
  }

  if (
    ![review.reviewerId, clientInteraction.operatorId].includes(currentUser.id) &&
    !get(currentUser, 'role.permissions', []).includes(PERMISSIONS.CAN_SEE_UNIT_CLIENT_INTERACTIONS)
  ) {
    return <Forbidden />;
  }

  if (clientInteraction.clientInteractionType === COMMUNICATION_TYPES.CUSTOM) {
    return (
      <CustomCommunicationReviewPage
        communication={clientInteraction}
        review={review}
        fromDrawer={fromDrawer}
      />
    );
  }

  return (
    <>
      <Helmet>
        <title>{t('pagesMeta.customCommunicationReviewPage.titles.review')}</title>
      </Helmet>
      <ClientInteractionPage
        Content={contentTypeToComponent(contentType)}
        id={id}
        clientInteraction={clientInteraction}
        review={review}
        loading={loading}
        offsetTop={offsetTop}
        affixTarget={affixTarget}
        fromDrawer={fromDrawer}
        shouldLoad={shouldLoad}
      />
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  const id = ownProps?.id || ownProps?.match?.params?.id;

  const { byIds, loading, loadByIdStarted } = state.reviewsResource;
  const review = byIds[id];
  //   get content type from review and pass to props
  if (loading || loadByIdStarted || isEmpty(review) || review.loading)
    return { id, loading: true, clientInteractions: state.clientInteractionsResource.byIds };

  const clientInteraction = state.clientInteractionsResource.byIds[review.clientInteractionId];
  const extendedClientInteraction = {
    ...get(state.phoneCallsResource.byIds, review.clientInteractionId, {}),
    ...get(state.textCommunicationsResource.byIds, review.clientInteractionId, {}),
    ...clientInteraction
  };

  return {
    id,
    currentUser: getCurrentUser(state),
    contentType: state.uiClientInteractionPage.contentType,
    clientInteraction: extendedClientInteraction,
    review,
    clientInteractions: state.clientInteractionsResource.byIds
  };
};

const mapDispatchToProps = {
  loadReviewById,
  setInitialState: actions.setInitialState
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReviewPage));
