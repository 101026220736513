import React from 'react';
import { Col, Tooltip, Row } from 'antd';
import { connect } from 'react-redux';
import { isEmpty, orderBy, get } from 'lodash';
import { ResponsiveBar } from '@nivo/bar';
import { beatifyFloat } from 'core/utils/convertFloat';
import truncateString from 'core/utils/truncateString';
import SettingsModal from '../../Components/SettingsModal';
import {
  widgetNameSetter,
  tooltipSetter,
  widgetPeriodSetter,
  widgetInfoTextSetter,
  getDateSelectorType,
  searchMaxValue
} from '../../utils';
import { SettingsButtonCol, WidgetNameCol, WidgetCard, TimePeriod } from '../../styled';
import EmptyWidget from '../../Components/EmptyWidget';

const ChecklistDefinitionQuestionGroupsAverageScores = ({
  widgetData,
  widgetsAnalyticsByIds,
  colors,
  loadingWidgetId,
  checklistDefinitionsByIds,
  questionGroupsByIds
}) => {
  const widgetValue = get(widgetsAnalyticsByIds, widgetData.id, []);

  const { id, type, filters, name } = widgetData;
  const dateSelectorType = getDateSelectorType({ widgetType: type });

  const prepareData = ({ data }) => {
    return Object.values(data).map(item => ({
      id: item.id,
      name: item.name,
      position: get(questionGroupsByIds, `${item.id}.position`),
      value: beatifyFloat(item.value)
    }));
  };

  const preparedData = orderBy(prepareData({ data: widgetValue }), 'position', 'desc');

  return (
    <>
      {isEmpty(widgetValue) || loadingWidgetId === id ? (
        <EmptyWidget key={id} widgetData={widgetData} loadingWidgetId={loadingWidgetId} />
      ) : (
        <Col span={6}>
          <WidgetCard>
            <Row type="flex">
              <WidgetNameCol span={20}>
                {widgetInfoTextSetter({ type })}
                <Tooltip title={widgetNameSetter({ name, type })}>
                  <span>{widgetNameSetter({ name, type })}</span>
                </Tooltip>
                <TimePeriod type="secondary">
                  {widgetPeriodSetter(filters, dateSelectorType)}
                </TimePeriod>
              </WidgetNameCol>
              <SettingsButtonCol span={4}>
                <SettingsModal widgetData={widgetData} />
              </SettingsButtonCol>
            </Row>

            <ResponsiveBar
              data={preparedData}
              keys={['value']}
              indexBy="name"
              margin={{ top: 0, right: 10, bottom: 70, left: 120 }}
              padding={0.3}
              layout="horizontal"
              colors={colors}
              borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
              axisTop={null}
              axisRight={null}
              maxValue={searchMaxValue({ checklistDefinitionsByIds, filters })}
              tooltip={tooltip => tooltipSetter(tooltip.data)}
              axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                format: d => (d.toString().match(/\./) ? '' : d)
              }}
              axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                format: string => truncateString(string, 16)
              }}
              labelSkipWidth={12}
              labelSkipHeight={12}
              labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
              animate
              motionStiffness={90}
              motionDamping={15}
            />
          </WidgetCard>
        </Col>
      )}
    </>
  );
};

const mapStateToProps = state => {
  return {
    widgetsAnalyticsByIds: state.dashboardAnalytics.byIds,
    checklistDefinitionsByIds: state.checklistDefinitionsResource.byIds,
    questionGroupsByIds: state.questionGroupsResource.byIds
  };
};

export default connect(mapStateToProps, null)(ChecklistDefinitionQuestionGroupsAverageScores);
