import SCard from 'components/Standard/SCard';
import SCol from 'components/Standard/SCol';
import SRow from 'components/Standard/SRow';
import { STitle } from 'components/Standard/SText';
import { isEqual, pickBy } from 'lodash';
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import UploadedFile from 'components/Comments/Editor/UploadedFile';
import { uploadedFilesResource } from 'redux/resources/uploadedFiles';
import { appellationCommentsResource } from 'redux/resources/appellationComments';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';
import ActionsListContainer from './ActionsListContainer';
import ActionCommentForm from './ActionCommentForm';

export const Actions = ({ appeal, loading }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [commentState, setCommentState] = useState({ text: '', uploadedFiles: [] });
  const actionsByIds = useSelector(
    state => pickBy(state.appellationActionsResource.byIds, { appellationId: appeal?.id }),
    isEqual
  );

  const onDeleteFile = id => {
    setCommentState({
      ...commentState,
      uploadedFiles: commentState?.uploadedFiles.filter(({ id: fileId }) => fileId !== id)
    });
    dispatch(uploadedFilesResource.operations.deleteById({ id }));
  };

  const sendComment = async commentState => {
    try {
      const comment = {
        appellationId: appeal.id,
        text: commentState.text,
        commentType: 'general_appellation_comment',
        uploadedFilesIds: commentState.uploadedFiles.map(({ id }) => id)
      };
      const result = await dispatch(appellationCommentsResource.operations.create(comment));
      if (result) {
        message.success(t('messages.success.commentAdded'));
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <SCard bordered shadowed loading={!appeal || loading}>
      <SRow gutter={[0, 16]} style={{ marginBottom: '-8px' }} data-testid="appeal-actions">
        <SCol span={24}>
          <STitle level={5}>{t('appealsPage.actionsListName')}</STitle>
        </SCol>
        <SCol span={24}>
          <ActionsListContainer actionsByIds={actionsByIds} />
        </SCol>
        <SCol span={24}>
          <ActionCommentForm onSend={sendComment} />
        </SCol>
        <SCol>
          {commentState.uploadedFiles?.map(file => (
            <UploadedFile
              uploadedFile={file}
              allFiles={commentState?.uploadedFiles}
              onDelete={onDeleteFile}
            />
          ))}
        </SCol>
      </SRow>
    </SCard>
  );
};
