import { Button, Col, message, Radio, Row } from 'antd';
import QuestionModal from 'components/Questions/QuestionModal';
import SCard from 'components/Standard/SCard';
import { SCALE_TYPES } from 'core/utils/constants';
import objectsDifference from 'core/utils/objectsDifference';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { actions } from 'redux/lists/questionsList';
import { questionsResource } from 'redux/resources/questions';
import { setCurrentList } from 'redux/ui/checklistsAndQuestionsPage/reducer';
import { setEditingQuestion } from 'redux/ui/questionModal/reducer';
import uniqid from 'uniqid';

const ChecklistsAndQuestionsFilters = ({
  history,
  setCurrentList,
  currentList,
  setEditingQuestion,
  createQuestion,
  updateQuestion,
  questionsByIds,
  onQuestionCreate
}) => {
  const { t } = useTranslation();

  const onAddQuestion = () => {
    setEditingQuestion({
      id: uniqid(),
      scaleType: SCALE_TYPES.max_5.type,
      colorZones: SCALE_TYPES.max_5.colorZones,
      ratingValues: SCALE_TYPES.max_5.numbers
    });
  };

  const onCreateChecklist = () => {
    history.push('/checklist-settings');
  };

  const onSubmitQuestion = async ({ question }) => {
    const isNew = !questionsByIds[question.id];
    const newQuestion = isNew
      ? await createQuestion(question)
      : await updateQuestion({
          ...objectsDifference(question, questionsByIds[question.id], ['colorZones']),
          id: question.id
        });

    if (newQuestion) {
      message.success(
        `${t('checklistsPage.messages.question')} ${question.name} ${
          isNew
            ? t('checklistsPage.messages.questionSuccessfullyCreated')
            : t('checklistsPage.messages.questionSuccessfullyUpdated')
        } `
      );
      onQuestionCreate({ questionId: newQuestion.id });
      setEditingQuestion({});
    } else {
      message.error(
        isNew
          ? t('checklistsPage.messages.createQuestionfailed')
          : t('checklistsPage.messages.updateQuestionfailed')
      );
    }
  };

  return (
    <SCard>
      <Row type="flex" justify="space-between">
        <Col style={{ flex: 'auto' }}>
          <Row type="flex">
            <Col>
              <Radio.Group
                onChange={({ target }) => setCurrentList(target.value)}
                value={currentList}
              >
                <Radio.Button value="checklists">
                  {t('checklistsPage.buttons.checklists')}
                </Radio.Button>
                <Radio.Button value="questions">
                  {t('checklistsPage.buttons.questions')}
                </Radio.Button>
              </Radio.Group>
            </Col>
          </Row>
        </Col>
        <Col>
          <Row type="flex" justify="end" gutter={[8, 0]} style={{ marginBottom: '-4px' }}>
            <Col>
              <Button type="default" onClick={onAddQuestion}>
                {t('checklistsPage.buttons.createQuestion')}
              </Button>
            </Col>
            <Col>
              <Button type="primary" onClick={onCreateChecklist}>
                {t('checklistsPage.buttons.createChecklists')}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <QuestionModal onSubmit={onSubmitQuestion} />
    </SCard>
  );
};

const mapStateToProps = state => ({
  currentList: state.uiChecklistsAndQuestionsPage.currentList,
  questionsByIds: state.questionsResource.byIds
});

const mapDispatchToProps = {
  setCurrentList,
  setEditingQuestion,
  createQuestion: questionsResource.operations.create,
  updateQuestion: questionsResource.operations.updateById,
  onQuestionCreate: actions.onQuestionCreate
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ChecklistsAndQuestionsFilters)
);
