import React from 'react';
import { Col, Tooltip, Row } from 'antd';
import { connect } from 'react-redux';
import { isEmpty, orderBy, get } from 'lodash';
import { FLAGS_COLORS_LITERALS_RUS, FLAGS_TO_COLORS_RELATIONS } from 'core/utils/constants';
import { ResponsiveBar } from '@nivo/bar';
import truncateString from 'core/utils/truncateString';
import { getUserName } from 'components/UserPreview/getUserName';
import { useTranslation } from 'react-i18next';
import SettingsModal from '../../Components/SettingsModal';
import {
  widgetNameSetter,
  tooltipSetter,
  widgetPeriodSetter,
  widgetInfoTextSetter,
  getDateSelectorType
} from '../../utils';
import { SettingsButtonCol, WidgetNameCol, WidgetCard, TimePeriod } from '../../styled';
import EmptyWidget from '../../Components/EmptyWidget';

const RatingFlagsCountByOperators = ({
  widgetData,
  widgetsAnalyticsByIds,
  loadingWidgetId,
  usersByIds
}) => {
  const { t } = useTranslation();
  const { id, type, filters, name } = widgetData;
  const dateSelectorType = getDateSelectorType({ widgetType: type });

  const widgetValue =
    loadingWidgetId === id
      ? []
      : orderBy(
          get(widgetsAnalyticsByIds, widgetData.id, []).map(item => ({
            ...item,
            name: getUserName({ user: usersByIds[item.id] }),
            gray: item.without_flag
          })),
          'name',
          'desc'
        );

  return (
    <>
      {isEmpty(widgetValue) || loadingWidgetId === id ? (
        <EmptyWidget key={id} widgetData={widgetData} loadingWidgetId={loadingWidgetId} />
      ) : (
        <Col span={6}>
          <WidgetCard>
            <Row type="flex">
              <WidgetNameCol span={20}>
                {widgetInfoTextSetter({ type })}
                <Tooltip title={widgetNameSetter({ name, type })}>
                  <span>{widgetNameSetter({ name, type })}</span>
                </Tooltip>
                <TimePeriod type="secondary">
                  {widgetPeriodSetter(filters, dateSelectorType)}
                </TimePeriod>
              </WidgetNameCol>
              <SettingsButtonCol span={4}>
                <SettingsModal widgetData={widgetData} />
              </SettingsButtonCol>
            </Row>

            <ResponsiveBar
              data={widgetValue}
              keys={['without_flag', 'green', 'yellow', 'red']}
              indexBy="name"
              margin={{ top: 0, right: 10, bottom: 70, left: 120 }}
              padding={0.3}
              layout="horizontal"
              colors={bar => FLAGS_TO_COLORS_RELATIONS[bar.id]}
              borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
              axisTop={null}
              axisRight={null}
              tooltip={tooltip =>
                tooltipSetter({
                  name: t(FLAGS_COLORS_LITERALS_RUS[tooltip.id]),
                  value: tooltip.value
                })
              }
              axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                format: d => (d.toString().match(/\./) ? '' : d)
              }}
              axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                format: string => truncateString(string, 16)
              }}
              labelSkipWidth={12}
              labelSkipHeight={12}
              labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
              animate
              motionStiffness={90}
              motionDamping={15}
            />
          </WidgetCard>
        </Col>
      )}
    </>
  );
};

const mapStateToProps = state => {
  return {
    widgetsAnalyticsByIds: state.dashboardAnalytics.byIds,
    usersByIds: state.usersResource.byIds
  };
};

export default connect(mapStateToProps, null)(RatingFlagsCountByOperators);
