export default {
  customCommunicationPage: {
    title: 'Nueva evaluación de la comunicación',
    checklistDefinitionPlaceholder: 'Elija una lista a chequear',
    form: {
      title: 'Autor: ',
      fields: {
        operator: 'Empleado',
        communicationType: 'Tipo de comunicación',
        clientPhoneNumber: 'Número de teléfono',
        communicationDate: 'Fecha de comunicacion',
        duration: 'Duración',
        direction: 'Dirección de la comunicación',
        email: 'Correo electrónico',
        additional: 'Adicional',
        communicationId: 'ID de la comunicación',
        mediaUrl: 'Enlace al aechivo multimedia'
      },
      messages: {
        requiredOperator: 'Por favor elija un empleado',
        requiredCommunicationType: 'Por favor elija el tipo de comunicación',
        requiredClientPhoneNumber: 'Por favor elija el número de teléfono',
        requiredCommunicationDate: 'Por favor elija la fecha de omunicación',
        requiredDirection: 'Por favor elija la direción',
        requiredEmail: 'Por favor introduzca el correo electrónico',
        wrongEmail: 'Por favor introduzca el correo el electrónico correcto',
        requiredCommunicationId: 'Por favor introduzca el Id de la comunicación',
        wrongMediaUrl: 'Por favor introduzca el Url correcto',
        communicationIdTooltip:
          'En este campo Usted puede añadir cualquier identificador, que le ayude a encontrar con facilidad la comunicación necesaria en el futuro'
      },
      buttons: {
        openFile: 'Abrir el archivo anexado',
        addUrl: 'Agregar el enlace al archivo'
      }
    },
    messages: {
      needToChangeMode: 'Para ejecutar las acciones pase al modo redacción de evaluación',
      commentSuccessfullyUpdated: 'Comentario actualizado',
      updateCommentFailed: 'No se logró actualizar el comentario',
      communicationSuccessfullyCreated: 'Evaluación creada',
      communicationSuccessfullyUpdated: 'Evaluación actualizada',
      createCommunicationFailed: 'No se logró crear la comunicación',
      updateCommunicationFailed: 'No se logró actualizar la comunicación',
      reviewSuccessfullyDeleted: 'Evaluación eliminada',
      deleteReviewFailed: 'No se logró eliminar la evaluación',
      disabledOperator: 'Ufff! Este usuario no está activo',
      needToActivateOperator: 'Para pasar a la evaluación, es necesario activar el usuario',
      unsavedReview: 'No se ha guardado la evaluación en la lista a chequear',
      isEmptyQuestionsValues:
        'No se puede guardar una evlación vacía. Evalúe al menos una prgunta de la lista a chequear'
    },
    buttons: {
      viewGeneralComment: 'Ver el comentario general',
      addGeneralComment: 'Agregar un comentario general',
      editGeneralComment: 'Redactar el comentario general',
      viewOperatorSettings: 'Pasar a los ajustes',
      saveReview: 'Guardar la evaluación',
      editReview: 'Redactar la evaluación'
    },
    confirmDeleteReview: {
      title: '¿Eliminar la evaluación?',
      description: 'La evaluación eliminada no se puede recuoperar'
    }
  }
};
