/* eslint-disable jsx-a11y/accessible-emoji */
import { Col, Empty, Row, Typography } from 'antd';
import QuestionDetailsModal from 'components/Questions/QuestionDetailsModal';
import { ANALYTICS_WIDGETS_NAMES } from 'core/utils/constants';
import { get } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import QuestionsAnalytics from '../QuestionsAnalytics';
import ChecklistDefinitionAnalytics from './ChecklistDefinitionAnalytics';

const { Text } = Typography;

export const AllQuestionsAnalytics = ({ loadingWidgets, widgets, userId }) => {
  const reviewsCount = get(
    widgets,
    [ANALYTICS_WIDGETS_NAMES.REVIEWS_COUNT_BY_OPERATORS, userId, 'value'],
    0
  );
  const { t } = useTranslation();

  if (!reviewsCount && !loadingWidgets) {
    return (
      <Row gutter={[8, 8]} style={{ margin: '-4px' }}>
        <Col span={24}>
          <Empty
            description={
              <Text type="secondary">{t('userAnalyticsPage.analyticsChartsPanel.empty')}</Text>
            }
          />
        </Col>
      </Row>
    );
  }

  return (
    <Row gutter={[8, 8]} style={{ margin: '-4px' }}>
      <Col span={24}>
        <ChecklistDefinitionAnalytics
          loading={loadingWidgets}
          reviewsCount={reviewsCount}
          colorZones={get(widgets, [
            ANALYTICS_WIDGETS_NAMES.CHECKLIST_DEFINITION_COLOR_ZONES_BY_OPERATORS,
            userId
          ])}
          ratingFlags={get(widgets, [
            ANALYTICS_WIDGETS_NAMES.RATING_FLAGS_COUNT_BY_OPERATORS,
            userId
          ])}
        />
      </Col>
      <Col span={24}>
        <QuestionsAnalytics
          widgets={widgets}
          loading={loadingWidgets}
          reviewsCount={get(widgets, [
            ANALYTICS_WIDGETS_NAMES.REVIEWS_COUNT_BY_OPERATORS,
            userId,
            'value'
          ])}
        />
      </Col>
      <QuestionDetailsModal widgets={widgets} loadingWidgets={loadingWidgets} />
    </Row>
  );
};
