import { Modal } from 'antd';
import { CommentsModal } from 'components/Comments/CommentsModal';
import withConditionalRender from 'components/WithConditionalRender/withConditionalRender';
import { CHECKLIST_MANAGER_STATES, COMMUNICATION_TYPES, PERMISSIONS } from 'core/utils/constants';
import { get, isEmpty, keyBy, pickBy, camelCase } from 'lodash';
import qs from 'qs';
import React, { useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { checklistDefinitionsResource } from 'redux/resources/checklistDefinitions';
import {
  getChecklistDefinitionQuestionGroups,
  getChecklistDefinitionQuestions,
  getQuestionGroupQuestions
} from 'redux/selectors/checklistItems/checklistItems';
import { getCurrentUser } from 'redux/selectors/users';
import { createChecklistFromDefinition } from 'redux/ui/checklistManager/operations';
import {
  setAddingCommentsToQuestionId,
  setChecklistManagerState,
  setQuestionIdDescriptionModal,
  updateAnswer,
  updateCurrentChecklist,
  updateQuestionStandardComments
} from 'redux/ui/checklistManager/reducer';

import QuestionDescriptionModal from './components/QuestionDescriptionModal';
import SelectChecklist from './SelectChecklist';

import ChecklistBody from './components/ChecklistBody';

const ChecklistManager = ({
  review,
  customCommunicationView = false,
  communication,
  currentChecklist,
  createChecklistFromDefinition,
  loadChecklistDefinitions,
  loading,
  checklistManagerState,
  onSubmit,
  onCommentSave,
  onDeleteComment,
  onUpdateComment,
  allowCommenting,
  comments,
  setAddingCommentsToQuestionId,
  addingCommentsToQuestionId,
  setChecklistManagerState,
  history,
  editingCommentId,
  addingComment
}) => {
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const initialLoad = async () => {
    const response = await loadChecklistDefinitions({
      pagination: false,
      sort: 'created_at',
      filters: { status: 'published' }
    });

    const { search } = history.location;

    if (search) {
      const { checklistDefinitionId } = qs.parse(search, { ignoreQueryPrefix: true });

      if (checklistDefinitionId && get(response, checklistDefinitionId) && !review?.id) {
        createChecklistFromDefinition({ currentChecklist, checklistDefinitionId });
      }
    }
  };

  useEffect(() => {
    initialLoad();
  }, []);

  const onAutoFail = useCallback(() => {
    return Modal.confirm({
      title: t('components.checklistManager.autoFailModal.title'),
      content: t('components.checklistManager.autoFailModal.description'),
      onOk: onSubmit,
      okText: t('components.checklistManager.autoFailModal.ok'),
      cancelText: t('components.checklistManager.autoFailModal.cancel')
    });
  }, [onSubmit]);

  const handleCommentSave = useCallback(
    comment => {
      dispatch(setChecklistManagerState(CHECKLIST_MANAGER_STATES.EDITING));

      onCommentSave({
        ...comment,
        metadata: { ...(comment.metadata || {}), questionId: addingCommentsToQuestionId }
      });
    },
    [dispatch, addingCommentsToQuestionId]
  );

  if (loading) return null;

  const ConditionalSelectChecklist = withConditionalRender(
    { allowedPermissions: [PERMISSIONS.CAN_MAKE_REVIEW] },
    SelectChecklist
  );

  if (!loading && !currentChecklist && !customCommunicationView) {
    return (
      <ConditionalSelectChecklist
        communication={communication}
        onSelect={({ checklistDefinitionId }) =>
          createChecklistFromDefinition({ currentChecklist, checklistDefinitionId })
        }
      />
    );
  }

  return (
    <>
      <ChecklistBody
        onAutoFail={onAutoFail}
        review={review}
        comments={comments}
        onDeleteComment={onDeleteComment}
        onUpdateComment={onUpdateComment}
        allowCommenting={allowCommenting}
        handleCommentSave={handleCommentSave}
        customCommunicationView={customCommunicationView}
        addingComment={addingComment}
      />
      <CommentsModal
        visible={!isEmpty(addingCommentsToQuestionId) && customCommunicationView}
        comments={pickBy(
          comments,
          comment => get(comment, 'metadata.questionId') === addingCommentsToQuestionId
        )}
        onCommentSave={handleCommentSave}
        onCancelComment={() => setAddingCommentsToQuestionId(null)}
        onCancel={() => setAddingCommentsToQuestionId(null)}
        onDeleteComment={onDeleteComment}
        onUpdateComment={onUpdateComment}
        allowCommenting={allowCommenting}
        editingCommentId={editingCommentId}
      />
      <QuestionDescriptionModal
        onAutoFail={onAutoFail}
        disabled={checklistManagerState === CHECKLIST_MANAGER_STATES.SAVED}
      />
    </>
  );
};

const mapStateToProps = state => {
  const {
    checklistManagerState,
    currentChecklist,
    loading,
    questionIdToAnswerValue,
    questionIdToStandardComment,
    addingCommentsToQuestionId
  } = state.uiChecklistManager;

  const { editingCommentId, contentType } = state.uiClientInteractionPage;

  if (isEmpty(currentChecklist))
    return { currentChecklist: undefined, questionIdToAnswerValue: {} };

  const checklistDefinitionsByIds = state.checklistDefinitionsResource.byIds;

  const checklistDefinition = get(
    checklistDefinitionsByIds,
    currentChecklist.checklistDefinitionId,
    {}
  );

  const isGroupable = get(checklistDefinition, 'isGroupable', undefined);

  // TODO: optimization
  const questionGroups = getChecklistDefinitionQuestionGroups(state, checklistDefinition).map(
    group => ({
      ...group,
      questions: getQuestionGroupQuestions(state, group)
    })
  );

  const questionsByIds = keyBy(
    getChecklistDefinitionQuestions(state, checklistDefinition).map(question => ({
      ...question,
      value: questionIdToAnswerValue[question.id]
    })),
    'id'
  );

  return {
    addingCommentsToQuestionId,
    checklistManagerState,
    loading: loading || state.checklistDefinitionsResource.loading,
    currentChecklist,
    checklistDefinition,
    isGroupable,
    title: checklistDefinition?.name,
    questionsByIds,
    questionGroups,
    questionIdToAnswerValue,
    questionIdToStandardComment,
    comment: currentChecklist.comment,
    reviewsByIds: state.reviewsResource.byIds,
    user: getCurrentUser(state),
    editingCommentId,
    contentType
  };
};

const mapDispatchToProps = {
  loadChecklistDefinitions: checklistDefinitionsResource.operations.loadWithInclude,
  setAddingCommentsToQuestionId,
  setChecklistManagerState,
  setQuestionIdDescriptionModal,
  updateAnswer,
  updateCurrentChecklist,
  createChecklistFromDefinition,
  updateQuestionStandardComments
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChecklistManager));
