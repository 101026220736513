import { Col, Row } from 'antd';
import ClientInteractionDrawer from 'components/ClientInteractionDrawer';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import ClientInteractionsSettingsDrawer from './ClientInteractionsSettingsDrawer/ClientInteractionsSettingsDrawer';
import ClientInteractionsTable from './components/ClientInteractionsTable';
import TableFilters from './components/TableFilters';

const ClientInteractions = () => {
  const { t } = useTranslation();

  return (
    <Row gutter={[16, 0]}>
      <Helmet>
        <title>{t('pagesMeta.clientInteractionsPage.title')}</title>
      </Helmet>
      <Col span={5}>
        <TableFilters />
      </Col>
      <Col span={19}>
        <ClientInteractionsTable />
      </Col>
      <ClientInteractionsSettingsDrawer />
      <ClientInteractionDrawer />
    </Row>
  );
};

export default ClientInteractions;
