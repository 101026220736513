export default {
  loginPage: {
    form: {
      title: 'Login',
      unRegistered: 'Non registrato?',
      fields: {
        emailPlaceholder: 'Inserisci la tua email',
        passwordPlaceholder: 'Inserisci la password'
      },
      messages: {
        enterEmail: 'Inserisci la tua email',
        enterPassword: 'Inserisci la password',
        wrongEmail: 'Inserisci una email valida',
        minLengthPassword: 'Minimo 6 caratteri'
      },
      buttons: {
        login: 'Login',
        forgotPassword: 'Hai dimenticato la password?',
        registration: 'Resistrazione',
        goToSite: 'Passare al sito web',
        registrationlink: 'Iscriviti ora'
      }
    },
    layout: {
      slides: {
        efficiency: {
          title: 'Efficienza',
          description:
            "Tutti gli strumenti per controllo della qualita' e la valutazione della comunicazione sono raccolti su un'unica piattaforma"
        },
        motivation: {
          title: 'Motivazione',
          description: 'Coinvolgi, sviluppa e motiva i dipendenti'
        },
        simplicity: {
          title: "Semplicita'",
          description:
            "Elimina fogli di calcolo e strumenti di valutazione e controllo della qualita' obsoleti"
        },
        analytics: {
          title: 'Analitica',
          description: "Analizza gli indicatori di qualita' e trova nuove possibilita' di crescita"
        }
      }
    }
  }
};
