import React from 'react';
import SRow from 'components/Standard/SRow';
import SCol from 'components/Standard/SCol';
import { FlagOutlined, FlagFilled } from '@ant-design/icons';
import SButton from 'components/Standard/SButton';
import { FLAGS_TO_COLORS_RELATIONS } from 'core/utils/constants';
import styled from 'styled-components';

const Flags = ({ templateState, setTemplateState }) => {
  return (
    <SRow type="flex" align="middle" justify="space-between" width="100px">
      <SCol span={8}>
        <StyledButton
          size="icon"
          icon={templateState?.metadata?.ratingFlag === 'red' ? <FlagFilled /> : <FlagOutlined />}
          style={{
            color: FLAGS_TO_COLORS_RELATIONS.red
          }}
          onClick={() => setTemplateState({ ...templateState, metadata: { ratingFlag: 'red' } })}
        />
      </SCol>
      <SCol span={8}>
        <StyledButton
          size="icon"
          icon={
            templateState?.metadata?.ratingFlag === 'yellow' ? <FlagFilled /> : <FlagOutlined />
          }
          style={{
            color: FLAGS_TO_COLORS_RELATIONS.yellow
          }}
          onClick={() => setTemplateState({ ...templateState, metadata: { ratingFlag: 'yellow' } })}
        />
      </SCol>
      <SCol span={8}>
        <StyledButton
          size="icon"
          icon={templateState?.metadata?.ratingFlag === 'green' ? <FlagFilled /> : <FlagOutlined />}
          style={{
            color: FLAGS_TO_COLORS_RELATIONS.green
          }}
          onClick={() => setTemplateState({ ...templateState, metadata: { ratingFlag: 'green' } })}
        />
      </SCol>
    </SRow>
  );
};

const StyledButton = styled(SButton)`
  &.ant-btn {
    margin-right: 20px;
    border: none;
    box-shadow: none;
    background-color: transparent;
    width: 24px !important;
  }

  &.ant-btn:hover {
    background-color: transparent;
  }

  &.ant-btn:focus {
    background-color: transparent;
  }
`;

export default Flags;
