export default {
  userAnalyticsPage: {
    analyticsTabs: {
      analytics: 'Analítica',
      rates: 'Evaluaciones',
      flagsAndComments: 'Comentarios y banderas'
    },
    widgets: {
      checklistTooltip: 'Analítica por la lista a chequear',
      questionTooltip: 'Analítica por pregunta',
      reviewsCount: {
        name: 'Total de evaluaciones',
        description: 'Cantidad total de evaluaciones por la lista a chequear en el período elegido'
      },
      averageScore: {
        name: 'Evaluación media',
        description:
          'Evaluación media por la lista a chequear calculado por todas las evaluciones del período elegido'
      },
      lowPercentage: {
        name: 'Crítico',
        description: 'Cantidad de evaluaciones en la zona roja'
      },
      mediumPercentage: {
        name: 'Normal',
        description: 'Cantidad de evaluaciones en la zona amarilla'
      },
      highPercentage: {
        name: 'Exelente',
        description: 'Cantidad de evaluaciones en la zona verde'
      },
      flags: {
        name: 'Banderas',
        description:
          'Cantidad de banderas agregadas a las evaluaciones en la lista a cjequear elegida'
      }
    },
    userReviewsTable: {
      columns: {
        clientInteractionStartedAt: 'Fecha y hora',
        clientPhoneNumber: 'Número de teléfono',
        clientInteractionDirection: 'Dirección',
        clientInteractionDuration: 'Duración',
        checklistScore: 'Evaluación',
        comments: 'Banderas y comentarios',
        checklistDefinition: 'Lista a chequear',
        tasks: 'Etiquetas',
        createdAt: 'Fecha de evaluación',
        reviewerLastName: 'Autor de la evaluación'
      },
      reviewsCount: 'Evaluaciones:',
      colorZoneSelect: { placeholder: 'Zonas de evaluación' }
    },
    reviewsWithComments: {
      flagsAndCommentsCount: 'Cantidad total de banderas y comentarios:',
      flagsAndCommentsPlaceholder: 'Banderas y comentarios',
      loading: {
        load: 'Cargando comentarios... 😤',
        empty: 'Lo sentimos, no se encontraroon comentarios 😟',
        all: 'Es todo 😊'
      }
    },
    reviewPreview: {
      date: 'Fecha:',
      type: 'Tipo:',
      value: 'Evaluación:'
    },
    analyticsChartsPanel: { empty: 'No se encontraron evaluaciones por esos filtros 😓' },
    mainControlsPanel: {
      checklistDefinitionsEmpty: 'No hay listas a chequear',
      questionValues: 'Evaluación por todas las preguntas'
    }
  }
};
