import { MessageOutlined } from '@ant-design/icons';
import { Col, Divider, Row } from 'antd';
import UserPreview from 'components/UserPreview';
import styled from 'styled-components';

export const StyledCol = styled(Col)`
  &.ant-col {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  span {
    color: ${props => props.color};
  }
`;

export const StyledSpan = styled.span`
  margin-right: 16px;
  color: ${props => props.color};
`;

export const SingleIcon = styled(MessageOutlined)`
  &&.anticon {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 0px 0 4px;
    cursor: pointer;
  }
`;

export const StyleIcon = iconComponent => styled(iconComponent)`
  &.anticon {
    color: ${props => props.color};
    margin-right: 4px;
  }
`;

export const FlagRow = styled(Row)`
  &.ant-row {
    display: flex;
  }
`;

export const StyledDiv = styled.div`
  max-height: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledUserPreview = styled(UserPreview)`
  display: inline;
  margin: 0;
`;

export const CommentContainer = styled.div`
  border-left: ${props => (props.showColor ? `5px solid ${props.color}` : 'none')};
  border-radius: 4px;
  padding-left: 4px;
`;

export const StyledRow = styled(Row)`
  &.ant-row {
    display: flex;
    align-items: center;
  }
`;

export const StyledDivider = styled(Divider)`
  &.ant-divider {
    margin: 2px 0 8px 0;
  }
`;
