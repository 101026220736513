import { Folder, PlusCircle } from 'react-feather';
import { Button, Col, Menu, Radio, Row } from 'antd';
import TimeSpecificSelector from 'components/Inputs/TimeSpecificSelector';
import SCard from 'components/Standard/SCard';
import { SESSIONS_DIRECTIONS } from 'core/utils/constants';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { updateCalibrationSessionSettingsForm } from 'redux/ui/calibrationSessionSettingsModal/reducer';
import { setTimeRange } from 'redux/ui/calibrationsPage/reducer';
import styled from 'styled-components';
import uniqid from 'uniqid';
import Icon from 'components/Icon';

const CalibrationsFilters = ({
  timeRange,
  setTimeRange,
  tab,
  history,
  updateCalibrationSessionSettingsForm
}) => {
  const { t } = useTranslation();

  const onChangeTab = e => {
    history.push(`${e.target.value}`);
  };

  return (
    <SCard>
      <Row type="flex" justify="space-between">
        <Col style={{ flex: 'auto' }}>
          <Row type="flex">
            <Col>
              <Radio.Group value={tab} onChange={onChangeTab}>
                {Object.values(SESSIONS_DIRECTIONS).map(item => (
                  <Radio.Button value={item.value}>{t(item.title)}</Radio.Button>
                ))}
              </Radio.Group>
            </Col>
            <Col>
              <StyledMenu mode="horizontal" selectedKeys={[tab]}>
                <Menu.Item key="folders">
                  <Link to="/calibrations/folders">
                    <Icon icon={Folder} />
                    <span>{t('calibrationsPage.buttons.viewFolderItems')}</span>
                  </Link>
                </Menu.Item>
              </StyledMenu>
            </Col>
          </Row>
        </Col>
        <Col>
          <Row type="flex" justify="end" gutter={[8, 0]} style={{ marginBottom: '-4px' }}>
            <Col>
              <TimeSpecificSelector value={timeRange} onChange={setTimeRange} />
            </Col>
            <Col>
              <Button
                block
                type="primary"
                icon={<Icon icon={PlusCircle} />}
                onClick={() => updateCalibrationSessionSettingsForm({ id: uniqid() })}
              >
                {t('calibrationsPage.buttons.createSession')}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </SCard>
  );
};

const mapStateToProps = state => ({
  timeRange: state.uiCalibrationsPage.timeRange
});

const mapDispatchToProps = {
  setTimeRange,
  updateCalibrationSessionSettingsForm
};

const StyledMenu = styled(Menu)`
  &&.ant-menu-horizontal {
    display: flex;
    align-items: center;
    border: none;
    line-height: 16px;
    height: 100%;

    & > .ant-menu-item-active,
    & > .ant-menu-item:hover,
    & > .ant-menu-item-selected {
      border: none !important;
    }

    & > li {
      border: transparent;
    }
  }
`;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CalibrationsFilters));
