import { Trash2, MoreVertical } from 'react-feather';
import { Dropdown, Menu, Modal, Typography } from 'antd';
import EditableLabel from 'components/Inputs/EditableLabel';
import SCard from 'components/Standard/SCard';
import withConditionalRender from 'components/WithConditionalRender/withConditionalRender';
import { PERMISSIONS } from 'core/utils/constants';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { reviewLibraryItemsResource } from 'redux/resources/reviewLibraryItems';
import { setEditingItem } from 'redux/ui/libraryPage/operations';
import { setCurrentInteractionId, updateEditingItem } from 'redux/ui/libraryPage/reducer';
import Icon from 'components/Icon';
import {
  ClientInteractionContainer,
  ClientInteractionContent,
  ClientInteractionIcon,
  SettingButton,
  SettingContainer
} from './styled';

const { confirm } = Modal;

const ClientInteractionPreview = ({
  id,
  selectInteraction,
  isSelected,
  createdAt,
  name,
  onEdit,
  onDelete,
  isEditing,
  updateEditingItem,
  updateItem
}) => {
  const { t } = useTranslation();
  const handleClick = ({ key }) => {
    switch (key) {
      case 'edit':
        onEdit(id);
        break;
      case 'delete':
        confirm({
          title: t('libraryPage.libraryItem.deleteItemModal.title'),
          okText: t('libraryPage.libraryItem.deleteItemModal.buttons.delete'),
          cancelText: t('libraryPage.libraryItem.deleteItemModal.buttons.cancel'),
          okType: 'danger',
          onOk: async () => {
            if (isSelected) {
              selectInteraction(null);
            }
            return onDelete({ id });
          }
        });
        break;
      default:
        break;
    }
  };
  const menu = (
    <Menu onClick={handleClick}>
      <Menu.Item key="edit">{t('libraryPage.libraryItem.dropdown.edit')}</Menu.Item>
      <Menu.Item key="delete">
        <Icon icon={Trash2} />
        {t('libraryPage.libraryItem.dropdown.delete')}
      </Menu.Item>
    </Menu>
  );

  const ConditionalSettingContainer = withConditionalRender(
    { allowedPermissions: [PERMISSIONS.CAN_MANAGE_LIBRARY] },
    SettingContainer
  );

  return (
    <SCard bodyPadding="0px">
      <ClientInteractionContainer onClick={() => selectInteraction(id)} isSelected={isSelected}>
        <ClientInteractionIcon />
        <ClientInteractionContent>
          {isEditing ? (
            <EditableLabel
              initialState="edit"
              value={name}
              onSave={(name, { final }) => {
                updateEditingItem({ name });
                if (final) {
                  updateItem({ id, name });
                }
              }}
            />
          ) : (
            name || t('libraryPage.libraryItem.emptyName')
          )}

          <Typography.Text type="secondary">
            {`${t('libraryPage.libraryItem.added')} ${moment(createdAt).format('DD/MM/YYYY')}`}
          </Typography.Text>
        </ClientInteractionContent>
        <ConditionalSettingContainer onClick={e => e.stopPropagation()}>
          <Dropdown overlay={menu} trigger={['click']}>
            <SettingButton
              icon={<Icon icon={MoreVertical} />}
              background={isSelected && '#EAEAEA '}
            />
          </Dropdown>
        </ConditionalSettingContainer>
      </ClientInteractionContainer>
    </SCard>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { editingItem, editingItemId } = state.uiLibraryPage;
  const isEditing = editingItemId === ownProps.id;
  const { name, createdAt, reviewId } = isEditing
    ? editingItem
    : state.reviewLibraryItemsResource.byIds[ownProps.id];

  return {
    clientInteraction: state.reviewsResource.byIds[reviewId],
    name,
    isSelected: ownProps.id === state.uiLibraryPage.currentClientInteractionId,
    isEditing,
    createdAt
  };
};

const mapDispatchToProps = {
  selectInteraction: setCurrentInteractionId,
  onDelete: reviewLibraryItemsResource.operations.deleteById,
  updateItem: reviewLibraryItemsResource.operations.updateById,
  onEdit: setEditingItem,
  updateEditingItem
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientInteractionPreview);
