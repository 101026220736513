import api from 'core/api';
import decamilize from 'decamelize-keys-deep';

export const exportCommunicationTable = exportParams => {
  return async dispatch => {
    const response = await api.exportCommunicationTable(decamilize(exportParams));
    return response;
  };
};

export const exportTeamPage = exportParams => {
  return async dispatch => {
    const response = await api.exportTeamPage(decamilize(exportParams));
    return response;
  };
};

export const exportToExcel = exportParams => {
  return async dispatch => {
    const response = await api.exportToExcel(exportParams);
    return response;
  };
};
