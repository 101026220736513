import {
  Button,
  Col,
  Dropdown,
  List,
  Menu,
  Modal,
  Row,
  Select,
  Form,
  message,
  Skeleton
} from 'antd';
import Icon from 'components/Icon';
import SCard from 'components/Standard/SCard';
import SCol from 'components/Standard/SCol';
import SRow from 'components/Standard/SRow';
import SSelect from 'components/Standard/SSelect';
import UserPreview from 'components/UserPreview';
import { PERMISSIONS } from 'core/utils/constants';
import { isEmpty, isEqual, sortBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { MoreVertical } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { usersResource } from 'redux/resources/users';
import { getCurrentUserPermissions, getUsersWithRoleAndUnit } from 'redux/selectors/users';
import { UnitEmployeesModal } from './UnitEmployeesModal';
import UnitSettings from './UnitSettings';

const cardStyles = { maxHeight: 'calc(100vh - 172px)', overflowY: 'auto' };
const { Option } = Select;

const UnitEmployeesList = () => {
  const [loading, setLoading] = useState(false);
  const [addingUserToUnitId, setAddingUserToUnit] = useState(null);
  const [movingUserIdToUnit, setMovingUserIdToUnit] = useState(null);
  const selectedUnitId = useSelector(state => state.uiOrganizationPage?.selectedUnit?.id);

  const [form] = Form.useForm();
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const usersWithPermissions = useSelector(state => {
    return sortBy(
      Object.values(getUsersWithRoleAndUnit(state)).filter(user => {
        return user.unitId === selectedUnitId && user.active;
      }),
      ['roleId']
    );
  }, isEqual);

  const loadUnitUsers = async () => {
    setLoading(true);
    try {
      await dispatch(
        usersResource.operations.load({
          filters: { unitsIds: selectedUnitId },
          pagination: 'false'
        })
      );
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    loadUnitUsers();
  }, [selectedUnitId]);

  const rolesByIds = useSelector(state => state.rolesResource.byIds, isEqual);
  const unitsByIds = useSelector(state => state.unitsResource.byIds, isEqual);

  const canChangeRole = useSelector(state =>
    getCurrentUserPermissions(state)?.includes(PERMISSIONS.CAN_MANAGE_ORGANIZATION_STRUCTURE)
  );

  const updateUserRole = async ({ user, roleId }) => {
    try {
      await dispatch(usersResource.operations.updateById({ id: user.id, roleId }));
    } catch (error) {
      console.log(error);
    }
  };

  const moveUserToUnit = async ({ unitId }) => {
    try {
      await dispatch(usersResource.operations.updateById({ id: movingUserIdToUnit, unitId }));
      setMovingUserIdToUnit(null);
      message.success(t('messages.success.userMoved'));
    } catch (error) {
      message.error(t('messages.error.failedMoveUser'));
    }
  };

  const handlMenuClick = (e, userId) => {
    switch (e.key) {
      case 'move':
        setMovingUserIdToUnit(userId);
        break;

      default:
    }
  };

  const getMenu = userId => (
    <Menu onClick={e => handlMenuClick(e, userId)}>
      <Menu.Item key="move">
        <span>{t('organizationStructure.moved')}</span>
      </Menu.Item>
    </Menu>
  );

  const cancelAddingEmployeesToUnit = () => {
    setAddingUserToUnit(null);
  };

  const addEmployeesToUnit = async usersIds => {
    await Promise.all(
      usersIds.map(id =>
        dispatch(usersResource.operations.updateById({ id, unitId: addingUserToUnitId }))
      )
    );
    setAddingUserToUnit(null);
  };

  return (
    <SCard style={cardStyles}>
      <Row gutter={[0, 16]}>
        <Col span={24}>
          <UnitSettings setAddingUserToUnit={setAddingUserToUnit} />
        </Col>
        {loading ? (
          <Skeleton active />
        ) : (
          <Col span={24}>
            <List
              bordered={false}
              split={false}
              size="small"
              dataSource={usersWithPermissions}
              style={{ marginTop: '-8px' }}
              renderItem={user => (
                <List.Item style={{ padding: '8px 0' }}>
                  <Col span={24} style={{ padding: '0' }}>
                    <SRow
                      type="flex"
                      justify="space-between"
                      align="middle"
                      gutter={[12, 0]}
                      style={{ marginRight: '0' }}
                      flexWrap="nowrap"
                    >
                      <Col span={14}>
                        <UserPreview userId={user.id} showAvatar disabled />
                      </Col>
                      <SCol display="flex" justifyContent="flex-end" flex="auto">
                        <SRow type="flex" justify="end" width="100%">
                          {canChangeRole ? (
                            <SSelect
                              maxWidth="168px"
                              width="100%"
                              value={user.roleId}
                              onSelect={roleId => updateUserRole({ user, roleId })}
                            >
                              {Object.values(rolesByIds).map(role => (
                                <Option key={role.id} value={role.id}>
                                  {role.name}
                                </Option>
                              ))}
                            </SSelect>
                          ) : (
                            rolesByIds[user.roleId]?.name
                          )}
                        </SRow>
                      </SCol>
                      <Col span={1}>
                        <Dropdown
                          overlay={getMenu(user.id)}
                          trigger={['click']}
                          placement="bottomRight"
                        >
                          <Button
                            type="link"
                            size="small"
                            icon={<Icon icon={MoreVertical} color="var(--blue_2)" size="24px" />}
                            strokeWidth="1"
                          />
                        </Dropdown>
                      </Col>
                    </SRow>
                  </Col>
                </List.Item>
              )}
            />
          </Col>
        )}
      </Row>

      <UnitEmployeesModal
        unitId={addingUserToUnitId}
        onAdd={addEmployeesToUnit}
        onCancel={cancelAddingEmployeesToUnit}
      />
      <Modal
        visible={!isEmpty(movingUserIdToUnit)}
        title={t('organizationStructure.moveToUnit')}
        onOk={form.submit}
        onCancel={() => setMovingUserIdToUnit(null)}
        okText={t('organizationStructure.tabs.units.modal.ok')}
        cancelText={t('organizationStructure.tabs.units.modal.cancel')}
      >
        <Form
          form={form}
          name="moveUserToUnit"
          initialValues={{ unitId: selectedUnitId }}
          scrollToFirstError
          layout="vertical"
          onFinish={moveUserToUnit}
        >
          <Form.Item label={t('organizationStructure.tabs.units.modal.form.name')} name="unitId">
            <Select placeholder={t('organizationStructure.tabs.units.modal.form.namePlaceholder')}>
              {Object.values(unitsByIds).map(unit => (
                <Option value={unit.id} key={unit.id}>
                  {unit.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </SCard>
  );
};

export default UnitEmployeesList;
