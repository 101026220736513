import { Col } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { actions } from 'redux/lists/clientInteractionsList';
import { toogleFilterGroup } from 'redux/ui/clientInteractions/reducer';
import { CategoryName, FilterContainer } from '../styled';
import ShareSettings from './ShareSettings';
import Tables from './Tables';

const TableConfigurations = ({ isVisibleShareSettings }) => {
  const { t } = useTranslation();

  return (
    <>
      <Col span={24}>
        <Col span={24}>
          <CategoryName>
            {t('clientInteractionsPage.tableFilters.tableConfigurations.title')}
          </CategoryName>
        </Col>
      </Col>

      <Col span={24}>
        <FilterContainer>
          <Tables />
        </FilterContainer>

        {isVisibleShareSettings && (
          <FilterContainer>
            <ShareSettings />
          </FilterContainer>
        )}
      </Col>
    </>
  );
};

const mapStateToProps = state => {
  const { collapsedFilterGroups, isVisibleShareSettings } = state.uiClientInteractions;

  return {
    collapsedFilterGroups,
    isVisibleShareSettings
  };
};

const mapDispatchToProps = {
  updateFilters: actions.updateFilters,
  toogleFilterGroup
};

export default connect(mapStateToProps, mapDispatchToProps)(TableConfigurations);
