export default {
  general: {
    save: 'Salva',
    cancel: 'Annulla',
    canceling: 'Annulla',
    edit: 'Modificare',
    answer: 'Rispondere',
    delete: 'Elimina',
    disagree: 'Disaccordo',
    accept: 'Accettare',
    accepted: 'Accettare',
    reject: 'Rifiutare',
    rejected: 'Rifiutare',
    from: 'Da',
    to: 'A',
    time: 'Tempo',
    hidden: 'Nascosto',
    visibleToAll: 'Disponibile per tutti',
    update: 'Aggiornare',
    add: 'Aggiungere',
    redFlag: 'Rosso',
    yellowFlag: 'Giallo',
    greenFlag: 'Verde',
    withoutFlag: 'Senza bandiera',
    uploadImage: 'Allega immagine',
    loading: 'Caricamento in corso',
    requiredField: 'Campo obbligatorio',
    continue: 'Continuare',
    date: 'Data',
    dateAndTime: 'Data e ora',
    direction: 'Direzione',
    number: 'Numero',
    phoneNumber: 'Numero di telefono',
    tags: 'Tag',
    reviewDate: 'Data di valutazione',
    applicationDate: 'Data di applicazione',
    applicator: 'Archiviato',
    checklistDefinition: 'Modulo di valutazione',
    checklistDefinitions: 'Moduli di valutazione',
    criterion: 'Criterio',
    criteries: 'Criteri',
    clientInteractionType: 'Tipo',
    clientInteractionTypes: 'Tipi',
    status: 'Stato',
    statuses: 'Stati',
    users: 'Utenti',
    executor: 'Esecutore',
    executors: 'Esecutori',
    role: 'Ruolo',
    roles: 'Ruoli',
    changed: 'Cambiato',
    view: 'Guadare',
    unit: 'Dipartimento',
    units: 'Dipartimenti',
    settings: 'Impostazioni',
    analytics: 'Analitica',
    yes: 'Si',
    no: 'No',
    name: 'Nome e patronimico',
    lastName: 'Cognome',
    email: 'E-mail',
    goTo: 'Vai a',
    employees: 'Dipendenti',
    employee: 'Dipendenti',
    all: 'Tutti',
    initials: 'Nome e cognome',
    duration: 'Durata',
    score: 'Valutazione',
    flagsAndComments: 'Bandiere e commenti',
    close: 'Finire',
    stop: 'Fermare',
    download: 'Scarica',
    total: 'Totale'
  }
};
