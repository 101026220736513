import React, { useState } from 'react';
import SRow from 'components/Standard/SRow';
import SCol from 'components/Standard/SCol';
import SText from 'components/Standard/SText';
import SButton from 'components/Standard/SButton';
import { Checkbox, message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { commentTemplatesResource } from 'redux/resources/commentTemplates';
import { isEmpty, get, isEqual } from 'lodash';
import { actions } from 'redux/lists/commentTemplatesListReducer';
import { useTranslation } from 'react-i18next';
import TemplateEditor from './TemplateEditor';

const defaultCommentState = {
  text: '',
  metadata: {
    ratingFlag: ''
  },
  isPublicTemplate: false,
  hidden: false
};

const CreateTemplateForm = ({ setOpenEditor, templateToUpdate }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentUserId = useSelector(state => state.reduxTokenAuth.currentUser.attributes.id);
  const [templateState, setTemplateState] = useState(templateToUpdate || defaultCommentState);
  const uploadedFilesByIds = useSelector(state => state.uploadedFilesResource.byIds, isEqual);

  const { onCommentTemplateCreate } = actions;

  const updateCommentTemplate = async () => {
    try {
      setOpenEditor(false);
      const res = await dispatch(
        commentTemplatesResource.operations.updateById({
          authorId: currentUserId,
          id: templateState?.id,
          text: templateState?.text,
          metadata: {
            ratingFlag: templateState?.metadata?.ratingFlag
          },
          isPublicTemplate: templateState?.isPublicTemplate,
          hidden: templateState?.hidden,
          uploadedFilesIds: get(templateState, 'uploadedFiles', []).reduce((acc, file) => {
            if (!Object.keys(uploadedFilesByIds).includes(file?.id)) return acc;
            return [...acc, file?.id];
          }, [])
        })
      );
      if (res) message.success(t('messages.success.templateUpdated'));
    } catch (error) {
      console.log(error);
      message.warning(t('messages.warning.failedTemplateUpdate'));
    }
  };

  const saveCommentTemplate = async () => {
    try {
      const res = await dispatch(
        commentTemplatesResource.operations.create({
          authorId: currentUserId,
          ...templateState,
          uploadedFilesIds: get(templateState, 'uploadedFiles', []).map(({ id }) => id)
        })
      );
      setOpenEditor(false);
      if (res) {
        dispatch(onCommentTemplateCreate(res));
        message.success(t('messages.success.templateAdded'));
      }
    } catch (error) {
      console.log(error);
      message.warning(t('messages.warning.failedTemplateSave'));
    }
  };

  return (
    <SCol span={24}>
      <SRow>
        <SCol span={24}>
          <TemplateEditor templateState={templateState} setTemplateState={setTemplateState} />
        </SCol>
        <SCol span={24}>
          <SRow type="flex" align="middle" justify="space-between">
            <SCol>
              <Checkbox
                onChange={e =>
                  setTemplateState({ ...templateState, isPublicTemplate: e.target.checked })
                }
                checked={templateState.isPublicTemplate}
              >
                <SText type="secondary">{t('general.visibleToAll')}</SText>
              </Checkbox>
              <Checkbox
                onChange={e => setTemplateState({ ...templateState, hidden: e.target.checked })}
                checked={templateState.hidden}
              >
                <SText type="secondary">{t('general.hidden')}</SText>
              </Checkbox>
            </SCol>
            <SCol>
              <SButton onClick={() => setOpenEditor(false)}>{t('general.cancel')}</SButton>
              <SButton
                marginLeft="10px"
                type="primary"
                onClick={templateToUpdate ? updateCommentTemplate : saveCommentTemplate}
                disabled={isEmpty(templateState.text)}
              >
                {templateToUpdate ? t('general.update') : t('general.add')}
              </SButton>
            </SCol>
          </SRow>
        </SCol>
      </SRow>
    </SCol>
  );
};

export default CreateTemplateForm;
