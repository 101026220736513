import { Trash2, ChevronDown, ChevronUp } from 'react-feather';
import { Col, Popconfirm, Row, Spin, Typography } from 'antd';
import { get, isNil } from 'lodash';
import Icon from 'components/Icon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { dashboardRowsResource } from 'redux/resources/dashboardRows';
import { dashboardsResource } from 'redux/resources/dashboards';
import {
  MoreRowInfoButton,
  MoveButtonsBlock,
  SettingsRow,
  StyleMoveIcon,
  WidgetInfoCol
} from './styled';

const UpIcon = StyleMoveIcon(Icon);
const DownIcon = StyleMoveIcon(Icon);

const Rows = ({
  dashboardsByIds,
  dashboardRowsByIds,
  activeDashboardId,
  moveUpRow,
  moveDownRow,
  deleteRow,
  loadingRows
}) => {
  const { Text } = Typography;
  const { t } = useTranslation();

  const activeDashboardRowsIds = get(dashboardsByIds, `${activeDashboardId}.rowsIds`, []).filter(
    row => dashboardRowsByIds[row]
  );

  const handleMenuClick = (actionType, rowId) => {
    if (actionType === 'moveUpRow') moveUpRow({ id: rowId });
    if (actionType === 'moveDownRow') moveDownRow({ id: rowId });
    if (actionType === 'deleteRow') {
      deleteRow({ id: rowId });
    }
  };

  const rowName = row => {
    const rowLenght = Object.keys(row.widgetsIds).length;
    return rowLenght === 1
      ? t('dashboardPage.dashboard.drawer.rows.rowsCount1')
      : `${t('dashboardPage.dashboard.drawer.rows.rowsCountPT1')} ${rowLenght} ${t(
          'dashboardPage.dashboard.drawer.rows.rowsCountPT2'
        )}`;
  };

  return (
    <>
      <Spin spinning={!isNil(loadingRows) && loadingRows === true}>
        <SettingsRow>
          <Col span={24}>
            <Text strong>{t('dashboardPage.dashboard.drawer.rows.title')}</Text>
          </Col>
          {activeDashboardRowsIds.map(activeDashboardRowId => (
            <Col span={24}>
              <Row type="flex" align="middle" justify="space-between" gutter={[8, 8]}>
                <WidgetInfoCol span={22}>
                  <Row type="flex" justify="space-between">
                    <Text>{rowName(dashboardRowsByIds[activeDashboardRowId])}</Text>
                    <MoveButtonsBlock>
                      <UpIcon
                        icon={ChevronUp}
                        onClick={() =>
                          handleMenuClick('moveUpRow', dashboardRowsByIds[activeDashboardRowId]?.id)
                        }
                      />

                      <DownIcon
                        icon={ChevronDown}
                        onClick={() =>
                          handleMenuClick(
                            'moveDownRow',
                            dashboardRowsByIds[activeDashboardRowId]?.id
                          )
                        }
                      />
                    </MoveButtonsBlock>
                  </Row>
                </WidgetInfoCol>
                <Col>
                  <Popconfirm
                    title={t('dashboardPage.dashboard.drawer.rows.confirmDelete.title')}
                    placement="topRight"
                    onConfirm={() =>
                      handleMenuClick('deleteRow', dashboardRowsByIds[activeDashboardRowId]?.id)
                    }
                    okText={t('dashboardPage.dashboard.drawer.rows.confirmDelete.ok')}
                    cancelText={t('dashboardPage.dashboard.drawer.rows.confirmDelete.cancel')}
                  >
                    <MoreRowInfoButton icon={<Icon icon={Trash2} />} />
                  </Popconfirm>
                </Col>
              </Row>
            </Col>
          ))}
        </SettingsRow>
      </Spin>
    </>
  );
};

const mapStateToProps = state => {
  return {
    dashboardRowsByIds: state.dashboardRowsResource.byIds,
    loadingRows: state.dashboardsResource.loadByIdStarted,
    activeDashboardId: state.uiDashboard.activeDashboardId
  };
};

const mapDispatchToProps = {
  moveUpRow: dashboardRowsResource.operations.moveUpRowById,
  moveDownRow: dashboardRowsResource.operations.moveDownRowById,
  deleteRow: dashboardRowsResource.operations.deleteById,
  loadDashboard: dashboardsResource.operations.loadById
};

export default connect(mapStateToProps, mapDispatchToProps)(Rows);
