export default {
  errorPages: {
    forbidden: { title: 'Access denied. \n Contact your Qolio administrator.' },
    internalServerError: { title: 'Something went wrong. Try reloading the page.' },
    loadingPage: { title: 'Loading' },
    mobileBlocked: {
      title: 'Oops...',
      description: 'Mobile version coming soon.'
    },
    notFound: { title: 'Not found' },
    unauthorized: { title: 'You must login to continue working' },
    buttons: {
      login: 'Login',
      goBack: 'Back',
      goToHomePage: 'Home page'
    }
  }
};
