import { DeleteOutlined, EditOutlined, ExportOutlined, FileAddOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu, message, Modal, Tooltip } from 'antd';
import camelcase from 'camelcase';
import SButton from 'components/Standard/SButton';
import SText from 'components/Standard/SText';
import SCol from 'components/Standard/SCol';
import SRow from 'components/Standard/SRow';
import SCard from 'components/Standard/SCard';
import withConditionalRender from 'components/WithConditionalRender/withConditionalRender';
import { CHECKLIST_MANAGER_STATES, PERMISSIONS } from 'core/utils/constants';
import { getScore } from 'core/utils/ratingsCalculations';
import { every, get, intersection, isEmpty, isNil } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Prompt, withRouter } from 'react-router-dom';
import { reviewsResource } from 'redux/resources/reviews';
import { getChecklistDefinitionQuestions } from 'redux/selectors/checklistItems/checklistItems';
import { getChecklistsDefinitionsByIds } from 'redux/selectors/checklists';
import { getCurrentUser } from 'redux/selectors/users';
import { openAddToCalibrationModal } from 'redux/ui/addToCalibrationModal/reducer';
import { clearCurrentChecklist, setChecklistManagerState } from 'redux/ui/checklistManager/reducer';
import { setCommunication } from 'redux/ui/clientInteractionDrawer/reducer';
import { updateTableRow } from 'redux/ui/clientInteractions/operations';
import { MoreVertical } from 'react-feather';
import Icon from 'components/Icon';
import { getAppellationsByIds } from 'redux/selectors/appeals';
import { AppealHead } from './components/AppealHead';

const ChecklistManagerHead = ({
  appeal,
  checklistDefinition,
  checklistManagerState,
  questions = [],
  questionIdToAnswerValue,
  currentChecklist,
  onSubmit,
  setChecklistManagerState,
  clearCurrentChecklist,
  openAddToCalibrationModal,
  loadingSubmitButton,
  isAbleToEdit,
  deleteReview,
  id,
  history,
  currentUserId,
  communication,
  reivewAuthorId,
  setCommunication,
  fromDrawer,
  updateTableRow
}) => {
  const { t } = useTranslation();
  const answeredQuestions = Object.values(questionIdToAnswerValue);

  const { confirm } = Modal;

  const allQuestionsAnswered =
    questions.length === answeredQuestions.length &&
    every(answeredQuestions, value => !isNil(value));

  const onEditButton = () => setChecklistManagerState(CHECKLIST_MANAGER_STATES.EDITING);

  const onSubmitButton = async event => {
    event.preventDefault();

    if (isEmpty(currentChecklist)) {
      return Modal.confirm({
        title: t('components.checklistManager.checklistManagerHead.emptyChecklistModal.title'),
        content: t(
          'components.checklistManager.checklistManagerHead.emptyChecklistModal.description'
        ),
        okText: t('components.checklistManager.checklistManagerHead.emptyChecklistModal.ok'),
        cancelText: t(
          'components.checklistManager.checklistManagerHead.emptyChecklistModal.cancel'
        ),
        onOk: onSubmit
      });
    }

    if (!allQuestionsAnswered)
      return Modal.confirm({
        title: t('components.checklistManager.checklistManagerHead.allQuestionsAnswered.title'),
        content: t(
          'components.checklistManager.checklistManagerHead.allQuestionsAnswered.description'
        ),
        okText: t('components.checklistManager.checklistManagerHead.allQuestionsAnswered.ok'),
        cancelText: t(
          'components.checklistManager.checklistManagerHead.allQuestionsAnswered.cancel'
        ),
        onOk: onSubmit
      });

    return onSubmit();
  };

  const questionsWithValuesAndBindings = questions.map(question => ({
    ...question,
    value: questionIdToAnswerValue[question.id],
    percentage: get(question, 'binding.percentage', 1)
  }));

  const score = getScore({
    checklist: currentChecklist,
    checklistDefinition,
    checklistManagerState:
      appeal && !appeal.questionObjectionsProcessed
        ? CHECKLIST_MANAGER_STATES.EDITING
        : checklistManagerState,
    questionsWithValuesAndBindings
  });

  const DeleteReviewMenuItem = withConditionalRender(
    { hide: currentUserId !== reivewAuthorId },
    Menu.Item
  );

  const ChangeCurrentChecklistMenuItem = withConditionalRender(
    { hide: reivewAuthorId && currentUserId !== reivewAuthorId },
    Menu.Item
  );

  const NewReviewMenuItem = withConditionalRender(
    {
      hide: !reivewAuthorId,
      allowedPermissions: [PERMISSIONS.CAN_MAKE_REVIEW]
    },
    Menu.Item
  );

  const AddToCalibrationMenuItem = withConditionalRender(
    {
      hide: history.location.pathname.match(/calibration/),
      allowedPermissions: [PERMISSIONS.CAN_MANAGE_CALIBRATION_SESSIONS]
    },
    Menu.Item
  );

  const navigateToClientInteraction = () => {
    const clientInteractionTypeToLink = {
      chat: id => `/text-communications/${id}`,
      textCommunication: id => `/text-communications/${id}`,
      phoneCall: id => `/calls/${id}`,
      ticket: id => `/text-communications/${id}`,
      email: id => `/text-communications/${id}`
    };

    const { communicationType, clientInteractionType, id } = communication;

    return fromDrawer
      ? setCommunication({ type: clientInteractionType, id })
      : history.push(
          clientInteractionTypeToLink[camelcase(communicationType || clientInteractionType)](id)
        );
  };

  const addToCalibration = () => openAddToCalibrationModal();

  const handleTableMenuClick = e => {
    switch (e.key) {
      case 'changeCurrentChecklist':
        return confirm({
          title: t('components.checklistManager.checklistManagerHead.confirmChangeChecklist.title'),
          content: t(
            'components.checklistManager.checklistManagerHead.confirmChangeChecklist.description'
          ),
          okText: t('components.checklistManager.checklistManagerHead.confirmChangeChecklist.ok'),
          cancelText: t(
            'components.checklistManager.checklistManagerHead.confirmChangeChecklist.cancel'
          ),
          onOk: () => {
            setChecklistManagerState(CHECKLIST_MANAGER_STATES.INITIAL);
            clearCurrentChecklist();
          }
        });

      case 'navigateToClientInteraction':
        return navigateToClientInteraction();

      case 'addToCalibration':
        return addToCalibration();
      case 'deleteReivew':
        return confirm({
          title: t('components.checklistManager.checklistManagerHead.confirmDeleteChecklist.title'),
          content: t(
            'components.checklistManager.checklistManagerHead.confirmDeleteChecklist.description'
          ),
          okText: t('components.checklistManager.checklistManagerHead.confirmDeleteChecklist.ok'),
          cancelText: t(
            'components.checklistManager.checklistManagerHead.confirmDeleteChecklist.cancel'
          ),
          onOk: async () => {
            try {
              await deleteReview({ id });

              if (fromDrawer) {
                setCommunication({});
                // * delete from table if needed
                updateTableRow({ clientInteractionId: communication.id });
              } else {
                history.push('/client-interactions');
              }

              message.success(
                t(
                  'components.checklistManager.checklistManagerHead.confirmDeleteChecklist.messages.reviewSuccessfullyDeleted'
                )
              );
            } catch (error) {
              console.log(error);
              message.error(
                t(
                  'components.checklistManager.checklistManagerHead.confirmDeleteChecklist.messages.deleteReviewFailed'
                )
              );
            }
          }
        });
      default:
        return '';
    }
  };

  const menu = (
    <Menu onClick={e => handleTableMenuClick(e)}>
      <ChangeCurrentChecklistMenuItem key="changeCurrentChecklist">
        <EditOutlined />
        <span>
          {t('components.checklistManager.checklistManagerHead.menu.changeCurrentChecklist')}
        </span>
      </ChangeCurrentChecklistMenuItem>
      <NewReviewMenuItem key="navigateToClientInteraction">
        <ExportOutlined />
        <span>
          {t('components.checklistManager.checklistManagerHead.menu.navigateToClientInteraction')}
        </span>
      </NewReviewMenuItem>
      <AddToCalibrationMenuItem key="addToCalibration">
        <FileAddOutlined />
        <span>{t('components.checklistManager.checklistManagerHead.menu.addToCalibration')}</span>
      </AddToCalibrationMenuItem>
      <DeleteReviewMenuItem key="deleteReivew" style={{ color: 'red' }}>
        <DeleteOutlined />
        <span>{t('components.checklistManager.checklistManagerHead.menu.deleteReivew')}</span>
      </DeleteReviewMenuItem>
    </Menu>
  );

  const componentStates = {
    [CHECKLIST_MANAGER_STATES.INITIAL]: (
      <SRow backgroundColor="var(--gray-background)">
        <SCol>
          <SText fontSize="20px" fontWeight={500} marginBottom="5px">
            {isAbleToEdit
              ? t('components.checklistManager.checklistManagerHead.buttons.selectChecklist')
              : t('components.checklistManager.checklistManagerHead.buttons.emptyReview')}
          </SText>
        </SCol>
      </SRow>
    ),
    [CHECKLIST_MANAGER_STATES.EDITING]: (
      <SRow
        type="flex"
        gutter={[16, 16]}
        backgroundColor="var(--gray-background)"
        style={{ marginBottom: 0 }}
      >
        <SCol span={24}>
          <SButton
            block
            loading={loadingSubmitButton}
            type="primary"
            ghost
            onClick={onSubmitButton}
          >
            {t('components.checklistManager.checklistManagerHead.buttons.submitReview')}
          </SButton>
        </SCol>
        <SCol span={24}>
          <SCard bodyPadding="16px" bordered shadowed>
            <SRow type="flex" align="middle" justify="space-between">
              <SCol lg={21} xl={22}>
                <Tooltip title={checklistDefinition?.name}>
                  <div
                    className="truncated"
                    style={{
                      maxWidth: 'calc(100% - 80px)',
                      display: 'inline-block',
                      verticalAlign: 'bottom'
                    }}
                  >
                    <SText fontSize="20px">
                      {!isEmpty(checklistDefinition)
                        ? checklistDefinition?.name
                        : 'Форма оценки не установлена'}
                    </SText>
                  </div>
                  <SText fontSize="20px">{`: ${score}`}</SText>
                </Tooltip>
              </SCol>
              <SCol display="flex" alignItems="center">
                <Dropdown overlay={menu} trigger={['click']} placement="bottomLeft">
                  <Button
                    type="link"
                    size="small"
                    icon={<Icon icon={MoreVertical} color="var(--blue_2)" size="24px" />}
                    strokeWidth="1"
                  />
                </Dropdown>
              </SCol>
            </SRow>
          </SCard>
        </SCol>
      </SRow>
    ),
    [CHECKLIST_MANAGER_STATES.SAVED]: (
      <SRow
        type="flex"
        gutter={[16, 16]}
        backgroundColor="var(--gray-background)"
        style={{ marginBottom: 0 }}
      >
        <AppealHead reviewId={id} />

        {currentUserId === reivewAuthorId && (
          <SCol span={24}>
            <SButton block type="primary" onClick={onEditButton} disabled={appeal}>
              {t('components.checklistManager.checklistManagerHead.buttons.editReview')}
            </SButton>
          </SCol>
        )}

        <SCol span={24}>
          <SCard bodyPadding="16px" bordered shadowed>
            <SRow type="flex" align="middle" justify="space-between">
              <SCol lg={21} xl={22}>
                <Tooltip title={checklistDefinition?.name}>
                  <SText width="100%" fontSize="20px" ellipsis>
                    {`${checklistDefinition?.name}: ${score}`}
                  </SText>
                </Tooltip>
              </SCol>
              {(isAbleToEdit || currentUserId === reivewAuthorId) && (
                <SCol display="flex" alignItems="center">
                  <Dropdown overlay={menu} trigger={['click']} placement="bottomLeft">
                    <Button
                      type="link"
                      size="small"
                      icon={<Icon icon={MoreVertical} color="var(--blue_2)" size="24px" />}
                      strokeWidth="1"
                    />
                  </Dropdown>
                </SCol>
              )}
            </SRow>
          </SCard>
        </SCol>
      </SRow>
    )
  };

  return (
    <SRow>
      <Prompt
        when={checklistManagerState === CHECKLIST_MANAGER_STATES.EDITING && !!answeredQuestions}
        message={t('components.checklistManager.checklistManagerHead.messages.unsavedScore')}
      />
      <SCol span={24}>{componentStates[checklistManagerState]}</SCol>
    </SRow>
  );
};

const mapStateToProps = (state, ownProps) => {
  const {
    checklistManagerState,
    currentChecklist,
    questionIdToAnswerValue,
    loading,
    appealId
  } = state.uiChecklistManager;

  let questions = [];

  const appeal = getAppellationsByIds(state)[appealId];
  const checklistDefinitionsByIds = getChecklistsDefinitionsByIds(state);
  const checklistDefinitionId = get(currentChecklist, 'checklistDefinitionId');
  const checklistDefinition = get(checklistDefinitionsByIds, checklistDefinitionId, {});

  if (!isEmpty(currentChecklist) && !isEmpty(checklistDefinition)) {
    questions = checklistDefinition
      ? getChecklistDefinitionQuestions(state, checklistDefinition)
      : [];
  }

  const currentUserPermissions = get(getCurrentUser(state), 'role.permissions', []);

  const isAbleToEdit = !isEmpty(
    intersection(currentUserPermissions, [PERMISSIONS.CAN_MAKE_REVIEW])
  );

  const currentUserId = get(getCurrentUser(state), 'id', '');
  const reivewAuthorId = get(state.reviewsResource.byIds[ownProps.id], 'reviewerId', '');

  return {
    appeal,
    checklistDefinition,
    loading: loading || state.checklistDefinitionsResource.loading,
    loadingSubmitButton:
      state.checklistsResource.updateByIdStarted || state.checklistsResource.createStarted,
    questionIdToAnswerValue,
    checklistManagerState,
    questions,
    currentChecklist,
    isAbleToEdit,
    currentUserId,
    reivewAuthorId
  };
};

const mapDispatchToProps = {
  setChecklistManagerState,
  clearCurrentChecklist,
  deleteReview: reviewsResource.operations.deleteById,
  openAddToCalibrationModal,
  setCommunication,
  updateTableRow
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChecklistManagerHead));
