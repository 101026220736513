import React, { useEffect } from 'react';
import { InputNumber, Radio, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import SCard from 'components/Standard/SCard';
import SRow from 'components/Standard/SRow';
import SCol from 'components/Standard/SCol';
import SSelect from 'components/Standard/SSelect';
import SText, { STitle } from 'components/Standard/SText';
import UserPreview from 'components/UserPreview';
import { Helmet } from 'react-helmet';
import { selectSearch } from 'core/utils/selectSearch';
import { getUserName } from 'components/UserPreview/getUserName';
import { usersResource } from 'redux/resources/users';
import {
  getAppellationsSettings,
  updateAppellationsSettings
} from 'redux/entities/appellationsSettings/operations';

const Settings = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { Option } = Select;
  const usersByIds = useSelector(state => state.usersResource.byIds);

  useEffect(() => {
    dispatch(
      usersResource.operations.load({
        pagination: 'false'
      })
    );
    dispatch(getAppellationsSettings());
  }, []);

  const appellationsSettings = useSelector(state => state.appellationsSettings.settings);
  const loading = useSelector(state => state.appellationsSettings.loading);

  return (
    <SRow margin="16px">
      <Helmet>
        <title>{t('pagesMeta.appealsSettingsPage.title')}</title>
      </Helmet>
      <SCol span={24} marginBottom="16px">
        <SCard bordered shadowed loading={loading}>
          <SRow gutter={[16, 16]}>
            <SCol span={14}>
              <SRow>
                <SCol span={16}>
                  <SText>{t('appealsPage.settings.appealDeadline')}</SText>
                </SCol>
                <SCol span={8}>
                  <InputNumber
                    min={1}
                    max={100}
                    value={appellationsSettings?.appealDeadlineAfterReviewCreatedInDays}
                    onChange={value => {
                      dispatch(
                        updateAppellationsSettings({
                          appealDeadlineAfterReviewCreatedInDays: value
                        })
                      );
                    }}
                  />
                </SCol>
              </SRow>
            </SCol>
            <SCol span={14} marginBottom="6px">
              <SRow>
                <SCol span={16}>
                  <SText>{t('appealsPage.settings.addOperatorAsWatcher')}</SText>
                </SCol>
                <SCol span={8}>
                  <Radio.Group
                    value={appellationsSettings?.addOperatorAsWatcher}
                    onChange={e => {
                      dispatch(
                        updateAppellationsSettings({
                          addOperatorAsWatcher: e.target.value
                        })
                      );
                    }}
                  >
                    <Radio value>{t('general.yes')}</Radio>
                    <Radio value={false}>{t('general.no')}</Radio>
                  </Radio.Group>
                </SCol>
              </SRow>
            </SCol>
            <SCol span={14}>
              <SRow>
                <SCol span={16}>
                  <SText>{t('appealsPage.settings.addReviewerAsWatcher')}</SText>
                </SCol>
                <SCol span={8}>
                  <Radio.Group
                    value={appellationsSettings?.addReviewerAsWatcher}
                    onChange={e => {
                      dispatch(
                        updateAppellationsSettings({
                          addReviewerAsWatcher: e.target.value
                        })
                      );
                    }}
                  >
                    <Radio value>{t('general.yes')}</Radio>
                    <Radio value={false}>{t('general.no')}</Radio>
                  </Radio.Group>
                </SCol>
              </SRow>
            </SCol>
          </SRow>
        </SCard>
      </SCol>
      <SCol span={24}>
        <SCard bordered shadowed loading={loading}>
          <SRow gutter={[16, 16]}>
            <SCol span={24}>
              <STitle level={5}>{t('appealsPage.settings.selectAssignerFromUsersArray')}</STitle>
            </SCol>
            <SCol span={14}>
              <SRow gutter={[8, 8]}>
                <SCol span={24}>
                  <SText>{t('appealsPage.settings.selectAssignerFromUsersArrayAdd')}</SText>
                </SCol>
                <SCol span={24}>
                  <SSelect
                    width="304px"
                    placeholder={t('appealsPage.settings.appealed')}
                    allowClear
                    mode="multiple"
                    showArrow
                    maxTagCount={0}
                    maxTagPlaceholder={selectedKeys =>
                      `${t('general.users')} ${selectedKeys.length}`
                    }
                    filterOption={(input, option) =>
                      selectSearch({ input, option, searchProp: 'label' })
                    }
                    value={appellationsSettings?.selectAssignerFromUsersArray}
                    onChange={ids =>
                      dispatch(updateAppellationsSettings({ selectAssignerFromUsersArray: ids }))
                    }
                  >
                    {Object.values(usersByIds).map(user => (
                      <Option
                        key={user.id}
                        value={user.id}
                        label={getUserName({ user: usersByIds[user.id] })}
                      >
                        <UserPreview disabled userId={user.id} />
                      </Option>
                    ))}
                  </SSelect>
                </SCol>
              </SRow>
            </SCol>
          </SRow>
        </SCard>
      </SCol>
    </SRow>
  );
};

export default Settings;
