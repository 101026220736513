import createListReducer from 'core/redux/lists/listReducer';
import { taskDefinitionsResource } from 'redux/resources/taskDefinitions';

const defaultState = {
  page: {
    size: '25',
    number: '1'
  }
};

const { actions, reducer, operations } = createListReducer({
  name: 'taskDefinitionsList',
  loader: taskDefinitionsResource.operations.load,
  defaultState,
  rebuildQuery: true,
  keepResults: true
});

const taskDefinitionsListReducer = reducer;

export { actions, taskDefinitionsListReducer, operations };
