import { message, Upload } from 'antd';
import Axios from 'axios';
import BraftEditor from 'braft-editor';
import 'braft-editor/dist/index.css';
import Icon from 'components/Icon';
import SCol from 'components/Standard/SCol';
import SRow from 'components/Standard/SRow';
import { apiPath } from 'core/api';
import { apiUrl } from 'core/config';
import { processJsonApiObject } from 'core/jsonapi';
import { handleError } from 'core/services/errors';
import { isEmpty, isFunction, get, isEqual } from 'lodash';
import React, { useState } from 'react';
import { Paperclip } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { uploadedFilesResource } from 'redux/resources/uploadedFiles';
import { getCurrentUser } from 'redux/selectors/users';
import UploadedFile from '../../../UploadedFile';
import Flags from './Flags';

const TemplateEditor = ({ templateState, setTemplateState }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const preferedLocale = useSelector(state => getCurrentUser(state)['prefered-locale']);
  const [editorState, setEditorState] = useState(
    BraftEditor.createEditorState(templateState?.text)
  );
  const [uploadError, setUploadError] = useState(false);

  const onDeleteFile = id => {
    setTemplateState({
      ...templateState,
      uploadedFiles: get(templateState, 'uploadedFiles', []).filter(
        ({ id: fileId }) => fileId !== id
      )
    });
  };

  const uploadedFilesByIds = useSelector(state => state.uploadedFilesResource.byIds, isEqual);

  const uploadHandler = async ({ file, onSuccess, onError, onProgress }) => {
    // TODO: upload file here
    const headers = {
      'access-token': localStorage.getItem('access-token'),
      client: localStorage.getItem('client'),
      uid: localStorage.getItem('uid'),
      'content-type': 'multipart/form-data'
    };
    const uploadEndpoint = `${apiUrl}${apiPath}/organization/uploaded_files`;
    const onUploadProgress = ({ total, loaded }) => {
      const percent = Math.round((loaded * 100) / total);
      onProgress({ percent });
    };
    const body = new FormData();
    body.append('file', file);
    body.append('name', file.name);
    const hide = message.loading(t('integrationsSettingsPage.manageUserAccounts.sendingFile'), 0);
    try {
      const response = await Axios.post(uploadEndpoint, body, { headers, onUploadProgress });
      hide();
      const uploadedFile = processJsonApiObject(response.data.data);
      dispatch(uploadedFilesResource.actions.loadByIdSucceed(uploadedFile));
      setTemplateState({
        ...templateState,
        uploadedFiles: [...(templateState?.uploadedFiles || []), uploadedFile]
      });
    } catch (error) {
      console.log(error);
      hide();
      handleError(error?.response?.status, error?.response?.data);
      onError(error);
    }
  };

  const beforeUpload = file => {
    const isLt2M = file.size / 1024 / 1024 < 3;

    if (!isLt2M) {
      // message.error('Image must smaller than 30MB!');
      setUploadError('Image must smaller than 3MB!');
      // TODO: set error for file size
    }

    return isLt2M;
  };

  const extendControls = [
    {
      key: 'attachment',
      type: 'component',
      component: (
        <Upload
          showUploadList={false}
          accept="image/*"
          beforeUpload={beforeUpload}
          customRequest={uploadHandler}
        >
          <button
            type="button"
            className="control-item button upload-button"
            data-title={t('general.uploadImage')}
          >
            <Icon icon={Paperclip} />
          </button>
        </Upload>
      )
    }
  ];

  const handleEditorChange = editorState => {
    const isEmptyText = isEmpty(editorState.toText().trim());
    setEditorState(editorState);
    if (isFunction(setTemplateState)) {
      setTemplateState({ ...templateState, text: isEmptyText ? '' : editorState.toHTML() });
    }
  };

  return (
    <SCol span={24}>
      <SRow gutter={[0, 8]} style={{ margin: '-4px' }}>
        <SCol span={24}>
          <BraftEditor
            value={editorState}
            placeholder={t('components.commentsPanel.commentPlaceholder')}
            language={preferedLocale === 'ru' ? 'ru' : 'en'}
            controls={['bold', 'underline', 'italic', 'link', 'list-ol', 'list-ul', 'emoji']}
            onChange={handleEditorChange}
            draftProps={{ spellCheck: true }}
            extendControls={extendControls}
          />
        </SCol>
        <SCol style={{ position: 'absolute', top: '11px', left: '316px' }}>
          <Flags templateState={templateState} setTemplateState={setTemplateState} />
        </SCol>
        <SCol span={24}>
          {templateState?.uploadedFiles?.reduce((acc, file) => {
            if (!Object.keys(uploadedFilesByIds).includes(file?.id)) return acc;
            const component = (
              <UploadedFile
                uploadedFile={file}
                allFiles={templateState?.uploadedFiles}
                onDelete={onDeleteFile}
              />
            );

            return [...acc, component];
          }, [])}
        </SCol>
      </SRow>
    </SCol>
  );
};

export default TemplateEditor;
