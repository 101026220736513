import { useSelections } from 'ahooks';
import { Button, Checkbox, Col, Modal, Row, Skeleton, Tooltip, Typography } from 'antd';
import Search from 'components/Inputs/Search';
import SCard from 'components/Standard/SCard';
import SCol from 'components/Standard/SCol';
import { SListItem } from 'components/Standard/SList';
import UserPreview from 'components/UserPreview';
import { intersection, isEmpty, isEqual, orderBy, reduce } from 'lodash';
import React, { useEffect, useState, useMemo, forwardRef, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FixedSizeList } from 'react-window';
import { operations, actions } from 'redux/lists/userAccountBindingsList';
import { userAccountBindingsResource } from 'redux/resources/userAccountBindings';

const { Text } = Typography;

const ManageUsers = forwardRef(
  ({ integrationId, integrationInProcess = false, onOk, okText, okButtonProps = {} }, ref) => {
    const userAccountBindingsByIds = useSelector(
      state => state.userAccountBindingsResource.byIds,
      isEqual
    );
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [handlingOk, setHandlingOk] = useState(false);
    const [search, setSearch] = useState('');
    const [matchesAndCollisions, setMatchesAndCollisions] = useState({
      userAccountBindingsIdsWithCollisionsOnCreate: [],
      userAccountBindingsIdsWithMatches: []
    });
    const { ids } = useSelector(state => state.userAccountBindingsList, isEqual);

    console.log('rerendered');

    const userAccounts = useMemo(
      () =>
        isEmpty(search)
          ? ids.map(id => userAccountBindingsByIds[id])
          : orderBy(
              ids.map(id => userAccountBindingsByIds[id]),
              account =>
                (account.lastName + account.firstName || '')
                  ?.toLowerCase()
                  ?.indexOf(search.toLowerCase()) >= 0,
              ['desc']
            ),
      [ids, search]
    );

    const dispatch = useDispatch();

    const {
      selected,
      allSelected,
      isSelected,
      toggle,
      partiallySelected,
      setSelected
    } = useSelections(ids, []);

    const decideSelectedAccountsIds = (userAccounts, matchesAndCollisions, forceSelect = false) =>
      reduce(
        userAccounts,
        (acc, userAccount) => {
          // * if account already has user it automatically selected
          if (userAccount.userId) return [...acc, userAccount.id];

          // * if there is no user and collision then we cant accept this account
          if (
            matchesAndCollisions.userAccountBindingsIdsWithCollisionsOnCreate.includes(
              userAccount.id
            ) ||
            isEmpty(userAccount.email)
          ) {
            return acc;
          }

          // * all available accounts are preselected in integration in proccess mode cuz it's handy
          return integrationInProcess || forceSelect ? [...acc, userAccount.id] : acc;
        },
        []
      );

    const toggleAll = () => {
      setSelected(decideSelectedAccountsIds(userAccounts, matchesAndCollisions, true));
    };

    const loadAndPreselectUsers = async () => {
      setLoading(true);

      const matchesAndCollisions = await dispatch(
        userAccountBindingsResource.operations.getMatchesAndCollisions({ id: integrationId }, {})
      );

      setMatchesAndCollisions(matchesAndCollisions);

      const accountsByIds = await dispatch(
        operations.load({
          pagination: 'false',
          include: 'user.unit,user.role',
          filters: { integrationId }
        })
      );

      setSelected(decideSelectedAccountsIds(accountsByIds, matchesAndCollisions));

      setLoading(false);
    };

    useImperativeHandle(ref, () => ({
      load: loadAndPreselectUsers
    }));

    useEffect(() => {
      loadAndPreselectUsers();
      return () => dispatch(actions.clearList());
    }, []);

    const handleOk = async () => {
      setHandlingOk(true);
      try {
        const data = selected.map(id => ({ user_account_binding_id: id }));
        const matchedIds = intersection(
          selected,
          matchesAndCollisions.userAccountBindingsIdsWithMatches
        );

        if (!isEmpty(matchedIds)) {
          return Modal.info({
            title: t('general.users'),
            width: '600px',
            content: (
              <Row gutter={[16, 16]} style={{ margin: '-8px' }}>
                <Col span={24}>
                  <Text strong>
                    {`${t('integrationsSettingsPage.manageUserAccounts.usersAlreadyInSystem')}`}
                  </Text>
                </Col>
                {matchedIds.map(id => (
                  <Col span={24}>
                    <Text>{userAccountBindingsByIds[id].email}</Text>
                  </Col>
                ))}
                <Col span={24}>
                  <Text strong>
                    {`${t('integrationsSettingsPage.manageUserAccounts.accountToUsers')}`}
                  </Text>
                </Col>
              </Row>
            ),
            onOk: async () => {
              await dispatch(
                userAccountBindingsResource.operations.importSaas({ id: integrationId, data })
              );

              if (onOk) {
                await onOk(selected);
              }

              setHandlingOk(false);
            }
          });
        }

        await dispatch(
          userAccountBindingsResource.operations.importSaas({ id: integrationId, data })
        );

        if (onOk) {
          await onOk(selected);
        }
      } catch (error) {
        console.log({ error });
      }

      setHandlingOk(false);
    };

    const getUserAccountTooltip = userAccount => {
      if (isEmpty(userAccount.email))
        return t('integrationsSettingsPage.manageUserAccounts.brokenEmail');

      if (
        !matchesAndCollisions.userAccountBindingsIdsWithCollisionsOnCreate.includes(userAccount.id)
      )
        return undefined;

      return t('integrationsSettingsPage.manageUserAccounts.usersAlreadyInSystem');
    };

    const renderItem = (userAccount, style) => {
      return (
        <SListItem style={style} borderBottom="1px solid var(--gray-border)">
          <Col span={24} style={{ padding: '0' }}>
            <Row type="flex" justify="space-between" align="middle">
              <Col span={1}>
                <Tooltip title={getUserAccountTooltip(userAccount)}>
                  <Checkbox
                    disabled={
                      matchesAndCollisions?.userAccountBindingsIdsWithCollisionsOnCreate.includes(
                        userAccount.id
                      ) || isEmpty(userAccount.email)
                    }
                    checked={isSelected(userAccount.id)}
                    onClick={() => toggle(userAccount.id)}
                  />
                </Tooltip>
              </Col>
              <Col span={9}>
                <UserPreview user={userAccount} showAvatar disabled />
              </Col>
              <SCol span={7} display="flex" justifyContent="flex-start" flex="auto">
                <Row type="flex" style={{ maxWidth: '100%' }}>
                  <Text className="truncated">{userAccount.email}</Text>
                </Row>
              </SCol>
              <SCol span={7} display="flex" justifyContent="flex-start" flex="auto">
                <Row type="flex" style={{ maxWidth: '100%' }}>
                  <Text className="truncated">{userAccount.integrationUid}</Text>
                </Row>
              </SCol>
            </Row>
          </Col>
        </SListItem>
      );
    };

    if (loading) {
      return (
        <SCard>
          <Skeleton active />
        </SCard>
      );
    }

    return (
      <SCard>
        <Row gutter={[0, 16]} style={{ marginBottom: '-16px' }}>
          <Col span={24}>
            <Row align="middle" justify="space-between">
              <Col>
                <Row align="middle" gutter={[16, 0]}>
                  <Col>
                    <Search onSearch={setSearch} />
                  </Col>
                  <Col>
                    <Text type="secondary">
                      {`${t('integrationsSettingsPage.manageUserAccounts.totalUsers')}: `}
                    </Text>
                    <Text>{userAccounts.length}</Text>
                  </Col>
                  <Col>
                    <Checkbox
                      checked={allSelected}
                      onClick={toggleAll}
                      indeterminate={partiallySelected}
                    >
                      {t('integrationsSettingsPage.manageUserAccounts.selectAll')}
                    </Checkbox>
                  </Col>
                  <Col>
                    <Text type="secondary">{`Выбрано: ${selected.length}`}</Text>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Button type="primary" loading={handlingOk} onClick={handleOk} {...okButtonProps}>
                  {isEmpty(okText) ? t('general.continue') : okText}
                </Button>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <SListItem borderBottom="1px solid var(--gray-border)">
              <Col span={24} style={{ padding: '0' }}>
                <Row type="flex" justify="space-between" align="middle">
                  <Col span={1} />
                  <Col span={9}>
                    <Text strong>
                      {t('integrationsSettingsPage.manageUserAccounts.columns.user')}
                    </Text>
                  </Col>
                  <SCol span={7} display="flex" justifyContent="flex-start" flex="auto">
                    <Text strong>
                      {t('integrationsSettingsPage.manageUserAccounts.columns.email')}
                    </Text>
                  </SCol>
                  <SCol span={7} display="flex" justifyContent="flex-start" flex="auto">
                    <Text strong>
                      {t('integrationsSettingsPage.manageUserAccounts.columns.id')}
                    </Text>
                  </SCol>
                </Row>
              </Col>
            </SListItem>

            <FixedSizeList itemSize={56} itemCount={userAccounts.length} height={300}>
              {({ index, style }) => renderItem(userAccounts[index], style)}
            </FixedSizeList>
          </Col>
        </Row>
      </SCard>
    );
  }
);

export default ManageUsers;
