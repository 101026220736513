import camelcaseKeys from 'camelcase-keys-deep';
import { normalizeById } from 'core/utils';
import { get, transform, isArray, isNull } from 'lodash';
// processJsonApiObject()

// export processJsonApiObject = (json)
const nullOrId = value => (isNull(value) ? null : value.id);

export const loadIncludedObjects = ({ included, relationshipLoaders, dispatch }) => {
  for (const relationshipName in relationshipLoaders) {
    const objects = processJsonApiIncludedObjects({
      included,
      type: relationshipName
    });
    dispatch(relationshipLoaders[relationshipName](objects));
  }
};

export const processJsonApiCollection = (entities, options = {}) => {
  const idToEntitiesMapping = normalizeById(entities, processJsonApiObject);
  return idToEntitiesMapping;
};

export const processJsonApiIncludedObjects = ({ type, included }) => {
  const includedObjects = included || [];
  const filteredObjects = includedObjects.filter(object => object.type == type);
  return processJsonApiCollection(filteredObjects);
};

export const processJsonApiObject = object => {
  const result = camelcaseKeys(get(object, 'attributes', {}));
  const relationships = processJsonApiRelationships(get(object, 'relationships', {}));
  return { id: object.id, type: object.type, ...result, ...relationships };
};

export const processJsonApiRelationships = relationships => {
  return transform(
    camelcaseKeys(relationships),
    (result, value, key) => {
      const data = get(value, 'data', {});
      const newKey = isArray(data) ? `${key}Ids` : `${key}Id`;
      const newValue = isArray(data) ? data.map(nullOrId) : nullOrId(data);
      result[newKey] = newValue;
      return result;
    },
    {}
  );
};

export const processJsonApiResponse = (data, options = {}) => {
  if (Array.isArray(data)) return processJsonApiCollection(data, options);
  return processJsonApiObject(data);
};
