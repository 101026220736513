import { ExclamationCircleTwoTone, InfoCircleOutlined, MessageOutlined } from '@ant-design/icons';
import { Select, Tooltip } from 'antd';
import { IconButton } from 'components/Buttons';
import CommentsList from 'components/Comments/CommentsList';
import CommentsPanel from 'components/Comments/CommentsPanel';
import Icon from 'components/Icon';
import RateValues from 'components/Inputs/RateValues';
import SButton from 'components/Standard/SButton';
import SCard from 'components/Standard/SCard';
import SCol from 'components/Standard/SCol';
import SRow from 'components/Standard/SRow';
import SText from 'components/Standard/SText';
import { getStandardCommentsKeyByRate } from 'core/utils/standardComments';
import { get, isEmpty, isEqual, isNil, some } from 'lodash';
import React, { useCallback, useState } from 'react';
import { ChevronDown, ChevronUp } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import {
  setAddingCommentsToQuestionId,
  setQuestionIdDescriptionModal,
  updateAnswer,
  updateQuestionStandardComments
} from 'redux/ui/checklistManager/reducer';
import styled from 'styled-components';
import QuestionAppealStatus from './QuestionAppealStatus';
import QuestionAppealComment from './QuestionAppealComment';

const { Option } = Select;

const StyledCard = styled(SCard)`
  && {
    .ant-radio-group-solid
      .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      border-color: var(--blue_4);
    }
  }
`;

const Question = ({
  id,
  name,
  value,
  ratingValues,
  ratingValuesViews,
  binding,
  standardComments,
  disabled = false,
  selectedStandardComments = [],
  checklistDefinition,
  onAutoFail,
  customCommunicationView,
  colorZones,
  questionComments,
  onDeleteComment,
  onUpdateComment,
  allowCommenting,
  onCommentSave,
  appealId,
  appealObjection,
  appealComment,
  hasComments
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isCollapsedComments, setCollapsedComments] = useState(true);
  const [isVisibleEditor, setVisibleEditor] = useState(false);

  const onChange = useCallback(
    newValue => {
      dispatch(updateAnswer({ questionId: id, value: newValue }));

      if (newValue !== value) {
        dispatch(updateQuestionStandardComments({ questionId: id, standardComments: undefined }));
      }

      if (
        get(binding, 'checklistAutofailValues', []).includes(newValue) &&
        binding?.checklistAutofailEnabled
      ) {
        onAutoFail();
      }
    },
    [dispatch, id, value]
  );

  const onInfo = useCallback(() => dispatch(setQuestionIdDescriptionModal(id)), [id, dispatch]);

  const onStandardCommentChange = comments => {
    dispatch(updateQuestionStandardComments({ questionId: id, comments }));
  };

  const standardCommentKey = getStandardCommentsKeyByRate(value);
  const showStandardCommentSelector =
    !isEmpty(standardComments) && !isEmpty(standardComments[standardCommentKey]) && !disabled;

  const showStandardComments = disabled && !isEmpty(selectedStandardComments);

  const autofailed =
    (get(binding, 'checklistAutofailValues', []).includes(value) &&
      binding?.checklistAutofailEnabled) ||
    (get(binding, 'groupAutofailValues', []).includes(value) && binding?.groupAutofailEnabled);

  const colorZonesValidated = () => {
    if (checklistDefinition.scaleType === 'max_2' || ratingValues.length === 2) {
      return !some([get(colorZones, 'low.stop')], isNil);
    }
    return !some([get(colorZones, 'low.stop'), get(colorZones, 'medium.stop')], isNil);
  };

  const selectedStandardCommentsText = selectedStandardComments.map(
    id => get(standardComments, standardCommentKey, {})[id]
  );

  // TODO: refactor
  const canComment = isVisibleEditor && !customCommunicationView && !appealId;
  const selectedToAppeal = appealId && appealObjection;

  const getMessageIconColor = () => {
    if (selectedToAppeal) return 'white';
    if (hasComments && !selectedToAppeal) return 'var(--link-color)';
    return 'inherit';
  };

  return (
    <SCol span={24}>
      <StyledCard
        bodyPadding="18px 20px 20px 20px"
        bordered
        shadowed
        {...(selectedToAppeal ? { background: 'var(--blue_6)' } : {})}
      >
        <SRow
          gutter={[8, 8]}
          style={{ margin: '-4px' }}
          type="flex"
          align="middle"
          {...(customCommunicationView && { justify: 'end' })}
        >
          <SCol {...(customCommunicationView ? { style: { flex: 'auto' } } : { span: 24 })}>
            <SRow justify="space-between">
              <SCol>
                {autofailed && (
                  <Tooltip title={t('components.checklistManager.question.autoFailTooltip.title')}>
                    <ExclamationCircleTwoTone
                      twoToneColor="var(--red_6)"
                      style={{ marginRight: '4px' }}
                    />
                  </Tooltip>
                )}
                <SText color={selectedToAppeal ? 'white' : 'black'}>{name}</SText>
              </SCol>

              <QuestionAppealStatus
                objection={appealObjection}
                questionId={id}
                appealId={appealId}
                questionHasAnswer={!isNil(value)}
              />
            </SRow>
          </SCol>

          <SCol {...(!customCommunicationView && { span: 24 })}>
            <SRow type="flex" justify="space-between" align="middle" style={{ flexWrap: 'nowrap' }}>
              <SCol style={{ maxWidth: 'calc(100% - 64px)' }}>
                <RateValues
                  onChange={onChange}
                  disabled={disabled}
                  ratingValues={ratingValues}
                  ratingValuesViews={ratingValuesViews}
                  value={isNil(value) ? null : value}
                  valuesDisplayType={checklistDefinition.valuesDisplayType}
                  scaleType={checklistDefinition.scaleType}
                />
              </SCol>
              <SCol>
                <SRow
                  type="flex"
                  justify="space-between"
                  align="middle"
                  style={{ flexWrap: 'nowrap' }}
                >
                  <IconButton
                    button={{
                      icon: <MessageOutlined />,
                      onClick: () => {
                        dispatch(setAddingCommentsToQuestionId(id));
                        setVisibleEditor(!isVisibleEditor);
                      },
                      color: getMessageIconColor(),
                      disabled: !allowCommenting
                    }}
                  />
                  <IconButton
                    button={{
                      icon: <InfoCircleOutlined />,
                      onClick: onInfo,
                      color: selectedToAppeal ? 'white' : 'inherit',
                      disabled: !colorZonesValidated()
                    }}
                  />
                </SRow>
              </SCol>
            </SRow>
          </SCol>

          {showStandardCommentSelector && (
            <SCol span={24}>
              <Select
                mode="multiple"
                onChange={onStandardCommentChange}
                style={{ width: '100%' }}
                value={selectedStandardComments || undefined}
                maxTagTextLength={10}
                placeholder={t(
                  'components.checklistManager.question.showStandardCommentSelector.placeholder'
                )}
                allowClear
              >
                {Object.keys(standardComments[standardCommentKey]).map(commentId => (
                  <Option value={commentId}>
                    {standardComments[standardCommentKey][commentId]}
                  </Option>
                ))}
              </Select>
            </SCol>
          )}

          {showStandardComments && (
            <SCol span={24}>
              <SRow>
                <SCol span={24}>
                  <SText color={selectedToAppeal ? 'white' : 'var(--black_6)'} fontSize="12px">
                    {selectedStandardCommentsText.join(', ')}
                  </SText>
                </SCol>
              </SRow>
            </SCol>
          )}

          {canComment && (
            <SCol span={24}>
              <SRow>
                <SCol span={24}>
                  <CommentsPanel
                    showCommentsList={false}
                    allowCommenting={allowCommenting}
                    addingComment
                    allowSelectTimestamp={false}
                    onCancelComment={() => {
                      setVisibleEditor(!isVisibleEditor);
                      setAddingCommentsToQuestionId(null);
                    }}
                    onCommentSave={comment => {
                      setVisibleEditor(!isVisibleEditor);
                      onCommentSave({ ...comment });
                    }}
                    isQuestionView
                  />
                </SCol>
              </SRow>
            </SCol>
          )}

          {!isEmpty(questionComments) && !customCommunicationView && (
            <SCol span={24}>
              <SRow>
                <SCol span={23}>
                  <CommentsList
                    comments={questionComments}
                    allowActions
                    onDelete={onDeleteComment}
                    onUpdate={onUpdateComment}
                    showFirst={isCollapsedComments}
                    isQuestionView
                    appealObjection={appealObjection}
                    appealComment={appealComment}
                    questionId={id}
                    appealId={appealId}
                  />
                </SCol>
                {Object.values(questionComments).length > 1 && (
                  <SCol span={1}>
                    <SButton
                      size="icon"
                      icon={
                        <Icon icon={isCollapsedComments ? ChevronDown : ChevronUp} size="16px" />
                      }
                      color={selectedToAppeal ? 'white' : 'var(--black_primary)'}
                      _hover="background-color:transparent"
                      _focus="background-color:transparent"
                      backgroundColor="transparent"
                      border="none"
                      boxShadow="none"
                      onClick={() => setCollapsedComments(!isCollapsedComments)}
                    />
                  </SCol>
                )}
              </SRow>
            </SCol>
          )}

          {(isEmpty(questionComments) || appealObjection?.new) && (
            <SCol span={23} style={{ padding: 0 }}>
              <QuestionAppealComment
                appealObjection={appealObjection}
                appealComment={appealComment}
                questionId={id}
                appealId={appealId}
                onlyEditor
              />
            </SCol>
          )}
        </SRow>
      </StyledCard>
    </SCol>
  );
};

export default React.memo(Question, isEqual);
