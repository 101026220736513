import React from 'react';
import { Col, Tooltip, Row } from 'antd';
import { connect } from 'react-redux';
import { isEmpty, orderBy, get } from 'lodash';
import { ResponsiveBar } from '@nivo/bar';
import { getUserName } from 'components/UserPreview/getUserName';
import moment from 'moment';
import { prepareData, prepareKeys, searchMaxValue } from './prepareFunctions';
import { SettingsButtonCol, WidgetNameCol, WidgetCard, TimePeriod } from '../../styled';
import SettingsModal from '../../Components/SettingsModal';
import {
  widgetNameSetter,
  widgetPeriodSetter,
  widgetInfoTextSetter,
  getDateSelectorType
} from '../../utils';
import EmptyWidget from '../../Components/EmptyWidget';

const ClientInteractionsCountByOperatorsHistory = ({
  widgetData,
  widgetsAnalyticsByIds,
  loadingWidgetId,
  usersByIds,
  colors
}) => {
  const widgetValue = get(widgetsAnalyticsByIds, widgetData.id, []);

  const { id, type, filters, name } = widgetData;
  const dateSelectorType = getDateSelectorType({ widgetType: type });
  const widgetHistory = widgetValue.filter(item => !isEmpty(item.history));

  return (
    <>
      {isEmpty(widgetValue) || isEmpty(widgetHistory) || loadingWidgetId === id ? (
        <EmptyWidget key={id} widgetData={widgetData} loadingWidgetId={loadingWidgetId} />
      ) : (
        <Col span={12}>
          <WidgetCard>
            <Row type="flex">
              <WidgetNameCol span={20}>
                {widgetInfoTextSetter({ type })}
                <Tooltip title={widgetNameSetter({ name, type })}>
                  <span>{widgetNameSetter({ name, type })}</span>
                </Tooltip>
                <TimePeriod type="secondary">
                  {widgetPeriodSetter(filters, dateSelectorType)}
                </TimePeriod>
              </WidgetNameCol>
              <SettingsButtonCol span={4}>
                <SettingsModal widgetData={widgetData} />
              </SettingsButtonCol>
            </Row>
            <ResponsiveBar
              data={orderBy(prepareData({ widgetValue }), 'period')}
              keys={prepareKeys({ widgetValue })}
              indexBy="period"
              margin={{ top: 30, right: 20, bottom: 70, left: 45 }}
              padding={0.3}
              maxValue={searchMaxValue({ widgetValue })}
              groupMode="grouped"
              colors={colors}
              borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
              axisTop={null}
              axisRight={null}
              tooltip={tooltip => (
                <>
                  <span>
                    {`${getUserName({ user: usersByIds[tooltip.id] })} - ${tooltip.data.period}: `}
                  </span>
                  <strong>{tooltip.data[tooltip.id]}</strong>
                </>
              )}
              axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                format: d => moment(d).format('DD/MM/YYYY')
              }}
              axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0
              }}
              enableLabel={false}
              labelSkipWidth={12}
              labelSkipHeight={12}
              labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
              animate
              motionStiffness={90}
              motionDamping={15}
            />
          </WidgetCard>
        </Col>
      )}
    </>
  );
};

const mapStateToProps = state => {
  return {
    widgetsAnalyticsByIds: state.dashboardAnalytics.byIds,
    usersByIds: state.usersResource.byIds
  };
};

export default connect(mapStateToProps, null)(ClientInteractionsCountByOperatorsHistory);
