import React from 'react';
import styled from 'styled-components';
import { layout, space, color, position, flexbox } from 'styled-system';

const StyledIcon = styled.span`
&&{
  ${flexbox}
  ${space}
  ${position}
  ${color}
  ${layout}
}

&&.ant-tooltip-open {
  line-height: 0px;
}

`;

const Icon = ({ icon, size = '16px', ...props }) => {
  const Component = icon;
  return (
    <StyledIcon className="anticon" role="img" {...props}>
      <Component size={size} {...props} />
    </StyledIcon>
  );
};

export default Icon;
