import React from 'react';
import { Col, Tooltip, Row } from 'antd';
import { connect } from 'react-redux';
import { isEmpty, orderBy, get } from 'lodash';
import { ResponsiveBar } from '@nivo/bar';
import truncateString from 'core/utils/truncateString';
import SettingsModal from '../../Components/SettingsModal';
import {
  tooltipSetter,
  widgetPeriodSetter,
  widgetNameSetter,
  widgetInfoTextSetter,
  getDateSelectorType
} from '../../utils';
import { SettingsButtonCol, WidgetNameCol, WidgetCard, TimePeriod } from '../../styled';
import EmptyWidget from '../../Components/EmptyWidget';

const PhoneCallsCountByUnits = ({
  widgetData,
  widgetsAnalyticsByIds,
  colors,
  keys,
  stacked,
  loadingWidgetId
}) => {
  const { id, type, filters, name } = widgetData;
  const dateSelectorType = getDateSelectorType({ widgetType: type });
  const widgetValue = get(widgetsAnalyticsByIds, widgetData.id, []);

  return (
    <>
      {isEmpty(widgetValue) || loadingWidgetId === id ? (
        <EmptyWidget key={id} widgetData={widgetData} loadingWidgetId={loadingWidgetId} />
      ) : (
        <Col span={6}>
          <WidgetCard>
            <Row type="flex">
              <WidgetNameCol span={20}>
                {widgetInfoTextSetter({ type })}
                <Tooltip title={widgetNameSetter({ name, type })}>
                  <span>{widgetNameSetter({ name, type })}</span>
                </Tooltip>
                <TimePeriod type="secondary">
                  {widgetPeriodSetter(filters, dateSelectorType)}
                </TimePeriod>
              </WidgetNameCol>
              <SettingsButtonCol span={4}>
                <SettingsModal widgetData={widgetData} />
              </SettingsButtonCol>
            </Row>

            <ResponsiveBar
              data={orderBy(widgetValue, 'name', 'desc')}
              keys={stacked ? keys : ['value']}
              indexBy="name"
              margin={{ top: 0, right: 10, bottom: 70, left: 120 }}
              padding={0.3}
              layout="horizontal"
              colors={colors}
              borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
              axisTop={null}
              axisRight={null}
              tooltip={tooltip => tooltipSetter(tooltip.data)}
              axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                format: d => (d.toString().match(/\./) ? '' : d)
              }}
              axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                format: string => truncateString(string, 16)
              }}
              labelSkipWidth={12}
              labelSkipHeight={12}
              labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
              animate
              motionStiffness={90}
              motionDamping={15}
            />
          </WidgetCard>
        </Col>
      )}
    </>
  );
};

const mapStateToProps = state => {
  return {
    widgetsAnalyticsByIds: state.dashboardAnalytics.byIds
  };
};

export default connect(mapStateToProps, null)(PhoneCallsCountByUnits);
