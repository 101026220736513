import React, { useState } from 'react';
import SRow from 'components/Standard/SRow';
import SCol from 'components/Standard/SCol';
import SCard from 'components/Standard/SCard';
import UserPreview from 'components/UserPreview';
import { useTranslation } from 'react-i18next';
import SText from 'components/Standard/SText';
import { beatifyFloat } from 'core/utils/convertFloat';
import { useSelector, useDispatch } from 'react-redux';
import RedesignWidgetTable from 'components/Dashboard/RedesignWidgetTable';
import { IconButton } from 'components/Buttons';
import { Download, Settings } from 'react-feather';
import AddUsersModal from 'components/AddUsersModal';
import { get, isEqual } from 'lodash';
import { widgetPeriodSetter } from 'pages/DashboardPage/utils';
import { updateDashboardWidget } from 'redux/ui/widgetSettingsModal/operations';
import { setLoadingWidgetId } from 'redux/entities/dashboardPages/reducer';
import { Radio } from 'antd';
import { PERMISSIONS, WIDGET_VIEW_MODES } from 'core/utils/constants';
import { getColorizedColumn, getOrderString, onTableChange } from './utils';

const AppellationsByAppellants = () => {
  const { t } = useTranslation();
  const [isVisibleFilterModal, setVisibleFilterModal] = useState(false);
  const [initialState, setInitialState] = useState({});
  const dispatch = useDispatch();

  const appealDashboard = useSelector(
    state => state.dashboardPages.dashboardsByTypes?.appellations,
    isEqual
  );
  const id = get(appealDashboard, 'widgetsByTypes.appellationStatusesCountByAppellants.id', {});
  const loading = useSelector(state => state.dashboardPages.loading);
  const loadingWidgetId = useSelector(state => state.dashboardPages.loadingWidgetId);
  const loadingTable = loadingWidgetId === id || loadingWidgetId === 'filters';
  const filters = useSelector(state => state.dashboardWidgetsResource.byIds[id]?.filters, isEqual);
  const page = useSelector(state => state.dashboardWidgetsResource.byIds[id]?.page, isEqual);
  const widgetAnalytics = useSelector(
    state => get(state.dashboardAnalytics.byIds, id, {}),
    isEqual
  );
  const { meta } = widgetAnalytics;
  const tableData = [
    ...get(widgetAnalytics, 'data', []),
    { ...widgetAnalytics?.total_appellations_count, id: 'footer' }
  ];

  const updateWidget = async ids => {
    dispatch(setLoadingWidgetId(id));
    await dispatch(
      updateDashboardWidget({
        id,
        filters: {
          ...filters,
          appellantsIds: ids
        },
        page: {
          number: page?.number,
          size: page?.size
        }
      })
    );
    dispatch(setLoadingWidgetId());
    setVisibleFilterModal(false);
  };

  const tableColumns = [
    {
      title: t('general.executor'),
      dataIndex: 'id',
      key: 'id',
      ellipsis: true,
      render: (text, record) => {
        const component =
          record?.id === 'footer' ? (
            <div style={{ height: '42px', display: 'flex', alignItems: 'center' }}>
              <SText>{`${t('general.total')}:`}</SText>
            </div>
          ) : (
            <UserPreview userId={text} showAvatar showEmail disabled />
          );

        return getColorizedColumn({
          color: 'var(--gray_4)',
          component
        });
      }
    },

    {
      title: t('appealsPage.analytics.pending'),
      dataIndex: 'open_appellations_count',
      key: 'open_appellations_count',
      defaultSortOrder: getOrderString({ orderString: meta?.open_appellations_count }),
      sorter: true,
      ellipsis: true,
      align: 'center',
      render: (text, record) => {
        const component =
          record?.id === 'footer' ? (
            <SText>{text}</SText>
          ) : (
            <>
              <SText>{text}</SText>
              <SText strong>
                {` / ${beatifyFloat((text / record?.total_appellations_count) * 100)} %`}
              </SText>
            </>
          );

        return getColorizedColumn({
          color: 'var(--gray_4)',
          component
        });
      }
    },

    {
      title: t('appealsPage.analytics.noAnswer'),
      dataIndex: 'without_answer_appellations_count',
      key: 'without_answer_appellations_count',
      defaultSortOrder: getOrderString({
        orderString: meta?.without_answer_appellations_count
      }),
      sorter: true,
      ellipsis: true,
      align: 'center',
      render: (text, record) => {
        const component =
          record?.id === 'footer' ? (
            <SText>{text}</SText>
          ) : (
            <>
              <SText>{text}</SText>
              <SText strong>
                {` / ${beatifyFloat((text / record?.total_appellations_count) * 100)} %`}
              </SText>
            </>
          );

        return getColorizedColumn({
          color: 'var(--yellow_4)',
          component
        });
      }
    },

    {
      title: t('general.accepted'),
      dataIndex: 'accepted_appellations_count',
      key: 'accepted_appellations_count',
      defaultSortOrder: getOrderString({ orderString: meta?.accepted_appellations_count }),
      sorter: true,
      ellipsis: true,
      align: 'center',
      render: (text, record) => {
        const component =
          record?.id === 'footer' ? (
            <SText>{text}</SText>
          ) : (
            <>
              <SText>{text}</SText>
              <SText strong>
                {` / ${beatifyFloat((text / record?.total_appellations_count) * 100)} %`}
              </SText>
            </>
          );

        return getColorizedColumn({
          color: 'var(--green_4)',
          component
        });
      }
    },

    {
      title: t('general.rejected'),
      dataIndex: 'rejected_appellations_count',
      key: 'rejected_appellations_count',
      defaultSortOrder: getOrderString({ orderString: meta?.rejected_appellations_count }),
      sorter: true,
      ellipsis: true,
      align: 'center',
      render: (text, record) => {
        const component =
          record?.id === 'footer' ? (
            <SText>{text}</SText>
          ) : (
            <>
              <SText>{text}</SText>
              <SText strong>
                {` / ${beatifyFloat((text / record?.total_appellations_count) * 100)} %`}
              </SText>
            </>
          );

        return getColorizedColumn({
          color: 'var(--red_4)',
          component
        });
      }
    },

    {
      title: t('general.total'),
      dataIndex: 'total_appellations_count',
      key: 'total_appellations_count',
      defaultSortOrder: getOrderString({ orderString: meta?.total_appellations_count }),
      sorter: true,
      ellipsis: true,
      align: 'center',
      width: 170,
      render: (text, record) => {
        const component = <SText>{text}</SText>;

        return getColorizedColumn({
          color: 'var(--gray_4)',
          component
        });
      }
    }
  ];

  return (
    <SCol span={24}>
      <SCard bordered shadowed rounded="4px" bodyPadding="12px 20px 6px 20px">
        <SRow>
          <SCol span={24}>
            <SRow type="flex" justify="space-between">
              <SCol span={8}>
                <SRow>
                  <SCol span={24}>
                    <SText fontSize="24px" strong>
                      {t('appealsPage.analytics.appellationsByAppellants.title')}
                    </SText>
                  </SCol>
                  <SCol span={24}>
                    <SText type="secondary" strong>
                      {widgetPeriodSetter(
                        get(appealDashboard, 'filters', {}),
                        'appellationSelector'
                      )}
                    </SText>
                  </SCol>
                </SRow>
              </SCol>
              <SCol span={8} display="flex" justifyContent="center" marginTop="6px">
                <Radio.Group
                  // onChange={e => onChangeViewMode({ viewMode: e.target.value })}
                  value={filters?.viewMode || WIDGET_VIEW_MODES.OPERATORS.value}
                >
                  <Radio.Button value={WIDGET_VIEW_MODES.OPERATORS.value}>
                    {t(WIDGET_VIEW_MODES.OPERATORS.title)}
                  </Radio.Button>
                  <Radio.Button
                    value={WIDGET_VIEW_MODES.UNITS.value}
                    // disabled={!currentChecklist?.isGroupable}
                  >
                    {t(WIDGET_VIEW_MODES.UNITS.title)}
                  </Radio.Button>
                </Radio.Group>
              </SCol>
              <SCol span={8} display="flex" justifyContent="flex-end">
                <IconButton
                  button={{
                    icon: <Download />,
                    color: 'var(--black_4)',
                    marginRight: '6px'
                  }}
                />
                <IconButton
                  button={{
                    icon: <Settings />,
                    color: 'var(--black_4)',
                    onClick: setVisibleFilterModal
                  }}
                />
              </SCol>
            </SRow>
          </SCol>
          <SCol marginTop="10px">
            <RedesignWidgetTable
              dataSource={widgetAnalytics?.data?.length === 0 ? [] : tableData}
              columns={loading ? [] : tableColumns}
              loading={loadingTable}
              size="small"
              showSorterTooltip={false}
              pagination={{
                position: 'bottomLeft',
                showSizeChanger: true,
                total: meta?.total_count,
                current: meta?.page,
                pageSize: page?.size + 1,
                pageSizeOptions: ['5', '10', '25', '50']
              }}
              onChange={(pagination, _, sorter) =>
                onTableChange({ pagination, sorter, widgetId: id, tableColumns, filters, dispatch })
              }
            />
            <AddUsersModal
              selectedUsersIds={filters?.appellantsIds}
              onCancel={() => setVisibleFilterModal(false)}
              visible={isVisibleFilterModal}
              onAdd={updateWidget}
              addedFilters={{ withRolePermissions: [PERMISSIONS.CAN_CREATE_APPELLATIONS] }}
            />
          </SCol>
        </SRow>
      </SCard>
    </SCol>
  );
};

export default AppellationsByAppellants;
