import React from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { selectSearch } from 'core/utils/selectSearch';
import { setCurrentChecklistDefinitionId } from 'redux/ui/userAnalyticsPage/reducer';
import { getChecklistsDefinitionsByStatuses } from 'redux/selectors/checklists';
import { CHECKLIST_DEFINITION_STATUS } from 'core/utils/constants';
import { useTranslation } from 'react-i18next';
import ChecklistDefinitionsSelect from 'components/Checklist/ChecklistDefinitionsSelect';

const ChecklistDefinitionSelector = ({
  checklistDefinitions = [],
  currentChecklistDefinitionId,
  changeChecklistDefiinitonId,
  loading
}) => {
  const empty = isEmpty(currentChecklistDefinitionId) && isEmpty(checklistDefinitions);
  const { t } = useTranslation();
  return (
    <ChecklistDefinitionsSelect
      checklistDefinitions={[
        { id: 'all-questions', name: t('userAnalyticsPage.mainControlsPanel.questionValues') },
        ...checklistDefinitions
      ]}
      loading={loading}
      disabled={empty}
      value={currentChecklistDefinitionId}
      placeholder={t('userAnalyticsPage.mainControlsPanel.checklistDefinitionsEmpty')}
      onChange={id => {
        changeChecklistDefiinitonId(id);
      }}
      style={{ width: 350 }}
      showSearch
      allowAll
      filterOption={(input, option) => selectSearch({ input, option, searchProp: 'searchValue' })}
    />
  );
};

const mapStateToProps = state => {
  const { currentChecklistDefinitionId, checklistDefinitionsIds } = state.uiUserAnalyticsPage;

  if (state.usersResource.loadByIdStarted) {
    return { loading: true, checklistDefinitions: [] };
  }

  const checklistDefinitions = Object.values(
    getChecklistsDefinitionsByStatuses(state, {
      statuses: [
        CHECKLIST_DEFINITION_STATUS.PUBLISHED.value,
        CHECKLIST_DEFINITION_STATUS.ARCHIVED.value
      ]
    }).filter(checklistDefinition => checklistDefinitionsIds.includes(checklistDefinition.id))
  );

  return {
    loading: false,
    currentChecklistDefinitionId,
    checklistDefinitions
  };
};

const mapDispatchToProps = {
  changeChecklistDefiinitonId: setCurrentChecklistDefinitionId
};

export default connect(mapStateToProps, mapDispatchToProps)(ChecklistDefinitionSelector);
