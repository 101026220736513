import React from 'react';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { get, isEqual } from 'lodash';
import SCard from 'components/Standard/SCard';
import SRow from 'components/Standard/SRow';
import SCol from 'components/Standard/SCol';
import SText from 'components/Standard/SText';
import SSelect from 'components/Standard/SSelect';
import SButton from 'components/Standard/SButton';
import { CLIENT_INTERACTIONS_TYPES_LITERALS } from 'core/utils/constants';
import TimeSpecificSelector from 'components/Inputs/TimeSpecificSelector';
import { selectSearch } from 'core/utils/selectSearch';
import { useHistory } from 'react-router-dom';
import { updateAppellationsDashboardPageFilters } from 'redux/entities/dashboardPages/operations';
import ChecklistDefinitionsSelect from 'components/Checklist/ChecklistDefinitionsSelect';

const Filters = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const { Option } = Select;

  const loading = useSelector(state => state.dashboardPages.loading);
  const checklistDefinitions = useSelector(
    state => Object.values(state.checklistDefinitionsResource.byIds),
    isEqual
  );

  const appealDashboard = useSelector(
    state => get(state, 'dashboardPages.dashboardsByTypes.appellations', {}),
    isEqual
  );
  const { filters } = appealDashboard;

  const onChange = updatedFilter => {
    dispatch(
      updateAppellationsDashboardPageFilters({
        filters: {
          ...filters,
          ...updatedFilter
        }
      })
    );
  };

  return (
    <SCard background="#F8F8FA" bodyPadding="16px 20px">
      <SRow type="flex" justify="space-between">
        <SCol width="calc(100% - 91px)">
          <SRow gutter={[8, 0]}>
            <SCol lg={6} xl={6} xxl={4}>
              <SSelect
                width="100%"
                placeholder={t('general.clientInteractionType')}
                allowClear
                mode="multiple"
                showArrow
                maxTagCount={0}
                loading={loading}
                value={filters?.clientInteractionTypes}
                maxTagPlaceholder={selectedKeys =>
                  `${t('general.clientInteractionTypes')}: ${selectedKeys.length}`
                }
                filterOption={(input, option) =>
                  selectSearch({ input, option, searchProp: 'children' })
                }
                onChange={type => onChange({ clientInteractionTypes: type })}
              >
                {Object.keys(CLIENT_INTERACTIONS_TYPES_LITERALS).map(type => (
                  <Option key={type} value={type}>
                    <SText>{t(CLIENT_INTERACTIONS_TYPES_LITERALS[type])}</SText>
                  </Option>
                ))}
              </SSelect>
            </SCol>

            <SCol lg={6} xl={6} xxl={4}>
              <ChecklistDefinitionsSelect
                placeholder={t('general.checklistDefinitions')}
                checklistDefinitions={checklistDefinitions}
                loading={loading}
                onChange={checklistDefinitionsIds => onChange({ checklistDefinitionsIds })}
                value={filters?.checklistDefinitionsIds}
                style={{ width: '100%' }}
                showArrow
                allowClear
                mode="multiple"
                maxTagCount={0}
                maxTagPlaceholder={selectedKeys =>
                  `${t('general.checklistDefinitions')}: ${selectedKeys.length}`
                }
                filterOption={(input, option) =>
                  selectSearch({ input, option, searchProp: 'searchValue' })
                }
              />
            </SCol>

            <SCol lg={6} xl={6} xxl={4}>
              <TimeSpecificSelector
                placeholder={t('general.applicationDate')}
                onChange={({ symbolicTimeRange, timeTo, timeFrom }) =>
                  onChange({
                    appellationCreatedAtTimeFrom: timeFrom,
                    appellationCreatedAtTimeTo: timeTo,
                    appellationCreatedAtSymbolicTimeRange: symbolicTimeRange
                  })
                }
                value={{
                  timeFrom: filters?.appellationCreatedAtTimeFrom,
                  timeTo: filters?.appellationCreatedAtTimeTo,
                  symbolicTimeRange: filters?.appellationCreatedAtSymbolicTimeRange
                }}
                style={{ width: '100%' }}
                insertedPlaceholder
                loading={loading}
              />
            </SCol>
          </SRow>
        </SCol>
        <SCol>
          <SButton type="primary" onClick={() => history.push(`/appeals/`)}>
            {t('appealsPage.analytics.tracker')}
          </SButton>
        </SCol>
      </SRow>
    </SCard>
  );
};

export default Filters;
