import React, { useState } from 'react';
import { Col, Tooltip, Row, Radio } from 'antd';
import { updateDashboardWidget } from 'redux/ui/widgetSettingsModal/operations';
import { connect } from 'react-redux';
import { WIDGET_VIEW_MODES, PERMISSIONS } from 'core/utils/constants';
import { get, isEmpty } from 'lodash';
import ExcelIcon from 'images/ExcelIcon';
import { useTranslation } from 'react-i18next';
import WidgetViewChanger from 'components/Dashboard/WidgetViewChanger';
import { LoadingOutlined } from '@ant-design/icons';
import { IconButton } from 'components/Buttons/IconButton';
import moment from 'moment';
import { getCurrentUserPermissions } from 'redux/selectors/users';
import { SettingsButtonCol, WidgetNameCol, LargeWidgetCard, TimePeriod } from '../../styled';
import SettingsModal from '../../Components/SettingsModal';
import {
  widgetNameSetter,
  widgetPeriodSetter,
  widgetInfoTextSetter,
  getDateSelectorType,
  exportWidgetData
} from '../../utils';
import EmptyWidget from '../../Components/EmptyWidget';
import { prepareColumns, prepareRows } from './prepareTableData';
import { prapareChartTable, prepareChartData } from './prepareChartData';
import TableView from './Views/TableView';
import ChartView from './Views/ChartView';

const TableReviewsCountByReviewers = ({
  widgetData,
  usersByIds,
  unitsByIds,
  widgetsAnalyticsByIds,
  loadingWidgetId,
  updateDashboardWidget,
  selectedUserId,
  isVisibeReviewerName
}) => {
  const { t } = useTranslation();
  const { id, type, filters, name } = widgetData;
  const dateSelectorType = getDateSelectorType({ widgetType: type });
  const [loadingExport, setLoadingExport] = useState(false);
  const { isWeekendView, historyGroupingPeriod } = filters;
  const viewMode = get(filters, 'viewMode', WIDGET_VIEW_MODES.REVIEWERS.value);
  const viewType = get(filters, 'viewType', WIDGET_VIEW_MODES.TABLE.value);

  const widgetValue = get(widgetsAnalyticsByIds, id, {});
  const metadata = widgetValue?.metadata;
  const timeFrom = metadata?.time_from;
  const timeTo = metadata?.time_to;

  const viewData = {
    reviewsCountByReviewers: get(widgetValue, 'reviews_count_by_reviewers', []),
    reviewsCountByUnits: get(widgetValue, 'reviews_count_by_units', []),
    reviewsCountByReviewersHistory: get(widgetValue, 'reviews_count_by_reviewers_history', []),
    reviewsCountByUnitsHistory: get(widgetValue, 'reviews_count_by_units_history', []),
    reviewedClientInteractionsDurationByReviewersHistory: get(
      widgetValue,
      'reviewed_client_interactions_duration_by_reviewers_history',
      []
    ),
    reviewedClientInteractionsDurationByUnitsHistory: get(
      widgetValue,
      'reviewed_client_interactions_duration_by_units_history',
      []
    )
  };

  const tableData = {
    tableColumns: prepareColumns({
      viewMode,
      isWeekendView,
      timeFrom,
      timeTo,
      historyGroupingPeriod
    }),
    tableRows: prepareRows({
      viewData,
      usersByIds,
      unitsByIds,
      viewMode,
      isWeekendView,
      timeFrom,
      timeTo,
      historyGroupingPeriod
    })
  };

  const chartData = {
    table: {
      tableColumns: prapareChartTable({ selectedUserId, viewData, viewMode }).columns,
      tableRows: prapareChartTable({ selectedUserId, viewData, viewMode }).rows
    },
    chart: prepareChartData({ filters, timeFrom, timeTo, viewData })
  };

  const isEmptyWidgetData = isEmpty(
    viewMode === WIDGET_VIEW_MODES.REVIEWERS.value
      ? viewData.reviewsCountByReviewers
      : viewData.reviewsCountByUnits
  );

  const getNameForExport = ({ item }) => {
    if (item.key === 'footer' || item.key === 'header') return t(item.name);
    return isVisibeReviewerName || viewMode === WIDGET_VIEW_MODES.UNITS.value
      ? item.name
      : t('constants.hiddenNamePlaceholder');
  };

  const body = {
    widgetType: type,
    widgetData: {
      columns: tableData.tableColumns.reduce((acc, item) => {
        if (filters.viewMode === WIDGET_VIEW_MODES.UNITS.value && item.key === 'unitName')
          return acc;

        const data = {
          ...item,
          title: ['name', 'totalScore', 'unitName'].includes(item.key)
            ? t(item?.title?.props?.i18nKey)
            : moment(item.key).format('DD/MM')
        };

        return [...acc, data];
      }, []),
      rows: tableData.tableRows.map(item => ({
        ...item,
        name: getNameForExport({ item })
      }))
    }
  };

  const onChangeViewType = viewType => {
    return updateDashboardWidget({
      id,
      type,
      filters: { ...filters, viewType }
    });
  };

  const onChangeViewMode = ({ viewMode }) => {
    return updateDashboardWidget({
      id,
      type,
      filters: { ...filters, viewMode }
    });
  };

  return (
    <>
      {loadingWidgetId === id || isEmptyWidgetData ? (
        <EmptyWidget key={id} widgetData={widgetData} loadingWidgetId={loadingWidgetId} />
      ) : (
        <Col span={24}>
          <LargeWidgetCard>
            <Row type="flex" justify="space-between" align="top">
              <WidgetNameCol span={8}>
                <Row type="flex" gutter={[6, 0]} justify="start">
                  <Col>{widgetInfoTextSetter({ type })}</Col>
                  <Col>
                    <Tooltip title={widgetNameSetter({ name, type })}>
                      <span>{widgetNameSetter({ name, type })}</span>
                    </Tooltip>
                  </Col>
                </Row>

                <TimePeriod type="secondary">
                  {widgetPeriodSetter(filters, dateSelectorType)}
                </TimePeriod>
              </WidgetNameCol>
              <Col span={6}>
                <Row justify="center">
                  <Col>
                    <Radio.Group
                      onChange={e => onChangeViewMode({ viewMode: e.target.value })}
                      value={filters.viewMode || WIDGET_VIEW_MODES.REVIEWERS.value}
                    >
                      <Radio.Button value={WIDGET_VIEW_MODES.REVIEWERS.value}>
                        {t(WIDGET_VIEW_MODES.REVIEWERS.title)}
                      </Radio.Button>
                      <Radio.Button value={WIDGET_VIEW_MODES.UNITS.value}>
                        {t(WIDGET_VIEW_MODES.UNITS.title)}
                      </Radio.Button>
                    </Radio.Group>
                  </Col>
                </Row>
              </Col>
              <SettingsButtonCol span={8}>
                <Row type="flex" gutter={[4, 0]}>
                  <Col>
                    <WidgetViewChanger value={viewType} onChange={onChangeViewType} />
                  </Col>
                  <Col>
                    <IconButton
                      tooltip={{ title: t('dashboardPage.widget.buttons.export') }}
                      button={{
                        icon: loadingExport ? (
                          <LoadingOutlined spin />
                        ) : (
                          <ExcelIcon color="rgba(38, 38, 38, 0.75)" />
                        ),
                        onClick: () =>
                          loadingExport ? null : exportWidgetData({ body, setLoadingExport }),
                        size: 'icon'
                      }}
                    />
                  </Col>
                  <Col>
                    <SettingsModal widgetData={widgetData} />
                  </Col>
                </Row>
              </SettingsButtonCol>
            </Row>
            {viewType === WIDGET_VIEW_MODES.TABLE.value ? (
              <TableView
                columns={tableData.tableColumns.filter(item => item.key !== 'unitName')}
                rows={tableData.tableRows}
              />
            ) : (
              <ChartView chartData={chartData} widgetId={id} selectedUserId={selectedUserId} />
            )}
          </LargeWidgetCard>
        </Col>
      )}
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  const widgetId = ownProps.widgetData.id;
  const usersByIds = state.usersResource.byIds;
  const unitsByIds = state.unitsResource.byIds;

  const checklistDefinitionsByIds = state.checklistDefinitionsResource.byIds;
  const selectedUserId = get(
    state,
    `uiDashboard.chartsView.widgets.${widgetId}.selectedUserId`,
    {}
  );

  const isVisibeReviewerName = getCurrentUserPermissions(state).includes(
    PERMISSIONS.CAN_SEE_REVIEWER_NAME
  );

  return {
    widgetsAnalyticsByIds: state.dashboardAnalytics.byIds,
    usersByIds,
    unitsByIds,
    checklistDefinitionsByIds,
    selectedUserId,
    isVisibeReviewerName
  };
};

const mapDispatchToProps = {
  updateDashboardWidget
};

export default connect(mapStateToProps, mapDispatchToProps)(TableReviewsCountByReviewers);
