import { Col, Row } from 'antd';
import { Info } from 'components/Info';
import SpecificInput from 'components/Inputs/SpecificInput';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const CommunicationIdInput = ({ value, onChange, disabled }) => {
  const { t } = useTranslation();
  return (
    <Col span={24}>
      <Row gutter={[8, 8]} style={{ margin: '-4px' }} align="middle">
        <Col span={1}>
          <Info
            tooltip={{ title: t('customCommunicationPage.form.messages.communicationIdTooltip') }}
          />
        </Col>
        <Col span={23}>
          <SpecificInput
            value={value}
            onChange={onChange}
            placeholder={t('customCommunicationPage.form.fields.communicationId')}
            disabled={disabled}
          />
        </Col>
      </Row>
    </Col>
  );
};
