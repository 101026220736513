import { LoadingOutlined } from '@ant-design/icons';
import { Typography, Alert } from 'antd';
import SCol from 'components/Standard/SCol';
import SRow from 'components/Standard/SRow';
import { MAX_COUNT_WIDGET_CLIENT_INTERACTIONS } from 'core/utils/constants';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { loadTableMeta } from 'redux/ui/clientInteractions/operations';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const ClientInteractionsMeta = ({ loadMeta, loading, filters = {}, meta, type }) => {
  const { Text } = Typography;
  const { t } = useTranslation();

  const getMeta = async ({ filters }) => {
    const meta = await loadMeta({ options: { filters } });
    return meta;
  };

  useEffect(() => {
    getMeta({
      filters: {
        ...filters,
        is_reviewed: true
      }
    });
  }, [filters]);

  const isVisibleSlowSortMessage =
    meta?.totalCount > MAX_COUNT_WIDGET_CLIENT_INTERACTIONS.SLOW_SORT_COUNT &&
    meta?.totalCount <= MAX_COUNT_WIDGET_CLIENT_INTERACTIONS.MAX_CLIENT_INTERACTIONS_COUNT &&
    type === 'table_reviews_with_scores';

  const isVisibleMaxCommunicationsCountMessage =
    meta?.totalCount > MAX_COUNT_WIDGET_CLIENT_INTERACTIONS.SLOW_SORT_COUNT &&
    meta?.totalCount > MAX_COUNT_WIDGET_CLIENT_INTERACTIONS.MAX_CLIENT_INTERACTIONS_COUNT &&
    type === 'table_reviews_with_scores';

  return (
    <SCol span={24}>
      <SRow type="flex" align="middle" justify="start">
        <SCol>
          {isVisibleSlowSortMessage && (
            <StyledAlert
              message={<Text>{t('dashboardPage.widgetMeta.longSortMessage')}</Text>}
              type="warning"
            />
          )}
          {isVisibleMaxCommunicationsCountMessage && (
            <Alert
              message={<Text>{t('dashboardPage.widgetMeta.maxItemsCount')}</Text>}
              type="error"
            />
          )}
        </SCol>
        <SCol marginTop="8px">
          <Text>{t('dashboardPage.widgetMeta.foundReviews')}</Text>
          {loading ? (
            <LoadingOutlined style={{ color: '#0061D0' }} />
          ) : (
            <Text strong>{meta?.totalCount}</Text>
          )}
        </SCol>
      </SRow>
    </SCol>
  );
};

const StyledAlert = styled(Alert)`
  &.ant-alert-warning {
    border: 1px solid var(--orange_primary);
  }
`;

const mapStateToProps = state => ({
  loading: state.uiClientInteractions.loadingMeta,
  meta: state.uiClientInteractions.meta,
  filters: state.uiWidgetSettingsModal.filters,
  type: state.uiWidgetSettingsModal.type
});

const mapDispatchToProps = {
  loadMeta: loadTableMeta
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientInteractionsMeta);
