import { Button, Checkbox, Col, Form, Input, message, Row, Select, Typography } from 'antd';
import SModal from 'components/Standard/SModal';
import { usePrevious } from 'core/utils/hooks';
import { find, isEmpty, isEqual, isNil } from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { unitsResource } from 'redux/resources/units';
import { usersResource } from 'redux/resources/users';
import { resetEmployeeModal } from 'redux/ui/employeeModal/reducer';

const { Option } = Select;

const EmployeeModal = ({
  isVisible = false,
  createUser,
  updateUser,
  inviteUser,
  users,
  units,
  roles,
  loadUnits,
  resetEmployeeModal,
  user = {},
  isEditMode,
  onCreateUser = () => {},
  updateByIdStarted,
  createStarted
}) => {
  useEffect(() => {
    loadUnits();
  }, []);

  const {
    id,
    firstName,
    lastName,
    email,
    unitId,
    roleId,
    integrationUid,
    collisionsOnCreate,
    originIntegrationId
  } = user;

  const initialValues = {
    lastName,
    firstName,
    email: isEmpty(email) ? (collisionsOnCreate || {})?.email : email,
    roleId,
    integrationUid,
    unitId,
    invite: true
  };

  const [form] = Form.useForm();

  const prevInitialValues = usePrevious(initialValues);

  useEffect(() => {
    if (!isEqual(prevInitialValues, initialValues)) {
      form.setFieldsValue(initialValues);
    }
  }, [initialValues, prevInitialValues, isVisible]);

  const { t } = useTranslation();

  const closeModal = () => {
    resetEmployeeModal();
    form.resetFields();
  };

  const createNewEmployee = async ({ values }) => {
    const {
      firstName,
      lastName,
      email,
      roleId,
      unitId,
      integrationUid,
      invite: shouldInvite
    } = values;

    const createdUser = await createUser({
      firstName,
      lastName,
      email,
      integrationUid,
      unitId,
      roleId
    });

    if (createdUser) {
      shouldInvite && inviteUser({ id: createdUser.id });
      onCreateUser();
      closeModal();
      message.success(
        `${t(
          'organizationStructure.tabs.employees.modal.messages.employee'
        )} ${lastName} ${firstName} ${t(
          'organizationStructure.tabs.employees.modal.messages.created'
        )}`
      );
    }
  };

  const updateEmployee = async ({ values }) => {
    const user = find(users, ['id', id]);
    const { firstName, lastName, roleId, unitId, integrationUid, email } = values;

    const objectToUpdate = {
      id,
      firstName,
      lastName,
      roleId,
      unitId
    };

    if (integrationUid !== user.integrationUid) {
      objectToUpdate.integrationUid = integrationUid;
    }

    if (email !== user.email) {
      objectToUpdate.email = email;
    }

    const updatedUser = await updateUser({
      ...objectToUpdate
    });

    if (updatedUser) {
      closeModal();

      message.success(
        `${t(
          'organizationStructure.tabs.employees.modal.messages.employee'
        )} ${lastName} ${firstName} ${t(
          'organizationStructure.tabs.employees.modal.messages.updated'
        )}`
      );
    }
  };

  const { Text } = Typography;

  const handleSubmit = values => {
    isEditMode ? updateEmployee({ values }) : createNewEmployee({ values });
  };

  return (
    <SModal
      title={t('organizationStructure.tabs.employees.modal.title')}
      centered
      visible={isVisible}
      onCancel={closeModal}
      confirmLoading={updateByIdStarted}
      footer={null}
      destroyOnClose
      size="small"
    >
      <Form
        layout="vertical"
        onFinish={handleSubmit}
        scrollToFirstError
        form={form}
        initialValues={initialValues}
      >
        <Form.Item
          label={
            <Text strong>
              {t('organizationStructure.tabs.employees.modal.form.fields.lastName')}
            </Text>
          }
          name="lastName"
          rules={[
            {
              required: true,
              message: t('organizationStructure.tabs.employees.modal.form.messages.enterLastName'),
              whitespace: true
            }
          ]}
        >
          <Input
            placeholder={t(
              'organizationStructure.tabs.employees.modal.form.fields.lastNamePlaceholder'
            )}
          />
        </Form.Item>

        <Form.Item
          label={
            <Text strong>
              {t('organizationStructure.tabs.employees.modal.form.fields.firstName')}
            </Text>
          }
          name="firstName"
          rules={[
            {
              required: true,
              message: t('organizationStructure.tabs.employees.modal.form.messages.enterFirstName'),
              whitespace: true
            }
          ]}
        >
          <Input
            placeholder={t(
              'organizationStructure.tabs.employees.modal.form.fields.firstNamePlaceholder'
            )}
          />
        </Form.Item>

        <Form.Item
          label={
            <Text strong>{t('organizationStructure.tabs.employees.modal.form.fields.email')}</Text>
          }
          name="email"
          rules={[
            {
              required: true,
              message: t('organizationStructure.tabs.employees.modal.form.messages.enterEmail')
            },
            {
              type: 'email',
              message: t('organizationStructure.tabs.employees.modal.form.messages.enterValidEmail')
            }
          ]}
        >
          <Input
            placeholder={t(
              'organizationStructure.tabs.employees.modal.form.fields.emailPlaceHolder'
            )}
          />
        </Form.Item>

        <Form.Item
          label={
            <Text strong>
              {t('organizationStructure.tabs.employees.modal.form.fields.integrationId')}
            </Text>
          }
          name="integrationUid"
        >
          <Input
            placeholder={t(
              'organizationStructure.tabs.employees.modal.form.fields.integrationIdPlaceholder'
            )}
            disabled={isEditMode && !isNil(originIntegrationId)}
          />
        </Form.Item>

        <Form.Item
          label={
            <Text strong>{t('organizationStructure.tabs.employees.modal.form.fields.role')}</Text>
          }
          name="roleId"
          rules={[
            {
              required: true,
              message: t('organizationStructure.tabs.employees.modal.form.messages.enterRole')
            }
          ]}
        >
          <Select
            placeholder={t(
              'organizationStructure.tabs.employees.modal.form.fields.rolePlaceholder'
            )}
            style={{ width: '100%' }}
          >
            {roles.map(role => (
              <Option value={role.id} key={role.id}>
                {role.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label={
            <Text strong>{t('organizationStructure.tabs.employees.modal.form.fields.unit')}</Text>
          }
          rules={[
            {
              required: true,
              message: t('organizationStructure.tabs.employees.modal.form.messages.enterUnit')
            }
          ]}
          name="unitId"
        >
          <Select
            placeholder={t(
              'organizationStructure.tabs.employees.modal.form.fields.unitPlaceHolder'
            )}
            style={{ width: '100%' }}
          >
            {units.map(unit => (
              <Option value={unit.id} key={unit.id}>
                {unit.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        {!isEditMode && (
          <Form.Item
            name="invite"
            valuePropName="checked"
            rules={[
              {
                transform: value => value || undefined,
                type: 'boolean'
              }
            ]}
          >
            <Checkbox>
              <Text strong>
                {t('organizationStructure.tabs.employees.modal.buttons.sendInvite')}
              </Text>
            </Checkbox>
          </Form.Item>
        )}

        <Row type="flex" justify="end" gutter={[16, 16]} style={{ margin: '-8px' }}>
          <Col>
            <Form.Item style={{ marginBottom: 0 }}>
              <Button onClick={() => closeModal()}>
                {t('organizationStructure.tabs.employees.modal.buttons.cancel')}
              </Button>
            </Form.Item>
          </Col>

          <Col>
            <Form.Item style={{ marginBottom: 0 }}>
              <Button type="primary" htmlType="submit" loading={updateByIdStarted || createStarted}>
                {t('organizationStructure.tabs.employees.modal.buttons.save')}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </SModal>
  );
};

const mapStateToProps = state => {
  return {
    editableUserData: state.uiEmployeeModal,
    user: state.usersResource.byIds[state.uiEmployeeModal.userId],
    isEditMode: state.uiEmployeeModal.isEditMode,
    isVisible: state.uiEmployeeModal.isVisibleEmployeeModal,
    users: Object.values(state.usersResource.byIds),
    units: Object.values(state.unitsResource.byIds),
    roles: Object.values(state.rolesResource.byIds),
    updateByIdStarted: state.usersResource.updateByIdStarted,
    createStarted: state.usersResource.createStarted
  };
};

const mapDispatchToProps = {
  createUser: usersResource.operations.create,
  updateUser: usersResource.operations.updateById,
  loadUnits: unitsResource.operations.load,
  inviteUser: usersResource.operations.inviteById,
  resetEmployeeModal
};

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeModal);
