import React from 'react';
import { Col, Row } from 'antd';
import moment from 'moment';
import { isParentHidden as checkIsParentHidden } from 'redux/selectors/comments';
import { useTranslation } from 'react-i18next';
import { themeColors } from 'core/utils/colors';
import { useSelector } from 'react-redux';
import DOMPurify from 'dompurify';
import { StyledDiv, StyledUserPreview, StyledRow, StyledDivider, CommentContainer } from './styled';

const CommentContent = ({
  id,
  authorId,
  createdAt,
  text,
  hidden,
  colorName,
  metadata,
  showColor = false
}) => {
  const color = themeColors[colorName] || 'gray';
  const { t } = useTranslation();
  const isParentHidden = useSelector(state =>
    checkIsParentHidden({
      commentsByIds: state.commentsResource.byIds,
      commentId: id || ''
    })
  );
  return (
    <>
      {(!isParentHidden || !hidden) && (
        <CommentContainer showColor={showColor} color={color}>
          <Row align="middle" justify="space-between">
            <Col span={6}>
              <StyledUserPreview userId={authorId} hidden />
            </Col>
            <Col>
              {`${t('components.commentsPreview.leftACommentStr')} ${moment(createdAt).format(
                'HH:mm, DD/MM/YYYY'
              )}${
                metadata?.timestamp
                  ? `, ${t('components.timestamp').toLowerCase()} ${moment()
                      .startOf('day')
                      .seconds(metadata.timestamp)
                      .format('mm:ss')}`
                  : ''
              }
                  `}
            </Col>
          </Row>
          <StyledDiv
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(text, {
                ALLOWED_ATTR: ['target', 'href']
              })
            }}
          />
          <StyledDivider />
        </CommentContainer>
      )}
    </>
  );
};

export default CommentContent;
