import { Typography } from 'antd';
import { CUSTOM_FIELDS_TYPES } from 'core/utils/constants';
import { find, get } from 'lodash';
import moment from 'moment';
import React from 'react';

const getCustomFieldSelectValue = ({ customFields = {}, customFieldsByKeys, customFieldKey }) => {
  // FIXME: remove toString conversion after Qiwi fix their keys
  const getText = key =>
    get(find(customFieldsByKeys[customFieldKey]?.settings, { key: key + '' }), 'text', '');

  if (Array.isArray(customFields[customFieldKey])) {
    return customFields[customFieldKey]
      .map(element => {
        if (Array.isArray(element)) {
          return element.map(getText).join(', ');
        }
      })
      .join(' / ');
  }

  return getText(customFields[customFieldKey]);
};

const CustomFieldValueView = ({ customFields, customFieldsByKeys, customFieldKey, value }) => {
  const fieldType = customFieldsByKeys?.[customFieldKey]?.fieldType;
  const fieldValue = value || get(customFields, customFieldKey, []);

  const { Text } = Typography;

  switch (fieldType) {
    case CUSTOM_FIELDS_TYPES.DATETIME:
      return <Text>{moment(fieldValue).format('DD/MM/YYYY, HH:mm')}</Text>;

    case CUSTOM_FIELDS_TYPES.STRING_ARRAY:
      return <Text>{fieldValue.join(', ')}</Text>;

    case CUSTOM_FIELDS_TYPES.NUMBER_ARRAY:
      return <Text>{fieldValue.join(', ')}</Text>;

    case CUSTOM_FIELDS_TYPES.BOOLEAN:
      return <Text>{fieldValue.toString()}</Text>;

    case CUSTOM_FIELDS_TYPES.SELECT:
      return (
        <Text>
          {getCustomFieldSelectValue({
            customFields,
            customFieldsByKeys,
            customFieldKey
          })}
        </Text>
      );

    default:
      return <Text>{fieldValue}</Text>;
  }
};

export default CustomFieldValueView;
