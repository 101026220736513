import { Button, Col, message, Modal, Radio, Row, Select, Tooltip } from 'antd';
import ChecklistDefinitionsSelect from 'components/Checklist/ChecklistDefinitionsSelect';
import TimeSpecificSelector from 'components/Inputs/TimeSpecificSelector';
import SCard from 'components/Standard/SCard';
import { CHECKLIST_DEFINITION_STATUS } from 'core/utils/constants';
import { selectSearch } from 'core/utils/selectSearch';
import ExcelIcon from 'images/ExcelIcon';
import { get, isEmpty } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { exportTeamPage } from 'redux/entities/exports/operations';
import { getChecklistsDefinitionsByParams } from 'redux/selectors/checklists';
import { getUnitsWithActiveUsers } from 'redux/selectors/users';
import { changeViewType, updateFilters } from 'redux/ui/teamAnalyticsPage/reducer';

const PageFilters = ({
  checklistDefinitions,
  units,
  loading,
  updateFilters,
  filters,
  exportTeamPage,
  viewType,
  changeViewType
}) => {
  const { Option } = Select;
  const { t } = useTranslation();

  const exportAnalytics = async ({ filters }) => {
    try {
      const result = await exportTeamPage({
        filters: {
          ...filters,
          checklistDefinitionsIds:
            filters.checklistDefinitionsIds === 'all' ? [] : filters.checklistDefinitionsIds,
          clientInteractionSymbolicTimeRange:
            filters.clientInteractionSymbolicTimeRange === 'all'
              ? undefined
              : filters.clientInteractionSymbolicTimeRange
        }
      });
      if (get(result, 'status', 200) === 200) {
        Modal.info({
          title: t('teamPage.pageFilters.exportModal.title'),
          content: t('teamPage.pageFilters.exportModal.description'),
          onOk: () => {}
        });
      } else {
        message.error(t('teamPage.pageFilters.messages.exportTableFailed'));
      }
    } catch (error) {
      console.log(error);
      message.error(t('teamPage.pageFilters.messages.exportTableFailed'));
    }
  };

  return (
    <SCard>
      <Row>
        <Col span={12}>
          <Row type="flex" gutter={[8, 0]} style={{ marginBottom: '-4px' }}>
            <Col>
              <Radio.Group value={viewType} onChange={e => changeViewType(e.target.value)}>
                <Radio.Button value="employees">{t('teamPage.buttons.employees')}</Radio.Button>
                <Radio.Button value="units">{t('teamPage.buttons.units')}</Radio.Button>
              </Radio.Group>
            </Col>
            <Col lg={9} xl={8} xxl={8}>
              <Select
                placeholder={t('teamPage.pageFilters.filters.unitPlaceholder')}
                style={{ width: '100%' }}
                onChange={value => updateFilters({ unitsIds: value })}
                loading={loading}
                value={isEmpty(filters.unitsIds) ? units.map(unit => unit.id) : filters.unitsIds}
                mode="multiple"
                allowClear
                maxTagCount={0}
                maxTagPlaceholder={selectedKeys =>
                  `${t('teamPage.pageFilters.filters.unitPlaceholderMax')} ${
                    selectedKeys.length
                  } ${t('teamPage.pageFilters.filters.unitPlaceholderMax2')}`
                }
              >
                {units.map(unit => (
                  <Option key={unit.id} value={unit.id}>
                    {unit.name}
                  </Option>
                ))}
              </Select>
            </Col>
            <Col lg={9} xl={8} xxl={8}>
              <ChecklistDefinitionsSelect
                placeholder={t('teamPage.pageFilters.filters.checklistPlaceholder')}
                checklistDefinitions={checklistDefinitions}
                loading={loading}
                onChange={value => updateFilters({ checklistDefinitionsIds: value })}
                value={
                  isEmpty(filters.checklistDefinitionsIds) ? 'all' : filters.checklistDefinitionsIds
                }
                style={{ width: '100%' }}
                showSearch
                allowClear
                filterOption={(input, option) =>
                  selectSearch({ input, option, searchProp: 'searchValue' })
                }
                allowAll
              />
            </Col>
          </Row>
        </Col>
        <Col span={12}>
          <Row type="flex" justify="end" gutter={[8, 0]} style={{ marginBottom: '-4px' }}>
            <Col>
              <Tooltip title={t('teamPage.buttons.exportTeamPageAnalytics')}>
                <Button
                  type="link"
                  onClick={() => exportAnalytics({ filters })}
                  style={{ fontSize: 0 }}
                >
                  <ExcelIcon color="rgba(38, 38, 38, 0.75)" />
                </Button>
              </Tooltip>
            </Col>
            <Col lg={7} xl={7} xxl={8}>
              <TimeSpecificSelector
                style={{ width: '100%' }}
                format="DD/MM/YYYY"
                showTime={false}
                ranges={null}
                showToday={false}
                value={{
                  timeFrom: filters.clientInteractionTimeFrom,
                  timeTo: filters.clientInteractionTimeTo,
                  symbolicTimeRange: filters.clientInteractionSymbolicTimeRange
                }}
                onChange={({ timeFrom, timeTo, symbolicTimeRange }) => {
                  updateFilters({
                    clientInteractionTimeFrom: timeFrom,
                    clientInteractionTimeTo: timeTo,
                    clientInteractionSymbolicTimeRange: symbolicTimeRange
                  });
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </SCard>
  );
};

const mapStateToProps = state => {
  const { filters } = state.uiTeamAnalyticsPage;
  const { viewType } = state.uiTeamAnalyticsPage;

  const checklistDefinitions = getChecklistsDefinitionsByParams(state, {
    params: {
      unitsIds: [],
      statuses: [
        CHECKLIST_DEFINITION_STATUS.ARCHIVED.value,
        CHECKLIST_DEFINITION_STATUS.PUBLISHED.value
      ],
      communicationTypes: []
    }
  });

  return {
    loading: state.checklistDefinitionsResource.loading || state.unitsResource.loading,
    checklistDefinitions,
    units: getUnitsWithActiveUsers(state),
    filters,
    viewType
  };
};

const mapDispatchToProps = {
  updateFilters,
  exportTeamPage,
  changeViewType
};

export default connect(mapStateToProps, mapDispatchToProps)(PageFilters);
