/* eslint-disable jsx-a11y/accessible-emoji */
import { Col, Row } from 'antd';
import React from 'react';
import MainControlsPanel from '../MainControlsPanel';
import AnalyticsChartsPanel from './AnalyticsChartsPanel';

const UserAnalyticsPage = () => {
  return (
    <Row gutter={[8, 8]} style={{ margin: '-4px' }}>
      <Col span={24}>
        <MainControlsPanel />
      </Col>
      <Col span={24}>
        <AnalyticsChartsPanel />
      </Col>
    </Row>
  );
};

export default UserAnalyticsPage;
