import Icon from 'components/Icon';
import SButton from 'components/Standard/SButton';
import SCard from 'components/Standard/SCard';
import SCol from 'components/Standard/SCol';
import SRow from 'components/Standard/SRow';
import SText from 'components/Standard/SText';
import React, { useState, useEffect } from 'react';
import { MoreVertical } from 'react-feather';
import moment from 'moment';
import { Dropdown, Menu, message, Modal } from 'antd';
import { appellationsResource } from 'redux/resources/appellations';
import { useDispatch, useSelector } from 'react-redux';
import { APPEALS_STATUSES, PERMISSIONS } from 'core/utils/constants';
import { OutlinedButton } from 'components/Buttons';
import { useTranslation } from 'react-i18next';
import { getCurrentUser, getCurrentUserPermissions } from 'redux/selectors/users';
import { isEqual, get, keyBy } from 'lodash';
import { getChecklistsByIds, getChecklistsDefinitionsByIds } from 'redux/selectors/checklists';
import { getChecklistDefinitionQuestionGroupsBeforeQuestions } from 'redux/selectors/checklistItems/checklistItems';
import { useHistory } from 'react-router-dom';
import QuestionObjectionCard from './QuestionObjectionCard';

const changeStatus = ({ appealId, status }) => async dispatch =>
  dispatch(appellationsResource.operations.changeStatus({ id: appealId, status }));

export const AppealStatus = ({ loading, appeal }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const currentUser = useSelector(getCurrentUser, isEqual);
  const currentUserPermissions = useSelector(getCurrentUserPermissions, isEqual);
  const objectionsByIds = useSelector(state => state.appellationObjectionsResource.byIds);

  const [loadingStatus, setLoadingStatus] = useState(null);
  const [showControls, setShowControls] = useState(
    ![
      APPEALS_STATUSES.rejected.value,
      APPEALS_STATUSES.accepted.value,
      APPEALS_STATUSES.without_answer.value
    ].includes(appeal?.status) && currentUser?.id === appeal?.assignedToId
  );

  const review = useSelector(state => state.reviewsResource.byIds[appeal?.reviewId], isEqual);
  const checklist = useSelector(state => getChecklistsByIds(state)[review?.checklistId], isEqual);

  const checklistDefinitionId = checklist?.checklistDefinitionId;
  const checklistDefinition = useSelector(
    state => state.checklistDefinitionsResource.byIds[checklistDefinitionId],
    isEqual
  );

  const questionsByIds = useSelector(
    state => getChecklistDefinitionQuestionGroupsBeforeQuestions(state, checklistDefinition),
    isEqual
  );
  const checklistDefinitionName = useSelector(state => {
    return getChecklistsDefinitionsByIds(state)[checklist?.checklistDefinitionId]?.name || '';
  });

  const canManageStatus = currentUser?.id === appeal?.assignedToId;
  const canDeleteAppeal = currentUserPermissions.includes(
    PERMISSIONS.CAN_ADMINISTRATE_APPELLATIONS
  );

  const changeStatusHandler = status => async () => {
    try {
      setLoadingStatus(status);
      const result = await dispatch(changeStatus({ appealId: appeal.id, status }));
    } catch (error) {
      console.log(error);
    }

    setLoadingStatus(null);
  };

  const handleMenuClick = async e => {
    const action = e.key;
    const actions = {
      deleteAppeal: () =>
        Modal.confirm({
          title: t('appealsPage.confirmDelete'),
          okType: 'danger',
          onOk: async () => {
            try {
              await dispatch(appellationsResource.operations.deleteById({ id: appeal?.id }));
              history.push('/appeals');
              message.success(t('messages.success.appealDeleted'));
            } catch (error) {
              console.log(error);
            }
          }
        }),
      toPendingStatus: changeStatusHandler(APPEALS_STATUSES.pending.value)
    };
    await actions[action]();
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      {canManageStatus && appeal?.status !== APPEALS_STATUSES.pending.value && (
        <Menu.Item key="toPendingStatus">{t('appealsPage.appealStatus.notProcessed')}</Menu.Item>
      )}
      {canDeleteAppeal && (
        <Menu.Item key="deleteAppeal">
          <SText color="var(--red_primary)">{t('general.delete')}</SText>
        </Menu.Item>
      )}
    </Menu>
  );

  useEffect(() => {
    setShowControls(
      ![
        APPEALS_STATUSES.rejected.value,
        APPEALS_STATUSES.accepted.value,
        APPEALS_STATUSES.without_answer.value
      ].includes(appeal?.status) && currentUser?.id === appeal?.assignedToId
    );
  }, [appeal?.status, currentUser?.id, appeal?.assignedToId]);

  const getSortedObjectionQuestions = () => {
    const objections = get(appeal, 'objectionsIds', []).map(
      objectionId => objectionsByIds[objectionId]
    );
    const questionsIds = objections.map(objection => objection?.questionId);

    return questionsByIds.reduce((acc, question) => {
      const objectionsByQuestionId = keyBy(objections, 'questionId');

      if (!questionsIds.includes(question?.id)) return acc;

      const data = {
        ...question,
        objectionId: objectionsByQuestionId[question?.id]?.id
      };

      return [...acc, data];
    }, []);
  };

  const sortedObjectionQuestions = getSortedObjectionQuestions();

  return (
    <SCard shadowed bordered loading={loading || !appeal}>
      <SRow gutter={[0, 20]} style={{ marginBottom: '-10px' }}>
        {showControls && (
          <SCol span={18} data-testid="appeal-status-change-buttons">
            <SRow justify="space-between">
              <SCol order={1}>
                <SRow gutter={[20, 8]}>
                  {APPEALS_STATUSES.pending.value === appeal?.status && (
                    <SCol>
                      <SButton
                        type="primary"
                        onClick={changeStatusHandler(APPEALS_STATUSES.in_progress.value)}
                        loading={APPEALS_STATUSES.in_progress.value === loadingStatus}
                      >
                        {t('appealsPage.appealStatus.process')}
                      </SButton>
                    </SCol>
                  )}

                  {(APPEALS_STATUSES.in_progress.value === appeal?.status ||
                    APPEALS_STATUSES.needs_information.value === appeal?.status) && (
                    <>
                      <SCol>
                        <OutlinedButton
                          onClick={changeStatusHandler(APPEALS_STATUSES.accepted.value)}
                          loading={APPEALS_STATUSES.accepted.value === loadingStatus}
                        >
                          {t('general.accept')}
                        </OutlinedButton>
                      </SCol>

                      <SCol>
                        <OutlinedButton
                          onClick={changeStatusHandler(APPEALS_STATUSES.needs_information.value)}
                          loading={APPEALS_STATUSES.needs_information.value === loadingStatus}
                          disabled={APPEALS_STATUSES.needs_information.value === appeal?.status}
                        >
                          {t('appealsPage.appealStatus.needsInformation')}
                        </OutlinedButton>
                      </SCol>
                      <SCol>
                        <OutlinedButton
                          onClick={changeStatusHandler(APPEALS_STATUSES.rejected.value)}
                          loading={APPEALS_STATUSES.rejected.value === loadingStatus}
                        >
                          {t('general.reject')}
                        </OutlinedButton>
                      </SCol>
                      <SCol>
                        <OutlinedButton
                          onClick={changeStatusHandler(APPEALS_STATUSES.without_answer.value)}
                          loading={APPEALS_STATUSES.without_answer.value === loadingStatus}
                        >
                          {t('appealsPage.appealStatus.withoutAnswer')}
                        </OutlinedButton>
                      </SCol>
                    </>
                  )}
                </SRow>
              </SCol>
            </SRow>
          </SCol>
        )}

        <SCol span={18} order={showControls ? 3 : 1}>
          <SRow gutter={[0, 12]} style={{ marginBottom: '-6px' }}>
            <SCol span={24}>
              <SText fontSize="20px" fontWeight={500}>
                {t('appealsPage.appealStatus.appealInfo')}
              </SText>
            </SCol>
            <SCol span={24}>
              <SRow gutter={[20, 0]}>
                <SCol>
                  <SText>{`${t('general.checklistDefinition')}: `}</SText>
                  <SText fontWeight="600">{checklistDefinitionName}</SText>
                </SCol>
                <SCol>
                  <SText>{`${t('general.applicationDate')}: `}</SText>
                  <SText fontWeight="600">{moment().format('DD/MM/YYYY')}</SText>
                </SCol>
                <SCol>
                  <SText>{`${t('general.time')}: `}</SText>
                  <SText fontWeight="600">{moment().format('HH:mm')}</SText>
                </SCol>
              </SRow>
            </SCol>
          </SRow>
          <SRow gutter={[8, 8]}>
            {sortedObjectionQuestions.map(question => (
              <SCol span={22}>
                <QuestionObjectionCard
                  appeal={appeal}
                  question={question}
                  review={review}
                  objection={objectionsByIds[question?.objectionId]}
                />
              </SCol>
            ))}
          </SRow>
        </SCol>

        <SCol span={6} order={2}>
          <SRow gutter={[16, 0]} align="middle" justify="end">
            <SCol>
              <SText
                fontSize="14px"
                fontWeight="600"
                color={APPEALS_STATUSES[appeal?.status]?.color}
              >
                {t(APPEALS_STATUSES[appeal?.status]?.title || '')}
              </SText>
            </SCol>

            {(canDeleteAppeal ||
              (canManageStatus && appeal?.status !== APPEALS_STATUSES.pending.value)) && (
              <SCol>
                <Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
                  <SButton
                    type="link"
                    size="icon"
                    icon={
                      <Icon
                        icon={MoreVertical}
                        color="var(--blue_2)"
                        style={{ marginTop: '-0.9px' }}
                        size="24px"
                      />
                    }
                    strokeWidth="1"
                  />
                </Dropdown>
              </SCol>
            )}
          </SRow>
        </SCol>
      </SRow>
    </SCard>
  );
};
