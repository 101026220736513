import Icon from 'components/Icon';
import SCol from 'components/Standard/SCol';
import React, { useState } from 'react';
import { X } from 'react-feather';
import Lightbox from 'react-awesome-lightbox';
import { findIndex } from 'lodash';
import SRow from 'components/Standard/SRow';
import SButton from 'components/Standard/SButton';
import { useSelector } from 'react-redux';
import { getCurrentUser } from 'redux/selectors/users';
import SText from 'components/Standard/SText';

const UploadedFile = ({ onDelete, uploadedFile, allFiles, style = {} }) => {
  const [visible, setVisible] = useState(false);
  const isCreator = useSelector(state => getCurrentUser(state).id === uploadedFile?.creatorId);
  const show = () => {
    setVisible(true);
  };

  const deleteFile = e => {
    e.stopPropagation();
    console.log('delete', e);
    onDelete(uploadedFile?.id);
  };

  const onClose = e => {
    e.stopPropagation();
    setVisible(false);
  };

  return (
    <SCol
      background="var(--gray_primary)"
      borderRadius="4px"
      padding="4px 8px"
      margin="4px 0"
      maxWidth="80%"
      onClick={e => {
        e.stopPropagation();
        show();
      }}
      width="fit-content"
      style={style}
    >
      <SRow justify="space-between" align="middle" flexFlow="row nowrap">
        <SCol className="truncated" span={isCreator ? 22 : 24}>
          <SButton
            height="21px"
            display="flex"
            alignItems="center"
            fontWeight="500"
            padding="0"
            type="link"
            fontSize="14px"
            width="100%"
          >
            <SText width="95%" ellipsis type="primary" color="var(--blue_6)">
              {uploadedFile?.name}
            </SText>
          </SButton>
        </SCol>
        {isCreator && onDelete && (
          <SCol display="flex" alignItems="center" span={2}>
            <Icon icon={X} onClick={deleteFile} color="var(--black_6)" />
          </SCol>
        )}
      </SRow>
      {visible && (
        <Lightbox
          images={allFiles.map(file => ({ title: file.name, url: file.url }))}
          startIndex={findIndex(allFiles, ({ id }) => id === uploadedFile?.id)}
          onClose={onClose}
        />
      )}
    </SCol>
  );
};

export default UploadedFile;
