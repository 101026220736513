import { Trash2, MoreVertical, Power, Settings } from 'react-feather';
import { Button, Col, Dropdown, Menu, message, Modal, Row, Typography } from 'antd';
import CustomTag from 'components/Tags/CustomTag';
import List from 'components/List';
import UserPreview from 'components/UserPreview';
import { TASK_DEFINITIONS_TYPES } from 'core/utils/constants';
import truncateString from 'core/utils/truncateString';
import { compact, get, throttle } from 'lodash';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { actions, operations } from 'redux/lists/taskDefinitionsListReducer';
import { taskDefinitionsResource } from 'redux/resources/taskDefinitions';
import Icon from 'components/Icon';

const TaskDefinitionsList = ({
  loading,
  page,
  hasNextPage,
  taskDefinitions,
  updatePage,
  clearList,
  history,
  loadTaskDefinitions,
  updateTaskDefinition,
  deleteTaskDefinition,
  taskDefinitionsByIds,
  checklistDefinitionsByIds
}) => {
  const { Text } = Typography;
  const { t } = useTranslation();
  useEffect(() => {
    clearList();
    return clearList;
  }, []);

  useEffect(() => {
    loadTaskDefinitions({ page, include: 'creator,checklist_definition', sort: '-created_at' });
  }, [page.number]);

  const onScrollY = throttle(async el => {
    const isOnBottom = (element => {
      return element.clientHeight + element.scrollTop + 20 >= element.scrollHeight;
    })(el);

    if (hasNextPage && !loading && isOnBottom) {
      const newPage = `${parseInt(page.number) + 1}`;
      updatePage({
        number: newPage
      });
    }
  }, 250);

  const getChecklistDefinitionName = ({ taskDefinitionId }) => {
    const taskDefinition = taskDefinitionsByIds[taskDefinitionId];
    const { checklistDefinitionId } = taskDefinition;
    const checklistDefinition = checklistDefinitionsByIds[checklistDefinitionId];
    return taskDefinition.labelType === TASK_DEFINITIONS_TYPES.AUTOMATIC
      ? truncateString(get(checklistDefinition, 'name', '-'), 30)
      : '-';
  };

  const handleMenuClick = ({ key, id }) => {
    const taskDefinition = taskDefinitionsByIds[id];
    const actions = {
      settings: () => history.push(`/settings/triggers/edit/task-definition/${id}`),
      poweroff: async () => {
        const result = await updateTaskDefinition({
          ...taskDefinition,
          status: taskDefinition.status === 'disabled' ? 'active' : 'disabled'
        });
        if (result)
          message.success(
            t('triggersSettingsPage.editTaskDefinition.messages.tagSuccessfullyUpdated')
          );
      },
      delete: () =>
        Modal.confirm({
          title: t('triggersSettingsPage.card.confirmDelete.title'),
          okText: t('triggersSettingsPage.card.confirmDelete.ok'),
          cancelText: t('triggersSettingsPage.card.confirmDelete.cancel'),
          okType: 'danger',
          onOk: async () => {
            const result = await deleteTaskDefinition({ id });
            if (result)
              message.success(
                t('triggersSettingsPage.editTaskDefinition.messages.tagSuccessfullyDeleted')
              );
          }
        })
    };
    actions[key]();
  };

  const getMenu = ({ id }) => (
    <Menu onClick={({ key }) => handleMenuClick({ key, id })}>
      <Menu.Item key="settings">
        <Icon icon={Settings} />
        <Text>{t('triggersSettingsPage.card.buttons.settings')}</Text>
      </Menu.Item>
      <Menu.Item key="poweroff">
        <Icon icon={Power} />
        <Text>
          {taskDefinitionsByIds[id]?.status === 'disabled'
            ? t('triggersSettingsPage.card.buttons.enable')
            : t('triggersSettingsPage.card.buttons.disable')}
        </Text>
      </Menu.Item>
      <Menu.Item key="delete" style={{ color: 'red' }}>
        <Icon icon={Trash2} />
        <span>{t('triggersSettingsPage.card.buttons.delete')}</span>
      </Menu.Item>
    </Menu>
  );

  const columns = {
    id: {
      render: (id, { labelColor, labelName }) => (
        <CustomTag truncate={25} color={labelColor} text={labelName} />
      ),
      col: { span: 5 }
    },
    creatorId: {
      render: creatorId => (
        <Row type="flex" align="middle" gutter={[4, 4]}>
          <Col>
            <Text strong>{t('triggersSettingsPage.card.info.author')}</Text>
          </Col>
          <Col>
            <UserPreview disabled userId={creatorId} showAvatar truncateSize={25} />
          </Col>
        </Row>
      ),
      col: { span: 7 }
    },
    createdAt: {
      render: createdAt => (
        <>
          <Text strong>{t('triggersSettingsPage.card.info.createdAt')}</Text>
          <Text>{moment(createdAt).format('DD/MM/YYYY')}</Text>
        </>
      ),
      col: { span: 3 }
    },
    status: {
      render: status => (
        <>
          <Text strong>{t('triggersSettingsPage.card.info.status.title')}</Text>
          <Text>
            {status === 'disabled'
              ? t('triggersSettingsPage.card.info.status.disable')
              : t('triggersSettingsPage.card.info.status.enable')}
          </Text>
        </>
      ),
      col: { span: 3 }
    },
    checklistDefinitionName: {
      render: (checklistDefinitionName, { id }) => (
        <>
          <Text strong>{t('triggersSettingsPage.card.info.checklistDefinition')}</Text>
          <Text>{getChecklistDefinitionName({ taskDefinitionId: id })}</Text>
        </>
      ),
      col: { span: 5 }
    },
    menu: {
      render: (taskDefinitionId, { id }) => (
        <Row type="flex" align="middle" justify="end">
          <Dropdown overlay={getMenu({ id })} trigger={['click']}>
            <Button icon={<Icon icon={MoreVertical} strokeWidth="1" />} />
          </Dropdown>
        </Row>
      ),
      col: { span: 1 }
    }
  };

  return (
    <Row gutter={[0, 8]} style={{ marginBottom: '-4px' }}>
      <PerfectScrollbar
        onScrollY={onScrollY}
        options={{ suppressScrollX: true }}
        className="list-scroll-container"
      >
        <List dataSource={taskDefinitions} columns={columns} />
        {loading && (
          <Col span={24}>
            <Row type="flex" justify="center">
              <Col>
                <Text type="secondary">{t('triggersSettingsPage.card.loading')}</Text>
              </Col>
            </Row>
          </Col>
        )}

        {!loading && !hasNextPage && (
          <Col span={24}>
            <Row type="flex" justify="center">
              <Col>
                <Text type="secondary">{t('triggersSettingsPage.card.listLoaded')}</Text>
              </Col>
            </Row>
          </Col>
        )}
      </PerfectScrollbar>
    </Row>
  );
};

const mapStateToProps = state => {
  const { ids, loading, page, totalPages } = state.taskDefinitionsList;
  const taskDefinitionsByIds = state.taskDefinitionsResource.byIds;
  const taskDefinitions = compact(ids.map(id => taskDefinitionsByIds[id]));
  const checklistDefinitionsByIds = state.checklistDefinitionsResource.byIds;

  return {
    loading,
    page,
    hasNextPage: totalPages > page.number,
    taskDefinitionsByIds,
    taskDefinitions,
    checklistDefinitionsByIds
  };
};

const mapDispatchToProps = {
  updatePage: actions.updatePage,
  clearList: actions.clearList,
  loadTaskDefinitions: operations.load,
  deleteTaskDefinition: taskDefinitionsResource.operations.deleteById,
  updateTaskDefinition: taskDefinitionsResource.operations.updateById
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TaskDefinitionsList));
