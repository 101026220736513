import { Button, Card, Collapse, Row, Typography } from 'antd';
import styled from 'styled-components';

const { Panel } = Collapse;

export const SubmitButton = styled(Button)`
  width: 100%;
`;

export const SubmitContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0 0 0;
`;

export const QuestionGroup = styled.div`
  margin: 16px -8px;
  padding: 8px;
`;

export const QuestionName = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0.5em 0;
  font-size: 1.15em;
  color: rgba(0, 0, 0, 0.85);
`;

export const QuestionContainer = styled(Row)`
  border: 1px solid var(--gray-border);
  box-sizing: border-box;
  border-radius: 4px;
  margin: 8px 0px;
  padding-left: 24px;
`;

export const QuestionCommentButton = styled(Button)`
  && {
    color: ${({ hasComments }) => (hasComments ? 'var(--primary)' : 'rgba(0, 0, 0, 0.65)')};
  }
`;

export const CommentFlagTitle = styled.span`
  font-weight: 500;
`;

export const ChecklistDefinitionContainer = styled.div`
  background: #f5f5f5;
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 44px;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
  margin: 8px 0;
`;

export const ChecklistDefinitionTitle = styled.span`
  font-weight: 600;
`;
export const ScoreContainer = styled.span`
  font-weight: 600;
  display: flex;
  align-items: center;
`;

export const StyledCollapse = styled(Collapse)`
  margin: 8px 0px !important;
  width: 100%;
`;

export const StyledPanel = styled(Panel)`
  && .ant-collapse-header {
    border-radius: 4px !important;
    background: var(--gray-light);
  }
`;

export const PanelHeader = styled.div`
  background: var(--gray-light);
  font-size: 1.15em;
`;

export const Score = styled.span`
  display: flex;
  justify-content: center;
  margin-left: 8px;
`;

export const HeadScore = styled(Typography.Text)`
  width: 39px;
  display: block;
  text-align: center;
  white-space: nowrap;
`;

export const ChecklistContainer = styled(Card)`
  &.ant-card {
    margin-bottom: 16px;
    border: none;
  }
  &.ant-card > .ant-card-body {
    padding: ${props => (props.currentChecklist || props.loading ? '16px' : '0px')};
  }
`;
