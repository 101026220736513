import { CloseCircleOutlined, DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Button, Col, Input, InputNumber, Row } from 'antd';
import SCol from 'components/Standard/SCol';
import colorZonesFromRatingValues from 'core/utils/colorZonesFromRatingValues';
import { SCALE_TYPES } from 'core/utils/constants';
import React from 'react';
import { useTranslation } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useDispatch } from 'react-redux';
import {
  updateEditingQuestion,
  updateEditingQuestionToGroupBinding
} from 'redux/ui/questionModal/reducer';
import styled from 'styled-components';
import uniqid from 'uniqid';

// TODO: move rate input to common components
// TODO: refactor and disconnect from redux

const RateInput = styled(InputNumber)`
  width: 63px !important;
  margin: 0 5px !important;

  .ant-input-number-handler-wrap {
    opacity: 1 !important;
  }
`;

export const CustomRatingValuesEditor = ({
  colorZones,
  disabled,
  scaleType,
  changeRates,
  ratingValues,
  ratingValuesViews
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const removeLastRate = () => {
    const newRates = ratingValues.slice(0, ratingValues.length - 1);
    changeRates(null, null, newRates);
  };

  const removeRate = i => {
    const newRates = [...ratingValues];
    newRates.splice(i, 1);

    const newViews = [...ratingValuesViews];
    newViews.splice(i, 1);

    dispatch(
      updateEditingQuestionToGroupBinding({
        checklistAutofailEnabled: false,
        checklistAutofailValues: [],
        groupAutofailEnabled: false,
        groupAutofailValues: [],
        defaultRatingValueScore: null,
        defaultRatingValueEnabled: false
      })
    );

    dispatch(
      updateEditingQuestion({
        ratingValuesViews: newViews
      })
    );

    changeRates(null, null, newRates);
  };

  const addRate = () => {
    const newRates = [...ratingValues, 1];
    const newViews =
      scaleType === SCALE_TYPES.custom_with_views.type ? [...ratingValuesViews, ''] : [];

    dispatch(
      updateEditingQuestionToGroupBinding({
        checklistAutofailEnabled: false,
        checklistAutofailValues: [],
        groupAutofailEnabled: false,
        groupAutofailValues: [],
        defaultRatingValueScore: null,
        defaultRatingValueEnabled: false
      })
    );
    return dispatch(
      updateEditingQuestion({
        ratingValues: newRates,
        colorZones: colorZonesFromRatingValues(newRates, colorZones),
        ratingValuesViews: newViews
      })
    );
  };

  const canAddRate = ratingValues.length < 10;

  if (scaleType === SCALE_TYPES.custom_with_views.type) {
    const updateQuestionView = (value, i) => {
      const newViews = [...ratingValuesViews];
      newViews.splice(i, 1, value);
      dispatch(updateEditingQuestion({ ratingValuesViews: newViews }));
    };

    return (
      <Row className="ant-form-item" gutter={[8, 8]} style={{ margin: '-4px' }}>
        {ratingValues.map((rate, i) => (
          <Col span={24}>
            <Row
              type="flex"
              justify="center"
              gutter={[8, 8]}
              style={{ width: '100%', margin: '-4px' }}
            >
              <Col style={{ flex: 'auto' }}>
                <Input
                  disabled={disabled}
                  value={ratingValuesViews[i] || undefined}
                  placeholder={t('components.customRatingValuesEditor.answer.placeholder')}
                  onChange={e => updateQuestionView(e?.target?.value, i)}
                />
              </Col>
              <Col>
                <RateInput
                  step={1}
                  max={100}
                  value={rate}
                  precision={1}
                  onChange={value => changeRates(value, i)}
                  disabled={disabled}
                />
              </Col>
              <Col>
                <Button
                  icon={<DeleteOutlined />}
                  type="danger"
                  disabled={disabled}
                  onClick={() => removeRate(i)}
                />
              </Col>
            </Row>
          </Col>
        ))}
        <SCol display="flex" justifyContent="center" span={24}>
          <Button type="dashed" disabled={disabled || !canAddRate} block onClick={addRate}>
            Добавить
          </Button>
        </SCol>
      </Row>
    );
  }

  return (
    <Row className="ant-form-item">
      {/* TODO: move to separate component */}
      <Col>
        <PerfectScrollbar className="rates-scroll">
          {ratingValues.map((rate, i) => (
            <RateInput
              key={uniqid()}
              step={1}
              precision={1}
              max={100}
              value={rate}
              onChange={value => changeRates(value, i)}
              disabled={disabled}
            />
          ))}

          <Button
            type="link"
            onClick={addRate}
            icon={<PlusCircleOutlined />}
            disabled={disabled || !canAddRate}
          />

          {(ratingValues[1] || ratingValues[1] === 0) && (
            <Button
              type="link"
              onClick={removeLastRate}
              icon={<CloseCircleOutlined />}
              disabled={disabled}
            />
          )}
        </PerfectScrollbar>
      </Col>
    </Row>
  );
};
