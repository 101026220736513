import React from 'react';
import { Trans } from 'react-i18next';
import { WIDGETS_DESCRIPTIONS } from 'core/utils/constants';

export default {
  checklist_definition_average_score: {
    type: 'checklist_definition_average_score',
    description: (
      <Trans i18nKey={WIDGETS_DESCRIPTIONS.CHECKLIST_DEFINITION_AVERAGE_SCORE.description} />
    ),
    name: <Trans i18nKey={WIDGETS_DESCRIPTIONS.CHECKLIST_DEFINITION_AVERAGE_SCORE.title} />,
    dateSelectorType: 'clientInteractionDateSelector',
    availableSizes: ['small'],
    availableFilters: [
      {
        type: 'clientInteractionDateSelector'
      },
      {
        type: 'unitsIds'
      },
      {
        type: 'operatorsIds'
      },
      {
        type: 'singleChecklistDefinitionId'
      }
    ]
  },
  phone_calls_count: {
    type: 'phone_calls_count',
    description: <Trans i18nKey={WIDGETS_DESCRIPTIONS.PHONE_CALLS_COUNT.description} />,
    name: <Trans i18nKey={WIDGETS_DESCRIPTIONS.PHONE_CALLS_COUNT.title} />,
    dateSelectorType: 'clientInteractionDateSelector',
    availableSizes: ['small'],
    availableFilters: [
      {
        type: 'clientInteractionDateSelector'
      },
      {
        type: 'unitsIds'
      },
      {
        type: 'operatorsIds'
      },
      {
        type: 'multipleChecklistDefinitionsIds'
      },
      {
        type: 'isReviewed'
      },
      {
        type: 'direction'
      }
    ]
  },
  phone_calls_average_duration: {
    type: 'phone_calls_average_duration',
    description: <Trans i18nKey={WIDGETS_DESCRIPTIONS.PHONE_CALLS_AVERAGE_DURATION.description} />,
    name: <Trans i18nKey={WIDGETS_DESCRIPTIONS.PHONE_CALLS_AVERAGE_DURATION.title} />,
    dateSelectorType: 'clientInteractionDateSelector',
    availableSizes: ['small'],
    availableFilters: [
      {
        type: 'clientInteractionDateSelector'
      },
      {
        type: 'unitsIds'
      },
      {
        type: 'operatorsIds'
      },
      {
        type: 'multipleChecklistDefinitionsIds'
      },
      {
        type: 'isReviewed'
      },
      {
        type: 'direction'
      }
    ]
  },
  total_phone_calls_duration: {
    type: 'total_phone_calls_duration',
    description: <Trans i18nKey={WIDGETS_DESCRIPTIONS.TOTAL_PHONE_CALLS_DURATION.description} />,
    name: <Trans i18nKey={WIDGETS_DESCRIPTIONS.TOTAL_PHONE_CALLS_DURATION.title} />,
    dateSelectorType: 'clientInteractionDateSelector',
    availableSizes: ['small'],
    availableFilters: [
      {
        type: 'clientInteractionDateSelector'
      },
      {
        type: 'unitsIds'
      },
      {
        type: 'operatorsIds'
      },
      {
        type: 'reviewersIds'
      },
      {
        type: 'multipleChecklistDefinitionsIds'
      },
      {
        type: 'isReviewed'
      },
      {
        type: 'direction'
      }
    ]
  },
  reviewed_client_interactions_duration: {
    type: 'reviewed_client_interactions_duration',
    description: (
      <Trans i18nKey={WIDGETS_DESCRIPTIONS.REVIEWED_CLIENT_INTERACTIONS_DURATION.description} />
    ),
    name: <Trans i18nKey={WIDGETS_DESCRIPTIONS.REVIEWED_CLIENT_INTERACTIONS_DURATION.title} />,
    dateSelectorType: 'reviewDateSelector',
    availableSizes: ['small'],
    availableFilters: [
      {
        type: 'reviewDateSelector'
      },
      {
        type: 'unitsIds'
      },
      {
        type: 'operatorsIds'
      },
      {
        type: 'reviewersIds'
      },
      {
        type: 'multipleChecklistDefinitionsIds'
      },
      {
        type: 'direction'
      }
    ]
  },
  reviewed_client_interactions_percentage: {
    type: 'reviewed_client_interactions_percentage',
    description: (
      <Trans i18nKey={WIDGETS_DESCRIPTIONS.REVIEWED_CLIENT_INTERACTIONS_PERCENTAGE.description} />
    ),
    name: <Trans i18nKey={WIDGETS_DESCRIPTIONS.REVIEWED_CLIENT_INTERACTIONS_PERCENTAGE.title} />,
    dateSelectorType: 'clientInteractionDateSelector',
    availableSizes: ['small'],
    availableFilters: [
      {
        type: 'clientInteractionDateSelector'
      },
      {
        type: 'unitsIds'
      },
      {
        type: 'operatorsIds'
      },
      {
        type: 'reviewersIds'
      },
      {
        type: 'multipleChecklistDefinitionsIds'
      },
      {
        type: 'direction'
      }
    ]
  }
};
