export default {
  teamPage: {
    unitTable: {
      empty: 'No data',
      infoIcons: {
        info: 'This table shows analytics based on the scores from a department',
        scoreInfo: 'Average score among all scores on a selected scorecard',
        reviewsCountInfo: 'Number of all checks on selected scorecards',
        greenZoneCountInfo: 'Percentage of checks in the green zone on selected scorecards',
        allScoresInfo: 'All scores by color zones on selected scorecards'
      },
      columns: {
        employee: 'Employee',
        unit: 'Unit',
        score: 'Score',
        reviewsCount: 'Checks',
        greenZoneCount: 'Excellent',
        allScores: 'All evaluations',
        flags: 'Flags'
      },
      differenceArrowScoreLiteral: 'points'
    },
    buttons: {
      exportTeamPageAnalytics: 'Export to Excel',
      units: 'Units',
      employees: 'Employees'
    },
    pageFilters: {
      exportModal: {
        title: 'Analytics exported',
        description: 'The export result will come to your email in a few minutes'
      },
      messages: { exportTableFailed: 'Failed to export analytics' },
      filters: {
        unitPlaceholder: 'Select unit',
        unitPlaceholderMax: 'Selected',
        unitPlaceholderMax2: 'units',
        checklistPlaceholder: 'Choose scorecard'
      }
    }
  }
};
