import { useToggle } from 'ahooks';
import { Typography, Form, TimePicker, DatePicker, Select, Descriptions } from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import { OutlinedButton } from 'components/Buttons';
import SCard from 'components/Standard/SCard';
import SCol from 'components/Standard/SCol';
import SDatePicker from 'components/Standard/SDatePicker';
import SInput, { SInputNumber } from 'components/Standard/SInput';
import SModal from 'components/Standard/SModal';
import SRow from 'components/Standard/SRow';
import SSelect from 'components/Standard/SSelect';
import React, { useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { PERIOD_REPEAT, TIME_UNIT } from 'core/utils/constants';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';

const { Title, Text } = Typography;

const LightLabel = styled.label`
  font-weight: normal;
  font-size: 12px;
  line-height: 24px;
  color: var(--black_4);
  position: absolute;
  top: -22px;
`;

const { Option } = Select;

const ScheduleSettings = ({ isNew, onChange, schedule, isScheduleValid, name, onChangeName }) => {
  const { t } = useTranslation();
  const [visible, { toggle: toggleModal }] = useToggle(false);
  const [periodTimeFrom, setPeriodTimeFrom] = useState(schedule?.periodTimeFrom || moment());
  const [periodTimeTo, setPeriodTimeTo] = useState(schedule?.periodTimeTo || undefined);
  const [dayTimeFrom, setDayTimeFrom] = useState(schedule?.dayTimeFrom || undefined);
  const [dayTimeTo, setDayTimeTo] = useState(schedule?.dayTimeTo || undefined);
  const [repeatEveryCount, setRepeatEveryCount] = useState(schedule?.repeatEveryCount || 1);
  const [repeatEvery, setRepeatEvery] = useState(
    schedule?.repeatEvery || PERIOD_REPEAT.never.value
  );
  const [stopRepeatingAt, setStopRepeatingAt] = useState(schedule?.stopRepeatingAt || undefined);
  const [customRepeatEvery, setCustomRepeatEvery] = useState(false);

  const disabledPeriodTimeFrom = current => {
    // Can not select days before today and today
    return current && current <= moment().startOf('day');
  };

  const disabledPeriodTimeTo = current => {
    return current && current <= periodTimeFrom;
  };

  const disabledStopRepeatingAt = disabledPeriodTimeTo;

  // useEffect(() => {
  //   if (periodTimeTo && periodTimeTo < periodTimeFrom) {
  //     setPeriodTimeTo(undefined);
  //   }
  // }, [periodTimeFrom]);

  const onSave = () => {
    onChange({
      periodTimeFrom,
      periodTimeTo,
      dayTimeFrom,
      dayTimeTo,
      repeatEvery,
      repeatEveryCount,
      stopRepeatingAt
    });
    toggleModal(false);
  };

  const canSave = !isEmpty(periodTimeFrom && periodTimeTo && dayTimeFrom && dayTimeTo);
  // TODO: добавить валидацию на обязательные поля

  return (
    <SRow gutter={[0, 12]} style={{ marginBottom: '-6px' }}>
      <SCol span={24}>
        <Title level={4}>{t('workPlanTaskConfigurationEditor.schedule.taskConfig')}</Title>
      </SCol>
      <SCol span={24}>
        <SCard bordered shadowed width="100%">
          <SRow gutter={[0, 22]} style={{ marginBottom: '-11px' }} justify="space-between">
            <SCol {...{ lg: 24, xl: 11, xxl: 12 }}>
              <SRow gutter={[28, 0]} align="bottom">
                <SCol>
                  <Form layout="vertical">
                    <Form.Item
                      label={t('workPlanTaskConfigurationEditor.schedule.taskName')}
                      style={{ margin: '0' }}
                    >
                      <SInput
                        placeholder={t('workPlanTaskConfigurationEditor.schedule.taskName')}
                        size="large"
                        width="260px"
                        value={name}
                        onChange={e => onChangeName(e.target.value)}
                      />
                    </Form.Item>
                  </Form>
                </SCol>
                <SCol>
                  <OutlinedButton size="big" onClick={toggleModal} width="174px">
                    {t('workPlanTaskConfigurationEditor.schedule.period')}
                  </OutlinedButton>
                </SCol>
              </SRow>
            </SCol>
            {isScheduleValid && (
              <SCol {...{ lg: 24, xl: 13, xxl: 16 }}>
                <Descriptions>
                  <Descriptions.Item
                    span={1}
                    label={
                      <Text type="secondary">
                        {t('workPlanTaskConfigurationEditor.schedule.timeFrom')}
                      </Text>
                    }
                  >
                    <Text strong>{periodTimeFrom?.format('DD/MM/YYYY')}</Text>
                  </Descriptions.Item>

                  <Descriptions.Item
                    span={1}
                    label={
                      <Text type="secondary">
                        {t('workPlanTaskConfigurationEditor.schedule.timeTo')}
                      </Text>
                    }
                  >
                    <Text strong>{periodTimeTo?.format('DD/MM/YYYY')}</Text>
                  </Descriptions.Item>

                  <Descriptions.Item
                    span={1}
                    label={
                      <Text type="secondary">
                        {customRepeatEvery
                          ? t('workPlanTaskConfigurationEditor.schedule.repeatEvery')
                          : t('workPlanTaskConfigurationEditor.schedule.repeat')}
                      </Text>
                    }
                  >
                    {customRepeatEvery ? (
                      <Text strong>
                        {`${repeatEveryCount} ${t(TIME_UNIT[repeatEvery]?.title)}`}
                      </Text>
                    ) : (
                      <Text strong>{t(PERIOD_REPEAT[repeatEvery]?.title)}</Text>
                    )}
                  </Descriptions.Item>

                  <Descriptions.Item
                    span={1}
                    label={
                      <Text type="secondary">
                        {t('workPlanTaskConfigurationEditor.schedule.dayTimeFrom')}
                      </Text>
                    }
                  >
                    <Text strong>{dayTimeFrom?.format('HH:mm')}</Text>
                  </Descriptions.Item>

                  <Descriptions.Item
                    span={2}
                    label={
                      <Text type="secondary">
                        {t('workPlanTaskConfigurationEditor.schedule.dayTimeTo')}
                      </Text>
                    }
                  >
                    <Text strong>{dayTimeTo?.format('HH:mm')}</Text>
                  </Descriptions.Item>
                </Descriptions>
              </SCol>
            )}
          </SRow>
        </SCard>
      </SCol>
      <SModal
        size="small"
        title={t('workPlanTaskConfigurationEditor.schedule.periodForTask')}
        width="535px"
        okText={t('general.save')}
        visible={visible}
        onCancel={() => toggleModal(false)}
        onOk={onSave}
        okButtonProps={{ disabled: !canSave }}
      >
        <SRow gutter={[0, 28]} style={{ marginBottom: '-14px' }} align="middle">
          <SCol span={24}>
            <SRow align="middle">
              <SCol span={10} className="ant-form-item-label ant-form-item-label-left">
                <label className="ant-form-item-required ant-form-item-no-colon">
                  {t('workPlanTaskConfigurationEditor.schedule.periodTask')}
                </label>
              </SCol>
              <SCol span={14}>
                <SRow flexWrap="nowrap" gutter={[16, 0]} align="middle">
                  {/* TODO: add default time from current date */}

                  <SCol position="relative">
                    <LightLabel>
                      {t('workPlanTaskConfigurationEditor.schedule.timeFrom')}
                    </LightLabel>
                    <DatePicker
                      format="DD/MM/YYYY"
                      disabledDate={disabledPeriodTimeFrom}
                      onChange={setPeriodTimeFrom}
                      value={periodTimeFrom}
                      placeholder={t('workPlanTaskConfigurationEditor.schedule.date')}
                    />
                  </SCol>
                  <SCol position="relative">
                    <LightLabel>{t('workPlanTaskConfigurationEditor.schedule.timeTo')}</LightLabel>
                    <DatePicker
                      format="DD/MM/YYYY"
                      disabledDate={disabledPeriodTimeTo}
                      placeholder={t('workPlanTaskConfigurationEditor.schedule.date')}
                      value={periodTimeTo}
                      onChange={setPeriodTimeTo}
                    />
                  </SCol>
                </SRow>
              </SCol>
            </SRow>
          </SCol>

          <SCol span={24}>
            <SRow align="middle">
              <SCol span={10} className="ant-form-item-label ant-form-item-label-left">
                <label className="ant-form-item-required ant-form-item-no-colon">
                  {t('workPlanTaskConfigurationEditor.schedule.time')}
                </label>
              </SCol>
              <SCol span={14}>
                <SRow flexWrap="nowrap" gutter={[16, 0]} align="middle">
                  <SCol position="relative" span={12}>
                    <LightLabel>{t('workPlanTaskConfigurationEditor.schedule.start')}</LightLabel>
                    <TimePicker
                      allowClear={false}
                      format="HH:mm"
                      showNow={false}
                      minuteStep={5}
                      placeholder={t('workPlanTaskConfigurationEditor.schedule.time')}
                      onSelect={setDayTimeFrom}
                      value={dayTimeFrom}
                    />
                  </SCol>
                  <SCol position="relative" span={12}>
                    <LightLabel>{t('workPlanTaskConfigurationEditor.schedule.end')}</LightLabel>
                    <TimePicker
                      allowClear={false}
                      format="HH:mm"
                      showNow={false}
                      minuteStep={5}
                      placeholder={t('workPlanTaskConfigurationEditor.schedule.time')}
                      onSelect={setDayTimeTo}
                      value={dayTimeTo}
                    />
                  </SCol>
                </SRow>
              </SCol>
            </SRow>
          </SCol>

          <SCol span={24}>
            <SRow align="middle">
              <SCol span={10}>
                <Text>{t('workPlanTaskConfigurationEditor.schedule.toRepeat')}</Text>
              </SCol>
              <SCol span={14}>
                <SSelect
                  width="100%"
                  value={repeatEvery}
                  onChange={setRepeatEvery}
                  disabled={customRepeatEvery}
                >
                  {Object.values(PERIOD_REPEAT).map(period => (
                    <Option value={period.value}>{t(period.title)}</Option>
                  ))}
                </SSelect>
              </SCol>
            </SRow>
          </SCol>

          <SCol span={24}>
            <SRow align="middle">
              <SCol span={10} className="ant-form-item-label ant-form-item-label-left">
                <label className="ant-form-item-no-colon">
                  {t('workPlanTaskConfigurationEditor.schedule.stopTask')}
                </label>
              </SCol>
              <SCol span={14}>
                <SDatePicker
                  format="DD/MM/YYYY"
                  placeholder={t('workPlanTaskConfigurationEditor.schedule.date')}
                  width="100%"
                  value={stopRepeatingAt}
                  onChange={setStopRepeatingAt}
                  disabledDate={disabledStopRepeatingAt}
                />
              </SCol>
            </SRow>
          </SCol>

          <SCol span={24}>
            <Checkbox
              checked={customRepeatEvery}
              onChange={e => setCustomRepeatEvery(e.target.checked)}
            >
              {t('workPlanTaskConfigurationEditor.schedule.customPeriod')}
            </Checkbox>
          </SCol>

          <SCol span={24}>
            <SRow align="middle">
              <SCol span={10}>
                <Text>{t('workPlanTaskConfigurationEditor.schedule.repeatEvery')}</Text>
              </SCol>
              <SCol span={14}>
                <SRow flexWrap="nowrap" gutter={[16, 0]} align="middle">
                  <SCol span={12}>
                    <SInputNumber
                      width="100%"
                      min={1}
                      disabled={!customRepeatEvery}
                      onChange={setRepeatEveryCount}
                      value={repeatEveryCount}
                    />
                  </SCol>
                  <SCol span={12}>
                    <SSelect
                      width="100%"
                      value={
                        repeatEvery === PERIOD_REPEAT.never.value
                          ? PERIOD_REPEAT.day.value
                          : repeatEvery
                      }
                      onChange={setRepeatEvery}
                      disabled={!customRepeatEvery}
                    >
                      {Object.values(TIME_UNIT).map(period => (
                        <Option value={period.value}>{t(period.title)}</Option>
                      ))}
                    </SSelect>
                  </SCol>
                </SRow>
              </SCol>
            </SRow>
          </SCol>
        </SRow>
      </SModal>
    </SRow>
  );
};

export default ScheduleSettings;
