import React, { useState } from 'react';
import { Col, Tooltip, Row, Radio } from 'antd';
import { updateDashboardWidget } from 'redux/ui/widgetSettingsModal/operations';
import WidgetTable from 'components/Dashboard/WidgetTable';
import { connect } from 'react-redux';
import { get, keyBy, isEmpty } from 'lodash';
import { IconButton } from 'components/Buttons/IconButton';
import ExcelIcon from 'images/ExcelIcon';
import moment from 'moment';
import { getChecklistDefinitionQuestionGroupsBeforeQuestions } from 'redux/selectors/checklistItems/checklistItems';
import { WIDGET_VIEW_MODES } from 'core/utils/constants';
import { useTranslation } from 'react-i18next';
import { LoadingOutlined } from '@ant-design/icons';
import { SettingsButtonCol, WidgetNameCol, LargeWidgetCard, TimePeriod } from '../../styled';
import SettingsModal from '../../Components/SettingsModal';
import {
  widgetNameSetter,
  widgetPeriodSetter,
  widgetInfoTextSetter,
  getDateSelectorType,
  exportWidgetData
} from '../../utils';
import { prepareColumns, prepareTableData } from './prepareTableData';
import EmptyWidget from '../../Components/EmptyWidget';

const TableChecklistItemsHistory = ({
  widgetData,
  usersByIds,
  widgetsAnalyticsByIds,
  checklistDefinitionsByIds,
  loadingWidgetId,
  updateDashboardWidget,
  checklistItems = []
}) => {
  const { t } = useTranslation();
  const { id, type, filters, name } = widgetData;
  const dateSelectorType = getDateSelectorType({ widgetType: type });
  const [loadingExport, setLoadingExport] = useState(false);

  const widgetValue = get(widgetsAnalyticsByIds, id, {});
  const metadata = widgetValue?.metadata;
  const timeFrom = metadata?.time_from;
  const timeTo = metadata?.time_to;

  const checklistDefinitionAverageScore = get(
    widgetValue,
    'checklist_definition_average_score',
    []
  );

  const averageScoreByQuestions = keyBy(get(widgetValue, 'average_score_by_questions', []), 'id');

  const reviewsCountHistory = get(widgetValue, 'reviews_count_history', {});

  const averageScoreByQuestionsHistory = keyBy(
    get(widgetValue, 'average_score_by_questions_history', []),
    'id'
  );

  const averageScoreByQuestionGroupsHistory = keyBy(
    get(widgetValue, 'average_score_by_question_groups_history', []),
    'id'
  );

  const checklistDefinitionAverageScoreHistory = get(
    widgetValue,
    'checklist_definition_average_score_history',
    []
  );

  const checklistDefinition = get(checklistDefinitionsByIds, filters.checklistDefinitionsIds, {});

  const colorZones = get(checklistDefinition, 'colorZones', {});

  const tableColumns = prepareColumns({
    filters,
    timeFrom,
    timeTo,
    colorZones,
    checklistDefinition
  });

  const tableRows = prepareTableData({
    filters,
    timeFrom,
    timeTo,
    colorZones,
    usersByIds,
    checklistItems,
    checklistDefinition,
    reviewsCountHistory,
    checklistDefinitionAverageScore,
    checklistDefinitionAverageScoreHistory,
    averageScoreByQuestions,
    averageScoreByQuestionsHistory,
    averageScoreByQuestionGroupsHistory
  });

  const isEmptyWidgetData = isEmpty(
    Object.values(
      filters.viewMode === WIDGET_VIEW_MODES.QUESTIONS.value
        ? averageScoreByQuestionsHistory
        : averageScoreByQuestionGroupsHistory
    ).filter(item => !isEmpty(item.history))
  );

  const body = {
    widgetType: type,
    widgetData: {
      columns: tableColumns.map(item => ({
        ...item,
        title:
          item.key === 'name' || item.key === 'totalScore'
            ? t(item?.title?.props?.i18nKey)
            : moment(item.key).format('DD/MM')
      })),
      rows: tableRows.map(item => ({
        ...item,
        name: item.key === 'footer' || item.key === 'header' ? t(item.name) : item.name
      }))
    }
  };

  return (
    <>
      {loadingWidgetId === id || isEmptyWidgetData ? (
        <EmptyWidget key={id} widgetData={widgetData} loadingWidgetId={loadingWidgetId} />
      ) : (
        <Col span={24}>
          <LargeWidgetCard>
            <Row type="flex" justify="space-between" align="top">
              <WidgetNameCol span={9}>
                <Row type="flex" gutter={[6, 0]} justify="start">
                  <Col>{widgetInfoTextSetter({ type })}</Col>
                  <Col>
                    <Tooltip title={widgetNameSetter({ name, type })}>
                      <span>{widgetNameSetter({ name, type })}</span>
                    </Tooltip>
                  </Col>
                </Row>

                <TimePeriod type="secondary">
                  {widgetPeriodSetter(filters, dateSelectorType)}
                </TimePeriod>
              </WidgetNameCol>
              <Col span={6}>
                <Row justify="center">
                  <Col>
                    <Radio.Group
                      onChange={e =>
                        updateDashboardWidget({
                          id,
                          type,
                          filters: { ...filters, viewMode: e.target.value }
                        })
                      }
                      value={filters.viewMode || WIDGET_VIEW_MODES.QUESTIONS.value}
                    >
                      <Radio.Button value={WIDGET_VIEW_MODES.QUESTIONS.value}>
                        {t(WIDGET_VIEW_MODES.QUESTIONS.title)}
                      </Radio.Button>
                      <Radio.Button
                        value={WIDGET_VIEW_MODES.GROUPS.value}
                        disabled={!checklistDefinition.isGroupable}
                      >
                        {t(WIDGET_VIEW_MODES.GROUPS.title)}
                      </Radio.Button>
                    </Radio.Group>
                  </Col>
                </Row>
              </Col>
              <SettingsButtonCol span={8}>
                <Row type="flex" align="start" gutter={[4, 0]}>
                  <Col>
                    <IconButton
                      tooltip={{ title: t('dashboardPage.widget.buttons.export') }}
                      button={{
                        icon: loadingExport ? (
                          <LoadingOutlined spin />
                        ) : (
                          <ExcelIcon color="rgba(38, 38, 38, 0.75)" />
                        ),
                        onClick: () =>
                          loadingExport ? null : exportWidgetData({ body, setLoadingExport }),
                        size: 'icon'
                      }}
                    />
                  </Col>
                  <Col>
                    <SettingsModal widgetData={widgetData} />
                  </Col>
                </Row>
              </SettingsButtonCol>
            </Row>
            <WidgetTable
              columns={tableColumns}
              dataSource={tableRows}
              pagination={false}
              size="small"
              bordered
              scroll={{ x: 1500, y: 452 }}
              style={{ marginTop: 8 }}
              rowClassName={record => (record.key === 'header' ? 'table-row-light' : '')}
            />
          </LargeWidgetCard>
        </Col>
      )}
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  const checklistDefinitionId = get(ownProps, 'widgetData.filters.checklistDefinitionsIds');
  const checklistDefinitionsByIds = state.checklistDefinitionsResource.byIds;
  const currentChecklist = get(checklistDefinitionsByIds, checklistDefinitionId, {});
  const checklistItems = getChecklistDefinitionQuestionGroupsBeforeQuestions(
    state,
    currentChecklist
  );
  const usersByIds = state.usersResource.byIds;
  return {
    widgetsAnalyticsByIds: state.dashboardAnalytics.byIds,
    usersByIds,
    checklistItems,
    checklistDefinitionsByIds
  };
};

const mapDispatchToProps = {
  updateDashboardWidget
};

export default connect(mapStateToProps, mapDispatchToProps)(TableChecklistItemsHistory);
