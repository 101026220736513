import React from 'react';
import { Tooltip } from 'antd';
import SText from 'components/Standard/SText';
import { every, isUndefined } from 'lodash';
import { useTranslation } from 'react-i18next';

const ColorizedNumbers = ({ completed, incompleted, total }) => {
  const { t } = useTranslation();
  if (every([completed, incompleted, total], isUndefined)) return '-';
  return (
    <>
      <Tooltip title={t('workPlanTasks.colorizedNumbers.completed')}>
        <SText color="var(--green_primary)">{completed}</SText>
      </Tooltip>
      <SText>/</SText>
      <Tooltip title={t('workPlanTasks.colorizedNumbers.incompleted')}>
        <SText color="var(--red_primary)">{incompleted}</SText>
      </Tooltip>
      <SText>/</SText>
      <Tooltip title={t('workPlanTasks.colorizedNumbers.total')}>
        <SText>{total}</SText>
      </Tooltip>
    </>
  );
};

export default ColorizedNumbers;
