import React, { useState, useEffect } from 'react';
import { get, isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import useEvent from '@react-hook/event';
import { setEditingCommentId } from 'redux/ui/clientInteractionPage/reducer';
import SButton from 'components/Standard/SButton';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import SCol from 'components/Standard/SCol';
import SRow from 'components/Standard/SRow';
import { ConditionaHideCommentCheckbox } from './ConditionaHideCommentCheckbox';

const CommentEditButton = ({
  metadata,
  id,
  onUpdate,
  hidden,
  isEditing,
  setEditing,
  editorText = '',
  uploadedFiles = []
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const editingCommentId = useSelector(state => state.uiClientInteractionPage.editingCommentId);
  const [ratingFlag, setRatingFlag] = useState(metadata?.ratingFlag);
  const [isHidden, setHidden] = useState(hidden);

  useEffect(() => {
    setRatingFlag(metadata?.ratingFlag);
  }, [metadata?.ratingFlag]);

  useEffect(() => {
    setHidden(hidden);
  }, [hidden]);

  const confirmUpdate = id => {
    setEditing(!isEditing);
    dispatch(setEditingCommentId(null));
    onUpdate({
      id,
      text: editorText,
      ratingFlag,
      hidden: isHidden,
      uploadedFilesIds: uploadedFiles.map(file => file?.id)
    });
  };

  const isEmptyComment = isEmpty(uploadedFiles) ? isEmpty(editorText.trim()) || !editorText : false;

  useEvent(document, 'keydown', e => {
    const drawer = document.getElementsByClassName('ant-drawer')[0];
    const modal = document.getElementsByClassName('ant-modal-content')[0];
    const editor = document.getElementsByClassName('public-DraftEditor-content')[0];
    const isEditorKeyPress = e.target.classList.contains('public-DraftEditor-content');
    if (
      e.code === 'Enter' &&
      (e.ctrlKey || e.metaKey) &&
      isEditorKeyPress &&
      editingCommentId === id &&
      !isEmptyComment
    ) {
      setEditing(false);
      onUpdate({
        id,
        text: editorText,
        ratingFlag,
        hidden: isHidden,
        uploadedFilesIds: uploadedFiles.map(file => file?.id)
      });
      dispatch(setEditingCommentId(null));

      if (drawer && !modal) drawer.focus();
      if (modal && editor) editor.focus();
    }
    if (e.code === 'Escape') {
      setEditing(false);
      dispatch(setEditingCommentId(null));
      if (drawer && !modal) drawer.focus();
      if (modal && editor) editor.focus();
    }
  });

  if (get(history, 'location.pathname', '') === '/library') return <div style={{ height: 16 }} />;

  const handleClick = id => {
    dispatch(setEditingCommentId(id));
    setEditing(!isEditing);
  };

  if (!isEditing) return null;

  return (
    <SRow>
      <SCol
        span={24}
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}
      >
        <SButton onClick={() => handleClick(null)} marginRight="6px">
          {t('components.commentList.comment.commentEditButton.buttons.cancel')}
        </SButton>

        <ConditionaHideCommentCheckbox checked={isHidden} onChange={() => setHidden(!isHidden)}>
          {t('components.commentList.comment.commentEditButton.buttons.hidden')}
        </ConditionaHideCommentCheckbox>

        {/* <SButton
          type="primary"
          onClick={() => confirmUpdate(id)}
          hidden={!isEditing}
          htmlType="submit"
          disabled={isEmptyComment}
        >
          {t('components.commentList.comment.commentEditButton.buttons.save')}
        </SButton> */}
      </SCol>
    </SRow>
  );
};

export default CommentEditButton;
