export default {
  loginPage: {
    form: {
      title: 'Login',
      unRegistered: 'Not registered?',
      fields: {
        emailPlaceholder: 'Enter email',
        passwordPlaceholder: 'Enter password'
      },
      messages: {
        enterEmail: 'Enter email',
        enterPassword: 'Enter password',
        wrongEmail: 'Wrong email format',
        minLengthPassword: 'Short password'
      },
      buttons: {
        login: 'Login',
        forgotPassword: 'Forgot password',
        registration: 'Sign in',
        goToSite: 'Go to website',
        registrationlink: 'Register'
      }
    },
    layout: {
      slides: {
        efficiency: {
          title: 'Efficiency',
          description:
            'All tools for quality control and evaluation of communications on one platform'
        },
        motivation: {
          title: 'Motivation',
          description: 'Involve, develop and motivate your team'
        },
        simplicity: {
          title: 'Simplicity',
          description:
            'Get rid of spreadsheets and outdated tools for evaluation and quality control'
        },
        analytics: {
          title: 'Analytics',
          description:
            'Analyze quality metrics and find new opportunities for the growth of your team'
        }
      }
    }
  }
};
