import { RightOutlined } from '@ant-design/icons';
import SCard from 'components/Standard/SCard';
import SCol from 'components/Standard/SCol';
import SRow from 'components/Standard/SRow';
import SText from 'components/Standard/SText';
import { Skeleton, Empty } from 'antd';
import { CHECKLIST_DEFINITION_STATUS } from 'core/utils/constants';
import { isEmpty } from 'lodash';
import pluralize from 'pluralize';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { getChecklistsDefinitionsByParams } from 'redux/selectors/checklists';
import styled from 'styled-components';

const SelectChecklist = ({ onSelect, checklistDefinitions, loading }) => {
  const { t } = useTranslation();
  if (isEmpty(checklistDefinitions) && !loading)
    return (
      <SCard bordered shadowed>
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={t('components.checklistManager.selectChecklist.empty')}
        />
      </SCard>
    );

  return loading ? (
    <SCard bordered shadowed>
      <Skeleton active />
    </SCard>
  ) : (
    <SRow gutter={[4, 4]} style={{ marginTop: 8 }}>
      {checklistDefinitions.map(item => (
        <SCol span={24}>
          <StyledCard
            onClick={() => onSelect({ checklistDefinitionId: item.id })}
            bordered
            shadowed
          >
            <SRow type="flex" justify="space-between">
              <SCol span={22}>
                <SText width="100%" ellipsis fontSize="14px">
                  {item.name}
                </SText>
              </SCol>
              <SCol>
                <RightOutlined />
              </SCol>
            </SRow>
          </StyledCard>
        </SCol>
      ))}
    </SRow>
  );
};

const StyledCard = styled(SCard)`
  &.ant-card {
    cursor: pointer;
  }

  &.ant-card:hover {
    background-color: var(--blue_2);
  }
`;

const mapStateToProps = (state, { communication = {} }) => {
  const { loading } = state.checklistDefinitionsResource;
  const { type, clientInteractionType, operatorId, communicationType } = communication;

  if (isEmpty(communication)) return { loading: true };

  // * hacking BE properties
  const allowedType = (
    clientInteractionType ||
    communicationType ||
    pluralize.singular(type)
  ).replace('-', '_');

  const operator = state.usersResource.byIds[operatorId];

  const checklistDefinitions = getChecklistsDefinitionsByParams(state, {
    params: {
      statuses: [CHECKLIST_DEFINITION_STATUS.PUBLISHED.value],
      communicationTypes: [allowedType],
      unitsIds: [operator.unitId]
    }
  });

  return {
    loading,
    checklistDefinitions
  };
};

export default connect(mapStateToProps, null)(SelectChecklist);
