import { Col, Row, Typography } from 'antd';
import { INTEGRATIONS } from 'core/utils/constants';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { createAmoIntegration } from 'redux/entities/amoIntegration/operations';
import { createBinotelIntegration } from 'redux/entities/binotelIntegration/operations';
import { createBitrixIntegration } from 'redux/entities/bitrixIntegration/operations';
import { createSipuniIntegration } from 'redux/entities/sipuniIntegration/operations';
import { createUsedeskIntegration } from 'redux/entities/usedeskIntegration/operations';
import { integrationsResource } from 'redux/resources/integrations';
import NameAndType from './IntegrationSettingsModules/NameAndType';
// TODO: move somewhere this operation
const createCustomIntegration = ({ name, integrationType }) => async (dispatch, getState) => {
  try {
    const result = await dispatch(
      integrationsResource.operations.create({ name, integrationType })
    );
    return result.id;
  } catch (error) {
    console.log(error);
    return undefined;
  }
};

const integrationTypeToHandler = {
  [INTEGRATIONS.amocrm.type]: createAmoIntegration,
  [INTEGRATIONS.bitrix_crm.type]: createBitrixIntegration,
  [INTEGRATIONS.custom_http_telephony.type]: createCustomIntegration,
  [INTEGRATIONS.custom_http_text.type]: createCustomIntegration,
  [INTEGRATIONS.sipuni.type]: createSipuniIntegration,
  [INTEGRATIONS.usedesk.type]: createUsedeskIntegration,
  [INTEGRATIONS.binotel.type]: createBinotelIntegration
};

const { Title } = Typography;

const NewIntegrationPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { url } = useRouteMatch();
  const { t } = useTranslation();

  const onSetNameAndType = async values => {
    const integrationId = await dispatch(
      integrationTypeToHandler[values.integrationType]({ ...values, url, history })
    );

    if (integrationId) {
      history.push(`${url}/${values.integrationType}/${integrationId}`);
    }
  };

  return (
    <Row gutter={[0, 20]} style={{ marginBottom: '-10px' }}>
      <Col span={24}>
        <Title level={4}>{t('integrationsSettingsPage.addIntegration.title')}</Title>
      </Col>
      <Col span={24}>
        <NameAndType onSubmit={onSetNameAndType} />
      </Col>
    </Row>
  );
};

export default NewIntegrationPage;
