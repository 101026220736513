import { Col, Row, Typography } from 'antd';
import UserPreview from 'components/UserPreview';
import moment from 'core/moment';
import { CLIENT_INTERACTION_DIRECTIONS } from 'core/utils/constants';
import { isEqual } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

const { Text } = Typography;

const PhoneCallInformation = ({ call }) => {
  const operator = useSelector(state => state.usersResource.byIds[call.operatorId], isEqual);
  const { t } = useTranslation();

  return (
    <Row
      type="flex"
      align="middle"
      justify="space-between"
      gutter={[16, 0]}
      style={{ marginBottom: 6 }}
    >
      <Col span={24}>
        <Row type="flex" align="start" justify="start" gutter={[16, 0]}>
          <Col>
            <UserPreview userId={operator.id} user={operator} disabled showAvatar showUnit />
          </Col>
          <StyledCol>
            <Text>
              <Text strong>{t('components.recordPlayer.phoneCallInformation.callDate')}</Text>
              {moment(call.startedAt).format('DD/MM/YYYY, HH:mm')}
            </Text>
          </StyledCol>
          <StyledCol>
            <Text strong>{t('components.recordPlayer.phoneCallInformation.callType')}</Text>
            <Text>
              {call.direction === 'incoming'
                ? t(CLIENT_INTERACTION_DIRECTIONS.INCOMING.title)
                : t(CLIENT_INTERACTION_DIRECTIONS.OUTCOMING.title)}
            </Text>
          </StyledCol>
          <StyledCol>
            <Text>
              <Text strong>{t('components.recordPlayer.phoneCallInformation.callNumber')}</Text>
              {call.clientPhoneNumber}
            </Text>
          </StyledCol>
        </Row>
      </Col>
    </Row>
  );
};

const StyledCol = styled(Col)`
  &.ant-col {
    padding-top: 6px;
  }
`;

export default PhoneCallInformation;
