export default {
  checklistSettingsPage: {
    questionsWithoutGroup: 'Criteri senza gruppo',
    checklistSuccessfullyCreated: 'Modulo di valutazione creato con successo',
    checklistSuccessfullyUpdated: 'Modulo di valutazione aggiornato con successo',
    updateChecklistFailed: 'Impossibile aggiornare il modulo di valutazione',
    createChecklistFailed: 'Impossibile creare il modulo di valutazione',
    breadcrumbs: {
      checklists: 'Moduli di valutazione',
      checklistSettings: 'Impostazioni del modulo di valutazione'
    },
    title: 'Impostazioni del modulo di valutazione',
    info:
      "Specifica qui le impostazioni generali per la forma di valutazione selezionata che verra' applicata a tutti i criteri. Fai clic sul pulsante \"Salva\" per accedere alla pagina per la creazione di gruppi e criteri. Puoi modificare questi impostazioni in qualsiasi momento. Puoi saperne di piu' su tutte le impostazioni e le funzionalita' ",
    infoButton: 'legere qui.',
    form: {
      title: 'Impostazioni di base',
      fields: {
        name: 'Nome',
        nameInfo:
          'Questo nome viene visualizzato quando si seleziona un modulo di valutazione durante la verifica nei widget analitici.',
        namePlaceholder: 'Immetti il nome del modulo di valutazione',
        description: 'Descrizione',
        descriptionInfo:
          'Descrivi per quali scopi stai utilizzando questo modulo di valutazione, chi o cosa vuoi valutare usandolo',
        descriptionPlaceholder: 'Immetti una descrizione del modulo di valutazione',
        units: 'Dipartimenti',
        unitsInfo:
          'Il modulo di valutazione sarà disponibile solo per comunicazioni da dipartimenti selezionati. I dipendenti di altri reparti non saranno in grado di valutarlo.',
        unitsPlaceholder: 'Seleziona reparto',
        unitsPlaceholderMax: 'Dipartimenti:',
        communicationTypes: 'Tipi di comunicazioni',
        communicationType: 'Tipo di comunicazione:',
        communicationTypesPlaceholder: 'Seleziona i tipi di comunicazione',
        communicationTypesPlaceholderMax: 'Tipi di comunicazioni:',
        communicationTypesInfo:
          "Il modulo di valutazione sara' disponibile per verificare solo i tipi di comunicazioni.",
        ratingMethod: 'Metodo di valutazione',
        ratingMethodInfo:
          'In una valutazione semplice, ogni criterio nel modulo di valutazione ha lo stesso peso. Se scegli "Ponderato" dovrai indicare la percentuale di significativita\' del punteggio assegnato per il criterio nel calcolo del punteggio medio del modulo di valutazione.',
        ratingCalculation: 'Calcolo della valutazione',
        ratingCalculationInfo:
          'Scegli quale vuoi ricevere risultato finale del controllo sul modulo di valutazione.',
        ratingMode: 'Visualizza punteggio',
        ratingModeInfo:
          'Scegli come vuoi vedere il risultato finale: i punti segnati o la percentuale del massimo risultato possibile',
        additionalSettings: 'Altre impostazioni',
        scaleType: 'Scala di valutazione',
        scaleTypeInfo:
          'Scegli una scala di valutazione gia\' pronta o in una "scala arbitraria" indica i punti che devi controllare sul modulo di valutazione.',
        valuesDisplayType: 'Visualizza nel modulo di valutazione',
        valuesDisplayTypeInfo:
          "La visualizzazione grafica (mimo) della scala sara' disponibile solo nella pagina di valutazione. Il risultato finale verra' visualizzato numericamente.",
        autofailEnabled: 'Archiviazione automatica del modulo di valutazione',
        autofailEnabledInfo:
          "Selezionare \"Si\" se si desidera aggiungere criteri al modulo di valutazione, il cui punteggio minimo portera' ad una valutazione automatica di tutto il modulo. In questo caso il voto finale sara' il piu' basso possibile.",
        noAnswerAsMax: 'N/A come max. criteri di valutazione',
        noAnswerAsMaxInfo:
          'Seleziona "Si" se desideri che i criteri che sono stati lasciati senza risposta siano considerati il valore massimo possibile per questo criterio'
      },
      messages: { enterName: 'Inserisci un titolo' },
      alerts: {
        disabledByQuestions: {
          title:
            "Non e' possibile modificare la scala di valutazione mentre il modulo di valutazione contiene criteri con una scala gia' stabilita"
        },
        colorZones: {
          title: 'Zone di valutazione',
          descriptionPT1:
            "Poiche' hai selezionato Calcolo del voto - Punti totali o Scala del voto, Scala libera, l'impostazione delle zone di valutazione sara' attivo prima che il voto venga pubblicato.",
          descriptionPT2:
            "Le zone consentono di aggiungere e personalizzale la codifica a colori dei risultati del controllo finale sul modulo di valutazione. Cioe' quando il punteggio finale rientra nell'intervallo specificato nella zona, verra' visualizzato nel colore della zona selezionata. Inoltre, rendera' piu' semplice capire e analizzare in quali zone si trovano piu' spesso le valutazioni dei dipendenti."
        },
        button: 'Maggiori dettagli qui'
      }
    },
    buttons: {
      cancel: 'Annulla',
      save: 'Salvare'
    }
  }
};
