import { Users, MoreVertical } from 'react-feather';
import { Button, Dropdown, Menu, Modal, Typography } from 'antd';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { unitsResource } from 'redux/resources/units';
import { removeSelectedUnit } from 'redux/ui/organizationPage/reducer';
import styled from 'styled-components';
import Icon from 'components/Icon';

const { Text } = Typography;

const NodeContent = ({ name, id, isRoot, setCreatingUnit, setRenamingUnitId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const deleteUnit = useCallback(
    async ({ id }) => {
      await dispatch(unitsResource.operations.deleteById({ id }));
      await dispatch(removeSelectedUnit());
    },
    [dispatch]
  );

  const handlMenuClick = e => {
    switch (e.key) {
      case 'add':
        setCreatingUnit(id);
        break;

      case 'delete':
        return Modal.confirm({
          destroyOnClose: true,
          closable: true,
          title: t('organizationStructure.tabs.units.confirmDelete.title'),
          cancelText: t('organizationStructure.tabs.units.confirmDelete.cancel'),
          okText: t('organizationStructure.tabs.units.confirmDelete.ok'),
          onOk: async () => deleteUnit({ id })
        });

      case 'rename':
        return setRenamingUnitId(id);

      default:
    }
  };

  const menu = (
    <Menu onClick={handlMenuClick}>
      <Menu.Item key="add">
        <span>Добавить отдел</span>
      </Menu.Item>
      <Menu.Item key="rename">
        <span>Переименовать</span>
      </Menu.Item>
      <Menu.Item disabled={isRoot} key="delete" style={{ color: 'var(--red_primary)' }}>
        <span>Удалить</span>
      </Menu.Item>
    </Menu>
  );

  return (
    <Node>
      <Icon icon={Users} />
      <Name>{name}</Name>
      <Actions onClick={e => e.stopPropagation()}>
        <Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
          <Button
            type="link"
            size="small"
            icon={
              <Icon
                icon={MoreVertical}
                color="var(--blue_2)"
                style={{ marginTop: '-0.9px' }}
                size="24px"
              />
            }
            strokeWidth="1"
          />
        </Dropdown>
      </Actions>
    </Node>
  );
};

const Node = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

const Name = styled(Text)`
  font-size: var(--font-size);
  width: 100%;
  width: -webkit-fill-available;
  width: -moz-available;
  width: fill-available;
  margin: 0 5px;
  overflow: hidden;
  font-weight: 500;
  text-overflow: ellipsis;
`;

export default NodeContent;
