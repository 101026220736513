import organizationReducer from 'redux/entities/organization/reducer';
import libraryReducer from 'redux/entities/library/reducer';
import customHttpTelephonyReducer from 'redux/entities/customHttpTelephonyIntegration/reducer';
import customHttpTextReducer from 'redux/entities/customHttpTextIntegration/reducer';
import taskPriorityReducer from 'redux/entities/taskPriority/reducer';
import { customCommunicationReducer } from 'redux/entities/customCommunication/reducer';
import { searchReducer } from 'redux/entities/search/reducer';
import { backgroundJobsReducer } from 'redux/entities/backgroundJob/reducer';
import appellationsSettingsReducer from 'redux/entities/appellationsSettings/reducer';
import dashboardPagesReducer from 'redux/entities/dashboardPages/reducer';

export default {
  library: libraryReducer,
  customHttpTelephony: customHttpTelephonyReducer,
  customHttpText: customHttpTextReducer,
  organizationInfo: organizationReducer,
  taskPriority: taskPriorityReducer,
  backgroundJob: backgroundJobsReducer,
  search: searchReducer,
  customCommunication: customCommunicationReducer,
  appellationsSettings: appellationsSettingsReducer,
  dashboardPages: dashboardPagesReducer
};
