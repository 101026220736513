import { Upload, message } from 'antd';
import { Camera, Trash2 } from 'react-feather';
import React from 'react';
import { useDispatch } from 'react-redux';
import { usersResource } from 'redux/resources/users';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Icon from 'components/Icon';
import styled from 'styled-components';

const updateUser = usersResource.operations.updateById;

const UploadAvatar = ({ userAvatarUrl }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { id: viewedUserId } = useParams();

  const getBase64 = file => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  };

  const updateAvatar = async ({ avatarPreview }) => {
    try {
      const response = await dispatch(
        updateUser({
          id: viewedUserId,
          avatar: avatarPreview
        })
      );
      if (response.type === 'users') {
        message.success(t('userSettingsPage.messages.dataSuccessfullyUpdated'));
      }
    } catch (error) {
      console.log(error);
      message.error(t('userSettingsPage.messages.updateDataFailed'));
    }
  };

  return (
    <>
      {!userAvatarUrl ? (
        <StyledUpload
          customRequest={async data => {
            const avatarB64 = await getBase64(data.file);
            await updateAvatar({ avatarPreview: avatarB64 });
          }}
          fileList={[]}
          accept=".jpeg,.jpg,.png,.gif"
          listType="picture-card"
        >
          <Icon icon={Camera} size="32px" color="var(--gray_primary)" />
        </StyledUpload>
      ) : (
        <DeleteAvatar onClick={() => updateAvatar({ avatarPreview: '' })}>
          <Icon icon={Trash2} size="32px" color="var(--gray_primary)" />
        </DeleteAvatar>
      )}
    </>
  );
};

const DeleteAvatar = styled.div`
  width: 80px;
  height: 80px;
  position: absolute;
  top: 6px;
  left: 6px;
  background-color: transparent;
  transition: background-color 0.2s ease-out 10ms;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  opacity: 0;
  cursor: pointer;

  &:hover {
    background-color: rgba(0, 0, 0, 0.3);
    opacity: 1;
  }
`;

const StyledUpload = styled(Upload)`
  &.ant-upload-picture-card-wrapper .ant-upload {
    border: none;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    position: absolute;
    top: 6px;
    left: 6px;
    background-color: transparent;
    transition: background-color 0.2s ease-out 10ms;
  }

  &.ant-upload-picture-card-wrapper .ant-upload:hover {
    background-color: rgba(0, 0, 0, 0.3);
  }

  &.ant-upload-picture-card-wrapper .ant-upload > span {
    top: 0px;
    left: 0px;
    width: 80px;
    height: 80px;
    opacity: 0;
    transition: opacity 0.2s ease-out 10ms;
  }

  &.ant-upload-picture-card-wrapper .ant-upload > span:hover {
    opacity: 1;
  }

  &.ant-upload-picture-card-wrapper .ant-upload svg {
    margin-top: 23px;
  }
`;

export default UploadAvatar;
