import { Info } from 'react-feather';
import { message } from 'antd';
import axios from 'axios';
import { IconButton } from 'components/Buttons/IconButton';
import { exportToExcelEndpoint } from 'core/api';
import {
  DATE_SELECTOR_TYPE_TO_FILTERS,
  RATING_MODE,
  SYMBOLIC_TIME_RANGE,
  SYMBOLIC_TIME_RANGE_LITERALS,
  ZONE_TO_COLOR_RELATIONS,
  CONVERT_PERIOD_STRING,
  WIDGET_VIEW_MODES
} from 'core/utils/constants';
import { beatifyFloat } from 'core/utils/convertFloat';
import { timeFormatter } from 'core/utils/timeFormatter';
import { Trans } from 'react-i18next';
import fileDownload from 'js-file-download';
import { get, isEmpty, isNil, snakeCase } from 'lodash';
import moment from 'moment';
import React from 'react';
import Icon from 'components/Icon';
import widgetsDefinitions from './widgetsDefinitions/widgetsDefinitions';

const excludedAllTimeWidgetsTypes = [
  'table_reviews_count_by_operators',
  'table_checklist_items_history',
  'table_reviews_count_by_reviewers',
  'table_reviews_with_scores'
];

const exludedSymbolicTimeRangePeriods = ['this_year', 'previous_year'];

export const getTimeRanges = ({ widgetType }) => {
  if (excludedAllTimeWidgetsTypes.includes(widgetType)) {
    return Object.values(SYMBOLIC_TIME_RANGE).filter(item => item !== 'all');
  }
  return Object.values(SYMBOLIC_TIME_RANGE);
};

export const getDefaultTimeRange = ({ widgetType }) =>
  excludedAllTimeWidgetsTypes.includes(widgetType)
    ? SYMBOLIC_TIME_RANGE.THIS_MONTH
    : SYMBOLIC_TIME_RANGE.ALL_TIME;

export const disabledDayOption = ({
  timeFrom,
  timeTo,
  symbolicTimeRange,
  widgetType,
  currentOption,
  historyGroupingPeriod,
  updateUiWidgetFilters
}) => {
  const start = moment(timeTo);
  const end = moment(timeFrom);
  const diff = start.diff(end, 'days');

  // ! optimize
  const isUnacceptableDaysCount =
    excludedAllTimeWidgetsTypes.includes(widgetType) && diff > 60 && currentOption === 'day';

  const isUnacceptableSymbolicTimeRangeValue =
    excludedAllTimeWidgetsTypes.includes(widgetType) &&
    exludedSymbolicTimeRangePeriods.includes(symbolicTimeRange) &&
    currentOption === 'day';

  if (isUnacceptableDaysCount || isUnacceptableSymbolicTimeRangeValue) {
    if (historyGroupingPeriod === 'day')
      updateUiWidgetFilters({
        historyGroupingPeriod: 'week'
      });

    return true;
  }
};

export const getSortString = ({ sorter, viewMode }) => {
  if (!sorter.order) return '';
  const standardKeys = ['clientInteractionStartedAt', 'createdAt', 'checklistScore'];

  const getSorterKey = () => {
    if (standardKeys.includes(sorter.columnKey)) return snakeCase(sorter.columnKey);
    if (viewMode === WIDGET_VIEW_MODES.QUESTIONS.value) return `answer_score_${sorter.columnKey}`;
    if (viewMode === WIDGET_VIEW_MODES.GROUPS.value) return `group_score_${sorter.columnKey}`;
  };

  return sorter.order === 'ascend' ? getSorterKey() : `-${getSorterKey()}`;
};

export const exportWidgetData = async ({ body, setLoadingExport }) => {
  try {
    setLoadingExport(true);
    const result = await axios.post(exportToExcelEndpoint, body, { responseType: 'blob' });
    if (get(result, 'status', 200) === 200) {
      fileDownload(result.data, 'export.xlsx');
      setLoadingExport(false);
      message.success(
        <Trans i18nKey="dashboardPage.widget.messages.analyticsSuccessfullyExported" />
      );
    } else {
      message.error(<Trans i18nKey="dashboardPage.widget.messages.exportAnalyticsFailed" />);
    }
  } catch (error) {
    setLoadingExport(false);
    console.log(error);
    message.error(<Trans i18nKey="dashboardPage.widget.messages.exportAnalyticsFailed" />);
  }
};

export const getDatesArray = ({
  timeFrom,
  timeTo,
  isWeekendView,
  historyGroupingPeriod = 'day'
}) => {
  if (!timeFrom || !timeTo) return [];
  const start = moment(timeFrom);
  const end = moment(timeTo);
  const diff = end.diff(start, CONVERT_PERIOD_STRING[historyGroupingPeriod]);

  const range = [];

  for (let i = 0; i <= diff; i++) {
    const iterableDay = moment(start)
      .add(i, CONVERT_PERIOD_STRING[historyGroupingPeriod])
      .startOf(historyGroupingPeriod === 'week' ? 'isoWeek' : historyGroupingPeriod);
    range.push(iterableDay);
  }

  const conditionalDays = isWeekendView ? range : range.filter(day => day.isoWeekday() < 6);
  return conditionalDays;
};

export const widgetNameSetter = ({ name, type }) => {
  return isEmpty(name)
    ? get(
        widgetsDefinitions,
        `${type}.name`,
        <Trans i18nKey="dashboardPage.widget.utils.emptyName" />
      )
    : name;
};

export const searchMaxValue = ({ checklistDefinitionsByIds, filters }) => {
  const checklistDefinition = get(checklistDefinitionsByIds, filters.checklistDefinitionsIds);
  const checklistDefinitionRatingMode = get(checklistDefinition, 'ratingMode');

  if (checklistDefinition) {
    return checklistDefinitionRatingMode === RATING_MODE.PERCENTAGE ? 100 : checklistDefinition.max;
  }
};

export const valueToColorZoneColor = ({ value, colorZones }) => {
  if ((!value || !colorZones) && value !== 0) return 'white';

  // * если все зоны на месте
  if (value < get(colorZones, 'low.stop')) return ZONE_TO_COLOR_RELATIONS.low;
  if (value < get(colorZones, 'medium.stop')) return ZONE_TO_COLOR_RELATIONS.medium;
  if (value >= get(colorZones, 'medium.stop')) return ZONE_TO_COLOR_RELATIONS.high;

  // * если есть только low + high зоны
  if (!get(colorZones, 'medium.stop') && get(colorZones, 'high.stop')) {
    if (value <= get(colorZones, 'low.stop')) return ZONE_TO_COLOR_RELATIONS.low;
    if (value === get(colorZones, 'high.stop')) return ZONE_TO_COLOR_RELATIONS.high;
  }

  // * если есть только low зона
  if (!get(colorZones, 'medium.stop') && !get(colorZones, 'high.stop')) {
    if (value < get(colorZones, 'low.stop')) return ZONE_TO_COLOR_RELATIONS.low;
    if (value === get(colorZones, 'low.stop')) return ZONE_TO_COLOR_RELATIONS.high;
  }
};

export const widgetValueTypeSetter = {
  checklist_definition_average_score: (widgetValue, checklistDefinitionRatingMode) =>
    `${beatifyFloat(widgetValue)}${checklistDefinitionRatingMode === 'percentage' ? '%' : ''}`,
  phone_calls_count: widgetValue => beatifyFloat(widgetValue, 0),
  reviewed_client_interactions_percentage: widgetValue =>
    `${beatifyFloat(get(widgetValue, 'percentage', ''))}%`,
  total_phone_calls_duration: widgetValue => timeFormatter({ timeInSeconds: widgetValue }),
  reviewed_client_interactions_duration: widgetValue =>
    timeFormatter({ timeInSeconds: widgetValue }),
  phone_calls_average_duration: widgetValue => moment.utc(widgetValue * 1000).format('mm:ss')
};

export const getDateSelectorType = ({ widgetType }) => {
  return get(widgetsDefinitions, `${widgetType}.dateSelectorType`);
};

export const widgetPeriodSetter = (widgetsFilters, dateSelectorType) => {
  const symbolicTimeRangeFilterType = get(
    DATE_SELECTOR_TYPE_TO_FILTERS,
    `${dateSelectorType}.symbolicTimeRange`,
    'symbolicTimeRange'
  );

  const timeFromFilterType = get(
    DATE_SELECTOR_TYPE_TO_FILTERS,
    `${dateSelectorType}.timeFrom`,
    'symbolicTimeRange'
  );

  const timeToFilterType = get(
    DATE_SELECTOR_TYPE_TO_FILTERS,
    `${dateSelectorType}.timeTo`,
    'symbolicTimeRange'
  );

  if (!isEmpty(widgetsFilters[symbolicTimeRangeFilterType]))
    return (
      <Trans i18nKey={SYMBOLIC_TIME_RANGE_LITERALS[widgetsFilters[symbolicTimeRangeFilterType]]} />
    );

  if (!isEmpty(widgetsFilters[timeFromFilterType]) && !isEmpty(widgetsFilters[timeToFilterType]))
    return `${moment(widgetsFilters[timeFromFilterType]).format('DD/MM/YYYY')} - ${moment(
      widgetsFilters[timeToFilterType]
    ).format('DD/MM/YYYY')}`;

  if (
    isEmpty(widgetsFilters[timeFromFilterType]) &&
    isEmpty(widgetsFilters[timeToFilterType]) &&
    isEmpty(widgetsFilters[symbolicTimeRangeFilterType])
  )
    return <Trans i18nKey="dashboardPage.widget.utils.allTime" />;
};

export const widgetInfoTextSetter = ({ type }) => {
  return (
    <IconButton
      popover={{
        content: get(
          widgetsDefinitions,
          `${type}.description`,
          <Trans i18nKey="dashboardPage.widget.utils.emptyName" />
        ),
        title: get(
          widgetsDefinitions,
          `${type}.name`,
          <Trans i18nKey="dashboardPage.widget.utils.emptyDescription" />
        ),
        trigger: 'hover',
        overlayStyle: { width: 500 }
      }}
      button={{
        icon: <Icon icon={Info} />,
        size: 'small',
        style: { width: 16, marginRight: 4 }
      }}
    />
  );
};

export const tooltipSetter = tooltipData => {
  return (
    <>
      <span>
        {isNil(tooltipData.name) ? (
          <Trans i18nKey="dashboardPage.widget.utils.nameIsNotInstalled" />
        ) : (
          `${tooltipData.name}: `
        )}
      </span>
      <strong>{`${tooltipData.value}`}</strong>
    </>
  );
};

export const requiredChecklistDefinitionsWidgetTypes = [
  'checklist_definition_average_score',
  'checklist_definition_average_score_by_units',
  'checklist_definition_average_score_by_operators',
  'checklist_definition_average_score_by_checklist_definitions',
  'checklist_definition_questions_average_scores',
  'checklist_definition_questions_average_scores_by_question_groups',
  'checklist_definition_question_groups_average_scores',
  'checklist_definition_average_score_by_operators_history',
  'checklist_definition_average_score_by_units_history',
  'average_score_by_questions_history',
  'average_score_by_question_groups_history',
  'checklist_definition_question_groups_average_scores',
  'table_reviews_count_by_operators',
  'table_checklist_items_by_operators',
  'table_checklist_items_history',
  'table_checklists_scores_by_operators',
  'table_reviews_with_scores'
];

export const requiredOperatorIdsWidgetTypes = [
  'checklist_definition_average_score_by_operators_history'
];

export const customEmptyDashboardRulesWidgetTypes = [
  'rating_flags_count',
  'reviews_count_by_reviewers_history',
  'checklist_definition_average_score_by_operators_history',
  'checklist_definition_average_score_by_units_history',
  'average_score_by_questions_history',
  'average_score_by_question_groups_history',
  'phone_calls_average_duration_by_operators_history',
  'phone_calls_average_duration_by_units_history',
  'client_interactions_count_by_operators_history',
  'client_interactions_count_by_units_history',
  'table_reviews_count_by_operators',
  'table_checklist_items_by_operators',
  'table_checklist_items_history',
  'flags_and_comments',
  'table_checklists_scores_by_operators',
  'table_reviews_count_by_reviewers'
];
