import { Col, Modal, Row, Typography } from 'antd';
import { isEmpty, isEqual } from 'lodash';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import BackgroundJob from 'pages/BackgroundJob';
import { useSelector, useDispatch } from 'react-redux';
import { Switch, useRouteMatch, Route, useHistory, Redirect, useParams } from 'react-router-dom';
import catWithSpinner from 'assets/video/cat_with_spinner.mp4';
import moment from 'core/moment';
import { INTEGRATIONS } from 'core/utils/constants';
import { syncDataWithSipuni } from 'redux/entities/sipuniIntegration/operations';
import { integrationsResource } from 'redux/resources/integrations';
import SyncPeriod from '../IntegrationSettingsModules/SyncPeriod';
import ManageSipuniUserAccounts from '../IntegrationSettingsModules/ManageSipuniUserAccounts';

const { Title } = Typography;

const CreateSipuniIntegrationPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { path, url } = useRouteMatch();
  const { integrationId, id: userId } = useParams();
  const manageUsersRef = useRef();

  useEffect(() => {
    dispatch(integrationsResource.operations.loadById({ id: integrationId }));
  }, [integrationId]);

  const integration = useSelector(
    state => state.integrationsResource.byIds[integrationId],
    isEqual
  );

  const currentBackgroundJob = useSelector(state => state.backgroundJob.currentBackgroundJob);

  const selectIntegrationPeriod = async fetchFrom => {
    const fetchFromToISO = moment()
      .subtract(fetchFrom, 'months')
      .toISOString();

    await dispatch(
      syncDataWithSipuni({
        id: integration.id,
        fetchFrom: fetchFromToISO
      })
    );
  };

  const handleSelectUsers = () => {
    history.push(`${url}/period`);
  };

  const onBackgroundJobDone = () => {
    if (window.location.pathname.split('/').includes('users')) {
      return manageUsersRef?.current?.load();
    }

    if (window.location.pathname.split('/').includes('period'))
      return history.push(
        `/user/${userId}/integrations-settings/${integration.integrationType}/${integrationId}`
      );
  };

  const onBackgroundJobFailed = () => {
    Modal.error({
      title: `${t('integrationsSettingsPage.createSaasIntegration.errorMessage')} ${t(
        INTEGRATIONS[integration.integrationType].name
      )} ${t(INTEGRATIONS[integration.integrationType].name)}`,
      content: t('integrationsSettingsPage.createSaasIntegration.errorMessageContent')
    });
  };

  return (
    <Row>
      <Col span={24}>
        <Switch>
          <Route path={`${path}/period`}>
            <Row gutter={[0, 20]} style={{ marginBottom: '-10px' }}>
              <Col span={24}>
                <Title level={4}>{t('integrationsSettingsPage.createSaasIntegration.sync')}</Title>
              </Col>
              <Col span={24}>
                <SyncPeriod okText={t('general.continue')} onOk={selectIntegrationPeriod} />
              </Col>
            </Row>
          </Route>
          <Route path={`${path}/users`}>
            <Row gutter={[0, 20]} style={{ marginBottom: '-10px' }}>
              <Col span={24}>
                <Title level={4}>{t('general.users')}</Title>
              </Col>
              <Col span={24}>
                <ManageSipuniUserAccounts
                  ref={manageUsersRef}
                  okText={t('general.users')}
                  integrationId={integrationId}
                  integrationInProcess
                  onOk={handleSelectUsers}
                />
              </Col>
            </Row>
          </Route>
          <Redirect exact from={`${path}/`} to={`${path}/users`} />
        </Switch>
      </Col>
      <Modal
        visible={!isEmpty(currentBackgroundJob)}
        footer={null}
        closable={false}
        maskClosable={false}
      >
        {currentBackgroundJob && (
          <BackgroundJob
            text={
              <video autoPlay loop muted playsinline>
                <source src={catWithSpinner} type="video/mp4" />
              </video>
            }
            description={
              <>
                <div>{t('backgroundJob.description')}</div>
              </>
            }
            spinning={false}
            onDone={onBackgroundJobDone}
            onFailed={onBackgroundJobFailed}
          />
        )}
      </Modal>
    </Row>
  );
};

export default CreateSipuniIntegrationPage;
