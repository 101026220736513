import Editor from 'components/Comments/Editor';
import UploadedFile from 'components/Comments/Editor/UploadedFile';
import SRow from 'components/Standard/SRow';
import SCol from 'components/Standard/SCol';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import SButton from 'components/Standard/SButton';
import Icon from 'components/Icon';
import { Send } from 'react-feather';
import { setEditingCommentId } from 'redux/ui/clientInteractionPage/reducer';
import { isEmpty } from 'lodash';
import DOMPurify from 'dompurify';

const CommentText = ({ commentState, setCommentState, isEditing, setEditing, onUpdate }) => {
  const dispatch = useDispatch();
  const loading = useSelector(state => state.commentsResource.updateByIdStarted);

  const onDeleteFile = async id => {
    await setCommentState({
      ...commentState,
      uploadedFiles: commentState?.uploadedFiles.filter(({ id: fileId }) => fileId !== id)
    });
    await onUpdate({
      id: commentState?.id,
      uploadedFilesIds: commentState?.uploadedFiles.filter(({ id: fileId }) => fileId !== id)
    });
  };

  const confirmUpdate = async id => {
    await onUpdate({
      id,
      text: commentState?.text,
      ratingFlag: commentState?.metadata?.ratingFlag,
      hidden: commentState?.hidden,
      uploadedFilesIds: commentState?.uploadedFiles?.map(file => file?.id)
    });
    setEditing(!isEditing);
    setCommentState({ ...commentState });
    dispatch(setEditingCommentId(null));
  };

  return (
    <StyledRow>
      {!isEditing && (
        <SRow gutter={[0, 8]} style={{ margin: '-4px' }}>
          <SCol span={24}>
            <span
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(commentState.text, {
                  ALLOWED_ATTR: ['target', 'href']
                })
              }}
              style={{ overflowWrap: 'break-word' }}
            />
          </SCol>
          <SCol span={24}>
            {commentState?.uploadedFiles?.map(file => (
              <UploadedFile
                uploadedFile={file}
                allFiles={commentState?.uploadedFiles}
                onDelete={onDeleteFile}
              />
            ))}
          </SCol>
        </SRow>
      )}
      {isEditing && (
        <SRow>
          <SCol width="calc(100% - 38px)">
            <Editor
              commentState={commentState}
              setCommentState={setCommentState}
              showTemplates
              showFlags
              showAttachments
            />
          </SCol>
          <SCol width="38px" paddingTop="56px" display="flex" justifyContent="flex-end">
            <SButton
              size="icon"
              icon={<Icon icon={Send} size="24px" />}
              border="none"
              boxShadow="none"
              backgroundColor
              loading={loading}
              color="var(--blue_6)"
              onClick={() => confirmUpdate(commentState?.id)}
              disabled={isEmpty(commentState?.text)}
              style={{ backgroundColor: 'transparent' }}
            />
          </SCol>
        </SRow>
      )}
    </StyledRow>
  );
};

const StyledRow = styled(SRow)`
  &.ant-row {
    display: block;
  }
  &.ant-row > span > p {
    margin: 0px;
  }
`;

export default CommentText;
