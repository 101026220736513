import { Col, Select, Row, Spin, Typography, List, Checkbox } from 'antd';
import { actions, operations } from 'redux/lists/usersToUnitList';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import UserPreview from 'components/UserPreview';
import { useSelector, useDispatch } from 'react-redux';
import { isEqual, throttle, isEmpty } from 'lodash';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { getUnitsByIds } from 'redux/selectors/units';
import { getRolesByIds } from 'redux/selectors/roles';
import Search from 'components/Inputs/Search';
import SModal from 'components/Standard/SModal';
import SSelect from 'components/Standard/SSelect';
import { selectSearch } from 'core/utils/selectSearch';
import { SListItem } from 'components/Standard/SList';
import SRow from './Standard/SRow';
import SCol from './Standard/SCol';

const { Text } = Typography;
const { Option } = Select;

const AddUsersModal = ({
  title,
  onAdd,
  onCancel,
  selectedUsersIds = [],
  visible,
  addedFilters = {},
  columns = ['employee', 'unit', 'role']
}) => {
  const { t } = useTranslation();
  const [selectedUsers, setSelectedUsers] = useState(selectedUsersIds);
  const dispatch = useDispatch();

  const columnNameToComponent = {
    employee: (
      <SCol span={10}>
        <SRow>
          <SCol width="24px" />
          <SCol padding="6px 0">
            <Text strong>{t('workPlanTaskConfigurationEditor.addUsersModal.users')}</Text>
          </SCol>
        </SRow>
      </SCol>
    ),
    unit: (
      <Col span={6} padding="6px 0">
        <Text strong>{t('workPlanTaskConfigurationEditor.addUsersModal.unit')}</Text>
      </Col>
    ),
    role: (
      <Col span={6} padding="6px 0">
        <Text strong>{t('workPlanTaskConfigurationEditor.addUsersModal.role')}</Text>
      </Col>
    )
  };

  const loading = useSelector(state => state.usersToUnitList.loading);
  const { totalPages, page, filters, search } = useSelector(
    state => state.usersToUnitList,
    isEqual
  );
  const users = useSelector(
    state => state.usersToUnitList.ids?.map(id => state.usersResource.byIds[id]),
    isEqual
  );

  const unitsByIds = useSelector(getUnitsByIds, isEqual);
  const rolesByIds = useSelector(getRolesByIds, isEqual);

  const onOk = () => {
    onAdd(selectedUsers);
  };

  const onSearch = search => {
    dispatch(actions.clearList());
    dispatch(actions.setSearch(search));
    dispatch(
      operations.load({
        search,
        page: { ...page, number: 1 },
        filters: { ...filters, ...addedFilters },
        sort: 'last_name',
        include: 'unit,role.levels'
      })
    );
  };

  useEffect(() => {
    if (visible) {
      setSelectedUsers(selectedUsersIds);
      dispatch(
        operations.load({
          search,
          page: { ...page, number: 1 },
          filters: { ...filters, ...addedFilters },
          sort: 'last_name',
          include: 'unit,role.levels'
        })
      );
    } else {
      dispatch(actions.setSearch(null));
      dispatch(actions.clearList());
      dispatch(actions.setFilters({}));
      setSelectedUsers([]);
    }

    return () => {
      dispatch(actions.setSearch(null));
      dispatch(actions.clearList());
      dispatch(actions.setFilters({}));
      setSelectedUsers([]);
    };
  }, [visible]);

  useEffect(() => {
    dispatch(actions.clearList());
    dispatch(
      operations.load({
        search,
        page: { ...page, number: 1 },
        filters: { ...filters, ...addedFilters },
        sort: 'last_name',
        include: 'unit,role.levels'
      })
    );
  }, [filters.unitsIds]);

  const hasNextPage = totalPages > page.number;

  const renderUser = user => {
    const onSelect = selected => {
      setSelectedUsers(
        selected
          ? [...selectedUsers, user.id]
          : selectedUsers.filter(selectedId => selectedId !== user.id)
      );
    };
    return (
      <SListItem
        padding="0"
        _hover="cursor:pointer"
        onClick={() => {
          onSelect(!selectedUsers.includes(user.id));
        }}
      >
        {columns.includes('employee') && (
          <Col span={10}>
            <Row gutter={[8, 0]} align="middle">
              <SCol padding="6px 0">
                <Checkbox
                  checked={selectedUsers.includes(user.id)}
                  onChange={e => {
                    e.stopPropagation();
                    onSelect(e.target.checked);
                  }}
                />
              </SCol>
              <SCol padding="6px 0" maxWidth="calc(100% - 24px)">
                <UserPreview showAvatar disabled user={user} />
              </SCol>
            </Row>
          </Col>
        )}

        {columns.includes('unit') && (
          <Col span={6} padding="6px 0">
            <Text>{unitsByIds[user.unitId]?.name}</Text>
          </Col>
        )}

        {columns.includes('role') && (
          <Col span={6} padding="6px 0">
            <Text>{rolesByIds[user.roleId]?.name}</Text>
          </Col>
        )}
      </SListItem>
    );
  };

  const onScrollY = throttle(async el => {
    const isOnBottom = (element => {
      return element.clientHeight + element.scrollTop + 20 >= element.scrollHeight;
    })(el);

    if (hasNextPage && !loading && isOnBottom) {
      const newPage = `${parseInt(page.number) + 1}`;
      dispatch(actions.updatePage({ number: newPage }));
      dispatch(
        operations.load({
          search,
          page: { ...page, number: newPage },
          filters: { ...filters, ...addedFilters },
          sort: 'last_name',
          include: 'unit,role.levels'
        })
      );
    }
  }, 250);

  return (
    <SModal
      width="887px"
      size="big"
      destroyOnClose
      title={
        <Row align="middle" justify="space-between" style={{ paddingRight: '25px' }}>
          <Col>
            <Row>
              <Col>{isEmpty(title) ? t('components.addUsersModal') : title}</Col>
            </Row>
          </Col>
          <Col>
            <Row gutter={[20, 0]}>
              <Col>
                <SSelect
                  mode="multiple"
                  width="200px"
                  optionLabelProp="label"
                  allowClear
                  placeholder={t(
                    'clientInteractionsPage.tableFilters.tableGeneralFilters.form.unit'
                  )}
                  maxTagCount={0}
                  maxTagPlaceholder={selectedKeys =>
                    `${t('clientInteractionsPage.tableFilters.tableGeneralFilters.form.units')} ${
                      selectedKeys.length
                    }`
                  }
                  onChange={unitsIds => dispatch(actions.updateFilters({ unitsIds }))}
                  value={filters.unitsIds || []}
                  filterOption={(input, option) =>
                    selectSearch({ input, option, searchProp: 'children' })
                  }
                >
                  {Object.values(unitsByIds).map(({ id, name }) => (
                    <Option key={id} value={id} label={name}>
                      {name}
                    </Option>
                  ))}
                </SSelect>
              </Col>
              <Col>
                <Search
                  loading={loading}
                  placeholder={t(
                    'organizationStructure.tabs.employees.table.filters.employeesSearch'
                  )}
                  onSearch={onSearch}
                  style={{ width: 200 }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      }
      visible={visible}
      onOk={onOk}
      onCancel={onCancel}
      okText={t('general.continue')}
      cancelText={t('general.cancel')}
    >
      <List.Item style={{ borderBottom: '1px solid var(--gray_4)', padding: '0' }}>
        {columns.map(columnName => columnNameToComponent[columnName])}
      </List.Item>
      <PerfectScrollbar
        onScrollY={onScrollY}
        options={{ suppressScrollX: true }}
        style={{ maxHeight: '385px' }}
      >
        <List dataSource={users} renderItem={renderUser} rowKey="id" />
        <Spin spinning={loading}>
          <List.Item />
        </Spin>
      </PerfectScrollbar>
    </SModal>
  );
};

export default AddUsersModal;
