export default {
  checklistSettingsPage: {
    questionsWithoutGroup: 'Критерии без группы',
    checklistSuccessfullyCreated: 'Форма оценки успешно создана',
    checklistSuccessfullyUpdated: 'Форма оценки успешно обновлена',
    updateChecklistFailed: 'Не удалось обновить форму оценки',
    createChecklistFailed: 'Не удалось создать форму оценки',
    breadcrumbs: {
      checklists: 'Формы оценки',
      checklistSettings: 'Настройки формы оценки'
    },
    title: 'Настройки формы оценки',
    info:
      'Укажите здесь общие параметры настроек выбранной формы оценки, которые будут применяться ко всем критериям. Нажмите кнопку “Сохранить”, чтобы перейти к странице создания групп и критериев. \n Вы сможете изменить данные настройки в любое время. Подробнее о всех настройках и возможностях можно ',
    infoButton: 'прочитать тут.',
    form: {
      title: 'Основные настройки',

      fields: {
        name: 'Название',
        nameInfo:
          'Это название отображается при выборе формы оценки во время проверки в виджетах аналитики.',
        namePlaceholder: 'Введите название формы оценки',

        description: 'Описание',
        descriptionInfo:
          'Опишите, для каких целей вы используете данную форму оценки, кого или что вы хотите оценивать с помощью него',
        descriptionPlaceholder: 'Введите описание формы оценки',

        units: 'Отделы',
        unitsInfo:
          'Форма оценки будет доступна для коммуникаций только выбранных отделов. Сотрудники из других отделов не смогут проводить оценку по нему.',
        unitsPlaceholder: 'Выберите отдел',
        unitsPlaceholderMax: 'Отделы:',

        communicationTypes: 'Типы коммуникаций',
        communicationType: 'Тип коммуникаций:',
        communicationTypesPlaceholder: 'Выберите типы коммуникаций',
        communicationTypesPlaceholderMax: 'Типы коммуникаций:',
        communicationTypesInfo:
          'Форма оценки будет доступна для проверки только выбранных типов коммуникаций.',

        ratingMethod: 'Метод оценки',
        ratingMethodInfo:
          'При простой оценке каждый критерий формы оценки имеет одинаковый вес. При выборе “Взвешенной” вам нужно будет указать % значимости выставленного балла по критерию в подсчете среднего балла формы оценки.',

        ratingCalculation: 'Расчет оценки',
        ratingCalculationInfo:
          'Выберите, какой хотите получать итоговый результат проверки по форме оценки.',

        ratingMode: 'Отображение оценки',
        ratingModeInfo:
          'Выберите, каким хотите видеть итоговый результат - набранные баллы или их процент от максимально возможного результата',

        additionalSettings: 'Дополнительные настройки',

        scaleType: 'Шкала оценки',
        scaleTypeInfo:
          'Выберите готовую шкалу оценки либо в “произвольной шкале” укажите та баллы, которые нужны вам для проверки по форме оценки.',

        valuesDisplayType: 'Отображение в форме оценки',
        valuesDisplayTypeInfo:
          'Графический вид отображения (смайлики) шкалы будет доступен только на странице оценки. Итоговый результат будет отображаться в числовом виде.',

        autofailEnabled: 'Автофейл формы оценки',
        autofailEnabledInfo:
          'Выберите “Да”, если хотите добавить в форму оценки критерии, минимальная оценка которых приведет к автоматической оценке всей формы. В таком случае итоговая оценка будет минимальной из возможных.',
        noAnswerAsMax: 'Учитывать N/A как макс. балл критерия',
        noAnswerAsMaxInfo:
          'Выберите “Да”, если хотите чтобы критерии, которые остались без ответа, считались как максимальное возможное значение по данному критерию'
      },
      messages: {
        enterName: 'Пожалуйста введите название'
      },
      alerts: {
        disabledByQuestions: {
          title:
            'Вы не можете изменить шкалу оценки пока в форме оценки находятся критерии с уже установленной шкалой'
        },
        colorZones: {
          title: 'Зоны оценки',
          descriptionPT1:
            'Так как вы выбрали Расчет оценки - Сумма баллов или Шкала оценки - Произвольная шкала, настройка зон оценки будет активна перед публикацией формы оценки.',
          descriptionPT2:
            'Зоны позволяют добавить и настроить цветовое обозначение итоговых результатов проверки по форме оценки. Т.е. когда итоговый балл попадает в указанный в зоне диапазон он будет отображаться выбранным цветом зоны. Кроме того, это позволит легко понимать и анализировать, в каких зонах чаще всего находятся оценки сотрудников.'
        },
        button: 'Подробнее здесь'
      }
    },
    buttons: {
      cancel: 'Отмена',
      save: 'Сохранить'
    }
  }
};
