import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { get } from 'lodash';
import { Col, Tooltip, Spin, Row } from 'antd';
import {
  widgetPeriodSetter,
  widgetNameSetter,
  widgetInfoTextSetter,
  widgetValueTypeSetter,
  getDateSelectorType
} from '../utils';
import SettingsModal from '../Components/SettingsModal';
import { SmallWidgetCard, SettingsButtonCol, WidgetNameCol, Value, TimePeriod } from '../styled';

const ScoreWidget = ({ widgetData, loadingWidgetId }) => {
  const checklistDefinitionsByIds = useSelector(
    state => state.checklistDefinitionsResource.byIds,
    shallowEqual
  );
  const { id, type, filters, name } = widgetData;

  const widgetValue = useSelector(state => state.dashboardAnalytics.byIds[id]);

  const dateSelectorType = getDateSelectorType({ widgetType: type });
  const checklistDefinitionRatingMode = get(
    checklistDefinitionsByIds,
    filters.checklistDefinitionsIds,
    {}
  ).ratingMode;

  return (
    <Col span={4}>
      <SmallWidgetCard>
        <Row type="flex">
          <WidgetNameCol span={20}>
            {widgetInfoTextSetter({ type })}

            <Tooltip title={widgetNameSetter({ name, type })}>
              <span>{widgetNameSetter({ name, type })}</span>
            </Tooltip>
            <TimePeriod type="secondary">
              {widgetPeriodSetter(filters, dateSelectorType)}
            </TimePeriod>
          </WidgetNameCol>
          <SettingsButtonCol span={4}>
            <SettingsModal widgetData={widgetData} />
          </SettingsButtonCol>
        </Row>
        <Spin spinning={loadingWidgetId === id}>
          <Row>
            <Value>
              {widgetValue
                ? `${widgetValueTypeSetter[type](widgetValue, checklistDefinitionRatingMode)}`
                : 0}
            </Value>
          </Row>
        </Spin>
      </SmallWidgetCard>
    </Col>
  );
};

export default ScoreWidget;
