import React from 'react';
import { Col, Tooltip, Row } from 'antd';
import { connect } from 'react-redux';
import { isEmpty, orderBy, get } from 'lodash';
import { ResponsiveBar } from '@nivo/bar';
import truncateString from 'core/utils/truncateString';
import { prepareData, prepareKeys, prepareColors } from './prepareFunctions';
import SettingsModal from '../../Components/SettingsModal';
import {
  widgetNameSetter,
  widgetPeriodSetter,
  widgetInfoTextSetter,
  getDateSelectorType
} from '../../utils';
import { SettingsButtonCol, WidgetNameCol, WidgetCard, TimePeriod } from '../../styled';
import EmptyWidget from '../../Components/EmptyWidget';

const LabelsCountByUnits = ({ widgetData, widgetsAnalyticsByIds, unitsByIds, loadingWidgetId }) => {
  const widgetValue = get(widgetsAnalyticsByIds, widgetData.id, []);

  const { id, type, filters, name } = widgetData;
  const dateSelectorType = getDateSelectorType({ widgetType: type });

  return (
    <>
      {isEmpty(widgetValue) || loadingWidgetId === id ? (
        <EmptyWidget key={id} widgetData={widgetData} loadingWidgetId={loadingWidgetId} />
      ) : (
        <Col span={6}>
          <WidgetCard>
            <Row type="flex">
              <WidgetNameCol span={20}>
                {widgetInfoTextSetter({ type })}
                <Tooltip title={widgetNameSetter({ name, type })}>
                  <span>{widgetNameSetter({ name, type })}</span>
                </Tooltip>
                <TimePeriod type="secondary">
                  {widgetPeriodSetter(filters, dateSelectorType)}
                </TimePeriod>
              </WidgetNameCol>
              <SettingsButtonCol span={4}>
                <SettingsModal widgetData={widgetData} />
              </SettingsButtonCol>
            </Row>
            <ResponsiveBar
              data={orderBy(prepareData({ widgetValue, unitsByIds }), 'name', 'desc')}
              keys={prepareKeys({ widgetValue })}
              indexBy="name"
              margin={{ top: 0, right: 10, bottom: 70, left: 120 }}
              padding={0.3}
              layout="horizontal"
              colors={prepareColors({ widgetValue, keys: prepareKeys({ widgetValue }) })}
              borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
              axisTop={null}
              axisRight={null}
              axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0
              }}
              axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                format: string => truncateString(string, 15)
              }}
              labelSkipWidth={12}
              labelSkipHeight={12}
              labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
              animate
              motionStiffness={90}
              motionDamping={15}
            />
          </WidgetCard>
        </Col>
      )}
    </>
  );
};

const mapStateToProps = state => {
  return {
    widgetsAnalyticsByIds: state.dashboardAnalytics.byIds,
    unitsByIds: state.unitsResource.byIds
  };
};

export default connect(mapStateToProps, null)(LabelsCountByUnits);
