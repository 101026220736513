/* eslint-disable react/no-did-update-set-state */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable import/no-extraneous-dependencies */
import { CloseCircleFilled } from '@ant-design/icons';
import { Button, Col, DatePicker, Divider, Row, Select } from 'antd';
import SText from 'components/Standard/SText';
import { SYMBOLIC_TIME_RANGE, SYMBOLIC_TIME_RANGE_LITERALS } from 'core/utils/constants';
import { isEqual, some } from 'lodash';
import { uniqueId } from 'lodash/fp';
import moment from 'moment';
import React from 'react';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';

const { RangePicker } = DatePicker;
const { Option } = Select;

const StyledRangePicker = styled(RangePicker)`
  && {
    .ant-calendar-footer-extra {
      width: 100%;
    }
  }
`;

const CustomOption = styled.div`
  && {
    line-height: 22px;
    padding: 5px 12px;
    color: var(--text);

    &:hover {
      cursor: pointer;
    }
  }
`;

const SelectWrapper = styled.div`
  display: flex;
`;

const directionCSSValue = {
  // '-568px' : '-34px'
  left: '-568px',
  right: '-34px'
};

class TimeSpecificSelector extends React.Component {
  state = {
    timeRanges: Object.values(SYMBOLIC_TIME_RANGE),
    datepickerOpen: false,
    selectedTimeRange: undefined,
    selectedCustomTimeRange: undefined,
    dropdownOpen: false,
    disabledDate: undefined,
    popupId: uniqueId(),
    selectId: uniqueId(),
    timePickerId: uniqueId()
  };

  selectRef = React.createRef();

  onTimeRangeChange = selectedTimeRange => {
    this.setState(
      {
        selectedTimeRange,
        selectedCustomTimeRange: undefined,
        dropdownOpen: false,
        datepickerOpen: false
      },
      () => this.selectRef.current.blur()
    );

    this.props.onChange({ symbolicTimeRange: selectedTimeRange });
  };

  onSetCustomTimeRange = ({ nativeEvent }) => {
    nativeEvent.stopPropagation();
    const { selectedCustomTimeRange } = this.state;
    this.setState({
      selectedTimeRange: undefined,
      dropdownOpen: false,
      datepickerOpen: false
    });

    this.props.onChange({
      timeFrom: moment(selectedCustomTimeRange[0])
        .startOf('day')
        .toISOString(true),
      timeTo: moment(selectedCustomTimeRange[1])
        .endOf('day')
        .toISOString(true)
    });
  };

  onRemove = e => {
    e.preventDefault();
    e.stopPropagation();
    const { defaultValue } = this.props;
    this.setState(
      {
        selectedTimeRange: defaultValue,
        selectedCustomTimeRange: undefined,
        dropdownOpen: false,
        datepickerOpen: false
      },
      () => this.selectRef.current.blur()
    );
    this.props.onChange({ symbolicTimeRange: defaultValue, from: undefined, to: undefined });
  };

  componentDidUpdate = ({ value: prevValue }) => {
    const { value } = this.props;

    // TODO: add dynamic ranges change
    if (
      !isEqual(value, prevValue) &&
      !isEqual(
        [value.timeFrom, value.timeTo],
        [
          moment(this.state.selectedCustomTimeRange?.[0]).toISOString(),
          moment(this.state.selectedCustomTimeRange?.[1]).toISOString()
        ]
      )
    ) {
      this.setState(
        value.timeTo && value.timeFrom
          ? {
              selectedCustomTimeRange: [moment(value.timeFrom), moment(value.timeTo)],
              selectedTimeRange: undefined,
              dropdownOpen: false,
              datepickerOpen: false
            }
          : {
              selectedTimeRange: value.symbolicTimeRange,
              selectedCustomTimeRange: undefined,
              dropdownOpen: false,
              datepickerOpen: false
            }
      );
    }
  };

  componentDidMount = () => {
    const { value, timeRanges, disabledDate } = this.props;

    document.body.addEventListener('click', this.selectWrapperClickHandler);

    if (timeRanges) {
      this.setState({ timeRanges });
    }

    if (disabledDate) {
      this.setState({ disabledDate });
    }

    this.setState(
      value.timeTo && value.timeFrom
        ? {
            selectedCustomTimeRange: [moment(value.timeFrom), moment(value.timeTo)],
            selectedTimeRange: undefined,
            dropdownOpen: false,
            datepickerOpen: false
          }
        : {
            selectedTimeRange: value.symbolicTimeRange,
            selectedCustomTimeRange: undefined,
            dropdownOpen: false,
            datepickerOpen: false
          }
    );
  };

  componentWillUnmount = () => {
    document.body.removeEventListener('click', this.selectWrapperClickHandler);
  };

  getCustomPeriodText() {
    const { selectedCustomTimeRange } = this.state;
    const { placeholder, insertedPlaceholder } = this.props;

    if (selectedCustomTimeRange) {
      const customDateString = `${moment(selectedCustomTimeRange[0]).format(
        'DD/MM/YYYY'
      )} ~ ${moment(selectedCustomTimeRange[1]).format('DD/MM/YYYY')}`;

      return insertedPlaceholder ? (
        <>
          <SText type="secondary">{`${placeholder}: `}</SText>
          <SText>{customDateString}</SText>
        </>
      ) : (
        <SText>{customDateString}</SText>
      );
    }

    return this.props.t('components.timeSpecificSelector.otherPeriod');
  }

  selectWrapperClickHandler = nativeEvent => {
    const path = nativeEvent.path || nativeEvent.composedPath();

    const popupComponentClicked = some(
      path,
      ({ classList }) =>
        (typeof classList?.contains === 'function' && classList?.contains(this.state.selectId)) ||
        classList?.contains(this.state.popupId) ||
        classList?.contains(this.state.timePickerId)
    );

    const optionClicked = some(
      path,
      ({ classList }) =>
        typeof classList?.contains === 'function' && classList?.contains('ant-select-item-option')
    );

    if (optionClicked) return;

    if (popupComponentClicked) {
      this.setState({ dropdownOpen: true });
    } else {
      this.setState({ dropdownOpen: false, datepickerOpen: false });
    }
  };

  toggleDropdownOpen = () => {
    this.setState({ dropdownOpen: !this.state.dropdownOpen });
  };

  updateSelectedCustomTimeRange = selectedCustomTimeRange => {
    this.setState({ selectedCustomTimeRange });
  };

  toggleDatepickerOpen = e => {
    e.stopPropagation();
    this.setState({ datepickerOpen: !this.state.datepickerOpen });
  };

  render() {
    const {
      timeRanges,
      selectedTimeRange,
      datepickerOpen,
      dropdownOpen,
      selectedCustomTimeRange,
      disabledDate
    } = this.state;
    const {
      disabled,
      style = { width: '230px' },
      placeholder = this.props.t('components.timeSpecificSelector.placeholder'),
      datePickerDirection = 'left',
      allowClear = true,
      defaultValue = SYMBOLIC_TIME_RANGE.ALL_TIME,
      size,
      insertedPlaceholder
    } = this.props;

    const activeCustom = datepickerOpen || selectedCustomTimeRange;

    const value = activeCustom ? this.getCustomPeriodText() : selectedTimeRange || defaultValue;

    return (
      <SelectWrapper>
        <Select
          dropdownClassName={`time-specific-select ${this.state.popupId}`}
          className={`time-specific-select-input ${this.state.selectId}`}
          ref={this.selectRef}
          style={style}
          disabled={disabled}
          placeholder={placeholder}
          onChange={this.onTimeRangeChange}
          value={value}
          open={datepickerOpen || dropdownOpen}
          clearIcon={<CloseCircleFilled onClick={this.onRemove} />}
          allowClear={allowClear}
          size={size}
          optionLabelProp={insertedPlaceholder ? 'customPlaceholderView' : undefined}
          dropdownRender={menu => (
            <>
              {activeCustom && dropdownOpen && (
                <StyledRangePicker
                  open
                  disabledDate={disabledDate}
                  disabled={disabled}
                  format="DD/MM/YYYY"
                  style={{
                    visibility: 'hidden',
                    height: 0,
                    display: 'block',
                    width: 0,
                    position: 'absolute',
                    top: '0',
                    [datePickerDirection]: directionCSSValue[datePickerDirection]
                  }}
                  dropdownClassName={`time-specific-selector-popup ${this.state.timePickerId}`}
                  value={selectedCustomTimeRange}
                  renderExtraFooter={() => (
                    <Row type="flex">
                      <Col span={24}>
                        <Button
                          type="primary"
                          disabled={
                            !(
                              selectedCustomTimeRange &&
                              selectedCustomTimeRange[0] &&
                              selectedCustomTimeRange[1]
                            )
                          }
                          block
                          onClick={this.onSetCustomTimeRange}
                        >
                          {this.props.t('components.timeSpecificSelector.selectButton')}
                        </Button>
                      </Col>
                    </Row>
                  )}
                  onChange={this.updateSelectedCustomTimeRange}
                />
              )}

              {menu}

              <Divider type="horizontal" style={{ margin: 0 }} />

              <CustomOption
                className={`ant-select-item ant-select-item-option ${
                  activeCustom ? 'ant-select-item-option-selected' : ''
                }`}
                // onMouseDown={e => e.preventDefault()}
                onClick={this.toggleDatepickerOpen}
              >
                {this.props.t('components.timeSpecificSelector.otherPeriod')}
              </CustomOption>
            </>
          )}
        >
          {timeRanges.map(timeRange => (
            <Option
              key={timeRange}
              value={timeRange}
              activeCustom={activeCustom}
              customPlaceholderView={
                <>
                  <SText type="secondary">{`${placeholder}: `}</SText>
                  <SText>{this.props.t(SYMBOLIC_TIME_RANGE_LITERALS[timeRange])}</SText>
                </>
              }
            >
              {this.props.t(SYMBOLIC_TIME_RANGE_LITERALS[timeRange])}
            </Option>
          ))}
        </Select>
      </SelectWrapper>
    );
  }
}

export default withTranslation()(TimeSpecificSelector);
