import { Descriptions, Typography } from 'antd';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import SCard from 'components/Standard/SCard';
import { useSelector } from 'react-redux';
import { isBoxVersion } from 'core/config';
import { isEqual, isNil, isEmpty } from 'lodash';
import { PLANS_LITERALS } from 'core/utils/constants';
import withConditionalRender from 'components/WithConditionalRender/withConditionalRender';
import { Info as InfoIcon } from 'components/Info';
import SCol from 'components/Standard/SCol';
import SRow from 'components/Standard/SRow';

const OrganizationInfo = () => {
  const { t } = useTranslation();
  const { Text, Title } = Typography;

  const organizationInfo = useSelector(state => state.organizationInfo.organization, isEqual);

  const { createdAt, usersCount, monthlyReviewsCount, planSlug } = organizationInfo;

  const isBox =
    isNil(planSlug) || isEmpty(planSlug) || !Object.keys(PLANS_LITERALS).includes(planSlug);

  const ConditonalItem = withConditionalRender({ hide: isBoxVersion }, Descriptions.Item);

  const loading = useSelector(state => state.organizationInfo.loading);

  return (
    <SCol span={24}>
      <SCard shadowed bordered loading={loading} bodyPadding="20px 20px 0px 20px">
        <SRow gutter={[0, 20]}>
          <SCol>
            <Title level={5}>{t('userSettingsPage.tabs.info.tabName')}</Title>
          </SCol>
          <SCol>
            <Descriptions column={{ lg: 20, xl: 24, xxl: 24 }}>
              <ConditonalItem
                span={9}
                label={
                  <Text type="secondary">{t('userSettingsPage.organization.currentPlanSlug')}</Text>
                }
              >
                <Text strong>
                  {isBox ? t(PLANS_LITERALS.notInstalled.name) : t(PLANS_LITERALS[planSlug].name)}
                </Text>
                <InfoIcon
                  tooltip={{ title: t('organizationSettingsPage.buttons.paymentPlansInfo') }}
                  iconProps={{
                    onClick: () => window.open('https://qolio.io/annual_price'),
                    style: { margin: '0px 5px', color: 'var(--primary)' }
                  }}
                />
              </ConditonalItem>

              <Descriptions.Item
                span={15}
                label={
                  <Text type="secondary">
                    {t('userSettingsPage.organization.registrationDate')}
                  </Text>
                }
              >
                <Text strong>{moment(createdAt).format('DD/MM/YYYY')}</Text>
              </Descriptions.Item>

              <Descriptions.Item
                span={9}
                label={
                  <Text type="secondary">{t('userSettingsPage.organization.registeredUsers')}</Text>
                }
              >
                <Text strong>{usersCount}</Text>
              </Descriptions.Item>

              <Descriptions.Item
                span={15}
                label={
                  <Text type="secondary">
                    {t('userSettingsPage.organization.monthReviewsCount')}
                  </Text>
                }
              >
                <Text strong>{monthlyReviewsCount}</Text>
              </Descriptions.Item>
            </Descriptions>
          </SCol>
        </SRow>
      </SCard>
    </SCol>
  );
};

export default OrganizationInfo;
