import { Col, Row, Skeleton } from 'antd';
import SCard from 'components/Standard/SCard';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { rolesResource } from 'redux/resources/roles';
import { unitsResource } from 'redux/resources/units';
import UnitEmployeesList from './UnitEmployeesList';
import UnitsTree from './UnitsTree';

const StructurePage = () => {
  const [loading, setLoading] = useState(false);
  const selectedUnitId = useSelector(state => state.uiOrganizationPage?.selectedUnit?.id);
  const dispatch = useDispatch();

  const intialLoad = async () => {
    setLoading(true);
    await Promise.all([
      dispatch(unitsResource.operations.load()),
      dispatch(rolesResource.operations.load({ include: 'levels' }))
    ]);
    setLoading(false);
  };

  useEffect(() => {
    intialLoad();
  }, []);

  const { t } = useTranslation();

  return (
    <Row gutter={[18, 18]} style={{ margin: '-9px' }}>
      <Helmet>
        <title>{t('pagesMeta.organizationStructurePage.tabs.organizationStructure.title')}</title>
      </Helmet>
      <Col lg={24} xl={12}>
        {!loading ? (
          <UnitsTree />
        ) : (
          <SCard>
            <Skeleton />
          </SCard>
        )}
      </Col>
      <Col lg={24} xl={12}>
        {selectedUnitId ? <UnitEmployeesList /> : null}
      </Col>
    </Row>
  );
};

export default StructurePage;
