import { Col, Row, Select } from 'antd';
import SCard from 'components/Standard/SCard';
import React, { useState } from 'react';
import { SYNC_DATA_PERIODS } from 'core/utils/constants';
import { useTranslation } from 'react-i18next';
import SButton from 'components/Standard/SButton';

const { Option } = Select;
const SyncPeriod = ({
  disabled,
  defaultValue = SYNC_DATA_PERIODS.WEEK.value,
  onOk,
  okText,
  okProps = {}
}) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(defaultValue);
  const [loading, setLoading] = useState(false);
  const handleOk = async () => {
    setLoading(true);
    await onOk(value);
    setLoading(false);
  };
  return (
    <SCard>
      <Row align="middle" justify="space-between">
        <Col>
          <Select
            size="large"
            style={{ width: '380px' }}
            disabled={disabled}
            onChange={setValue}
            value={value}
            placeholder={t('integrationsSettingsPage.selectSyncPeriod')}
          >
            {Object.values(SYNC_DATA_PERIODS).map(period => (
              <Option value={period.key} key={period.key}>
                {t(period.name)}
              </Option>
            ))}
          </Select>
        </Col>
        <Col>
          <SButton
            width="230px"
            type="primary"
            size="big"
            onClick={handleOk}
            loading={loading}
            disabled={!value}
            {...okProps}
          >
            {okText}
          </SButton>
        </Col>
      </Row>
    </SCard>
  );
};

export default SyncPeriod;
