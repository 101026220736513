import { Button, Typography } from 'antd';
import ClientInteractionLink from 'components/ClientInteractionLink';
import CommentsPreview from 'components/Comments/CommentsPreview';
import CustomFieldValueView from 'components/CustomFieldValueView';
import StatusSelector from 'components/PhoneCallPlayer/RecordPlayer/StatusSelector';
import { TagsDisplay } from 'components/Tags/TagsDisplay';
import UserPreview from 'components/UserPreview';
import addScopeToObjectKeys from 'core/utils/addScopeToObject';
import { CALL_DIRECTION_LITERALS } from 'core/utils/constants';
import { beatifyFloat } from 'core/utils/convertFloat';
import { reduce } from 'lodash';
import moment from 'moment';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { utils } from 'react-media-player';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { DeactivatedUserLinkToCommunication } from './DeactivatedLinkToCommunication';

const { Text } = Typography;

const renderCustomField = ({ key, customFieldsByKeys }) => {
  return (data, record) => {
    return (
      <CustomFieldValueView
        clientInteractionCustomFields={addScopeToObjectKeys('customField', record.customFields)}
        customFieldsByKeys={customFieldsByKeys}
        customFieldKey={key}
        value={data}
      />
    );
  };
};

const ColumnsRender = ({ onRowExpand, customFieldsByKeys, col, text, record }) => {
  const { t } = useTranslation();

  const renderColumnData = useMemo(
    () => ({
      communicationType: (
        type,
        { reviewId, operator, id, communicationType, clientInteractionType }
      ) =>
        operator.active ? (
          <ClientInteractionLink
            communication={{ communicationType, clientInteractionType }}
            isReview={!!reviewId}
            id={reviewId || id}
            style={{ width: '24px', height: '24px', display: 'block' }}
          />
        ) : (
          <DeactivatedUserLinkToCommunication>
            <ClientInteractionLink
              style={{
                width: '24px',
                height: '24px',
                display: 'block',
                color: 'var(--blue_2)'
              }}
              communication={{ communicationType, clientInteractionType }}
              isReview={!!reviewId}
              id={reviewId || id}
            />
          </DeactivatedUserLinkToCommunication>
        ),
      startedAt: data =>
        data ? (
          <CenteredData>
            <Text strong style={{ paddingRight: '4px' }}>
              {`${moment(data).format('DD/MM/YYYY')},`}
            </Text>
            <Text>{moment(data).format('HH:mm')}</Text>
          </CenteredData>
        ) : (
          ''
        ),
      duration: data =>
        data ? (
          <CenteredData>
            <Text strong>{utils.formatTime(data)}</Text>
          </CenteredData>
        ) : (
          ''
        ),
      direction: data => t(CALL_DIRECTION_LITERALS[data]),
      operatorId: (data, record) => {
        const userId = data || record?.reviewOperatorId;
        return userId ? <UserPreview userId={userId} disabled showAvatar margin="0px" /> : '';
      },
      isReviewed: (data, record) =>
        record.clientInteractionType &&
        (record.reviewId
          ? t('constants.reviewStatuses.reviewed')
          : t('constants.reviewStatuses.notReviewed')),
      reviewsCount: (data, record) => {
        const reviews = record?.reviews;

        if (reviews?.length === 1) {
          return (
            <CenteredLink to={`/reviews/${reviews[0].reviewId}`}>{reviews.length}</CenteredLink>
          );
        }

        return reviews?.length > 1 ? (
          <CenteredButton type="link" onClick={() => onRowExpand(record)}>
            {reviews.length}
          </CenteredButton>
        ) : null;
      },
      clientId: (data, record) => record?.client?.integrationUid || null,
      unitId: (data, record) => record?.unit?.name || null,
      nps: data => <CenteredData>{data}</CenteredData>,
      email: (data, record) => record?.email,
      clientPhoneNumber: data => data,
      partsCount: (data, record) => (
        <CenteredData> {record.textCommunicationPartsIds?.length || ''}</CenteredData>
      ),
      score: (data, record) => (
        <CenteredData>{beatifyFloat(record?.reviewChecklist?.score ?? data)}</CenteredData>
      ),
      chatId: (data, record) => record.communicationId || null,
      ticketId: (data, record) => record.communicationId || null,
      reviewReviewerId: data =>
        data ? <UserPreview disabled userId={data} showAvatar margin="0px" hidden /> : null,
      reviewCreatedAt: data =>
        data ? (
          <CenteredData>
            <Text strong style={{ paddingRight: '4px' }}>
              {`${moment(data).format('DD/MM/YYYY')},`}
            </Text>
            <Text>{moment(data).format('HH:mm')}</Text>
          </CenteredData>
        ) : null,
      checklistDefinitionName: (data, record) => record?.reviewChecklistDefinition?.name || null,
      reviewComments: (data, record) => (
        <CommentsPreview
          comments={data || []}
          checklistData={{ checklist: record.reviewChecklist }}
        />
      ),
      reviewTasksCount: (data, record) => {
        const tasksCount = record?.tasks?.length || 0;

        return tasksCount > 1 ? (
          <CenteredButton type="link" onClick={() => onRowExpand(record)}>
            {tasksCount}
          </CenteredButton>
        ) : null;
      },
      taskTaskDefinitions: (data, record) => (
        <TagsDisplay tasks={record.tasks || []} truncate={16} />
      ),
      status: (data, record) =>
        (record?.type === 'client-interactions' || record?.type === 'client_interactions') && (
          <StatusSelector canAddStatus={false} allowLoadStatuses={false} call={record} />
        ),
      ...reduce(
        customFieldsByKeys,
        (acc, curr, key) => ({ ...acc, [key]: renderCustomField({ key, customFieldsByKeys }) }),
        {}
      )
    }),
    [onRowExpand, customFieldsByKeys]
  );

  return renderColumnData[col] && renderColumnData[col](text, record)
    ? renderColumnData[col](text, record)
    : null;
};

export default ColumnsRender;

const CenteredButton = styled(Button)`
  width: 100%;
  align-self: center;
`;

const CenteredLink = styled(Link)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CenteredData = styled.span`
  width: 100%;
  display: flex;
  justify-content: center;
`;
