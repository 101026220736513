export default {
  organizationStructure: {
    moved: 'Mossa',
    moveToUnit: 'Sposta in reparto',
    employeesList: 'Elenco dei dipendenti aggiunti',
    addEmployee: 'Aggiungi dipendente',
    addEmployee: 'Aggiungi dipendente',
    tabs: {
      units: {
        title: "La struttura dell'azienda",
        modal: {
          title: 'Aggiungi reparto',
          ok: 'Aggiungere',
          cancel: 'Annulla',
          form: {
            name: 'Nome del dipartimento',
            namePlaceholder: 'Inserisci il nome del reparto',
            messages: { errors: { enterName: 'Per favore inserisci un nome di reparto' } }
          }
        },
        confirmDelete: {
          title: 'Eliminare il reparto?',
          ok: 'Elimina',
          cancel: 'Annulla'
        }
      },
      employees: {
        title: 'Dipendenti',
        table: {
          columns: {
            name: 'Dipendente',
            unit: 'Dipartimento',
            role: 'Ruolo',
            level: 'Livello',
            access: 'Accesso',
            status: 'Stato'
          },
          filters: {
            totalCountEmployees: 'Utenti totali:',
            employeesSearch: 'Ricerca',
            unitsIdsPlaceholder: 'Seleziona reparto',
            unitsIdsMaxPlaceholder: 'Dipartimenti:',
            rolesIdsPlaceholder: 'Scegli un ruolo',
            rolesIdsMaxPlaceholder: 'Ruoli:',
            statusPlaceholder: 'Seleziona lo stato',
            invitationStatusPlaceholder: 'Seleziona il livello di accesso'
          },
          buttons: { addEmployee: 'Aggiungi utente' },
          menu: {
            items: {
              activate: 'Attivare',
              deactivate: 'Disattivare',
              sendInvite: 'Invia un invito',
              editEmployee: 'Modificare',
              employeeProfile: 'Vai al profilo'
            },
            messages: { invitationSended: 'Invito inviato' }
          }
        },
        modal: {
          title: 'Impostazioni utente',
          buttons: {
            sendInvite: 'Invia un invito al sistema',
            cancel: 'Annulla',
            save: 'Salvare'
          },
          messages: {
            employee: 'Utente',
            created: 'aggiunto',
            updated: 'aggiornato'
          },
          form: {
            fields: {
              lastName: 'Cognome',
              lastNamePlaceholder: 'Inserisci il cognome',
              firstName: 'Nome e patronimico',
              firstNamePlaceholder: 'Inserisci nome e patronimico',
              email: 'E-mail',
              emailPlaceHolder: 'Inserisci e-mail',
              integrationId: 'Id nel sistema integrato',
              integrationIdPlaceholder: 'Inserisci id nel sistema integrato',
              role: 'Ruolo',
              rolePlaceholder: 'Scegli un ruolo',
              unit: 'Dipartimento',
              unitPlaceHolder: 'Seleziona reparto'
            },
            messages: {
              enterLastName: 'Per favore inserisci il cognome',
              enterFirstName: 'Per favore inserisci nome e patronimico',
              enterEmail: "Per favore inserisci l'e-mail",
              enterValidEmail: 'Hai inserito un indirizzo e-mail non valido',
              enterRole: 'Per favore seleziona un ruolo',
              enterUnit: 'Per favore seleziona un reparto'
            }
          }
        },
        lastActivity: 'Ultima azione',
        accessLevels: {
          notInvited: 'Invito non inviato',
          invited: 'Invito inviato',
          loggedIn: 'Accesso',
          noAccess: 'Nessun accesso',
          buttons: {
            setLevel: 'Imposta il livello',
            resetLevel: 'Riavvia il livello'
          }
        }
      },
      roles: {
        title: 'Ruoli e diritti di accesso',
        item: {
          buttons: {
            setting: 'Impostazioni',
            delete: 'Elimina',
            newRole: 'Nuovo ruolo',
            addRole: 'Aggiungi ruolo'
          },
          confirmDelete: 'Eliminare il ruolo?',
          header: 'Nome del ruolo'
        },
        modal: {
          title: 'Impostazione dei diritti di accesso',
          buttons: {
            update: 'Aggiornare',
            save: 'Salvare',
            cancel: 'Annulla'
          },
          messages: {
            roleSuccessfullyUpdated: 'Ruolo aggiornato con successo',
            updateRoleFailed: 'Impossibile aggiornare il ruolo',
            saveRoleFailed: 'Impossibile salvare il ruolo',
            roleSuccessfullySaved: 'Ruolo creato'
          },
          form: {
            nameCategory: 'Nome del ruolo',
            generalCategory: 'Generale',
            profileCategory: 'Area personale',
            analyticsCategory: 'Analisi, valutazione e feedback',
            tasksCategory: 'Compiti',
            libraryCategory: 'Libreria delle chiamate',
            administrateCategory: 'Amministrazione'
          }
        }
      },
      levels: {
        title: 'Livelli dei dipendenti',
        item: {
          buttons: { setting: 'Regola i livelli' },
          header: 'Nome del ruolo e livelli'
        },
        modal: {
          title: 'Impostazione dei livelli di ruolo',
          form: { namePlaceholder: 'Inserisci il nome del livello' },
          buttons: {
            save: 'Salvare',
            cancel: 'Annulla',
            addLevel: 'Aggiungi livello'
          },
          messages: {
            hasEmptyNames: 'I nomi dei livelli non possono essere vuoti',
            hasSameNames: 'I livelli con lo stesso nome non possono essere salvati'
          }
        }
      },
      statuses: {
        title: 'Stati di contatto',
        form: {
          namePlaceholder: 'Inserisci il nome dello stato',
          empty: 'Nessuno stato creato',
          addStatus: 'Aggiungi stato',
          save: 'Salvare'
        }
      },
      notifications: {
        title: 'Notifiche',
        table: {
          title: 'Impostazione delle notifiche per un ruolo:',
          rolePlaceholder: 'Scegli un ruolo',
          columns: {
            eventType: 'Oggetto della notifica',
            emailNotification: 'Notifica per e-mail'
          },
          buttons: { setting: 'Impostazioni' }
        },
        modal: {
          title: 'Impostazione delle notifiche:',
          isSendEmail: {
            title: 'Invia notifica via e-mail?',
            true: 'Si',
            false: 'No'
          }
        }
      },
      connections: {
        title: 'Connessioni',
        table: {
          userHasNoIntegrations: 'Utente non aggiunto alle integrazioni',
          deleteFrom: 'Elimina da',
          users: 'Utenti'
        },
        userAccounts: {
          userConnectedToIntegration: "L'utente è connesso all'integrazione",
          selectUserAccount: 'Seleziona un account',
          downloadingAccounts: 'Caricamento account',
          integrationHasNoAccounts: 'Questa integrazione non dispone di account per la connessione'
        }
      }
    }
  }
};
