import React, { useCallback, useEffect } from 'react';
import { Typography, Menu, Modal, Dropdown, Button, message } from 'antd';
import Icon from 'components/Icon';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import SCard from 'components/Standard/SCard';
import SRow from 'components/Standard/SRow';
import SCol from 'components/Standard/SCol';
import STable from 'components/Standard/STable';
import SText from 'components/Standard/SText';
import { Zap, MoreVertical } from 'react-feather';
import { Link, useHistory } from 'react-router-dom';
import { isEqual } from 'lodash';
import { workPlanTaskConfigurationsResource } from 'redux/resources/workPlanTaskConfigurations';
import { PERIOD_REPEAT, WORK_PLAN_TASK_CONFIGURATIONS_STATUSES } from 'core/utils/constants';
import moment from 'core/moment';
import UserPreview from 'components/UserPreview';
import { actions, operations } from 'redux/lists/workPlanTaskConfigurationsListReducer';
import { Helmet } from 'react-helmet';
import Filters from './Filters';

const WorkPlanTaskConfigurationsTable = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();

  const { updatePage, setSorting, onConfigurationDelete } = actions;

  const { ids, totalCount, page, sort, filters, loading } = useSelector(
    state => state.workPlanTaskConfigurationsList,
    isEqual
  );

  useEffect(() => {
    dispatch(
      operations.load({
        page,
        filters,
        sort,
        include: 'created_by,filters.fields,reviewer_bindings.reviewer,last_task,schedule'
      })
    );
  }, [page, sort, filters]);

  const workPlanTaskConfigurationsByIds = useSelector(
    state => state.workPlanTaskConfigurationsResource.byIds,
    isEqual
  );

  const workPlanTaskConfigurations = ids.map(id => workPlanTaskConfigurationsByIds[id]);

  const handleTableChange = useCallback(
    (pagination, filters, sorter) => {
      dispatch(
        updatePage({
          size: pagination.pageSize.toString(),
          number: pagination.current.toString()
        })
      );
      dispatch(setSorting(sorter));
    },
    [dispatch]
  );

  const workPlanTasksByIds = useSelector(state => state.workPlanTasksResource.byIds, isEqual);

  const workPlanTaskSchedulesByIds = useSelector(
    state => state.workPlanTaskSchedulesResource.byIds,
    isEqual
  );

  const deleteWorkPlanConfiguration = async ({ id }) => {
    try {
      const res = await dispatch(workPlanTaskConfigurationsResource.operations.deleteById({ id }));
      if (res) {
        dispatch(onConfigurationDelete({ id }));
        message.success(t('messages.success.configurationDeleted'));
      }
    } catch (error) {
      console.log(error);
      message.error(t('messages.error.failedDeleteConfiguration'));
    }
  };

  const stopRepeatingWorkPlanTaskConfiguration = async ({ id }) => {
    try {
      const res = await dispatch(
        workPlanTaskConfigurationsResource.operations.stopRepeatingWorkPlanTaskConfiguration({ id })
      );
      if (res) message.success(t('messages.success.repeatingCanceled'));
    } catch (error) {
      console.log(error);
      message.error(t('messages.error.failedStopRepeating'));
    }
  };

  const cancelWorkPlanTaskConfiguration = async ({ id }) => {
    try {
      const res = await dispatch(
        workPlanTaskConfigurationsResource.operations.cancelWorkPlanTaskConfiguration({ id })
      );
      if (res) message.success(t('messages.success.taskCompleted'));
    } catch (error) {
      console.log(error);
      message.error(t('messages.error.failedCloseTask'));
    }
  };

  const handleMenuClick = ({ key, id }) => {
    switch (key) {
      case 'edit':
        return history.push(`/work-plan/task-configurations/editor/${id}`);

      case 'stopRepeating':
        return Modal.confirm({
          destroyOnClose: true,
          closable: true,
          title: t('workPlanTasks.confirmStopRepeating'),
          cancelText: t('organizationStructure.tabs.units.confirmDelete.cancel'),
          okText: t('general.stop'),
          onOk: () => stopRepeatingWorkPlanTaskConfiguration({ id })
        });

      case 'cancel':
        return Modal.confirm({
          destroyOnClose: true,
          closable: true,
          title: t('workPlanTasks.confirmClosing'),
          cancelText: t('organizationStructure.tabs.units.confirmDelete.cancel'),
          okText: t('general.close'),
          onOk: () => cancelWorkPlanTaskConfiguration({ id })
        });

      case 'delete':
        return Modal.confirm({
          destroyOnClose: true,
          closable: true,
          title: t('workPlanTasks.confirmDelete'),
          cancelText: t('organizationStructure.tabs.units.confirmDelete.cancel'),
          okText: t('organizationStructure.tabs.units.confirmDelete.ok'),
          onOk: () => deleteWorkPlanConfiguration({ id })
        });

      default:
        console.log('Wrong case');
    }
  };

  const getMenu = ({ id }) => (
    <Menu onClick={({ key }) => handleMenuClick({ key, id })}>
      <Menu.Item key="edit">
        <span>{t('general.edit')}</span>
      </Menu.Item>
      <Menu.Item key="stopRepeating" style={{ color: 'var(--red_primary)' }}>
        <span>{t('workPlanTasks.tableColumns.stopRepeating')}</span>
      </Menu.Item>
      <Menu.Item key="cancel" style={{ color: 'var(--red_primary)' }}>
        <span>{t('workPlanTasks.tableColumns.closeTask')}</span>
      </Menu.Item>
      <Menu.Item key="delete" style={{ color: 'var(--red_primary)' }}>
        <span>{t('general.delete')}</span>
      </Menu.Item>
    </Menu>
  );

  const tableColumns = [
    {
      title: t('workPlanTasks.tableColumns.name'),
      dataIndex: 'name',
      key: 'name',

      render: (text, record) => {
        return record?.status === WORK_PLAN_TASK_CONFIGURATIONS_STATUSES.draft.value ? (
          <SRow gutter={[8, 0]}>
            <SCol>
              <Icon icon={Zap} />
            </SCol>
            <SCol className="truncated" width="calc(100% - 24px)">
              {text}
            </SCol>
          </SRow>
        ) : (
          <Link to={`/work-plan/task-configurations/${record?.id}/tasks`} type="link">
            <SRow gutter={[8, 0]}>
              <SCol>
                <Icon icon={Zap} />
              </SCol>
              <SCol className="truncated" width="calc(100% - 24px)">
                {text}
              </SCol>
            </SRow>
          </Link>
        );
      }
    },

    // {
    //   title: t('workPlanTasks.tableColumns.reviewsCount'),
    //   dataIndex: 'reviewsCount',
    //   key: 'reviewsCount',
    //   ellipsis: true,
    //   render: (text, record) => {
    //     const lastTask = workPlanTasksByIds[record?.lastTaskId];

    //     return (
    //       <ColorizedNumbers
    //         completed={lastTask?.completedAssignmentsCount}
    //         incompleted={lastTask?.incompletedAssignmentsCount}
    //         total={lastTask?.totalAssignmentsCount}
    //       />
    //     );
    //   }
    // },

    {
      title: t('workPlanTasks.tableColumns.lastTaskTimeFrom'),
      dataIndex: 'lastTaskTimeFrom',
      key: 'lastTaskTimeFrom',
      ellipsis: true,
      sorter: true,
      width: 170,
      render: (text, record) => {
        const lastTask = workPlanTasksByIds[record?.lastTaskId];
        return <SText>{moment(lastTask?.timeFrom).format('DD/MM/YYYY')}</SText>;
      }
    },

    {
      title: t('workPlanTasks.tableColumns.lastTaskTimeTo'),
      dataIndex: 'lastTaskTimeTo',
      key: 'lastTaskTimeTo',
      ellipsis: true,
      sorter: true,
      width: 170,
      render: (text, record) => {
        const lastTask = workPlanTasksByIds[record?.lastTaskId];
        return <SText>{moment(lastTask?.timeTo).format('DD/MM/YYYY')}</SText>;
      }
    },

    {
      title: t('workPlanTasks.tableColumns.creator'),
      dataIndex: 'createdById',
      key: 'createdById',
      ellipsis: true,
      render: (text, record) => {
        return <UserPreview userId={text} showAvatar disabled />;
      }
    },

    {
      title: t('workPlanTasks.tableColumns.schedule'),
      dataIndex: 'schedule',
      key: 'schedule',
      ellipsis: true,
      width: 150,
      render: (text, record) => {
        return (
          <SText>
            {t(PERIOD_REPEAT[workPlanTaskSchedulesByIds[record?.scheduleId]?.repeatEvery]?.title)}
          </SText>
        );
      }
    },

    {
      title: t('workPlanTasks.tableColumns.repeatedCount'),
      dataIndex: 'repeatedCount',
      key: 'repeatedCount',
      width: 120,
      align: 'center',
      render: text => <SText>{text}</SText>
    },

    {
      title: t('general.status'),
      dataIndex: 'status',
      key: 'status',
      ellipsis: true,
      sorter: true,
      width: 150,
      render: text => (
        <SText color={WORK_PLAN_TASK_CONFIGURATIONS_STATUSES[text]?.color}>
          {t(WORK_PLAN_TASK_CONFIGURATIONS_STATUSES[text]?.title)}
        </SText>
      )
    },

    {
      title: '',
      dataIndex: 'id',
      key: 'menu',
      width: 50,
      ellipsis: true,
      fixed: 'right',
      render: text => {
        return (
          <Dropdown overlay={getMenu({ id: text })} trigger={['click']} placement="bottomRight">
            <Button
              type="link"
              size="small"
              icon={
                <Icon
                  icon={MoreVertical}
                  color="var(--blue_2)"
                  style={{ marginTop: '-0.9px' }}
                  size="24px"
                />
              }
              strokeWidth="1"
            />
          </Dropdown>
        );
      }
    }
  ];

  return (
    <SRow>
      <Helmet>
        <title>{t('pagesMeta.workPlanTaskConfigurationsPage.title')}</title>
      </Helmet>
      <SCol span={24}>
        <SRow>
          <SCol span={24}>
            <Filters />
          </SCol>
          <SCol span={24}>
            <SRow type="flex" margin="16px">
              <SCol span={24}>
                <SCard bordered shadowed bodyPadding="14px 20px">
                  <STable
                    dataSource={workPlanTaskConfigurations}
                    columns={tableColumns}
                    loading={loading}
                    border
                    pagination={{
                      pageSize: parseInt(page.size),
                      current: parseInt(page.number),
                      total: totalCount,
                      showSizeChanger: true,
                      pageSizeOptions: ['10', '25', '50'],
                      size: 'small'
                    }}
                    onChange={handleTableChange}
                    fixSorterColumns
                    scroll={{ y: 'calc(100vh - 300px)', x: 'max-content' }}
                    size="small"
                  />
                </SCard>
              </SCol>
            </SRow>
          </SCol>
        </SRow>
      </SCol>
    </SRow>
  );
};

export default WorkPlanTaskConfigurationsTable;
