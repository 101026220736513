import { Menu } from 'antd';
import withConditionalRender from 'components/WithConditionalRender/withConditionalRender';
import { PERMISSIONS } from 'core/utils/constants';
import styled from 'styled-components';

const BasicMenuItem = styled(Menu.Item)`
  && {
    display: flex !important;
    align-items: center;
    height: 100%;

    &&.ant-menu-item-active {
      background-color: transparent;
    }

    &&.ant-menu-item-selected {
      background-color: var(--primary);
    }

    span {
      /* transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); */
      display: block;
    }
  }
`;

const SubMenuItem = styled(Menu.SubMenu)`
  &&.ant-menu-submenu-open,
  &&.ant-menu-submenu-active,
  &&.ant-menu-submenu:hover {
    color: white;

    .ant-menu-submenu-title {
      color: white;
    }
  }

  &&.ant-menu-submenu {
    height: 100%;
    /* transition: color 0.3s; */
    .ant-menu-submenu-title {
      height: 100%;
      display: flex;
      align-items: center;
      transition: color 0.3s;
    }
  }
`;

const MainMenuItem = styled(BasicMenuItem)`
  @media (max-width: 1320px) {
    && {
      i,
      .anticon {
        margin: 0 auto;
      }

      span:not(.anticon) {
        opacity: 0;
        display: none;
        height: 0;
        overflow: hidden;
      }

      &&:hover {
        i,
        .anticon {
          margin-right: 10px;
        }

        span:not(.anticon) {
          opacity: 1;
          height: auto;
          display: block;
        }
      }
    }
  }
`;

export const RightSideItemsDivider = styled.li`
  margin-left: auto;
`;

export const getBasicMenuItemForPermissions = accessPermissions =>
  withConditionalRender({ allowedPermissions: accessPermissions }, BasicMenuItem);

export const getMainMenuItemForPermissions = accessPermissions =>
  withConditionalRender({ allowedPermissions: accessPermissions }, MainMenuItem);

export const getSubMenuForPermissions = accessPermissions =>
  withConditionalRender({ allowedPermissions: accessPermissions }, SubMenuItem);

export const DashboardMenuItem = getMainMenuItemForPermissions([PERMISSIONS.CAN_SEE_DASHBOARDS]);

export const ClientInterationsMenuItem = getMainMenuItemForPermissions([
  PERMISSIONS.CAN_SEE_UNIT_CLIENT_INTERACTIONS
]);

// export const TasksMenuItem = getMainMenuItemForPermissions([
//   PERMISSIONS.CAN_SEE_AND_UPDATE_ASSIGNED_TASKS
// ]);

export const LibraryMenuItem = getMainMenuItemForPermissions([PERMISSIONS.CAN_ACCESS_LIBRARY]);

export const AddReviewMenuItem = getMainMenuItemForPermissions([PERMISSIONS.CAN_MAKE_REVIEW]);

export const TeamMenuItem = getMainMenuItemForPermissions([PERMISSIONS.CAN_ACCESS_TEAM_PAGE]);

export const AppealsMenuItem = getMainMenuItemForPermissions([
  PERMISSIONS.CAN_SEE_UNIT_APPELLATIONS
]);

export const SettingsSubMenu = getSubMenuForPermissions([
  PERMISSIONS.CAN_MANAGE_TASK_DEFINITIONS,
  PERMISSIONS.CAN_MANAGE_CHECKLIST_DEFINITIONS,
  PERMISSIONS.CAN_MANAGE_CALIBRATION_SESSIONS
]);

export const ChecklistMenuItem = getBasicMenuItemForPermissions([
  PERMISSIONS.CAN_MANAGE_CHECKLIST_DEFINITIONS
]);
export const TaskDefinitionsMenuItem = getBasicMenuItemForPermissions([
  PERMISSIONS.CAN_MANAGE_TASK_DEFINITIONS
]);
export const UnitsMenuItem = getBasicMenuItemForPermissions([
  PERMISSIONS.CAN_MANAGE_ORGANIZATION_STRUCTURE
]);
export const OrganizationMenuItem = getBasicMenuItemForPermissions([
  PERMISSIONS.CAN_MANAGE_ORGANIZATION_SETTINGS
]);
export const IntegrationsMenuItem = getBasicMenuItemForPermissions([
  PERMISSIONS.CAN_MANAGE_INTEGRATIONS
]);
export const HelpMenuItem = getBasicMenuItemForPermissions([]);

export const CalibrationsMenuItem = getBasicMenuItemForPermissions([
  PERMISSIONS.CAN_MANAGE_CALIBRATION_SESSIONS
]);

export const UserAnalyticsMenuItem = getBasicMenuItemForPermissions([
  PERMISSIONS.CAN_ACCESS_USER_ANALYTICS_PAGE_CHARTS_TAB,
  PERMISSIONS.CAN_PERFORM_CLIENT_INTERACTION
]);

export const WorkPlanTaskConfigurationsMenuItem = getBasicMenuItemForPermissions([
  PERMISSIONS.CAN_MANAGE_WORK_PLAN_TASK_CONFIGURATIONS
]);

export const UserSettingsMenuItem = getBasicMenuItemForPermissions([]);

export const LogoutMenuItem = getBasicMenuItemForPermissions([]);
