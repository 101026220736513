import SCol from 'components/Standard/SCol';
import SButton from 'components/Standard/SButton';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setAppealId,
  setQuestionIdsToAppealObjections,
  setQuestionIdsToAppealComments,
  setReviewState
} from 'redux/ui/checklistManager/reducer';
import { OutlinedButton } from 'components/Buttons';
import { every, find, isEmpty, isEqual, isNil, pick, pickBy, reduce } from 'lodash';
import { getCurrentUser, getCurrentUserPermissions } from 'redux/selectors/users';
import { PERMISSIONS } from 'core/utils/constants';
import { appellationsResource } from 'redux/resources/appellations';
import { getAnswersByIds, getChecklistsByIds } from 'redux/selectors/checklists';
import { updateResource } from 'core/redux/resources/updateResource';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';

const loadAppeal = ({ reviewId }) => async (dispatch, getState) => {
  try {
    const response = await dispatch(
      appellationsResource.operations.load({
        filters: {
          reviewId
        },
        include: 'comments.uploaded-files,comments.author,objections'
      })
    );

    if (!isEmpty(response?.resources)) {
      const appeal = Object.values(response?.resources)[0];
      dispatch(setAppealId(appeal.id));
      const state = getState();
      const comments = reduce(
        state.appellationCommentsResource.byIds,
        (acc, comment, commentId) => {
          if (comment.appellationId !== appeal?.id) return acc;
          const key = comment.questionId;
          return {
            ...acc,
            [key]: {
              ...comment,
              uploadedFiles: comment.uploadedFilesIds.map(
                id => state.uploadedFilesResource.byIds[id]
              ),
              saved: true
            }
          };
        },
        {}
      );
      const objections = reduce(
        appeal.objectionsIds,
        (acc, id) => {
          const objection = state.appellationObjectionsResource.byIds[id];

          if (objection) {
            return { ...acc, [objection.questionId]: objection };
          }

          return acc;
        },
        {}
      );
      dispatch(setQuestionIdsToAppealComments(comments));
      dispatch(setQuestionIdsToAppealObjections(objections));
      return appeal;
    }
  } catch (error) {
    console.log(error);
  }
};

export const AppealHead = ({ reviewId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const appealId = useSelector(state => state.uiChecklistManager.appealId);
  const appeal = useSelector(state => state.appellationsResource.byIds[appealId], isEqual);
  const questionIdToObjection = useSelector(
    state => state.uiChecklistManager.questionIdToAppealObjection,
    isEqual
  );
  const review = useSelector(state => state.reviewsResource.byIds[reviewId], isEqual);
  const checklist = useSelector(state => getChecklistsByIds(state)[review?.checklistId], isEqual);
  const checklistAnswers = useSelector(
    state => pickBy(getAnswersByIds(state), { checklistId: checklist?.id }),
    isEqual
  );
  const currentUser = useSelector(getCurrentUser, isEqual);
  const currentUserPermissions = useSelector(getCurrentUserPermissions, isEqual);
  const questionIdToAppealComment = useSelector(
    state => state.uiChecklistManager.questionIdToAppealComment,
    isEqual
  );
  const questionIdToAnswer = useSelector(
    state => state.uiChecklistManager.questionIdToAnswerValue,
    isEqual
  );

  const loadReviewAppeal = async () => {
    if (reviewId) {
      dispatch(loadAppeal({ reviewId }));
    }
  };

  useEffect(() => {
    loadReviewAppeal();
  }, [reviewId]);

  useEffect(() => {
    if (appeal?.questionObjectionsProcessed) {
      dispatch(setReviewState({ checklist }));
    }
  }, [appeal?.questionObjectionsProcessed]);

  const onNewAppeal = useCallback(() => {
    dispatch(setAppealId('new'));
  }, [dispatch]);

  const onSaveAppeal = async () => {
    try {
      const appeal = await dispatch(
        appellationsResource.operations.create({
          reviewId,
          commentsAttributes: reduce(
            questionIdToAppealComment,
            (acc, comment, questionId) => [
              ...acc,
              {
                text: comment.text,
                uploadedFilesIds: comment.uploadedFilesIds,
                answerId: find(checklistAnswers, { questionId })?.id
              }
            ],
            []
          )
        })
      );
      dispatch(setAppealId(appeal.id));
      message.success(t('messages.success.appealSended'));
    } catch (error) {
      console.log(error);
    }
  };

  const onCancelAppeal = useCallback(async () => {
    try {
      await dispatch(appellationsResource.operations.deleteById({ id: appealId }));
      dispatch(setAppealId(null));
      dispatch(setQuestionIdsToAppealObjections({}));
      dispatch(setQuestionIdsToAppealComments({}));
      message.success(t('messages.success.appealCanceled'));
    } catch (error) {
      console.log(error);
    }
  }, [dispatch, appealId]);

  const onProccessObjections = async () => {
    try {
      await dispatch(
        appellationsResource.operations.processObjections({
          id: appealId,
          objectionsAttributes: reduce(
            questionIdToObjection,
            (acc, objection, questionId) => [
              ...acc,
              {
                ...pick(objection, ['id', 'accepted']),
                answerAfter: questionIdToAnswer[questionId]
              }
            ],
            []
          )
        })
      );
      message.success(t('messages.success.processingCompleted'));
    } catch (error) {
      console.log(error);
    }
  };

  const onEditObjections = async () => {
    try {
      updateResource({
        resource: { ...appeal, questionObjectionsProcessed: false },
        dispatch,
        type: 'appellations'
      });
    } catch (error) {
      console.log(error);
    }
  };

  const showAppealHead =
    review &&
    (currentUserPermissions.includes(PERMISSIONS.CAN_SEE_UNIT_APPELLATIONS) ||
      currentUserPermissions.includes(PERMISSIONS.CAN_CREATE_APPELLATIONS));

  const canSendAppeal =
    !isEmpty(questionIdToObjection) &&
    !isEmpty(questionIdToAppealComment) &&
    every(questionIdToAppealComment, comment => comment?.saved);

  if (!showAppealHead) return null;

  const canProccessObjections =
    !isEmpty(questionIdToObjection) &&
    every(questionIdToObjection, objection => !isNil(objection?.accepted));

  if (appealId === 'new') {
    // * save appeal
    return (
      <SCol span={24}>
        <OutlinedButton block showLoad onClick={onSaveAppeal} disabled={!canSendAppeal}>
          {t('components.appealHead.sendAppeal')}
        </OutlinedButton>
      </SCol>
    );
  }

  if (appealId && appeal?.appellantId === currentUser?.id) {
    // * cancel appeal
    return (
      <SCol span={24}>
        <SButton type="danger" showLoad block onClick={onCancelAppeal}>
          {t('components.appealHead.cancelAppeal')}
        </SButton>
      </SCol>
    );
  }

  // * appeal processing
  if (
    appealId &&
    appeal?.assignedToId === currentUser?.id &&
    !appeal?.questionObjectionsProcessed
  ) {
    // * review appeal
    return (
      <SCol span={24}>
        <OutlinedButton
          block
          showLoad
          onClick={onProccessObjections}
          disabled={!canProccessObjections}
        >
          {t('components.appealHead.closeAppeal')}
        </OutlinedButton>
      </SCol>
    );
  }

  if (appealId && appeal?.assignedToId === currentUser?.id && appeal?.questionObjectionsProcessed) {
    // * review appeal
    return (
      <SCol span={24}>
        <SButton block type="primary" onClick={onEditObjections}>
          {t('components.appealHead.editAppeal')}
        </SButton>
      </SCol>
    );
  }

  //   * create appeal
  if (
    currentUserPermissions.includes(PERMISSIONS.CAN_CREATE_APPELLATIONS) &&
    !appealId &&
    review.reviewerId !== currentUser.id
  )
    return (
      <SCol span={24}>
        <SButton block type="primary" onClick={onNewAppeal}>
          {t('components.appealHead.createAppeal')}
        </SButton>
      </SCol>
    );

  return null;
};
