export default {
  errorPages: {
    forbidden: {
      title: 'Доступ к странице запрещён. \n Обратитесь к администратору портала.'
    },
    internalServerError: {
      title: 'Что-то пошло не так. Попробуйте перезагрузить страницу.'
    },
    loadingPage: {
      title: 'Загрузка данных...'
    },
    mobileBlocked: {
      title: 'Упс...',
      description: 'Мобильная версия ещё в разработке.'
    },
    notFound: {
      title: 'Такого адреса не существует.'
    },
    unauthorized: {
      title: 'Для продолжения работы необходимо войти'
    },
    buttons: {
      login: 'Войти',
      goBack: 'Назад',
      goToHomePage: 'На главную'
    }
  }
};
