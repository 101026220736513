import BitrixService from 'core/services/bitrix';
import { BITRIX_INTEGRATION_PLACEMENTS } from 'core/utils/constants';
import { operations as reviewsOperations } from 'redux/lists/bitrixReviewsListReducer';
import { operations as clientInteractionsOperations } from 'redux/lists/bitrixClientInteractionsList';
import { interactionsLoadStarted } from './reducer';

export const loadInteractions = () => async dispatch => {
  dispatch(interactionsLoadStarted());
  const { placement, options } = BitrixService.getPlacementInfo();
  const id = options.ID;

  if (placement === BITRIX_INTEGRATION_PLACEMENTS.CRM_LEAD_DETAIL_TAB) {
    return dispatch(
      clientInteractionsOperations.load({
        filters: { clientIntegrationUids: `lead-${id}` },
        include: 'operator'
      })
    );
  }

  if (placement === BITRIX_INTEGRATION_PLACEMENTS.CRM_DEAL_DETAIL_TAB) {
    BitrixService.callApiMethod({
      methodName: 'crm.deal.contact.items.get',
      params: { id },
      callback: async res => {
        await dispatch(
          clientInteractionsOperations.load({
            include: 'client,operator',
            filters: { clientIntegrationUids: res.map(({ CONTACT_ID }) => `contact-${CONTACT_ID}`) }
          })
        );
      }
    });
  }
};

export const loadReviews = ({ ...props }) => async dispatch => {
  dispatch(interactionsLoadStarted());
  const { placement, options } = BitrixService.getPlacementInfo();
  const id = options.ID;

  if (placement === BITRIX_INTEGRATION_PLACEMENTS.CRM_LEAD_DETAIL_TAB) {
    return dispatch(
      reviewsOperations.load({
        filters: { clientIntegrationUids: `lead-${id}` },
        ...props
      })
    );
  }

  if (placement === BITRIX_INTEGRATION_PLACEMENTS.CRM_DEAL_DETAIL_TAB) {
    BitrixService.callApiMethod({
      methodName: 'crm.deal.contact.items.get',
      params: { id },
      callback: async res => {
        await dispatch(
          reviewsOperations.load({
            filters: {
              clientIntegrationUids: res.map(({ CONTACT_ID }) => `contact-${CONTACT_ID}`)
            },
            ...props
          })
        );
      }
    });
  }
};
