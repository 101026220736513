import { StopOutlined } from '@ant-design/icons';
import { Button, Radio, Select, Tooltip } from 'antd';
import { SCALE_TYPES } from 'core/utils/constants';
import { get, isEmpty, isNil } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const CHANGE_VIEW_VALUES = {
  CHARACTERS: 32,
  RATING_VALUES_LENGTH: 5
};

const RadioGroup = styled(Radio.Group)`
  && {
    display: flex;
    flex-direction: row;
  }
  && label.ant-radio-button-wrapper {
    padding: 0 15px;
    display: flex;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    justify-content: center;
    align-items: center;
    text-align: center;

    .ant-radio-button-input {
      display: none;
    }

    && span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
`;

const RateValues = ({
  disabled,
  size = 'medium',
  ratingValues = [],
  ratingValuesViews = [],
  scaleType,
  value,
  valuesDisplayType = 'numbers',
  onChange,
  allowMultiple = false,
  showNull = true
}) => {
  const { t } = useTranslation();
  const onClick = ({ target }) => onChange(target.value);

  const onClickMultiple = rate => {
    const newValue = isNil(rate) ? null : parseFloat(rate);

    onChange(
      value.includes(newValue)
        ? value.filter(current => current !== newValue)
        : [...value, isNil(newValue) ? null : newValue]
    );
  };

  const valuesViews = get(
    SCALE_TYPES,
    [scaleType, valuesDisplayType],
    isEmpty(ratingValuesViews) ? ratingValues : ratingValuesViews
  );

  const getValueView = ({ rate }) => {
    if (scaleType === SCALE_TYPES.custom_binary.type) {
      return valuesDisplayType === 'numbers' ? rate : valuesViews[ratingValues.indexOf(rate)];
    }
    const fullRatingValues = get(SCALE_TYPES, [scaleType, 'numbers'], ratingValues);

    // return truncateString(valuesViews[fullRatingValues.indexOf(rate)] || '', 5);
    return valuesViews[fullRatingValues.indexOf(rate)] || rate;
  };

  if (
    ratingValues.length > CHANGE_VIEW_VALUES.RATING_VALUES_LENGTH &&
    !allowMultiple &&
    scaleType === SCALE_TYPES.custom_with_views.type
  ) {
    return (
      <Select
        onChange={onChange}
        style={{ width: '100%', minWidth: '100px' }}
        value={value}
        disabled={disabled}
        placeholder={t('components.rateValues.text.placeholder')}
      >
        {(showNull ? [null, ...ratingValues] : ratingValues).map((rate, i) => (
          <Select.Option value={rate} key={rate}>
            {getValueView({ rate }) || 'N/A'}
          </Select.Option>
        ))}
      </Select>
    );
  }

  if (allowMultiple) {
    return (
      <Button.Group size={size} buttonStyle="solid" style={{ width: '100%' }}>
        {showNull && (
          <Button
            key={null}
            type={value.includes('null') ? 'primary' : 'default'}
            value={null}
            onClick={() => onClickMultiple(null)}
          >
            <StopOutlined />
          </Button>
        )}

        {ratingValues.map(rate => (
          <Button
            key={rate}
            value={rate}
            type={value.includes(rate) ? 'primary' : 'default'}
            onClick={() => onClickMultiple(rate)}
            style={{ width: `${100 / ratingValuesViews.length}%` }}
          >
            <Tooltip title={t(getValueView({ rate }))}>
              <span
                style={{
                  width: '100%',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}
              >
                {t(getValueView({ rate }))}
              </span>
            </Tooltip>
          </Button>
        ))}
      </Button.Group>
    );
  }

  return (
    <RadioGroup
      disabled={disabled}
      size={size}
      onChange={onClick}
      value={value}
      buttonStyle="solid"
    >
      {showNull && (
        <Radio.Button key="disabled" value={null}>
          <StopOutlined />
        </Radio.Button>
      )}

      {ratingValues.map((rate, index) => (
        <Radio.Button key={index} value={rate}>
          {t(getValueView({ rate, index }))}
        </Radio.Button>
      ))}
    </RadioGroup>
  );
};

export default React.memo(RateValues);
