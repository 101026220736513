import React from 'react';
import { Col, Tooltip, Spin, Row } from 'antd';
import { useSelector } from 'react-redux';
import {
  widgetPeriodSetter,
  widgetNameSetter,
  widgetInfoTextSetter,
  widgetValueTypeSetter,
  getDateSelectorType
} from '../utils';
import SettingsModal from '../Components/SettingsModal';
import { SmallWidgetCard, SettingsButtonCol, WidgetNameCol, Value, TimePeriod } from '../styled';

const TimeWidget = ({ widgetData, loadingWidgetId }) => {
  const { id, type, filters, name } = widgetData;

  const widgetValue = useSelector(state => state.dashboardAnalytics.byIds[id]);
  const dateSelectorType = getDateSelectorType({ widgetType: type });

  return (
    <Col span={4}>
      <SmallWidgetCard>
        <Row type="flex">
          <WidgetNameCol span={20}>
            {widgetInfoTextSetter({ type })}
            <Tooltip title={widgetNameSetter({ name, type })}>
              <span>{widgetNameSetter({ name, type })}</span>
            </Tooltip>
            <TimePeriod type="secondary">
              {widgetPeriodSetter(filters, dateSelectorType)}
            </TimePeriod>
          </WidgetNameCol>
          <SettingsButtonCol span={4}>
            <SettingsModal widgetData={widgetData} />
          </SettingsButtonCol>
        </Row>
        <Spin spinning={loadingWidgetId === id}>
          <Row>
            <Value color="var(--blue_6)" secondsCount={widgetValue}>
              {widgetValue ? widgetValueTypeSetter[type](widgetValue) : '00:00'}
            </Value>
          </Row>
        </Spin>
      </SmallWidgetCard>
    </Col>
  );
};

export default TimeWidget;
