import React from 'react';
import { Tooltip, Spin, Row } from 'antd';
import { Info } from 'components/Info';
import {
  SmallWidgetCard,
  SettingsButtonCol,
  WidgetNameCol,
  TimePeriod,
  SettingsButton
} from '../styled';

const SmallWidget = ({
  loading,
  children,
  info,
  showArrow = false,
  onArrowClick,
  name,
  description,
  headerColor = 'white'
}) => {
  return (
    <SmallWidgetCard
      headerColor={headerColor}
      title={
        <Row type="flex" align="middle">
          <WidgetNameCol span={20}>
            <Tooltip title={name}>
              <span>{name}</span>
            </Tooltip>
            <TimePeriod type="secondary">{description}</TimePeriod>
          </WidgetNameCol>
          <SettingsButtonCol span={4}>
            {showArrow && <SettingsButton type="down" onClick={onArrowClick} />}
            <Info {...info} />
          </SettingsButtonCol>
        </Row>
      }
    >
      <Spin spinning={loading}>{children}</Spin>
    </SmallWidgetCard>
  );
};

export default SmallWidget;
