import api from 'core/api';
import { loadDashboards } from 'redux/ui/dashboard/operations';
import { createReduxResource } from 'core/redux/resources';

export const dashboardsResource = createReduxResource({
  name: 'dashboards',
  customOperations: {
    copyById: {
      callEndpoint: async requestOptions => {
        const { id } = requestOptions;
        return api.copyDashboard({ id }, {});
      },
      onSuccess: ({ dispatch }) => {
        dispatch(loadDashboards());
      }
    }
  }
});
