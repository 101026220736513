import { Phone } from 'react-feather';
import { Col, Row } from 'antd';
import { CALL_DIRECTION_LITERALS } from 'core/utils/constants';
import { beatifyFloat } from 'core/utils/convertFloat';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Icon from 'components/Icon';

const ReviewIcon = styled(Phone)`
  padding: 0 4px;

  &:hover {
    color: var(--blue_6);
  }
`;

const ReviewPreview = ({ review, onClick }) => {
  const { t } = useTranslation();

  return (
    <Link to={`/reviews/${review.id}`} onClick={onClick}>
      <Container>
        <Row type="flex" justify="space-between" align="middle" style={{ height: '100%' }}>
          <Col>
            <Info>
              <Icon icon={ReviewIcon} size="20px" />
              <Data>
                <DataLabel>{t('userAnalyticsPage.reviewPreview.date')}</DataLabel>
                {moment(review.createdAt, moment.ISO_8601).format('DD/MM/YYYY HH:MM')}
              </Data>
              <Data>
                <DataLabel>{t('userAnalyticsPage.reviewPreview.type')}</DataLabel>
                {t(CALL_DIRECTION_LITERALS[review.direction])}
              </Data>
            </Info>
          </Col>
          <Col>
            <Data>
              <DataLabel>{t('userAnalyticsPage.reviewPreview.value')}</DataLabel>
              {beatifyFloat(review.score)}
            </Data>
          </Col>
        </Row>
      </Container>
    </Link>
  );
};

const Container = styled.div`
  background: var(--_gray_4);
  height: 31px;
  font-size: 12px;
  color: var(--text);
  border-radius: 4px;

  &:hover {
    cursor: pointer;
  }
`;

const Info = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Data = styled.p`
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  padding: 0 4px;
`;

const DataLabel = styled.span`
  font-weight: 600;
  padding: 0 2px;
`;

export default ReviewPreview;
