import { get } from 'lodash';
import { createSelector } from '@reduxjs/toolkit';

export const getTasksByIds = state => get(state, 'tasksResource.byIds', {});
export const getTasksIdsByReviewId = (state, reviewId) =>
  get(state, ['reviewsResource', 'byIds', reviewId, 'tasksIds'], []);

export const getReviewTaskDefinitionsIds = createSelector(
  getTasksByIds,
  getTasksIdsByReviewId,
  (tasksByIds, tasksIds) =>
    tasksIds.map(taskId => get(tasksByIds, [taskId, 'taskDefinitionId'], undefined))
);
