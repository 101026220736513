import { createAction, createReducer } from '@reduxjs/toolkit';

const defaultState = {
  viewType: 'employees',
  filters: {
    checklistDefinitionsIds: 'all',
    unitsIds: [],
    clientInteractionTimeFrom: undefined,
    clientInteractionTimeTo: undefined,
    clientInteractionSymbolicTimeRange: 'this_month'
  }
};

export const updateFilters = createAction('uiTeamAnalyticsPage/updateFilters');
export const changeViewType = createAction('uiTeamAnalyticsPage/changeViewType');

export const teamAnalyticsPageReducer = createReducer(defaultState, {
  [updateFilters]: (state, { payload }) => {
    return { ...state, filters: { ...state.filters, ...payload } };
  },
  [changeViewType]: (state, { payload }) => {
    return { ...state, viewType: payload };
  }
});

export default teamAnalyticsPageReducer;
