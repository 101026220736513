import { Col } from 'antd';
import withConditionalRender from 'components/WithConditionalRender/withConditionalRender';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { checklistDefinitionsResource } from 'redux/resources/checklistDefinitions';
import { setTaskDefinitionToEdit } from 'redux/ui/taskDefinition/operations';
import { resetUiTaskDefinition } from 'redux/ui/taskDefinition/reducer';
import ChecklistItems from './Components/ChecklistItems';
import TaskDefinitionsOptions from './Components/TaskDefinitionsOptions';
import { StyledDivider, StyledEditTaskDefinitionCard, StyledRow } from './styled';

const EditTaskDefinitionCardPage = () => {
  const dispatch = useDispatch();
  const { id: taskDefinitionId } = useParams();

  const labelType = useSelector(state => state.uiTaskDefinition.labelType);

  const loadingTaskDefinitions = useSelector(
    state => state.taskDefinitionsResource.loadByIdStarted
  );
  const loadingChecklistDefinitions = useSelector(
    state => state.checklistDefinitionsResource.loading
  );

  const loading = loadingChecklistDefinitions || loadingTaskDefinitions;

  const loadChecklistDefinitions = checklistDefinitionsResource.operations.load;

  useEffect(() => {
    dispatch(resetUiTaskDefinition());
    dispatch(
      loadChecklistDefinitions({
        include: 'question_groups.question_to_group_bindings.question',
        pagination: 'false'
      })
    );
    if (taskDefinitionId) {
      dispatch(setTaskDefinitionToEdit({ id: taskDefinitionId }));
    }
  }, [taskDefinitionId]);

  const { t } = useTranslation();

  const ChecklistSettingsBlock = withConditionalRender({ hide: labelType === 'manual' }, 'div');

  return (
    <StyledEditTaskDefinitionCard loading={loading}>
      <Helmet>
        <title>
          {taskDefinitionId
            ? `${t('pagesMeta.editTaskDefinitionCardPage.titles.updateTag')}`
            : `${t('pagesMeta.editTaskDefinitionCardPage.titles.createTag')}`}
        </title>
      </Helmet>
      <StyledRow type="flex" align="middle" gutter={[8]}>
        <Col span={24}>
          <TaskDefinitionsOptions />
        </Col>
      </StyledRow>
      <ChecklistSettingsBlock>
        <span>
          {t('triggersSettingsPage.editTaskDefinition.messages.addSettingsToAutomaticTag')}
        </span>
        <StyledDivider />
        <ChecklistItems />
      </ChecklistSettingsBlock>
    </StyledEditTaskDefinitionCard>
  );
};

export default EditTaskDefinitionCardPage;
