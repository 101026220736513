import React, { useCallback, useMemo } from 'react';
import { Typography, Select, Checkbox, Empty } from 'antd';
import SCard from 'components/Standard/SCard';
import { useTranslation } from 'react-i18next';
import { isEqual } from 'lodash';
import { notificationSettingsResource } from 'redux/resources/notificationSettings';
import { NOTIFICATIONS_TYPES_LITERALS } from 'core/utils/constants';
import { setActiveRoleId } from 'redux/ui/notificationSettings/reducer';
import { useDispatch, useSelector } from 'react-redux';
import SSelect from 'components/Standard/SSelect';
import SCol from 'components/Standard/SCol';
import SRow from 'components/Standard/SRow';

const { Title, Text } = Typography;
const { Option } = Select;

const updateNotification = notificationSettingsResource.operations.updateById;

const Notifications = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const activeRoleId = useSelector(state => state.uiNotificationSettings.activeRoleId);

  const { byIds: rolesByIds = {}, loading } = useSelector(state => state.rolesResource, isEqual);

  const notifications = useSelector(
    state => Object.values(state.notificationSettingsResource.byIds),
    isEqual
  );

  const allowedNotifications = useMemo(
    () =>
      notifications.filter(
        item => item.eventType !== 'task_created' && item?.roleId === activeRoleId
      ),
    [notifications, activeRoleId]
  );

  const onChange = useCallback(value => dispatch(setActiveRoleId({ id: value })), [dispatch]);

  return (
    <SCol sm={24} lg={24} xl={12}>
      <SCard bordered loading={loading}>
        <SRow gutter={[0, 18]}>
          <SCol span={24}>
            <Title level={5}>{t('organizationStructure.tabs.notifications.title')}</Title>
          </SCol>
          <SCol span={24}>
            <SRow type="flex" align="middle" justify="space-between">
              <SCol span={12}>
                <Text>{t('organizationStructure.tabs.notifications.table.title')}</Text>
              </SCol>
              <SCol span={12}>
                <SSelect
                  width="100%"
                  placeholder={t('organizationStructure.tabs.notifications.table.rolePlaceholder')}
                  value={activeRoleId}
                  onChange={onChange}
                >
                  {Object.values(rolesByIds).map(role => (
                    <Option key={role.id} value={role.id}>
                      {role.name}
                    </Option>
                  ))}
                </SSelect>
              </SCol>
            </SRow>
          </SCol>
          <SCol span={24}>
            <Text strong>{t('general.email')}</Text>
          </SCol>
          {allowedNotifications.length > 0 ? (
            allowedNotifications.map(item => {
              return (
                <SCol span={24}>
                  <Checkbox
                    checked={item?.emailNotification}
                    onChange={() => {
                      try {
                        dispatch(
                          updateNotification({
                            ...item,
                            emailNotification: !item.emailNotification
                          })
                        );
                      } catch (error) {
                        console.log(error);
                      }
                    }}
                  >
                    {t(NOTIFICATIONS_TYPES_LITERALS[item?.eventType])}
                  </Checkbox>
                </SCol>
              );
            })
          ) : (
            <SCol span={24}>
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            </SCol>
          )}
        </SRow>
      </SCard>
    </SCol>
  );
};

export default Notifications;
