import Icon from 'components/Icon';
import SButton from 'components/Standard/SButton';
import SCard from 'components/Standard/SCard';
import SCol from 'components/Standard/SCol';
import SRow from 'components/Standard/SRow';
import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import { Plus } from 'react-feather';
import { useTranslation } from 'react-i18next';
import AddUsersModal from '../../../components/AddUsersModal';
import ReviewsWithDistribution from './ReviewsWithDistribution';

const Reviewers = ({
  reviewersIds = [],
  clientInteractionsTypesConfigs = {},
  onChange,
  isFiltersValid,
  distribution
}) => {
  const { t } = useTranslation();
  const [usersIds, setUsersIds] = useState(reviewersIds);
  const [showAddModal, setShowAddModal] = useState(false);
  return (
    <SRow gutter={[0, 12]} style={{ marginBottom: '-6px' }}>
      {/* <SCol span={24}>
        <Title level={4}>Проверяющие</Title>
      </SCol> */}
      <SCol span={24}>
        <SCard bordered shadowed width="100%">
          {!isEmpty(usersIds) && (
            <SCol span={24} style={{ paddingBottom: '24px' }}>
              <ReviewsWithDistribution
                reviewersIds={usersIds}
                clientInteractionsTypesConfigs={clientInteractionsTypesConfigs}
                onChange={onChange}
                distribution={distribution}
              />
            </SCol>
          )}

          <SButton
            icon={<Icon icon={Plus} />}
            type="link"
            padding="0"
            disabled={!isFiltersValid}
            onClick={() => setShowAddModal(true)}
          >
            {t('workPlanTaskConfigurationEditor.distribution.addReviewer')}
          </SButton>
        </SCard>
      </SCol>
      <AddUsersModal
        selectedUsersIds={usersIds}
        onCancel={() => setShowAddModal(false)}
        visible={showAddModal}
        onAdd={usersIds => {
          setUsersIds(usersIds);
          setShowAddModal(false);
        }}
      />
    </SRow>
  );
};

export default Reviewers;
