import styled from 'styled-components';
import { space, layout, flexbox, background, shadow, position, border } from 'styled-system';
import { Card } from 'antd';
import { isBoolean, isString } from 'lodash';

export default styled(Card)`
  &.ant-card {
    border: none;
    border-radius: ${({ rounded = true }) => {
      if (isBoolean(rounded)) {
        return rounded ? '12px' : '0px';
      }
      if (isString(rounded)) return rounded;
    }};
    box-shadow: ${({ shadowed }) => (shadowed ? '1px 1px 6px rgba(0, 0, 0, 0.06)' : 'none')};
    border: ${({ bordered }) => (bordered ? '1px solid var(--gray-border)' : 'none')};
    ${space}
    ${layout} 
    ${flexbox} 
    ${background} 
    ${shadow} 
    ${position}
    ${border}
  }

  &.ant-card > .ant-card-body {
    padding: ${props => (props.bodyPadding ? props.bodyPadding : '20px')};
  }

  &.ant-card > .ant-card-head {
    padding: ${props => (props.headPadding ? props.headPadding : '0 24px')};
  }
`;
