import { createAction, createReducer } from '@reduxjs/toolkit';

const defaultState = {
  loading: false,
  errors: [],
  organization: {}
};

export const loadingOrganization = createAction('organization/loadingOrganization');
export const organizationLoaded = createAction('organization/organizationLoaded');

export const updatingOrganization = createAction('organization/updatingOrganization');
export const organizationUpdated = createAction('organization/organizationUpdated');

export const catchError = createAction('organization/catchError');

export const organizationReduser = createReducer(defaultState, {
  [loadingOrganization]: state => {
    return { ...state, loading: true };
  },

  [organizationLoaded]: (state, { payload }) => ({
    ...state,
    organization: payload,
    loading: false
  }),

  [updatingOrganization]: state => ({ ...state, loading: true }),

  [organizationUpdated]: (state, { payload }) => ({
    ...state,
    organization: payload,
    loading: false
  }),

  [catchError]: (state, { payload }) => ({ ...state, errors: payload, loading: false })
});

export default organizationReduser;
