export default {
  integrationsSettingsPage: {
    proceed: 'Продолжить',
    save: 'Сохранить',
    delete: 'Удалить',
    sync: 'Синхронизировать',
    selectSyncPeriod: 'Выберите период синхронизации',
    deleteConfirm: {
      title: 'Удалить интеграцию?',
      ok: 'Да',
      cancel: 'Нет'
    },
    nameAndType: {
      name: 'Название',
      integrationName: 'Название интеграции',
      type: 'Тип',
      integrationType: 'Тип интеграции',
      yourB24address: 'Ваш адрес Bitrix 24'
    },
    integrationPage: {
      title: 'Интеграция',
      sync: 'Синхронизация',
      users: 'Пользователи',
      integrationSettings: 'Настройки интеграции',
      customFieldsSettings: {
        title: 'Настройки пользовательских полей',
        addField: 'Добавить поле',
        deleteConfirm: {
          title: 'Удалить пользовательское поле',
          okText: 'Удалить'
        },
        editor: {
          fieldName: 'Название поля',
          fieldKey: 'Ключ поля',
          fieldType: 'Тип поля',
          usedForFilters: 'Используется для фильтрации',
          usedForReviews: 'Используется для оценок',
          save: 'Сохранить',
          edit: 'Редактировать',
          delete: 'Удалить'
        }
      },
      testRequest: {
        title: 'Тестовый запрос',
        success: 'В систему добавлена коммуникация, доступная по этой ссылке',
        failure: 'Не удалось добавить тестовую коммуникацию',
        makeRequest: 'Сделать запрос'
      }
    },
    messages: {
      integrationSuccessfullyCreate: 'Интеграция успешно создана',
      integrationCreateFailed: 'Не удалось создать интеграцию'
    },
    addIntegration: {
      title: 'Добавить интеграцию',
      yourIntegrations: 'Ваши интеграции'
    },
    manageUserAccounts: {
      totalUsers: 'Пользователей',
      activeUsers: 'Активных',
      selectAll: 'Выбрать все',
      columns: {
        user: 'Сотрудник',
        email: 'Email',
        id: 'ID',
        sync: 'Синхронизация'
      },
      add: 'Добавить',
      downloadCSV: 'Скачать пример CSV',
      manual: 'Вручную',
      fromCSV: 'Через CSV',
      sendingFile: 'Отправка файла...',
      csvModal: {
        title: 'Поздравляем',
        content: 'Вы загрузили всех пользователей из вашего CSV файла!',
        okText: 'Продолжить'
      },
      usersAlreadyInSystem: 'В qolio уже существуют пользователи с таким email',
      accountToUsers: 'Данные учётные записи будут добавлены к пользователям',
      brokenEmail: 'У пользователя отсуствует email'
    },
    createCustomIntegration: {
      addUsersTitle: 'Добавить пользователей'
    },
    createSaasIntegration: {
      errorMessage: 'Возникла ошибка при синхронизации данных с',
      errorMessageContent:
        'Пожалуйста, переустановите приложение или напишите нам на info@qolio.io',
      sync: 'Синхронизация'
    },
    customUserAccountForm: {
      enterEmail: 'Введите email',
      enterCorrectEmail: 'Введите корректный email',
      sync: 'Синхронизируется',
      notSync: 'Не синхронизируется',
      enterId: 'Введите id'
    },
    sipuniAccountsImported:
      'Аккаунты пользователей импортированы. Для настройки их необходимо привязать к учётным записям в Qolio. Для этого перейдите в ',
    urlAndToken: {
      generate: 'Сгенерировать'
    }
  }
};
