import React from 'react';
import { Col, Tooltip, Row } from 'antd';
import { connect } from 'react-redux';
import { isEmpty, slice, get, compact } from 'lodash';
import { ResponsiveBar } from '@nivo/bar';
import { getChecklistDefinitionBindings } from 'redux/selectors/checklistItems/checklistItems';
import truncateString from 'core/utils/truncateString';
import SettingsModal from '../../Components/SettingsModal';
import {
  widgetNameSetter,
  tooltipSetter,
  widgetPeriodSetter,
  widgetInfoTextSetter,
  getDateSelectorType,
  searchMaxValue
} from '../../utils';
import { SettingsButtonCol, WidgetNameCol, WidgetCard, TimePeriod } from '../../styled';
import EmptyWidget from '../../Components/EmptyWidget';
import prepareData from './prepareData';

const ChecklistDefinitionQuestionsAverageScores = ({
  widgetData,
  loadingWidgetId,
  checklistDefinitionsByIds,
  questionGroupsByIds,
  colors,
  widgetsAnalyticsByIds,
  currentChecklistBindings
}) => {
  const widgetValue = get(widgetsAnalyticsByIds, widgetData.id, []);

  const { id, type, filters, name } = widgetData;
  const dateSelectorType = getDateSelectorType({ widgetType: type });
  const checklistDefinition = get(checklistDefinitionsByIds, filters.checklistDefinitionsIds, {});
  const preparedData =
    loadingWidgetId === id
      ? []
      : prepareData({
          widgetValue,
          currentChecklistBindings,
          questionGroupsByIds,
          checklistDefinition
        });

  return (
    <>
      {isEmpty(widgetValue) || loadingWidgetId === id ? (
        <EmptyWidget key={id} widgetData={widgetData} loadingWidgetId={loadingWidgetId} />
      ) : (
        <Col span={6}>
          <WidgetCard>
            <Row type="flex">
              <WidgetNameCol span={20}>
                {widgetInfoTextSetter({ type })}
                <Tooltip title={widgetNameSetter({ name, type })}>
                  <span>{widgetNameSetter({ name, type })}</span>
                </Tooltip>
                <TimePeriod type="secondary">
                  {widgetPeriodSetter(filters, dateSelectorType)}
                </TimePeriod>
              </WidgetNameCol>
              <SettingsButtonCol span={4}>
                <SettingsModal widgetData={widgetData} />
              </SettingsButtonCol>
            </Row>

            <ResponsiveBar
              data={preparedData.length > 25 ? slice(preparedData, 0, 25) : preparedData}
              keys={['value', 'name']}
              indexBy="name"
              margin={{ top: 0, right: 10, bottom: 70, left: 120 }}
              padding={0.3}
              layout="horizontal"
              colors={colors}
              borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
              axisTop={null}
              axisRight={null}
              maxValue={searchMaxValue({ checklistDefinitionsByIds, filters })}
              tooltip={tooltip => tooltipSetter(tooltip.data)}
              axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0
              }}
              axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                format: string => truncateString(string, 16)
              }}
              labelSkipWidth={12}
              labelSkipHeight={12}
              labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
              animate
              motionStiffness={90}
              motionDamping={15}
            />
          </WidgetCard>
        </Col>
      )}
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  const checklistDefinitionsByIds = state.checklistDefinitionsResource.byIds;
  const selectedChecklistDefinitionId = get(
    ownProps,
    'widgetData.filters.checklistDefinitionsIds',
    ''
  );

  const currentChecklist = get(checklistDefinitionsByIds, selectedChecklistDefinitionId, {});
  const currentChecklistBindings = compact(getChecklistDefinitionBindings(state, currentChecklist));
  const questionGroupsByIds = state.questionGroupsResource.byIds;
  const widgetsAnalyticsByIds = state.dashboardAnalytics.byIds;

  return {
    checklistDefinitionsByIds,
    questionGroupsByIds,
    questionsByIds: state.questionsResource.byIds,
    currentChecklistBindings,
    widgetsAnalyticsByIds
  };
};

export default connect(mapStateToProps, null)(ChecklistDefinitionQuestionsAverageScores);
