import { Copy, Trash2, Edit, File, Inbox, MoreVertical, Settings } from 'react-feather';
import { Col, Dropdown, Menu, message, Modal, Row, Tooltip, Typography } from 'antd';
import { Info } from 'components/Info';
import List from 'components/List';
import {
  CHECKLIST_DEFINITION_STATUS,
  RATING_CALCULATION,
  RATING_METHOD,
  RATING_MODE,
  SCALE_TYPES
} from 'core/utils/constants';
import truncateString from 'core/utils/truncateString';
import { flatten, get, isEmpty, sumBy, throttle } from 'lodash';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { actions, operations } from 'redux/lists/checklistDefinitionsList';
import { checklistDefinitionsResource } from 'redux/resources/checklistDefinitions';
import { setEditingColorZonesBeforePublish } from 'redux/ui/checklistEditor/reducer';
import ChecklistDefinitionStatusTag from 'components/Checklist/ChecklistDefinitionStatusTag';
import ColorZonesBeforePublishModal from 'components/ColorZones/ColorZonesBeforePublishModal';
import UserPreview from 'components/UserPreview';
import Icon from 'components/Icon';
import SButton from 'components/Standard/SButton';

const { Text } = Typography;

const ChecklistsList = ({
  history,
  page,
  hasNextPage,
  loading,
  updatePage,
  loadChecklistDefinitions,
  checklistDefinitions,
  checklistDefinitionsByIds,
  updateChecklistDefinition,
  deleteChecklistDefinition,
  copyChecklistDefinition,
  clearList,
  questionGroupsByIds,
  questionsByIds,
  bindingsByIds,
  setEditingColorZonesBeforePublish
}) => {
  useEffect(() => {
    clearList();
    return clearList;
  }, []);
  const { t } = useTranslation();

  useEffect(() => {
    loadChecklistDefinitions({
      page,
      include: 'creator,question_groups.question_to_group_bindings.question',
      sort: '-created_at'
    });
  }, [page.number]);

  const onScrollY = throttle(async el => {
    const isOnBottom = (element => {
      return element.clientHeight + element.scrollTop + 20 >= element.scrollHeight;
    })(el);

    if (hasNextPage && !loading && isOnBottom) {
      const newPage = `${parseInt(page.number) + 1}`;
      updatePage({
        number: newPage
      });
    }
  }, 250);

  const createCopy = async ({ id, name }) => {
    try {
      const result = await copyChecklistDefinition({ id, name });
      if (result.type === 'checklist-definitions') {
        history.push(`/checklist-editor/${result.id}`);
        message.success(t('components.checklistsList.messages.copySuccessfullyCreated'));
      }
    } catch (error) {
      console.log(error);
      message.error(t('components.checklistsList.messages.createCopyFailed'));
    }
  };

  // ! страница списка чек-листов
  const showPublishModal = (id, checklistProps = {}) => {
    Modal.destroyAll();
    return Modal.confirm({
      title: t('components.checklistsList.publishChecklistConfirm.title'),
      content: t('components.checklistsList.publishChecklistConfirm.description'),
      onOk: async () => {
        await updateChecklistDefinition({
          status: CHECKLIST_DEFINITION_STATUS.PUBLISHED.value,
          id,
          ...checklistProps
        });
        setEditingColorZonesBeforePublish({});
      },
      okText: t('components.checklistsList.publishChecklistConfirm.ok'),
      cancelText: t('components.checklistsList.publishChecklistConfirm.cancel')
    });
  };

  const onPublish = async ({ checklistDefinitionId, colorZones }) => {
    await showPublishModal(checklistDefinitionId, { colorZones });
  };

  const handleMenuClick = ({ key, id }) => {
    const actions = {
      edit: () => history.push(`checklist-editor/${id}`),
      settings: () => history.push(`checklist-settings/${id}`),
      publish: () => {
        // TODO: move this flow to common components

        const checklistDefinition = checklistDefinitionsByIds[id];
        const {
          ratingMethod,
          scaleType,
          ratingCalculation,
          status,
          ratingMode
        } = checklistDefinition;

        const questionGroups = get(checklistDefinition, 'questionGroupsIds', []).reduce(
          (acc, id) => (questionGroupsByIds[id] ? [...acc, questionGroupsByIds[id]] : acc),
          []
        );

        const bindings = flatten(
          questionGroups.map(({ questionToGroupBindingsIds }) =>
            questionToGroupBindingsIds.map(bindingId => bindingsByIds[bindingId])
          )
        );

        const questions = bindings
          .map(binding => get(questionsByIds, binding.questionId))
          .filter(Boolean);

        const sumPercentage = sumBy(bindings, 'percentage');

        if (ratingMethod === RATING_METHOD.WEIGHTED && sumPercentage < 100)
          return message.warning(
            t('components.checklistsList.messages.sumPercentageLessThanHundren')
          );

        if (isEmpty(questions)) {
          return message.warning(t('components.checklistsList.messages.emptyQuestions'));
        }

        if (
          status === CHECKLIST_DEFINITION_STATUS.DRAFT.value &&
          (scaleType === SCALE_TYPES.custom.type ||
            scaleType === SCALE_TYPES.custom_binary.type ||
            ratingCalculation === RATING_CALCULATION.SUM) &&
          ratingMode === RATING_MODE.NUMBERS
        ) {
          return setEditingColorZonesBeforePublish({
            checklistDefinitionId: id,
            colorZones:
              get(checklistDefinition, 'colorZones', SCALE_TYPES[scaleType].colorZones) ||
              SCALE_TYPES.max_5.colorZones
          });
        }

        return showPublishModal(id);
      },
      archive: () =>
        Modal.confirm({
          okText: t('components.checklistsList.confirmChecklistArchive.ok'),
          cancelText: t('components.checklistsList.confirmChecklistArchive.cancel'),
          title: t('components.checklistsList.confirmChecklistArchive.title'),
          content: t('components.checklistsList.confirmChecklistArchive.description'),
          onOk: () =>
            updateChecklistDefinition({
              id,
              name: get(checklistDefinitionsByIds, `${id}.name`, undefined),
              unitsIds: get(checklistDefinitionsByIds, `${id}.unitsIds`, undefined),
              status: 'archived'
            })
        }),
      copy: () => {
        createCopy({
          id,
          name: `${get(checklistDefinitionsByIds, `${id}.name`, '')} (Копия)`
        });
      },
      delete: () =>
        Modal.confirm({
          title: t('components.checklistsList.confirmChecklistDelete.title'),
          content: t('components.checklistsList.confirmChecklistDelete.description'),
          okText: t('components.checklistsList.confirmChecklistDelete.ok'),
          cancelText: t('components.checklistsList.confirmChecklistDelete.cancel'),
          okType: 'danger',
          onOk: async () => deleteChecklistDefinition({ id })
        })
      //   TODO: add copy
      //   Modal
    };

    actions[key]();
  };

  const getMenu = ({ id }) => (
    <Menu onClick={({ key }) => handleMenuClick({ key, id })}>
      {checklistDefinitionsByIds[id]?.status !== CHECKLIST_DEFINITION_STATUS.PUBLISHED.value && (
        <Menu.Item key="publish">
          <Icon icon={File} />
          {t('components.checklistsList.menu.publish')}
        </Menu.Item>
      )}

      <Menu.Item key="edit">
        <Icon icon={Edit} />
        {checklistDefinitionsByIds[id]?.status === CHECKLIST_DEFINITION_STATUS.DRAFT.value
          ? t('components.checklistsList.menu.edit')
          : t('components.checklistsList.menu.view')}
        {t('components.checklistsList.checklistQuestionsStr')}
      </Menu.Item>

      <Menu.Item key="settings">
        <Icon icon={Settings} />
        {t('components.checklistsList.menu.settings')}
      </Menu.Item>

      {checklistDefinitionsByIds[id]?.status !== CHECKLIST_DEFINITION_STATUS.ARCHIVED.value && (
        <Menu.Item key="copy">
          <Icon icon={Copy} />
          {t('components.checklistsList.menu.copy')}
        </Menu.Item>
      )}

      {checklistDefinitionsByIds[id]?.status !== CHECKLIST_DEFINITION_STATUS.ARCHIVED.value && (
        <Menu.Item key="archive">
          <Icon icon={Inbox} />
          {t('components.checklistsList.menu.archive')}
        </Menu.Item>
      )}

      {checklistDefinitionsByIds[id]?.status === CHECKLIST_DEFINITION_STATUS.DRAFT.value && (
        <Menu.Item key="delete" style={{ color: 'var(--red_primary)' }}>
          <Icon icon={Trash2} />
          {t('components.checklistsList.menu.delete')}
        </Menu.Item>
      )}
    </Menu>
  );

  const columns = {
    name: {
      render: (name, { description }) => (
        <Row type="flex" align="middle" gutter={[4, 4]}>
          <Col>
            <Tooltip title={name}>
              <Text>{truncateString(name, 25)}</Text>
            </Tooltip>
          </Col>
          <Col>{!isEmpty(description) && <Info tooltip={{ title: description }} />}</Col>
        </Row>
      ),
      col: { span: 6 }
    },
    creatorId: {
      render: creatorId => (
        <Row type="flex" align="middle" gutter={[4, 4]}>
          <Col>
            <Text strong>{t('components.checklistsList.listColumns.author')}</Text>
          </Col>
          <Col>
            <UserPreview disabled userId={creatorId} showAvatar truncateSize={25} />
          </Col>
        </Row>
      ),
      col: { span: 7 }
    },
    createdAt: {
      render: createdAt => (
        <>
          <Text strong>{t('components.checklistsList.listColumns.createdAt')}</Text>
          <Text>{moment(createdAt).format('DD/MM/YYYY')}</Text>
        </>
      ),
      col: { span: 4 }
    },
    status: {
      render: status => (
        <Row type="flex" align="middle" gutter={[4, 4]}>
          <Col>
            <Text strong>{t('components.checklistsList.listColumns.status')}</Text>
          </Col>
          <Col>
            <ChecklistDefinitionStatusTag status={status} />
          </Col>
        </Row>
      ),
      col: { span: 4 }
    },
    menu: {
      render: (checklistdefinition, { id }) => (
        <Row type="flex" align="middle" justify="end">
          <Dropdown overlay={getMenu({ id })} trigger={['click']}>
            <SButton icon={<Icon icon={MoreVertical} strokeWidth="1" />} />
          </Dropdown>
        </Row>
      ),
      col: { span: 3 }
    }
  };

  return (
    <Row gutter={[8, 8]} style={{ margin: '-4px' }}>
      <PerfectScrollbar
        onScrollY={onScrollY}
        options={{ suppressScrollX: true }}
        className="list-scroll-container"
      >
        <List dataSource={checklistDefinitions} columns={columns} />
        {loading && (
          <Col span={24}>
            <Row type="flex" justify="center">
              <Col>
                <Text type="secondary">{t('components.checklistsList.loading')}</Text>
              </Col>
            </Row>
          </Col>
        )}

        {!loading && !hasNextPage && (
          <Col span={24}>
            <Row type="flex" justify="center">
              <Col>
                <Text type="secondary">{t('components.checklistsList.listLoaded')}</Text>
              </Col>
            </Row>
          </Col>
        )}
      </PerfectScrollbar>
      <ColorZonesBeforePublishModal onOk={onPublish} />
    </Row>
  );
};

const mapStateToProps = state => {
  const { editingColorZonesBeforePublish } = state.uiChecklistEditor;
  const { ids, loading, page, totalPages } = state.checklistDefinitionsList;
  const checklistDefinitionsByIds = state.checklistDefinitionsResource.byIds;
  const checklistDefinitions = ids.map(id => checklistDefinitionsByIds[id]).filter(Boolean);

  return {
    checklistDefinitions,
    bindingsByIds: state.questionToGroupBindingsResource.byIds,
    questionGroupsByIds: state.questionGroupsResource.byIds,
    questionsByIds: state.questionsResource.byIds,
    checklistDefinitionsByIds,
    loading,
    page,
    hasNextPage: totalPages > page.number,
    editingColorZonesBeforePublish
  };
};

const mapDispatchToProps = {
  updatePage: actions.updatePage,
  clearList: actions.clearList,
  loadChecklistDefinitions: operations.load,
  updateChecklistDefinition: checklistDefinitionsResource.operations.updateById,
  deleteChecklistDefinition: checklistDefinitionsResource.operations.deleteById,
  copyChecklistDefinition: checklistDefinitionsResource.operations.copyById,
  setEditingColorZonesBeforePublish
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChecklistsList));
