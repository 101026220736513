import { Col, Empty, Row, Spin } from 'antd';
import SCard from 'components/Standard/SCard';
import { get, isEmpty, orderBy } from 'lodash';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { reviewLibrariesResource } from 'redux/resources/reviewLibraries';
import ClientInteraction from './components/ClientInteraction';
import ClientInteractionPreview from './components/ClientInteractionPreview';
import CreateFolder from './components/CreateFolder';
import Folder from './components/Folder';

const LibraryPage = ({
  loading,
  foldersByIds = {},
  currentFolder,
  currentClientInteractionId,
  itemsByIds,
  loadLibraries
}) => {
  useEffect(() => {
    loadLibraries({
      include:
        'items.review.reviewer,items.review.phone_call.operator,items.review.comments.author,items.review.comments.uploaded_files,items.review.checklist.answers'
    });
  }, []);

  const { t } = useTranslation();

  return (
    <Row style={{ padding: 16 }}>
      <Col span={24}>
        <Helmet>
          <title>{t('pagesMeta.libraryPage.title')}</title>
        </Helmet>
        <CreateFolder />
      </Col>
      {isEmpty(foldersByIds) ? (
        <Col span={24}>
          <SCard>
            <Spin spinning={loading}>
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={t('libraryPage.libraryItem.empty.folders')}
              />
            </Spin>
          </SCard>
        </Col>
      ) : (
        <Col span={24}>
          <Row gutter={[8, 8]}>
            <Col span={6}>
              <Row gutter={[8, 8]}>
                {orderBy(foldersByIds, ['createdAt'], ['desc']).map(({ id, name }) => (
                  <Col flex="auto">
                    <Folder key={id} id={id} name={name} />
                  </Col>
                ))}
              </Row>
            </Col>
            <Col span={6}>
              {currentFolder && isEmpty(currentFolder.itemsIds) ? (
                <SCard style={{ height: 208 }}>
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={t('libraryPage.libraryItem.empty.calls')}
                    style={{ margin: '52px 0' }}
                  />
                </SCard>
              ) : (
                currentFolder &&
                orderBy(
                  currentFolder.itemsIds,
                  [itemId => get(itemsByIds, `${itemId}.createdAt`, '')],
                  ['desc']
                ).map(
                  id =>
                    itemsByIds[id] && (
                      <Row key={id} gutter={[8, 8]}>
                        <Col flex="auto">
                          <ClientInteractionPreview key={id} id={id} />
                        </Col>
                      </Row>
                    )
                )
              )}
            </Col>
            <Col span={12}>
              {currentClientInteractionId && <ClientInteraction id={currentClientInteractionId} />}
            </Col>
          </Row>
        </Col>
      )}
    </Row>
  );
};

const mapStateToProps = state => {
  const { byIds: foldersByIds, loading } = state.reviewLibrariesResource;
  const { byIds: itemsByIds } = state.reviewLibraryItemsResource;

  if (loading) return { loading };

  const { currentFolderId, currentClientInteractionId, editingFolder } = state.uiLibraryPage;

  const currentFolder = currentFolderId ? foldersByIds[currentFolderId] : undefined;

  return {
    loading,
    foldersByIds: editingFolder
      ? { ...foldersByIds, [editingFolder.id]: editingFolder }
      : foldersByIds,
    currentFolderId,
    currentFolder,
    itemsByIds,
    currentClientInteractionId
  };
};

const mapDispatchToProps = {
  loadLibraries: reviewLibrariesResource.operations.load
};

export default connect(mapStateToProps, mapDispatchToProps)(LibraryPage);
