export default {
  workPlanTaskConfigurationEditor: {
    taskStarted: 'Tarea iniciada',
    taskSaved: 'Tarea guardada',
    deleteTask: 'Eliminar',
    saveTask: 'Guardar',
    startTask: 'Correr',
    delete: {
      title: '¿Eliminar tarea?',
      taskDeleted: 'Tarea eliminada',
      cantDeleteTask: 'No se pudo borrar la tarea'
    },
    schedule: {
      taskConfig: 'Parámetros de la tarea',
      taskName: 'Nombre de la tarea',
      period: 'Período',
      timeFrom: 'La fecha del comienzo',
      timeTo: 'Fecha de realización',
      repeatEvery: 'Repite cada',
      repeat: 'Repeticiones',
      toRepeat: 'Repetir',
      dayTimeFrom: 'Hora de inicio',
      dayTimeTo: 'Tiempo de finalización',
      periodForTask: 'Período de ejecución de la tarea',
      periodTask: 'Periodo de ejecución',
      date: 'Fecha',
      time: 'Tiempo',
      start: 'Empezar',
      end: 'Terminar en',
      stopTask: 'Detener tarea',
      customPeriod: 'Periodo personalizable'
    },
    filters: {
      deleteFilters: 'Quitar filtros',
      maxCount: 'Cantidad de evaluaciones',
      clientInteractionType: 'Tipo de comunicación',
      addUsers: 'Agragar usuario',
      add: 'Añadir',
      usersAdded: 'Usuarios',
      addFilters: 'Agregar tipo de comunicación'
    },
    distribution: {
      addReviewer: 'Agregar revisor',
      reviewers: 'Verificador',
      manual: 'Manualmente',
      auto: 'Igualmente'
    },
    addUsersModal: {
      users: 'Empleados',
      unit: 'Departamento',
      role: 'Papel'
    }
  }
};
