import React, { useState } from 'react';
import { Col, Tooltip, Row, Radio, message } from 'antd';
import { updateDashboardWidget } from 'redux/ui/widgetSettingsModal/operations';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { RATING_MODE, WIDGET_VIEW_MODES } from 'core/utils/constants';
import { get, isEmpty, isEqual } from 'lodash';
import ExcelIcon from 'images/ExcelIcon';
import { useTranslation } from 'react-i18next';
import WidgetViewChanger from 'components/Dashboard/WidgetViewChanger';
import { LoadingOutlined } from '@ant-design/icons';
import { getChecklistDefinitionQuestionGroupsBeforeQuestions } from 'redux/selectors/checklistItems/checklistItems';
import { IconButton } from 'components/Buttons/IconButton';
import WidgetTable from 'components/Dashboard/WidgetTable';
import ClientInteractionDrawer from 'components/ClientInteractionDrawer/ClientInteractionDrawer';
import EmptyWidget from '../../Components/EmptyWidget';
import { SettingsButtonCol, WidgetNameCol, LargeWidgetCard, TimePeriod } from '../../styled';
import SettingsModal from '../../Components/SettingsModal';
import {
  widgetNameSetter,
  widgetPeriodSetter,
  widgetInfoTextSetter,
  getDateSelectorType,
  exportWidgetData,
  getSortString
} from '../../utils';
import { prepareColumns, prepareRows } from './prepareTableData';

const TableReviewsWithScores = ({ widgetData, loadingWidgetId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id, type, filters, name } = widgetData;

  const [loadingExport, setLoadingExport] = useState(false);
  const [initialState, setInitialState] = useState({});

  const dateSelectorType = getDateSelectorType({ widgetType: type });
  const viewType = get(filters, 'viewType', WIDGET_VIEW_MODES.PERCENTAGE.value);

  const widgetsAnalytics = useSelector(state => state.dashboardAnalytics.byIds[id], isEqual);
  const usersByIds = useSelector(state => state.usersResource.byIds, shallowEqual);
  const unitsByIds = useSelector(state => state.unitsResource.byIds, shallowEqual);

  const checklistDefinitionId = get(widgetData, 'filters.checklistDefinitionsIds');
  const checklistDefinitionsByIds = useSelector(
    state => state.checklistDefinitionsResource.byIds,
    shallowEqual
  );
  const currentChecklist = get(checklistDefinitionsByIds, checklistDefinitionId, {});
  const checklistItems = useSelector(
    state => getChecklistDefinitionQuestionGroupsBeforeQuestions(state, currentChecklist),
    shallowEqual
  );

  const widgetValue = get(widgetsAnalytics, 'data', []);
  const widgetMeta = get(widgetsAnalytics, 'meta', []);

  const tableData = {
    tableColumns: prepareColumns({
      widgetValue,
      currentChecklist,
      checklistItems,
      filters,
      widgetMeta
    }),
    tableRows: prepareRows({
      filters,
      currentChecklist,
      widgetValue,
      checklistItems,
      usersByIds,
      unitsByIds
    })
  };

  const onChangeViewType = viewType => {
    return dispatch(
      updateDashboardWidget({
        id,
        type,
        filters: { ...filters, viewType }
      })
    );
  };

  const onChangeViewMode = ({ viewMode }) => {
    return dispatch(
      updateDashboardWidget({
        id,
        type,
        filters: { ...filters, viewMode }
      })
    );
  };

  const startExport = async () => {
    const res = await dispatch(
      updateDashboardWidget({
        id,
        type,
        filters,
        page: {
          number: 1,
          size: widgetMeta.total_count
        }
      })
    );

    const body = {
      widgetType: type,
      widgetData: {
        columns: prepareColumns({
          widgetValue: res?.updatedAnalytics,
          currentChecklist,
          checklistItems,
          filters,
          widgetMeta
        }).map(item => ({
          ...item,
          title: [
            'reviewId',
            'operatorId',
            'communicationKey',
            'totalScore',
            'clientInteractionStartedAt',
            'reviewCreatedAt',
            'unitName'
          ].includes(item.key)
            ? t(item?.title?.props?.i18nKey)
            : item?.title?.props?.title
        })),
        rows: prepareRows({
          filters,
          currentChecklist,
          widgetValue: res?.updatedAnalytics,
          checklistItems,
          isExport: true,
          usersByIds,
          unitsByIds
        })
      }
    };

    await exportWidgetData({ body, setLoadingExport });

    await dispatch(
      updateDashboardWidget({
        id,
        type,
        filters,
        page: initialState
      })
    );
  };

  const onChange = async ({ pagination, sorter }) => {
    setInitialState({
      number: pagination?.current,
      size: pagination?.pageSize
    });

    const columnsKeys = tableData.tableColumns.map(item => item.key);
    const { columnKey } = sorter;

    const isNewSort = columnsKeys.includes(columnKey);

    await dispatch(
      updateDashboardWidget({
        id,
        type,
        filters,
        page: {
          number: pagination.current,
          size: pagination.pageSize
        },
        sort: isNewSort ? getSortString({ sorter, viewMode: filters?.viewMode }) : ''
      })
    );
  };

  const isEmptyWidgetData = isEmpty(widgetData);

  return (
    <>
      {isEmptyWidgetData ? (
        <EmptyWidget key={id} widgetData={widgetData} loadingWidgetId={loadingWidgetId} />
      ) : (
        <Col span={24}>
          <LargeWidgetCard>
            <Row type="flex" justify="space-between" align="top">
              <WidgetNameCol span={8}>
                <Row type="flex" gutter={[6, 0]} justify="start">
                  <Col>{widgetInfoTextSetter({ type })}</Col>
                  <Col>
                    <Tooltip title={widgetNameSetter({ name, type })}>
                      <span>{widgetNameSetter({ name, type })}</span>
                    </Tooltip>
                  </Col>
                </Row>

                <TimePeriod type="secondary">
                  {widgetPeriodSetter(filters, dateSelectorType)}
                </TimePeriod>
              </WidgetNameCol>
              <Col span={6}>
                <Row justify="center">
                  <Col>
                    <Radio.Group
                      onChange={e => onChangeViewMode({ viewMode: e.target.value })}
                      value={filters.viewMode || WIDGET_VIEW_MODES.QUESTIONS.value}
                    >
                      <Radio.Button value={WIDGET_VIEW_MODES.QUESTIONS.value}>
                        {t(WIDGET_VIEW_MODES.QUESTIONS.title)}
                      </Radio.Button>
                      <Radio.Button
                        value={WIDGET_VIEW_MODES.GROUPS.value}
                        disabled={!currentChecklist?.isGroupable}
                      >
                        {t(WIDGET_VIEW_MODES.GROUPS.title)}
                      </Radio.Button>
                    </Radio.Group>
                  </Col>
                </Row>
              </Col>
              <SettingsButtonCol span={8}>
                <Row type="flex" gutter={[4, 0]}>
                  <Col>
                    <WidgetViewChanger
                      value={viewType}
                      onChange={onChangeViewType}
                      disabled={currentChecklist.ratingMode === RATING_MODE.NUMBERS}
                    />
                  </Col>

                  <Col>
                    <IconButton
                      tooltip={{ title: t('dashboardPage.widget.buttons.export') }}
                      button={{
                        icon: loadingExport ? (
                          <LoadingOutlined spin />
                        ) : (
                          <ExcelIcon color="rgba(38, 38, 38, 0.75)" />
                        ),
                        onClick: () =>
                          widgetMeta.total_count >= 5000
                            ? message.warning(t('messages.warning.limitExportReviews'))
                            : startExport(),
                        size: 'icon'
                      }}
                    />
                  </Col>
                  <Col>
                    <SettingsModal widgetData={widgetData} />
                  </Col>
                </Row>
              </SettingsButtonCol>
            </Row>
            <WidgetTable
              columns={tableData.tableColumns.filter(item => item.key !== 'unitName')}
              dataSource={tableData.tableRows}
              loading={loadingWidgetId === id || loadingExport}
              size="small"
              bordered
              showSorterTooltip={false}
              scroll={{ x: 1500, y: 452 }}
              style={{ marginTop: 8 }}
              rowClassName={record => (record.key === 'header' ? 'table-row-light' : '')}
              pagination={{
                position: 'bottomLeft',
                showSizeChanger: true,
                total: widgetMeta.total_count,
                current: widgetMeta.page
              }}
              onChange={(pagination, filters, sorter) => onChange({ pagination, sorter })}
            />
            <ClientInteractionDrawer shouldLoad />
          </LargeWidgetCard>
        </Col>
      )}
    </>
  );
};

export default TableReviewsWithScores;
