export default {
  triggersSettingsPage: {
    loading: 'Cargando',
    card: {
      buttons: {
        enable: 'Incluir',
        disable: 'Excluir',
        settings: 'Ir a la configuración de etiquetas',
        delete: '¿Eliminar la etiqueta',
        createTaskDefinitionCard: 'Construir etiqueta'
      },
      confirmDelete: {
        title: '¿Está seguro de que desea eliminar la etiqueta?',
        ok: 'Eliminar',
        cancel: 'Cerrar'
      },
      info: {
        type: 'Tipo: ',
        author: 'Autor: ',
        status: {
          title: 'Estatus: ',
          enable: 'Activo',
          disable: 'No activo'
        },
        checklistDefinition: 'Formulario de evaluación: ',
        createdAt: 'Creado por:  '
      },
      loading: 'Cargando etiquetas... 😤',
      listLoaded: 'Es todo 😊'
    },
    editTaskDefinition: {
      messages: {
        tagSuccessfullyUpdated: 'Etiqueta actualizada',
        tagSuccessfullySaved: 'Etiqueta guardada',
        tagSuccessfullyDeleted: 'Etiqueta eliminada',
        addSettingsToAutomaticTag: 'Añada ajustes a su etiqueta automática:'
      },
      buttons: {
        cancel: 'Cancelar',
        save: 'Guardar'
      },
      text: {
        form: 'desde:',
        to: 'hasta:'
      },
      empty: {
        checklistDefinition:
          'No ha elegido la lista a chequear o en la lista a chequear no hay preguntas',
        checklistDefinitionItemName: 'No se ha establecido el nombre'
      },
      form: {
        name: 'Nombre',
        namePlaceholder: 'Introduzca el nombre',
        checklistDefinition: 'Lista a chequear',
        checklistDefinitionPlaceholder: 'Elija la lista a chequear',
        type: {
          title: 'Tipo',
          automatic: {
            name: 'Automático ',
            description: '(Se añade a la llamada por el resultado de la lista a chequear)'
          },
          manual: {
            name: 'Manual ',
            description: '(lo puede añadir a la llamada usred mismo)'
          }
        }
      },
      changeTagTypeConfirm: {
        title: '¿Cambiar el tipo de etiqueta?',
        description: 'Los datos que no se han guardado serán eliminados',
        ok: 'Si',
        cancel: 'No'
      },
      changeChecklistDefinitionConfirm: {
        title: '¿Cambiar la lista a chequear?',
        description: 'Los datos que no se han guardado serán eliminados',
        ok: 'Si',
        cancel: 'No'
      },
      resetChecklistDefinitionConditionsConfirm: {
        title: 'La condición no puede contener preguntas o grupos de preguntas',
        description: '¿Borrar la condición para toda la lista a chequear?',
        ok: 'Si',
        cancel: 'No'
      },
      resetChecklistDefinitionQuestionsConditionsConfirm: {
        title: 'La condición no puede contener preguntas',
        description: '¿Borrar la condición poara el grupo de preguntas?',
        ok: 'Si',
        cancel: 'No'
      }
    }
  }
};
