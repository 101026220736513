import { Col } from 'antd';
import SCard from 'components/Standard/SCard';
import { get, isEqual, orderBy } from 'lodash';
import React, { useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FLAGS_TO_COLORS_RELATIONS, COMMENTS_FLAGS_COLORS_RELATIONS } from 'core/utils/constants';
import { deleteReplyComment, updateReplyComment } from 'redux/ui/clientInteractionPage/operations';
import styled from 'styled-components';
import SRow from 'components/Standard/SRow';
import SCol from 'components/Standard/SCol';
import CommentEditButton from '../Components/CommentEditButton';
import CommentInfo from '../Components/CommentInfo';
import CommentText from '../Components/CommentText';
import CreateReplyForm from '../Components/CreateReplyForm';
import ReplyComment from './ReplyComment';

const colorForRatingFlag = ratingFlag =>
  FLAGS_TO_COLORS_RELATIONS[ratingFlag] || FLAGS_TO_COLORS_RELATIONS.without_flag;

const colorForCommentBody = ratingFlag =>
  COMMENTS_FLAGS_COLORS_RELATIONS[ratingFlag] || COMMENTS_FLAGS_COLORS_RELATIONS.without_flag;

const marginForComment = type => {
  return type === 'review_comment' ? '8px' : '4px';
};

const ReviewComment = ({
  reviewCommentId,
  descendantIds = [],
  onDelete,
  onUpdate,
  allowActions,
  isQuestionView
}) => {
  const dispatch = useDispatch();
  const deleteReply = useCallback(options => dispatch(deleteReplyComment(options)), [dispatch]);
  const updateReply = useCallback(options => dispatch(updateReplyComment(options)), [dispatch]);

  const clientInteractionsCommentsByIds = useSelector(
    state => state.uiClientInteractionPage.commentsByIds,
    isEqual
  );
  const commentsByIds = useSelector(state => state.commentsResource.byIds, isEqual);

  const currentReviewComment =
    clientInteractionsCommentsByIds[reviewCommentId] || get(commentsByIds, reviewCommentId, {});

  const uploadedFilesByIds = useSelector(state => state.uploadedFilesResource.byIds, isEqual);
  const [commentState, setCommentState] = useState({
    ...currentReviewComment,
    uploadedFiles: currentReviewComment?.uploadedFilesIds?.map(id => uploadedFilesByIds[id])
  });
  const editorText = commentState?.text;
  const [isEditing, setEditing] = useState(false);
  const [isEditReply, setEditReply] = useState(false);

  const descendantCommentsByIds = orderBy(
    descendantIds.map(descendantId => get(commentsByIds, descendantId, {})),
    'createdAt'
  );

  const ratingFlag = get(commentState, 'metadata.ratingFlag', '');
  const isHidden = get(commentState, 'hidden', false);
  const commentType = get(commentState, 'commentType', 'review_comment');
  const parentId = get(commentState, 'parentId', '');

  const commentTimeStamp = commentState?.metadata?.timestamp;
  const editingCommentId = useSelector(state => state.uiClientInteractionPage.editingCommentId);

  return (
    <>
      <StyledCol span={24} commentType={commentType}>
        <SCard
          bodyPadding="12px"
          style={{ borderLeft: `6px solid ${colorForRatingFlag(ratingFlag)}` }}
          background={colorForCommentBody(ratingFlag)}
          rounded="4px"
        >
          <SRow type="flex" justify="space-between">
            {editingCommentId && editingCommentId === reviewCommentId ? (
              <SCol span={24}>
                <CommentText
                  commentState={commentState}
                  setCommentState={setCommentState}
                  editorText={editorText}
                  isEditing={isEditing}
                  setEditing={setEditing}
                  onUpdate={onUpdate}
                />
              </SCol>
            ) : (
              <>
                <SCol span={24}>
                  <CommentInfo
                    commentState={commentState}
                    isEditing={isEditing}
                    setEditing={setEditing}
                    isEditReply={isEditReply}
                    setEditReply={setEditReply}
                    editorText={editorText}
                    currentComment={currentReviewComment}
                    allowActions={allowActions}
                    onDelete={onDelete}
                    commentType={commentType}
                    commentTimeStamp={commentTimeStamp}
                    isQuestionView={isQuestionView}
                  />
                  <SCol span={24}>
                    <CommentText
                      commentState={commentState}
                      isEditing={isEditing}
                      setCommentState={setCommentState}
                      editorText={editorText}
                      setEditing={setEditing}
                      onUpdate={onUpdate}
                    />
                  </SCol>
                </SCol>
              </>
            )}
          </SRow>

          {allowActions && (
            <CommentEditButton
              {...commentState}
              isEditing={isEditing}
              setEditing={setEditing}
              editorText={editorText}
              onDelete={onDelete}
              onUpdate={onUpdate}
              setEditReply={setEditReply}
            />
          )}
        </SCard>
        {allowActions && (
          <CreateReplyForm
            parentId={parentId}
            isEditReply={isEditReply}
            setEditReply={setEditReply}
          />
        )}
      </StyledCol>
      {descendantCommentsByIds.map(descendantComment => {
        return (
          <ReplyComment
            replyCommentId={descendantComment.id}
            commentsByIds={commentsByIds}
            onDelete={deleteReply}
            onUpdate={updateReply}
            isParentHidden={isHidden}
            allowActions={allowActions}
          />
        );
      })}
    </>
  );
};

const StyledCol = styled(Col)`
  &.ant-col {
    width: 100%;
    &:nth-of-type(n + 2) {
      margin-top: ${({ commentType }) => `${marginForComment(commentType)}`};
    }
  }
`;

export default ReviewComment;
