import { FlagFilled, MessageFilled } from '@ant-design/icons';
import { Col, Empty, Row, Select, Typography } from 'antd';
import CommentsList from 'components/Comments/CommentsList';
import {
  ANALYTICS_WIDGETS_NAMES,
  FLAGS_COLORS_LITERALS_RUS,
  FLAGS_TO_COLORS_RELATIONS
} from 'core/utils/constants';
import { selectSearch } from 'core/utils/selectSearch';
import { get, isEmpty, reduce, throttle } from 'lodash';
import React, { useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { connect } from 'react-redux';
import { loadAnalyticsWidgets } from 'redux/entities/analytics/widgets/operations';
import { actions, operations } from 'redux/lists/userReviewsWithComments';
import { commentsResource } from 'redux/resources/comments';
import { getCommentsFilteredByPermission } from 'redux/selectors/comments';
import { getCurrentUserPermissions } from 'redux/selectors/users';
import { setCommunication } from 'redux/ui/clientInteractionDrawer/reducer';
import { omitAllQuestionsFilter } from 'redux/ui/userAnalyticsPage/operations';
import { setDataString } from 'redux/ui/userAnalyticsPage/reducer';
import styled from 'styled-components';
import Icon from 'components/Icon';
import SCol from 'components/Standard/SCol';
import MainControlsPanel from '../MainControlsPanel';
import ReviewPreview from './ReviewPreview';

const { Option } = Select;

const { Text } = Typography;

const ReviewsWithComments = ({
  userId,
  updateFilters,
  checklistDefinitionId,
  currentTimeRange = {},
  loadReviews,
  loadComments,
  filters,
  clearList,
  page,
  loading,
  hasNextPage,
  totalCount = '',
  updatePage,
  reviews,
  currentUserPermissions,
  loadAnalyticsWidgets,
  setCommunication,
  history,
  setQuery
}) => {
  const flagSelectRef = useRef(null);
  const { search } = history.location;

  useEffect(() => {
    if (search) {
      setQuery(search);
    }
  }, [search]);

  // ? use effect for every filter to decide clear list or not and react on global state values or do any specific logic

  useEffect(() => {
    clearList();
    return clearList;
  }, []);

  // * flags
  useEffect(() => {
    clearList();
    flagSelectRef && flagSelectRef.current && flagSelectRef.current.blur();
  }, [filters.commentsRatingFlags]);

  // * user
  useEffect(() => {
    if (!isEmpty(userId)) {
      clearList();
      updateFilters({ operatorsIds: userId });
    }
  }, [userId]);

  // * time
  useEffect(() => {
    clearList();
    updateFilters(currentTimeRange);
  }, [
    currentTimeRange.clientInteractionTimeFrom,
    currentTimeRange.clientInteractionTimeTo,
    currentTimeRange.clientInteractionSymbolicTimeRange
  ]);

  // * checklist
  useEffect(() => {
    clearList();
    updateFilters({ checklistDefinitionsIds: checklistDefinitionId });
  }, [checklistDefinitionId]);

  const loadReviewsWithComments = async ({ page, filters }) => {
    const reviews = await loadReviews({
      page,
      filters: omitAllQuestionsFilter(filters),
      include: 'custom_communication,text_communication,client_interaction,checklist'
    });

    if (!isEmpty(reviews))
      await loadComments({
        filters: {
          reviewsIds: Object.keys(reviews),
          commentsRatingFlags: filters.commentsRatingFlags
        },
        include: 'author.unit'
      });
  };

  useEffect(() => {
    if (
      filters.checklistDefinitionsIds &&
      filters.operatorsIds &&
      userId &&
      filters.operatorsIds === userId
    ) {
      loadReviewsWithComments({ page, filters: omitAllQuestionsFilter(filters) });
      loadAnalyticsWidgets({
        widgetNames: [ANALYTICS_WIDGETS_NAMES.COMMENTS_COUNT_BY_OPERATORS],
        filters: omitAllQuestionsFilter(filters)
      });
    }
  }, [
    page.number,
    filters.clientInteractionTimeFrom,
    filters.clientInteractionTimeTo,
    filters.clientInteractionSymbolicTimeRange,
    filters.operatorsIds,
    filters.checklistDefinitionsIds,
    filters.commentsRatingFlags
  ]);

  useEffect(() => {
    clearList();
    return clearList;
  }, []);

  const { t } = useTranslation();

  const onScrollY = throttle(async el => {
    const isOnBottom = (element => {
      return element.clientHeight + element.scrollTop + 40 >= element.scrollHeight;
    })(el);

    if (hasNextPage && !loading && isOnBottom) {
      const newPage = `${parseInt(page.number) + 1}`;
      updatePage({
        number: newPage
      });
    }
  }, 250);

  const getReviewComments = review => {
    return (
      getCommentsFilteredByPermission({
        currentUserPermissions,
        comments: review.comments
      }) || []
    ).filter(({ ratingFlag, commentType }) => {
      const allowedFlags = get(filters, 'commentsRatingFlags', []);

      if (isEmpty(allowedFlags) || commentType !== 'review_comment') {
        return true;
      }

      if (!ratingFlag && allowedFlags.includes('empty')) {
        return true;
      }

      if (allowedFlags.includes(ratingFlag)) {
        return true;
      }

      return false;
    });
  };

  const onLinkClick = id => e => {
    if (!e.ctrlKey) {
      e.preventDefault();
      setCommunication({ type: 'review', id });
    }
  };

  const totalCountString = `${t(
    'userAnalyticsPage.reviewsWithComments.flagsAndCommentsCount'
  )} ${totalCount || ''}`;

  return (
    <Row gutter={[8, 8]} style={{ margin: '-4px' }}>
      <Helmet>
        <title>{t('pagesMeta.userAnalyticsPage.title')}</title>
      </Helmet>
      <Col span={24}>
        <MainControlsPanel />
      </Col>
      <Col span={24}>
        <Row
          type="flex"
          gutter={[8, 8]}
          style={{ margin: '-4px' }}
          justify="space-between"
          align="middle"
        >
          <Col>
            <Text type="secondary">{totalCountString}</Text>
          </Col>

          <SCol display="flex" justifyContent="flex-end" span={8}>
            <Select
              loading={loading}
              disabled={loading}
              style={{ width: '245px' }}
              placeholder={t('userAnalyticsPage.reviewsWithComments.flagsAndCommentsPlaceholder')}
              mode="multiple"
              onChange={commentsRatingFlags => updateFilters({ commentsRatingFlags })}
              value={filters.commentsRatingFlags || []}
              optionLabelProp="label"
              ref={flagSelectRef}
              allowClear
              filterOption={(input, option) =>
                selectSearch({ input, option, searchProp: 'searchValue' })
              }
            >
              <Option
                key="green"
                value="green"
                searchValue={t(FLAGS_COLORS_LITERALS_RUS.green)}
                label={<Icon icon={FlagFilled} color={FLAGS_TO_COLORS_RELATIONS.green} />}
              >
                <Icon icon={FlagFilled} color={FLAGS_TO_COLORS_RELATIONS.green} />
              </Option>
              <Option
                key="yellow"
                value="yellow"
                searchValue={t(FLAGS_COLORS_LITERALS_RUS.yellow)}
                label={<Icon icon={FlagFilled} color={FLAGS_TO_COLORS_RELATIONS.yellow} />}
              >
                <Icon icon={FlagFilled} color={FLAGS_TO_COLORS_RELATIONS.yellow} />
              </Option>
              <Option
                key="red"
                value="red"
                searchValue={t(FLAGS_COLORS_LITERALS_RUS.red)}
                label={<Icon icon={FlagFilled} color={FLAGS_TO_COLORS_RELATIONS.red} />}
              >
                <Icon icon={FlagFilled} color={FLAGS_TO_COLORS_RELATIONS.red} />
              </Option>
              <Option
                key="empty"
                value="empty"
                searchValue={t(FLAGS_COLORS_LITERALS_RUS.gray)}
                label={<Icon icon={MessageFilled} color={FLAGS_TO_COLORS_RELATIONS.without_flag} />}
              >
                <Icon icon={MessageFilled} color={FLAGS_TO_COLORS_RELATIONS.without_flag} />
              </Option>
            </Select>
          </SCol>
        </Row>
      </Col>
      <Col span={24}>
        <PerfectScrollbar
          onScrollY={onScrollY}
          options={{ suppressScrollX: true }}
          className="reviews-with-comments-container"
        >
          {reviews.map(review => (
            <ReviewWithComments className="review">
              <ReviewPreview review={review} onClick={onLinkClick(review.id)} />
              <CommentsList comments={getReviewComments(review)} allowActions={false} />
            </ReviewWithComments>
          ))}
          {loading && (
            <Row type="flex" justify="center">
              <Col>
                {/* <Spin style={{ margin: '0 auto' }} /> */}
                <Text type="secondary">
                  {t('userAnalyticsPage.reviewsWithComments.loading.load')}
                </Text>
              </Col>
            </Row>
          )}
          {!loading && !hasNextPage && isEmpty(reviews) && (
            <Empty
              description={
                <Text type="secondary">
                  {t('userAnalyticsPage.reviewsWithComments.loading.empty')}
                </Text>
              }
            />
          )}

          {!loading && !hasNextPage && !isEmpty(reviews) && (
            <Row type="flex" justify="center">
              <Col>
                {/* <Spin style={{ margin: '0 auto' }} /> */}
                <Text type="secondary">
                  {t('userAnalyticsPage.reviewsWithComments.loading.all')}
                </Text>
              </Col>
            </Row>
          )}
        </PerfectScrollbar>
      </Col>
    </Row>
  );
};

const ReviewWithComments = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  width: calc(100% - 10px);
`;

const mapStateToProps = state => {
  const { page, filters, query, ids, totalPages, loading } = state.userReviewsWithCommentsList;
  const { loadingWidgets, widgets } = state.analyticsWidgets;
  const {
    currentUser,
    currentTimeRange,
    currentChecklistDefinitionId,
    dataString
  } = state.uiUserAnalyticsPage;
  const reviews = reduce(
    ids,
    (result, reviewId) => {
      const review = state.reviewsResource.byIds[reviewId];
      const comments = reduce(
        review.commentsIds,
        (result, commentId) => {
          const comment = state.commentsResource.byIds[commentId];
          return comment ? [...result, comment] : result;
        },
        []
      );
      if (isEmpty(comments)) return result;

      const direction = get(state.phoneCallsResource.byIds, `${review.phoneCallId}.direction`);
      const score = get(state.checklistsResource.byIds, `${review.checklistId}.score`);

      return [...result, { ...review, comments, direction, score }];
    },
    []
  );
  const userId = get(currentUser, 'id', undefined);
  const totalCount = get(widgets, [
    ANALYTICS_WIDGETS_NAMES.COMMENTS_COUNT_BY_OPERATORS,
    userId,
    'value'
  ]);
  return {
    query,
    reviews,
    loadingWidgets,
    widgets,
    hasNextPage: totalPages > page.number,
    userId,
    currentTimeRange,
    filters,
    dataString,
    page,
    loading: loading || state.commentsResource.loading || state.reviewsResource.loading,
    totalCount,
    checklistDefinitionId: currentChecklistDefinitionId,
    currentUserPermissions: getCurrentUserPermissions(state)
  };
};

const mapDispatchToProps = {
  updatePage: actions.updatePage,
  loadReviews: operations.load,
  loadComments: commentsResource.operations.load,
  clearList: actions.clearList,
  updateFilters: actions.updateFilters,
  setDataString,
  loadAnalyticsWidgets,
  setCommunication,
  setQuery: actions.setCurrentQuery
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReviewsWithComments));
