import { Col, Menu, Row } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, Redirect, Switch, Route, useParams } from 'react-router-dom';
import styled from 'styled-components';
import Connections from './Connections';
import Employees from './Employees';
import NotificationsAndStatuses from './NotificationsAndStatuses/NotificationsAndStatuses';
import PermissionsAndLevels from './PermissionsAndLevels/PermissionsAndLevels';
import StructureTab from './Structure';

const OrganizationSettingsPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { tab } = useParams();

  return (
    <Row gutter={[0, 24]} style={{ marginTop: '-24px' }}>
      <Col span={24}>
        <StyledMenu onClick={({ key }) => history.push(key)} selectedKeys={[tab]} mode="horizontal">
          <Menu.Item key="units">{t('organizationStructure.tabs.units.title')}</Menu.Item>
          <Menu.Item key="employees">{t('organizationStructure.tabs.employees.title')}</Menu.Item>
          <Menu.Item key="roles-levels">{t('organizationStructure.tabs.roles.title')}</Menu.Item>
          <Menu.Item key="notifications-statuses">
            {t('organizationStructure.tabs.notifications.title')}
          </Menu.Item>
          <Menu.Item key="connections">
            {t('organizationStructure.tabs.connections.title')}
          </Menu.Item>
        </StyledMenu>
      </Col>
      <Col span={24}>
        <Switch>
          <Redirect
            from="/user/:id/organization-settings"
            exact
            to="/user/:id/organization-settings/units"
          />
          <Route path="/user/:id/organization-settings/units">
            <StructureTab />
          </Route>
          <Route path="/user/:id/organization-settings/employees">
            <Employees />
          </Route>
          <Route path="/user/:id/organization-settings/roles-levels">
            <PermissionsAndLevels />
          </Route>
          <Route path="/user/:id/organization-settings/notifications-statuses">
            <NotificationsAndStatuses />
          </Route>
          <Route path="/user/:id/organization-settings/connections">
            <Connections />
          </Route>
        </Switch>
      </Col>
    </Row>
  );
};

const StyledMenu = styled(Menu)`
  && {
    background: transparent;
    border-bottom: 2px solid var(--black_4);
    display: flex;
    justify-content: space-between;
    color: var(--black_4);

    &::before,
    &::after {
      display: none;
    }

    & > li.ant-menu-item {
      margin: 0;
      margin-bottom: -1px;

      &.ant-menu-item-active,
      &.ant-menu-item-selected {
        color: black;
        /* border-color: var(--black_8); */
      }
    }
  }
`;

export default OrganizationSettingsPage;
