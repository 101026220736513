import api from 'core/api';
import { processJsonApiObject } from 'core/jsonapi';
import decamelize from 'decamelize-keys-deep';
import { clientInteractionsResource } from 'redux/resources/clientInteractions';
import * as actions from './reducer';

export const createCustomCommunication = params => {
  return async dispatch => {
    dispatch(actions.setLoading(true));
    try {
      const response = await api.createCustomCommunication(decamelize(params));
      const result = processJsonApiObject(response.body.data);
      dispatch(clientInteractionsResource.actions.createSucceed(result));
      dispatch(actions.setLoading(false));
      return result;
    } catch (error) {
      console.log(error);
      dispatch(actions.setLoading(false));
      dispatch(actions.catchError(error));
    }
  };
};
