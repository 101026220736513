import React from 'react';
import { connect } from 'react-redux';
import WidgetTable from 'components/Dashboard/SmallWidgetTable';
import { get } from 'lodash';
import { updateActiveChartsViewItem } from 'redux/ui/dashboard/reducer';

const UsersTable = ({ tableData, widgetId, selectedUserId, updateActiveChartsViewItem }) => {
  return (
    <WidgetTable
      dataSource={tableData.tableRows}
      bordered={false}
      columns={tableData.tableColumns}
      pagination={false}
      size="small"
      showSorterTooltip={false}
      scroll={{ y: 462 }}
      onRow={(record, rowIndex) => {
        return {
          onClick: event => {
            updateActiveChartsViewItem({
              [widgetId]: {
                id: widgetId,
                selectedUserId: selectedUserId === record.id ? '' : record.id
              }
            });
          }
        };
      }}
    />
  );
};

const mapStateToProps = (state, ownProps) => {
  const { widgetId } = ownProps;
  return {
    selectedUserId: get(state, `uiDashboard.chartsView.widgets.${widgetId}.selectedUserId`, {})
  };
};

const mapDispatchToProps = {
  updateActiveChartsViewItem
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersTable);
