import { Col, Row, Typography } from 'antd';
import SCard from 'components/Standard/SCard';
import UserPreview from 'components/UserPreview';
import { getUserInitials } from 'components/UserPreview/getUserName';
import { isEqual } from 'lodash';
import React from 'react';
import { getCurrentUser, getUserWithRoleAndUnitById } from 'redux/selectors/users';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import SRow from 'components/Standard/SRow';
import SCol from 'components/Standard/SCol';
import UploadAvatar from './UploadAvatar';

const { Text } = Typography;

const UserInfoPanel = ({ loading }) => {
  const { t } = useTranslation();

  const viewedUser = useSelector(
    state => getUserWithRoleAndUnitById(state, state.uiUserAnalyticsPage.currentUser?.id),
    isEqual
  );

  const currentUser = useSelector(state => getCurrentUser(state));

  console.log(currentUser);

  if (loading || !viewedUser) {
    return <SCard loading={loading} />;
  }

  return (
    <SCard bodyPadding="24px">
      <SRow
        type="flex"
        align="middle"
        justify="middle"
        gutter={[0, 24]}
        style={{ marginBottom: '-12px' }}
      >
        <SCol display="flex" justifyContent="center" span={24} flexDirection="column">
          <SRow gutter={[12, 12]} style={{ marginBottom: '-6px' }}>
            <SCol display="flex" justifyContent="center">
              <UserPreview
                size="large"
                userId={viewedUser?.id}
                disabled
                disabledInitials
                showAvatar
              >
                {getUserInitials(viewedUser)}
              </UserPreview>
              {viewedUser?.id === currentUser?.id && (
                <UploadAvatar userAvatarUrl={viewedUser?.avatarUrl} />
              )}
            </SCol>
            <SCol display="flex" justifyContent="center">
              <SRow
                gutter={[4, 4]}
                style={{ margin: '-2px' }}
                flexDirection="column"
                justify="center"
              >
                <SCol display="flex" alignItems="flex-end">
                  <UserName>{viewedUser?.name}</UserName>
                </SCol>
                <SCol display="flex">
                  <UserRole>{viewedUser?.role?.name}</UserRole>
                </SCol>
              </SRow>
            </SCol>
          </SRow>
        </SCol>

        <SCol>
          <Row gutter={[0, 20]} style={{ margin: '0' }}>
            <SCol display="flex" justifyContent="center" span={24}>
              <SRow align="middle" width="100%" justify="space-between" flexWrap="nowrap">
                <TruncatedColumn span={24}>
                  <Text type="secondary">{`${viewedUser?.unit?.name}`}</Text>
                </TruncatedColumn>
              </SRow>
            </SCol>

            {/* <SCol display="flex" justifyContent="center" span={24}>
              <SRow align="middle" width="100%" justify="space-between" flexWrap="nowrap">
                <SCol paddingRight="8px">
                  <Text type="secondary">
                    {t('userSettingsPage.tabs.info.form.registrationDate')}
                  </Text>
                </SCol>
                <TruncatedColumn>
                  <Text type="secondary">{`${moment(user.createdAt).format('DD/MM/YYYY')}`}</Text>
                </TruncatedColumn>
              </SRow>
            </SCol> */}

            <SCol display="flex" justifyContent="center" span={24}>
              <SRow align="middle" width="100%" justify="space-between" flexWrap="nowrap">
                <TruncatedColumn span={24}>
                  <Text type="secondary">{`${viewedUser?.email}`}</Text>
                </TruncatedColumn>
              </SRow>
            </SCol>
          </Row>
        </SCol>
      </SRow>
    </SCard>
  );
};

const UserName = styled(Text)`
  &.ant-typography {
    font-size: 16px;
    font-weight: 500;
    color: var(--text);
  }
`;

const UserRole = styled(Text)`
  &.ant-typography {
    font-size: 14px;
    font-weight: 600;
    color: var(--black_6);
  }
`;

const TruncatedColumn = styled(Col)`
  overflow: hidden;
  max-width: 50%;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export default UserInfoPanel;
