export default {
  workPlanTasks: {
    taskPlaceholder: 'Tickets',
    colorizedNumbers: {
      completed: 'Número de controles realizados',
      incompleted: 'Número de cheques vencidos',
      total: 'Número de cheques restantes'
    },
    tableColumns: {
      reviewsCount: 'Evaluaciones',
      reviewsCount2: 'Evaluaciones',
      completed: 'Realizado',
      incompleted: 'Izquierda',
      closeTask: 'Completa la tarea',
      stopRepeating: 'Detener repeticiones',
      name: 'Nombre de la tarea',
      lastTaskTimeFrom: 'La fecha del comienzo',
      lastTaskTimeTo: 'Fecha de realización',
      creator: 'El creador',
      schedule: 'Repetición',
      repeatedCount: 'Lanza',
      reviewCreatedAt: 'Fecha y hora de la evaluación'
    },
    assignments: {
      closeTask: 'Completa la tarea',
      close: 'Terminar',
      taskStartedAt: 'Fecha de lanzamiento',
      assignmentsCount: 'Numero de inspectores',
      completedTaskItemsCount: 'Número total de cheques'
    },
    filters: { createTask: 'Crear tarea' },
    confirmStopRepeating: 'Detener repeticiones?',
    confirmClosing: 'Completa la tarea?',
    confirmDelete: '¿Eliminar configuración?'
  }
};
