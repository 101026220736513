import { Copy, Trash2, Edit, Menu as MenuIcon, MoreVertical, Plus, Share2 } from 'react-feather';
import { Button, Col, Dropdown, Menu, message, Modal, Row, Select } from 'antd';
import EditableLabel from 'components/Inputs/EditableLabel';
import { SYMBOLIC_TIME_RANGE } from 'core/utils/constants';
import ExcelIcon from 'images/ExcelIcon';
import { get, isEmpty } from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { exportCommunicationTable } from 'redux/entities/exports/operations';
import { actions } from 'redux/lists/clientInteractionsList';
import { communicationTablesResource } from 'redux/resources/communicationTables';
import {
  createCommunicationTable,
  deleteCommunicationTable
} from 'redux/ui/clientInteractions/operations';
import {
  setActiveTable,
  toogleVisibleDrawer,
  toogleVisibleShareSettings,
  updateActiveTable
} from 'redux/ui/clientInteractions/reducer';
import Icon from 'components/Icon';

const Tables = ({
  toogleVisibleShareSettings,
  communicationTablesByIds,
  setActiveTable,
  updateActiveTable,
  tableId,
  tableName,
  currentUserId,
  usersByIds,
  setFilters,
  toogleVisibleDrawer,
  updateCommunicationTable,
  createCommunicationTable,
  deleteCommunicationTable,
  exportCommunicationTable,
  tableColumns,
  tableFilters,
  disabled,
  loadingCommunicationTables
}) => {
  const [isEditing, setEditing] = useState(false);

  const { Option } = Select;
  const { confirm } = Modal;
  const { t } = useTranslation();

  const communicationTablesCount = Object.values(communicationTablesByIds).length;

  const copyTable = async () => {
    try {
      const newTable = await createCommunicationTable({
        name: `${communicationTablesByIds[tableId]?.name} ${t(
          'clientInteractionsPage.drawer.tables.strings.copyString'
        )}`,
        columns: communicationTablesByIds[tableId]?.columns,
        filters: communicationTablesByIds[tableId]?.filters
      });
      message.success(t('clientInteractionsPage.drawer.tables.messages.tableSuccessfullyCopied'));
      localStorage.setItem('DEALAPP_ACTIVE_TABLE_ID', newTable.id);
    } catch (error) {
      console.log(error);
      message.error(t('clientInteractionsPage.drawer.tables.messages.copyTableFailed'));
    }
  };

  const updateTable = async ({ id, name }) => {
    try {
      const result = await updateCommunicationTable({ id, name });
      if (result) {
        message.success(
          t('clientInteractionsPage.drawer.tables.messages.tableSuccessfullyUpdated')
        );
      } else {
        message.error(t('clientInteractionsPage.drawer.tables.messages.updateTableFailed'));
      }
    } catch (error) {
      console.log(error);
      message.error(t('clientInteractionsPage.drawer.tables.messages.updateTableFailed'));
    }
  };

  const deleteTable = ({ id }) => {
    confirm({
      title: t('clientInteractionsPage.drawer.tables.confirmDelete.title'),
      okText: t('clientInteractionsPage.drawer.tables.confirmDelete.ok'),
      cancelText: t('clientInteractionsPage.drawer.tables.confirmDelete.cancel'),
      content: t('clientInteractionsPage.drawer.tables.confirmDelete.description'),
      onOk: async () => {
        try {
          const result = deleteCommunicationTable({ id });
          if (result) {
            message.success(
              t('clientInteractionsPage.drawer.tables.messages.tableSuccessfullyDeleted')
            );
          } else {
            message.error(t('clientInteractionsPage.drawer.tables.messages.deleteTableFailed'));
          }
        } catch (error) {
          console.log(error);
          message.error(t('clientInteractionsPage.drawer.tables.messages.deleteTableFailed'));
        }
      }
    });
  };

  const exportTable = async () => {
    try {
      const result = await exportCommunicationTable({
        column_names: tableColumns,
        filters: tableFilters
      });
      if (get(result, 'status', 200) === 200) {
        Modal.info({
          title: t('clientInteractionsPage.drawer.tables.messages.tableSuccessfullyExported'),
          content: t(
            'clientInteractionsPage.drawer.tables.messages.tableSuccessfullyExportMessage'
          ),
          onOk: () => {}
        });
      } else {
        message.error(t('clientInteractionsPage.drawer.tables.messages.exportTableFailed'));
      }
    } catch (error) {
      console.log(error);
      message.error(t('clientInteractionsPage.drawer.tables.messages.exportTableFailed'));
    }
  };

  const createTable = async () => {
    try {
      const newTable = await createCommunicationTable({
        name:
          Object.keys(communicationTablesByIds).length === 0
            ? t('clientInteractionsPage.drawer.tables.strings.newTableString')
            : `${t('clientInteractionsPage.drawer.tables.strings.newTableString')} ${
                Object.keys(communicationTablesByIds).length
              }`,
        columns: [],
        filters: {
          clientInteractionSymbolicTimeRange: SYMBOLIC_TIME_RANGE.THIS_MONTH
        }
      });
      message.success(t('clientInteractionsPage.drawer.tables.messages.tableSuccessfullyCreated'));
      setFilters({ clientInteractionSymbolicTimeRange: SYMBOLIC_TIME_RANGE.THIS_MONTH });
      localStorage.setItem('DEALAPP_ACTIVE_TABLE_ID', newTable.id);
    } catch (error) {
      console.log(error);
      message.error(t('clientInteractionsPage.drawer.tables.messages.createTableFailed'));
    }
  };

  const handleMenuClick = ({ key }) => {
    const actions = {
      createTable,
      editTable: () => setEditing(!isEditing),
      editTableColumns: toogleVisibleDrawer,
      copyTable,
      shareTable: toogleVisibleShareSettings,
      exportTable,
      deleteTable: () => deleteTable({ id: tableId })
    };

    return actions[key]();
  };

  const tableCreatorId = get(communicationTablesByIds, `${tableId}.creatorId`, '');

  const userOwnsTable = tableCreatorId === currentUserId;

  const menu = (
    <Menu onClick={e => handleMenuClick({ key: e.key })}>
      <Menu.Item key="createTable">
        <Icon icon={Plus} />
        <span>{t('clientInteractionsPage.drawer.tables.menu.addConfig')}</span>
      </Menu.Item>
      <Menu.Item key="editTable" disabled={!userOwnsTable}>
        <Icon icon={Edit} />
        <span>{t('clientInteractionsPage.drawer.tables.menu.editTable')}</span>
      </Menu.Item>
      <Menu.Item key="editTableColumns" disabled={!userOwnsTable}>
        <Icon icon={MenuIcon} rotate={90} />
        <span>{t('clientInteractionsPage.drawer.tables.menu.editTableColumns')}</span>
      </Menu.Item>
      <Menu.Item key="copyTable">
        <Icon icon={Copy} />
        <span>{t('clientInteractionsPage.drawer.tables.menu.copyTable')}</span>
      </Menu.Item>
      <Menu.Item key="shareTable" disabled={!userOwnsTable}>
        <Icon icon={Share2} />
        <span>{t('clientInteractionsPage.drawer.tables.menu.shareTable')}</span>
      </Menu.Item>
      <Menu.Item key="exportTable" disabled={!userOwnsTable}>
        <ExcelIcon />
        <span style={{ marginLeft: 8 }}>
          {t('clientInteractionsPage.drawer.tables.menu.export')}
        </span>
      </Menu.Item>
      {userOwnsTable && (
        <Menu.Item
          key="deleteTable"
          style={communicationTablesCount > 1 && { color: 'red' }}
          disabled={communicationTablesCount <= 1}
        >
          <Icon icon={Trash2} />
          <span>{t('clientInteractionsPage.drawer.tables.menu.deleteTable')}</span>
        </Menu.Item>
      )}
    </Menu>
  );

  const handleChangeTable = value => {
    setFilters(communicationTablesByIds[value]?.filters);
    setActiveTable({
      id: value,
      ...communicationTablesByIds[value]
    });
    try {
      localStorage.setItem('DEALAPP_ACTIVE_TABLE_ID', value);
    } catch (error) {
      console.log(error);
    }
  };

  const renderTable = ({ creatorId, usersIds = [], name, id }) => {
    let tableName;

    if (creatorId === currentUserId && !isEmpty(usersIds))
      tableName = `${name} ${t('clientInteractionsPage.drawer.tables.strings.openAsccess')}`;

    if (isEmpty(!usersIds) && usersIds.includes(currentUserId))
      tableName = `${name} (${t('clientInteractionsPage.drawer.tables.strings.author')}: ${
        usersByIds[creatorId]?.name
      })`;

    if (isEmpty(usersIds)) tableName = `${name}`;

    return (
      <Option value={id} key={id}>
        {tableName}
      </Option>
    );
  };

  if (isEmpty(communicationTablesByIds)) {
    return (
      <Button block onClick={createTable} disabled={loadingCommunicationTables}>
        {t('clientInteractionsPage.drawer.tables.buttons.addConfig')}
      </Button>
    );
  }

  return (
    <Row type="flex" align="middle" justify="space-between">
      {isEditing ? (
        <Col span={24}>
          <EditableLabel
            initialState="edit"
            value={tableName}
            onSave={(name, { final }) => {
              updateActiveTable({ tableName: name });
              if (final) {
                updateTable({ id: tableId, name: tableName });
                setEditing(!isEditing);
              }
            }}
          />
        </Col>
      ) : (
        <Col span={24}>
          <Row type="flex" align="middle" justify="space-between">
            <Col span={20}>
              <Select
                placeholder={t('clientInteractionsPage.drawer.tables.selectTablePlaceholder')}
                onChange={handleChangeTable}
                value={tableId}
                style={{ width: '100%' }}
                disabled={disabled}
              >
                {Object.values(communicationTablesByIds).map(renderTable)}
              </Select>
            </Col>
            <Col>
              <Dropdown
                overlay={menu}
                trigger={['click']}
                placement="bottomLeft"
                disabled={disabled}
              >
                <Button icon={<Icon icon={MoreVertical} />} />
              </Dropdown>
            </Col>
          </Row>
        </Col>
      )}
    </Row>
  );
};

const mapStateToProps = state => {
  const {
    tableId,
    tableName,
    tableColumns,
    tableFilters,
    tableLoading
  } = state.uiClientInteractions;

  return {
    currentUserId: state.reduxTokenAuth.currentUser.attributes.id,
    usersByIds: state.usersResource.byIds,
    tableId,
    tableName,
    tableColumns,
    tableFilters,
    communicationTablesByIds: state.communicationTablesResource.byIds,
    loadingCommunicationTables: state.communicationTablesResource.loading,
    disabled: tableLoading || isEmpty(tableId)
  };
};

const mapDispatchToProps = {
  createCommunicationTable,
  updateCommunicationTable: communicationTablesResource.operations.updateById,
  deleteCommunicationTable,
  toogleVisibleShareSettings,
  setActiveTable,
  updateActiveTable,
  setFilters: actions.setFilters,
  toogleVisibleDrawer,
  exportCommunicationTable
};

export default connect(mapStateToProps, mapDispatchToProps)(Tables);
