import { OutlinedButton } from 'components/Buttons';
import SButton from 'components/Standard/SButton';
import SCard from 'components/Standard/SCard';
import SCol from 'components/Standard/SCol';
import SRow from 'components/Standard/SRow';
import { every, get, isEmpty, isEqual, pickBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { message, Modal } from 'antd';
import { workPlanTaskConfigurationsResource } from 'redux/resources/workPlanTaskConfigurations';
import { actions } from 'redux/lists/workPlanTaskConfigurationsListReducer';
import { WORK_PLAN_TASK_CONFIGURATIONS_STATUSES } from 'core/utils/constants';
import { useTranslation } from 'react-i18next';
import FiltersSettings from './ConfigurationEditorModules/FiltersSettings';
import ScheduleSettings from './ConfigurationEditorModules/ScheduleSettings';
import Reviewers from './ConfigurationEditorModules/Reviewers';
import { loadConfigurationToUi, mapUiDataForRequest } from './operations';

const createConfiguration = workPlanTaskConfigurationsResource.operations.create;
const runConfiguration = workPlanTaskConfigurationsResource.operations.runWorkPlanTaskConfiguration;
const updateConfiguration = workPlanTaskConfigurationsResource.operations.updateById;

const WorkPlanTaskConfigurationEditor = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState();
  const [schedule, setSchedule] = useState({});
  const [clientInteractionsTypesConfigs, setClientInteractionsConfigs] = useState({});
  const [distribution, setDistribution] = useState({});
  const [reviewersIds, setReviewersIds] = useState([]);
  const dispatch = useDispatch();
  const reviewerBindingsByIds = useSelector(
    state => pickBy(state.workPlanReviewerBindingsResource.byIds, { taskConfigurationId: id }),
    isEqual
  );
  const isNew = id === 'new';

  const configurationStatus = useSelector(state =>
    isNew
      ? WORK_PLAN_TASK_CONFIGURATIONS_STATUSES.draft.value
      : get(
          state.workPlanTaskConfigurationsResource.byIds,
          [id, 'status'],
          WORK_PLAN_TASK_CONFIGURATIONS_STATUSES.draft.value
        )
  );

  const loadConfiguration = async id => {
    setLoading(true);
    const {
      name,
      schedule,
      clientInteractionsTypesConfigs,
      distribution,
      reviewersIds
    } = await dispatch(loadConfigurationToUi(id));
    setName(name);
    setSchedule(schedule);
    setClientInteractionsConfigs(clientInteractionsTypesConfigs);
    setDistribution(distribution);
    setReviewersIds(reviewersIds);
    setLoading(false);
  };

  useEffect(() => {
    if (id && !isNew) {
      // do request
      loadConfiguration(id);
    }
  }, [id]);

  const onDelete = () => {
    return Modal.confirm({
      destroyOnClose: true,
      closable: true,
      title: t('workPlanTaskConfigurationEditor.delete.title'),
      cancelText: t('organizationStructure.tabs.units.confirmDelete.cancel'),
      okText: t('organizationStructure.tabs.units.confirmDelete.ok'),
      okType: 'danger',
      onOk: async () => {
        try {
          const res = await dispatch(
            workPlanTaskConfigurationsResource.operations.deleteById({ id })
          );
          if (res) {
            dispatch(actions.onConfigurationDelete({ id }));
            message.success(t('workPlanTaskConfigurationEditor.delete.taskDeleted'));
            history.push('/work-plan/task-configurations');
          }
        } catch (error) {
          console.log(error);
          message.error(t('workPlanTaskConfigurationEditor.delete.cantDeleteTask'));
        }
      }
    });
  };

  const isScheduleValid = !isEmpty(
    schedule?.periodTimeFrom &&
      schedule?.periodTimeTo &&
      schedule?.dayTimeFrom &&
      schedule?.dayTimeTo
  );

  const isFiltersValid =
    !isEmpty(clientInteractionsTypesConfigs) &&
    every(
      clientInteractionsTypesConfigs,
      ({ clientInteractionsMaxCount }) => clientInteractionsMaxCount > 0
    );

  const isDistributionValid =
    !isEmpty(distribution) &&
    every(Object.values(distribution), ({ totalDistributed }) => totalDistributed === 100);

  const isValid = name && isScheduleValid && isFiltersValid && isDistributionValid;

  const onSave = async () => {
    try {
      const data = mapUiDataForRequest({
        name,
        schedule,
        clientInteractionsTypesConfigs,
        distribution,
        isNew,
        reviewerBindingsByIds
      });

      const configuration = await dispatch(
        isNew ? createConfiguration(data) : updateConfiguration({ ...data, id })
      );

      if (configuration) {
        message.success(t('workPlanTaskConfigurationEditor.taskSaved'));

        if (isNew) {
          history.push(`/work-plan/task-configurations/editor/${configuration.id}`);
        }

        return configuration;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onRun = async () => {
    let configurationId = id;
    if (isNew) {
      const configuration = await onSave();
      configurationId = configuration?.id;
    }

    try {
      if (configurationId) {
        const res = await dispatch(runConfiguration({ id: configurationId }));

        if (res) {
          message.success(t('workPlanTaskConfigurationEditor.taskStarted'));
          history.push(`/work-plan/task-configurations/${configurationId}/tasks`);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const canRun = [
    WORK_PLAN_TASK_CONFIGURATIONS_STATUSES.draft.value,
    WORK_PLAN_TASK_CONFIGURATIONS_STATUSES.stopped.value
  ].includes(configurationStatus);

  if (loading) {
    return (
      <SCol span={24} padding="12px">
        <SCard loading />
      </SCol>
    );
  }
  return (
    <SRow gutter={[0, 24]} style={{ marginBottom: '-12px' }} padding="36px">
      <SCol span={24}>
        <ScheduleSettings
          isNew={isNew}
          name={name}
          onChangeName={setName}
          onChange={setSchedule}
          schedule={schedule}
          isScheduleValid={isScheduleValid}
        />
      </SCol>

      <SCol span={24}>
        <FiltersSettings
          onChange={setClientInteractionsConfigs}
          clientInteractionsConfigs={clientInteractionsTypesConfigs}
        />
      </SCol>

      <SCol span={24}>
        <Reviewers
          onChange={setDistribution}
          isFiltersValid={isFiltersValid}
          clientInteractionsTypesConfigs={clientInteractionsTypesConfigs}
          distribution={distribution}
          reviewersIds={reviewersIds}
        />
      </SCol>

      <SCol span={24}>
        <SCard shadowed bordered>
          <SRow justify="end" align="middle">
            <SCol>
              <SRow gutter={[20, 0]}>
                {!isNew && (
                  <SCol>
                    <SButton type="link" padding="0" size="big" width="auto" onClick={onDelete}>
                      {t('workPlanTaskConfigurationEditor.deleteTask')}
                    </SButton>
                  </SCol>
                )}

                <SCol>
                  <OutlinedButton size="big" disabled={!isValid} onClick={onSave}>
                    {t('workPlanTaskConfigurationEditor.saveTask')}
                  </OutlinedButton>
                </SCol>

                {canRun && (
                  <SCol>
                    <SButton type="primary" size="big" disabled={!isValid} onClick={onRun}>
                      {t('workPlanTaskConfigurationEditor.startTask')}
                    </SButton>
                  </SCol>
                )}
              </SRow>
            </SCol>
          </SRow>
        </SCard>
      </SCol>
    </SRow>
  );
};

export default WorkPlanTaskConfigurationEditor;
