import React from 'react';
import { AlertCircle } from 'react-feather';
import Icon from 'components/Icon';
import { Col, Empty, Row, Typography, Spin, Tooltip } from 'antd';
import { connect } from 'react-redux';
import { isNil, isEmpty, get } from 'lodash';
import { useTranslation } from 'react-i18next';
import SettingsModal from './SettingsModal';
import { widgetNameSetter, customEmptyDashboardRulesWidgetTypes } from '../utils';
import {
  EmptyWidgetCard,
  StyledEmpty,
  StyledErrorMessage,
  SettingsButtonCol,
  WidgetNameCol
} from '../styled';

const EmptyWidget = ({ widgetData, widgetsAnalyticsByIds, loadingWidgetId, outdated }) => {
  const { size, type, filters, name } = widgetData;
  const { Text } = Typography;

  const widgetValue = get(widgetsAnalyticsByIds, widgetData.id, []);
  const { t } = useTranslation();

  const uninstalledWidget =
    (isEmpty(widgetValue) && isEmpty(widgetData.filters)) ||
    widgetValue === 'Widget does not implemented or you make mistake in widget name!';

  const emptyWidgetData =
    (isEmpty(widgetValue) && !isEmpty(widgetData.filters)) ||
    customEmptyDashboardRulesWidgetTypes.includes(type);

  const symbolicSizeToWidth = size => {
    switch (size) {
      case 'small':
        return 4;
      case 'medium':
        return 6;
      case 'large':
        return 12;
      case 'extra_large':
        return 24;
      default:
        return 6;
    }
  };

  const setEmptyWidgetName = (type, filters, name) => {
    if (isEmpty(type)) return t('dashboardPage.widget.emptyName');
    if (isNil(name) || isEmpty(name)) {
      return widgetNameSetter({ name, type });
    }
    return name;
  };

  const outdatedWidgetTypeToDescription = {
    rating_flags_count: t('dashboardPage.widget.outdated.outdatedFlagsAndCommentsWidgets'),
    rating_flags_count_by_units: t('dashboardPage.widget.outdated.outdatedFlagsAndCommentsWidgets'),
    rating_flags_count_by_operators: t(
      'dashboardPage.widget.outdated.outdatedFlagsAndCommentsWidgets'
    )
  };

  const renderDescription = () => {
    if (outdated) {
      return (
        <>
          <StyledErrorMessage level={3} type="secondary">
            {t('dashboardPage.widget.outdated.title')}
          </StyledErrorMessage>
          <Text type="secondary">{outdatedWidgetTypeToDescription[type]}</Text>
        </>
      );
    }
    if (uninstalledWidget) {
      return (
        <>
          <StyledErrorMessage level={3} type="secondary">
            {t('dashboardPage.widget.filtersIsNotInstalled')}
          </StyledErrorMessage>
          <Text type="secondary">{t('dashboardPage.widget.needToInstallFilters')}</Text>
        </>
      );
    }
    if (emptyWidgetData) {
      return (
        <>
          <StyledErrorMessage level={3} type="secondary">
            {t('dashboardPage.widget.checkFilters')}
          </StyledErrorMessage>
          <Text type="secondary">{t('dashboardPage.widget.emptyFiltersData')}</Text>
        </>
      );
    }
  };

  return (
    <Col span={symbolicSizeToWidth(size)}>
      <Spin spinning={loadingWidgetId === widgetData.id}>
        <EmptyWidgetCard size={size}>
          <Row type="flex">
            <WidgetNameCol span={22}>
              <Tooltip title={setEmptyWidgetName(type, filters, name)}>
                <span>{setEmptyWidgetName(type, filters, name)}</span>
              </Tooltip>
            </WidgetNameCol>
            <SettingsButtonCol span={2}>
              <SettingsModal widgetData={widgetData} />
            </SettingsButtonCol>
          </Row>
          <StyledEmpty
            image={
              outdated ? (
                <Icon icon={AlertCircle} color="var(--red_primary)" size="60px" />
              ) : (
                Empty.PRESENTED_IMAGE_SIMPLE
              )
            }
            description={size === 'small' ? false : renderDescription()}
          />
        </EmptyWidgetCard>
      </Spin>
    </Col>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    widgetsAnalyticsByIds: state.dashboardAnalytics.byIds
  };
};

export default connect(mapStateToProps, null)(EmptyWidget);
