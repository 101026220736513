import { LoadingOutlined } from '@ant-design/icons';
import Editor from 'components/Comments/Editor';
import Icon from 'components/Icon';
import SButton from 'components/Standard/SButton';
import SCol from 'components/Standard/SCol';
import SRow from 'components/Standard/SRow';
import { isEmpty } from 'lodash';
import React, { useState, useRef } from 'react';
import { Send } from 'react-feather';

const ActionCommentForm = ({ onSend }) => {
  const editorRef = useRef(null);
  const [sendingComment, setSendingComment] = useState(false);
  const [commentState, setCommentState] = useState({ text: '', uploadedFiles: [] });
  const isEmptyComment = isEmpty(commentState.uploadedFiles)
    ? isEmpty(commentState.text?.trim())
    : false;

  const handleSend = async () => {
    setSendingComment(true);
    try {
      await onSend(commentState);
      setCommentState({ text: '', uploadedFiles: [] });
      editorRef.current.setEditorState({ text: '' });
    } catch (error) {
      console.log(error);
    }

    setSendingComment(false);
  };

  return (
    <SRow align="top" gutter={[14, 0]}>
      <SCol span={16}>
        <Editor
          commentState={commentState}
          setCommentState={setCommentState}
          showFlags={false}
          showTemplates
          showAttachments
          ref={editorRef}
        />
      </SCol>
      <SCol paddingTop="52px">
        <SButton
          size="icon"
          icon={sendingComment ? <LoadingOutlined /> : <Icon icon={Send} size="24px" />}
          border="none"
          boxShadow="none"
          color="var(--blue_6)"
          onClick={handleSend}
          disabled={isEmptyComment || sendingComment}
          style={{ backgroundColor: 'transparent' }}
        />
      </SCol>
    </SRow>
  );
};

export default ActionCommentForm;
