export default {
  menu: {
    appMenu: {
      analytics: 'Analítica',
      communications: 'Lista de comunicaciones',
      tasks: 'Tareas',
      library: 'Biblioteca de llamadas',
      addReview: 'Nuevo cheque',
      team: 'Equipo',
      settings: 'Herramientas',
      checklistsSettins: 'Formularios de evaluación',
      tagsSettings: 'Etiquetas',
      calibrations: 'Sesiones de calibración',
      workPlanTaskConfigurations: 'Tareas',
      appeals: 'Apelaciones'
    },
    userMenu: {
      profile: 'Área personal',
      profileSettings: 'Configuración de perfil',
      organizationSettings: 'Configuración de la organización',
      organizationStructureSettings: 'Empleados y derechos de acceso',
      integrationsSettings: 'Integración',
      logout: 'Salida'
    },
    helpMenu: {
      helpCenter: 'Centro de ayuda',
      updates: 'Ultimas actualizaciones',
      support: 'Soporte de contacto',
      hotKeys: 'Teclas de acceso rápido'
    },
    alerts: {
      rolesAlert: {
        title:
          'Papeles del Administrador y el Operador son básicos, sin posibilidad de borrarlos. Detalles de los papeles, derechos y ajustes ',
        button: 'aquí'
      }
    }
  }
};
