import React from 'react';
import { Row, Col } from 'antd';
import { ANALYTICS_WIDGETS_NAMES } from 'core/utils/constants';
import { get } from 'lodash';
import { loadAnalyticsWidgets } from 'redux/entities/analytics/widgets/operations';
import ChecklistHistoryModal from 'components/Checklist/ChecklistHistoryModal';
import ChecklistDefinitionAnalytics from './ChecklistDefinitionAnalytics';

const getWidgetData = ({ checklistDefinitionId, widgets }) => {
  const reviewsCount = get(widgets, [
    ANALYTICS_WIDGETS_NAMES.REVIEWS_COUNT_BY_CHECKLIST_DEFINITIONS,
    checklistDefinitionId,
    'value'
  ]);

  const averageScore = get(widgets, [
    ANALYTICS_WIDGETS_NAMES.AVERAGE_SCORE_BY_CHECKLIST_DEFINITIONS,
    checklistDefinitionId,
    'value'
  ]);

  const ratingFlags = get(widgets, [
    ANALYTICS_WIDGETS_NAMES.RATING_FLAGS_COUNT_BY_CHECKLIST_DEFINITIONS,
    checklistDefinitionId
  ]);

  const colorZones = get(widgets, [
    ANALYTICS_WIDGETS_NAMES.CHECKLIST_SCORES_COLOR_ZONE_BY_CHECKLIST_DEFINITIONS,
    checklistDefinitionId
  ]);

  return {
    reviewsCount,
    averageScore,
    ratingFlags,
    colorZones
  };
};

export const AllChecklistsAnalytics = ({ checklistDefinitions = [], widgets, loadingWidgets }) => {
  return (
    <Row gutter={[32, 16]}>
      {checklistDefinitions.reduce((acc, checklistDefinition) => {
        const widgetData = getWidgetData({
          checklistDefinitionId: checklistDefinition.id,
          widgets
        });

        if (!widgetData?.reviewsCount) return acc;

        const component = (
          <Col span={24}>
            <ChecklistDefinitionAnalytics
              showName
              checklistDefinition={checklistDefinition}
              reviewsCount={widgetData?.reviewsCount}
              averageScore={widgetData?.averageScore}
              ratingFlags={widgetData?.ratingFlags}
              colorZones={widgetData?.colorZones}
              loading={loadingWidgets}
            />
          </Col>
        );

        return [...acc, component];
      }, [])}

      <ChecklistHistoryModal widgets={widgets} loadingWidgets={loadAnalyticsWidgets} />
    </Row>
  );
};
