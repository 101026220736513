import React from 'react';
import { Typography } from 'antd';
import { Info } from 'components/Info';

const { Text } = Typography;

export default ({ children, infoTitle, regularText }) => {
  return (
    <Text strong={!regularText}>
      {`${children} `}
      <Info tooltip={{ title: infoTitle }} />
    </Text>
  );
};
