import React from 'react';
import { isEmpty, get } from 'lodash';
import { connect } from 'react-redux';
import { Col, Tooltip, Row } from 'antd';
import { ResponsivePie } from '@nivo/pie';
import { FLAGS_COLORS_LITERALS_RUS, FLAGS_TO_COLORS_RELATIONS } from 'core/utils/constants';
import { useTranslation } from 'react-i18next';
import SettingsModal from '../../Components/SettingsModal';
import {
  widgetNameSetter,
  widgetPeriodSetter,
  widgetInfoTextSetter,
  tooltipSetter,
  getDateSelectorType
} from '../../utils';
import EmptyWidget from '../../Components/EmptyWidget';
import { SettingsButtonCol, WidgetNameCol, WidgetCard, TimePeriod } from '../../styled';

const RatingFlagsCount = ({ widgetData, widgetsAnalyticsByIds, loadingWidgetId }) => {
  const { t } = useTranslation();
  const widgetValue = get(widgetsAnalyticsByIds, widgetData.id, []);

  const { id, type, filters, name } = widgetData;
  const dateSelectorType = getDateSelectorType({ widgetType: type });

  const prepareData = ({ widgetValue }) => {
    return Object.keys(widgetValue).map(item => ({
      id: item,
      value: widgetValue[item],
      color: FLAGS_TO_COLORS_RELATIONS[item]
    }));
  };

  return (
    <>
      {isEmpty(widgetValue) ||
      loadingWidgetId === id ||
      isEmpty(prepareData({ widgetValue }).filter(item => item.value)) ? (
        <EmptyWidget key={id} widgetData={widgetData} loadingWidgetId={loadingWidgetId} />
      ) : (
        <Col span={6}>
          <WidgetCard>
            <Row type="flex">
              <WidgetNameCol span={20}>
                {widgetInfoTextSetter({ type })}
                <Tooltip title={widgetNameSetter({ name, type })}>
                  <span>{widgetNameSetter({ name, type })}</span>
                </Tooltip>
                <TimePeriod type="secondary">
                  {widgetPeriodSetter(filters, dateSelectorType)}
                </TimePeriod>
              </WidgetNameCol>
              <SettingsButtonCol span={4}>
                <SettingsModal widgetData={widgetData} />
              </SettingsButtonCol>
            </Row>

            <ResponsivePie
              data={prepareData({ widgetValue })}
              margin={{ top: 20, right: 40, bottom: 60, left: 40 }}
              innerRadius={0.5}
              padAngle={0.7}
              cornerRadius={3}
              colors={shape => shape.color}
              tooltip={tooltip =>
                tooltipSetter({
                  name: t(FLAGS_COLORS_LITERALS_RUS[tooltip.id]),
                  value: tooltip.value
                })
              }
              borderWidth={1}
              borderColor={{ from: 'color' }}
              enableRadialLabels={false}
              slicesLabelsSkipAngle={10}
              slicesLabelsTextColor="#333333"
              animate
              motionStiffness={90}
              motionDamping={15}
            />
          </WidgetCard>
        </Col>
      )}
    </>
  );
};

const mapStateToProps = state => {
  return {
    widgetsAnalyticsByIds: state.dashboardAnalytics.byIds
  };
};

export default connect(mapStateToProps, null)(RatingFlagsCount);
