import React from 'react';
import { Typography, Tooltip } from 'antd';
import { sum, keyBy, isEmpty, orderBy, flattenDeep } from 'lodash';
import { WIDGET_VIEW_MODES } from 'core/utils/constants';
import { Trans } from 'react-i18next';
import { getUserName } from 'components/UserPreview/getUserName';
import moment from 'moment';
import { isSafari } from 'react-device-detect';
import UserPreview from 'components/UserPreview';
import { timeFormatter } from 'core/utils/timeFormatter';
import PeriodInfo from 'components/PeriodInfo';
import { getDatesArray } from '../../utils';

const { Text } = Typography;

export const prepareColumns = ({
  viewMode,
  isWeekendView,
  timeFrom,
  timeTo,
  historyGroupingPeriod
}) => {
  const firstColumnNameKey =
    viewMode === WIDGET_VIEW_MODES.REVIEWERS.value
      ? 'dashboardPage.tableReviewsCountByReviewers.firstColumnNames.reviewers'
      : 'dashboardPage.tableReviewsCountByReviewers.firstColumnNames.units';

  const datesArray = getDatesArray({
    timeFrom,
    timeTo,
    isWeekendView,
    historyGroupingPeriod
  });

  const borwserColumnWidgth = isSafari ? 105 : 90;

  const datesColumns = datesArray.map(day => ({
    title: (
      <PeriodInfo startDate={day} groupingPeriod={historyGroupingPeriod} isVisibleYear={false} />
    ),
    dataIndex: moment(day).format('YYYY-MM-DD'),
    key: moment(day).format('YYYY-MM-DD'),
    align: 'center',
    width: datesArray.length < 15 ? null : borwserColumnWidgth,
    render: (text, record) => <Text strong={record.key === 'footer'}>{text}</Text>
  }));

  return [
    {
      title: <Trans i18nKey={firstColumnNameKey} />,
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
      width: 270,
      ellipsis: true,
      render: (text, record) => {
        return {
          props: {
            style: {
              padding: '2px 5px'
            }
          },
          children:
            record.key === 'header' ||
            record.key === 'footer' ||
            viewMode !== WIDGET_VIEW_MODES.REVIEWERS.value ? (
              <Tooltip title={<Trans i18nKey={text} />}>
                <Text strong={record.key === 'footer'}>
                  <Trans i18nKey={text} />
                </Text>
              </Tooltip>
            ) : (
              <Tooltip title={text}>
                <UserPreview userId={record.key} margin="0px" showAvatar showUnit disabled hidden />
              </Tooltip>
            )
        };
      }
    },

    {
      title: <Trans i18nKey="dashboardPage.widget.unitName" />,
      dataIndex: 'unitName',
      key: 'unitName',
      fixed: 'left',
      width: 80
    },

    ...datesColumns,
    {
      title: <Trans i18nKey="dashboardPage.tableChecklistsScoresByOperators.columns.totalScore" />,
      dataIndex: 'totalScore',
      key: 'totalScore',
      align: 'center',
      fixed: 'right',
      width: 140,
      render: (text, record) => <Text strong={record.key === 'footer'}>{text}</Text>
    }
  ];
};

export const prepareRows = ({
  viewData,
  usersByIds,
  unitsByIds,
  viewMode,
  timeFrom,
  timeTo,
  historyGroupingPeriod,
  isWeekendView
}) => {
  const datesArray = getDatesArray({
    timeFrom,
    timeTo,
    isWeekendView,
    historyGroupingPeriod
  });

  const widgetItem =
    viewMode === WIDGET_VIEW_MODES.REVIEWERS.value
      ? {
          totalReviews: keyBy(viewData.reviewsCountByReviewers, 'id'),
          history: viewData.reviewsCountByReviewersHistory.filter(item => !isEmpty(item.history)),
          duration: viewData.reviewedClientInteractionsDurationByReviewersHistory
        }
      : {
          totalReviews: keyBy(viewData.reviewsCountByUnits, 'id'),
          history: viewData.reviewsCountByUnitsHistory.reduce((acc, item) => {
            // ! это нужно потому что в reviewsCountByUnitsHistory нет имен отделов, и если отдел пустой, то на виджете отдел показывается как пустая строка (отделы загружаются через инклуд в юзерах)
            if (isEmpty(item.history)) return acc;
            const totalReviewsByIds = keyBy(viewData.reviewsCountByUnits, 'id');
            const data = {
              ...item,
              name: totalReviewsByIds[item.id]?.name
            };
            return [...acc, data];
          }, []),
          duration: viewData.reviewedClientInteractionsDurationByUnitsHistory
        };

  const getItemHistoryWithEmptyDates = ({ history }) => {
    return datesArray.reduce((acc, date) => {
      const formattedDate = moment(date).format('YYYY-MM-DD');
      const data = {
        ...acc,
        [formattedDate]: history[formattedDate] || ''
      };
      return data;
    }, {});
  };

  const mappedItemsToRows = orderBy(
    widgetItem.history.map(item => {
      return {
        key: item.id,
        name:
          viewMode === WIDGET_VIEW_MODES.REVIEWERS.value
            ? getUserName({ user: usersByIds[item.id] })
            : item?.name,
        unitName: unitsByIds[usersByIds[item.id]?.unitId]?.name,
        ...getItemHistoryWithEmptyDates({ history: item.history }),
        totalScore: widgetItem.totalReviews[item.id]?.value
      };
    }),
    'name'
  );

  const mapItemsTotalScore = () => {
    return datesArray.reduce((acc, date) => {
      const formattedDate = moment(date).format('YYYY-MM-DD');
      const data = {
        ...acc,
        [formattedDate]: sum(widgetItem.history.map(item => item.history[formattedDate])) || ''
      };
      return data;
    }, {});
  };

  const mapReviewsDuration = () => {
    return datesArray.reduce((acc, date) => {
      const formattedDate = moment(date).format('YYYY-MM-DD');
      const data = {
        ...acc,
        [formattedDate]:
          timeFormatter({
            timeInSeconds: sum(widgetItem.duration.map(item => item.history[formattedDate]))
          }) || ''
      };
      return data;
    }, {});
  };

  const totalScore = sum(Object.values(widgetItem.totalReviews).map(item => item.value));

  return [
    {
      key: 'header',
      name: 'dashboardPage.tableReviewsCountByReviewers.rows.header',
      ...mapReviewsDuration(),
      totalScore: timeFormatter({
        timeInSeconds: sum(
          flattenDeep(widgetItem.duration.map(item => Object.values(item.history)))
        )
      })
    },
    ...mappedItemsToRows,
    {
      key: 'footer',
      name: 'dashboardPage.tableReviewsCountByReviewers.rows.footer',
      ...mapItemsTotalScore(),
      totalScore
    }
  ];
};
