import { Info } from 'react-feather';
import { Button, Card, Col, Input } from 'antd';
import styled from 'styled-components';

// export const StyledIcon = styled(Icon)`
//   &.anticon {
//     margin-right: 8px;
//   }
// `;

export const StyledInput = styled(Input)`
  &.ant-input {
    width: 64px;
  }
`;

export const MarginLeftButton = styled(Button)`
  &.ant-btn {
    margin-left: 8px;
  }
`;

export const StyledButton = styled(Button)`
  &.ant-btn {
    background-color: white !important;
    margin-left: 8px;
  }
`;

export const ValueCol = styled(Col)`
  &.ant-col {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-left: 8px;
    & > i,
    span {
      margin: 0 4px;
    }
  }
`;

export const InfoIcon = styled(Info)`
  &.anticon {
    margin: 0px 16px;
  }
`;

export const ValuesContainer = styled(Col)`
  &.ant-col {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }
`;

export const QestionGroupCard = styled(Card)`
  &.ant-card {
    border-radius: 4px;
    border: none;
    background-color: #e6f7ff;
    box-shadow: ${({ isDraggingOver }) =>
      isDraggingOver ? '0px 8px 8px rgba(0, 0, 0, 0.15)' : 'none'};
  }

  &.ant-card > .ant-card-body {
    padding: 11px 8px;
  }

  &&:hover {
    cursor: pointer;
  }
`;

export const QestionCard = styled(Card)`
  &.ant-card {
    border-radius: 4px;
    border: none;
    /* opacity: ${({ isDragging }) => (isDragging ? '.95' : 1)}; */
  }
  &.ant-card > .ant-card-body {
    padding: 11px 8px;
  }
`;

export const CardContainer = styled(Card)`
  &.ant-card {
    border-radius: 4px;
    border: none;
  }
  &.ant-card > .ant-card-body {
    padding: 8px;
  }
`;

export const StyledCol = styled(Col)`
  &.ant-col {
    display: flex;
    justify-content: flex-end;
    align-content: center;
  }
`;

export const ChecklistInfoText = styled.div`
  margin: 5px 0px;
`;
