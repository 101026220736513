export default {
  general: {
    save: 'Save',
    cancel: 'Cancel',
    canceling: 'Cancel',
    edit: 'Edit',
    answer: 'Reply',
    delete: 'Delete',
    disagree: 'Disagree',
    accept: 'Accept',
    accepted: 'Accepted',
    reject: 'Reject',
    rejected: 'Rejected',
    from: 'From',
    to: 'To',
    time: 'Time',
    hidden: 'Hidden',
    visibleToAll: 'Public',
    update: 'Update',
    add: 'Add',
    redFlag: 'Red',
    yellowFlag: 'Yellow',
    greenFlag: 'Green',
    withoutFlag: 'Without flag',
    uploadImage: 'Add image',
    loading: 'Loading',
    requiredField: 'Required field',
    continue: 'Continue',
    date: 'Date',
    dateAndTime: 'Date and time',
    direction: 'Direction',
    number: 'Number',
    phoneNumber: 'Phone number',
    tags: 'Tags',
    reviewDate: 'Evaluation date',
    applicationDate: 'Aplication date',
    applicator: 'Filed',
    checklistDefinition: 'Score card',
    checklistDefinitions: 'Score cards',
    criterion: 'Question',
    criteries: 'Questions',
    clientInteractionType: 'Type',
    clientInteractionTypes: 'Types',
    status: 'Status',
    statuses: 'Statuses',
    users: 'Users',
    executor: 'Performer',
    executors: 'Performers',
    role: 'Role',
    roles: 'Roles',
    changed: 'Changed',
    view: 'Watch',
    unit: 'Unit',
    units: 'Units',
    settings: 'Settings',
    analytics: 'Analytics',
    yes: 'Yes',
    no: 'No',
    name: 'Name',
    lastName: 'Last name',
    email: 'E-mail',
    goTo: 'Go to',
    employees: 'Employees',
    employee: 'Employee',
    all: 'All',
    initials: 'Full name',
    duration: 'Duration',
    score: 'Score',
    flagsAndComments: 'Flags and comments',
    close: 'Finish',
    stop: 'Stop',
    download: 'Download',
    reviewer: 'Reviewer',
    reviewers: 'Reviewers',
    total: 'Total'
  }
};
