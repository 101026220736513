import React, { useEffect, useCallback } from 'react';
import SRow from 'components/Standard/SRow';
import SCol from 'components/Standard/SCol';
import { Typography } from 'antd';
import SCard from 'components/Standard/SCard';
import STable from 'components/Standard/STable';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { WORK_PLAN_TASK_CONFIGURATIONS_STATUSES } from 'core/utils/constants';
import SText from 'components/Standard/SText';
import moment from 'moment';
import SButton from 'components/Standard/SButton';
import UserPreview from 'components/UserPreview';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual, get } from 'lodash';
import { operations, actions } from 'redux/lists/workPlanTasksListReducer';
import ColorizedNumbers from '../Components/ColorizedNumbers';

const WorkPlanTasks = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const { id: currentConfigurationId } = useParams();
  const { Title } = Typography;
  const { updatePage, setSorting } = actions;

  const workPlanTaskConfigurationsByIds = useSelector(
    state => state.workPlanTaskConfigurationsResource.byIds,
    isEqual
  );

  const currentWorkPlanTaskConfiguration = get(
    workPlanTaskConfigurationsByIds,
    currentConfigurationId,
    {}
  );

  const workPlanTasksByIds = useSelector(state => state.workPlanTasksResource.byIds, isEqual);

  const { ids, totalCount, page, sort, filters, loading } = useSelector(
    state => state.workPlanTasksList
  );

  const handleTableChange = useCallback(
    (pagination, filters, sorter) => {
      dispatch(
        updatePage({
          size: pagination.pageSize.toString(),
          number: pagination.current.toString()
        })
      );
      dispatch(setSorting(sorter));
    },
    [dispatch]
  );

  const workPlanTasks = ids.map(item => workPlanTasksByIds[item]);

  useEffect(() => {
    dispatch(
      operations.load({
        id: currentConfigurationId,
        page,
        filters,
        sort,
        include: 'task_configuration.created_by,assignments.task_items'
      })
    );
  }, [page, sort, filters]);

  const tableColumns = [
    {
      title: t('workPlanTasks.tableColumns.creator'),
      dataIndex: 'creatorId',
      key: 'creatorId',
      ellipsis: true,
      render: (text, record) => {
        const configurationAuthorId =
          workPlanTaskConfigurationsByIds[record?.taskConfigurationId]?.createdById;
        return <UserPreview userId={configurationAuthorId} disabled showAvatar />;
      }
    },

    {
      title: t('workPlanTasks.tableColumns.reviewsCount2'),
      dataIndex: 'reviewsCount',
      key: 'reviewsCount',
      ellipsis: true,
      width: 130,
      render: (text, record) => {
        return (
          <ColorizedNumbers
            completed={record?.completedTaskItemsCount}
            incompleted={record?.incompletedTaskItemsCount}
            total={record?.totalTaskItemsCount}
          />
        );
      }
    },

    {
      title: t('workPlanTasks.tableColumns.lastTaskTimeFrom'),
      dataIndex: 'timeFrom',
      key: 'timeFrom',
      ellipsis: true,
      sorter: true,
      render: text => <SText>{moment(text).format('DD/MM/YYYY')}</SText>
    },

    {
      title: t('workPlanTasks.tableColumns.lastTaskTimeTo'),
      dataIndex: 'timeTo',
      key: 'timeTo',
      ellipsis: true,
      sorter: true,
      render: text => <SText>{moment(text).format('DD/MM/YYYY')}</SText>
    },

    {
      title: t('general.status'),
      dataIndex: 'status',
      key: 'status',
      sorter: true,
      ellipsis: true,
      render: text => (
        <SText color={WORK_PLAN_TASK_CONFIGURATIONS_STATUSES[text]?.color}>
          {t(WORK_PLAN_TASK_CONFIGURATIONS_STATUSES[text]?.title)}
        </SText>
      )
    },

    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      width: 140,
      align: 'right',
      fixed: 'right',
      render: text => {
        return (
          <SButton
            type="primary"
            onClick={() =>
              history.push(`/work-plan/task-configurations/${currentConfigurationId}/tasks/${text}`)
            }
          >
            {t('general.view')}
          </SButton>
        );
      }
    }
  ];

  return (
    <SRow margin="16px">
      <Helmet>
        <title>{t('pagesMeta.workPlanTaskConfigurationsPage.title')}</title>
      </Helmet>
      <SCol span={24}>
        <SRow gutter={[16, 16]}>
          <SCol span={24}>
            <Title level={4}>{currentWorkPlanTaskConfiguration?.name}</Title>
          </SCol>
          <SCol span={24}>
            <SCard bordered shadowed bodyPadding="14px 20px">
              <STable
                dataSource={workPlanTasks}
                columns={tableColumns}
                loading={loading}
                border
                pagination={{
                  pageSize: parseInt(page.size),
                  current: parseInt(page.number),
                  total: totalCount,
                  showSizeChanger: true,
                  pageSizeOptions: ['10', '25', '50'],
                  size: 'small'
                }}
                onChange={handleTableChange}
                fixSorterColumns
                scroll={{ y: 'calc(100vh - 300px)', x: 'max-content' }}
                size="small"
              />
            </SCard>
          </SCol>
        </SRow>
      </SCol>
    </SRow>
  );
};

export default WorkPlanTasks;
