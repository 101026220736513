import React, { useState } from 'react';
import { Row, Col, Button } from 'antd';
import { get, isEmpty } from 'lodash';
import styled from 'styled-components';
import Editor from 'components/Comments/Editor';
import { useHistory } from 'react-router-dom';
import { createReplyComment } from 'redux/ui/clientInteractionPage/operations';
import SCard from 'components/Standard/SCard';
import { useTranslation } from 'react-i18next';
import useEvent from '@react-hook/event';
import { selectComment } from 'redux/ui/comments/reducer';
import { useSelector, useDispatch } from 'react-redux';
import SCol from 'components/Standard/SCol';
import { ConditionaHideCommentCheckbox } from './ConditionaHideCommentCheckbox';

const CreateReplyForm = ({ isEditReply, setEditReply, hidden }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [commentState, setCommentState] = useState({ text: '', uploadedFiles: [] });
  const [isHidden, setHidden] = useState(hidden);
  const parentId = useSelector(state => state.uiComments.selectedComment);
  const isEmptyComment = isEmpty(commentState.text.replace(/<[^>]*>?/gm, '').trim());

  const cancelReply = () => {
    setEditReply();
    setCommentState({ ...commentState, text: '' });
  };

  const sendReply = () => {
    dispatch(
      createReplyComment({
        id: parentId,
        text: commentState.text,
        hidden: isHidden,
        uploadedFilesIds: get(commentState, 'uploadedFiles', []).map(({ id }) => id)
      })
    );
    setEditReply();
    setCommentState({ ...commentState, text: '' });
  };

  useEvent(document, 'keydown', e => {
    const drawer = document.getElementsByClassName('ant-drawer')[0];
    const modal = document.getElementsByClassName('ant-modal-content')[0];
    const isEditorKeyPress = e.target.classList.contains('public-DraftEditor-content');
    if (
      e.code === 'Enter' &&
      (e.ctrlKey || e.metaKey) &&
      isEditorKeyPress &&
      commentState.text &&
      !isEmptyComment
    ) {
      sendReply();
      if (drawer && !modal) drawer.focus();
    }
    if (e.code === 'Escape') {
      cancelReply(false);
      dispatch(selectComment(null));
      if (drawer && !modal) drawer.focus();
    }
  });

  if (get(history, 'location.pathname', '') === '/library') return <div style={{ height: 16 }} />;

  return (
    <>
      {isEditReply && (
        <StyledRow>
          <SCol span={23}>
            <SCard rounded="4px" bordered shadowed>
              <Editor
                commentState={commentState}
                setCommentState={setCommentState}
                showAttachments
              />
              <StyledCol>
                <ConditionaHideCommentCheckbox
                  checked={isHidden}
                  onChange={() => setHidden(!isHidden)}
                >
                  {t('components.commentList.comment.createReplyForm.buttons.hidden')}
                </ConditionaHideCommentCheckbox>
                <StyledButton onClick={cancelReply}>
                  {t('components.commentList.comment.createReplyForm.buttons.cancel')}
                </StyledButton>
                <Button type="primary" onClick={sendReply} disabled={isEmptyComment}>
                  {t('components.commentList.comment.createReplyForm.buttons.send')}
                </Button>
              </StyledCol>
            </SCard>
          </SCol>
        </StyledRow>
      )}
    </>
  );
};

const StyledRow = styled(Row)`
  &.ant-row {
    display: flex;
    justify-content: flex-end;
    margin-top: 4px;
  }
  &.ant-row > span > p {
    margin: 0px;
  }
`;

const StyledCol = styled(Col)`
  &.ant-col {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
    align-items: center;
  }
`;

const StyledButton = styled(Button)`
  &.ant-btn {
    margin-right: 8px;
  }
`;

export default CreateReplyForm;
