import qs from 'qs';

export const stringify = settings => {
  return qs.stringify(settings, {
    encode: false,
    arrayFormat: 'comma',
    addQueryPrefix: true,
    skipNulls: true
  });
};

export const parse = query => {
  return qs.parse(query, { ignoreQueryPrefix: true, comma: true });
};
