import { Col, Row } from 'antd';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import TaskDefinitionsFilters from './TaskDefinitionsFilters';
import TaskDefinitionsList from './TaskDefinitionsList';

const TaskDefinitions = () => {
  const { t } = useTranslation();

  return (
    <Row gutter={[0, 8]} style={{ marginBottom: '-4px' }}>
      <Helmet>
        <title>{t('pagesMeta.triggersSettingsPage.title')}</title>
      </Helmet>
      <Col span={24}>
        <TaskDefinitionsFilters />
      </Col>
      <Col span={24}>
        <TaskDefinitionsList />
      </Col>
    </Row>
  );
};

export default TaskDefinitions;
