/* eslint-disable jsx-a11y/accessible-emoji */
import { Card, Col, Empty, Row, Typography } from 'antd';
import ChecklistHistoryModal from 'components/Checklist/ChecklistHistoryModal';
import QuestionDetailsModal from 'components/Questions/QuestionDetailsModal';
import { ANALYTICS_WIDGETS_NAMES, CHECKLIST_DEFINITION_STATUS } from 'core/utils/constants';
import { get } from 'lodash';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { getChecklistDefinitionQuestionGroups } from 'redux/selectors/checklistItems/checklistItems';
import { getChecklistsDefinitionsByStatuses } from 'redux/selectors/checklists';
import { updateUserChecklistAnalytics } from 'redux/ui/userAnalyticsPage/operations';
import QuestionsAnalytics from '../QuestionsAnalytics';
import { AllChecklistsAnalytics } from './AllChecklistsAnalytics';
import { AllQuestionsAnalytics } from './AllQuestionsAnalytics';
import ChecklistDefinitionAnalytics from './ChecklistDefinitionAnalytics';

const { Text } = Typography;

const AnalyticsChartsPanel = ({
  loading,
  questionGroups = [],
  currentChecklistDefinitionId,
  currentTimeRange = {},
  checklistDefinitions,
  checklistDefinition,
  updateUserChecklistAnalytics,
  widgets,
  loadingWidgets,
  userId,
  questions
}) => {
  useEffect(() => {
    updateUserChecklistAnalytics();
  }, [
    userId,
    currentTimeRange.clientInteractionTimeFrom,
    currentTimeRange.clientInteractionTimeTo,
    currentTimeRange.clientInteractionSymbolicTimeRange,
    currentChecklistDefinitionId
  ]);

  const { t } = useTranslation();

  if (loading) {
    return <Card loading />;
  }

  if (currentChecklistDefinitionId === 'all') {
    return (
      <AllChecklistsAnalytics
        checklistDefinitions={checklistDefinitions}
        widgets={widgets}
        loadingWidgets={loadingWidgets}
      />
    );
  }

  if (currentChecklistDefinitionId === 'all-questions') {
    return (
      <AllQuestionsAnalytics userId={userId} loadingWidgets={loadingWidgets} widgets={widgets} />
    );
  }

  const reviewsCount = get(
    widgets,
    [
      ANALYTICS_WIDGETS_NAMES.REVIEWS_COUNT_BY_CHECKLIST_DEFINITIONS,
      checklistDefinition.id,
      'value'
    ],
    0
  );

  if (!reviewsCount && !loadingWidgets) {
    return (
      <Row gutter={[8, 8]} style={{ margin: '-4px' }}>
        <Col span={24}>
          <Empty
            description={
              <Text type="secondary">{t('userAnalyticsPage.analyticsChartsPanel.empty')}</Text>
            }
          />
        </Col>
      </Row>
    );
  }

  return (
    <Row gutter={[8, 8]} style={{ margin: '-4px' }}>
      <Helmet>
        <title>{t('pagesMeta.userAnalyticsPage.title')}</title>
      </Helmet>
      <Col span={24}>
        <ChecklistDefinitionAnalytics
          showName
          checklistDefinition={checklistDefinition}
          loading={loadingWidgets}
          reviewsCount={reviewsCount}
          colorZones={get(widgets, [
            ANALYTICS_WIDGETS_NAMES.CHECKLIST_SCORES_COLOR_ZONE_BY_CHECKLIST_DEFINITIONS,
            checklistDefinition.id
          ])}
          averageScore={get(widgets, [
            ANALYTICS_WIDGETS_NAMES.AVERAGE_SCORE_BY_CHECKLIST_DEFINITIONS,
            checklistDefinition.id,
            'value'
          ])}
          ratingFlags={get(widgets, [
            ANALYTICS_WIDGETS_NAMES.RATING_FLAGS_COUNT_BY_CHECKLIST_DEFINITIONS,
            checklistDefinition.id
          ])}
        />
      </Col>
      <Col span={24}>
        <Row gutter={[8, 8]} style={{ margin: '-4px' }}>
          {Object.values(questionGroups).map(questionGroup => (
            <Col span={24}>
              <QuestionsAnalytics
                widgets={widgets}
                key={questionGroup.id}
                loading={loadingWidgets}
                questions={questions}
                questionGroup={questionGroup}
                isGroupable={checklistDefinition.isGroupable}
                reviewsCount={get(widgets, [
                  ANALYTICS_WIDGETS_NAMES.REVIEWS_COUNT_BY_OPERATORS,
                  userId,
                  'value'
                ])}
              />
            </Col>
          ))}
          <QuestionDetailsModal widgets={widgets} loadingWidgets={loadingWidgets} />
          <ChecklistHistoryModal widgets={widgets} loadingWidgets={loadingWidgets} />
        </Row>
      </Col>
    </Row>
  );
};

const mapStateToProps = state => {
  const {
    currentChecklistDefinitionId,
    currentTimeRange,
    checklistDefinitionsIds,
    currentUser
  } = state.uiUserAnalyticsPage;
  const { loadingWidgets, widgets } = state.analyticsWidgets;

  const checklistDefinition = get(
    state.checklistDefinitionsResource.byIds,
    currentChecklistDefinitionId,
    {}
  );

  const questionGroups = getChecklistDefinitionQuestionGroups(state, checklistDefinition);

  if (state.usersResource.loadByIdStarted) {
    return { loading: true, checklistDefinitions: [] };
  }

  const checklistDefinitions = Object.values(
    getChecklistsDefinitionsByStatuses(state, {
      statuses: [
        CHECKLIST_DEFINITION_STATUS.PUBLISHED.value,
        CHECKLIST_DEFINITION_STATUS.ARCHIVED.value
      ]
    }).filter(checklistDefinition => checklistDefinitionsIds.includes(checklistDefinition.id))
  );

  const { isGroupable } = checklistDefinition;

  const loading =
    state.usersResource.loading ||
    state.users.loading ||
    state.questionGroupsAnalytics.loading ||
    state.questionsAnalytics.loading;

  return {
    questionsByIds: state.questionsResource.byIds,
    loading,
    checklistDefinition,
    checklistDefinitions,
    currentChecklistDefinitionId,
    userId: get(currentUser, 'id', undefined),
    currentTimeRange,
    questionGroups,
    isGroupable,
    widgets,
    loadingWidgets
  };
};

const mapDispatchToProps = {
  updateUserChecklistAnalytics
};

export default connect(mapStateToProps, mapDispatchToProps)(AnalyticsChartsPanel);
