import api from 'core/api';
import * as actions from './reducer';

export const getDashboardAnalytics = ({ id }) => {
  return async dispatch => {
    const response = await api.getDashboardAnalytics({ id });
    const analyticsObject = response.body;
    return analyticsObject;
  };
};

export const getDashboardWidgetAnalytics = ({ id }) => {
  return async dispatch => {
    await dispatch(actions.loadDashboardWidgetAnalyticsStarted({ id }));
    const response = await api.getDashboardWidgetAnalytics({ id });
    const widgetAnalyticsObject = response.body;
    await dispatch(actions.loadDashboardWidgetAnalyticsSucceed(widgetAnalyticsObject));
    return widgetAnalyticsObject;
  };
};
