import { Settings } from 'react-feather';
import { Button, Drawer, Spin } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { toogleVisibleDrawer } from 'redux/ui/dashboard/reducer';
import styled from 'styled-components';
import Icon from 'components/Icon';
import AddRow from './Components/AddRow';
import Dashboards from './Components/Dashboards';
import Rows from './Components/Rows';

const DashboardSettingsDrawer = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { activeDashboardId, isVisibleDrawer } = useSelector(
    state => state.uiDashboard,
    shallowEqual
  );
  const dashboardsByIds = useSelector(state => state.dashboardsResource.byIds, shallowEqual);
  const { loading } = useSelector(state => state.dashboardAnalytics);

  return (
    <>
      <Drawer
        title={t('dashboardPage.dashboard.drawer.title')}
        placement="right"
        width={600}
        closable
        onClose={() => dispatch(toogleVisibleDrawer())}
        visible={isVisibleDrawer}
      >
        <Spin spinning={loading}>
          <Dashboards
            activeDashboardId={activeDashboardId}
            dashboardsByIds={dashboardsByIds}
            loading={loading}
          />
          <Rows activeDashboardId={activeDashboardId} dashboardsByIds={dashboardsByIds} />
          <AddRow />
        </Spin>
      </Drawer>

      <OpenDrawerButton
        type="primary"
        icon={<Icon icon={Settings} />}
        onClick={() => dispatch(toogleVisibleDrawer())}
      />
    </>
  );
};

const OpenDrawerButton = styled(Button)`
  &.ant-btn {
    position: fixed;
    top: 30%;
    right: 0;
    width: 50px !important;
    height: 50px !important;
    line-height: 3;

    z-index: 999;

    span {
      font-size: 24px !important;
    }
  }
`;

export default DashboardSettingsDrawer;
