import camelcase from 'camelcase';
import { assignChildrenToParents } from 'core/utils';
import {
  difference,
  every,
  find,
  get,
  isEmpty,
  isEqual,
  isNil,
  keyBy,
  mapValues,
  omit,
  some
} from 'lodash';
import { phoneCallsResource } from 'redux/resources/calls';
import { checklistsResource } from 'redux/resources/checklist';
import { commentsResource } from 'redux/resources/comments';
import { customFieldsResource } from 'redux/resources/customFields';
import { reviewsResource } from 'redux/resources/reviews';
import { tasksResource } from 'redux/resources/tasks';
import { getReviewTaskDefinitionsIds } from 'redux/selectors/taskDefinitions';
import * as checklistManagerActions from 'redux/ui/checklistManager/reducer';
import { addTasks } from 'redux/ui/clientInteractionPage/reducer';
import { updateTableRow } from '../clientInteractions/operations';
import * as actions from './reducer';

export const mapReviewToUiReducer = ({ state, review }) => {
  const getChecklistWithAnswers = ({ state, checklistId }) => {
    const checklist = state.checklistsResource.byIds[checklistId];

    if (!isEmpty(checklist)) {
      const questionIdToAnswerValue = mapValues(
        keyBy(
          checklist.answersIds.map(id => state.checklistAnswersResource.byIds[id]),
          'questionId'
        ),
        'value'
      );

      const questionIdToStandardComment = mapValues(
        keyBy(
          checklist.answersIds.map(id => state.checklistAnswersResource.byIds[id]),
          'questionId'
        ),
        'standardComments'
      );

      return { checklist, questionIdToAnswerValue, questionIdToStandardComment };
    }

    return { checklist: {}, questionIdToAnswerValue: {}, questionIdToStandardComment: {} };
  };

  const {
    checklist,
    questionIdToAnswerValue,
    questionIdToStandardComment
  } = getChecklistWithAnswers({
    state,
    checklistId: review.checklistId
  });
  return { checklist, questionIdToAnswerValue, questionIdToStandardComment };
};

const createCommentFromUi = commentFromUi => async dispatch => {
  const comment = await dispatch(commentsResource.operations.create(commentFromUi));
  await dispatch(actions.deleteComment(commentFromUi));
  await dispatch(actions.addComment(comment));
};

const isCommentsEqual = (realComment, uiComment) => {
  if (realComment.commentType === 'review_comment')
    return isEqual(
      omit(realComment, ['createdAt', 'updatedAt']),
      omit(uiComment, ['createdAt', 'updatedAt'])
    );

  // * exclude position comparison for other comments
  return isEqual(
    omit(realComment, ['position', 'createdAt', 'updatedAt']),
    omit(uiComment, ['position', 'createdAt', 'updatedAt'])
  );
};

export const updateComments = ({ reviewId, commentsByIds }) => async (dispatch, getState) => {
  const state = getState();
  const review = state.reviewsResource.byIds[reviewId];

  const oldComments = review.commentsIds.reduce(
    (acc, id) =>
      state.commentsResource.byIds[id] ? [...acc, state.commentsResource.byIds[id]] : acc,
    []
  );
  const newCommentsByIds = { ...commentsByIds };

  // * running all requests in parallel
  await Promise.all([
    ...oldComments.map(comment => {
      // * delete old comments that deleted on ui
      if (isEmpty(newCommentsByIds[comment.id])) {
        delete newCommentsByIds[comment.id];
        return dispatch(commentsResource.operations.deleteById({ id: comment.id }));
      }

      // * update old comments that updated on ui
      const newComment = { ...comment, ...newCommentsByIds[comment.id] };
      delete newCommentsByIds[comment.id];
      // * check if comment needs update
      if (!isCommentsEqual(comment, newComment))
        return dispatch(commentsResource.operations.updateById(newComment));
    }),
    // * create new comments (should wokr only for Review Comments, replies are sent to BE instantly)
    ...Object.values(newCommentsByIds)
      .filter(comment => comment?.commentType === 'review_comment')
      .map(comment => dispatch(createCommentFromUi({ ...comment, reviewId })))
  ]);
};

export const createTasks = ({ reviewId }) => async (dispatch, getState) => {
  const state = getState();
  const tasksByIds = state.tasksResource.byIds;
  const currentReviewTasks = state.reviewsResource.byIds[reviewId]?.tasksIds;
  const currentReviewTaskDefifnitions = currentReviewTasks.map(
    task => tasksByIds[task].taskDefinitionId
  );
  const uiTaskDefinitionsIds = get(state.uiClientInteractionPage, 'tasks.taskDefinitionsIds', []);
  const taskDefinitionsToDelete = difference(currentReviewTaskDefifnitions, uiTaskDefinitionsIds);

  const tasksToDelete = taskDefinitionsToDelete.map(
    taskDefinition => find(tasksByIds, { reviewId, taskDefinitionId: taskDefinition }).id
  );
  const tasksDefinitionsToCreate = uiTaskDefinitionsIds.filter(
    taskDefinitionId => !currentReviewTaskDefifnitions.includes(taskDefinitionId)
  );

  if (!isEmpty(tasksToDelete)) {
    await Promise.all([
      tasksToDelete.map(taskId => dispatch(tasksResource.operations.deleteById({ id: taskId })))
    ]);
  }

  if (!isEmpty(tasksDefinitionsToCreate)) {
    await Promise.all([
      tasksDefinitionsToCreate.map(taskDefinitionId =>
        dispatch(tasksResource.operations.create({ reviewId, taskDefinitionId }))
      )
    ]);
  }
};

export const loadReviewById = ({ id, shouldLoad = true, ...params }) => {
  return async (dispatch, getState) => {
    const startState = getState();
    await dispatch(actions.setLoading(true));
    const review = await dispatch(reviewsResource.operations.loadById({ id, ...params }));

    dispatch(customFieldsResource.operations.load({ pagination: false }));

    if (!review) {
      return;
    }

    if (shouldLoad) {
      await dispatch(
        commentsResource.operations.load({
          filters: { reviewsIds: review.id },
          include: 'author.unit,uploaded-files'
        })
      );
    }

    const state = getState();
    const clientInteraction = state.clientInteractionsResource.byIds[review.clientInteractionId];
    dispatch(actions.setContentType(camelcase(clientInteraction.clientInteractionType)));
    dispatch(checklistManagerActions.setReviewState(mapReviewToUiReducer({ state, review })));
    const reviewComments = keyBy(
      review.commentsIds.map(id => state.commentsResource.byIds[id]),
      'id'
    );

    dispatch(actions.setOperatorId(review.operatorId || clientInteraction.operatorId));
    dispatch(actions.setComments(assignChildrenToParents({ nodesByIds: reviewComments })));
    dispatch(actions.setLoading(false));
  };
};

export const submitReview = ({ id, review, fromDrawer = false }) => {
  return async (dispatch, getState) => {
    let state = getState();
    const {
      currentChecklist,
      questionIdToAnswerValue,
      questionIdToStandardComment
    } = state.uiChecklistManager;
    const { commentsByIds, operatorId } = state.uiClientInteractionPage;

    const answers = Object.keys(questionIdToAnswerValue).reduce((result, questionId) => {
      const value = questionIdToAnswerValue[questionId];
      const standardComments = questionIdToStandardComment[questionId];

      if (isNil(value)) {
        return result;
      }

      return [...result, { questionId, value, standardComments }];
    }, []);

    const checklist = currentChecklist
      ? {
          id: currentChecklist.id,
          checklistDefinitionId: currentChecklist.checklistDefinitionId,
          answers,
          comment: currentChecklist.comment,
          metadata: currentChecklist.metadata || {}
        }
      : {};

    let reviewId;
    let newReview;
    let newChecklist = null;

    if (isEmpty(review)) {
      // * create new review for client interaction
      newReview = await dispatch(
        reviewsResource.operations.create({ clientInteractionId: id, operatorId })
      );
      if (!newReview.id) return;
      reviewId = newReview.id;
    } else {
      // * updating existing review
      reviewId = id;
    }

    await dispatch(updateComments({ commentsByIds, reviewId }));

    await dispatch(createTasks({ reviewId }));

    const shouldCreateChecklist =
      checklist.checklistDefinitionId &&
      !isEmpty(questionIdToAnswerValue) &&
      some(questionIdToAnswerValue, value => !isNil(value));

    if (shouldCreateChecklist) {
      state = getState();

      const isChecklistNew =
        isEmpty(state.checklistsResource.byIds[checklist.id]) &&
        isEmpty(state.reviewsResource.byIds[reviewId].checklistId);

      const updatedChecklistId = !isChecklistNew
        ? checklist.id || state.reviewsResource.byIds[reviewId].checklistId
        : null;

      newChecklist = !isChecklistNew
        ? await dispatch(
            checklistsResource.operations.updateById({ ...checklist, id: updatedChecklistId })
          )
        : await dispatch(checklistsResource.operations.create({ ...checklist, reviewId }));

      state = getState();
      const reviewTaskDefinitionsIds = getReviewTaskDefinitionsIds(state, reviewId);
      await dispatch(addTasks({ reviewId, taskDefinitionsIds: reviewTaskDefinitionsIds }));
    }

    if (!isEmpty(review) && review?.operatorId !== operatorId) {
      await dispatch(reviewsResource.operations.updateById({ id: reviewId, operatorId }));
      await dispatch(
        loadReviewById({
          id: reviewId,
          include:
            'reviewer,custom_communication,text_communication,phone_call.phone_call_transcription.parts,client_interaction.operator.unit,client_interaction.text_communication_parts.operator,checklist.answers.question,checklist.checklist_definition.question_groups.question_to_group_bindings.question,tasks.task_definition'
        })
      );
    }

    await dispatch(checklistManagerActions.setReviewState({ checklist: newChecklist }));

    fromDrawer &&
      (await dispatch(
        updateTableRow({
          clientInteractionId: newReview?.clientInteractionId || review?.clientInteractionId,
          review: newReview || review
        })
      ));

    return reviewId;
  };
};

export const loadCallById = ({ id, fromDrawer, ...params }) => async dispatch => {
  dispatch(actions.setLoading(true));
  dispatch(checklistManagerActions.setLoading(true));

  const call = await dispatch(phoneCallsResource.operations.loadById({ id, ...params }));

  dispatch(actions.setOperatorId(call.operatorId));
  dispatch(customFieldsResource.operations.load({ pagination: false }));

  dispatch(actions.setLoading(false));
  dispatch(checklistManagerActions.setLoading(false));

  return call;
};

export const updateReplyComment = ({ id, ...params }) => async dispatch => {
  const comment = await dispatch(commentsResource.operations.updateById({ id, ...params }));
  await dispatch(actions.updateComment(comment));
  return comment;
};

export const deleteReplyComment = ({ id }) => async (dispatch, getState) => {
  await dispatch(commentsResource.operations.deleteById({ id }));
  const state = getState();
  const comment = get(state.uiClientInteractionPage.commentsByIds, id, {});
  const parentComment = state.uiClientInteractionPage.commentsByIds[comment.parentId];
  if (parentComment)
    await dispatch(
      actions.updateComment({
        ...parentComment,
        childrenIds: [...parentComment.childrenIds.filter(relationId => relationId !== id)]
      })
    );
  await dispatch(actions.deleteComment({ id }));
};

export const createReplyComment = params => async (dispatch, getState) => {
  const state = getState();
  const parentComment = state.uiClientInteractionPage.commentsByIds[params.id];
  const questionId = get(parentComment, ['metadata', 'questionId']);
  const comment = await dispatch(
    commentsResource.operations.createCommentReply({
      ...params,
      ...(questionId && { metadata: { questionId } }),
      parentComment
    })
  );

  if (comment) {
    dispatch(actions.addComment(comment));
  }

  if (parentComment && comment)
    await dispatch(
      actions.updateComment({
        ...parentComment,
        childrenIds: [...parentComment.childrenIds, comment.id]
      })
    );
  return comment;
};
