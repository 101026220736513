import React, { useState } from 'react';
import { Col, Tooltip, Row, Radio } from 'antd';
import { updateDashboardWidget } from 'redux/ui/widgetSettingsModal/operations';
import { connect } from 'react-redux';
import { WIDGET_VIEW_MODES } from 'core/utils/constants';
import WidgetTable from 'components/Dashboard/WidgetTable';
import { get, isEmpty } from 'lodash';
import ExcelIcon from 'images/ExcelIcon';
import { useTranslation } from 'react-i18next';
import { LoadingOutlined } from '@ant-design/icons';
import { IconButton } from 'components/Buttons/IconButton';
import { SettingsButtonCol, WidgetNameCol, LargeWidgetCard, TimePeriod } from '../../styled';
import SettingsModal from '../../Components/SettingsModal';
import {
  widgetNameSetter,
  widgetPeriodSetter,
  widgetInfoTextSetter,
  getDateSelectorType,
  exportWidgetData
} from '../../utils';
import EmptyWidget from '../../Components/EmptyWidget';
import { prepareColumns, prepareRows } from './prepareTableData';

const TableChecklistsScoresByOperators = ({
  widgetData,
  usersByIds,
  unitsByIds,
  widgetsAnalyticsByIds,
  loadingWidgetId,
  updateDashboardWidget,
  checklistDefinitionsByIds
}) => {
  const { t } = useTranslation();
  const { id, type, filters, name } = widgetData;
  const dateSelectorType = getDateSelectorType({ widgetType: type });
  const [loadingExport, setLoadingExport] = useState(false);
  const { viewMode } = filters;

  const widgetValue = get(widgetsAnalyticsByIds, id, {});

  const checklistDefinitionsAverageScoresByOperators = get(
    widgetValue,
    'checklist_definitions_average_scores_by_operators',
    []
  );

  const checklistDefinitionsAverageScoresByUnits = get(
    widgetValue,
    'checklist_definitions_average_scores_by_units',
    []
  );

  const reviewsCountByOperators = get(widgetValue, 'reviews_count_by_operators', []);

  const reviewsCountByUnits = get(widgetValue, 'reviews_count_by_units', []);

  const selectedChecklistDefinitions = filters.checklistDefinitionsIds.reduce(
    (acc, checklistDefinitionId) => {
      if (!Object.keys(checklistDefinitionsByIds).includes(checklistDefinitionId)) return acc;
      return [...acc, checklistDefinitionsByIds[checklistDefinitionId]];
    },
    []
  );

  const tableColumns = prepareColumns({
    selectedChecklistDefinitions,
    viewMode,
    filters
  });

  const tableRows = prepareRows({
    checklistDefinitionsAverageScoresByOperators,
    checklistDefinitionsAverageScoresByUnits,
    selectedChecklistDefinitions,
    reviewsCountByOperators,
    reviewsCountByUnits,
    usersByIds,
    unitsByIds,
    viewMode
  });

  const isEmptyWidgetData = isEmpty(
    viewMode === WIDGET_VIEW_MODES.OPERATORS.value
      ? checklistDefinitionsAverageScoresByOperators
      : checklistDefinitionsAverageScoresByUnits
  );

  const body = {
    widgetType: type,
    widgetData: {
      columns: tableColumns.reduce((acc, item) => {
        if (filters.viewMode === WIDGET_VIEW_MODES.UNITS.value && item.key === 'unitName')
          return acc;

        const data = {
          ...item,
          title: ['name', 'totalScore', 'reviewsCount', 'unitName'].includes(item.key)
            ? t(item?.title?.props?.i18nKey)
            : item?.title?.props?.title
        };
        return [...acc, data];
      }, []),
      rows: tableRows.map(item => ({
        ...item,
        name: item.key === 'footer' ? t(item.name) : item.name
      }))
    }
  };

  return (
    <>
      {loadingWidgetId === id || isEmptyWidgetData ? (
        <EmptyWidget key={id} widgetData={widgetData} loadingWidgetId={loadingWidgetId} />
      ) : (
        <Col span={24}>
          <LargeWidgetCard>
            <Row type="flex" justify="space-between" align="top">
              <WidgetNameCol span={8}>
                <Row type="flex" gutter={[6, 0]} justify="start">
                  <Col>{widgetInfoTextSetter({ type })}</Col>
                  <Col>
                    <Tooltip title={widgetNameSetter({ name, type })}>
                      <span>{widgetNameSetter({ name, type })}</span>
                    </Tooltip>
                  </Col>
                </Row>

                <TimePeriod type="secondary">
                  {widgetPeriodSetter(filters, dateSelectorType)}
                </TimePeriod>
              </WidgetNameCol>
              <Col span={6}>
                <Row justify="center">
                  <Col>
                    <Radio.Group
                      onChange={e =>
                        updateDashboardWidget({
                          id,
                          type,
                          filters: { ...filters, viewMode: e.target.value }
                        })
                      }
                      value={filters.viewMode || WIDGET_VIEW_MODES.OPERATORS.value}
                    >
                      <Radio.Button value={WIDGET_VIEW_MODES.OPERATORS.value}>
                        {t(WIDGET_VIEW_MODES.OPERATORS.title)}
                      </Radio.Button>
                      <Radio.Button value={WIDGET_VIEW_MODES.UNITS.value}>
                        {t(WIDGET_VIEW_MODES.UNITS.title)}
                      </Radio.Button>
                    </Radio.Group>
                  </Col>
                </Row>
              </Col>
              <SettingsButtonCol span={8}>
                <Row type="flex" align="start" gutter={[4, 0]}>
                  <Col>
                    <IconButton
                      tooltip={{ title: t('dashboardPage.widget.buttons.export') }}
                      button={{
                        icon: loadingExport ? (
                          <LoadingOutlined spin />
                        ) : (
                          <ExcelIcon color="rgba(38, 38, 38, 0.75)" />
                        ),
                        onClick: () =>
                          loadingExport ? null : exportWidgetData({ body, setLoadingExport }),
                        size: 'icon'
                      }}
                    />
                  </Col>
                  <Col>
                    <SettingsModal widgetData={widgetData} />
                  </Col>
                </Row>
              </SettingsButtonCol>
            </Row>
            <WidgetTable
              columns={tableColumns.filter(item => item.key !== 'unitName')}
              dataSource={tableRows}
              pagination={false}
              size="small"
              bordered
              scroll={{ x: 1500, y: 452 }}
              style={{ marginTop: 8 }}
              rowClassName={record => (record.key === 'header' ? 'table-row-light' : '')}
            />
          </LargeWidgetCard>
        </Col>
      )}
    </>
  );
};

const mapStateToProps = state => {
  const usersByIds = state.usersResource.byIds;
  const unitsByIds = state.unitsResource.byIds;

  const checklistDefinitionsByIds = state.checklistDefinitionsResource.byIds;
  const widgetsAnalyticsByIds = state.dashboardAnalytics.byIds;
  return {
    widgetsAnalyticsByIds,
    usersByIds,
    unitsByIds,
    checklistDefinitionsByIds
  };
};

const mapDispatchToProps = {
  updateDashboardWidget
};

export default connect(mapStateToProps, mapDispatchToProps)(TableChecklistsScoresByOperators);
