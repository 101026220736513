export default {
  teamPage: {
    unitTable: {
      empty: 'Non ci sono dati',
      infoIcons: {
        info: 'Questa tabella mostra le analisi di riepilogo per tutte le valutazioni',
        scoreInfo:
          'Punteggio medio tra tutte le valutazioni per il modulo di valutazione selezionato',
        reviewsCountInfo:
          'Il numero di tutte le valutazioni per i moduli di valutazioni selezionati',
        greenZoneCountInfo:
          'Percentuale di valutazioni che rientrano nella zona verde per i moduli di valutazione selezionati',
        allScoresInfo:
          'Tutte le valutazioni nel contesto delle zone di colore per i moduli di valutazione selezionati'
      },
      columns: {
        employee: 'Dipendente',
        unit: 'Dipartimento',
        score: 'Punteggio',
        reviewsCount: 'Valutazioni',
        greenZoneCount: 'Eccellente',
        allScores: 'Tutte le valutazioni',
        flags: 'Bandiere'
      },
      differenceArrowScoreLiteral: 'punteggio'
    },
    buttons: {
      exportTeamPageAnalytics: 'Esportare in Excel',
      units: 'Dipartimenti',
      employees: 'Dipendenti'
    },
    pageFilters: {
      exportModal: {
        title: 'Analitica esportata',
        description: "Il risultato dell'esportazione arrivera' alla tua posta in pochi minuti"
      },
      messages: { exportTableFailed: "Impossibile esportare l'analisi" },
      filters: {
        unitPlaceholder: 'Seleziona reparto',
        unitPlaceholderMax: 'Selezionato',
        unitPlaceholderMax2: 'dipartimento',
        checklistPlaceholder: 'Scegli un modulo di valutazione'
      }
    }
  }
};
