import { Col, Row } from 'antd';
import React from 'react';
import CombinedChart from './Components/CombinedChart';
import UsersTable from './Components/UsersTable';
import WidgetCardDivider from '../../../Components/WidgetCardDivider';

const ChartView = ({ widgetId, chartData, selectedUserId }) => {
  return (
    <Row type="flex" justify="space-between">
      <Col span={13}>
        <CombinedChart chartData={chartData.chart} selectedUserId={selectedUserId} />
      </Col>
      <Col span={1}>
        <WidgetCardDivider />
      </Col>
      <Col span={10}>
        <UsersTable
          tableData={chartData.table}
          widgetId={widgetId}
          selectedUserId={selectedUserId}
        />
      </Col>
    </Row>
  );
};

export default ChartView;
