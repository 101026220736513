export default {
  clientInteractionsPage: {
    clientInteractionsTable: {
      deactivatedUserLinkToCommunicationModal: {
        title: "Ufff... Questo utente non e' attivo 😢",
        description: "Per procedere alla valutazione, attiva l'utente. ",
        button: 'Vai alle impostazioni',
        ok: 'Va bene',
        cancel: 'Chiudere'
      },
      emptyTables: 'Nessuna tabella di comunicazione',
      buttons: { createTable: 'Creare' }
    },
    clientInteractionMeta: { searchResult: 'Trovato: ' },
    tableFilters: {
      tableConfigurations: { title: 'Configurazioni di filtri' },
      salesPipelines: {
        salesPipelinesPlaceholder: 'Imbuto di vendite',
        salePipelineStatusesPlaceholder: "Fasi dell'imbuto"
      },
      search: {
        clientPhoneNumberPlaceholder: 'Inserisci il tuo numero di telefono',
        placeholder: 'Cerca nel',
        result: {
          standartResult: 'Risultati di ricerca:',
          bigResult: "20 o piu'",
          emptyResult: 'Risultato della ricerca: Non trovato'
        },
        searchOptions: {
          clientId: 'ID cliente',
          operatorId: 'ID operatore',
          clientPhoneNumber: 'Telefono del cliente',
          companyName: "Nome dell'azienda",
          clientName: 'Nome del cliente',
          email: 'Email'
        }
      },
      buttons: {
        applyFilters: 'Applica filtri',
        resetFilters: 'Reimposta filtri',
        saveFilters: 'Salva configurazione'
      },
      messages: {
        tableSuccessfullyUpdated: 'Configurazione aggiornata',
        updateTableFailed: 'Impossibile aggiornare la configurazione'
      },
      shareConfirm: {
        title:
          'Le modifiche apportate alle impostazioni di questa tabella saranno disponibili per tutti gli utenti che vi hanno accesso',
        description: 'Continuare?',
        ok: 'Si',
        cancel: 'No'
      },
      tableGeneralFilters: {
        title: 'Informazione generale',
        form: {
          communicationsTypes: 'Tipo di comunicazioni',
          communicationsTypesShort: 'Tipi',
          directionPlaceholder: 'Direzione della comunicazione',
          direction: 'Direzione: ',
          datesRange: 'Intervallo di date',
          responseTime: 'Tempo di risposta:',
          communicationPartsCount: 'Numero di messaggi',
          duration: 'Durata',
          unit: 'Dipartimento',
          units: 'Dipartimenti:',
          levels: 'Livelli',
          operators: 'Operatori',
          nps: 'NPS',
          reviewStatus: 'Stato della valutazione',
          status: 'Stato del contatto'
        }
      },
      tableReviewsFilters: {
        title: 'Informazione sulla valutazione',
        infoTooltip: 'Questi filtri sono attivi solo per comunicazioni valutate',
        form: {
          reviewsCount: 'Numero di valutazioni:',
          checklistsPlaceholder: 'Moduli di valutazione',
          reviewResult: 'Risultato della valutazione:',
          reviewAuthor: 'Autore della valutazione',
          reviewAuthorShort: 'Verificatore',
          datesRange: 'Intervallo di date',
          commentsRatingFlags: 'Bandiere e commenti',
          tasksDefinitions: 'Tag'
        }
      },
      customFieldFilters: { title: 'Informazione aggiuntiva' }
    },
    drawer: {
      title: 'Impostazioni della tabella',
      columns: {
        title: 'Colonne',
        confirmDeleteColumn: {
          title: 'Sei sicuro di voler eliminare una colonna?',
          ok: 'Si',
          cancel: 'No'
        },
        buttons: {
          viewAddColumnPanel: 'Aggiungere una colonna',
          hidePanel: 'Nascondere'
        }
      },
      tables: {
        title: 'Configurazioni della tabella',
        empty: 'Nessuna configurazione creata',
        tableCountMax: 'Numero massimo di tabelle create',
        selectTablePlaceholder: 'Seleziona tabella attiva',
        shareTable: {
          title: 'Impostazioni di accesso',
          messages: {
            tableSuccessfullyShared: 'Impostazioni di accesso aggiornate',
            tableSharedFailed: 'Impossibile aggiornare le impostazioni di accesso'
          },
          sharedUserSelectPlaceholder: 'Inserisci il nome del dipendente',
          buttons: { apply: 'Accettare' }
        },
        strings: {
          copyString: '(Copia)',
          openAsccess: "(l'accesso e' aperto)",
          author: 'autore',
          newTableString: 'Nuova configurazione'
        },
        messages: {
          tableSuccessfullyCreated: 'Configurazione creata',
          tableSuccessfullyCopied: 'Configurazione copiata',
          tableSuccessfullyUpdated: 'Configurazione aggiornata',
          tableSuccessfullyDeleted: 'Configurazione eliminata',
          tableSuccessfullyExported: 'Elenco comunicazioni esportato',
          tableSuccessfullyExportMessage:
            "Elenco delle comunicazioni arrivera' alla tua posta tra pochi minuti",
          copyTableFailed: 'Impossibile copiare la tua configurazione',
          createTableFailed: 'Impossibile creare la configurazione',
          updateTableFailed: 'Impossibile aggiornare la configurazione',
          deleteTableFailed: 'Impossibile eliminare la configurazione',
          exportTableFailed: "Impossibile esportare l'elenco delle comunicazioni"
        },
        confirmDelete: {
          title: 'Sei sicuro di voler eliminare questa configurazione?',
          description:
            'Dopo aver eliminato una configurazione, le sue impostazioni non possono essere ripristinate',
          ok: 'Elimina',
          cancel: 'Annulla'
        },
        menu: {
          addConfig: 'Aggiungi configurazione',
          editTable: 'Rinomina configurazione',
          editTableColumns: 'Modifica colonne',
          copyTable: 'Crea copia',
          shareTable: 'Condividere',
          export: 'Esportare in Excel',
          deleteTable: 'Elimina'
        },
        buttons: { addConfig: 'Aggiungi configurazione' }
      }
    }
  }
};
