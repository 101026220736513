import { createSelector } from '@reduxjs/toolkit';
import { get, orderBy, pickBy, some, uniq, isEmpty, keyBy } from 'lodash';
import { getChecklistsDefinitionsByIds } from '../checklists';

export const getBindingsByIds = state => get(state, 'questionToGroupBindingsResource.byIds', {});
export const getQuestionsByIds = state => get(state, 'questionsResource.byIds', {});
export const getQuestionGroupsByIds = state => get(state, 'questionGroupsResource.byIds', {});

export const getQuestionGroupBindings = createSelector(
  getBindingsByIds,
  (_, questionGroup) => questionGroup,
  (bindings, questionGroup) =>
    orderBy(pickBy(bindings, { questionGroupId: questionGroup?.id }), 'position')
);

export const getQuestionGroupBindingsIds = createSelector(getQuestionGroupBindings, res =>
  res.map(({ id }) => id)
);

export const getQuestionGroupQuestions = createSelector(
  getQuestionsByIds,
  getQuestionGroupBindings,
  (questionsByIds, questionGroupBindings) =>
    questionGroupBindings.reduce((result, binding) => {
      const question = get(questionsByIds, binding.questionId);

      if (!question) {
        return result;
      }

      return [...result, { ...question, binding }];
    }, [])
);

export const getChecklistDefinitionQuestionGroups = createSelector(
  getQuestionGroupsByIds,
  (_, checklistDefinition) => checklistDefinition,
  (questionGroupsByIds, checklistDefinition) =>
    orderBy(
      Object.values(questionGroupsByIds).reduce(
        (acc, group) =>
          group.checklistDefinitionId === checklistDefinition?.id ? [...acc, group] : acc,
        []
      ),
      'position'
    )
);

export const getChecklistDefinitionQuestionGroupsWithQuestions = (state, checklistDefinition) =>
  getChecklistDefinitionQuestionGroups(state, checklistDefinition).map(group => ({
    ...group,
    questions: getQuestionGroupQuestions(state, group)
  }));

export const getChecklistDefinitionBindings = createSelector(
  getBindingsByIds,
  getChecklistDefinitionQuestionGroups,
  (bindingsByIds, questionGroups) => {
    return orderBy(
      Object.values(bindingsByIds).filter(binding =>
        some(questionGroups, { id: binding.questionGroupId })
      ),
      'position'
    );
  }
);

export const getChecklistDefinitionQuestions = createSelector(
  getQuestionsByIds,
  getQuestionGroupsByIds,
  getChecklistDefinitionBindings,
  (questionsByids, questionGroupsByIds, bindings) =>
    bindings.reduce((acc, binding) => {
      const question = get(questionsByids, binding.questionId, {});
      if (isEmpty(question)) return acc;

      const data = {
        ...question,
        binding: { ...binding, questionGroup: questionGroupsByIds[binding.questionGroupId] }
      };

      return [...acc, data];
    }, [])
);

export const getChecklistDefinitionQuestionGroupsBeforeQuestions = createSelector(
  getChecklistDefinitionQuestionGroups,
  getBindingsByIds,
  getQuestionsByIds,
  (_, currentChecklist) => currentChecklist,
  (questionGroups, bindingsByIds, questionsByIds, checklist) => {
    return questionGroups.reduce((acc, questionGroup) => {
      const questions = orderBy(
        Object.values(bindingsByIds).reduce((result, binding) => {
          if (binding.questionGroupId !== questionGroup.id) {
            return result;
          }

          const question = get(questionsByIds, binding.questionId);

          return question ? [...result, { ...question, binding }] : result;
        }, []),
        'binding.position'
      );

      return checklist.isGroupable ? [...acc, questionGroup, ...questions] : [...acc, ...questions];
    }, []);
  }
);

export const getQuestionBindings = createSelector(
  getBindingsByIds,
  (_, { question }) => question,
  (bindingsByIds, question) => {
    return Object.values(bindingsByIds).reduce(
      (result, binding) => (binding.questionId === question.id ? [...result, binding] : result),
      []
    );
  }
);

export const getQuestionChecklistDefinitions = createSelector(
  getChecklistsDefinitionsByIds,
  getBindingsByIds,
  getQuestionGroupsByIds,
  (_, question) => question,
  (checklistDefinitionsByIds, bindingsByIds, questionGroupsByIds, question) => {
    const questionBindings = Object.values(bindingsByIds).reduce(
      (result, binding) => (binding.questionId === question.id ? [...result, binding] : result),
      []
    );

    const questionGroups = Object.values(questionGroupsByIds).reduce(
      (result, questionGroup) =>
        some(questionBindings, { questionGroupId: questionGroup.id })
          ? [...result, questionGroup]
          : result,
      []
    );

    const checklistDefinitions = uniq(
      questionGroups.map(
        ({ checklistDefinitionId }) => checklistDefinitionsByIds[checklistDefinitionId]
      )
    );
    return checklistDefinitions;
  }
);
