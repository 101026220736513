import React from 'react';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import SCard from 'components/Standard/SCard';
import SRow from 'components/Standard/SRow';
import SCol from 'components/Standard/SCol';
import SSelect from 'components/Standard/SSelect';
import SButton from 'components/Standard/SButton';
import { useHistory } from 'react-router-dom';
import { actions } from 'redux/lists/workPlanTaskConfigurationsListReducer';
import { WORK_PLAN_TASK_CONFIGURATIONS_STATUSES } from 'core/utils/constants';

const Filters = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { Option } = Select;
  const history = useHistory();

  const { filters } = useSelector(state => state.workPlanTaskConfigurationsList);

  return (
    <SCard background="#F8F8FA" bodyPadding="16px 20px">
      <SRow type="flex" justify="space-between">
        <SCol>
          <SSelect
            width="200px"
            placeholder={t('general.status')}
            onChange={status => dispatch(actions.updateFilters({ status }))}
            value={filters.status}
            allowClear
          >
            {Object.values(WORK_PLAN_TASK_CONFIGURATIONS_STATUSES).map(item => (
              <Option key={item.value} value={item.value}>
                {t(item.title)}
              </Option>
            ))}
          </SSelect>
        </SCol>
        <SCol>
          <SButton
            type="primary"
            onClick={() => history.push('/work-plan/task-configurations/editor/new')}
          >
            {t('workPlanTasks.filters.createTask')}
          </SButton>
        </SCol>
      </SRow>
    </SCard>
  );
};

export default Filters;
