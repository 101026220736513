import moment from 'moment';
import { mean, isNaN, isEmpty } from 'lodash';
import { getDatesArray } from '../../utils';

export const prepareBarData = ({
  filters,
  timeFrom,
  timeTo,
  usersByIds,
  checklistDefinitionAverageScoreByOperatorsHistory
}) => {
  const { isWeekendView, historyGroupingPeriod } = filters;
  const datesArray = getDatesArray({ timeFrom, timeTo, isWeekendView, historyGroupingPeriod });

  return checklistDefinitionAverageScoreByOperatorsHistory.reduce((acc, item) => {
    if (!usersByIds[item.id]?.active || isEmpty(item.history)) return acc;

    const history = datesArray.reduce((acc, day) => {
      return {
        ...acc,
        [moment(day).format('YYYY-MM-DD')]:
          parseFloat(item.history[moment(day).format('YYYY-MM-DD')]) || 0
      };
    }, {});

    const data = {
      id: item.id,
      name: item.user_name,
      history: { ...history }
    };
    return [...acc, data];
  }, []);
};

export const prepareLineData = ({
  filters,
  timeFrom,
  timeTo,
  checklistDefinitionAverageScoreByOperatorsHistory
}) => {
  const { isWeekendView, historyGroupingPeriod } = filters;
  const datesArray = getDatesArray({ timeFrom, timeTo, isWeekendView, historyGroupingPeriod });

  const getAverageReviewScore = ({ date }) => {
    return (
      mean(
        checklistDefinitionAverageScoreByOperatorsHistory
          .map(item => parseFloat(item.history[date]))
          .filter(value => !isNaN(value))
      ) || 0
    );
  };

  return datesArray.reduce((acc, day) => {
    return {
      ...acc,
      [moment(day).format('YYYY-MM-DD')]: getAverageReviewScore({
        date: moment(day).format('YYYY-MM-DD')
      })
    };
  }, {});
};
