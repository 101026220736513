// import './wdyr'; // ! <--- first import
import * as Sentry from '@sentry/react';
import { ConfigProvider } from 'antd';
import ruRu from 'antd/es/locale/ru_RU';
import enUs from 'antd/es/locale/en_US';
import esEs from 'antd/es/locale/es_ES';
import itIt from 'antd/es/locale/it_IT';
import { version } from 'core/config';
import { store } from 'core/store';
import React from 'react';
import 'moment/locale/ru';
import 'moment/locale/es';
import 'moment/locale/it';
import 'react-app-polyfill/stable';
import { isMobile } from 'react-device-detect';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import serviceWorker from 'serviceWorker';
import getRoutes from './App';
import './i18next';
import MobileBlocked from './pages/ErrorPages/MobileBlocked';
import './core/polyfills/composedPath';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    release: version,
    dsn: 'https://39f5e06e99fc4bdd9eb9f7ffe1e81678@sentry.io/1825478',
    normalizeDepth: 4,
    ignoreErrors: [
      'ResizeObserver loop limit exceeded',
      'ResizeObserver loop completed with undelivered notifications'
    ],
    maxBreadcrumbs: 20
  });
}

// reportWebVitals(console.log);

serviceWorker.register();

const ANTD_LOCALES_CONVERT = {
  ru: ruRu,
  en: enUs,
  es: esEs,
  it: itIt
};

const locale = ANTD_LOCALES_CONVERT[localStorage.getItem('i18nextLng')] || ruRu;

// import "./index.scss";
getRoutes().then(routes => {
  ReactDOM.render(
    <Provider store={store}>
      {!isMobile ? <ConfigProvider locale={locale}>{routes}</ConfigProvider> : <MobileBlocked />}
    </Provider>,
    document.getElementById('root')
  );
});
