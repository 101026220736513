import camelize from 'camelcase';
import { processJsonApiCollection } from 'core/jsonapi';
import { isArray, isEmpty } from 'lodash';

const loadIncludedObjectsToRedux = ({ objects, dispatch }) => {
  if (isArray(objects) && !isEmpty(objects)) {
    const includedTypes = [...new Set(objects.map(includedObject => includedObject.type))];
    includedTypes.forEach(type => {
      const objectsOfType = objects.filter(object => object.type === type);

      dispatch({
        type: `${camelize(type)}/loadSucceed`,
        payload: processJsonApiCollection(objectsOfType)
      });
    });
  }
};

export default loadIncludedObjectsToRedux;
