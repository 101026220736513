import React from 'react';
import { Typography, List, Popconfirm } from 'antd';
import SCard from 'components/Standard/SCard';
import { useTranslation } from 'react-i18next';
import { isEqual, orderBy } from 'lodash';
import uniqid from 'uniqid';
import SButton from 'components/Standard/SButton';
import { useSelector, useDispatch } from 'react-redux';
import { setSelectedRole } from 'redux/ui/rolesAndPermissions/reducer';
import SCol from 'components/Standard/SCol';
import SRow from 'components/Standard/SRow';
import { rolesResource } from 'redux/resources/roles';
import RoleModal from './Components/RoleModal';

const deleteRole = rolesResource.operations.deleteById;

const Permissions = () => {
  const { t } = useTranslation();
  const { Text, Title } = Typography;
  const dispatch = useDispatch();

  const roles = useSelector(
    state => orderBy(Object.values(state.rolesResource.byIds), 'createdAt'),
    isEqual
  );

  const loading = useSelector(state => state.rolesResource.loading);

  const renderItem = role => (
    <List.Item
      actions={[
        <SButton type="link" onClick={() => dispatch(setSelectedRole(role))}>
          {t('organizationStructure.tabs.roles.item.buttons.setting')}
        </SButton>,
        <Popconfirm
          onConfirm={() => dispatch(deleteRole(role))}
          title={t('organizationStructure.tabs.roles.item.confirmDelete')}
        >
          <SButton type="link">{t('organizationStructure.tabs.roles.item.buttons.delete')}</SButton>
        </Popconfirm>
      ]}
    >
      <List.Item.Meta title={<Text>{role.name}</Text>} />
    </List.Item>
  );

  return (
    <SCol sm={24} lg={24} xl={12}>
      <SCard bordered loading={loading}>
        <SRow gutter={[0, 16]}>
          <SCol span={24}>
            <Title level={5}>{t('organizationStructure.tabs.roles.title')}</Title>
          </SCol>
          <SCol span={24}>
            <List
              loading={loading}
              header={<Text>{t('organizationStructure.tabs.roles.item.header')}</Text>}
              dataSource={roles}
              renderItem={renderItem}
            />

            <SButton
              type="primary"
              onClick={() =>
                dispatch(
                  setSelectedRole({
                    id: uniqid(),
                    name: t('organizationStructure.tabs.roles.item.buttons.newRole')
                  })
                )
              }
            >
              {t('organizationStructure.tabs.roles.item.buttons.addRole')}
            </SButton>
            <RoleModal />
          </SCol>
        </SRow>
      </SCard>
    </SCol>
  );
};

export default Permissions;
