import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Row, Modal, Col, Typography, Empty, Tooltip } from 'antd';
import { ResponsiveBar } from '@nivo/bar';
import { tooltipSetter } from 'pages/DashboardPage/utils';
import { setScoresHistoryChecklistDefinitionId } from 'redux/ui/userAnalyticsPage/reducer';
import { loadChecklistAnalyticsHistory } from 'redux/ui/userAnalyticsPage/operations';
import { ANALYTICS_WIDGETS_NAMES, ZONE_TO_COLOR_RELATIONS } from 'core/utils/constants';
import { sum, isEmpty, get, reduce } from 'lodash';
import { beatifyFloat } from 'core/utils/convertFloat';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import styled from 'styled-components';

const { Text } = Typography;

const LoadingText = styled(Text)`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ChecklistHistoryModal = ({
  checklistDefinitionId,
  checklistDefinition,
  loading,
  widgets,
  loadChecklistAnalyticsHistory,
  setScoresHistoryChecklistDefinitionId,
  currentChecklistDefinitionId,
  currentTimeRange = {},
  checklistDefinitionsIds,
  currentUser
}) => {
  const visible = !isEmpty(checklistDefinitionId) && !isEmpty(checklistDefinition);
  const { t } = useTranslation();
  useEffect(() => {
    if (visible) {
      loadChecklistAnalyticsHistory(checklistDefinitionId);
    }
  }, [
    currentChecklistDefinitionId,
    currentTimeRange.clientInteractionTimeFrom,
    currentTimeRange.clientInteractionTimeTo,
    currentTimeRange.clientInteractionSymbolicTimeRange,
    checklistDefinitionsIds,
    currentUser,
    checklistDefinitionId
  ]);

  const rawData = get(
    widgets,
    [
      ANALYTICS_WIDGETS_NAMES.CHECKLIST_SCORES_COLOR_ZONE_BY_CHECKLIST_DEFINITIONS_HISTORY,
      checklistDefinitionId
    ],
    {}
  );

  const data = reduce(
    rawData.history || {},
    (result, history, key) => {
      const low = get(history, 'low', 0);
      const medium = get(history, 'medium', 0);
      const high = get(history, 'high', 0);

      const zoneValuesSum = sum([low, medium, high]);
      const startOfPeriod = moment(key).format('DD/MM/YYYY');
      const endOfPeriod = moment(startOfPeriod, 'DD/MM/YYYY')
        .add(1, rawData.history_group_period)
        .format('DD/MM/YYYY');

      const totalSum = zoneValuesSum;

      const lowPercantage = beatifyFloat((100 / totalSum) * low);
      const mediumPercantage = beatifyFloat((100 / totalSum) * medium);
      const highPercantage = beatifyFloat((100 / totalSum) * high);

      return [
        ...result,
        {
          endOfPeriod,
          startOfPeriod,
          low,
          medium,
          high,
          lowPercantage,
          mediumPercantage,
          highPercantage,
          sum: totalSum
        }
      ];
    },
    []
  );

  const ZONE_PERCANTAGE_TO_COLOR_RELATIONS = {
    lowPercantage: ZONE_TO_COLOR_RELATIONS.low,
    mediumPercantage: ZONE_TO_COLOR_RELATIONS.medium,
    highPercantage: ZONE_TO_COLOR_RELATIONS.high
  };

  const COLOR_PERCANTAGE_ZONE_LITERALS = {
    lowPercantage: t('constants.colorPercantageZone.lowPercantage'),
    mediumPercantage: t('constants.colorPercantageZone.mediumPercantage'),
    highPercantage: t('constants.colorPercantageZone.highPercantage')
  };

  const hideWidget = () => {
    setScoresHistoryChecklistDefinitionId(undefined);
  };

  const noData = isEmpty(data);

  return (
    <Modal
      title={checklistDefinition.name}
      visible={visible}
      onOk={hideWidget}
      onCancel={hideWidget}
      okText={t('general.continue')}
      cancelButtonProps={{ style: { display: 'none' } }}
      width={900}
      destroyOnClose
    >
      <Row style={{ height: 400 }} type="flex" align="stretch">
        {loading && (
          <Col span={24} style={{ height: '100%' }}>
            <LoadingText type="secondary">
              {t('components.checklistHistoryModal.loading')}
            </LoadingText>
          </Col>
        )}

        {!loading && noData ? (
          <Empty
            style={{ margin: 'auto' }}
            description={
              <Text type="secondary">{t('components.checklistHistoryModal.empty')}</Text>
            }
          />
        ) : (
          <ResponsiveBar
            data={data}
            keys={['lowPercantage', 'mediumPercantage', 'highPercantage']}
            indexBy="startOfPeriod"
            margin={{ top: 10, right: 1, bottom: 20, left: 40 }}
            colors={bar => ZONE_PERCANTAGE_TO_COLOR_RELATIONS[bar.id]}
            padding={0.7}
            enableLabel
            label={({ data, id }) => get(data, id.replace('Percantage', ''))}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor="var(--black_primary)"
            maxValue={100}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              renderTick: ({ value, x, y }) => {
                const duration = moment
                  .duration({ [rawData.history_group_period]: 1 })
                  .subtract(1, 'day');

                const endOfPeriod = moment(value, 'DD/MM/YYYY')
                  .add(duration)
                  .format('DD/MM/YYYY');

                return (
                  <g transform={`translate(${x},${y + 11})`}>
                    <Tooltip title={`${value} - ${endOfPeriod}`}>
                      <text
                        textAnchor="middle"
                        dominantBaseline="middle"
                        style={{ fontSize: '11px', cursor: 'pointer' }}
                      >
                        {value}
                      </text>
                    </Tooltip>
                  </g>
                );
              }
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              format: d => `${d}%`
            }}
            tooltip={({ id, value }) =>
              tooltipSetter({
                name: COLOR_PERCANTAGE_ZONE_LITERALS[id],
                value: `${value}%`
              })
            }
            animate
            motionStiffness={90}
            motionDamping={15}
          />
        )}
      </Row>
    </Modal>
  );
};

const mapStateToProps = state => {
  const {
    scoresHistoryChecklistDefinitionId: checklistDefinitionId,
    currentChecklistDefinitionId,
    currentTimeRange,
    checklistDefinitionsIds,
    currentUser
  } = state.uiUserAnalyticsPage;

  const { updatingWidgets: loading } = state.analyticsWidgets;

  const checklistDefinition = get(
    state.checklistDefinitionsResource.byIds,
    checklistDefinitionId,
    {}
  );

  return {
    checklistDefinitionId,
    checklistDefinition,
    currentChecklistDefinitionId,
    currentTimeRange,
    checklistDefinitionsIds,
    userId: get(currentUser, 'id'),
    loading
  };
};

const mapDispatchToProps = {
  loadChecklistAnalyticsHistory,
  setScoresHistoryChecklistDefinitionId
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChecklistHistoryModal));
