import { isEmpty, isEqual, orderBy, pick, pickBy } from 'lodash';
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getCurrentUser } from 'redux/selectors/users';
import {
  setQuestionIdToAppealComment,
  setQuestionIdToAppealObjection
} from 'redux/ui/checklistManager/reducer';
import moment from 'moment';
import SCol from 'components/Standard/SCol';
import SCard from 'components/Standard/SCard';
import SRow from 'components/Standard/SRow';
import UserPreview from 'components/UserPreview';
import DOMPurify from 'dompurify';
import SText from 'components/Standard/SText';
import SButton from 'components/Standard/SButton';
import Editor from 'components/Comments/Editor';
import Icon from 'components/Icon';
import { Send, Edit3, Trash2 } from 'react-feather';
import UploadedFile from 'components/Comments/Editor/UploadedFile';
import { uploadedFilesResource } from 'redux/resources/uploadedFiles';
import { commentsResource } from 'redux/resources/comments';
import { message, Popconfirm } from 'antd';
import { appellationCommentsResource } from 'redux/resources/appellationComments';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const QuestionAppealComment = ({ appealObjection, appealComment, questionId, appealId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentUser = useSelector(getCurrentUser, isEqual);
  const [replyComment, setReplyComment] = useState(undefined);
  const replies = useSelector(state => {
    if (appealComment?.id) {
      return orderBy(
        pickBy(state.appellationCommentsResource.byIds, { parentId: appealComment.id }),
        'createdAt'
      );
    }
    return [];
  }, isEqual);

  const updateAppealComment = comment => {
    dispatch(setQuestionIdToAppealComment({ questionId, comment }));
  };

  const appealCommentsByIds = useSelector(
    state => state.appellationCommentsResource.byIds,
    isEqual
  );

  const deleteCommentHandler = async id => {
    try {
      await dispatch(commentsResource.operations.deleteById({ id }));
    } catch (error) {
      console.log(error);
    }
  };

  const onSaveAppealComment = async () => {
    if (appealId === 'new') {
      dispatch(
        setQuestionIdToAppealComment({
          questionId,
          comment: {
            ...appealComment,
            authorId: currentUser.id,
            updatedAt: moment().toISOString(),
            saved: true
          }
        })
      );
    } else {
      try {
        await dispatch(
          commentsResource.operations.updateById({
            ...pick(appealComment, ['id', 'text']),
            uploadedFilesIds: appealComment.uploadedFiles.map(({ id }) => id)
          })
        );
        dispatch(
          setQuestionIdToAppealComment({
            questionId,
            comment: {
              ...appealComment,
              authorId: currentUser.id,
              updatedAt: moment().toISOString(),
              saved: true
            }
          })
        );
        message.success(t('messages.success.commentSaved'));
      } catch (error) {
        console.log(error);
      }
    }
  };

  const editAppealComment = () => {
    dispatch(
      setQuestionIdToAppealComment({ questionId, comment: { ...appealComment, saved: false } })
    );
  };

  const onDeleteFile = id => {
    dispatch(
      setQuestionIdToAppealComment({
        questionId,
        comment: {
          ...appealComment,
          uploadedFilesIds: appealComment?.uploadedFilesIds.filter(fileId => fileId !== id),
          uploadedFiles: appealComment?.uploadedFiles.filter(({ id: fileId }) => fileId !== id)
        }
      })
    );
    dispatch(uploadedFilesResource.operations.deleteById({ id }));
  };

  const sendReplyToAppealComment = async () => {
    try {
      const comment = {
        text: replyComment?.text,
        uploadedFilesIds: replyComment?.uploadedFiles?.map(({ id }) => id),
        appellationId: appealId,
        parentId: appealComment?.id,
        commentType: 'appellant_comment_reply'
      };
      await dispatch(appellationCommentsResource.operations.create(comment));
      setReplyComment(null);
      message.success(t('messages.success.answerSended'));
    } catch (error) {
      console.log({ error });
    }
  };

  if (!appealObjection) return null;

  return (
    <SCol span={24} marginTop="4px">
      <SRow gutter={[0, 16]} style={{ marginBottom: '-8px' }} type="flex" justify="end">
        {appealComment?.saved ? (
          <SCol span={24}>
            <SCard
              borderLeft="6px solid var(--blue_primary)"
              background="var(--gray_primary)"
              rounded="4px"
              bodyPadding="12px"
            >
              <SRow type="flex" justify="space-between">
                <SCol>
                  <SRow>
                    <SCol>
                      <UserPreview
                        userId={appealComment?.authorId}
                        size="extraSmall"
                        showAvatar
                        disabled
                      />
                    </SCol>
                    <SCol marginLeft="6px">
                      <SText type="secondary">
                        {moment(appealComment?.updatedAt).format('DD/MM/YYYY, HH:mm')}
                      </SText>
                    </SCol>
                  </SRow>
                </SCol>
                {appealComment?.authorId === currentUser?.id ? (
                  <SCol display="flex" alignItems="center" justifyContent="flex-end">
                    <StyledButton
                      size="icon"
                      icon={<Icon icon={Edit3} size="16px" />}
                      color="var(--black_6)"
                      backgroundColor="transparent"
                      onClick={editAppealComment}
                    />
                  </SCol>
                ) : (
                  <SButton
                    size="small"
                    type="link"
                    padding="0"
                    textAlign="right"
                    onClick={() =>
                      setReplyComment({
                        parentId: appealComment?.id,
                        appelationId: appealId,
                        commentType: 'appellant_comment_reply'
                      })
                    }
                  >
                    {t('general.answer')}
                  </SButton>
                )}

                <SCol span={24}>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(appealComment?.text, {
                        ALLOWED_ATTR: ['target', 'href']
                      })
                    }}
                    style={{ overflowWrap: 'break-word' }}
                  />
                </SCol>
                <SCol span={24}>
                  {appealComment?.uploadedFiles?.map(file => (
                    <UploadedFile
                      uploadedFile={file}
                      allFiles={appealComment?.uploadedFiles}
                      onDelete={onDeleteFile}
                    />
                  ))}
                </SCol>
              </SRow>
            </SCard>
          </SCol>
        ) : (
          <SCol span={24}>
            <SCard width="100%" borderRadius="4px">
              <SRow>
                <SCol span={22}>
                  <Editor
                    commentState={appealComment}
                    setCommentState={updateAppealComment}
                    showTemplates={false}
                    showFlags={false}
                    showAttachments
                  />
                </SCol>
                <SCol span={2} paddingTop="56px" display="flex" justifyContent="flex-end">
                  <SButton
                    size="icon"
                    icon={<Icon icon={Send} size="24px" />}
                    border="none"
                    boxShadow="none"
                    color="var(--blue_6)"
                    onClick={onSaveAppealComment}
                    showLoad
                    disabled={
                      isEmpty(appealComment?.uploadedFiles) ? isEmpty(appealComment?.text) : false
                    }
                    style={{ backgroundColor: 'transparent' }}
                  />
                </SCol>
                <SCol span={24}>
                  {/* <SButton
                    onClick={() => {
                      if (appealCommentsByIds[appealComment?.id]?.text)
                        updateAppealComment({
                          ...appealComment,
                          text: appealCommentsByIds[appealComment?.id]?.text,
                          saved: true
                        });
                      if (!appealCommentsByIds[appealComment?.id]?.text) {
                        dispatch(
                          setQuestionIdToAppealObjection({
                            questionId,
                            objection: null
                          })
                        );
                        dispatch(setQuestionIdToAppealComment({ questionId, comment: null }));
                      }
                    }}
                    marginRight="6px"
                  >
                    {t('general.cancel')}
                  </SButton> */}
                </SCol>
              </SRow>
            </SCard>
          </SCol>
        )}

        {!isEmpty(replyComment) && (
          <SCol span={24}>
            <SCard width="100%" borderRadius="4px">
              <SRow>
                <SCol span={22}>
                  <Editor
                    commentState={replyComment}
                    setCommentState={setReplyComment}
                    showTemplates={false}
                    showFlags={false}
                    showAttachments
                  />
                </SCol>
                <SCol span={2} paddingTop="56px" display="flex" justifyContent="flex-end">
                  <SButton
                    size="icon"
                    icon={<Icon icon={Send} size="24px" />}
                    border="none"
                    boxShadow="none"
                    color="var(--blue_6)"
                    onClick={sendReplyToAppealComment}
                    showLoad
                    disabled={
                      isEmpty(replyComment?.uploadedFiles) ? isEmpty(replyComment?.text) : false
                    }
                    style={{ backgroundColor: 'transparent' }}
                  />
                </SCol>
                <SButton onClick={() => setReplyComment(null)}>{t('general.cancel')}</SButton>
              </SRow>
            </SCard>
          </SCol>
        )}

        {replies.map(comment => (
          <SCol span={23} marginTop="-12px">
            <SCard rounded="4px" bodyPadding="12px">
              <SRow justify="space-between">
                <SCol>
                  <SRow>
                    <SCol>
                      <UserPreview
                        userId={comment?.authorId}
                        size="extraSmall"
                        showAvatar
                        disabled
                      />
                    </SCol>
                    <SCol marginLeft="6px">
                      <SText type="secondary">
                        {moment(comment?.updatedAt).format('DD/MM/YYYY, HH:mm')}
                      </SText>
                    </SCol>
                  </SRow>
                </SCol>
                {comment?.authorId === currentUser?.id ? (
                  <SCol
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                    style={{ position: 'absolute', right: '12px', top: '12px' }}
                  >
                    <StyledButton
                      size="icon"
                      icon={<Icon icon={Edit3} size="16px" />}
                      color="var(--black_6)"
                      backgroundColor="transparent"
                      onClick={editAppealComment}
                    />
                    <Popconfirm
                      title={t(
                        'components.commentList.comment.commentEditButton.confirmDelete.title'
                      )}
                      okText={t(
                        'components.commentList.comment.commentEditButton.confirmDelete.ok'
                      )}
                      cancelText={t(
                        'components.commentList.comment.commentEditButton.confirmDelete.cancel'
                      )}
                      onConfirm={() => deleteCommentHandler(comment?.id)}
                    >
                      <StyledButton
                        size="icon"
                        icon={<Icon icon={Trash2} size="16px" />}
                        color="var(--black_6)"
                        backgroundColor="transparent"
                      />
                    </Popconfirm>
                  </SCol>
                ) : (
                  <SButton
                    type="link"
                    padding="0"
                    textAlign="right"
                    onClick={() =>
                      setReplyComment({
                        parentId: appealComment?.id,
                        appelationId: appealId,
                        commentType: 'appellant_comment_reply'
                      })
                    }
                  >
                    {t('general.answer')}
                  </SButton>
                )}
                <SCol span={24}>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(comment.text, {
                        ALLOWED_ATTR: ['target', 'href']
                      })
                    }}
                    style={{ overflowWrap: 'break-word' }}
                  />
                </SCol>
                <SCol span={24}>
                  {comment.uploadedFiles?.map(file => (
                    <UploadedFile
                      uploadedFile={file}
                      allFiles={appealComment?.uploadedFiles}
                      onDelete={onDeleteFile}
                    />
                  ))}
                </SCol>
              </SRow>
            </SCard>
          </SCol>
        ))}
      </SRow>
    </SCol>
  );
};

const StyledButton = styled(SButton)`
  &.ant-btn {
    border: none;
    box-shadow: none;
    background-color: transparent;
    width: 24px !important;
  }

  &.ant-btn:hover {
    background-color: transparent;
  }
`;

export default QuestionAppealComment;
