export default {
  userSettingsPage: {
    categories: {
      settings: 'Settings',
      main: 'Main'
    },
    title: 'Profile settings',
    tabs: {
      info: {
        tabName: 'Information',
        form: {
          registrationDate: 'Registration date',
          companyName: 'Company name',
          role: 'Role',
          unit: 'Unit'
        }
      },
      accountSettings: {
        tabName: 'Account settings',
        form: {
          lastName: 'Last name',
          firstAndMiddleName: 'First Name',
          email: 'E-mail',
          phoneNumber: 'Phone number',
          locale: 'Application language'
        },
        avatar: {
          tabName: 'Avatar',
          updaloadAvatarlabel: 'Click to select a photo',
          avatarPreview: 'Preview: ',
          buttons: {
            loadAvatar: 'Upload',
            deleteAvatar: 'Delete photo'
          }
        }
      },
      securitySettings: {
        tabName: 'Access settings',
        form: {
          newPassword: 'New password',
          newPasswordConfirmation: 'Password confirmation'
        }
      }
    },
    buttons: { update: 'Update data' },
    messages: {
      dataSuccessfullyUpdated: 'Data updated',
      updateDataFailed: 'Update data failed',
      passwordSuccessfullyUpdated: 'Password updated',
      editingAnotherUsersSettingsPT1: 'Attention! You are now editing user settings',
      editingAnotherUsersSettingsPT2:
        'Go to the menu (in the upper right corner) and select the setting item to edit your page',
      form: {
        validateMessages: {
          enterLastName: 'Please enter last name',
          enterFirstAndMiddleName: 'Please enter first name',
          enterPassword: 'Please enter your password',
          enterPasswordConfirmation: 'Please confirm the password',
          passwordMismathing: 'Passwords do not match',
          passwordLenghtMismathing: 'Password must be more than 6 characters'
        }
      }
    },
    organization: {
      currentPlanSlug: 'Current tariff plan',
      registrationDate: 'Registration date',
      registeredUsers: 'Users registered',
      monthReviewsCount: 'Number of checks for the current month'
    }
  }
};
