import { Col, message, Modal, Row, Spin, Typography } from 'antd';
import { audioServiceOrigin } from 'core/config';
import qs from 'qs';
import React, { useCallback, useEffect, useRef } from 'react';
import Player from 'react-player';
import { useHistory } from 'react-router-dom';
import {
  setCurrentCallId,
  setUpdatingUrl,
  setUrl,
  updatePlayerState
} from 'redux/ui/recordPlayer/reducer';
import openSocket from 'socket.io-client';
import { useTranslation } from 'react-i18next';
import { isEqual } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import truncateString from 'core/utils/truncateString';
import AudioTrack from './AudioTrack';
import RecordControls from './RecordControls';

const { Text } = Typography;

const RecordPlayer = ({
  messages,
  onMessageClick,
  review,
  call,
  comments,
  allowAttachTags = true,
  showTags = true,
  shouldLoad = true
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    playbackRate,
    isPlaying,
    wasPlayed,
    forcePlayed,
    playedSeconds,
    url,
    updatingUrl
  } = useSelector(state => state.uiRecordPlayer);

  const { phoneCallMediaUrl, mediaUrl } = call;
  const unsafeRecordUrl = phoneCallMediaUrl || mediaUrl;

  const { t } = useTranslation();
  const player = useRef();

  const setTime = useCallback(
    seconds => {
      if (player.current && player.current.seekTo) {
        player.current.seekTo(seconds, 'seconds');
      }
    },
    [player?.current]
  );

  useEffect(() => {
    if (call) dispatch(setCurrentCallId(call.id));
    if (call.duration) dispatch(updatePlayerState({ duration: call.duration }));
  }, [call.id, call.duration]);

  useEffect(() => {
    const setInitState = async () => {
      const { search } = history.location;
      if (search) {
        const { t } = qs.parse(search, { ignoreQueryPrefix: true });
        if (t) {
          setTime(parseFloat(t));
          dispatch(
            updatePlayerState({
              playedSeconds: parseFloat(t),
              wasPlayed: false
            })
          );
        }
      }
    };
    setInitState();
  }, [history.location]);

  useEffect(() => {
    if (forcePlayed) {
      setTime(playedSeconds);
      dispatch(updatePlayerState({ forcePlayed: false }));
    }
  }, [playedSeconds]);

  const handleProgress = useCallback(
    ({ playedSeconds, played }) => {
      if (playedSeconds === 0 && !wasPlayed) {
        return;
      }
      dispatch(updatePlayerState({ playedSeconds, played }));
    },
    [dispatch]
  );

  useEffect(() => {
    if (!audioServiceOrigin) return dispatch(setUrl(null));

    dispatch(setUpdatingUrl(true));
    const audioServiceUrl = new URL(audioServiceOrigin);
    const socket = openSocket(audioServiceUrl.origin, {
      path: audioServiceUrl.pathname + 'socket.io',
      reconnectionAttempts: 5
    });
    socket.on('connect_error', () => dispatch(setUrl(null)));
    socket.on('connect_failed', () => dispatch(setUrl(null)));
    socket.on('disconnect', () => dispatch(setUrl(null)));
    socket.on('record trade error', () => dispatch(setUrl(null)));

    socket.emit('trade record url', { recordUrl: unsafeRecordUrl, callId: call.id });

    socket.on('traded record', ({ path }) => {
      dispatch(setUrl(audioServiceOrigin + path));
    });

    return () => {
      socket.emit('leave player page');
      dispatch(setUrl(null));
      dispatch(setUpdatingUrl(true));
      dispatch(updatePlayerState({ isPlaying: false }));
    };
  }, [unsafeRecordUrl, call.id]);

  if (updatingUrl) {
    return (
      <Row
        type="flex"
        justify="center"
        align="middle"
        gutter={[8, 8]}
        style={{ margin: '-4px', height: '134px', width: '100%' }}
      >
        <Col>
          <Spin spinning={updatingUrl} tip={t('components.recordPlayer.loading')} />
        </Col>
      </Row>
    );
  }

  const onPlay = () => {
    dispatch(updatePlayerState({ wasPlayed: true }));
    // ! remove autoplay attr from audio tag cuz it can cause double sound on some devices
    try {
      document.querySelector('audio').removeAttribute('autoplay');
    } catch (error) {
      console.log(error);
    }
  };

  const onError = () => {
    if (!url) {
      Modal.warning({
        title: t('constants.errors.loadingRecordError.title'),
        content: (
          <Row gutter={[16, 16]} style={{ margin: '-8px' }}>
            <Col span={24}>
              <Text>{t('constants.errors.loadingRecordError.content')}</Text>
            </Col>
            <Col span={24}>
              <Text
                copyable={{
                  text: unsafeRecordUrl,
                  tooltips: [
                    t('constants.errors.loadingRecordError.tooltip.copy'),
                    t('constants.errors.loadingRecordError.tooltip.copied')
                  ]
                }}
                className="truncated"
              >
                <a href={unsafeRecordUrl}>{truncateString(unsafeRecordUrl, 40)}</a>
              </Text>
            </Col>
          </Row>
        )
      });
    } else {
      message.warning(t('constants.errors.loadingRecordError.title'));
      setUrl(null);
    }
  };

  return (
    <div>
      <Player
        url={url || unsafeRecordUrl}
        onProgress={handleProgress}
        style={{ display: 'none' }}
        onDuration={duration => dispatch(updatePlayerState({ duration }))}
        loop={false}
        playing={isPlaying}
        playbackRate={parseFloat(playbackRate)}
        progressInterval={100}
        ref={player}
        onError={onError}
        onPlay={onPlay}
      />

      <AudioTrack
        comments={comments}
        setTime={setTime}
        messages={messages}
        onMessageClick={onMessageClick}
      />
      <RecordControls
        call={call}
        reviewId={review ? review.id : null}
        setTime={setTime}
        allowAttachTags={allowAttachTags}
        showTags={showTags}
        shouldLoad={shouldLoad}
        recordUrl={url || unsafeRecordUrl}
      />
    </div>
  );
};

export default React.memo(RecordPlayer, isEqual);
