import React, { useEffect } from 'react';
import { Row, Col } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import TimeSpecificSelector from 'components/Inputs/TimeSpecificSelector';
import SCard from 'components/Standard/SCard';
import { isEqual } from 'lodash';
import {
  setCurrentTimeRange,
  setCurrentChecklistDefinitionId
} from 'redux/ui/userAnalyticsPage/reducer';
import { useLocation } from 'react-router-dom';
import ChecklistDefinitionSelector from './ChecklistDefinitionSelector';

const MainControlsPanel = () => {
  const dispatch = useDispatch();

  const query = new URLSearchParams(useLocation().search);

  const timeFrom = query.get('filters[timeFrom]');
  const timeTo = query.get('filters[timeTo]');
  const symbolicTimeRange = query.get('filters[symbolicTimeRange]');
  const checklistDefinitionId = query.get('filters[checklistDefinitionId]');

  const changeTimeRange = ({ timeFrom, timeTo, symbolicTimeRange }) => {
    dispatch(
      setCurrentTimeRange({
        clientInteractionTimeFrom: timeFrom,
        clientInteractionTimeTo: timeTo,
        clientInteractionSymbolicTimeRange: symbolicTimeRange
      })
    );
  };

  useEffect(() => {
    if (symbolicTimeRange || (timeFrom && timeTo))
      changeTimeRange({ timeFrom, timeTo, symbolicTimeRange });
  }, []);

  useEffect(() => {
    if (checklistDefinitionId) {
      dispatch(setCurrentChecklistDefinitionId(checklistDefinitionId));
    }
  }, []);

  const currentTimeRange = useSelector(
    state => state.uiUserAnalyticsPage.currentTimeRange,
    isEqual
  );

  const timeRange = {
    timeFrom: currentTimeRange.clientInteractionTimeFrom,
    timeTo: currentTimeRange.clientInteractionTimeTo,
    symbolicTimeRange: currentTimeRange.clientInteractionSymbolicTimeRange
  };

  return (
    <SCard>
      <Row type="flex" align="middle" justify="space-between">
        <Col>
          <ChecklistDefinitionSelector />
        </Col>
        <Col>
          <TimeSpecificSelector value={timeRange} onChange={changeTimeRange} />
        </Col>
      </Row>
    </SCard>
  );
};

export default MainControlsPanel;
