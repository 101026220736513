import api from 'core/api';
import decamelize from 'decamelize-keys-deep';
import { processJsonApiObject } from 'core/jsonapi';
import * as actions from './reducer';

export const loadOrganization = () => {
  return async dispatch => {
    dispatch(actions.loadingOrganization());
    try {
      const response = await api.getOrganizationInfo();
      const organizationInfo = processJsonApiObject(response.body.data);
      dispatch(actions.organizationLoaded(organizationInfo));
      return organizationInfo;
    } catch (error) {
      console.log(error);
      dispatch(actions.catchError(error));
    }
  };
};

export const updateOrganization = ({ name, address, email, phoneNumber }) => {
  return async dispatch => {
    dispatch(actions.updatingOrganization());
    try {
      const response = await api.updateOrganizationInfo(
        decamelize({
          name,
          email,
          phoneNumber,
          address
        })
      );
      const organizationInfo = processJsonApiObject(response.body.data);
      dispatch(actions.organizationUpdated(organizationInfo));
      return organizationInfo;
    } catch (error) {
      console.log(error);
      dispatch(actions.catchError(error));
    }
  };
};
