import React from 'react';
import { orderBy, every } from 'lodash';
import { useSelector, shallowEqual } from 'react-redux';
import { Typography, Tooltip } from 'antd';
import WidgetTable from 'components/Dashboard/SmallWidgetTable';
import UserPreview from 'components/UserPreview/UserPreview';
import { useTranslation } from 'react-i18next';
import CommentsBar from 'components/Dashboard/CommentsBar';
import { WIDGET_VIEW_MODES } from 'core/utils/constants';
import { stringify } from 'core/utils/queryString';

const UsersTable = ({ tableData, viewMode, filters }) => {
  const { Text } = Typography;
  const { t } = useTranslation();

  const usersByIds = useSelector(state => state.usersResource.byIds, shallowEqual);

  const prepareData = viewMode => {
    switch (viewMode) {
      case WIDGET_VIEW_MODES.OPERATORS.value:
        return tableData.reduce((acc, item) => {
          if (every([item], { without_flag: 0, green: 0, red: 0, yellow: 0 })) return acc;

          const data = {
            id: item.id,
            lastName: usersByIds[item.id]?.lastName,
            comments: {
              without_flag: item.without_flag,
              red: item.red,
              yellow: item.yellow,
              green: item.green
            }
          };
          return [...acc, data];
        }, []);

      case WIDGET_VIEW_MODES.UNITS.value:
        return tableData.reduce((acc, item) => {
          if (every([item], { without_flag: 0, green: 0, red: 0, yellow: 0 })) return acc;

          const data = {
            id: item.id,
            lastName: item.name,
            name: item.name,
            comments: {
              without_flag: item.without_flag,
              red: item.red,
              yellow: item.yellow,
              green: item.green
            }
          };
          return [...acc, data];
        }, []);

      default:
        return [];
    }
  };

  const getSymbolicTimeRange = () => {
    if (filters.clientInteractionSymbolicTimeRange)
      return filters.clientInteractionSymbolicTimeRange;
    if (
      !filters.clientInteractionSymbolicTimeRange &&
      !filters.clientInteractionTimeFrom &&
      !filters.clientInteractionTimeTo
    )
      return 'all';
  };

  const columns = [
    {
      title: <Text>{t('dashboardPage.flagsAndComments.table.columns.name')}</Text>,
      dataIndex: 'id',
      key: 'id',
      ellipsis: true,
      render: (text, record) =>
        viewMode === WIDGET_VIEW_MODES.OPERATORS.value ? (
          <UserPreview
            userId={text}
            margin="0px"
            showAvatar
            showUnit
            link={`/user/${text}/charts${stringify({
              filters: {
                symbolicTimeRange: getSymbolicTimeRange(),
                timeFrom: encodeURIComponent(filters.clientInteractionTimeFrom || ''),
                timeTo: encodeURIComponent(filters.clientInteractionTimeTo || ''),
                checklistDefinitionId: filters.checklistDefinitionsIds
              }
            })}`}
          />
        ) : (
          <Tooltip title={record.name}>
            <Text>{record.name}</Text>
          </Tooltip>
        ),
      width: 270,
      sorter: (a, b) => a.lastName.localeCompare(b.lastName)
    },
    {
      title: <Text>{t('dashboardPage.flagsAndComments.table.columns.commentsCount')}</Text>,
      dataIndex: 'comments',
      key: 'comments',
      render: (text, record) => <CommentsBar comments={record.comments} textColor="#000000" />
    }
  ];

  return (
    <WidgetTable
      dataSource={orderBy(prepareData(viewMode), 'lastName')}
      columns={columns}
      pagination={false}
      size="small"
      scroll={{ y: 462 }}
      showSorterTooltip={false}
    />
  );
};

export default UsersTable;
