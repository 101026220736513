import Call from 'components/CallContent';
import TextCommunication from 'components/TextCommunicationContent';

const ContentTypeToComponent = {
  phoneCall: Call,
  textCommunication: TextCommunication,
  chat: TextCommunication
};

export default contentType => {
  return ContentTypeToComponent[contentType];
};
