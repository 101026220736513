export default {
  workPlanTasks: {
    taskPlaceholder: 'Задача',
    colorizedNumbers: {
      completed: 'Количество выполненых проверок',
      incompleted: 'Количество просроченых проверок',
      total: 'Количество оставшихся проверок'
    },
    tableColumns: {
      reviewsCount: 'Проверок',
      reviewsCount2: 'Проверки',
      completed: 'Выполнено',
      incompleted: 'Осталось',
      closeTask: 'Завершить задачу',
      stopRepeating: 'Остановить повторения',
      name: 'Название задачи',
      lastTaskTimeFrom: 'Дата начала',
      lastTaskTimeTo: 'Дата завершения',
      creator: 'Создатель',
      schedule: 'Повторение',
      repeatedCount: 'Запуски',
      reviewCreatedAt: 'Дата и время оценки'
    },
    assignments: {
      closeTask: 'Завершить задачу',
      close: 'Завершить',
      taskStartedAt: 'Дата запуска',
      assignmentsCount: 'Кол-во проверяющих',
      completedTaskItemsCount: 'Общее кол-во проверок'
    },
    filters: {
      createTask: 'Создать задачу'
    },
    confirmStopRepeating: 'Остановить повторения?',
    confirmClosing: 'Завершить задачу?',
    confirmDelete: 'Удалить конфигурацию?'
  }
};
