import { TagOutlined } from '@ant-design/icons';
import { Col, Dropdown, Menu, Row, Tooltip } from 'antd';
import CustomTag from 'components/Tags/CustomTag';
import { CHECKLIST_MANAGER_STATES } from 'core/utils/constants';
import { get } from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { taskDefinitionsResource } from 'redux/resources/taskDefinitions';
import { tasksResource } from 'redux/resources/tasks';
import { getReviewTaskDefinitionsIds } from 'redux/selectors/taskDefinitions';
import { getCurrentUser } from 'redux/selectors/users';
import { setChecklistManagerState } from 'redux/ui/checklistManager/reducer';
import { addTasks } from 'redux/ui/clientInteractionPage/reducer';
import styled from 'styled-components';
import TasksSection from './TasksSection';

const AttachTag = ({
  shouldLoad = true,
  allowAttachTags = true,
  showTags = true,
  block = true,
  loadTasks,
  loadTasksDefinitions,
  setChecklistManagerState,
  taskDefinitionsByIds,
  reviewTaskDefinitionsIds,
  uiTaskDefinitionsIds = [],
  addTasks,
  reviewId,
  currentUserId,
  currentReviewAuthorId,
  truncate
}) => {
  useEffect(() => {
    if (showTags) {
      shouldLoad && loadTasksDefinitions({ pagination: 'false' });
      if (reviewId) {
        loadTasks({ pagination: 'false', 'filters[reviews_ids]': reviewId });
        addTasks({ reviewId, taskDefinitionsIds: reviewTaskDefinitionsIds });
      }
    }
  }, []);
  const { t } = useTranslation();

  const onChange = ({ key }) => {
    setChecklistManagerState(CHECKLIST_MANAGER_STATES.EDITING);
    addTasks({
      reviewId,
      taskDefinitionsIds: uiTaskDefinitionsIds.includes(key)
        ? uiTaskDefinitionsIds.filter(id => id !== key)
        : [...uiTaskDefinitionsIds, key]
    });
  };

  const isAllowToEdit = () => {
    if (!reviewId) return true;
    if (reviewId && currentUserId === currentReviewAuthorId) return true;
    return false;
  };

  const allowToEditTags = isAllowToEdit() && allowAttachTags;

  const menu = (
    <Menu selectedKeys={uiTaskDefinitionsIds} onClick={onChange}>
      {Object.values(taskDefinitionsByIds).reduce((acc, taskDefinition) => {
        if (
          taskDefinition?.status === 'disabled' &&
          !uiTaskDefinitionsIds.includes(taskDefinition.id)
        ) {
          return acc;
        }
        return [
          ...acc,
          <StyledMenuItem value={taskDefinition.id} key={taskDefinition.id}>
            <CustomTag
              color={taskDefinition.labelColor}
              text={taskDefinition.labelName}
              truncate={truncate}
            />
          </StyledMenuItem>
        ];
      }, [])}
    </Menu>
  );

  return (
    <Row
      type="flex"
      style={{ marginBottom: '-4px' }}
      style={{ width: block ? '100%' : 'auto', height: '100%' }}
      align="middle"
    >
      <Col>{showTags && <TasksSection reviewId={reviewId} truncate={truncate} />}</Col>
      {allowToEditTags && (
        <Col style={{ minWidth: '24px', height: '16px' }}>
          <Tooltip title={t('components.recordPlayer.attachTag.title')} placement="topLeft">
            <Dropdown
              overlay={menu}
              trigger={['click']}
              placement="bottomLeft"
              getPopupContainer={() => document.querySelector('body')}
              overlayClassName="ant-dropdown-placement-bottomLeft"
              overlayStyle={{ maxHeight: '340px', overflow: 'hidden auto' }}
            >
              <StyledIcon />
            </Dropdown>
          </Tooltip>
        </Col>
      )}
    </Row>
  );
};

const StyledIcon = styled(TagOutlined)`
  &.anticon {
    font-size: var(--font-size);
    position: absolute;
  }
`;

const StyledMenuItem = styled(Menu.Item)`
  &&.ant-dropdown-menu-item-selected {
    background: var(--primary-light);
  }
`;

const mapStateToProps = (state, ownProps) => {
  const reviewId = get(ownProps, 'reviewId', undefined);
  const reviewsByIds = state.reviewsResource.byIds;
  const uiTaskDefinitionsIds = state.uiClientInteractionPage.tasks.taskDefinitionsIds;
  const taskDefinitionsByIds = Object.values(state.taskDefinitionsResource.byIds).filter(
    item => item.labelType === 'manual'
  );

  const reviewTaskDefinitionsIds = getReviewTaskDefinitionsIds(state, reviewId);
  const currentReviewAuthorId = get(reviewsByIds, `${reviewId}.reviewerId`, '');
  const currentUserId = get(getCurrentUser(state), 'id', '');

  return {
    taskDefinitionsByIds,
    uiTaskDefinitionsIds,
    reviewTaskDefinitionsIds,
    currentUserId,
    currentReviewAuthorId
  };
};

const mapDispatchToProps = {
  loadTasks: tasksResource.operations.load,
  loadTasksDefinitions: taskDefinitionsResource.operations.load,
  setChecklistManagerState,
  addTasks
};

export default connect(mapStateToProps, mapDispatchToProps)(AttachTag);
