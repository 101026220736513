import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import SRow from 'components/Standard/SRow';
import SCol from 'components/Standard/SCol';
import { Helmet } from 'react-helmet';
import { loadAppellationsDashboard } from 'redux/entities/dashboardPages/operations';
import LoadingDashboard from 'pages/DashboardPage/Components/LoadingDashboard';
import Filters from './Components/Filters';
import AppellationsByReviewers from './Components/Widgets/AppellationsByReviewers';
import AppellationsByAppellants from './Components/Widgets/AppellationsByAppellants';

const Analytics = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(loadAppellationsDashboard());
  }, []);

  const loading = useSelector(state => state.dashboardPages.loading);

  return (
    <SRow>
      <Helmet>
        <title>{t('pagesMeta.appealsPage.title')}</title>
      </Helmet>
      <SCol span={24}>
        <SRow>
          <SCol span={24}>
            <Filters />
          </SCol>
          <SCol span={24} padding="16px">
            {loading ? (
              <LoadingDashboard />
            ) : (
              <SRow gutter={[0, 16]}>
                <AppellationsByReviewers />
                <AppellationsByAppellants />
              </SRow>
            )}
          </SCol>
        </SRow>
      </SCol>
    </SRow>
  );
};

export default Analytics;
