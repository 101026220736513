import { Popover, Tooltip } from 'antd';
import SButton from 'components/Standard/SButton';
import React from 'react';
import styled from 'styled-components';

const StyledButton = styled(SButton)`
  & {
    color: ${({ active }) => (active ? 'var(--primary)' : 'inherit')};
  }
`;

export const IconButton = ({ popover, tooltip, button, children }) => {
  const Button = (
    <StyledButton type="link" {...button}>
      {children}
    </StyledButton>
  );

  if (tooltip) {
    return <Tooltip {...tooltip}>{Button}</Tooltip>;
  }

  if (popover) {
    return <Popover {...popover}>{Button}</Popover>;
  }
  return Button;
};
