import { createAction, createReducer } from '@reduxjs/toolkit';
import { SYMBOLIC_TIME_RANGE } from 'core/utils/constants';

const defaultState = {
  currentUser: undefined,
  currentChecklistDefinitionId: 'all',
  checklistDefinitionsIds: [],
  currentTimeRange: {
    clientInteractionTimeFrom: undefined,
    clientInteractionTimeTo: undefined,
    clientInteractionSymbolicTimeRange: SYMBOLIC_TIME_RANGE.THIS_MONTH
  },
  currentLevel: undefined,
  dataString: '',
  reviewsIds: [],
  allSkills: {
    questionGroups: {
      analytics: {},
      history: {}
    },
    questions: {
      analytics: {},
      history: {}
    }
  },
  answersHistoryQuestionId: undefined,
  scoresHistoryChecklistDefinitionId: undefined
};

export const setCurrentChecklistDefinitionId = createAction(
  'uiUserAnalyticsPage/setCurrentChecklistDefinitionId'
);
export const setChecklistDefinitionsIds = createAction(
  'uiUserAnalyticsPage/setChecklistDefinitionsIds'
);
export const setCurrentTimeRange = createAction('uiUserAnalyticsPage/setCurrentTimeRange');
export const setCurrentUser = createAction('uiUserAnalyticsPage/setCurrentUser');
export const setCurrentLevel = createAction('uiUserAnalyticsPage/setCurrentLevel');

export const setReviewsIds = createAction('uiUserAnalyticsPage/setReviewsIds');

export const skillsLoaded = createAction('uiUserAnalyticsPage/skillsLoaded');

export const setDataString = createAction('uiUserAnalyticsPage/setDataString');

export const setAnswersHistoryQuestionId = createAction(
  'uiUserAnalytics/setAnswersHistoryQuestionId'
);

export const setScoresHistoryChecklistDefinitionId = createAction(
  'uiUserAnalytics/scoresHistoryChecklistDefinitionId'
);

export const userAnalyticsPageReducer = createReducer(defaultState, {
  [setCurrentChecklistDefinitionId]: (state, { payload }) => {
    return { ...state, currentChecklistDefinitionId: payload, analytics: {} };
  },
  [setCurrentLevel]: (state, { payload }) => ({ ...state, setCurrentLevel: payload }),
  [setChecklistDefinitionsIds]: (state, { payload }) => {
    return { ...state, checklistDefinitionsIds: payload };
  },
  [setCurrentTimeRange]: (state, { payload }) => {
    return { ...state, currentTimeRange: payload };
  },
  [setCurrentUser]: (state, { payload }) => {
    return { ...state, currentUser: payload, analytics: {} };
  },
  [setReviewsIds]: (state, { payload }) => {
    return { ...state, reviewsIds: payload };
  },
  [skillsLoaded]: (state, { payload }) => {
    return { ...state, allSkills: payload };
  },
  [setDataString]: (state, { payload }) => {
    return { ...state, dataString: payload };
  },
  [setAnswersHistoryQuestionId]: (state, { payload }) => ({
    ...state,
    answersHistoryQuestionId: payload
  }),
  [setScoresHistoryChecklistDefinitionId]: (state, { payload }) => ({
    ...state,
    scoresHistoryChecklistDefinitionId: payload
  })
});

export default userAnalyticsPageReducer;
