import {
  camelCase,
  find,
  get,
  isEmpty,
  keyBy,
  omit,
  orderBy,
  reduce,
  snakeCase,
  uniqBy
} from 'lodash';
import moment from 'moment';
import { workPlanTaskConfigurationsResource } from 'redux/resources/workPlanTaskConfigurations';
import { getCustomFieldsByIds } from 'redux/selectors/customFields';
import { getScopedKey } from 'core/utils/addScopeToObject';
import decamilize from 'decamelize-keys-deep';
import { PERIOD_REPEAT } from 'core/utils/constants';

export const getDistributionByUserIds = (distribution, reviewerBindingsByIds) => {
  // * за такую разработку мне наверное прeмию дадут
  //  ? пример данных
  //  "reviewer_bindings": [
  // {
  //   "id": "id123",
  //   "user_id": "user_id",
  //   "chat_percentage_distribution": 0,
  //   "email_percentage_distribution": 0,
  //   "ticket_percentage_distribution": 0,
  //   "other_percentage_distribution": 0
  // }

  const clientInteractionTypes = Object.keys(distribution);
  const userIds = Object.keys(Object.values(distribution)[0].byUserIds);

  return userIds.reduce((acc, userId, i) => {
    const userDistributions = clientInteractionTypes.reduce(
      (acc, type) => ({
        ...acc,
        [snakeCase(`${type}PercentageDistribution`)]: distribution[type].byUserIds[userId]
      }),
      {}
    );
    return [
      ...acc,
      {
        id: find(reviewerBindingsByIds, { reviewerId: userId })?.id,
        position: i,
        reviewerId: userId,
        ...userDistributions
      }
    ];
  }, []);
};

const clientInteractionsTypesConfigsToFiltersAttributes = (
  clientInteractionsTypesConfigs,
  isNew
) => {
  const handleAttribute = (field, position) => {
    if (field.customFieldId) {
      return { ...omit(field, ['value', 'key', 'type']), position };
    }

    return omit(
      {
        ...field,
        key: snakeCase(field.key),
        settings: decamilize(field?.settings || {}),
        position
      },
      ['type', 'value']
    );
  };
  return Object.values(clientInteractionsTypesConfigs).map((config, position) => ({
    ...omit(
      config,
      isNew ? ['fields', 'id', 'type', 'fieldsIds'] : ['fields', 'type', 'fieldsIds']
    ),
    position,
    fieldsAttributes: config.fields.map(handleAttribute)
  }));
};

export const mapUiDataForRequest = ({
  name,
  schedule,
  clientInteractionsTypesConfigs,
  distribution,
  reviewerBindingsByIds,
  isNew = false,
  data = {}
}) => {
  const dayTimeFrom = schedule.dayTimeFrom.diff(
    schedule.dayTimeFrom.clone().startOf('day'),
    'seconds'
  );

  const dayTimeTo = schedule.dayTimeTo.diff(schedule.dayTimeTo.clone().startOf('day'), 'seconds');

  return {
    ...data,
    name,
    scheduleAttributes: {
      position: 1,
      id: schedule?.id || undefined,
      periodTimeFrom: schedule.periodTimeFrom
        .startOf('day')
        .utc(true)
        .toISOString(),
      periodTimeTo: schedule.periodTimeTo
        .startOf('day')
        .utc(true)
        .toISOString(),
      dayTimeFrom,
      dayTimeTo,
      stopRepeatingAt: schedule?.stopRepeatingAt
        ?.startOf('day')
        ?.utc(true)
        ?.toISOString(),
      customRepeatEveryPeriod: schedule.customRepeatEveryPeriod || false,
      repeatEveryCount: schedule?.repeatEveryCount,
      repeatEvery:
        schedule?.customRepeatEveryPeriod && schedule?.repeatEvery === PERIOD_REPEAT.never.value
          ? PERIOD_REPEAT.day.value
          : schedule?.repeatEvery,
      timezoneShiftInSeconds: moment().utcOffset() * 60
    },
    reviewerBindingsAttributes: getDistributionByUserIds(distribution, reviewerBindingsByIds),
    filtersAttributes: clientInteractionsTypesConfigsToFiltersAttributes(
      clientInteractionsTypesConfigs,
      isNew
    )
  };
};

const scheduleResourceToUi = ({
  periodTimeFrom,
  periodTimeTo,
  stopRepeatingAt,
  dayTimeFrom,
  dayTimeTo,
  ...resource
}) => {
  return {
    ...resource,
    periodTimeFrom: moment(periodTimeFrom),
    periodTimeTo: moment(periodTimeTo),
    stopRepeatingAt: stopRepeatingAt ? moment(stopRepeatingAt) : undefined,
    dayTimeFrom: moment()
      .startOf('day')
      .add(dayTimeFrom, 'seconds'),
    dayTimeTo: moment()
      .startOf('day')
      .add(dayTimeTo, 'seconds')
  };
};

const filtersResourcesToUi = (filtersResources, filerFieldsByIds, customFieldsByIds) => {
  return keyBy(
    orderBy(
      filtersResources.map(resource => ({
        ...resource,
        fields: resource.fieldsIds.map(id => {
          const field = filerFieldsByIds[id];
          const customField = get(customFieldsByIds, field?.customFieldId);
          return isEmpty(customField)
            ? { ...field, key: camelCase(field.key) }
            : {
                ...field,
                key: getScopedKey('customField', customField?.key)
              };
        })
      })),
      'position'
    ),
    'id'
  );
};

const reviewersBindingsResourcesToUi = (reviewersBindingsResources, clientInteactionsTypes) => {
  return reduce(
    clientInteactionsTypes,
    (acc, type) => {
      return {
        ...acc,
        [type]: {
          byIds: reduce(
            reviewersBindingsResources,
            (acc, resource) => ({
              ...acc,
              [resource.reviewerId]: resource[camelCase(`${type}PercentageDistribution`)]
            }),
            {}
          ),
          totalDistributed: reduce(
            reviewersBindingsResources,
            (acc, resource) => acc + resource[camelCase(`${type}PercentageDistribution`)],
            0
          )
        }
      };
    },
    {}
  );
};

export const loadConfigurationToUi = id => async (dispatch, getState) => {
  const configuration = await dispatch(
    workPlanTaskConfigurationsResource.operations.loadById({
      id,
      include: 'filters.fields,reviewer_bindings.reviewer,schedule'
    })
  );

  const state = getState();
  const scheduleResource = state.workPlanTaskSchedulesResource.byIds[configuration.scheduleId];
  const filtersResources = configuration.filtersIds.map(
    id => state.workPlanFiltersResource.byIds[id]
  );
  const reviewersBindingsResources = configuration.reviewerBindingsIds.map(
    id => state.workPlanReviewerBindingsResource.byIds[id]
  );
  const filerFieldsByIds = state.workPlanFilterFieldsResource.byIds;
  const customFieldsByIds = getCustomFieldsByIds(state);
  return {
    name: configuration.name,
    schedule: scheduleResourceToUi(scheduleResource),
    clientInteractionsTypesConfigs: filtersResourcesToUi(
      filtersResources,
      filerFieldsByIds,
      customFieldsByIds
    ),
    distribution: reviewersBindingsResourcesToUi(
      reviewersBindingsResources,
      uniqBy(filtersResources.map(({ clientInteractionType }) => clientInteractionType))
    ),
    reviewersIds: reviewersBindingsResources.map(({ reviewerId }) => reviewerId)
  };
};
