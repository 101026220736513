import { ANALYTICS_WIDGETS_NAMES } from 'core/utils/constants';
import { loadAnalyticsWidgets } from 'redux/entities/analytics/widgets/operations';
import { checklistDefinitionsResource } from 'redux/resources/checklistDefinitions';
import { usersResource } from 'redux/resources/users';

export const loadTeamAnalytics = ({ filters }) => {
  return async dispatch => {
    const widgetNames = [
      ANALYTICS_WIDGETS_NAMES.CHECKLIST_DEFINITION_AVERAGE_SCORE_BY_OPERATORS,
      ANALYTICS_WIDGETS_NAMES.REVIEWS_COUNT_BY_OPERATORS,
      ANALYTICS_WIDGETS_NAMES.CHECKLIST_DEFINITION_COLOR_ZONES_BY_OPERATORS,
      ANALYTICS_WIDGETS_NAMES.RATING_FLAGS_COUNT_BY_OPERATORS
    ];

    dispatch(usersResource.operations.load({ include: 'unit,level', pagination: false }));

    dispatch(
      checklistDefinitionsResource.operations.load({
        pagination: false,
        sort: 'created_at'
      })
    );

    dispatch(loadAnalyticsWidgets({ widgetNames, filters, withDynamics: true }));
  };
};
