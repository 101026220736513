import { apiPath } from 'core/api';
import axios from 'axios';
import parseOptionsForApi from 'core/utils/parseOptionsForApi';
import { stringify } from 'core/utils/queryString';
import { apiUrl } from 'core/config';
import { createReduxResource } from 'core/redux/resources';
import camelcaseKeysDeep from 'camelcase-keys-deep';

export const clientInteractionsResource = createReduxResource({
  name: 'clientInteractions',
  customOperations: {
    loadMeta: {
      parseOptions: false,
      // * endpoint function that fires on operation call
      callEndpoint: async ({ options = {}, cancelTokenSource }) => {
        const res = await axios.get(
          `${apiUrl}${apiPath}/client_interactions/meta${stringify(parseOptionsForApi(options))}`,
          {
            cancelToken: cancelTokenSource.token,
            headers: {
              'Content-Type': 'application/json',
              'access-token': localStorage.getItem('access-token'),
              client: localStorage.getItem('client'),
              uid: localStorage.getItem('uid')
            }
          }
        );
        return {
          ...res,
          body: {
            data: res.data
          }
        };
      },

      // * calls if request was successfull
      onSuccess: ({ result, response, dispatch }) => {
        dispatch({ type: 'uiClientInteractionPage/updateAxiosToken', payload: null });
        return camelcaseKeysDeep(response.data);
      }
    }
  }
});
