export default {
  integrationsSettingsPage: {
    proceed: 'Continue',
    save: 'Save',
    delete: 'Delete',
    sync: 'Synchronize',
    selectSyncPeriod: 'Select sync period',
    deleteConfirm: {
      title: 'Remove integration?',
      ok: 'Yes',
      cancel: 'No'
    },
    nameAndType: {
      name: 'Name',
      integrationName: 'Integration name',
      type: 'Type',
      integrationType: 'Integration Type',
      yourB24address: 'Your Bitrix 24 address'
    },
    integrationPage: {
      title: 'Integration',
      sync: 'Synchronization',
      users: 'Users',
      integrationSettings: 'Integration Settings',
      customFieldsSettings: {
        title: 'Custom Fields Settings',
        addField: 'Add field',
        deleteConfirm: {
          title: 'Remove custom field',
          okText: 'Delete'
        },
        editor: {
          fieldName: 'Field name',
          fieldKey: 'Field Key',
          fieldType: 'Field type',
          usedForFilters: 'Used for filtering',
          usedForReviews: 'Used for ratings',
          save: 'Save',
          edit: 'Edit',
          delete: 'Delete'
        }
      },
      testRequest: {
        title: 'Test Request',
        success: 'Added communication to the system, available at this link',
        failure: 'Failed to add test communication',
        makeRequest: 'Make a request'
      }
    },
    messages: {
      integrationSuccessfullyCreate: 'The integration has been successfully created',
      integrationCreateFailed: 'Failed to create integration'
    },
    addIntegration: {
      title: 'Add Integration',
      yourIntegrations: 'Your integrations'
    },
    manageUserAccounts: {
      totalUsers: 'Users',
      activeUsers: 'Active',
      selectAll: 'Select All',
      columns: {
        user: 'Employee',
        email: 'Email',
        id: 'ID',
        sync: 'Sync'
      },
      add: 'Add',
      downloadCSV: 'Download CSV example',
      manual: 'Manually',
      fromCSV: 'Via CSV',
      sendingFile: 'Sending a file ...',
      csvModal: {
        title: 'Congratulations',
        content: 'You have uploaded all users from your CSV file!',
        okText: 'Continue'
      },
      usersAlreadyInSystem: 'Qolio already has users with this email',
      accountToUsers: 'These accounts will be added to users',
      brokenEmail: 'The user does not have an email address'
    },
    createCustomIntegration: { addUsersTitle: 'Add users' },
    createSaasIntegration: {
      errorMessage: 'An error occurred while syncing data with',
      errorMessageContent: 'Please reinstall the app or email us at info@qolio.io',
      sync: 'Synchronization'
    },
    customUserAccountForm: {
      enterEmail: 'Enter email',
      enterCorrectEmail: 'Wrong email format',
      sync: 'Syncing',
      notSync: "Doesn't sync",
      enterId: 'Enter an Id'
    },
    sipuniAccountsImported:
      'User accounts are imported. To configure them, you need to link them to accounts in Qolio. To do this go to ',
    urlAndToken: { generate: 'Generate' }
  }
};
