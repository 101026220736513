import React, { useState } from 'react';
import { Col, Tooltip, Row } from 'antd';
import { updateDashboardWidget } from 'redux/ui/widgetSettingsModal/operations';
import { connect } from 'react-redux';
import { get } from 'lodash';
import ExcelIcon from 'images/ExcelIcon';
import { Trans, useTranslation } from 'react-i18next';
import moment from 'moment';
import { RATING_MODE, WIDGET_VIEW_MODES } from 'core/utils/constants';
import { LoadingOutlined } from '@ant-design/icons';
import { IconButton } from 'components/Buttons/IconButton';
import WidgetViewChanger from 'components/Dashboard/WidgetViewChanger';
import { SettingsButtonCol, WidgetNameCol, LargeWidgetCard, TimePeriod } from '../../styled';
import SettingsModal from '../../Components/SettingsModal';
import {
  widgetNameSetter,
  widgetPeriodSetter,
  widgetInfoTextSetter,
  getDateSelectorType,
  exportWidgetData
} from '../../utils';
import { prepareColumns, prepareTableData } from './prepareTableData';
import { prepareBarData, prepareLineData } from './prepareChartData';
import EmptyWidget from '../../Components/EmptyWidget';
import TableView from './Views/TableView';
import ChartView from './Views/ChartView';

const TableReviewsCountByOperators = ({
  widgetData,
  usersByIds,
  unitsByIds,
  widgetsAnalyticsByIds,
  checklistDefinitionsByIds,
  loadingWidgetId,
  updateDashboardWidget
}) => {
  const { t } = useTranslation();
  const { id, type, filters, name } = widgetData;
  const dateSelectorType = getDateSelectorType({ widgetType: type });
  const [loadingExport, setLoadingExport] = useState(false);
  const viewMode = get(filters, 'viewMode', WIDGET_VIEW_MODES.REVIEWERS.value);

  const widgetValue = get(widgetsAnalyticsByIds, id, {});
  const metadata = widgetValue?.metadata;
  const timeFrom = metadata?.time_from;
  const timeTo = metadata?.time_to;

  const checklistDefinitionAverageScoreByOperatorsHistory = get(
    widgetValue,
    'checklist_definition_average_score_by_operators_history',
    []
  );

  const checklistDefinitionAverageScore = get(widgetValue, 'checklist_definition_average_score');

  const reviewsCountByOperatorsHistory = get(widgetValue, 'reviews_count_by_operators_history', []);

  const reviewsCountByOperators = get(widgetValue, 'reviews_count_by_operators', []);

  const checklistDefinitionAverageScoreByOperators = get(
    widgetValue,
    'checklist_definition_average_score_by_operators',
    []
  );

  const checklistDefinition = get(checklistDefinitionsByIds, filters.checklistDefinitionsIds, {});

  const colorZones = get(checklistDefinition, 'colorZones', {});

  const checklistDefinitionMax = () => {
    const currentChecklistRatingMode = get(
      checklistDefinitionsByIds,
      `${filters.checklistDefinitionsIds}.ratingMode`
    );

    return currentChecklistRatingMode === RATING_MODE.PERCENTAGE
      ? 100
      : get(checklistDefinitionsByIds, `${filters.checklistDefinitionsIds}.max`);
  };

  const onChangeViewMode = value => {
    updateDashboardWidget({
      id,
      type,
      filters: { ...filters, viewMode: value }
    });
  };

  const tableColumns = prepareColumns({
    filters,
    timeFrom,
    timeTo,
    colorZones,
    checklistDefinition
  });

  const tableRows = prepareTableData({
    filters,
    timeFrom,
    timeTo,
    usersByIds,
    unitsByIds,
    checklistDefinition,
    checklistDefinitionAverageScore,
    checklistDefinitionAverageScoreByOperatorsHistory,
    checklistDefinitionAverageScoreByOperators,
    reviewsCountByOperatorsHistory,
    reviewsCountByOperators
  });

  const isEmptyWidgetData = tableRows.length === 2;

  const barData = prepareBarData({
    filters,
    timeFrom,
    timeTo,
    usersByIds,
    checklistDefinitionAverageScoreByOperatorsHistory
  });

  const lineData = prepareLineData({
    filters,
    timeFrom,
    timeTo,
    checklistDefinitionAverageScoreByOperatorsHistory
  });

  const body = {
    widgetType: type,
    widgetData: {
      columns: tableColumns.map(item => ({
        ...item,
        title: ['name', 'totalScore', 'reviewsCount', 'unitName'].includes(item.key)
          ? t(item?.title?.props?.i18nKey)
          : moment(item.key).format('DD/MM')
      })),
      rows: tableRows.map(item => ({
        ...item,
        name: item.key === 'header' || item.key === 'footer' ? t(item.name) : item.name
      }))
    }
  };

  return (
    <>
      {loadingWidgetId === id || isEmptyWidgetData ? (
        <EmptyWidget key={id} widgetData={widgetData} loadingWidgetId={loadingWidgetId} />
      ) : (
        <Col span={24}>
          <LargeWidgetCard>
            <Row type="flex" justify="space-between" align="top">
              <WidgetNameCol span={8}>
                <Row type="flex" gutter={[6, 0]} justify="start">
                  <Col>{widgetInfoTextSetter({ type })}</Col>
                  <Col>
                    <Tooltip title={widgetNameSetter({ name, type })}>
                      <span>{widgetNameSetter({ name, type })}</span>
                    </Tooltip>
                  </Col>
                </Row>

                <TimePeriod type="secondary">
                  {widgetPeriodSetter(filters, dateSelectorType)}
                </TimePeriod>
              </WidgetNameCol>
              <SettingsButtonCol span={8}>
                <Row type="flex" align="start" gutter={[4, 0]}>
                  <Col>
                    <WidgetViewChanger value={viewMode} onChange={onChangeViewMode} />
                  </Col>
                  <Col>
                    <IconButton
                      tooltip={{ title: t('dashboardPage.widget.buttons.export') }}
                      button={{
                        icon: loadingExport ? (
                          <LoadingOutlined spin />
                        ) : (
                          <ExcelIcon color="rgba(38, 38, 38, 0.75)" />
                        ),
                        onClick: () =>
                          loadingExport ? null : exportWidgetData({ body, setLoadingExport }),
                        size: 'icon'
                      }}
                    />
                  </Col>
                  <Col>
                    <SettingsModal widgetData={widgetData} />
                  </Col>
                </Row>
              </SettingsButtonCol>
            </Row>
            {viewMode === WIDGET_VIEW_MODES.TABLE.value ? (
              <TableView
                columns={tableColumns.filter(item => item.key !== 'unitName')}
                dataSource={tableRows}
              />
            ) : (
              <ChartView
                dataSource={{ table: checklistDefinitionAverageScoreByOperators }}
                checklistDefinition={checklistDefinition}
                colorZones={colorZones}
                checklistDefinitionMax={checklistDefinitionMax()}
                barData={barData}
                lineData={lineData}
                filters={filters}
                widgetId={id}
              />
            )}
          </LargeWidgetCard>
        </Col>
      )}
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  const checklistDefinitionsByIds = state.checklistDefinitionsResource.byIds;
  const questionGroupsbyIds = state.questionGroupsResource.byIds;
  const maxValue = get(
    checklistDefinitionsByIds[ownProps.widgetData.filters.checklistDefinitionsIds],
    'max',
    ''
  );
  const usersByIds = state.usersResource.byIds;
  const unitsByIds = state.unitsResource.byIds;

  return {
    widgetsAnalyticsByIds: state.dashboardAnalytics.byIds,
    checklistDefinitionsByIds,
    maxValue,
    questionGroupsbyIds,
    usersByIds,
    unitsByIds
  };
};

const mapDispatchToProps = {
  updateDashboardWidget
};

export default connect(mapStateToProps, mapDispatchToProps)(TableReviewsCountByOperators);
