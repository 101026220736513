import { Col, Row, Skeleton } from 'antd';
import CommentsList from 'components/Comments/CommentsList/CommentsList';
import SButton from 'components/Standard/SButton';
import SCard from 'components/Standard/SCard';
import SCol from 'components/Standard/SCol';
import withConditionalRender from 'components/WithConditionalRender/withConditionalRender';
import { PERMISSIONS } from 'core/utils/constants';
import { isEmpty } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import CreateCommentForm from './CreateCommentForm';

const CommentsPanel = ({
  loading,
  comments = [],
  showCommentsList,
  allowCommenting = true,
  allowActions = true,
  allowSelectTimestamp = true,
  addingComment,
  onAddCommentButton,
  onCancelComment,
  onDeleteComment,
  onUpdateComment,
  onCommentSave,
  seconds,
  isQuestionView
}) => {
  const ConditionalAddCommentButton = withConditionalRender(
    { allowedPermissions: [PERMISSIONS.CAN_MAKE_REVIEW] },
    SButton
  );
  const { t } = useTranslation();

  if (loading) {
    return (
      <Row style={{ background: 'white' }}>
        <Skeleton active />
      </Row>
    );
  }
  return (
    <>
      {showCommentsList ? (
        <Row>
          <Col span={24}>
            <CommentsList
              isQuestionView={isQuestionView}
              allowActions={allowActions}
              onDelete={onDeleteComment}
              onUpdate={onUpdateComment}
              comments={comments}
              loading={loading}
            />
          </Col>
        </Row>
      ) : null}

      {allowCommenting && (
        <Row>
          <StyledCol span={24} haveComments={!isEmpty(comments)}>
            <Row>
              {addingComment ? (
                <SCol span={24}>
                  <CreateCommentForm
                    onCancel={onCancelComment}
                    onCommentSave={onCommentSave}
                    allowSelectTimestamp={allowSelectTimestamp}
                    seconds={seconds}
                  />
                </SCol>
              ) : (
                <SCol span={24}>
                  <SCard bordered shadowed>
                    <ConditionalAddCommentButton
                      size="large"
                      boxShadow="none"
                      fontSize="16px"
                      height="40px"
                      width="100%"
                      border="1px dashed var(--blue_2)"
                      color="var(--blue_6)"
                      block
                      onClick={onAddCommentButton}
                    >
                      {t('components.commentsPanel.buttons.addComment')}
                    </ConditionalAddCommentButton>
                  </SCard>
                </SCol>
              )}
            </Row>
          </StyledCol>
        </Row>
      )}
    </>
  );
};

const StyledCol = styled(Col)`
  &.ant-col {
    padding: 0;
    margin-top: ${({ haveComments }) => (haveComments ? '8px' : '0')};
  }
`;

export default CommentsPanel;
