import { Trash2, Edit, AlertCircle, MoreVertical } from 'react-feather';
import { Col, Dropdown, InputNumber, Menu, Modal, Row, Tooltip, Typography } from 'antd';
import RateValues from 'components/Inputs/RateValues';
import Icon from 'components/Icon';
import { CHECKLIST_DEFINITION_STATUS, RATING_METHOD, SCALE_TYPES } from 'core/utils/constants';
import { debounce, get } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { questionToGroupBindingsResource } from 'redux/resources/questionToGroupBindings';
import { setEditingQuestionWithBinding } from 'redux/ui/questionModal/reducer';
import { MarginLeftButton, QestionCard, StyledCol } from './styled';

const Question = ({
  sumPercentage,
  updateBinding,
  questionId,
  bindingId,
  bindingsByIds,
  questionsByIds,
  ratingMethod,
  provided,
  currentChecklist,
  isDragging,
  deleteBinding,
  setEditingQuestionWithBinding
}) => {
  const { Text } = Typography;
  const { t } = useTranslation();

  const {
    scaleType = SCALE_TYPES.max_5.type,
    valuesDisplayType = 'numbers',
    status
  } = currentChecklist;
  const currentQuestion = get(questionsByIds, `${questionId}`, {});
  const { name, ratingValues, ratingValuesViews } = currentQuestion;

  const handlMenuClick = e => {
    switch (e.key) {
      case 'edit':
        setEditingQuestionWithBinding({
          question: questionsByIds[questionId],
          questionToGroupBinding: binding
        });
        break;

      case 'copy':
        // * maybe will be implemented in BE
        break;

      case 'delete':
        Modal.confirm({
          title: t('checklistsPage.question.confirmDelete.title'),
          content: t('checklistsPage.question.confirmDelete.description'),
          okText: t('checklistsPage.question.confirmDelete.ok'),
          cancelText: t('checklistsPage.question.confirmDelete.cancel'),
          okType: 'danger',
          onOk: async () => deleteBinding({ id: bindingId })
        });
        break;
      default:
        console.log();
    }
  };

  const menu = (
    <Menu onClick={e => handlMenuClick(e)}>
      <Menu.Item key="edit">
        <Icon icon={Edit} />
        <span>{t('checklistsPage.question.menu.edit')}</span>
      </Menu.Item>
      {status === CHECKLIST_DEFINITION_STATUS.DRAFT.value && (
        <Menu.Item key="delete" style={{ color: 'red' }}>
          <Icon icon={Trash2} />
          <span>{t('checklistsPage.question.menu.delete')}</span>
        </Menu.Item>
      )}
    </Menu>
  );

  const binding = bindingsByIds[bindingId];

  // * binding can be undefined in moment after deleting but before creating new one
  if (!binding) {
    return (
      <Col key={questionId}>
        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
          <QestionCard isDragging={isDragging}>
            <Row type="flex" align="middle">
              <Col span={10}>
                <Text strong>{name}</Text>
              </Col>
              <StyledCol span={14} style={{ display: 'flex', alignItems: 'center' }}>
                <RateValues
                  size="medium"
                  ratingValues={ratingValues}
                  ratingValuesViews={ratingValuesViews}
                  scaleType={scaleType}
                  valuesDisplayType={valuesDisplayType}
                  disabled
                />

                <Dropdown overlay={menu} trigger={['click']}>
                  <MarginLeftButton icon={<Icon icon={MoreVertical} />} />
                </Dropdown>
              </StyledCol>
            </Row>
          </QestionCard>
          {provided.placeholder}
        </div>
      </Col>
    );
  }
  const { percentage, autofailEnabled } = binding;
  const maxPercentage = sumPercentage >= 100 ? percentage || 1 : 100;
  const updatePercentage = debounce(newPercentage => {
    if (newPercentage && sumPercentage - percentage + newPercentage <= 100) {
      updateBinding({ id: bindingId, percentage: newPercentage });
    }
  }, 200);

  return (
    <Col key={questionId}>
      <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
        <QestionCard isDragging={isDragging}>
          <Row type="flex" align="middle">
            <Col span={10}>
              <Text strong>{name}</Text>
            </Col>
            <StyledCol span={14} style={{ display: 'flex', alignItems: 'center' }}>
              {autofailEnabled && (
                <Tooltip title={t('checklistsPage.question.tooltip.title')}>
                  <AlertCircle color="var(--red_primary)" style={{ marginRight: '8px' }} />
                </Tooltip>
              )}
              <RateValues
                size="medium"
                ratingValues={ratingValues}
                ratingValuesViews={ratingValuesViews}
                scaleType={scaleType}
                valuesDisplayType={valuesDisplayType}
                disabled
              />

              {ratingMethod === RATING_METHOD.WEIGHTED && (
                <>
                  <Text style={{ margin: '0px 8px' }}>{t('checklistsPage.question.weight')}</Text>
                  <InputNumber
                    min={1}
                    max={maxPercentage}
                    onChange={updatePercentage}
                    value={percentage}
                    formatter={value => `${value}%`}
                    parser={value => value.replace('%', '')}
                  />
                </>
              )}
              <Dropdown overlay={menu} trigger={['click']}>
                <MarginLeftButton icon={<Icon icon={MoreVertical} />} />
              </Dropdown>
            </StyledCol>
          </Row>
        </QestionCard>
        {provided.placeholder}
      </div>
    </Col>
  );
};

const mapStateToProps = state => {
  return {
    bindingsByIds: state.questionToGroupBindingsResource.byIds,
    questionsByIds: state.questionsResource.byIds
  };
};

const mapDispatchToProps = {
  deleteBinding: questionToGroupBindingsResource.operations.deleteById,
  updateBinding: questionToGroupBindingsResource.operations.updateById,
  setEditingQuestionWithBinding
};

export default connect(mapStateToProps, mapDispatchToProps)(Question);
