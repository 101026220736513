import { Button, Modal, Typography } from 'antd';
import amo from 'images/integrations/icons/amo.png';
import bitrix from 'images/integrations/icons/b24.png';
import usedesk from 'images/integrations/icons/usedesk.jpg';
import binotel from 'images/integrations/icons/binotel.png';
import SCard from 'components/Standard/SCard';
import React, { useCallback, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useMount } from 'ahooks';
import { integrationsResource } from 'redux/resources/integrations';
import { isEqual, orderBy } from 'lodash';
import { INTEGRATIONS } from 'core/utils/constants';
import { CustomAvatar } from 'components/UserPreview/UserPreview';
import SRow from 'components/Standard/SRow';
import SCol from 'components/Standard/SCol';
import { useTranslation } from 'react-i18next';
import { showRedirectModal } from 'redux/entities/bitrixIntegration/operations';
import BitrixService from 'core/services/bitrix';

const { Text } = Typography;

const integrationTypeToAvatar = {
  [INTEGRATIONS.amocrm.type]: amo,
  [INTEGRATIONS.bitrix_crm.type]: bitrix,
  [INTEGRATIONS.usedesk.type]: usedesk,
  [INTEGRATIONS.binotel.type]: binotel
};

const IntegrationsList = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { url } = useRouteMatch();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const integrations = useSelector(
    state => orderBy(Object.values(state.integrationsResource.byIds), 'createdAt', 'desc'),
    isEqual
  );

  const loadIntegrations = async () => {
    setLoading(true);
    await dispatch(integrationsResource.operations.load());
    setLoading(false);
  };

  useMount(() => {
    loadIntegrations();
  });

  const onDelete = useCallback(
    id => () => {
      Modal.confirm({
        title: t('integrationsSettingsPage.deleteConfirm.title'),
        okText: t('integrationsSettingsPage.deleteConfirm.ok'),
        okType: 'danger',
        onOk: async () => dispatch(integrationsResource.operations.deleteById({ id }))
      });
    },
    [dispatch]
  );

  const goToIntegration = async integration => {
    if (integration.integrationType === INTEGRATIONS.bitrix_crm.type && !integration.initialized) {
      if (BitrixService.isIntegrated) {
        const auth = await BitrixService.getAuth();
        if (auth.domain === integration.endpoint) {
          return history.push(`${url}/${integration.integrationType}/new/${integration.id}`);
        }
      }

      return showRedirectModal(integration.endpoint);
    }

    history.push(`${url}/${integration.integrationType}/${integration.id}`);
  };

  if (loading) {
    return (
      <SRow type="flex" marginLeft="-14px">
        <SCol sm={12} lg={12} xl={8}>
          <SCard
            bodyPadding="38px 24px 24px 24px"
            height="178px"
            marginBottom="20px"
            position="relative"
            marginLeft="14px"
            shadowed
            loading
          />
        </SCol>
        <SCol sm={12} lg={12} xl={8}>
          <SCard
            bodyPadding="38px 24px 24px 24px"
            height="178px"
            marginBottom="20px"
            position="relative"
            marginLeft="14px"
            shadowed
            loading
          />
        </SCol>
        <SCol sm={12} lg={12} xl={8}>
          <SCard
            bodyPadding="38px 24px 24px 24px"
            height="178px"
            marginBottom="20px"
            position="relative"
            marginLeft="14px"
            shadowed
            loading
          />
        </SCol>
      </SRow>
    );
  }

  return (
    <SRow type="flex" marginLeft="-14px">
      {integrations.map(integration => (
        <SCol sm={12} lg={12} xl={8}>
          <SCard
            bodyPadding="38px 24px 24px 24px"
            height="178px"
            marginBottom="20px"
            position="relative"
            marginLeft="14px"
            shadowed
          >
            <SRow gutter={[0, 32]} align="middle">
              <SCol span={24}>
                <SRow align="middle" gutter={[12, 0]} flexWrap="nowrap">
                  <SCol>
                    <CustomAvatar
                      style={{
                        background: 'var(--primary)',
                        display: 'flex',
                        alignItems: 'center'
                      }}
                      size="medium"
                      src={integrationTypeToAvatar[integration?.integrationType]}
                    >
                      {'</>'}
                    </CustomAvatar>
                  </SCol>
                  <SCol flex="auto">
                    <SRow align="middle">
                      <SCol span={24} className="truncated" maxWidth="calc(100% - 64px)">
                        <Text>
                          {integration?.name || INTEGRATIONS[integration.integrationType]?.name}
                        </Text>
                      </SCol>
                      <SCol span={24} className="truncated" maxWidth="calc(100% - 64px)">
                        <Text type="secondary">
                          {t(INTEGRATIONS[integration.integrationType]?.name)}
                        </Text>
                      </SCol>
                    </SRow>
                  </SCol>
                </SRow>
              </SCol>
              <SCol span={24}>
                <SRow gutter={[20, 0]} align="middle">
                  <SCol>
                    <Button type="primary" onClick={() => goToIntegration(integration)}>
                      {t('general.goTo')}
                    </Button>
                  </SCol>
                  <SCol>
                    <Button onClick={onDelete(integration.id)}>{t('general.delete')}</Button>
                  </SCol>
                </SRow>
              </SCol>
            </SRow>
          </SCard>
        </SCol>
      ))}
    </SRow>
  );
};

export default IntegrationsList;
