import { Checkbox, Divider, Dropdown, Form, Select } from 'antd';
import React, { useMemo, useState } from 'react';
import SCol from 'components/Standard/SCol';
import { SInputNumber } from 'components/Standard/SInput';
import SRow from 'components/Standard/SRow';
import SSelect from 'components/Standard/SSelect';
import { useTranslation } from 'react-i18next';
import {
  CLIENT_INTERACTION_DIRECTIONS,
  CLIENT_INTERACTION_TYPES,
  COLUMN_TYPES,
  SYMBOLIC_TIME_RANGE
} from 'core/utils/constants';
import SButton from 'components/Standard/SButton';
import Icon from 'components/Icon';
import { Plus, Trash2, X } from 'react-feather';
import TimeSpecificSelector from 'components/Inputs/TimeSpecificSelector';
import { useSelector } from 'react-redux';
import { find, isEqual, isNil, reduce, sortBy } from 'lodash';
import { selectSearch } from 'core/utils/selectSearch';
import DurationPicker from 'components/Inputs/DurationPicker';
import RangeInputs from 'components/Inputs/RangeInputs';
import { getCustomFieldsByIds, getCustomFieldsByKeys } from 'redux/selectors/customFields';
import GrayButton from 'components/Buttons/GrayButton';
import AddUsersModal from '../../../components/AddUsersModal';
import CustomFieldFilter from './CustomFieldFilter';

const updateArray = (array, index, updateFn) => {
  return [...array.slice(0, index), updateFn(array[index]), ...array.slice(index + 1)];
};

const { Option, OptGroup } = Select;
const defaultFields = [];

const ClientInteractionFilters = ({
  clientInteractionType,
  fields = defaultFields,
  onChange,
  onDelete
}) => {
  const { t } = useTranslation();
  const [showUsersModal, setShowUsersModal] = useState(false);
  const levelsByIds = useSelector(state => state.levelsResource.byIds, isEqual);
  const statusesByIds = useSelector(state => state.statusesResource.byIds, isEqual);
  const roles = useSelector(state => Object.values(state.rolesResource.byIds), isEqual);
  const customFieldsByKeys = useSelector(
    state =>
      reduce(
        getCustomFieldsByKeys(state, true),
        (acc, field, key) => (field.usedForFilters ? { ...acc, [key]: field } : acc),
        {}
      ),
    isEqual
  );
  const customFieldsByIds = useSelector(
    state =>
      reduce(
        getCustomFieldsByIds(state, true),
        (acc, field, id) => (field.usedForFilters ? { ...acc, [id]: field } : acc),
        {}
      ),
    isEqual
  );

  const fieldsByKeys = fields.reduce((acc, filter) => ({ ...acc, [filter.key]: filter }), {});
  const fieldsKeys = Object.keys(fieldsByKeys);

  const customFieldFiltersByKeys = useMemo(
    () =>
      Object.keys(customFieldsByKeys).reduce(
        (acc, key) => ({ ...acc, [key]: find(fields, ({ key: filterKey }) => filterKey === key) }),
        {}
      ),
    [customFieldsByKeys]
  );

  const fieldsTypes = useMemo(
    () => ({
      direction: {
        key: 'direction',
        title: COLUMN_TYPES.direction.name,
        component: ({ disabled, onChange, filters }) => {
          // const { t } = useTranslation();
          return (
            <SSelect
              disabled={disabled}
              placeholder={t(
                'clientInteractionsPage.tableFilters.tableGeneralFilters.form.directionPlaceholder'
              )}
              value={filters?.direction || 'all'}
              onChange={direction => onChange({ direction })}
              size="large"
              width="260px"
            >
              {Object.values(CLIENT_INTERACTION_DIRECTIONS).map(item => (
                <Option
                  value={item.value}
                  key={item.value}
                  label={`${t(
                    'clientInteractionsPage.tableFilters.tableGeneralFilters.form.direction'
                  )} ${t(item.title)}`}
                >
                  {t(item.title)}
                </Option>
              ))}
            </SSelect>
          );
        }
      },
      clientInteractionsPeriod: {
        key: 'clientInteractionsPeriod',
        title: 'clientInteractionsPage.tableFilters.tableGeneralFilters.form.datesRange',
        component: ({ disabled, onChange, filters }) => {
          // const { t } = useTranslation();
          return (
            <TimeSpecificSelector
              disabled={disabled}
              placeholder={t(
                'clientInteractionsPage.tableFilters.tableGeneralFilters.form.datesRange'
              )}
              style={{ width: '260px' }}
              size="large"
              datePickerDirection="right"
              onChange={({ symbolicTimeRange, timeTo, timeFrom }) =>
                onChange({
                  clientInteractionTimeFrom: timeFrom,
                  clientInteractionTimeTo: timeTo,
                  clientInteractionSymbolicTimeRange: symbolicTimeRange
                })
              }
              value={{
                symbolicTimeRange: filters?.clientInteractionSymbolicTimeRange,
                timeFrom: filters?.clientInteractionTimeFrom,
                timeTo: filters?.clientInteractionTimeTo
              }}
              timeRanges={Object.values(SYMBOLIC_TIME_RANGE).filter(
                item => item !== SYMBOLIC_TIME_RANGE.ALL_TIME
              )}
              defaultValue={SYMBOLIC_TIME_RANGE.LAST_SEVEN_DAYS}
            />
          );
        }
      },
      levels: {
        key: 'levels',
        title: 'clientInteractionsPage.tableFilters.tableGeneralFilters.form.levels',
        component: ({ disabled, onChange, filters }) => (
          <SSelect
            disabled={disabled}
            mode="multiple"
            optionLabelProp="label"
            allowClear
            placeholder={t('clientInteractionsPage.tableFilters.tableGeneralFilters.form.levels')}
            maxTagCount={0}
            maxTagPlaceholder={selectedKeys =>
              `${t('clientInteractionsPage.tableFilters.tableGeneralFilters.form.levels')}: ${
                selectedKeys.length
              }`
            }
            onChange={levelsIds => onChange({ levelsIds })}
            value={filters?.levelsIds || []}
            filterOption={(input, option) => selectSearch({ input, option, searchProp: 'label' })}
            size="large"
            width="260px"
          >
            {roles.map(({ id, name, levelsIds = [] }) => (
              <OptGroup key={id} label={name}>
                {sortBy(
                  levelsIds.reduce((acc, id) => {
                    const level = levelsByIds[id];
                    return level ? [...acc, level] : acc;
                  }, []),
                  'rank'
                ).map(({ id, name }) => (
                  <Option value={id} key={id} label={name}>
                    {name}
                  </Option>
                ))}
              </OptGroup>
            ))}
          </SSelect>
        )
      },
      statuses: {
        key: 'statuses',
        title: 'clientInteractionsPage.tableFilters.tableGeneralFilters.form.status',
        component: ({ disabled, onChange, filters }) => (
          <SSelect
            disabled={disabled}
            placeholder={t('clientInteractionsPage.tableFilters.tableGeneralFilters.form.status')}
            value={filters?.statusesIds || []}
            onChange={statusesIds => onChange({ statusesIds })}
            optionLabelProp="label"
            size="large"
            width="260px"
            filterOption={(input, option) =>
              selectSearch({ input, option, searchProp: 'children' })
            }
            allowClear
            mode="multiple"
            maxTagCount={0}
            maxTagPlaceholder={selectedKeys =>
              `${t('clientInteractionsPage.tableFilters.tableGeneralFilters.form.status')}: ${
                selectedKeys.length
              }`
            }
          >
            {Object.values(statusesByIds).map(({ id, name }) => (
              <Option key={id} value={id}>
                {name}
              </Option>
            ))}
          </SSelect>
        )
      },
      isReviewed: {
        key: 'isReviewed',
        title: 'clientInteractionsPage.tableFilters.tableGeneralFilters.form.reviewStatus',
        component: ({ disabled, filters, onChange }) => (
          <SSelect
            placeholder={t(
              'clientInteractionsPage.tableFilters.tableGeneralFilters.form.reviewStatus'
            )}
            disabled={disabled}
            mode="default"
            onChange={isReviewed => onChange({ isReviewed })}
            value={filters?.isReviewed || 'all'}
            size="large"
            width="260px"
          >
            <Option
              key="all"
              value="all"
              label={`${t(
                'clientInteractionsPage.tableFilters.tableGeneralFilters.form.reviewStatus'
              )}: ${t('constants.reviewStatuses.all')}`}
            >
              {t('constants.reviewStatuses.all')}
            </Option>
            <Option
              key="reviewed"
              value="true"
              label={`${t(
                'clientInteractionsPage.tableFilters.tableGeneralFilters.form.reviewStatus'
              )}: ${t('constants.reviewStatuses.reviewed')}`}
            >
              {t('constants.reviewStatuses.reviewed')}
            </Option>
            <Option
              key="notReviewed"
              value="false"
              label={`${t(
                'clientInteractionsPage.tableFilters.tableGeneralFilters.form.reviewStatus'
              )}: ${t('constants.reviewStatuses.notReviewed')}`}
            >
              {t('constants.reviewStatuses.notReviewed')}
            </Option>
          </SSelect>
        )
      },
      duration: {
        key: 'duration',
        title: 'clientInteractionsPage.tableFilters.tableGeneralFilters.form.duration',
        component: ({ filters, onChange, disabled }) => (
          <DurationPicker
            from={filters?.durationFrom}
            to={filters?.durationTo}
            disabled={disabled}
            size="large"
            style={{ width: '260px' }}
            onChange={({ from, to }) =>
              onChange({
                durationFrom: isNil(from) ? undefined : `${from}`,
                durationTo: isNil(to) ? undefined : `${to}`
              })
            }
          />
        )
      },
      communicationPartsCount: {
        key: 'communicationPartsCount',
        title:
          'clientInteractionsPage.tableFilters.tableGeneralFilters.form.communicationPartsCount',
        component: ({ filters, onChange, disabled }) => (
          <RangeInputs
            disabled={disabled}
            min={0}
            precision={0}
            value={{
              from: filters?.communicationPartsCountFrom,
              to: filters?.communicationPartsCountTo
            }}
            size="large"
            style={{ width: '260px' }}
            onChange={({ from, to }) =>
              onChange({
                communicationPartsCountFrom: isNil(from) ? undefined : `${from}`,
                communicationPartsCountTo: isNil(to) ? undefined : `${to}`
              })
            }
          />
        )
      },
      ...reduce(
        customFieldsByKeys,
        (acc, field, key) => {
          return {
            ...acc,
            [key]: {
              customField: field,
              key,
              title: field.name,
              component: ({ filters, disabled, onChange }) => {
                return (
                  <CustomFieldFilter
                    customField={field}
                    disabled={disabled}
                    onChange={onChange}
                    value={filters}
                    customFieldsByIds={customFieldsByIds}
                    customFieldFiltersByKeys={customFieldFiltersByKeys}
                  />
                );
              }
            }
          };
        },
        {}
      )
    }),
    [customFieldsByKeys]
  );

  const onAddFilter = addedFilter => {
    onChange({ ...clientInteractionType, fields: [...fields, addedFilter] });
  };

  const onRemoveFilter = removedFilterKey => {
    onChange({
      ...clientInteractionType,
      fields: fields.filter(filter => filter.key !== removedFilterKey)
    });
  };

  const onUpdateFilter = filter => {
    const filterIndex = fields.findIndex(({ key }) => key === filter.key);
    filterIndex < 0
      ? onAddFilter(filter)
      : onChange({
          ...clientInteractionType,
          fields: updateArray(fields, filterIndex, oldFilter => ({ ...oldFilter, ...filter }))
        });
  };

  const onAddUsers = usersIds => {
    onUpdateFilter({ key: 'operatorsIds', settings: { operatorsIds: usersIds } });
    setShowUsersModal(false);
  };

  const onFilterCheckboxHandler = (customField, defaultValue) => e => {
    const key = e.target.name;
    fieldsKeys.includes(key)
      ? onRemoveFilter(key)
      : onAddFilter({
          key,
          customFieldId: customField?.id,
          settings: defaultValue || {}
        });
  };

  const renderFilterCheckbox = (filterKey, customField, defaultValue) => (
    <Checkbox
      name={filterKey}
      checked={fieldsKeys.includes(filterKey)}
      onChange={onFilterCheckboxHandler(customField, defaultValue)}
    >
      {t(fieldsTypes[filterKey]?.title)}
    </Checkbox>
  );

  const menu = (
    <SRow gutter={[24, 12]} style={{ marginBottom: '-8px', maxHeight: '300px', overflowY: 'auto' }}>
      <SCol>{renderFilterCheckbox(fieldsTypes.direction.key)}</SCol>
      <Divider style={{ margin: '0' }} />
      <SCol>
        {renderFilterCheckbox(fieldsTypes.clientInteractionsPeriod.key, undefined, {
          clientInteractionSymbolicTimeRange: SYMBOLIC_TIME_RANGE.LAST_SEVEN_DAYS
        })}
      </SCol>
      <SCol>{renderFilterCheckbox(fieldsTypes.levels.key)}</SCol>
      <SCol>{renderFilterCheckbox(fieldsTypes.statuses.key)}</SCol>
      <SCol>{renderFilterCheckbox(fieldsTypes.isReviewed.key)}</SCol>
      <SCol>{renderFilterCheckbox(fieldsTypes.duration.key)}</SCol>
      <SCol>{renderFilterCheckbox(fieldsTypes.communicationPartsCount.key)}</SCol>
      <Divider style={{ margin: '0' }} />
      {reduce(
        customFieldsByKeys,
        (acc, customField, customFieldKey) => [
          ...acc,
          <SCol>{renderFilterCheckbox(customFieldKey, customField)}</SCol>
        ],
        []
      )}
      <Divider style={{ margin: '0' }} />
      <SCol>
        <SButton
          padding="0"
          color="var(--red_primary)"
          type="link"
          _hover="color: var(--red_primary)"
          icon={<Icon icon={Trash2} color="var(--red_primary)" />}
          onClick={() => onDelete(clientInteractionType.id)}
        >
          {t('workPlanTaskConfigurationEditor.filters.deleteFilters')}
        </SButton>
      </SCol>
    </SRow>
  );

  const usersCount = fieldsByKeys?.operatorsIds?.settings?.operatorsIds?.length || 0;

  return (
    <Form layout="vertical">
      <SRow gutter={[28, 0]} align="middle">
        <SCol>
          <Form.Item label={t('workPlanTaskConfigurationEditor.filters.clientInteractionType')}>
            <SSelect
              size="large"
              width="260px"
              value={clientInteractionType?.clientInteractionType}
              onChange={value =>
                onChange({ ...clientInteractionType, clientInteractionType: value })
              }
            >
              {Object.values(CLIENT_INTERACTION_TYPES).map(type => (
                <Option value={type.value}>{t(type.title)}</Option>
              ))}
            </SSelect>
          </Form.Item>
        </SCol>

        <SCol>
          <Form.Item label={t('workPlanTaskConfigurationEditor.filters.maxCount')}>
            <SInputNumber
              size="large"
              type="number"
              min={0}
              onChange={value =>
                onChange({ ...clientInteractionType, clientInteractionsMaxCount: parseInt(value) })
              }
              value={clientInteractionType?.clientInteractionsMaxCount || 0}
              width="260px"
            />
          </Form.Item>
        </SCol>

        <SCol>
          <SRow>
            <Form.Item label={t('workPlanTaskConfigurationEditor.filters.addUsers')}>
              <SCol
                border="1px solid var(--gray-border)"
                borderRadius="4px"
                height="40px"
                style={{ padding: '0 4px 0 11px' }}
              >
                <SRow justify="flex-between" align="middle" gutter={[20, 0]} height="100%">
                  <SCol>
                    {`${t('workPlanTaskConfigurationEditor.filters.usersAdded')}: ${usersCount}`}
                  </SCol>
                  <SCol>
                    <GrayButton onClick={() => setShowUsersModal(true)}>
                      {t('workPlanTaskConfigurationEditor.filters.add')}
                    </GrayButton>
                  </SCol>
                </SRow>
              </SCol>
            </Form.Item>
          </SRow>
        </SCol>

        {fields.map(filter => {
          const Component = fieldsTypes[filter.key]?.component;
          if (!Component) return null;
          const onChange = settings => onUpdateFilter({ ...filter, settings });
          return (
            <SCol>
              <Form.Item label={t(fieldsTypes[filter.key].title)}>
                <SRow align="middle">
                  <SCol>
                    <Component filters={filter.settings} onChange={onChange} />
                  </SCol>

                  <SCol>
                    <SButton
                      icon={<Icon icon={X} />}
                      type="link"
                      onClick={() => onRemoveFilter(filter.key)}
                    />
                  </SCol>
                </SRow>
              </Form.Item>
            </SCol>
          );
        })}

        <SCol>
          <SRow>
            <Dropdown overlay={menu} trigger={['click']} placement="bottomLeft">
              <GrayButton
                icon={<Icon icon={Plus} size="20px" />}
                size="large"
                padding="0"
                width="40px"
                height="40px"
              />
            </Dropdown>
          </SRow>
        </SCol>
      </SRow>
      <AddUsersModal
        visible={showUsersModal}
        onCancel={() => setShowUsersModal(false)}
        onAdd={onAddUsers}
        selectedUsersIds={fieldsByKeys?.operatorsIds?.settings?.operatorsIds}
      />
    </Form>
  );
};

export default ClientInteractionFilters;
