import React, { useEffect, useState } from 'react';
import { Col } from 'antd';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { get, isEqual } from 'lodash';
import { isParentHidden as checkIsParrentHidden } from 'redux/selectors/comments';
import SCard from 'components/Standard/SCard';
import SRow from 'components/Standard/SRow';
import SCol from 'components/Standard/SCol';
import CommentEditButton from '../Components/CommentEditButton';
import CommentText from '../Components/CommentText';
import CommentInfo from '../Components/CommentInfo';
import CreateReplyForm from '../Components/CreateReplyForm';

const marginForComment = type => {
  return type === 'review_comment' ? '8px' : '4px';
};

const ReplyComment = ({ replyCommentId, commentsByIds, onDelete, onUpdate, allowActions }) => {
  const isParentHidden = useSelector(state =>
    checkIsParrentHidden({
      commentsByIds: state.commentsResource.byIds,
      commentId: replyCommentId
    })
  );
  const uploadedFilesByIds = useSelector(state => state.uploadedFilesResource.byIds, isEqual);
  const [commentState, setCommentState] = useState({
    ...commentsByIds[replyCommentId],
    uploadedFiles: commentsByIds[replyCommentId]?.uploadedFilesIds?.map(
      id => uploadedFilesByIds[id]
    )
  });
  const [isEditing, setEditing] = useState(false);
  const [isEditReply, setEditReply] = useState(false);
  const currentCommentReply = get(commentsByIds, replyCommentId, {});

  useEffect(() => {
    if (!isEditing) {
      setCommentState({
        ...commentsByIds[replyCommentId],
        uploadedFiles: commentsByIds[replyCommentId]?.uploadedFilesIds?.map(
          id => uploadedFilesByIds[id]
        )
      });
    }
  }, [isEditing]);

  const editingCommentId = useSelector(state => state.uiClientInteractionPage.editingCommentId);

  return (
    <StyledCol commentType={currentCommentReply.commentType} span={23}>
      <SCard bodyPadding="12px" rounded="4px" bordered shadowed>
        <SRow type="flex" justify="space-between">
          {editingCommentId && editingCommentId === replyCommentId ? (
            <SCol span={24}>
              <CommentText
                commentState={commentState}
                setCommentState={setCommentState}
                isEditing={isEditing}
                setEditing={setEditing}
                onUpdate={onUpdate}
              />
            </SCol>
          ) : (
            <>
              <SCol span={24}>
                <CommentInfo
                  isEditing={isEditing}
                  setEditing={setEditing}
                  isEditReply={isEditReply}
                  setEditReply={setEditReply}
                  editorText={commentState.text}
                  currentComment={currentCommentReply}
                  isParentHidden={isParentHidden}
                  onDelete={onDelete}
                  allowActions={allowActions}
                />
              </SCol>
              <SCol span={24}>
                <CommentText
                  commentState={commentState}
                  setCommentState={setCommentState}
                  isEditing={isEditing}
                  setEditing={setEditing}
                  onUpdate={onUpdate}
                />
              </SCol>
            </>
          )}
        </SRow>
        <CommentEditButton
          {...currentCommentReply}
          isEditing={isEditing}
          setEditing={setEditing}
          editorText={commentState.text}
          setEditReply={setEditReply}
          onDelete={onDelete}
          onUpdate={onUpdate}
        />
      </SCard>
      <CreateReplyForm
        parentId={currentCommentReply.id}
        isEditReply={isEditReply}
        setEditReply={setEditReply}
      />
    </StyledCol>
  );
};

const StyledCol = styled(Col)`
  &.ant-col {
    margin-top: ${({ commentType }) => `${marginForComment(commentType)}`};
  }
`;

export default ReplyComment;
