import { BookOutlined } from '@ant-design/icons';
import { Input, message, Modal, Row, Select, Tooltip } from 'antd';
import IconButton from 'components/IconButton';
import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createLibraryItem, loadLibrary } from 'redux/entities/library/operations';
import {
  clearLibraryModal,
  closeAddToLibraryModal,
  openAddToLibraryModal,
  setCurrentFoldersIds,
  setNewItemName
} from 'redux/ui/addToLibraryModal/reducer';
import styled from 'styled-components';

const AddToLibrary = ({
  visible,
  openLibraryModal,
  clearLibraryModal,
  closeLibraryModal,
  createLibraryItem,
  foldersByIds,
  reviewId,
  folders,
  selectFolders,
  changeName,
  name,
  selectedFolders,
  loading,
  loadLibrary,
  margin
}) => {
  useEffect(() => {
    if (!folders.length && visible) {
      loadLibrary({ include: 'items.review' });
    }
  }, [visible]);
  const { t } = useTranslation();
  // if (!reviewId) return null;

  const handleSave = async () => {
    await selectedFolders.forEach(async libraryId => {
      const response = await createLibraryItem({ libraryId, reviewId, name });
      if (response) {
        message.success(
          `${t('components.recordPlayer.addToLibrary.messages.recordStr')} "${name}" ${t(
            'components.recordPlayer.addToLibrary.messages.addedStr'
          )} "${foldersByIds[libraryId]?.name}"`
        );
        closeLibraryModal();
        clearLibraryModal();
      } else {
        message.error(t('components.recordPlayer.addToLibrary.messages.addRecordToLibraryFailed'));
      }
    });
  };

  const { Option } = Select;

  return (
    <Row>
      <Tooltip title={t('components.recordPlayer.addToLibrary.tooltip')}>
        <IconButton
          shape="circle-outline"
          icon={<BookOutlined />}
          size="small"
          onClick={openLibraryModal}
          disabled={!reviewId}
          margin={margin}
        />
      </Tooltip>
      <Modal
        title={t('components.recordPlayer.addToLibrary.modal.title')}
        visible={visible}
        okText={t('general.save')}
        cancelText={t('general.cancel')}
        onOk={handleSave}
        onCancel={closeLibraryModal}
        okButtonProps={{ disabled: isEmpty(selectedFolders) || !name, loading }}
      >
        <StyledSpan margin="0 0 8px 0">
          {t('components.recordPlayer.addToLibrary.modal.enterFolderName')}
        </StyledSpan>
        <Select
          mode="multiple"
          loading={loading}
          style={{ width: '100%' }}
          placeholder={t('components.recordPlayer.addToLibrary.modal.folderNamePlaceholder')}
          onChange={selectFolders}
          value={selectedFolders}
          optionLabelProp="label"
        >
          {folders.map(({ id, name }) => (
            <Option key={id} value={id} label={name}>
              {name}
            </Option>
          ))}
        </Select>
        <StyledSpan margin="16px 0 8px 0">
          {t('components.recordPlayer.addToLibrary.modal.enterRecordName')}
        </StyledSpan>
        <Input
          placeholder={t('components.recordPlayer.addToLibrary.modal.recordNamePlaceholder')}
          value={name}
          onChange={e => changeName(e.target.value)}
        />
      </Modal>
    </Row>
  );
};

const StyledSpan = styled.span`
  display: block;
  margin: ${props => props.margin};
  font-weight: 500;
`;

const mapStateToProps = (state, ownProps) => {
  return {
    visible: state.uiAddToLibraryModal.showAddToLibraryModal,
    selectedFolders: state.uiAddToLibraryModal.currentFoldersIds,
    folders: Object.values(state.library.foldersByIds),
    foldersByIds: state.library.foldersByIds,
    name: state.uiAddToLibraryModal.newItemName,
    loading: state.library.loading,
    ...ownProps
  };
};

const mapDispatchToProps = {
  openLibraryModal: openAddToLibraryModal,
  closeLibraryModal: closeAddToLibraryModal,
  selectFolders: setCurrentFoldersIds,
  changeName: setNewItemName,
  clearLibraryModal,
  loadLibrary,
  createLibraryItem
};

export default connect(mapStateToProps, mapDispatchToProps)(AddToLibrary);
