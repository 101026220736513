import React, { useState } from 'react';
import SCard from 'components/Standard/SCard';
import SRow from 'components/Standard/SRow';
import SCol from 'components/Standard/SCol';
import GreenFlag from 'components/Flags/GreenFlag';
import YellowFlag from 'components/Flags/YellowFlag';
import RedFlag from 'components/Flags/RedFlag';
import StandardComment from 'components/Flags/StandardComment';
import SButton from 'components/Standard/SButton';
import Icon from 'components/Icon';
import { Trash2, Edit3, EyeOff } from 'react-feather';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { message, Modal } from 'antd';
import BraftEditor from 'braft-editor';
import { commentTemplatesResource } from 'redux/resources/commentTemplates';
import { actions } from 'redux/lists/commentTemplatesListReducer';
import UploadedFile from 'components/Comments/Editor/UploadedFile';
import { isEqual, get } from 'lodash';
import DOMPurify from 'dompurify';
import { useTranslation } from 'react-i18next';
import CreateTemplateForm from './CreateTemplateForm';

const TemplateComment = ({
  template,
  commentState,
  setCommentState,
  setEditorState,
  setVisibleSelector
}) => {
  const [isEditing, setEditing] = useState(false);
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const FLAG_COLOR_TO_COMPONENT = {
    green: <GreenFlag />,
    yellow: <YellowFlag />,
    red: <RedFlag />
  };

  const playedSeconds = useSelector(state => state.uiRecordPlayer.playedSeconds);

  const { onCommentTemplateDelete } = actions;

  const onDeleteFile = async id => {
    try {
      const res = await dispatch(
        commentTemplatesResource.operations.updateById({
          ...template,
          uploadedFilesIds: template?.uploadedFiles.filter(({ id: fileId }) => fileId !== id)
        })
      );

      dispatch(onCommentTemplateDelete(res));
      if (res) message.success(t('messages.success.templateUpdated'));
    } catch (error) {
      console.log(error);
      message.warning(t('messages.warning.failedTemplateUpdate'));
    }
  };

  const updateCommentId = useSelector(state => state.uiClientInteractionPage.editingCommentId);
  const uploadedFilesByIds = useSelector(state => state.uploadedFilesResource.byIds, isEqual);

  const deleteCommentTemplate = async () => {
    Modal.confirm({
      title: t('templates.confirmDelete'),
      onOk: async () => {
        try {
          const res = await dispatch(
            commentTemplatesResource.operations.deleteById({
              id: template?.id
            })
          );
          dispatch(onCommentTemplateDelete(res));
          if (res) message.success(t('messages.success.templateDeleted'));
        } catch (error) {
          console.log(error);
          message.warning(t('messages.warning.failedTemplateSave'));
        }
      },
      okText: t('general.delete'),
      cancelText: t('general.cancel')
    });
  };

  return (
    <SCol span={24}>
      <StyledCard
        shadowed
        style={{ cursor: 'pointer' }}
        margin="2px"
        onClick={() => {
          setEditorState(BraftEditor.createEditorState(template?.text));
          setCommentState(
            updateCommentId
              ? {
                  ...template,
                  id: updateCommentId,
                  isTimestampAdded: true,
                  metadata: {
                    ...template?.metadata,
                    timestamp: commentState?.metadata?.timestamp
                  }
                }
              : {
                  ...template,
                  isTimestampAdded: true,
                  metadata: {
                    ...template?.metadata,
                    timestamp: playedSeconds
                  }
                }
          );
          setVisibleSelector(false);
        }}
      >
        {isEditing ? (
          <SRow onClick={event => event.stopPropagation()}>
            <SCol span={24}>
              <CreateTemplateForm setOpenEditor={setEditing} templateToUpdate={template} />
            </SCol>
          </SRow>
        ) : (
          <SRow>
            <SCol span={1}>
              {FLAG_COLOR_TO_COMPONENT[template?.ratingFlag] || <StandardComment />}
            </SCol>
            <SCol span={19}>
              <div
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(template?.text, {
                    ALLOWED_ATTR: ['target', 'href']
                  })
                }}
              />
            </SCol>
            <SCol span={4}>
              <SRow type="flex" align="end">
                {template?.hidden && (
                  <SCol marginRight="3px">
                    <Icon icon={EyeOff} size="16px" color="var(--black_2)" />
                  </SCol>
                )}
                <SCol>
                  <StyledButton
                    size="icon"
                    icon={<Icon icon={Edit3} size="16px" />}
                    color="var(--black_2)"
                    backgroundColor="transparent"
                    onClick={event => {
                      event.stopPropagation();
                      setEditing(true);
                    }}
                  />
                </SCol>
                <SCol>
                  <StyledButton
                    size="icon"
                    icon={<Icon icon={Trash2} size="16px" />}
                    color="var(--black_2)"
                    onClick={event => {
                      event.stopPropagation();
                      deleteCommentTemplate();
                    }}
                  />
                </SCol>
              </SRow>
            </SCol>
            <SCol span={24}>
              {get(template, 'uploadedFiles', []).reduce((acc, file) => {
                if (!Object.keys(uploadedFilesByIds).includes(file?.id)) return acc;
                const component = (
                  <UploadedFile
                    uploadedFile={file}
                    allFiles={template?.uploadedFiles}
                    onDelete={onDeleteFile}
                  />
                );

                return [...acc, component];
              }, [])}
            </SCol>
          </SRow>
        )}
      </StyledCard>
    </SCol>
  );
};

const StyledCard = styled(SCard)`
  &.ant-card:hover {
    background-color: #f0f2f5;
  }
`;

const StyledButton = styled(SButton)`
  &.ant-btn {
    border: none;
    box-shadow: none;
    background-color: transparent;
    width: 24px !important;
  }

  &.ant-btn:hover {
    background-color: transparent;
  }
`;

export default TemplateComment;
