import React from 'react';
import { isEmpty, get, find, groupBy, reduce, keyBy } from 'lodash';
import { Row, Col, Empty, Table, Typography } from 'antd';
import styled from 'styled-components';
import SCard from 'components/Standard/SCard';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { beatifyFloat } from 'core/utils/convertFloat';
import { getChecklistsWithAnswersByIds } from 'redux/selectors/checklists';
import {
  getChecklistDefinitionQuestionGroupsBeforeQuestions,
  getChecklistDefinitionQuestions
} from 'redux/selectors/checklistItems/checklistItems';
import UserPreview from 'components/UserPreview';
import { prepareTableColumns } from './prepareTableColumns';
import { prepareTableRows } from './prepareTableRows';
import STable from 'components/Standard/STable';

const NewCalibrationResultTable = ({
  members,
  checklistItems,
  questionsDifferenceByIds,
  totalDifference,
  session = {}
}) => {
  const { t } = useTranslation();
  const { Text } = Typography;

  const tableRows = prepareTableRows({
    checklistItems,
    members,
    questionsDifferenceByIds,
    totalDifference
  });
  const tableColumns = prepareTableColumns({ members });
  const scroll = { x: 1500, y: undefined };

  if (tableColumns.length <= 2) {
    return (
      <Row>
        <Col span={24}>
          <SCard>
            <StyledEmpty
              description={t('calibrationResultPage.messages.emptyReviews')}
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          </SCard>
        </Col>
      </Row>
    );
  }

  return (
    <Row gutter={[8, 8]}>
      <Col span={24}>
        <SCard bodyPadding="16px 16px 8px 16px">
          <Row>
            <Col span={24}>
              <Text strong>{session.name}</Text>
            </Col>
            <Col span={24}>
              <Row type="flex" gutter={[4, 4]} align="middle">
                <Col>
                  <Text>{t('calibrationResultPage.table.organizer')}</Text>
                </Col>
                <Col>
                  <UserPreview disabled userId={session.authorId} />
                </Col>
              </Row>
            </Col>
          </Row>
        </SCard>
      </Col>
      <Col span={24}>
        <STable
          dataSource={tableRows}
          columns={tableColumns}
          pagination={false}
          size="small"
          scroll={scroll}
        />
      </Col>
    </Row>
  );
};

const StyledEmpty = styled(Empty)`
  &&.ant-empty.ant-empty-normal {
    margin: 8px 0;
  }
`;

const mapStateToProps = (state, { session }) => {
  const checklistDefinition = get(
    state.checklistDefinitionsResource.byIds,
    session.checklistDefinitionId,
    {}
  );

  const { viewedItem } = state.uiCalibrationResultPage;
  const checklistDefinitionQuestions = getChecklistDefinitionQuestions(state, checklistDefinition);

  const checklistDefinitionQuestionsByIds = keyBy(checklistDefinitionQuestions, 'id');

  const { clientInteractionId } = get(state.folderItemsResource.byIds, viewedItem, {});

  const checklistsWithAnswersByIds = getChecklistsWithAnswersByIds(state);

  const sessionReviewsByIds = session.reviewsIds.reduce((acc, id) => {
    const review = get(state.reviewsResource.byIds, id, {});

    if (isEmpty(review) || review.clientInteractionId !== clientInteractionId) {
      return acc;
    }
    return { ...acc, [id]: review };
  }, {});

  const members = session.membersIds.reduce((acc, id) => {
    const user = get(state.usersResource.byIds, id, {});
    const review = find(sessionReviewsByIds, ({ reviewerId }) => id === reviewerId);

    if (!review || isEmpty(user)) {
      return acc;
    }

    const checklist = get(checklistsWithAnswersByIds, review.checklistId, {});
    const comments = review.commentsIds.map(id => get(state.commentsResource.byIds, id, {}));
    return [...acc, { ...user, review, checklist, comments }];
  }, []);

  const checklistItems = getChecklistDefinitionQuestionGroupsBeforeQuestions(
    state,
    checklistDefinition
  );

  const sessionChecklistsByIds = reduce(
    sessionReviewsByIds,
    (acc, review) => {
      const checklist = get(checklistsWithAnswersByIds, review.checklistId);

      if (!checklist) {
        return acc;
      }

      return { ...acc, [checklist.id]: checklist };
    },
    {}
  );

  const questionsDifferenceByIds = reduce(
    checklistDefinitionQuestionsByIds,
    (acc, question, questionId) => {
      // * 1. берём общще количество оценок по вопросу - T
      const totalAnswers = reduce(
        sessionChecklistsByIds,
        (acc, checklist) => {
          const answer = get(checklist, ['questionsWithAnswersByIds', questionId, 'value']);

          return [...acc, answer];
        },
        []
      );
      const totalAnswersCount = totalAnswers.length;

      // * 2. находим наиболее часто повторяющиеся оценки - N
      const groupedAnswers = groupBy(totalAnswers);

      if (isEmpty(groupedAnswers)) {
        return { ...acc, [questionId]: '0%' };
      }
      const baseAnswer = Object.keys(groupedAnswers).reduce((a, b) =>
        groupedAnswers[a].length > groupedAnswers[b].length ? a : b
      );
      const baseAnswerCount = groupedAnswers[baseAnswer].length;

      // * 3. если N > 1 то совпадение = N/T * 100 %, иначе совпадение = 0
      const difference =
        baseAnswerCount > 1
          ? `${beatifyFloat((baseAnswerCount / totalAnswersCount) * 100)}%`
          : '0%';

      return { ...acc, [questionId]: difference };
    },
    {}
  );

  // * общая разницы считается так же как разница по вопросам, только по скорам чек-листа
  const totalDifference = (() => {
    const checklists = Object.values(sessionChecklistsByIds);
    const groupedChecklists = groupBy(checklists, 'score');

    if (isEmpty(groupedChecklists)) {
      return '0%';
    }

    const baseChecklistScore = Object.keys(groupedChecklists).reduce((a, b) =>
      groupedChecklists[a].length > groupedChecklists[b].length ? a : b
    );

    const baseChecklistsCount = groupedChecklists[baseChecklistScore].length;
    return baseChecklistsCount > 1
      ? `${beatifyFloat((baseChecklistsCount / checklists.length) * 100)}%`
      : '0%';
  })();

  return {
    members,
    checklistItems,
    totalDifference,
    questionsDifferenceByIds,
    checklistDefinitionQuestionsByIds
  };
};

export default withRouter(connect(mapStateToProps, null)(NewCalibrationResultTable));
