import React from 'react';
import SRow from 'components/Standard/SRow';
import SCol from 'components/Standard/SCol';
import { FlagOutlined, FlagFilled } from '@ant-design/icons';
import SButton from 'components/Standard/SButton';
import { FLAGS_COLORS_VALUES, FLAGS_TO_COLORS_RELATIONS } from 'core/utils/constants';
import styled from 'styled-components';

const Flags = ({ commentState, setCommentState }) => {
  const onChangeFlag = ({ ratingFlag }) => {
    if (ratingFlag === commentState?.metadata?.ratingFlag)
      return setCommentState({
        ...commentState,
        metadata: { ...commentState?.metadata, ratingFlag: 'empty' }
      });

    return setCommentState({
      ...commentState,
      metadata: { ...commentState?.metadata, ratingFlag }
    });
  };

  return (
    <SRow
      type="flex"
      align="middle"
      justify="space-between"
      width="125px"
      style={{ position: 'absolute', top: 15, left: 207 }}
    >
      <SCol span={8}>
        <StyledButton
          size="icon"
          icon={
            commentState?.metadata?.ratingFlag === FLAGS_COLORS_VALUES.red ? (
              <FlagFilled />
            ) : (
              <FlagOutlined />
            )
          }
          style={{
            color: FLAGS_TO_COLORS_RELATIONS.red
          }}
          onClick={() => onChangeFlag({ ratingFlag: FLAGS_COLORS_VALUES.red })}
        />
      </SCol>
      <SCol span={8}>
        <StyledButton
          size="icon"
          icon={
            commentState?.metadata?.ratingFlag === FLAGS_COLORS_VALUES.yellow ? (
              <FlagFilled />
            ) : (
              <FlagOutlined />
            )
          }
          style={{
            color: FLAGS_TO_COLORS_RELATIONS.yellow
          }}
          onClick={() => onChangeFlag({ ratingFlag: FLAGS_COLORS_VALUES.yellow })}
        />
      </SCol>
      <SCol span={8}>
        <StyledButton
          size="icon"
          icon={
            commentState?.metadata?.ratingFlag === FLAGS_COLORS_VALUES.green ? (
              <FlagFilled />
            ) : (
              <FlagOutlined />
            )
          }
          style={{
            color: FLAGS_TO_COLORS_RELATIONS.green
          }}
          onClick={() => onChangeFlag({ ratingFlag: FLAGS_COLORS_VALUES.green })}
        />
      </SCol>
    </SRow>
  );
};

const StyledButton = styled(SButton)`
  &.ant-btn {
    margin-right: 20px;
    border: none;
    box-shadow: none;
    background-color: transparent;
    width: 24px !important;
  }

  &.ant-btn:hover {
    background-color: transparent;
  }

  &.ant-btn:focus {
    background-color: transparent;
  }
`;

export default Flags;
