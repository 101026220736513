import addToCalibrationModalReducer from 'redux/ui/addToCalibrationModal/reducer';
import addToLibraryModalReducer from 'redux/ui/addToLibraryModal/reducer';
import { bitrixIntegrationPageReducer } from 'redux/ui/bitrixIntegration/reducer';
import { uiCalibrationResultPageReducer } from 'redux/ui/calibrationResult/reducer';
import { calibrationSessionSettingsModalReducer } from 'redux/ui/calibrationSessionSettingsModal/reducer';
import { uiCalibrationsPageReducer } from 'redux/ui/calibrationsPage/reducer';
import { uiChecklistEditorReducer } from 'redux/ui/checklistEditor/reducer';
import { checklistManagerReducer } from 'redux/ui/checklistManager/reducer';
import { checklistDefinitionsReducer } from 'redux/ui/checklists/checklistDefinition';
import { questionGroupsReducer } from 'redux/ui/checklists/questionGroups';
import { questionsReducer } from 'redux/ui/checklists/questions';
import { checklistsAndQuestionsPageReducer } from 'redux/ui/checklistsAndQuestionsPage/reducer';
import { checklistSettingsPageReducer } from 'redux/ui/checklistSettingsPage/reducer';
import { checklistsPageReducer } from 'redux/ui/checklistsPage/reducer';
import { uiClientInteractionDrawerReducer } from 'redux/ui/clientInteractionDrawer/reducer';
import { clientInteractionPageReducer } from 'redux/ui/clientInteractionPage/reducer';
import { uiClientInteractionsReducer } from 'redux/ui/clientInteractions/reducer';
import { uiCommentsReduser } from 'redux/ui/comments/reducer';
import createTaskModal from 'redux/ui/createTaskModal/reducer';
import { customCommunicationReviewReducer } from 'redux/ui/customCommunicationReviewPage/reducer';
import { uiDashboardReducer } from 'redux/ui/dashboard/reducer';
import { uiEmployeeModalReducer } from 'redux/ui/employeeModal/reducer';
import { integrationModalReducer } from 'redux/ui/integrationModal/reducer';
import { layoutReducer } from 'redux/ui/layout/reducer';
import { levelsPageReducer } from 'redux/ui/levelsPage/reducer';
import libraryPageReducer from 'redux/ui/libraryPage/reducer';
import { uiNotificationSettingsReducer } from 'redux/ui/notificationSettings/reducer';
import { organizationPageReducer } from 'redux/ui/organizationPage/reducer';
import { paymentSettingsReducer } from 'redux/ui/paymentSettings/reducer';
import { uiQuestionDetailsModalReducer } from 'redux/ui/questionDetailsModal/reducer';
import uiQuestionModalReducer from 'redux/ui/questionModal/reducer';
import recordPlayerReducer from 'redux/ui/recordPlayer/reducer';
import { uiRegisterFromIntegrationReducer } from 'redux/ui/registerFromIntegration/reducer';
import { uiRolesAndPermissionsReducer } from 'redux/ui/rolesAndPermissions/reducer';
import skillsWidgetReducer from 'redux/ui/skillsWidget/reducer';
import { statusesPageReducer } from 'redux/ui/statusesPage/reducer';
import { uiTaskDefinitionReducer } from 'redux/ui/taskDefinition/reducer';
import { uiTaskPrioritySettingsReducer } from 'redux/ui/taskPrioritySettings/reducer';
import { teamAnalyticsPageReducer } from 'redux/ui/teamAnalyticsPage/reducer';
import { uploadPageReducer } from 'redux/ui/uploadPage/reducer';
import userAnalyticsPageReducer from 'redux/ui/userAnalyticsPage/reducer';
import { uiWidgetSettingsModalReducer } from 'redux/ui/widgetSettingsModal/reducer';

export default {
  uiCustomCommunicationReview: customCommunicationReviewReducer,
  uiPaymentSettings: paymentSettingsReducer,
  uiWidgetSettingsModal: uiWidgetSettingsModalReducer,
  uiChecklistManager: checklistManagerReducer,
  uiEmployeeModal: uiEmployeeModalReducer,
  uiDashboard: uiDashboardReducer,
  uiClientInteractions: uiClientInteractionsReducer,
  uiChecklistQuestionGroups: questionGroupsReducer,
  uiChecklistQuestions: questionsReducer,
  uiChecklistDefinitions: checklistDefinitionsReducer,
  uiRecordPlayer: recordPlayerReducer,
  uiUserAnalyticsPage: userAnalyticsPageReducer,
  uiUploadPage: uploadPageReducer,
  uiChecklistsPage: checklistsPageReducer,
  uiSkillsWidget: skillsWidgetReducer,
  uiLibraryPage: libraryPageReducer,
  uiAddToLibraryModal: addToLibraryModalReducer,
  uiLayout: layoutReducer,
  uiComments: uiCommentsReduser,
  uiIntegrationModal: integrationModalReducer,
  uiOrganizationPage: organizationPageReducer,
  uiClientInteractionPage: clientInteractionPageReducer,
  uiCreateTaskModal: createTaskModal,
  uiTaskPrioritySettings: uiTaskPrioritySettingsReducer,
  uiTaskDefinition: uiTaskDefinitionReducer,
  uiBitrixIntegration: bitrixIntegrationPageReducer,
  uiRolesAndPermissions: uiRolesAndPermissionsReducer,
  uiNotificationSettings: uiNotificationSettingsReducer,
  uiRegisterFromIntegration: uiRegisterFromIntegrationReducer,
  uiQuestionModal: uiQuestionModalReducer,
  uiChecklistEditor: uiChecklistEditorReducer,
  uiChecklistsAndQuestionsPage: checklistsAndQuestionsPageReducer,
  uiChecklistSettingsPage: checklistSettingsPageReducer,
  uiTeamAnalyticsPage: teamAnalyticsPageReducer,
  uiQuestionDetailsModal: uiQuestionDetailsModalReducer,
  uiLevelsPage: levelsPageReducer,
  uiAddToCalibrationModal: addToCalibrationModalReducer,
  uiCalibrationsPage: uiCalibrationsPageReducer,
  uiCalibrationSessionSettingsModal: calibrationSessionSettingsModalReducer,
  uiCalibrationResultPage: uiCalibrationResultPageReducer,
  uiStatusesPage: statusesPageReducer,
  uiClientInteractionDrawer: uiClientInteractionDrawerReducer
};
