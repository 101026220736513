import { ChevronDown } from 'react-feather';
import { Card, Col, Empty, Input, Select, Typography } from 'antd';
import styled from 'styled-components';

// * widgets, EmptyWidget, EmptyDashboard
export const WidgetCard = styled(Card)`
  &.ant-card {
    border-radius: 4px;
    border: none;
  }
  &.ant-card > .ant-card-body {
    padding: 8px;
    height: 29rem;
  }
`;

export const LargeWidgetCard = styled(Card)`
  &.ant-card {
    border-radius: 4px;
    border: none;
  }
  &.ant-card > .ant-card-body {
    padding: 8px;
  }
`;

export const SmallWidgetCard = styled(Card)`
  &.ant-card {
    border-radius: 4px;
    border: none;
    padding: 0;
    height: 7.5rem;
  }

  &.ant-card > .ant-card-body {
    padding: 8px;
  }

  &.ant-card > .ant-card-head {
    min-height: unset;
    padding: 0;
    font-size: inherit;
    border-bottom: 0;
    background-color: ${props => props.headerColor};
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    padding: 8px;

    .ant-card-head-title {
      padding: 0;
    }
  }
`;

const widgetSizeToWidgetHeight = {
  small: '7.5rem',
  medium: '29rem',
  large: '29rem',
  extra_large: '35rem'
};

export const EmptyWidgetCard = styled(Card)`
  &.ant-card {
    border-radius: 4px;
    border: none;
  }
  &.ant-card > .ant-card-body {
    padding: 8px;
    height: ${props => widgetSizeToWidgetHeight[props.size]};
  }
`;

export const StyledEmpty = styled(Empty)`
  &.ant-empty {
    display: flex;
    justify-content: center;
    margin: 0px;
    flex-direction: column;
    height: 95%;
  }
`;

export const StyledErrorMessage = styled.span`
  font-size: 24px;
  display: block;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.45);
  margin-bottom: 0.5em;
`;

export const SettingsButtonCol = styled(Col)`
  &.ant-col {
    display: flex;
    justify-content: flex-end;
  }
`;

export const WidgetNameCol = styled(Col)`
  &.ant-col {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 500;
  }
`;

export const TimePeriod = styled(Typography.Text)`
  &.ant-typography {
    font-size: var(--font-size);
    display: block;
  }
`;

export const SmallValue = styled.span`
  font-size: 24px;
  font-weight: 500;
  color: ${props => props.color || 'inherit'};
`;

export const Value = styled.span`
  font-size: ${props => (props.secondsCount >= 3600000 ? '34px' : '40px')};
  font-weight: 500;
  color: ${props => props.color || 'inherit'};

  ${SmallValue} {
    padding: 0 4px;
  }
`;

export const EmptyDashboardCard = styled(Card)`
  &.ant-card {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

// * FilterForm, SettingsModal
export const StyledSelect = styled(Select)`
  &.ant-select {
    width: 100%;
    margin: 4px 0px 8px 0px;
  }
`;

export const SettingsButton = styled(ChevronDown)`
  &.anticon:hover {
    color: var(--primary);
  }
  &.anticon:active {
    color: #0061d0;
  }
`;

export const StyledInput = styled(Input)`
  &.ant-input {
    margin: 4px 0px 8px 0px;
  }
`;
