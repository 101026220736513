/* eslint-disable max-classes-per-file */
import React from 'react';
import { Table, Select, Popconfirm, Button, DatePicker, Tag, Input } from 'antd';
import moment from 'core/moment';
import UserPreview from 'components/UserPreview';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { get } from 'lodash';
import { withRouter } from 'react-router-dom';
import { getUserName } from 'components/UserPreview/getUserName';
import { utils } from 'react-media-player';
import { actions, operations } from 'redux/lists/uploadedCallsListReducer';
import { phoneCallsResource } from 'redux/resources/calls';
import styled from 'styled-components';
import { usersResource } from 'redux/resources/users';
import { PERMISSIONS } from 'core/utils/constants';
import { withTranslation } from 'react-i18next';
import { getUsersWithRoleAndUnit } from 'redux/selectors/users';
import STable from 'components/Standard/STable';

const { Option } = Select;

class EditableTable extends React.Component {
  constructor(props) {
    super(props);
    this.columns = [
      {
        key: 'operators',
        title: 'Оператор',
        dataIndex: 'operatorId',
        width: 300,
        render: (operatorId, record) => {
          const { usersByIds, users, updateEditing } = this.props;
          const user = usersByIds[operatorId];
          if (this.isEditing(record)) {
            return (
              <SelectContainer>
                <Select
                  placeholder={this.props.t('uploadCallsPage.table.columns.operator')}
                  onChange={value => updateEditing({ operatorId: value })}
                  {...(user ? { defaultValue: user.id } : {})}
                >
                  {users
                    .filter(user =>
                      get(user, 'role.permissions', []).includes(
                        PERMISSIONS.CAN_PERFORM_CLIENT_INTERACTION
                      )
                    )
                    .map(user => (
                      <Option key={user.id} value={user.id}>
                        {getUserName({ user })}
                      </Option>
                    ))}
                </Select>
              </SelectContainer>
            );
          }

          return user ? (
            <UserPreview user={user} disabled />
          ) : (
            <Tag color="red">{this.props.t('uploadCallsPage.table.uinstalledCallDirection')}</Tag>
          );
        }
      },
      {
        key: 'direction',
        title: this.props.t('uploadCallsPage.table.columns.direction'),
        dataIndex: 'direction',
        width: 200,
        render: (text, record) => {
          const { updateEditing } = this.props;
          if (this.isEditing(record)) {
            return (
              <Select
                style={{ minWidth: 150 }}
                placeholder={this.props.t('uploadCallsPage.table.columns.direction')}
                onChange={value => updateEditing({ direction: value })}
                {...(text ? { defaultValue: text } : {})}
              >
                <Option value="incoming">
                  {this.props.t('constants.clientInteractionDirections.incoming')}
                </Option>
                <Option value="outcoming">
                  {this.props.t('constants.clientInteractionDirections.outcoming')}
                </Option>
              </Select>
            );
          }
          switch (text) {
            case 'incoming':
              return <span>{this.props.t('constants.clientInteractionDirections.incoming')}</span>;
            case 'outcoming':
              return <span>{this.props.t('constants.clientInteractionDirections.outcoming')}</span>;
            default:
              return (
                <Tag color="red">
                  {this.props.t('constants.clientInteractionDirections.unknown')}
                </Tag>
              );
          }
        }
      },
      {
        key: 'startedAt',
        title: this.props.t('uploadCallsPage.table.columns.startedAt'),
        dataIndex: 'startedAt',
        width: 200,
        render: (text, record) => {
          const { updateEditing } = this.props;
          if (this.isEditing(record)) {
            return (
              <SelectContainer>
                <DatePicker
                  showTime
                  defaultValue={text ? moment(text) : moment(record.createdAt)}
                  placeholder={this.props.t('uploadCallsPage.table.columns.startedAtPlaceholder')}
                  format="DD/MM/YYYY"
                  onOk={value => updateEditing({ startedAt: value.toISOString() })}
                />
              </SelectContainer>
            );
          }
          return <span>{moment(text).format('DD/MM/YYYY, HH:mm')}</span>;
        }
      },
      {
        key: 'clientPhoneNumber',
        title: this.props.t('uploadCallsPage.table.columns.clientPhoneNumber'),
        dataIndex: 'clientPhoneNumber',
        width: 250,
        render: (text, record) => {
          const { updateEditing } = this.props;
          if (this.isEditing(record)) {
            return (
              <Input
                defaultValue={text}
                placeholder={this.props.t('uploadCallsPage.table.columns.clientPhoneNumber')}
                onChange={e => updateEditing({ clientPhoneNumber: e.target.value })}
              />
            );
          }
          return <span>{text}</span>;
        }
      },
      {
        key: 'duration',
        title: this.props.t('uploadCallsPage.table.columns.duration'),
        dataIndex: 'duration',
        width: 150,
        render: text => {
          return <span>{utils.formatTime(text) || '-'}</span>;
        }
      },
      {
        key: 'actions',
        title: 'Действия',
        dataIndex: 'operation',
        render: (text, record) => {
          const { editingCallId, startEditing, cancelEditing, deleteCall } = this.props;
          const editable = this.isEditing(record);
          return editable ? (
            <span>
              <Button type="link" onClick={() => this.saveCall(record)} style={{ marginRight: 8 }}>
                {this.props.t('general.save')}
              </Button>
              <Popconfirm
                title={this.props.t('uploadCallsPage.table.confirmChange.title')}
                onConfirm={() => cancelEditing()}
                okText={this.props.t('uploadCallsPage.table.confirmChange.ok')}
                cancelText={this.props.t('uploadCallsPage.table.confirmChange.cancel')}
              >
                <Button type="link">
                  {this.props.t('uploadCallsPage.table.confirmChange.cancel')}
                </Button>
              </Popconfirm>
            </span>
          ) : (
            <>
              <Button
                type="link"
                disabled={!!editingCallId}
                onClick={() => startEditing(record.id)}
              >
                {this.props.t('uploadCallsPage.table.buttons.edit')}
              </Button>
              <Popconfirm
                title={this.props.t('uploadCallsPage.table.confirmDelete.title')}
                okText={this.props.t('uploadCallsPage.table.confirmDelete.ok')}
                cancelText={this.props.t('uploadCallsPage.table.confirmDelete.cancel')}
                onConfirm={() => deleteCall({ id: record.id })}
              >
                <Button type="link" disabled={!!editingCallId}>
                  {this.props.t('uploadCallsPage.table.buttons.delete')}
                </Button>
              </Popconfirm>
            </>
          );
        }
      }
    ];
  }

  componentDidMount = () => {
    const { loadCalls, history, setQuery, listQuery, listPage, loadUsers } = this.props;
    const { search } = history.location;

    if (search) {
      setQuery(search);
    } else if (listQuery) {
      history.push({ search: listQuery });
      loadCalls({ page: listPage, include: 'operator' });
    }

    loadUsers({ include: 'unit,role', pagination: 'false', sort: 'last_name' });
  };

  componentDidUpdate = prevProps => {
    const { history, listQuery, loadCalls, listPage } = this.props;
    if (this.isTableParamsUpdated(prevProps)) {
      history.push({ search: listQuery });
      loadCalls({ page: listPage, include: 'operator' });
    }
  };

  onDelete = async id => {
    const { deleteCall, loadCalls, listPage } = this.props;
    await deleteCall({ id });
    loadCalls({ page: listPage, include: 'operator' });
  };

  handleTableChange = pagination => {
    const { updatePage } = this.props;
    updatePage({ size: pagination.pageSize.toString(), number: pagination.current.toString() });
  };

  isEditing = record => {
    const { editingCallId } = this.props;
    return record.id === editingCallId;
  };

  saveCall = async record => {
    const { editedCalls, finishEditing } = this.props;
    const call = editedCalls[record.id];
    finishEditing({ id: record.id, ...call });
  };

  isTableParamsUpdated(prevProps) {
    const { listPage } = this.props;
    return prevProps.listPage !== listPage;
  }

  render() {
    const { calls, cancelEditing, loading, totalCount, listPage } = this.props;

    return (
      <STable
        size="small"
        bordered
        rowKey="id"
        dataSource={calls}
        columns={this.columns}
        rowClassName="editable-row"
        pagination={{
          pageSize: parseInt(listPage.size),
          current: parseInt(listPage.number),
          total: totalCount,
          onChange: cancelEditing
        }}
        onChange={this.handleTableChange}
        scroll={{ y: 'calc(100vh - 300px)' }}
        loading={loading}
      />
    );
  }
}

const SelectContainer = styled.div`
  display: flex;
  width: 100%;
  .ant-select.ant-select-enabled {
    width: 100% !important;
  }

  .ant-calendar-picker {
    min-width: 170px !important;
  }
`;

const mapStateToProps = (state, ownProps) => {
  const calls = state.uploadedCallsList.ids
    .map(id => state.phoneCallsResource.byIds[id])
    .filter(call => !!call);

  const users = Object.values(getUsersWithRoleAndUnit(state));
  const loading =
    state.uploadedCallsList.loading ||
    state.phoneCallsResource.loading ||
    state.phoneCallsResource.updateByIdStarted ||
    state.phoneCallsResource.deleteByIdStarted;
  return {
    loading,
    totalCount: state.uploadedCallsList.totalCount,
    calls,
    usersByIds: state.usersResource.byIds,
    users,
    editingCallId: state.uploadedCallsList.editingCallId,
    editedCalls: state.uploadedCallsList.callsByIds,
    listPage: state.uploadedCallsList.page,
    listQuery: state.uploadedCallsList.query,
    ...ownProps
  };
};

const mapDispatchToProps = dispatch => {
  return {
    startEditing: callId => dispatch(actions.startEditingCall({ callId })),
    cancelEditing: () => dispatch(actions.cancelEditingCall()),
    updateEditing: call => dispatch(actions.updateEditingCall(call)),
    finishEditing: async call => {
      await dispatch(phoneCallsResource.operations.updateById(call));
      dispatch(actions.finishEditingCall(call));
    },
    updatePage: pagination => dispatch(actions.updatePage(pagination)),
    setQuery: query => dispatch(actions.setCurrentQuery(query)),
    ...bindActionCreators(
      {
        deleteCall: phoneCallsResource.operations.deleteById,
        loadCalls: operations.load,
        loadUsers: usersResource.operations.load
      },
      dispatch
    )
  };
};

export default withTranslation()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(EditableTable))
);
