import { Tooltip, Typography } from 'antd';
import ValueBar from 'components/Dashboard/ValueBar';
import UserPreview from 'components/UserPreview/UserPreview';
import WidgetTable from 'components/Dashboard/SmallWidgetTable';
import { beatifyFloat } from 'core/utils/convertFloat';
import { valueFormatter } from 'core/utils/valueFormatter';
import { get, orderBy } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { updateActiveChartsViewItem } from 'redux/ui/dashboard/reducer';
import { stringify } from 'core/utils/queryString';
import { valueToColorZoneColor } from '../../../../utils';

const UsersTable = ({
  dataSource,
  checklistDefinition,
  checklistDefinitionMax,
  filters,
  updateActiveChartsViewItem,
  widgetId,
  selectedUserId,
  usersByIds
}) => {
  const { Text } = Typography;
  const { t } = useTranslation();

  const columns = [
    {
      title: (
        <Text>
          {t('dashboardPage.tableReviewsCountByOperators.viewModes.types.chart.table.name')}
        </Text>
      ),
      dataIndex: 'id',
      key: 'id',
      render: text => {
        return {
          props: {
            style: {
              backgroundColor: selectedUserId === text && 'var(--gray-light)'
            }
          },
          children: (
            <UserPreview
              userId={text}
              margin="0px"
              showAvatar
              showUnit
              link={`/user/${text}/charts${stringify({
                filters: {
                  symbolicTimeRange: filters.clientInteractionSymbolicTimeRange,
                  timeFrom: encodeURIComponent(filters.clientInteractionTimeFrom || ''),
                  timeTo: encodeURIComponent(filters.clientInteractionTimeTo || ''),
                  checklistDefinitionId: filters.checklistDefinitionsIds
                }
              })}`}
            />
          )
        };
      },

      width: 225,
      sorter: (a, b) => a.name.localeCompare(b.name)
    },
    {
      title: (
        <Tooltip
          title={t(
            'dashboardPage.tableReviewsCountByOperators.viewModes.types.chart.table.description'
          )}
        >
          <Text>
            {`${t(
              'dashboardPage.tableReviewsCountByOperators.viewModes.types.chart.table.averageScoreStr'
            )} ${valueFormatter({
              value: checklistDefinitionMax,
              ratingMode: checklistDefinition.ratingMode
            })})`}
          </Text>
        </Tooltip>
      ),
      dataIndex: 'value',
      key: 'value',
      render: (text, record) => {
        return {
          props: {
            style: { backgroundColor: selectedUserId === record.id && 'var(--gray-light)' }
          },
          children: (
            <ValueBar
              data={[record]}
              maxValue={checklistDefinitionMax}
              color={valueToColorZoneColor({
                value: text,
                colorZones: checklistDefinition.colorZones
              })}
              selectedUserId={filters.selectedUserId}
              ratingMode={checklistDefinition.ratingMode}
            />
          )
        };
      },

      sorter: (a, b) => a.value - b.value
    }
  ];

  return (
    <WidgetTable
      dataSource={orderBy(
        dataSource.reduce((acc, item) => {
          return [
            ...acc,
            { ...item, value: beatifyFloat(item.value), lastName: usersByIds[item.id]?.lastName }
          ];
        }, []),
        'lastName'
      )}
      columns={columns}
      showSorterTooltip={false}
      pagination={false}
      size="small"
      scroll={{ y: 462 }}
      onRow={(record, rowIndex) => {
        return {
          onClick: event => {
            updateActiveChartsViewItem({
              [widgetId]: {
                id: widgetId,
                selectedUserId: selectedUserId === record.id ? '' : record.id
              }
            });
          }
        };
      }}
    />
  );
};

const mapStateToProps = (state, ownProps) => {
  const { widgetId } = ownProps;
  return {
    selectedUserId: get(state, `uiDashboard.chartsView.widgets.${widgetId}.selectedUserId`, {}),
    usersByIds: state.usersResource.byIds
  };
};

const mapDispatchToProps = {
  updateActiveChartsViewItem
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersTable);
