import React, { useState } from 'react';
import Icon from 'components/Icon';
import { MessageSquare } from 'react-feather';
import SButton from 'components/Standard/SButton';
import SModal from 'components/Standard/SModal';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import TemplateSelector from './Components/TemplateSelector';

const Templates = ({ setCommentState, commentState, setEditorState }) => {
  const { t } = useTranslation();
  const [isVisibleSelector, setVisibleSelector] = useState(false);
  const [buttonPosition, setButtonPosition] = useState(false);

  return (
    <>
      <StyledModal
        visible={isVisibleSelector}
        width={535}
        footer={null}
        onCancel={() => setVisibleSelector(false)}
        closable={false}
      >
        <TemplateSelector
          commentState={commentState}
          setCommentState={setCommentState}
          setEditorState={setEditorState}
          setVisibleSelector={setVisibleSelector}
        />
      </StyledModal>
      <SButton
        ref={el => {
          if (el && !buttonPosition) return setButtonPosition(el.getBoundingClientRect());
        }}
        onClick={() => {
          setVisibleSelector(!isVisibleSelector);
        }}
        className="control-item"
        border="none"
        boxShadow="none"
        backgroundColor="transparent"
        data-title={t('templates.addTemplate')}
        style={{ position: 'absolute', top: 12, left: 156 }}
        _hover="background-color: transparent"
      >
        <Icon icon={MessageSquare} color="#6A6F7B" />
      </SButton>
    </>
  );
};

const StyledModal = styled(SModal)`
  &.ant-modal .ant-modal-body {
    min-height: 511px;
  }
`;

export default Templates;
