export default {
  workPlanTaskConfigurationEditor: {
    taskStarted: 'Задача запущена',
    taskSaved: 'Задача сохранена',
    deleteTask: 'Удалить',
    saveTask: 'Сохранить',
    startTask: 'Запустить',
    delete: {
      title: 'Удалить задачу?',
      taskDeleted: 'Задача удалена',
      cantDeleteTask: 'Не удалось удалить задачу'
    },
    schedule: {
      taskConfig: 'Параметры задачи',
      taskName: 'Название задачи',
      period: 'Период',
      timeFrom: 'Дата начала',
      timeTo: 'Дата завершения',
      repeatEvery: 'Повторять каждые',
      repeat: 'Повторения',
      toRepeat: 'Повторять',
      dayTimeFrom: 'Время начала',
      dayTimeTo: 'Время завершения',
      periodForTask: 'Период выполнения задачи',
      periodTask: 'Период выполнения',
      date: 'Дата',
      time: 'Время',
      start: 'Начать',
      end: 'Завершить в',
      stopTask: 'Остановить задачу',
      customPeriod: 'Настраиваемый период'
    },
    filters: {
      deleteFilters: 'Удалить фильтры',
      maxCount: 'Количество проверок',
      clientInteractionType: 'Тип коммуникации',
      addUsers: 'Добавить пользователей',
      add: 'Добавить',
      usersAdded: 'Пользователей',
      addFilters: 'Добавить тип коммуникации'
    },
    distribution: {
      addReviewer: 'Добавить проверяющего',
      reviewers: 'Проверяющие',
      manual: 'Вручную',
      auto: 'Равномерно'
    },
    addUsersModal: {
      users: 'Сотрудники',
      unit: 'Отдел',
      role: 'Роль'
    }
  }
};
