import { Button, Input } from 'antd';
import Editor from 'components/Comments/Editor';
import { isEmpty, isFunction } from 'lodash';
import React, { useState } from 'react';
import { utils } from 'react-media-player';
import { getCurrentUser } from 'redux/selectors/users';
import styled from 'styled-components';
import uniqid from 'uniqid';
import { Trans, useTranslation } from 'react-i18next';
import useEvent from '@react-hook/event';
import SRow from 'components/Standard/SRow';
import { useSelector } from 'react-redux';
import SCol from 'components/Standard/SCol';
import Icon from 'components/Icon';
import SButton from 'components/Standard/SButton';
import { Send } from 'react-feather';
import { stopPlaying } from 'redux/ui/recordPlayer/reducer';
import SCard from 'components/Standard/SCard';
import { ConditionaHideCommentCheckbox } from '../CommentsList/Comment/Components/ConditionaHideCommentCheckbox';

const CommentTimestamp = ({ playedSeconds }) => {
  return (
    <span>
      <span>
        <Trans i18nKey="components.timestamp" />
      </span>
      <Time value={utils.formatTime(playedSeconds)} disabled placeholder="00:00" />
    </span>
  );
};

const Time = styled(Input)`
  &.ant-input-disabled {
    width: 60px !important;
    margin: 0 0px 0 10px !important;
    background-color: white !important;
    color: #262626 !important;
  }
`;

const defaultCommentState = {
  text: '',
  metadata: {
    ratingFlag: ''
  },
  isPublicTemplate: false,
  hidden: false,
  isTimestampAdded: true
};

const CreateCommentForm = ({
  seconds,
  onCancel,
  allowSelectTimestamp = true,
  onCommentSave,
  reviewId,
  callId
}) => {
  const { t } = useTranslation();
  const loading = useSelector(
    state => state.commentsResource.createStarted || state.commentsResource.loading
  );
  const playedSeconds = useSelector(state => seconds || state.uiRecordPlayer.playedSeconds);
  const currentUser = useSelector(state => getCurrentUser(state));
  const addingCommentsToQuestionId = useSelector(
    state => state.uiChecklistManager.addingCommentsToQuestionId
  );
  const [commentState, setCommentState] = useState(defaultCommentState);
  const { text, metadata, isTimestampAdded, hidden, uploadedFiles = [] } = commentState;

  const toogleHidden = () => {
    const { hidden: ishidden } = commentState;
    setCommentState({ ...commentState, hidden: !ishidden });
  };

  const onSave = async () => {
    let meta = { ...(metadata?.ratingFlag && { ratingFlag: metadata?.ratingFlag }) };

    if (isTimestampAdded && playedSeconds) {
      meta = { ...metadata, timestamp: playedSeconds };
    }

    const commentParams = {
      text,
      id: uniqid(),
      reviewId,
      callId,
      metadata: meta,
      authorId: currentUser.id,
      hidden,
      commentType: 'review_comment',
      uploadedFilesIds: uploadedFiles.map(({ id }) => id)
    };

    if (isFunction(onCommentSave)) {
      onCommentSave(commentParams);
    }
    if (commentParams) {
      setCommentState(defaultCommentState);
    }
  };

  const isEmptyComment = isEmpty(uploadedFiles) ? isEmpty(text?.trim()) : false;

  useEvent(document, 'keydown', e => {
    const drawer = document.getElementsByClassName('ant-drawer')[0];
    const isEditorKeyPress = e.target.classList.contains('public-DraftEditor-content');
    if (e.code === 'Enter' && (e.ctrlKey || e.metaKey) && !isEmptyComment && isEditorKeyPress) {
      onSave();
      if (addingCommentsToQuestionId) {
        if (drawer) drawer.focus();
      }
    }
  });

  return (
    <SCard bordered shadowed bodyPadding="8px 20px 13px 23px">
      <SRow>
        <SCol width="calc(100% - 38px)">
          <Editor
            commentState={commentState}
            setCommentState={setCommentState}
            showTemplates
            showFlags
            showAttachments
            stopPlaying={stopPlaying}
          />
        </SCol>
        <SCol width="38px" paddingTop="56px" display="flex" justifyContent="flex-end">
          <SButton
            size="icon"
            icon={<Icon icon={Send} size="24px" />}
            border="none"
            boxShadow="none"
            color="var(--blue_6)"
            onClick={onSave}
            disabled={loading || isEmptyComment}
            style={{ backgroundColor: 'transparent' }}
          />
        </SCol>
      </SRow>

      <SRow gutter={[8, 8]} type="flex" align="middle" justify="start">
        <SCol>
          <Button type="default" onClick={onCancel}>
            {t('components.commentsPanel.buttons.cancel')}
          </Button>
        </SCol>

        <SCol>
          <ConditionaHideCommentCheckbox checked={hidden} onChange={toogleHidden}>
            {t('components.commentsPanel.buttons.hidden')}
          </ConditionaHideCommentCheckbox>
        </SCol>

        {allowSelectTimestamp && (
          <SCol>
            <CommentTimestamp playedSeconds={playedSeconds} isTimestampAdded={isTimestampAdded} />
          </SCol>
        )}

        {/* <Button disabled={loading || isEmptyComment} type="primary" onClick={onSave}>
          {t('components.commentsPanel.buttons.addComment')}
        </Button> */}
      </SRow>
    </SCard>
  );
};

export default CreateCommentForm;
