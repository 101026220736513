export default {
  menu: {
    appMenu: {
      analytics: 'Analytics',
      communications: 'Communication list',
      tasks: 'Tasks',
      library: 'Library',
      addReview: 'Add evaluation',
      team: 'Team',
      settings: 'Tools',
      checklistsSettins: 'Score cards',
      tagsSettings: 'Tags',
      calibrations: 'Calibration sessions',
      workPlanTaskConfigurations: 'Tasks',
      appeals: 'Appeals'
    },
    userMenu: {
      profile: 'Profile',
      profileSettings: 'Profile settings',
      organizationSettings: 'Organization settings',
      organizationStructureSettings: 'Employees and access rights',
      integrationsSettings: 'Integrations',
      logout: 'Log out'
    },
    helpMenu: {
      helpCenter: 'Help center',
      updates: 'Last updates',
      support: 'Contact support',
      hotKeys: 'Hotkeys'
    },
    alerts: {
      rolesAlert: {
        title:
          '💡 The Administrator and Operator roles are basic, without the possibility to delete them. More information about roles, access rights and their settings you may read ',
        button: 'here'
      }
    }
  }
};
