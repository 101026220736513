import SCard from 'components/Standard/SCard';
import SCol from 'components/Standard/SCol';
import SRow from 'components/Standard/SRow';
import SText from 'components/Standard/SText';
import React from 'react';
import { useSelector } from 'react-redux';
import { orderBy, isEqual } from 'lodash';
import { useTranslation } from 'react-i18next';
import ObjectionComment from './ObjectionComment';

const QuestionObjectionCard = ({ appeal, question, objection, review }) => {
  const { t } = useTranslation();
  const appellationComments = useSelector(
    state =>
      Object.values(state.appellationCommentsResource.byIds).filter(
        appealComment =>
          appealComment?.questionId === question?.id && appealComment?.appellationId === appeal?.id
      ),
    isEqual
  );

  const commentsByIds = useSelector(state => state.commentsResource.byIds, isEqual);

  const reviewComments = review?.commentsIds.reduce((acc, reviewCommentId) => {
    const reviewComment = commentsByIds[reviewCommentId];
    if (reviewComment?.metadata?.questionId !== question.id) return acc;

    return [...acc, reviewComment];
  }, []);

  const allComments = orderBy([...appellationComments, ...reviewComments], 'createdAt');

  return (
    <SCard background="#F0F2F5" rounded="10px">
      <SRow>
        <SCol span={24}>
          <SText fontSize="16px">{`${t('general.criterion')}: `}</SText>
          <SText fontSize="16px" fontWeight={500} marginRight="10px">
            {question?.name}
          </SText>
          <SText fontSize="16px" color="var(--blue_6)">
            {`Оценка: ${objection?.answerBefore}`}
          </SText>
        </SCol>
        <SCol span={24}>
          {allComments.map(comment => (
            <ObjectionComment comment={comment} />
          ))}
        </SCol>
      </SRow>
    </SCard>
  );
};

export default QuestionObjectionCard;
