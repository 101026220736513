export default {
  integrationsSettingsPage: {
    proceed: 'Continuar',
    save: 'Guardar',
    delete: 'Eliminar',
    sync: 'Sincronizar',
    selectSyncPeriod: 'Seleccionar período de sincronización',
    deleteConfirm: {
      title: '¿Eliminar integración?',
      ok: 'Sí',
      cancel: 'No'
    },
    nameAndType: {
      name: 'Nombre',
      integrationName: 'Nombre de integración',
      type: 'Tipo',
      integrationType: 'Tipo de integración',
      yourB24address: 'Tu dirección Bitrix 24'
    },
    integrationPage: {
      title: 'Integración',
      sync: 'Sincronización',
      users: 'Usuarios',
      integrationSettings: 'Configuración de integración',
      customFieldsSettings: {
        title: 'Configuración de campos personalizados',
        addField: 'Agregar campo',
        deleteConfirm: {
          title: 'Eliminar campo personalizado',
          okText: 'Eliminar'
        },
        editor: {
          fieldName: 'Nombre de campo',
          fieldKey: 'Clave de campo',
          fieldType: 'Tipo de campo',
          usedForFilters: 'Se usa para filtrar',
          usedForReviews: 'Se usa para calificaciones',
          save: 'Guardar',
          edit: 'Editar',
          delete: 'Eliminar'
        }
      },
      testRequest: {
        title: 'Solicitud de prueba',
        success: 'Comunicación agregada al sistema, disponible en este enlace',
        failure: 'No se pudo agregar la comunicación de prueba',
        makeRequest: 'Hacer una solicitud'
      }
    },
    messages: {
      integrationSuccessfullyCreate: 'La integración se creó correctamente',
      integrationCreateFailed: 'No se pudo crear la integración'
    },
    addIntegration: {
      title: 'Agregar integración',
      yourIntegrations: 'Sus integraciones'
    },
    manageUserAccounts: {
      totalUsers: 'Usuarios',
      activeUsers: 'Activo',
      selectAll: 'Seleccionar todo',
      columns: {
        user: 'Empleado',
        email: 'Correo electrónico',
        id: 'ID',
        sync: 'Sincronizar'
      },
      add: 'Agregar',
      downloadCSV: 'Ejemplo de descarga de CSV',
      manual: 'Manualmente',
      fromCSV: 'A través de CSV',
      sendingFile: 'Enviando un archivo ...',
      csvModal: {
        title: 'Felicitaciones',
        content: '¡Ha subido a todos los usuarios desde su archivo CSV!',
        okText: 'Continuar'
      },
      usersAlreadyInSystem: 'Qolio ya tiene usuarios con este correo electrónico',
      accountToUsers: 'Estas cuentas se agregarán a los usuarios',
      brokenEmail: 'El usuario no tiene una dirección de correo electrónico'
    },
    createCustomIntegration: { addUsersTitle: 'Agragar usuario' },
    createSaasIntegration: {
      errorMessage: 'Se produjo un error al sincronizar datos con',
      errorMessageContent:
        'Vuelva a instalar la aplicación o envíenos un correo electrónico a info@qolio.io',
      sync: 'Sincronizar'
    },
    customUserAccountForm: {
      enterEmail: 'Introduzca el correo electrónico',
      enterCorrectEmail: 'Introduzca el correo electrónico correcto',
      sync: 'Sincronizado',
      notSync: 'No sincroniza',
      enterId: 'Ingrese id'
    },
    sipuniAccountsImported:
      'Las cuentas de usuario se importan. Para configurarlos, debe vincularlos a cuentas en Qolio. Para hacer esto, ve a ',
    urlAndToken: { generate: 'Generar' }
  }
};
