export default {
  workPlanTaskConfigurationEditor: {
    taskStarted: 'Attività iniziata',
    taskSaved: 'Attività salvata',
    deleteTask: 'Elimina',
    saveTask: 'Salvare',
    startTask: 'Correre',
    delete: {
      title: "Eliminare l'attività?",
      taskDeleted: 'Attività eliminata',
      cantDeleteTask: "Impossibile eliminare l'attività"
    },
    schedule: {
      taskConfig: "Parametri dell'attività",
      taskName: "Nome dell'attività",
      period: 'Период',
      timeFrom: "La data dell'inizio",
      timeTo: 'Data di completamento',
      repeatEvery: 'Ripeti ogni',
      repeat: 'Ripetizioni',
      toRepeat: 'Ripetere',
      dayTimeFrom: 'Ora di inizio',
      dayTimeTo: 'Tempo di completamento',
      periodForTask: "Periodo di esecuzione dell'attività",
      periodTask: 'Periodo di esecuzione',
      date: 'Data',
      time: 'Tempo',
      start: 'Iniziare',
      end: 'Fine in',
      stopTask: 'Interrompi attività',
      customPeriod: 'Periodo personalizzabile'
    },
    filters: {
      deleteFilters: 'Rimuovi filtri',
      maxCount: 'Numero di valutazioni',
      clientInteractionType: 'Tipo di comunicazione',
      addUsers: 'Aggiungi utente',
      add: 'Aggiungere',
      usersAdded: 'Utenti',
      addFilters: 'Aggiungi tipo di comunicazione'
    },
    distribution: {
      addReviewer: 'Aggiungi recensore',
      reviewers: 'Revisori',
      manual: 'Manuale',
      auto: 'Uniformemente'
    },
    addUsersModal: {
      users: 'Dipendenti',
      unit: 'Dipartimento',
      role: 'Ruolo'
    }
  }
};
