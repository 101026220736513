import React, { useEffect, useCallback } from 'react';
import SRow from 'components/Standard/SRow';
import SCol from 'components/Standard/SCol';
import { Typography } from 'antd';
import SCard from 'components/Standard/SCard';
import STable from 'components/Standard/STable';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { CALL_DIRECTION_LITERALS } from 'core/utils/constants';
import SText from 'components/Standard/SText';
import moment from 'moment';
import { beatifyFloat } from 'core/utils/convertFloat';
import UserPreview from 'components/UserPreview';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual, get, find } from 'lodash';
import { operations, actions } from 'redux/lists/clientInteractionsList';
import ClientInteractionLink from 'components/ClientInteractionLink';
import ClientInteractionDrawer from 'components/ClientInteractionDrawer';
import CommentsPreview from 'components/Comments/CommentsPreview';
import { TagsDisplay } from 'components/Tags/TagsDisplay';
import { workPlanTaskAssignmentsResource } from 'redux/resources/workPlanTaskAssignments';

const WorkPlanTaskItems = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { task_id: currentTaskId, assignment_id: currentAssignmentId } = useParams();
  const { Title } = Typography;

  const { ids, totalCount, page, sort, filters, loading } = useSelector(
    state => state.clientInteractionsList
  );
  const { updatePage, setSorting, setFilters } = actions;

  const taskItemsByIds = useSelector(state => state.workPlanTaskItemsResource.byIds, isEqual);
  const assignmetnsByIds = useSelector(
    state => state.workPlanTaskAssignmentsResource.byIds,
    isEqual
  );
  const reviewsByIds = useSelector(state => state.reviewsResource.byIds, isEqual);
  const usersByIds = useSelector(state => state.usersResource.byIds, isEqual);
  const commentsByIds = useSelector(state => state.commentsResource.byIds, isEqual);
  const tasksByIds = useSelector(state => state.tasksResource.byIds, isEqual);
  const taskDefinitionsByIds = useSelector(state => state.taskDefinitionsResource.byIds, isEqual);
  const checklistsByIds = useSelector(state => state.checklistsResource.byIds, isEqual);
  const clientInteractionsByIds = useSelector(
    state => state.clientInteractionsResource.byIds,
    isEqual
  );

  const currentAssignment = assignmetnsByIds[currentAssignmentId];

  useEffect(() => {
    dispatch(
      setFilters({
        taskAssignmentsIds: [currentAssignmentId]
      })
    );
  }, []);

  const clientInteractions = ids.map(item => clientInteractionsByIds[item]);

  useEffect(() => {
    dispatch(
      workPlanTaskAssignmentsResource.operations.load({
        filters: {
          taskId: [currentTaskId]
        },
        include: 'reviewer.unit,task.task_configuration,task_items'
      })
    );
    dispatch(
      operations.load({
        page,
        filters: { taskAssignmentsIds: [currentAssignmentId] },
        sort,
        include: 'operator,reviews.checklist,reviews.comments,reviews.tasks.task_definition'
      })
    );
  }, [page, sort, filters]);

  const handleTableChange = useCallback(
    (pagination, filters, sorter) => {
      dispatch(
        updatePage({
          size: pagination.pageSize.toString(),
          number: pagination.current.toString()
        })
      );
      dispatch(setSorting(sorter));
    },
    [dispatch]
  );

  const tableColumns = [
    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      width: 40,
      render: (text, record) => {
        const taskItem = find(Object.values(taskItemsByIds), { clientInteractionId: record.id });
        return (
          <ClientInteractionLink
            isReview={!!taskItem?.reviewId}
            id={taskItem?.reviewId || text}
            communication={record}
          />
        );
      }
    },

    {
      title: t('general.dateAndTime'),
      dataIndex: 'startedAt',
      key: 'startedAt',
      ellipsis: true,
      sorter: true,
      width: 160,
      render: text => <SText>{moment(text).format('DD/MM/YYYY HH:mm')}</SText>
    },

    {
      title: t('general.initials'),
      dataIndex: 'operatorId',
      key: 'operatorId',
      ellipsis: true,
      render: text => <UserPreview userId={text} disabled showAvatar />
    },

    {
      title: t('general.direction'),
      dataIndex: 'direction',
      key: 'direction',
      ellipsis: true,
      width: 160,
      render: text => <SText>{t(CALL_DIRECTION_LITERALS[text])}</SText>
    },

    {
      title: t('general.duration'),
      dataIndex: 'duration',
      key: 'duration',
      ellipsis: true,
      align: 'center',
      width: 100,
      render: text => <SText>{moment.duration(text, 'seconds').format('mm:ss')}</SText>
    },

    {
      title: t('general.score'),
      dataIndex: 'left',
      key: 'left',
      align: 'center',
      ellipsis: true,
      width: 100,
      render: (text, record) => {
        const taskItem = find(Object.values(taskItemsByIds), { clientInteractionId: record.id });
        const review = reviewsByIds[taskItem?.reviewId];
        const checklist = checklistsByIds[review?.checklistId];
        return <SText>{beatifyFloat(checklist?.score) || '-'}</SText>;
      }
    },

    {
      title: t('general.flagsAndComments'),
      dataIndex: 'flagsAndComments',
      key: 'flagsAndComments',
      align: 'center',
      width: 200,
      render: (text, record) => {
        const taskItem = find(Object.values(taskItemsByIds), { clientInteractionId: record.id });
        const review = reviewsByIds[taskItem?.reviewId];
        const checklist = checklistsByIds[review?.checklistId];
        const comments = get(review, 'commentsIds', []).map(item => commentsByIds[item]);

        return (
          <CommentsPreview
            comments={comments}
            checklistData={{
              checklist: {
                commment: checklist?.comment
              }
            }}
          />
        );
      }
    },

    {
      title: t('general.phoneNumber'),
      dataIndex: 'clientPhoneNumber',
      key: 'clientPhoneNumber',
      ellipsis: true,
      width: 165,
      render: text => <SText>{text}</SText>
    },

    {
      title: t('general.tags'),
      dataIndex: 'countData',
      key: 'countData',
      // ellipsis: true,
      width: 200,
      render: (text, record) => {
        const taskItem = find(Object.values(taskItemsByIds), { clientInteractionId: record.id });
        const review = reviewsByIds[taskItem?.reviewId];
        const tasks = get(review, 'tasksIds', []).map(item => {
          const task = tasksByIds[item];
          return {
            ...task,
            taskDefinition: taskDefinitionsByIds[task?.taskDefinitionId]
          };
        });

        return <TagsDisplay tasks={tasks} truncate={16} />;
      }
    },

    {
      title: t('workPlanTasks.tableColumns.reviewCreatedAt'),
      dataIndex: 'reviewCreatedAt',
      key: 'reviewCreatedAt',
      ellipsis: true,
      width: 165,
      sorter: true,
      render: (text, record) => {
        const taskItem = find(Object.values(taskItemsByIds), { clientInteractionId: record.id });
        const review = reviewsByIds[taskItem?.reviewId];
        return <SText>{review ? moment(review?.createdAt).format('DD/MM/YYYY HH:mm') : '-'}</SText>;
      }
    }
  ];

  return (
    <SRow margin="16px">
      <Helmet>
        <title>{t('pagesMeta.workPlanTaskConfigurationsPage.title')}</title>
      </Helmet>
      <SCol span={24}>
        <SRow gutter={[16, 16]}>
          <SCol span={24}>
            <SRow type="flex" align="middle" justify="space-between">
              <SCol>
                <Title level={4}>
                  {`${t('general.employee')}: ${usersByIds[currentAssignment?.reviewerId]?.name}}`}
                </Title>
              </SCol>
            </SRow>
          </SCol>
          <SCol span={24}>
            <SCard bordered shadowed bodyPadding="14px 20px">
              <STable
                dataSource={clientInteractions}
                columns={tableColumns}
                loading={loading}
                border
                pagination={{
                  pageSize: parseInt(page.size),
                  current: parseInt(page.number),
                  total: totalCount,
                  showSizeChanger: true,
                  pageSizeOptions: ['10', '25', '50'],
                  size: 'small'
                }}
                onChange={handleTableChange}
                fixSorterColumns
                scroll={{ y: 'calc(100vh - 300px)', x: 'max-content' }}
                size="small"
              />
              <ClientInteractionDrawer shouldLoad />
            </SCard>
          </SCol>
        </SRow>
      </SCol>
    </SRow>
  );
};

export default WorkPlanTaskItems;
