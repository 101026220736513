import React, { useCallback } from 'react';

import { isEmpty, isEqual } from 'lodash';
import { Row, Typography, Col, Button } from 'antd';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const { Title } = Typography;
const UnitSettings = ({ setAddingUserToUnit }) => {
  const { t } = useTranslation();
  const selectedUnit = useSelector(state => state.uiOrganizationPage.selectedUnit, isEqual);
  const { name, id } = selectedUnit;
  const onAddEmployee = useCallback(() => {
    setAddingUserToUnit(id);
  }, [id]);

  if (isEmpty(selectedUnit)) return null;

  return (
    <Row justify="space-between" align="middle" gutter={[0, 16]} style={{ marginBottom: '-8px' }}>
      <Col>
        <Title className="no-margin" level={5}>{`${name}`}</Title>
      </Col>
      <Col>
        <Button type="primary" size="small" onClick={onAddEmployee}>
          {t('organizationStructure.addEmployee')}
        </Button>
      </Col>
    </Row>
  );
};

export default UnitSettings;
