export default {
  workPlanTaskConfigurationEditor: {
    taskStarted: 'Task started',
    taskSaved: 'Task saved',
    deleteTask: 'Delete',
    saveTask: 'Save',
    startTask: 'Run',
    delete: {
      title: 'Delete Task?',
      taskDeleted: 'Task deleted',
      cantDeleteTask: 'Failed to delete task'
    },
    schedule: {
      taskConfig: 'Task parameters',
      taskName: 'Task name',
      period: 'Timeframe',
      timeFrom: 'Start Date',
      timeTo: 'End date',
      repeatEvery: 'Repeat every',
      repeat: 'Repeats',
      toRepeat: 'Repeat',
      dayTimeFrom: 'Start time',
      dayTimeTo: 'End time',
      periodForTask: 'Task execution period',
      periodTask: 'Execution period',
      date: 'Date',
      time: 'Time',
      start: 'Start',
      end: 'Finish at',
      stopTask: 'Stop task',
      customPeriod: 'Customizable period'
    },
    filters: {
      deleteFilters: 'Delete filters',
      maxCount: 'Number of reviews',
      clientInteractionType: 'Communication type',
      addUsers: 'Add users',
      add: 'Add',
      usersAdded: 'Users',
      addFilters: 'Add communication type'
    },
    distribution: {
      addReviewer: 'Add reviewer',
      reviewers: 'Reviewers',
      manual: 'Manually',
      auto: 'Linear'
    },
    addUsersModal: {
      users: 'Employees',
      unit: 'Unit',
      role: 'Role'
    }
  }
};
