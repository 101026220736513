import { Col, Input, message, Modal, Row } from 'antd';
import { TruncatedButton } from 'components/Truncated';
import withConditionalRender from 'components/WithConditionalRender/withConditionalRender';
import { PERMISSIONS, SYMBOLIC_TIME_RANGE } from 'core/utils/constants';
import { get, isEmpty, throttle } from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { search } from 'redux/entities/search/operations';
import { actions } from 'redux/lists/clientInteractionsList';
import { checklistDefinitionsResource } from 'redux/resources/checklistDefinitions';
import { customFieldsResource } from 'redux/resources/customFields';
import { salePipelinesResource } from 'redux/resources/salesPipelines';
import { statusesResource } from 'redux/resources/status';
import { taskDefinitionsResource } from 'redux/resources/taskDefinitions';
import { usersResource } from 'redux/resources/users';
import { workPlanTaskConfigurationsResource } from 'redux/resources/workPlanTaskConfigurations';
import { updateTable } from 'redux/ui/clientInteractions/operations';
import {
  saveTableFilters,
  setDefaultState,
  setSearchCategory,
  setSearchState
} from 'redux/ui/clientInteractions/reducer';
import styled from 'styled-components';
import ClientInteractionsMeta from '../ClientInteractionsMeta';
import AddCustomCommunicationButton from './AddCustomCommunicationButton';
import CustomFieldsFilters from './CustomFieldsFilters';
import { FilterContainer } from './styled';
import TableConfigurations from './TableConfigurations/TableConfigurations';
import TableGeneralFilters from './TableGeneralFilters';
import TableReviewsFilters from './TableReviewsFilters';
import WorkPlanTaskFilter from './WorkPlanTaskFilter';

const TableFilters = ({
  filters,
  customFieldFilters = {},
  searchLoading,
  setFilters,
  disabled,
  loadChecklistDefinitions,
  loadUsers,
  loadTaskDefinitions,
  saveTableFilters,
  tableId,
  tableSharedUsers,
  updateTable,
  updateFilters,
  setSearchState,
  search,
  loadCustomFields,
  setCustomFieldFilters,
  loadStatuses,
  setDefaultState,
  loadSalePipelines,
  meta,
  searchState,
  loadWorkPlanTasks
}) => {
  const { t } = useTranslation();

  const resetFilters = () => {
    setFilters({ clientInteractionSymbolicTimeRange: SYMBOLIC_TIME_RANGE.LAST_SEVEN_DAYS });
    setCustomFieldFilters({});
  };

  useEffect(() => {
    loadChecklistDefinitions({ pagination: 'false' });
    loadUsers({
      pagination: 'false',
      include: 'unit,role.levels',
      sort: 'last_name',
      'filters[with_inactive]': 'true'
    });
    loadStatuses();
    loadTaskDefinitions({ pagination: 'false' });
    loadCustomFields();
    loadSalePipelines({ pagination: 'false', include: 'statuses' });
    loadWorkPlanTasks({ pagination: 'false', include: 'last-task.assignments' });

    return setDefaultState;
  }, []);

  useEffect(() => {
    const { length, showResult } = searchState;
    // TODO: translate
    if (showResult) {
      const bySearchText = `Найдено по поиску: ${
        length === 20 ? t('clientInteractionsPage.tableFilters.search.result.bigResult') : length
      }. По заданным фильтрам: ${meta.totalCount}`;

      message.info({
        content: `${bySearchText}`,
        key: 'searchMessage',
        duration: 4
      });

      setSearchState({ ...searchState, showResult: false });
    }
  }, [meta.totalCount]);

  const ConditionalAddCustomCommunicationButton = withConditionalRender(
    { allowedPermissions: [PERMISSIONS.CAN_MAKE_REVIEW] },
    AddCustomCommunicationButton
  );

  const onUpdateTable = async () => {
    saveTableFilters({ filters, customFieldFilters });
    const onOk = async () => {
      try {
        await updateTable({ id: tableId, filters, customFieldFilters });
        if (isEmpty(filters?.taskAssignmentsIds)) {
          message.success(
            t('clientInteractionsPage.tableFilters.messages.tableSuccessfullyUpdated')
          );
        } else {
          message.success(t('messages.success.filtersApplied'));
        }
      } catch (error) {
        console.log(error);
        message.error(t('clientInteractionsPage.tableFilters.messages.updateTableFailed'));
      }
    };

    return isEmpty(tableSharedUsers)
      ? onOk()
      : Modal.confirm({
          title: t('clientInteractionsPage.tableFilters.shareConfirm.title'),
          content: t('clientInteractionsPage.tableFilters.shareConfirm.description'),
          okText: t('clientInteractionsPage.tableFilters.shareConfirm.ok'),
          cancelText: t('clientInteractionsPage.tableFilters.shareConfirm.cancel'),
          onOk
        });
  };

  const onSearch = throttle(
    async e => {
      e.persist();
      const value = get(e, 'target.value', '');
      updateFilters({ clientPhoneNumber: value });
      if (isEmpty(value)) {
        return setSearchState({ results: {}, value: '', length: 0, showResult: false });
      }

      // ! TODO: map class to searchCategory
      const results = await search({ class: 'client_interactions', search: value });
      const foundBySearch = results ? Object.values(results).length : 0;

      setSearchState({ results, length: foundBySearch, showResult: true, value });
    },
    500,
    { trailing: false }
  );

  return (
    <>
      <Row>
        <ClientInteractionsMeta />
      </Row>
      <Row>
        <FilterContainer style={{ marginTop: 0 }}>
          <Input
            placeholder={t(
              'clientInteractionsPage.tableFilters.search.clientPhoneNumberPlaceholder'
            )}
            value={filters.clientPhoneNumber}
            loading={searchLoading}
            onChange={onSearch}
          />
        </FilterContainer>
      </Row>
      <Row gutter={[8, 8]} style={{ margin: '-4px' }}>
        <Col span={24}>
          <TruncatedButton type="primary" block onClick={onUpdateTable} disabled={disabled}>
            {t('clientInteractionsPage.tableFilters.buttons.applyFilters')}
          </TruncatedButton>
        </Col>

        <Col span={24}>
          <TruncatedButton
            type="ghost"
            block
            style={{ background: 'white' }}
            onClick={resetFilters}
            disabled={disabled}
          >
            {t('clientInteractionsPage.tableFilters.buttons.resetFilters')}
          </TruncatedButton>
        </Col>

        <Col span={24}>
          <ConditionalAddCustomCommunicationButton />
        </Col>
      </Row>
      <FiltersSection>
        <TableConfigurations />
        <WorkPlanTaskFilter />
      </FiltersSection>
      <FiltersContainer>
        <FiltersSection style={{ marginTop: 0 }}>
          <TableGeneralFilters />
        </FiltersSection>

        {/* Filters Section inside */}
        <CustomFieldsFilters />

        <FiltersSection style={{ marginBottom: 0 }}>
          <TableReviewsFilters />
        </FiltersSection>
      </FiltersContainer>
    </>
  );
};

const FiltersSection = styled(Row)`
  margin: 16px 0;
`;

const FiltersContainer = styled.div`
  /* 394px - magic number всего контента что сверху  */
  max-height: calc(100vh - 394px);
  padding-right: 8px;
  overflow-y: auto;
`;

const mapStateToProps = state => {
  const currentUserId = state.reduxTokenAuth.currentUser.attributes.id;
  const communicationTables = state.communicationTablesResource.byIds;
  const {
    tableLoading,
    tableId,
    tableFilters,
    tableCustomFieldFilters,
    tableSharedUsers,
    searchCategory,
    searchState,
    meta
  } = state.uiClientInteractions;
  const isShared = currentUserId !== get(communicationTables, `${tableId}.creatorId`, '');

  return {
    customFields: state.customFieldsResource.byIds,
    searchLoading: state.search.loading,
    filters: state.clientInteractionsList.filters,
    customFieldFilters: state.clientInteractionsList.customFieldFilters,
    savedTableFilters: get(state.communicationTablesResource.byIds, `${tableId}.filters`, {}),
    tableSharedUsers,
    tableFilters,
    tableCustomFieldFilters,
    tableId,
    isShared,
    searchCategory,
    searchState,
    meta,
    disabled: tableLoading || isEmpty(tableId) || isShared
  };
};

const mapDispatchToProps = {
  updateFilters: actions.updateFilters,
  setFilters: actions.setFilters,
  setCustomFieldFilters: actions.setCustomFieldFilters,
  loadChecklistDefinitions: checklistDefinitionsResource.operations.load,
  loadUsers: usersResource.operations.load,
  loadTaskDefinitions: taskDefinitionsResource.operations.load,
  saveTableFilters,
  updateTable,
  setSearchCategory,
  setSearchState,
  search,
  loadCustomFields: customFieldsResource.operations.load,
  loadStatuses: statusesResource.operations.load,
  setDefaultState,
  loadSalePipelines: salePipelinesResource.operations.load,
  loadWorkPlanTasks: workPlanTaskConfigurationsResource.operations.load
};

export default connect(mapStateToProps, mapDispatchToProps)(TableFilters);
