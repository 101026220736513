import { Checkbox } from 'antd';
import SButton from 'components/Standard/SButton';
import SCol from 'components/Standard/SCol';
import SRow from 'components/Standard/SRow';
import SText from 'components/Standard/SText';
import { isEqual, isNil } from 'lodash';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getCurrentUser } from 'redux/selectors/users';
import {
  setQuestionIdToAppealComment,
  setQuestionIdToAppealObjection
} from 'redux/ui/checklistManager/reducer';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const StyledCheckbox = styled(Checkbox)`
  && .ant-checkbox-checked .ant-checkbox-inner {
    border-color: ${props => (props.status ? 'var(--black_2)' : 'var(--blue_6)')};
    &:hover {
      border-color: ${props => (props.status ? 'var(--black_2)' : 'var(--blue_6)')};
    }
    &::after {
      border-color: ${props => (props.status ? 'var(--black_2)' : 'white')};
    }
  }
  && .ant-checkbox-checked::after {
    border-color: ${props => (props.status ? 'var(--black_2)' : 'var(--link-color)')};
  }
`;

const QuestionAppealStatus = ({ appealId, questionId, objection, questionHasAnswer }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const appeal = useSelector(state => state.appellationsResource.byIds[appealId], isEqual);
  const currentUser = useSelector(getCurrentUser, isEqual);
  const toggleStatus = e => {
    const value = e.target.checked;
    dispatch(
      setQuestionIdToAppealObjection({ questionId, objection: value ? { new: true } : null })
    );

    if (!value) {
      dispatch(setQuestionIdToAppealComment({ questionId, comment: null }));
    }
  };

  if (appealId === 'new' && questionHasAnswer) {
    return (
      <SCol>
        <StyledCheckbox checked={objection?.new} status={objection?.new} onChange={toggleStatus}>
          <SText color={objection?.new ? 'var(--black_2)' : 'inherit'}>
            {t('general.disagree')}
          </SText>
        </StyledCheckbox>
      </SCol>
    );
  }

  const onAcceptAppeal = () => {
    dispatch(
      setQuestionIdToAppealObjection({ questionId, objection: { ...objection, accepted: true } })
    );
  };

  const onDenyAppeal = () => {
    dispatch(
      setQuestionIdToAppealObjection({ questionId, objection: { ...objection, accepted: false } })
    );
  };

  const onToggleAcceptence = () => {
    dispatch(
      setQuestionIdToAppealObjection({
        questionId,
        objection: { ...objection, accepted: !objection.accepted }
      })
    );
  };

  if (appeal?.assignedToId === currentUser?.id && objection && isNil(objection?.accepted)) {
    return (
      <SCol>
        <SRow gutter={[12, 0]}>
          <SCol>
            <SButton
              type="text"
              color="white"
              height="24px"
              padding="0"
              _hover="color:white;background:transparent"
              _focus="color:white;background:transparent"
              onClick={onAcceptAppeal}
            >
              {t('general.accept')}
            </SButton>
          </SCol>
          <SCol>
            <SButton
              type="text"
              color="var(--black_2)"
              height="24px"
              padding="0"
              _hover="color:white;background:transparent"
              _focus="color:white;background:transparent"
              onClick={onDenyAppeal}
            >
              {t('general.reject')}
            </SButton>
          </SCol>
        </SRow>
      </SCol>
    );
  }

  if (objection && !isNil(objection?.accepted)) {
    return (
      <SCol>
        <SRow gutter={[12, 0]}>
          <SCol order={objection?.accepted ? 1 : 2}>
            <SText color="white">
              {objection?.accepted ? 'Апелляция принята' : 'Апелляция отклонена'}
            </SText>
          </SCol>
          {appeal?.assignedToId === currentUser?.id && !appeal?.questionObjectionsProcessed && (
            <SCol order={objection?.accepted ? 2 : 1}>
              <SButton
                type="text"
                color="var(--blue_4)"
                height="24px"
                padding="0"
                _hover="color:white;background:transparent"
                _focus="color:white;background:transparent"
                onClick={onToggleAcceptence}
              >
                {objection?.accepted ? 'Отклонить' : 'Принять'}
              </SButton>
            </SCol>
          )}
        </SRow>
      </SCol>
    );
  }

  return null;
};

export default QuestionAppealStatus;
