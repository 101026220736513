import { Dropdown } from 'antd';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import SText from 'components/Standard/SText';
import { useDispatch, useSelector } from 'react-redux';
import { workPlanTaskAssignmentsResource } from 'redux/resources/workPlanTaskAssignments';
import { getCurrentUserAssignmentsByTaskStatus } from 'redux/selectors/workPlanTasks';
import { isEqual } from 'lodash';
import SCard from 'components/Standard/SCard';
import SRow from 'components/Standard/SRow';
import SCol from 'components/Standard/SCol';
import { WORK_PLAN_TASK_CONFIGURATIONS_STATUSES } from 'core/utils/constants';

const WorkPlanTasksMenu = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const currentUserId = useSelector(state => state.reduxTokenAuth.currentUser.attributes.id);

  const loadAssignments = () => {
    dispatch(
      workPlanTaskAssignmentsResource.operations.load({
        pagination: false,
        filters: {
          reviewersIds: currentUserId,
          taskStatus: WORK_PLAN_TASK_CONFIGURATIONS_STATUSES.in_progress.value
        },
        include: 'task.task_configuration'
      })
    );
  };

  const currentUserAssignments = useSelector(
    state =>
      getCurrentUserAssignmentsByTaskStatus(state, {
        status: WORK_PLAN_TASK_CONFIGURATIONS_STATUSES.in_progress.value
      }),
    isEqual
  );

  useEffect(() => {
    if (currentUserId) loadAssignments();
  }, [currentUserId]);

  const assignmentsList = (
    <SCard style={{ width: 284 }}>
      {currentUserAssignments.map(item => (
        <SRow margin="8px" type="flex" align="middle" justify="space-between">
          <SCol span={20}>
            <SText>{item.configurationName}</SText>
          </SCol>
          <SCol span={4} textAlign="right">
            <SText color="var(--green_primary)">{item.completedTaskItemsCount}</SText>
            <SText color="var(--black_4)">/</SText>
            <SText color="var(--black_4)">{item.totalTaskItemsCount}</SText>
          </SCol>
        </SRow>
      ))}
    </SCard>
  );

  if (currentUserAssignments.length === 0) return null;

  return (
    <SRow type="flex" justify="end">
      <SCol span={24}>
        <StyledDropdown
          overlay={assignmentsList}
          trigger={['click']}
          placement="bottomRight"
          style={{ height: 48 }}
        >
          <SText
            type="secondary"
            fontSize="14px"
            style={{ cursor: 'pointer', color: 'rgba(255, 255, 255, 0.65)' }}
            onClick={() => loadAssignments()}
          >
            Мои задачи
          </SText>
        </StyledDropdown>
      </SCol>
    </SRow>
  );
};

const StyledDropdown = styled(Dropdown)`
  margin-right: 10px;
  color: #a6adb4;
  :hover {
    color: white;
  }
  max-width: 155px;
`;

export default WorkPlanTasksMenu;
