import React from 'react';
import { Col, Tooltip, Row } from 'antd';
import { connect } from 'react-redux';
import { isNil, isEmpty, flatMap, get } from 'lodash';
import { ResponsiveLine } from '@nivo/line';
import moment from 'moment';
import { prepareData } from './prepareFunctions';
import { SettingsButtonCol, WidgetNameCol, WidgetCard, TimePeriod } from '../../styled';
import SettingsModal from '../../Components/SettingsModal';
import {
  widgetNameSetter,
  widgetPeriodSetter,
  widgetInfoTextSetter,
  getDateSelectorType,
  searchMaxValue
} from '../../utils';
import EmptyWidget from '../../Components/EmptyWidget';

const AverageScoreByQuestionsHistory = ({
  widgetData,
  widgetsAnalyticsByIds,
  loadingWidgetId,
  checklistDefinitionsByIds,
  questionGroupsByIds,
  colors
}) => {
  const widgetValue = get(widgetsAnalyticsByIds, widgetData.id, []);

  const { id, type, filters, name } = widgetData;
  const dateSelectorType = getDateSelectorType({ widgetType: type });

  const widgetHistory = widgetValue.filter(item => !isEmpty(item.history));

  const checklistDefinition = get(checklistDefinitionsByIds, filters.checklistDefinitionsIds, {});

  const prepareRawData = () => {
    const currentChecklistDefinitionQuestionGroupsIds = get(
      checklistDefinitionsByIds,
      `${filters.checklistDefinitionsIds}.questionGroupsIds`,
      []
    );

    const questionsIds = flatMap(
      currentChecklistDefinitionQuestionGroupsIds.map(id => questionGroupsByIds[id]?.questionsIds)
    );

    return widgetValue.filter(item => questionsIds.includes(item.id));
  };

  return (
    <>
      {isEmpty(widgetValue) || isEmpty(widgetHistory) || loadingWidgetId === id ? (
        <EmptyWidget key={id} widgetData={widgetData} loadingWidgetId={loadingWidgetId} />
      ) : (
        <Col span={12}>
          <WidgetCard>
            <Row type="flex">
              <WidgetNameCol span={20}>
                {widgetInfoTextSetter({ type })}
                <Tooltip title={widgetNameSetter({ name, type })}>
                  <span>{widgetNameSetter({ name, type })}</span>
                </Tooltip>
                <TimePeriod type="secondary">
                  {widgetPeriodSetter(filters, dateSelectorType)}
                </TimePeriod>
              </WidgetNameCol>
              <SettingsButtonCol span={4}>
                <SettingsModal widgetData={widgetData} />
              </SettingsButtonCol>
            </Row>
            <ResponsiveLine
              data={prepareData({ widgetValue: prepareRawData(), checklistDefinition })}
              margin={{ top: 20, right: 160, bottom: 70, left: 40 }}
              xScale={{ type: 'point' }}
              yScale={{
                type: 'linear',
                max: searchMaxValue({ checklistDefinitionsByIds, filters }),
                stacked: false,
                reverse: false
              }}
              axisTop={null}
              axisRight={null}
              axisBottom={{
                orient: 'bottom',
                tickSize: 5,
                tickPadding: 5,
                format: d => moment(d).format('DD/MM/YYYY')
              }}
              colors={colors}
              enableSlices="x"
              pointSize={10}
              pointColor={{ theme: 'background' }}
              pointBorderWidth={2}
              pointBorderColor={{ from: 'serieColor' }}
              pointLabel="y"
              pointLabelYOffset={-12}
              useMesh
              legends={[
                {
                  anchor: 'bottom-right',
                  direction: 'column',
                  justify: false,
                  translateX: 100,
                  translateY: 0,
                  itemsSpacing: 0,
                  itemDirection: 'left-to-right',
                  itemWidth: 80,
                  itemHeight: 20,
                  itemOpacity: 0.75,
                  symbolSize: 12,
                  symbolShape: 'circle',
                  symbolBorderColor: 'rgba(0, 0, 0, .5)'
                }
              ]}
            />
          </WidgetCard>
        </Col>
      )}
    </>
  );
};

const mapStateToProps = state => {
  const checklistDefinitionsByIds = state.checklistDefinitionsResource.byIds;

  return {
    widgetsAnalyticsByIds: state.dashboardAnalytics.byIds,
    checklistDefinitionsByIds,
    questionGroupsByIds: state.questionGroupsResource.byIds
  };
};

export default connect(mapStateToProps, null)(AverageScoreByQuestionsHistory);
