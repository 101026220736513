import React, { useCallback, useEffect, useState } from 'react';
import SRow from 'components/Standard/SRow';
import SCol from 'components/Standard/SCol';
import { message, Typography, Modal } from 'antd';
import SCard from 'components/Standard/SCard';
import STable from 'components/Standard/STable';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { WORK_PLAN_TASK_CONFIGURATIONS_STATUSES } from 'core/utils/constants';
import SText from 'components/Standard/SText';
import moment from 'moment';
import SButton from 'components/Standard/SButton';
import UserPreview from 'components/UserPreview';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { workPlanTaskConfigurationsResource } from 'redux/resources/workPlanTaskConfigurations';
import { isEqual, get, sum } from 'lodash';
import { actions, operations } from 'redux/lists/workPlanTaskAssignmentsListReducer';
import ProgressBar from '../Components/ProgressBar';

const WorkPlanTaskAssignments = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const { id: currentConfigurationId, task_id: currentTaskId } = useParams();

  const { Title } = Typography;

  const completeTaskById = async () => {
    try {
      const res = await dispatch(
        workPlanTaskConfigurationsResource.operations.completeWorkPlanTask({
          id: currentConfigurationId,
          taskId: currentTaskId
        })
      );
      if (res) message.success(t('messages.success.taskStopped'));
    } catch (error) {
      console.log(error);
      message.error(t('messages.error.failedStopTask'));
    }
  };

  const tasksByIds = useSelector(state => state.workPlanTasksResource.byIds, isEqual);
  const taskConfigurationsByIds = useSelector(
    state => state.workPlanTaskConfigurationsResource.byIds,
    isEqual
  );
  const usersByIds = useSelector(state => state.usersResource.byIds, isEqual);
  const unitsByIds = useSelector(state => state.unitsResource.byIds, isEqual);

  const currentTask = tasksByIds[currentTaskId] || {};
  const currentConfiguration = taskConfigurationsByIds[currentConfigurationId] || {};

  const taskAssignmentsByIds = useSelector(
    state => state.workPlanTaskAssignmentsResource.byIds,
    isEqual
  );

  const currentTaskCompletedTaskItemsCount = sum(
    get(currentTask, 'assignmentsIds', []).map(
      item => taskAssignmentsByIds[item]?.completedTaskItemsCount
    )
  );

  const { ids, totalCount, page, sort, filters, loading } = useSelector(
    state => state.workPlanTaskAssignmentsList
  );
  const taskAssignments = ids.map(id => taskAssignmentsByIds[id]);

  const { updatePage, setSorting, setFilters } = actions;

  const handleTableChange = useCallback(
    (pagination, filters, sorter) => {
      dispatch(
        updatePage({
          size: pagination.pageSize.toString(),
          number: pagination.current.toString()
        })
      );
      dispatch(setSorting(sorter));
    },
    [dispatch]
  );

  useEffect(() => {
    dispatch(
      setFilters({
        taskId: [currentTaskId]
      })
    );
  }, []);

  useEffect(() => {
    dispatch(
      operations.load({
        page,
        filters: {
          taskId: [currentTaskId]
        },
        sort,
        include: 'reviewer.unit,task.task_configuration'
      })
    );
  }, [page, sort, filters]);

  const tableColumns = [
    {
      title: t('general.employee'),
      dataIndex: 'reviewerId',
      key: 'reviewerId',
      ellipsis: true,
      render: text => <UserPreview userId={text} disabled showAvatar />
    },

    {
      title: t('general.unit'),
      dataIndex: 'unitId',
      key: 'unitId',
      ellipsis: true,
      width: 200,
      render: (text, record) => {
        const user = usersByIds[record?.reviewerId];
        const unitName = unitsByIds[user?.unitId]?.name;
        return <SText>{unitName}</SText>;
      }
    },

    {
      title: t('workPlanTasks.tableColumns.reviewsCount'),
      dataIndex: 'totalTaskItemsCount',
      key: 'totalTaskItemsCount',
      align: 'center',
      sorter: true,
      width: 150,
      render: text => <SText color>{text}</SText>
    },

    {
      title: t('workPlanTasks.tableColumns.completed'),
      dataIndex: 'completedTaskItemsCount',
      key: 'completedTaskItemsCount',
      align: 'center',
      sorter: true,
      width: 150,
      render: text => <SText color="var(--green_6)">{text}</SText>
    },

    {
      title: t('workPlanTasks.tableColumns.incompleted'),
      dataIndex: 'incompletedTaskItemsCount',
      key: 'incompletedTaskItemsCount',
      align: 'center',
      sorter: true,
      width: 150,
      render: text => <SText color="var(--red_6)">{text}</SText>
    },

    {
      title: '',
      dataIndex: 'countData',
      key: 'countData',
      align: 'right',
      width: 300,
      render: (text, record) => {
        return (
          <ProgressBar
            totalCount={record?.totalTaskItemsCount}
            doneCount={record?.completedTaskItemsCount}
          />
        );
      }
    },

    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      align: 'right',
      fixed: 'right',
      width: 120,
      render: text => (
        <SButton
          type="primary"
          onClick={() =>
            history.push(
              `/work-plan/task-configurations/${currentConfigurationId}/tasks/${currentTaskId}/task-assignment/${text}`
            )
          }
        >
          {t('general.view')}
        </SButton>
      )
    }
  ];

  return (
    <SRow margin="16px">
      <Helmet>
        <title>{t('pagesMeta.workPlanTaskConfigurationsPage.title')}</title>
      </Helmet>
      <SCol span={24}>
        <SCard bordered shadowed>
          <SRow gutter={[16, 16]}>
            <SCol span={24}>
              <SRow type="flex" align="middle" justify="space-between" marginBottom="14px">
                <SCol>
                  <Title level={4}>{currentConfiguration?.name}</Title>
                </SCol>
                <SCol>
                  <SButton
                    type="primary"
                    ghost
                    onClick={() => {
                      Modal.confirm({
                        destroyOnClose: true,
                        closable: true,
                        title: `${t('workPlanTasks.assignments.closeTask')}?`,
                        cancelText: t('general.cancel'),
                        okText: t('workPlanTasks.assignments.close'),
                        onOk: completeTaskById
                      });
                    }}
                  >
                    {t('workPlanTasks.assignments.closeTask')}
                  </SButton>
                </SCol>
              </SRow>
              <SRow type="flex" align="middle" gutter={[16, 16]}>
                <SCol>
                  <SText type="secondary">
                    {`${t('workPlanTasks.assignments.taskStartedAt')}`}
                  </SText>
                  <SText>{moment(currentTask?.timeFrom).format('DD/MM/YYYY')}</SText>
                </SCol>
                <SCol>
                  <SText type="secondary">
                    {`${t('workPlanTasks.assignments.assignmentsCount')}`}
                  </SText>
                  <SText>{get(currentTask, 'assignmentsIds', []).length}</SText>
                </SCol>
                <SCol>
                  <SText type="secondary">
                    {`${t('workPlanTasks.assignments.completedTaskItemsCount')}`}
                  </SText>
                  <SText>{currentTaskCompletedTaskItemsCount}</SText>
                </SCol>
                <SCol>
                  <SText type="secondary">{`${t('general.status')}`}</SText>
                  <SText color={WORK_PLAN_TASK_CONFIGURATIONS_STATUSES[currentTask?.status]?.color}>
                    {t(WORK_PLAN_TASK_CONFIGURATIONS_STATUSES[currentTask?.status]?.title)}
                  </SText>
                </SCol>
              </SRow>
            </SCol>
            <SCol span={24}>
              <STable
                dataSource={taskAssignments}
                columns={tableColumns}
                loading={loading}
                border
                pagination={{
                  pageSize: parseInt(page.size),
                  current: parseInt(page.number),
                  total: totalCount,
                  showSizeChanger: true,
                  pageSizeOptions: ['10', '25', '50'],
                  size: 'small'
                }}
                onChange={handleTableChange}
                fixCenterSorterColumns
                scroll={{ y: 'calc(100vh - 300px)', x: 'max-content' }}
                size="small"
              />
            </SCol>
          </SRow>
        </SCard>
      </SCol>
    </SRow>
  );
};

export default WorkPlanTaskAssignments;
