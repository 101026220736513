import { Modal } from 'antd';
import { SCALE_TYPES } from 'core/utils/constants';
import { calculateQuestions } from 'core/utils/ratingsCalculations';
import { find, get, isEmpty, max, minBy, subtract } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  getChecklistDefinitionBindings,
  getChecklistDefinitionQuestions
} from 'redux/selectors/checklistItems/checklistItems';
import { setEditingColorZonesBeforePublish } from 'redux/ui/checklistEditor/reducer';
import ColorZones from './ColorZones';

const ColorZonesBeforePublishModal = ({
  maxRate,
  minRate,
  checklistDefinition = {},
  setEditingColorZonesBeforePublish,
  editingColorZonesBeforePublish = {},
  onOk
}) => {
  const { t } = useTranslation();
  const { colorZones } = editingColorZonesBeforePublish;
  const { scaleType, id } = checklistDefinition;
  const range = subtract(maxRate, minRate);
  return (
    <Modal
      destroyOnClose
      visible={!isEmpty(editingColorZonesBeforePublish)}
      width={980}
      title={t('components.colorZonesBeforePublishModal.title')}
      okText={t('components.colorZonesBeforePublishModal.ok')}
      cancelText={t('components.colorZonesBeforePublishModal.cancel')}
      onCancel={() => setEditingColorZonesBeforePublish({})}
      onOk={() => onOk(editingColorZonesBeforePublish)}
    >
      <ColorZones
        max={maxRate}
        min={minRate}
        onChange={colorZones =>
          setEditingColorZonesBeforePublish({
            checklistDefinitionId: id,
            colorZones
          })
        }
        binary={minRate === maxRate || range === 1 || range === -1}
        scaleType={scaleType}
        colorZones={colorZones || get(SCALE_TYPES, [scaleType, 'colorZones'])}
        col={{ span: 24 }}
      />
    </Modal>
  );
};

const mapStateToProps = state => {
  const { editingColorZonesBeforePublish } = state.uiChecklistEditor;

  const checklistDefinition =
    state.checklistDefinitionsResource.byIds[editingColorZonesBeforePublish.checklistDefinitionId];

  if (!checklistDefinition) return { editingColorZonesBeforePublish };

  const currentChecklistBindings = getChecklistDefinitionBindings(state, checklistDefinition);

  const currentChecklistQuestions = getChecklistDefinitionQuestions(state, checklistDefinition);

  const allRatingValues = [...currentChecklistQuestions].map(question => ({
    ...question,
    value: max(get(question, 'ratingValues', [])),
    percentage: get(find(currentChecklistBindings, { questionId: question.id }), 'percentage', 0)
  }));

  const maxRate = calculateQuestions({
    ratingCalculation: checklistDefinition.ratingCalculation,
    ratingMethod: checklistDefinition.ratingMethod,
    questions: allRatingValues
  });

  const minRate = get(minBy(allRatingValues, 'min'), 'min', 1);

  return { editingColorZonesBeforePublish, checklistDefinition, maxRate, minRate };
};

const mapDispatchToProps = {
  setEditingColorZonesBeforePublish
};

export default connect(mapStateToProps, mapDispatchToProps)(ColorZonesBeforePublishModal);
