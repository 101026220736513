import { Col, Row, Tooltip, Typography, Select } from 'antd';
import ClientInteractionLink from 'components/ClientInteractionLink';
import CommentsPreview from 'components/Comments/CommentsPreview';
import SCard from 'components/Standard/SCard';
import STable from 'components/Standard/STable';
import { TagsDisplay } from 'components/Tags/TagsDisplay';
import { TruncatedText } from 'components/Truncated';
import UserPreview from 'components/UserPreview';
import { withRouter } from 'react-router-dom';
import { CALL_DIRECTION_LITERALS, COLOR_ZONE_LITERALS } from 'core/utils/constants';
import { selectSearch } from 'core/utils/selectSearch';
import { beatifyFloat } from 'core/utils/convertFloat';
import { get, isEmpty, pick } from 'lodash';
import moment from 'moment';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { utils } from 'react-media-player';
import { connect } from 'react-redux';
import { actions, operations } from 'redux/lists/userReviewsListReducer';
import { omitAllQuestionsFilter } from 'redux/ui/userAnalyticsPage/operations';
import { setDataString } from 'redux/ui/userAnalyticsPage/reducer';
import MainControlsPanel from '../MainControlsPanel';

const { Text } = Typography;
const { Option } = Select;

const UserReviewsTable = ({
  userId,
  totalCount,
  checklistDefinitionId,
  loading,
  sorting,
  page,
  filters,
  updatePage,
  setSorting,
  setFilter,
  currentTimeRange = {},
  tableRows,
  loadReviews,
  history,
  setQuery
}) => {
  const { search } = history.location;

  useEffect(() => {
    if (search) {
      setQuery(search);
    }
  }, [search]);

  useEffect(() => {
    if (!isEmpty(userId)) setFilter({ operatorsIds: userId });
  }, [userId]);

  useEffect(() => {
    setFilter(currentTimeRange);
  }, [
    currentTimeRange.clientInteractionTimeFrom,
    currentTimeRange.clientInteractionTimeTo,
    currentTimeRange.clientInteractionSymbolicTimeRange
  ]);

  useEffect(() => {
    setFilter({ checklistDefinitionsIds: checklistDefinitionId });
  }, [checklistDefinitionId]);

  useEffect(() => {
    if (filters.checklistDefinitionsIds && filters.operatorsIds)
      loadReviews({
        page,
        filters: omitAllQuestionsFilter(filters),
        sort: sorting,
        include:
          'client_interaction,reviewer.unit,comments.author,checklist.answers,checklist.checklist-definition,tasks.task-definition'
      });
  }, [page, sorting, filters]);

  const { t } = useTranslation();

  const columns = [
    {
      key: 'clientInteractionLink',
      dataIndex: 'id',
      width: 30,
      render: (id, { clientInteraction }) => {
        return <ClientInteractionLink id={id} isReview communication={clientInteraction} />;
      }
    },
    {
      key: 'clientInteractionStartedAt',
      title: t('userAnalyticsPage.userReviewsTable.columns.clientInteractionStartedAt'),
      dataIndex: 'clientInteraction',
      width: 150,
      // sortDirections: ['descend', 'ascend'],
      // sorter: true,

      render: call => {
        return (
          <span style={{ textOverflow: 'ellipsis' }}>
            {call ? moment(call.startedAt || call.createdAt).format('DD/MM/YYYY, HH:mm') : ''}
          </span>
        );
      }
    },
    {
      key: 'clientPhoneNumber',
      title: t('userAnalyticsPage.userReviewsTable.columns.clientPhoneNumber'),
      dataIndex: ['clientInteraction', 'clientPhoneNumber'],
      width: 150
    },
    {
      key: 'clientInteractionDirection',
      title: t('userAnalyticsPage.userReviewsTable.columns.clientInteractionDirection'),
      dataIndex: 'clientInteraction',
      // sortDirections: ['descend', 'ascend'],
      // sorter: true,
      width: 110,
      render: clientInteraction => {
        return <span>{t(CALL_DIRECTION_LITERALS[clientInteraction.direction]) || ''}</span>;
      }
    },
    {
      key: 'clientInteractionDuration',
      title: t('userAnalyticsPage.userReviewsTable.columns.clientInteractionDuration'),
      dataIndex: ['clientInteraction', 'duration'],
      // sortDirections: ['descend', 'ascend'],
      // sorter: true,
      width: 100,
      render: text => {
        return <span>{utils.formatTime(text) || '-'}</span>;
      }
    },
    {
      key: 'checklistScore',
      // sortDirections: ['descend', 'ascend'],
      // sorter: true,
      title: t('userAnalyticsPage.userReviewsTable.columns.checklistScore'),
      dataIndex: ['checklist', 'score'],
      width: 100,
      render: checklist => {
        return (
          <span>
            {checklist % 1 === 0 || checklist === undefined ? checklist : beatifyFloat(checklist)}
          </span>
        );
      }
    },
    {
      key: 'comments',
      title: t('userAnalyticsPage.userReviewsTable.columns.comments'),
      dataIndex: 'comments',
      width: 210,
      render: (comments, checklist) => (
        <CommentsPreview comments={comments} checklistData={checklist} />
      )
    },
    // checklist
    {
      key: 'checklistDefinition',
      title: t('userAnalyticsPage.userReviewsTable.columns.checklistDefinition'),
      dataIndex: ['checklistDefinition', 'name'],
      width: 200,
      render: text => (
        <Tooltip title={text}>
          <TruncatedText block>{text}</TruncatedText>
        </Tooltip>
      )
    },
    // tags
    {
      key: 'tasks',
      title: t('userAnalyticsPage.userReviewsTable.columns.tasks'),
      dataIndex: 'tasks',
      width: 200,
      render: (tasks = []) => <TagsDisplay tasks={tasks} truncate={16} />
    },
    {
      key: 'createdAt',
      title: t('userAnalyticsPage.userReviewsTable.columns.createdAt'),
      dataIndex: 'createdAt',
      width: 200,
      // sortDirections: ['descend', 'ascend'],
      // sorter: true,

      render: text => {
        return (
          <span style={{ textOverflow: 'ellipsis' }}>
            {text ? moment(text).format('DD/MM/YYYY, HH:mm') : ''}
          </span>
        );
      }
    },
    {
      key: 'reviewerLastName',
      title: t('userAnalyticsPage.userReviewsTable.columns.reviewerLastName'),
      dataIndex: 'reviewer',
      width: 250,
      render: reviewer => {
        return (
          <UserPreview
            user={reviewer}
            disabled
            showAvatar
            showUnit
            margin="0px"
            truncateSize={25}
            hidden
          />
        );
      }
    }
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    updatePage({
      size: pagination.pageSize.toString(),
      number: pagination.current.toString()
    });

    setSorting(sorter);
  };

  return (
    <Row gutter={[8, 8]} style={{ margin: '-4px' }}>
      <Helmet>
        <title>{t('pagesMeta.userAnalyticsPage.title')}</title>
      </Helmet>
      <Col span={24}>
        <Row
          type="flex"
          gutter={[8, 8]}
          style={{ margin: '-4px' }}
          justify="space-between"
          align="middle"
        >
          <Col>
            <Text type="secondary">
              {`${t('userAnalyticsPage.userReviewsTable.reviewsCount')} ${totalCount || ''}`}
            </Text>
          </Col>
          <Col>
            <Select
              loading={loading}
              disabled={loading}
              style={{ width: '245px' }}
              placeholder={t('userAnalyticsPage.userReviewsTable.colorZoneSelect.placeholder')}
              mode="multiple"
              onChange={checklistDefinitionColorZones =>
                setFilter({ checklistDefinitionColorZones })
              }
              value={filters.checklistDefinitionColorZones || []}
              optionLabelProp="label"
              allowClear
              filterOption={(input, option) =>
                selectSearch({ input, option, searchProp: 'searchValue' })
              }
            >
              <Option
                key="high"
                value="high"
                searchValue={t(COLOR_ZONE_LITERALS.high)}
                label={t(COLOR_ZONE_LITERALS.high)}
              >
                {t(COLOR_ZONE_LITERALS.high)}
              </Option>
              <Option
                key="medium"
                value="medium"
                searchValue={t(COLOR_ZONE_LITERALS.medium)}
                label={t(COLOR_ZONE_LITERALS.medium)}
              >
                {t(COLOR_ZONE_LITERALS.medium)}
              </Option>
              <Option
                key="low"
                value="low"
                searchValue={t(COLOR_ZONE_LITERALS.low)}
                label={t(COLOR_ZONE_LITERALS.low)}
              >
                {t(COLOR_ZONE_LITERALS.low)}
              </Option>
            </Select>
          </Col>
        </Row>
        <MainControlsPanel />
      </Col>
      <Col span={24}>
        <Text type="secondary">
          {`${t('userAnalyticsPage.userReviewsTable.reviewsCount')}: ${totalCount || ''}`}
        </Text>
      </Col>

      <Col span={24}>
        <SCard>
          <STable
            size="small"
            rowKey="id"
            columns={columns}
            dataSource={tableRows}
            tableLayout="fixed"
            loading={loading}
            border
            pagination={{
              pageSize: parseInt(page.size),
              current: parseInt(page.number),
              total: totalCount
            }}
            scroll={{ y: 'calc(100vh - 340px)', x: 'max-content' }}
            onChange={handleTableChange}
          />
        </SCard>
      </Col>
    </Row>
  );
};

const mapStateToProps = state => {
  const { ids, totalCount, query, page, sorting, filters, loading } = state.userReviewsList;
  const reviews = ids.map(id => state.reviewsResource.byIds[id] || {});

  const tableRows = reviews.reduce((acc, review) => {
    const clientInteraction =
      state.clientInteractionsResource.byIds[review.clientInteractionId] || {};

    const operator = pick(state.usersResource.byIds, clientInteraction.operatorId);
    const comments = Object.values(pick(state.commentsResource.byIds, review.commentsIds));
    const checklist = get(state.checklistsResource.byIds, review.checklistId, {});
    const checklistDefinition = get(
      state.checklistDefinitionsResource.byIds,
      checklist.checklistDefinitionId
    );
    const reviewer = state.usersResource.byIds[review?.reviewerId];
    const tasks = get(review, 'tasksIds', []).reduce((acc, id) => {
      const task = state.tasksResource.byIds[id];
      const taskDefinition = state.taskDefinitionsResource.byIds[task?.taskDefinitionId];

      if (!task || !taskDefinition) {
        return acc;
      }

      return [...acc, { ...task, taskDefinition }];
    }, []);

    if (isEmpty(clientInteraction)) return acc;

    return [
      ...acc,
      {
        ...review,
        clientInteraction,
        operator,
        comments,
        reviewer,
        checklist,
        checklistDefinition,
        tasks
      }
    ];
  }, []);

  return {
    tableRows,
    loading: !state.uiUserAnalyticsPage.currentUser || loading,
    reviewsIds: state.uiUserAnalyticsPage.reviewsIds,
    currentTimeRange: state.uiUserAnalyticsPage.currentTimeRange,
    checklistDefinitionId: state.uiUserAnalyticsPage.currentChecklistDefinitionId,
    userId: get(state.uiUserAnalyticsPage, 'currentUser.id', undefined),
    filters,
    sorting,
    totalCount,
    page,
    query
  };
};

const mapDispatchToProps = {
  loadReviews: operations.load,
  setFilter: actions.updateFilters,
  updatePage: actions.updatePage,
  setQuery: actions.setCurrentQuery,
  setSorting: actions.setSorting,
  setDataString
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserReviewsTable));
