export default {
  pagesMeta: {
    loginPage: { title: 'Iniciar sesión | qolio.io' },
    registerPage: { title: 'Registro | qolio.io' },
    recoverPasswordPage: { title: 'Recuperación de contraseña | qolio.io' },
    setNewPasswordPage: { title: 'Establecer una nueva contraseña | qolio.io' },
    organizationSettingsPage: { title: 'Configuración de la organización | qolio.io' },
    checklistEditorPage: { title: 'Configuración del formulario de calificación | qolio.io' },
    checklistQuestionsEditor: { title: 'Configuración de criterios | qolio.io' },
    checklistsAndQuestionsPage: { title: 'Formularios de evaluación | qolio.io' },
    callPage: { title: 'Llamar | qolio.io' },
    reviewPage: { title: 'Evaluación | qolio.io' },
    textCommunicationPage: { title: 'Comunicación de texto | qolio.io' },
    clientInteractionsPage: { title: 'Lista de comunicación | qolio.io' },
    calibrationsSessionsPages: {
      calibrationsSessions: { title: 'Sesiones de calibración | qolio.io' },
      viewCalibrationSession: { title: 'Sesión actual | qolio.io' },
      calibrationSessionResult: { title: 'Resultados de la sesión | qolio.io' }
    },
    customCommunicationReviewPage: {
      titles: {
        review: 'Evaluación | qolio.io',
        addReview: 'Nuevo cheque | qolio.io'
      }
    },
    dashboardPage: { title: 'Analítica | qolio.io' },
    notFoundPage: { title: 'Página no encontrada | qolio.io' },
    libraryPage: { title: 'Biblioteca de llamadas | qolio.io' },
    organizationStructurePage: {
      tabs: {
        organizationStructure: { title: 'Estructura de la empresa | qolio.io' },
        employees: { title: 'Empleados | qolio.io' },
        permissionsAndLevels: { title: 'Derechos de acceso y niveles de empleados | qolio.io' },
        notificationsAndStatuses: { title: 'Notificaciones y estados de contacto | qolio.io' }
      }
    },
    integrationsSettingsPage: { title: 'Integraciones | qolio.io' },
    editTaskDefinitionCardPage: {
      titles: {
        createTag: 'Crear etiqueta | qolio.io',
        updateTag: 'Editar etiqueta | qolio.io'
      }
    },
    triggersSettingsPage: { title: 'Etiquetas | qolio.io' },
    userSettingsPage: { title: 'Configuración de usuario | qolio.io' },
    teamPage: { title: 'Equipo | qolio.io' },
    uploadCallsPage: { title: 'Descarga de llamadas | qolio.io' },
    userAnalyticsPage: { title: 'Cuenta personal | qolio.io' },
    workPlanTaskConfigurationsPage: { title: 'Tareas | qolio.io' },
    appealsPage: { title: 'Apelaciones | qolio.io' },
    appealsSettingsPage: { title: 'Configuración de apelaciones | qolio.io' }
  }
};
