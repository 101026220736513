import { createAction, createReducer } from '@reduxjs/toolkit';

const defaultState = {
  loading: false,
  loadingWidgetId: '',
  byIds: {}
};

export const loadDashboardAnalyticsStarted = createAction(
  'dashboardAnalytics/loadDashboardAnalyticsStarted'
);
export const loadDashboardAnalyticsSucceed = createAction(
  'dashboardAnalytics/loadDashboardAnalyticsSucceed'
);

export const loadDashboardWidgetAnalyticsStarted = createAction(
  'dashboardAnalytics/loadDashboardWidgetAnalyticsStarted'
);
export const loadDashboardWidgetAnalyticsSucceed = createAction(
  'dashboardAnalytics/loadDashboardWidgetAnalyticsSucceed'
);

export const dashboardAnalyticsReducer = createReducer(defaultState, {
  [loadDashboardAnalyticsStarted]: () => ({
    loading: true,
    loadingWidgetId: ''
  }),
  [loadDashboardAnalyticsSucceed]: (state, { payload }) => ({
    ...state,
    byIds: payload,
    loading: false,
    loadingWidgetId: ''
  }),
  [loadDashboardWidgetAnalyticsStarted]: (state, { payload }) => ({
    ...state,
    loadingWidgetId: payload.id
  }),
  [loadDashboardWidgetAnalyticsSucceed]: (state, { payload }) => ({
    ...state,
    byIds: {
      ...state.byIds,
      ...payload
    },
    loadingWidgetId: ''
  })
});

export default dashboardAnalyticsReducer;
