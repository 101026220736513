import { getDashboardWidgetAnalytics } from 'redux/entities/analytics/dashboard/operations';
import { loadDashboardWidgetAnalyticsStarted } from 'redux/entities/analytics/dashboard/reducer';
import { dashboardWidgetsResource } from 'redux/resources/dashboardWidgets';
import { get } from 'lodash';
import extraLargeWidgets from 'pages/DashboardPage/widgetsDefinitions/extraLargeWidgets';
import { resetUiWidgetModal } from './reducer';

const getWidgetNames = ({ widgetType, filters }) => {
  const { viewType, viewMode } = filters;
  const selectedWidget = extraLargeWidgets[widgetType];

  if (widgetType === 'table_reviews_count_by_reviewers')
    return get(selectedWidget, ['widget_names', viewType, viewMode], []);

  return get(extraLargeWidgets, [widgetType, 'widget_names', filters?.viewMode], []);
};

export const updateDashboardWidget = ({ ...props }) => {
  return async dispatch => {
    await dispatch(loadDashboardWidgetAnalyticsStarted({ id: props.id }));
    const updatedWidget = await dispatch(
      dashboardWidgetsResource.operations.updateById({
        ...props,
        widgetNames: [...getWidgetNames({ widgetType: props.type, filters: props.filters })]
      })
    );
    const updatedAnalytics = await dispatch(getDashboardWidgetAnalytics({ id: props.id }));
    await dispatch(resetUiWidgetModal());
    return { updatedWidget, updatedAnalytics: updatedAnalytics[props.id]?.data };
  };
};
