import ColumnTitle from 'components/ColumnTitle';
import { COLUMN_TYPES } from 'core/utils/constants';
import { isEmpty, isEqual } from 'lodash';
import React, { useCallback, useEffect, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { actions } from 'redux/lists/clientInteractionsList';
import { getCustomFieldsByKeysForReviews } from 'redux/selectors/customFields';
import { loadCommunicationTables, loadTableData } from 'redux/ui/clientInteractions/operations';
import { expandTableRow } from 'redux/ui/clientInteractions/reducer';
import { Button } from 'antd';
import { MinusSquare, PlusSquare } from 'react-feather';
import Icon from 'components/Icon';
import ColumnsRender from './ColumnsRender';
import { ClientInteractionsStyledTable } from './styled';

const sortableColumns = ['duration', 'startedAt', 'reviewCreatedAt'];
const tableScroll = { y: 'calc(100vh - 148px)', x: 'max-content' };
const defaultTableColumns = [];
const defaultTableFilters = {};
const defaultCustomFieldFilters = {};

const shouldCellUpdate = (record, prevRecord) => !isEqual(record.reviews, prevRecord.reviews);

const ClientInteractionsTable = () => {
  const dispatch = useDispatch();

  const selectCustomFieldsByKeys = useMemo(() => getCustomFieldsByKeysForReviews, []);

  const { page, totalCount, sort, loading: listLoading } = useSelector(
    state => state.clientInteractionsList,
    shallowEqual
  );

  const {
    tableLoading,
    tableRows,
    tableFilters = defaultTableFilters,
    tableCustomFieldFilters = defaultCustomFieldFilters,
    tableColumns = defaultTableColumns,
    tableId
  } = useSelector(state => state.uiClientInteractions, shallowEqual);

  const expandedTableRowsKeys = useSelector(
    state => state.uiClientInteractions.expandedTableRowsKeys,
    isEqual
  );

  const customFieldsByKeys = useSelector(state => selectCustomFieldsByKeys(state, true), isEqual);

  const tablesLoading = useSelector(state => state.communicationTablesResource.loading);

  const loading = listLoading || tableLoading || tablesLoading;

  useEffect(() => {
    dispatch(loadCommunicationTables());
    return () => dispatch(actions.setDefaultState());
  }, []);

  useEffect(() => {
    if (!loading && tableId) {
      dispatch(
        loadTableData({
          tableId,
          page,
          filters: tableFilters,
          customFieldFilters: tableCustomFieldFilters,
          sort,
          include: 'client,operator.role,operator.unit,status,text_communication_parts.operator'
        })
      );
    }
  }, [tableId, page.number, page.size, sort]);

  const handleTableChange = useCallback(
    (pagination, filters, sorter) => {
      dispatch(
        actions.updatePage({
          size: pagination.pageSize.toString(),
          number: pagination.current.toString()
        })
      );
      dispatch(actions.setSorting(sorter));
    },
    [dispatch]
  );

  const onExpand = useCallback(
    (e, record) => {
      e.stopPropagation();
      return !isEmpty(record.children) && dispatch(expandTableRow(record.id));
    },
    [dispatch]
  );

  const onRowExpand = useCallback(record => dispatch(expandTableRow(record.id)), [dispatch]);

  const pagination = useMemo(
    () => ({
      pageSize: parseInt(page.size),
      current: parseInt(page.number),
      total: totalCount,
      showSizeChanger: true,
      pageSizeOptions: ['10', '25', '50']
    }),
    [totalCount, page.size, page.number]
  );

  const columnRender = useCallback(
    col => (text, record) => (
      <ColumnsRender
        onRowExpand={onRowExpand}
        customFieldsByKeys={customFieldsByKeys}
        col={col}
        text={text}
        record={record}
      />
    ),
    [onRowExpand, customFieldsByKeys]
  );

  const columns = useMemo(
    () =>
      tableColumns.map(col => ({
        title: <ColumnTitle columnType={col} customFieldsByKeys={customFieldsByKeys} tableView />,
        key: col,
        dataIndex: col,
        render: columnRender(col),
        shouldCellUpdate:
          col === COLUMN_TYPES.communicationType.value ? undefined : shouldCellUpdate,
        sorter: sortableColumns.includes(col),
        width: COLUMN_TYPES[col]?.width || 200,
        textWrap: 'word-break',
        ellipsis: true
      })),
    [tableColumns]
  );

  return (
    <ClientInteractionsStyledTable
      expandable={{
        // * looks like row click not working
        expandRowByClick: true,
        expandedRowKeys: expandedTableRowsKeys,
        expandIcon: ({ record, expanded }) => {
          const exp = expandedTableRowsKeys.includes(record.id);

          if (isEmpty(record?.children))
            return (
              <button className="ant-table-row-expand-icon ant-table-row-expand-icon-spaced" />
            );

          if (exp || expanded) {
            return (
              <Button
                onClick={e => onExpand(e, record)}
                size="small"
                type="link"
                style={{ float: 'left' }}
                icon={<Icon icon={MinusSquare} />}
              />
            );
          }

          return (
            <Button
              size="small"
              type="link"
              style={{ float: 'left' }}
              onClick={e => onExpand(e, record)}
              icon={<Icon icon={expanded ? MinusSquare : PlusSquare} />}
            />
          );
        }
      }}
      size="small"
      rowKey="id"
      loading={loading}
      columns={columns}
      dataSource={tableRows}
      border
      scroll={tableScroll}
      pagination={pagination}
      onChange={handleTableChange}
    />
  );
};

export default ClientInteractionsTable;
