export const COLOR_PALETTE_6 = [
  'var(--blue_6)',
  'var(--red_6)',
  'var(--orange_6)',
  'var(--green_6)',
  'var(--yellow_6)',
  'var(--violet_6)',
  'var(--gray_6)',
  'var(--magenta_6)',
  'var(--black_6)'
];

export const themeColors = {
  black: 'var(--black_primary)',
  lilac: 'var(--lilac_primary)',
  green: 'var(--green_primary)',
  red: 'var(--red_primary)',
  yellow: 'var(--yellow_primary)',
  orange: 'var(--orange_primary)',
  blue: 'var(--blue_primary)',
  transparent: 'transparent'
};

export const avatarColors = [
  'var(--orange_primary)',
  'var(--green_6)',
  'var(--blue_6)',
  'var(--yellow_primary)',
  'var(--magenta_primary)'
];

export const mapColorToId = id => {
  const number = id?.match(/\d/)[0] / 2;
  if (id) {
    return avatarColors[number] || avatarColors[2];
  }
};
