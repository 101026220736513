import { createReduxResource } from 'core/redux/resources';
import { updateResource } from 'core/redux/resources/updateResource';
import api from 'core/api';
import decamelize from 'decamelize-keys-deep';
import { identity } from 'lodash';
import { assignChildrenToParents } from 'core/utils';
import { appellationCommentsResource } from './appellationComments';

export const commentsResource = createReduxResource({
  name: 'comments',
  reactors: {
    // * reactor functions accepts first param as a result of operation and dispatch with getState to operate correctly

    onLoadSucceed: ({ resources, dispatch, getState, actions }) => {
      const commentsByIds = resources;
      const commentsWithChildren = assignChildrenToParents({ nodesByIds: commentsByIds });
      return dispatch(actions.loadSucceed(commentsWithChildren));
    },
    onCreateSucceed: ({ resource, dispatch, getState }) => {
      const state = getState();
      const review = state.reviewsResource.byIds[resource.commentableId];
      if (review) {
        updateResource({
          dispatch,
          type: 'reviews',
          resource: { ...review, commentsIds: [...review.commentsIds, resource.id] }
        });
      }
    },
    onDeleteByIdSucceed: ({ id, dispatch, requestOptions, getState }) => {
      const state = getState();
      const comment = state.commentsResource.byIds[id];

      // Delete comment from review
      const review = state.reviewsResource.byIds[comment.commentableId];
      if (review) {
        updateResource({
          dispatch,
          type: 'reviews',
          resource: {
            ...review,
            commentsIds: [...review.commentsIds.filter(relationId => relationId !== id)]
          }
        });
      }

      // Delete comment from parent comment
      const parentComment = state.commentsResource.byIds[comment.parentId];
      if (parentComment) {
        updateResource({
          dispatch,
          type: 'comments',
          resource: {
            ...parentComment,
            childrenIds: [...parentComment.childrenIds.filter(relationId => relationId !== id)]
          }
        });
      }

      // Delete comment if it's appellation comment
      const appellationComment = state.appellationCommentsResource.byIds[id];
      if (appellationComment) {
        dispatch({ type: 'appellationComments/deleteByIdSucceed', payload: { id } });
      }
    }
  },
  // * custom operations example
  customOperations: {
    loadReviewsCommentsWithoutReplies: {
      // * endpoint function that fires on operation call
      callEndpoint: api.getReviewCommentsWithoutReplies,

      // * calls if request was successfull
      onSuccess: ({ result, dispatch, getState }) => {
        dispatch({ type: 'comments/loadSucceed', payload: result });
      }
    },
    loadCommentsByReviewId: {
      callEndpoint: api.getComments,
      onSuccess: ({ result, dispatch, getState }) => {
        dispatch({ type: 'comments/loadSucceed', payload: result });
      }
    },

    createCommentReply: {
      parseOptionsFunction: identity,
      callEndpoint: requestOptions => {
        const { id, ...params } = requestOptions;
        return api.createCommentReply({ id }, decamelize(params));
      },
      onSuccess: ({ result, dispatch, getState, requestOptions }) => {
        if (!result) return;

        const state = getState();
        updateResource({ dispatch, type: 'comments', resource: { ...result, childrenIds: [] } });

        const parentComment =
          requestOptions.parentComment || state.commentsResource.byIds[result.parentId];
        const review = state.reviewsResource.byIds[result.commentableId];

        if (parentComment) {
          updateResource({
            dispatch,
            type: 'comments',
            resource: {
              ...parentComment,
              childrenIds: [...parentComment.childrenIds, result.id]
            }
          });

          if (review) {
            updateResource({
              dispatch,
              type: 'reviews',
              resource: { ...review, commentsIds: [...review.commentsIds, result.id] }
            });
          }
        }

        return result;
      }
    },
    deleteReplyComment: {
      callEndpoint: requestOptions => {
        const { id } = requestOptions;
        return api.deleteComment({ id });
      },
      onSuccess: ({ result, dispatch, getState }) => {
        dispatch({ type: 'comments/deleteByIdSucceed', payload: result });
      }
    }
  }
});
