import { createAction, createReducer } from '@reduxjs/toolkit';

const defaultState = {
  id: '',
  type: '',
  name: '',
  filters: {}
};

export const updateUiWidgetSettings = createAction(
  'uiWidgetSettingsModalReducer/updateUiWidgetSettings'
);
export const updateUiWidgetFilters = createAction(
  'uiWidgetSettingsModalReducer/updateUiWidgetFilters'
);
export const resetUiWidgetModal = createAction('uiWidgetSettingsModalReducer/resetUiWidgetModal');
export const resetUiWidgetFilters = createAction(
  'uiWidgetSettingsModalReducer/resetUiWidgetFilters'
);

export const uiWidgetSettingsModalReducer = createReducer(defaultState, {
  [updateUiWidgetSettings]: (state, { payload }) => ({
    ...state,
    ...payload
  }),
  [updateUiWidgetFilters]: (state, { payload }) => ({
    ...state,
    filters: { ...state.filters, ...payload }
  }),
  [resetUiWidgetModal]: () => ({ ...defaultState }),
  [resetUiWidgetFilters]: state => ({ ...state, filters: {} })
});
