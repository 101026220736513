import React from 'react';
import { Row } from 'antd';
import { ResponsivePie } from '@nivo/pie';
import styled from 'styled-components';
import { get, sum } from 'lodash';
import { beatifyFloat } from 'core/utils/convertFloat';
import { FLAGS_COLORS_LITERALS_RUS, WIDGET_FLAGS_COLORS_RELATIONS } from 'core/utils/constants';
import { useTranslation } from 'react-i18next';
import { tooltipSetter } from '../../../utils';

const PieChart = ({ pieData }) => {
  const { t } = useTranslation();

  const prepareData = ({ pieData }) => {
    return Object.keys(pieData).map(item => ({
      id: item,
      value: pieData[item],
      color: WIDGET_FLAGS_COLORS_RELATIONS[item]
    }));
  };

  const withoutFlag = get(pieData, 'without_flag', 0);
  const red = get(pieData, 'red', 0);
  const yellow = get(pieData, 'yellow', 0);
  const green = get(pieData, 'green', 0);

  const commentsCount = sum([withoutFlag, red, yellow, green]);

  const percantageValues = {
    without_flag: (100 / commentsCount) * withoutFlag,
    red: (100 / commentsCount) * red,
    yellow: (100 / commentsCount) * yellow,
    green: (100 / commentsCount) * green
  };

  return (
    <StyledRow>
      <ResponsivePie
        data={prepareData({ pieData })}
        margin={{ top: 30, right: 10, bottom: 30, left: 10 }}
        innerRadius={0.5}
        padAngle={0.7}
        cornerRadius={3}
        colors={shape => shape.color}
        tooltip={tooltip =>
          tooltipSetter({
            name: t(FLAGS_COLORS_LITERALS_RUS[tooltip.id]),
            value: tooltip.value
          })
        }
        borderWidth={1}
        borderColor={{ from: 'color' }}
        radialLabel={label => `${beatifyFloat(percantageValues[label.id])}%`}
        slicesLabelsSkipAngle={10}
        slicesLabelsTextColor="#000000"
        animate
        motionStiffness={90}
        motionDamping={15}
      />
    </StyledRow>
  );
};

const StyledRow = styled(Row)`
  &.ant-row {
    height: 29rem !important;
    background-color: white;
    border-radius: 4px;
    overflow: hidden;
  }
`;

export default PieChart;
