import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { EyeInvisibleOutlined } from '@ant-design/icons';
import { Tooltip, Popconfirm } from 'antd';
import UserPreview from 'components/UserPreview';
import { useTranslation } from 'react-i18next';
import { getCurrentUser } from 'redux/selectors/users';
import SRow from 'components/Standard/SRow';
import SCol from 'components/Standard/SCol';
import SText from 'components/Standard/SText';
import Icon from 'components/Icon';
import moment from 'moment';
import { isEqual, get } from 'lodash';
import SButton from 'components/Standard/SButton';
import { PERMISSIONS } from 'core/utils/constants';
import { setEditingCommentId } from 'redux/ui/clientInteractionPage/reducer';
import { selectComment } from 'redux/ui/comments/reducer';
import styled from 'styled-components';
import { Trash2, Edit3 } from 'react-feather';
import TimestampButton from './TimestampButton';

const CommentInfo = ({
  isParentHidden,
  currentComment,
  setEditing,
  isEditing,
  setEditReply,
  isEditReply,
  onDelete,
  commentType,
  commentState,
  commentTimeStamp,
  allowActions,
  isQuestionView
}) => {
  const { t } = useTranslation();
  const isHiddenComment = currentComment?.hidden || isParentHidden;
  const currentUserId = useSelector(state => getCurrentUser(state)?.id);
  const currentUser = useSelector(state => getCurrentUser(state), isEqual);
  const dispatch = useDispatch();

  const handleClick = id => {
    dispatch(setEditingCommentId(id));
    setEditing(!isEditing);
  };

  const toogleReplyEditor = () => {
    setEditReply(!isEditReply);
    dispatch(selectComment(currentComment?.id));
  };

  const renderEditButton = () => (
    <StyledButton
      size="icon"
      icon={<Icon icon={Edit3} size="16px" />}
      color="var(--black_6)"
      backgroundColor="transparent"
      onClick={() => handleClick(currentComment?.id)}
    />
  );

  const renderReplyButton = () =>
    get(currentUser, 'role.permissions', []).includes(PERMISSIONS.CAN_REPLY_COMMENTS) && (
      <SButton type="link" onClick={toogleReplyEditor} padding="0px" height="21px">
        {t('components.commentList.comment.commentEditButton.buttons.answer')}
      </SButton>
    );

  const confirmDelete = (id, commentType) => {
    onDelete({ id, commentType });
  };

  return (
    <SRow type="flex" justify="space-between" align="middle">
      <SCol>
        <SRow>
          <SCol>
            <UserPreview
              userId={currentComment?.authorId}
              disabled
              showAvatar
              hidden={currentComment?.authorId !== currentUserId}
              size="extraSmall"
            />
          </SCol>
          <SCol display="flex" alignItems="center" marginLeft="6px">
            <SRow type="flex" align="middle">
              <SCol marginRight={isQuestionView && !commentTimeStamp ? '70px' : ''}>
                {isHiddenComment && (
                  <Tooltip
                    title={t('components.commentList.comment.commentInfo.hiddenCommentTooltip')}
                  >
                    <Icon color="var(--black_6)" icon={EyeInvisibleOutlined} />
                  </Tooltip>
                )}
                <SText color="var(--black_6)" marginLeft="6px">
                  {moment(currentComment?.updatedAt).format('DD/MM/YYYY, HH:mm')}
                </SText>
              </SCol>

              {commentTimeStamp && (
                <SCol marginRight={isQuestionView ? '47px' : ''}>
                  <TimestampButton
                    timestamp={commentState?.metadata?.timestamp}
                    commentType={commentType}
                  />
                </SCol>
              )}
            </SRow>
          </SCol>
        </SRow>
      </SCol>

      {allowActions && (
        <SCol style={isQuestionView ? { position: 'absolute', right: '1px', top: '1px' } : {}}>
          {currentUserId === currentComment?.authorId ? renderEditButton() : renderReplyButton()}

          {currentUserId === currentComment?.authorId && (
            <Popconfirm
              title={t('components.commentList.comment.commentEditButton.confirmDelete.title')}
              okText={t('components.commentList.comment.commentEditButton.confirmDelete.ok')}
              cancelText={t(
                'components.commentList.comment.commentEditButton.confirmDelete.cancel'
              )}
              onConfirm={() => confirmDelete(currentComment?.id, currentComment?.commentType)}
            >
              <StyledButton
                size="icon"
                icon={<Icon icon={Trash2} size="16px" />}
                color="var(--black_6)"
              />
            </Popconfirm>
          )}
        </SCol>
      )}
    </SRow>
  );
};

const StyledButton = styled(SButton)`
  &.ant-btn {
    border: none;
    box-shadow: none;
    background-color: transparent;
    width: 24px !important;
  }

  &.ant-btn:hover {
    background-color: transparent;
  }
`;

export default CommentInfo;
