import { ChevronDown, ChevronRight } from 'react-feather';
import Icon from 'components/Icon';
import { Col, Input, Row, Select } from 'antd';
import camelcase from 'camelcase';
import FilterName from 'pages/ClientInteractionsPage/components/FilterName';
import TimeSpecificSelector from 'components/Inputs/TimeSpecificSelector';
import RangeInputs from 'components/Inputs/RangeInputs';
import { CUSTOM_FIELDS_TYPES } from 'core/utils/constants';
import { selectSearch } from 'core/utils/selectSearch';
import { find, flatMap, intersectionWith, isEmpty, some, sortBy } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { actions } from 'redux/lists/clientInteractionsList';
import { getCustomFieldsByIds } from 'redux/selectors/customFields';
import { toogleFilterGroup } from 'redux/ui/clientInteractions/reducer';
import { CategoryName, CategoryNameBlock, FilterContainer, StyledSelect } from './styled';

const { Option, OptGroup } = Select;

const CustomFieldsFilters = ({
  disabled,
  updateCustomFieldFilters,
  updateFilters,
  filters,
  customFieldFilters = {},
  salePipelinesByIds = {},
  salePipelines = [],
  customFields = [],
  statusesByIds = {},
  customFieldsByIds = {},
  toogleFilterGroup,
  collapsedFilterGroups = []
}) => {
  const { t } = useTranslation();
  if (isEmpty(customFields) && isEmpty(salePipelinesByIds)) return null;
  const isCollapsedFilterGroup = collapsedFilterGroups.includes('customFieldsFilters');

  const renderCustomFieldFilter = ({
    id,
    fieldType,
    key,
    name,
    options,
    dependsOnId,
    settings
  }) => {
    const getComponent = () => {
      if (
        fieldType === CUSTOM_FIELDS_TYPES.STRING ||
        fieldType === CUSTOM_FIELDS_TYPES.STRING_ARRAY
      ) {
        return (
          <Input
            disabled={disabled}
            value={customFieldFilters[camelcase(key)]?.contains || undefined}
            onChange={e =>
              updateCustomFieldFilters({ [camelcase(key)]: { contains: e.target.value } })
            }
            placeholder={name}
          />
        );
      }

      if (
        fieldType === CUSTOM_FIELDS_TYPES.NUMBER ||
        fieldType === CUSTOM_FIELDS_TYPES.NUMBER_ARRAY
      ) {
        return (
          <>
            <FilterName>{name}</FilterName>
            <RangeInputs
              value={customFieldFilters[camelcase(key)]}
              precision={0}
              onChange={({ from, to }) =>
                updateCustomFieldFilters({ [camelcase(key)]: { from, to } })
              }
            />
          </>
        );
      }

      if (fieldType === CUSTOM_FIELDS_TYPES.DATETIME) {
        return (
          <>
            <FilterName>{name}</FilterName>
            <TimeSpecificSelector
              disabled={disabled}
              placeholder={name}
              style={{ width: '100%' }}
              datePickerDirection="right"
              onChange={value => updateCustomFieldFilters({ [camelcase(key)]: value })}
              value={customFieldFilters[camelcase(key)] || {}}
            />
          </>
        );
      }

      if (!isEmpty(settings)) {
        // id, fieldType, key, name, options, dependsOnId, settings
        const parentKey = customFieldsByIds[dependsOnId]?.key;
        const parentSelectedValue = customFieldFilters[parentKey]?.eq;

        // * allow options on parent key or allow all
        const options = parentSelectedValue
          ? settings.filter(({ parentKey }) => parentKey === parentSelectedValue)
          : settings;

        const setControlledValueToUndefined = () => {
          if (customFieldFilters[key]?.eq) {
            updateCustomFieldFilters({ [key]: { eq: undefined } });
          }

          const childKey = find(customFieldsByIds, field => field.dependsOnId === id)?.key;
          if (childKey && customFieldFilters[childKey]?.eq) {
            updateCustomFieldFilters({ [childKey]: { eq: undefined } });
          }

          return undefined;
        };

        // * if value should be controlled - check if that option exists. if not - set to undefined
        const controlledValue =
          some(options, ({ key: allowedKey }) => allowedKey === customFieldFilters[key]?.eq) ||
          !parentKey
            ? customFieldFilters[key]?.eq
            : undefined;

        if (controlledValue !== customFieldFilters[key]?.eq) {
          setControlledValueToUndefined();
        }

        return (
          <StyledSelect
            disabled={disabled}
            showSearch
            allowClear
            optionLabelProp="label"
            placeholder={name}
            value={parentSelectedValue ? controlledValue : customFieldFilters[key]?.eq || undefined}
            onChange={value => updateCustomFieldFilters({ [key]: { eq: value } })}
            filterOption={(input, option) =>
              selectSearch({ input, option, searchProp: 'children' })
            }
          >
            {sortBy(options, ['text']).map(({ key, text }) => (
              <Option key={key} value={key} label={text}>
                {text}
              </Option>
            ))}
          </StyledSelect>
        );
      }

      if (!isEmpty(options)) {
        return (
          <StyledSelect
            disabled={disabled}
            showSearch
            allowClear
            optionLabelProp="label"
            placeholder={name}
            value={customFieldFilters[camelcase(key)]?.eq || undefined}
            onChange={value => updateCustomFieldFilters({ [camelcase(key)]: { eq: value } })}
            filterOption={(input, option) =>
              selectSearch({ input, option, searchProp: 'children' })
            }
          >
            {options.map(option => (
              <Option key={option} value={option} label={option}>
                {option}
              </Option>
            ))}
          </StyledSelect>
        );
      }
    };

    return <FilterContainer>{getComponent()}</FilterContainer>;
  };

  const filterSalePipelines = pipelinesIds => {
    const allowedPipelinesStatusesIds = flatMap(
      salePipelines.filter(({ id }) => pipelinesIds.includes(id)),
      'statusesIds'
    );

    updateFilters({
      pipelinesIds,
      pipelineStatusesIds:
        isEmpty(filters.pipelineStatusesIds) || isEmpty(pipelinesIds)
          ? filters.pipelineStatusesIds
          : filters.pipelineStatusesIds.filter(statusId => {
              return allowedPipelinesStatusesIds.includes(statusId);
            })
    });
  };

  const selectedSalePipelines = intersectionWith(
    salePipelines,
    isEmpty(filters.pipelinesIds) ? Object.keys(salePipelinesByIds) : filters.pipelinesIds,
    ({ id }, filterId) => id === filterId
  );

  return (
    <Row style={{ marginTop: '16px' }}>
      <Col span={24}>
        <CategoryNameBlock
          type="flex"
          align="middle"
          justify="space-between"
          onClick={() => toogleFilterGroup('customFieldsFilters')}
        >
          <Col span={22}>
            <CategoryName>
              {t('clientInteractionsPage.tableFilters.customFieldFilters.title')}
            </CategoryName>
          </Col>
          <Col span={2}>
            {isCollapsedFilterGroup ? <Icon icon={ChevronDown} /> : <Icon icon={ChevronRight} />}
          </Col>
        </CategoryNameBlock>
      </Col>

      {isCollapsedFilterGroup && (
        <Col span={24}>
          {!isEmpty(salePipelines) && (
            <FilterContainer>
              <StyledSelect
                mode="multiple"
                disabled={disabled}
                showSearch
                allowClear
                optionLabelProp="label"
                maxTagCount={0}
                maxTagPlaceholder={selectedKeys =>
                  `${t(
                    'clientInteractionsPage.tableFilters.salesPipelines.salesPipelinesPlaceholder'
                  )}: ${selectedKeys.length}`
                }
                placeholder={t(
                  'clientInteractionsPage.tableFilters.salesPipelines.salesPipelinesPlaceholder'
                )}
                value={filters.pipelinesIds || undefined}
                onChange={filterSalePipelines}
                filterOption={(input, option) =>
                  selectSearch({ input, option, searchProp: 'children' })
                }
              >
                {salePipelines.map(({ name, id }) => (
                  <Option value={id} key={id} label={name}>
                    {name}
                  </Option>
                ))}
              </StyledSelect>
            </FilterContainer>
          )}

          {!isEmpty(statusesByIds) && (
            <FilterContainer>
              <StyledSelect
                disabled={disabled}
                mode="multiple"
                style={{ width: '100%' }}
                optionLabelProp="label"
                allowClear
                placeholder={t(
                  'clientInteractionsPage.tableFilters.salesPipelines.salePipelineStatusesPlaceholder'
                )}
                maxTagCount={0}
                maxTagPlaceholder={selectedKeys =>
                  `${t(
                    'clientInteractionsPage.tableFilters.salesPipelines.salePipelineStatusesPlaceholder'
                  )}: ${selectedKeys.length}`
                }
                onChange={pipelineStatusesIds => updateFilters({ pipelineStatusesIds })}
                value={filters.pipelineStatusesIds || []}
                filterOption={(input, option) =>
                  selectSearch({ input, option, searchProp: 'children' })
                }
              >
                {selectedSalePipelines.map(({ id, name, statusesIds = [] }) => (
                  <OptGroup key={id} label={name}>
                    {statusesIds.reduce((acc, id) => {
                      const status = statusesByIds[id];
                      return status
                        ? [
                            ...acc,
                            <Option value={status.id} key={status.id} label={status.name}>
                              {status.name}
                            </Option>
                          ]
                        : acc;
                    }, [])}
                  </OptGroup>
                ))}
              </StyledSelect>
            </FilterContainer>
          )}
          {customFields.map(renderCustomFieldFilter)}
        </Col>
      )}
    </Row>
  );
};

const mapStateToProps = state => {
  const customFieldsByIds = getCustomFieldsByIds(state, true);
  const { byIds: salePipelinesByIds } = state.salePipelinesResource;
  const { byIds: statusesByIds } = state.salesPipelineStatusesResource;
  const { customFieldFilters, loading, filters } = state.clientInteractionsList;
  const { tableLoading, tableId, collapsedFilterGroups } = state.uiClientInteractions;

  return {
    disabled: tableLoading || loading || isEmpty(tableId),
    salePipelinesByIds,
    salePipelines: Object.values(salePipelinesByIds),
    customFields: Object.values(customFieldsByIds)
      .filter(customField => customField?.usedForFilters)
      .sort((a, b) => {
        if (a.id === b?.dependsOnId) {
          return -1;
        }

        if (b.id === a?.dependsOnId) {
          return 1;
        }

        return 0;
      }),
    customFieldFilters,
    filters,
    customFieldsByIds,
    statusesByIds,
    collapsedFilterGroups
  };
};

const mapDispatchToProps = {
  updateCustomFieldFilters: actions.updateCustomFieldFilters,
  updateFilters: actions.updateFilters,
  toogleFilterGroup
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomFieldsFilters);
