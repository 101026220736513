import smallWidgets from './smallWidgets';
import mediumWidgets from './mediumWidgets';
import largeWidgets from './largeWidgets';
import extraLargeWidgets from './extraLargeWidgets';

export default {
  ...smallWidgets,
  ...mediumWidgets,
  ...largeWidgets,
  ...extraLargeWidgets
};
