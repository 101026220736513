import { Check } from 'react-feather';
import { Button, Col, message, Row, Select, Skeleton, Typography } from 'antd';
import BraftEditor from 'braft-editor';
import PhoneCallPlayer from 'components/PhoneCallPlayer';
import SCard from 'components/Standard/SCard';
import { CLIENT_INTERACTIONS_TYPES } from 'core/utils/constants';
import DOMPurify from 'dompurify';
import { debounce, get, isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Prompt, withRouter } from 'react-router-dom';
import { calibrationSessionsResource } from 'redux/resources/calibrationSessions';
import { phoneCallsResource } from 'redux/resources/calls';
import { getCalibrationSessionsByIds } from 'redux/selectors/calibrationSessions';
import { setComment, setLoading, setViewedItem } from 'redux/ui/calibrationResult/reducer';
import styled from 'styled-components';
import Icon from 'components/Icon';
import CalibrationResultTable from './components/CalibrationResultTable';

const { Option } = Select;

const { Text } = Typography;

const StyledEditor = styled(BraftEditor)`
  &&.bf-container {
    &.disabled {
      pointer-events: all;
    }
    .bf-content {
      height: calc(100% - 64px);
    }
  }
`;

const CalibrationResult = ({
  id,
  loading,
  session,
  loadSession,
  updateSession,
  clientInteraction,
  items = [],
  setViewedItem,
  setComment,
  comment,
  viewedItem,
  updateByIdStarted
}) => {
  const { t } = useTranslation();
  const initialLoad = async () => {
    setLoading(true);
    const result = await loadSession({
      id,
      include:
        'author,checklist-definition.question-groups.question-to-group-bindings.question,folder,folder_items.client_interaction.operator,folder_items.phone_call,folder_items.text_communication,folder_items.custom_communication,members,reviews.comments,reviews.checklist.answers,reviews.client_interaction.operator'
    });

    if (!result) {
      return message.error(t('calibrationResultPage.messages.loadSessionsFailed'));
    }

    setViewedItem(result.folderItemsIds[0]);
    setComment(BraftEditor.createEditorState(result.comment));
    setLoading(false);
  };

  const commentText = comment.toHTML ? comment.toHTML() : comment;

  const saveCommentOnLeave = () => {
    updateSession({ id, comment: commentText });
    return true;
  };

  useEffect(() => {
    initialLoad();
  }, []);

  if (isEmpty(session) || loading) {
    return (
      <SCard>
        <Skeleton active />
      </SCard>
    );
  }

  const onSubmit = async () => {
    const result = await updateSession({ id: session.id, status: 'done', comment: commentText });

    if (result) {
      message.success(t('calibrationResultPage.messages.sessionSuccessfullyCompleted'));
    }
  };

  const onChangeComment = debounce(setComment, 150);

  const showPlayer =
    clientInteraction &&
    clientInteraction.clientInteractionType === CLIENT_INTERACTIONS_TYPES.PHONE_CALL;

  return (
    <Row type="flex" gutter={[8, 8]} style={{ margin: '-4px' }}>
      <Prompt
        when={session.status !== 'done' && session.comment !== commentText}
        message={saveCommentOnLeave}
      />
      <Helmet>
        <title>{t('pagesMeta.calibrationsSessionsPages.calibrationSessionResult.title')}</title>
      </Helmet>
      <Col span={24}>
        <Row gutter={[8, 8]} style={{ margin: '-4px' }} type="flex">
          <Col span={15}>
            {showPlayer && (
              <PhoneCallPlayer allowAttachTags={false} showTags={false} call={clientInteraction} />
            )}
          </Col>
          <Col span={9}>
            <SCard style={{ height: '100%' }}>
              <Row type="flex" gutter={[8, 8]} style={{ margin: '-4px' }}>
                <Col span={24}>
                  <Button
                    type="primary"
                    icon={<Icon icon={Check} />}
                    onClick={onSubmit}
                    disabled={session.status === 'done' || updateByIdStarted}
                    loading={updateByIdStarted}
                    block
                  >
                    {t('calibrationResultPage.buttons.completeSession')}
                  </Button>
                </Col>
                <Col span={24}>
                  <Select
                    value={viewedItem || t('calibrationResultPage.messages.emptyReviews')}
                    style={{ width: '100%' }}
                    onChange={setViewedItem}
                    disabled={isEmpty(items)}
                  >
                    {items.map(({ name, id }) => (
                      <Option value={id}>{name}</Option>
                    ))}
                  </Select>
                </Col>
              </Row>
            </SCard>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <CalibrationResultTable session={session} />
      </Col>
      <Col span={24}>
        <SCard>
          <Row type="flex" gutter={[8, 8]} style={{ margin: '-4px' }}>
            <Col span={24}>
              <Text strong>{`Итоги: ${session.name}`}</Text>
            </Col>

            <Col span={24} style={session.status !== 'done' && { height: '35vh' }}>
              {session.status === 'done' ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(commentText, {
                      ALLOWED_ATTR: ['target', 'href']
                    })
                  }}
                />
              ) : (
                <StyledEditor
                  disabled={session.status === 'done' || updateByIdStarted}
                  editorState={comment}
                  placeholder={t('calibrationResultPage.commentPlaceholder')}
                  language="ru"
                  controls={['bold', 'underline', 'italic', 'link', 'list-ol', 'list-ul', 'emoji']}
                  onChange={onChangeComment}
                  draftProps={{ spellCheck: true }}
                />
              )}
            </Col>
          </Row>
        </SCard>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state, props) => {
  const { id } = props.match.params;

  const { loadByIdStarted, updateByIdStarted } = state.calibrationSessionsResource;
  const { viewedItem, comment, loading: uiLoading } = state.uiCalibrationResultPage;

  const sessionsByIds = getCalibrationSessionsByIds(state);

  const session = get(sessionsByIds, id, {});

  const loading = loadByIdStarted || uiLoading;

  if (isEmpty(session) || loading) {
    return { id, session, comment, loading: true, viewedItem };
  }

  const folderItem = get(state.folderItemsResource.byIds, viewedItem, {});
  const items = session.folderItemsIds.reduce((acc, id) => {
    const item = state.folderItemsResource.byIds[id];
    if (!item) return acc;
    return [...acc, item];
  }, []);

  const clientInteraction = {
    ...get(state.phoneCallsResource.byIds, folderItem.clientInteractionId, {}),
    ...get(state.textCommunicationsResource.byIds, folderItem.clientInteractionId, {}),
    ...get(state.clientInteractionsResource.byIds, folderItem.clientInteractionId, {})
  };

  return {
    id,
    session,
    sessionsByIds,
    loading,
    comment,
    items,
    viewedItem,
    clientInteraction,
    updateByIdStarted
  };
};

const mapDispatchToProps = {
  loadSession: calibrationSessionsResource.operations.loadById,
  updateSession: calibrationSessionsResource.operations.updateById,
  loadClientInteraction: phoneCallsResource.operations.loadById,
  setViewedItem,
  setComment
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CalibrationResult));
