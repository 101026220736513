import api from 'core/api';
import { createReduxResource } from 'core/redux/resources';
import { workPlanTasksResource } from './workPlanTasks';

export const workPlanTaskConfigurationsResource = createReduxResource({
  name: 'workPlanTaskConfigurations',

  customOperations: {
    getConfigurationTasksById: {
      callEndpoint: async requestOptions => {
        return api.getWorkPlanTaskConfigurationTasks({ ...requestOptions });
      },
      onSuccess: ({ response, dispatch, result }) => {
        dispatch(workPlanTasksResource.actions.loadSucceed(result));

        return {
          resources: result,
          meta: response.body.meta
        };
      }
    },

    stopRepeatingWorkPlanTaskConfiguration: {
      callEndpoint: async requestOptions => {
        const { id } = requestOptions;
        return api.stopRepeatingWorkPlanTaskConfigurations({ id }, {});
      },
      onSuccess: ({ result }) => {
        return result;
      }
    },

    cancelWorkPlanTaskConfiguration: {
      callEndpoint: async requestOptions => {
        const { id } = requestOptions;
        return api.cancelWorkPlanTaskConfigurations({ id }, {});
      },
      onSuccess: ({ result }) => {
        return result;
      }
    },

    completeWorkPlanTask: {
      callEndpoint: async requestOptions => {
        return api.completeWorkPlanTask({ ...requestOptions }, {});
      },
      onSuccess: ({ result }) => {
        return result;
      }
    },

    runWorkPlanTaskConfiguration: {
      callEndpoint: async ({ id }) => api.runWorkPlanTaskConfiguration({ id }, {}),
      onSuccess: ({ result }) => {
        return result;
      }
    }
  }
});
