import SCard from 'components/Standard/SCard';
import SCol from 'components/Standard/SCol';
import SRow from 'components/Standard/SRow';
import SText from 'components/Standard/SText';
import React from 'react';
import moment from 'moment';
import { get } from 'lodash';
import { COMMENTS_FLAGS_COLORS_RELATIONS, FLAGS_TO_COLORS_RELATIONS } from 'core/utils/constants';
import UserPreview from 'components/UserPreview';
import UploadedFile from 'components/Comments/Editor/UploadedFile';
import { useSelector } from 'react-redux';
import DOMPurify from 'dompurify';

const ObjectionComment = ({ comment }) => {
  // TODO: показывать
  const uploadedFilesByIds = useSelector(state => state.uploadedFilesResource.byIds);

  const uploadedFiles = get(comment, 'uploadedFilesIds', []).map(
    fileId => uploadedFilesByIds[fileId]
  );

  return (
    <SRow type="flex" align="middle" justify="space-between" marginTop="16px">
      <SCol>
        <UserPreview userId={comment?.authorId} showAvatar size="extraSmall" disabled />
      </SCol>
      <SCol marginTop="8px">
        <SText type="secondary" fontSize="12px">
          {moment(comment?.createdAt).format('DD/MM/YYYY, HH:mm')}
        </SText>
      </SCol>
      <SCol span={24}>
        <SCard
          bodyPadding="8px"
          style={
            comment?.commentType === 'appellant_comment'
              ? { borderLeft: '4px solid var(--blue_primary)' }
              : {
                  borderLeft: `4px solid ${FLAGS_TO_COLORS_RELATIONS[
                    comment?.metadata?.ratingFlag
                  ] || FLAGS_TO_COLORS_RELATIONS.without_flag}`
                }
          }
          background={
            comment?.commentType === 'appellant_comment'
              ? 'var(--gray_primary)'
              : COMMENTS_FLAGS_COLORS_RELATIONS[comment?.metadata?.ratingFlag] ||
                COMMENTS_FLAGS_COLORS_RELATIONS.without_flag
          }
          rounded="4px"
        >
          <span
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(comment?.text, {
                ALLOWED_ATTR: ['target', 'href']
              })
            }}
          />
          {uploadedFiles.map(file => (
            <UploadedFile uploadedFile={file} allFiles={uploadedFiles} />
          ))}
        </SCard>
      </SCol>
    </SRow>
  );
};

export default ObjectionComment;
