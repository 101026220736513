import { intercomAppId } from 'core/config';
import { DEFAULT_PAGE_TITLE, PERMISSIONS } from 'core/utils/constants';
import { get, isEqual } from 'lodash';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const checkPermission = ({ permission, user }) => {
  const currentUserPermissions = get(user, 'role.permissions', []);
  return currentUserPermissions.includes(permission);
};

const permissions = user => ({
  Сотрудник_коммуницирует_с_клиентами: checkPermission({
    permission: PERMISSIONS.CAN_PERFORM_CLIENT_INTERACTION,
    user
  }),
  Сотрудник_имеет_доступ_в_qolio: checkPermission({ permission: PERMISSIONS.CAN_SIGN_IN }),
  Добавлять_и_видеть_скрытые_комментарии: checkPermission({
    permission: PERMISSIONS.CAN_SEE_AND_MANAGE_HIDDEN_COMMENTS,
    user
  }),
  Доступ_к_ЛК: checkPermission({
    permission: PERMISSIONS.CAN_ACCESS_USER_ANALYTICS_PAGE_CHARTS_TAB,
    user
  }),
  Доступ_к_персональному_списку_оценок_в_личном_кабинете: checkPermission({
    permission: PERMISSIONS.CAN_ACCESS_USER_ANALYTICS_PAGE_REVIEWS_TAB,
    user
  }),
  Ответ_на_комментарии_к_своим_коммуникациям: checkPermission({
    permission: PERMISSIONS.CAN_REPLY_COMMENTS,
    user
  }),
  Доступ_к_библиотеке_звонков: checkPermission({
    permission: PERMISSIONS.CAN_ACCESS_LIBRARY,
    user
  }),
  Просмотр_всех_коммуникаций_в_отделе: checkPermission({
    permission: PERMISSIONS.CAN_SEE_UNIT_CLIENT_INTERACTIONS,
    user
  }),
  Право_оценивать_и_комментировать_коммуникации_в_своем_отделе: checkPermission({
    permission: PERMISSIONS.CAN_MAKE_REVIEW,
    user
  }),
  Просмотр_оценок_и_комментариев_других_пользователей: checkPermission({
    permission: PERMISSIONS.CAN_SEE_UNIT_REVIEWS,
    user
  }),
  Управление_своими_задачами: checkPermission({
    permission: PERMISSIONS.CAN_SEE_AND_UPDATE_ASSIGNED_TASKS,
    user
  }),
  Добавление_и_управление_созданными_задачами: checkPermission({
    permission: PERMISSIONS.CAN_MANAGE_TASKS,
    user
  }),
  Добавление_и_управление_задачами_отдела: checkPermission({
    permission: PERMISSIONS.CAN_MANAGE_UNIT_TASKS,
    user
  }),
  Управление_библиотекой_звонков: checkPermission({ permission: PERMISSIONS.CAN_MANAGE_LIBRARY }),
  Управление_формами_оценки: checkPermission({
    permission: PERMISSIONS.CAN_MANAGE_CHECKLIST_DEFINITIONS,
    user
  }),
  Управление_тегами: checkPermission({ permission: PERMISSIONS.CAN_MANAGE_TASK_DEFINITIONS, user }),
  Управление_отделами_и_пользователями: checkPermission({
    permission: PERMISSIONS.CAN_MANAGE_ORGANIZATION_STRUCTURE,
    user
  }),
  Управление_общими_настройками_организации: checkPermission({
    permission: PERMISSIONS.CAN_MANAGE_ORGANIZATION_SETTINGS,
    user
  }),
  Управление_интеграциями: checkPermission({
    permission: PERMISSIONS.CAN_MANAGE_INTEGRATIONS,
    user
  }),
  Доступ_к_аналитике: checkPermission({ permission: PERMISSIONS.CAN_SEE_DASHBOARDS, user }),
  Доступ_к_ЛК_сотрудников_которые_коммуницируют_с_клиентами: checkPermission({
    permission: PERMISSIONS.CAN_SEE_UNIT_OPERATORS_ANALYTICS_PAGE,
    user
  }),
  Доступ_к_странице_Команда: checkPermission({
    permission: PERMISSIONS.CAN_ACCESS_TEAM_PAGE,
    user
  }),

  Доступ_к_калибровочным_сессиям: checkPermission({
    permission: PERMISSIONS.CAN_MANAGE_CALIBRATION_SESSIONS,
    user
  })
});

const Intercom = () => {
  const { t } = useTranslation();
  const isLoadingReduxTokenAuth = useSelector(state => state.reduxTokenAuth.currentUser.isLoading);

  const currentUserId = useSelector(state => state.reduxTokenAuth.currentUser.attributes.id);

  const user = useSelector(state => {
    const { user: currentUser } = state.reduxTokenAuth.currentUser.attributes;
    const user = {
      user_id: currentUserId,
      email: get(currentUser, 'email'),
      role: get(currentUser, 'role.name', ''),
      name: `${get(currentUser, 'first-name', '')} ${get(currentUser, 'last-name', '')}`,
      ...permissions(currentUser),
      company: {
        company_id: get(currentUser, 'organization.name'),
        created_at: get(currentUser, 'organization.created-at', ''),
        name: get(currentUser, 'organization.name', ''),
        plan: get(currentUser, 'organization.plan-slug', ''),
        Количество_оценок_в_организации_за_текущий_месяц: get(
          currentUser,
          'organization.monthly-reviews-count'
        )
      }
    };
    return user;
  }, isEqual);

  useEffect(() => {
    if (!isLoadingReduxTokenAuth) {
      window.Intercom('boot', {
        hide_default_launcher: !!currentUserId,
        app_id: intercomAppId,
        ...user
      });
    }
  }, [currentUserId]);

  useEffect(() => {
    const target = document.querySelector('title');

    // create an observer instance
    const observer = new MutationObserver(mutations => {
      // We need only first event and only new value of the title
      const title = mutations[0].target.innerHTML;

      if (title !== t(DEFAULT_PAGE_TITLE)) {
        window.Intercom('update', { email: user?.email, user_id: currentUserId });
      }
    });

    // configuration of the observer:
    const config = { subtree: true, characterData: true, childList: true };

    // pass in the target node, as well as the observer options
    observer.observe(target, config);
  }, []);

  return null;
};

export default Intercom;
