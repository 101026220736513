import { Col, Row } from 'antd';
import ChecklistsList from 'components/Checklist/ChecklistsList';
import QuestionsPageList from 'components/Questions/QuestionsPageList';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ChecklistsAndQuestionsFilters from './ChecklistsAndQuestionsFilters';

const ChecklistsAndQuestionsPage = ({ currentList }) => {
  const { t } = useTranslation();

  const listToComponents = {
    checklists: ChecklistsList,
    questions: QuestionsPageList
  };

  const Component = listToComponents[currentList];

  return (
    <Row gutter={[0, 8]} style={{ marginBottom: '-4px' }}>
      <Helmet>
        <title>{t('pagesMeta.checklistsAndQuestionsPage.title')}</title>
      </Helmet>
      <Col span={24}>
        <ChecklistsAndQuestionsFilters />
      </Col>
      <Col span={24}>
        <Component />
      </Col>
    </Row>
  );
};

const mapStateToProps = state => ({
  currentList: state.uiChecklistsAndQuestionsPage.currentList
});

const mapDispatchToProps = {};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChecklistsAndQuestionsPage));
