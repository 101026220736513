import SCol from 'components/Standard/SCol';
import React, { useEffect } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useTranslation } from 'react-i18next';
import { Empty } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { isEqual, throttle, uniq, keyBy, isEmpty, orderBy, get } from 'lodash';
import { actions, operations } from 'redux/lists/commentTemplatesListReducer';
import SRow from 'components/Standard/SRow';
import moment from 'moment';
import SText from 'components/Standard/SText';
import TemplateComment from './TemplateComment';

const TemplatesList = ({
  commentState,
  setCommentState,
  setEditorState,
  setTemplateState,
  setVisibleSelector
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { updatePage } = actions;

  const { ids, totalCount, page, sort, filters, loading, totalPages } = useSelector(
    state => state.commentTemplatesList,
    isEqual
  );

  const uploadedFilesByIds = useSelector(state => state.uploadedFilesResource.byIds, isEqual);

  useEffect(() => {
    dispatch(
      operations.load({
        page,
        filters,
        sort: 'created_at',
        include: 'uploaded_files'
      })
    );
  }, [page, sort, filters]);

  const hasNextPage = totalPages > page.number;

  const onScrollY = throttle(async el => {
    const isOnBottom = (element => {
      return element.clientHeight + element.scrollTop + 20 >= element.scrollHeight;
    })(el);

    if (hasNextPage && !loading && isOnBottom) {
      const newPage = `${parseInt(page.number) + 1}`;
      dispatch(
        updatePage({
          number: newPage
        })
      );
    }
  }, 250);

  // !: shit
  const updatedCommentTemplates = Object.values(
    useSelector(state => state.commentTemplatesResource.byIds, isEqual)
  ).map(item => {
    return {
      ...item,
      ratingFlag: isEmpty(item.ratingFlag) ? 'without_flag' : item.ratingFlag
    };
  });

  const commentTemplatesByIds = keyBy(updatedCommentTemplates, 'id');

  const commentsTemplates = orderBy(
    uniq(ids).reduce((acc, templateId) => {
      const { commentsRatingFlags } = filters;

      if (
        commentsRatingFlags &&
        commentTemplatesByIds[templateId]?.ratingFlag !== commentsRatingFlags
      )
        return acc;

      const data = {
        ...commentTemplatesByIds[templateId],
        uploadedFiles: get(commentTemplatesByIds, `${templateId}.uploadedFilesIds`, []).map(
          itemId => uploadedFilesByIds[itemId]
        )
      };

      return [...acc, data];
    }, []),
    ['createdAt', template => moment(get(template, 'createdAt', '').valueOf())],
    ['desc']
  );

  return (
    <SCol span={24}>
      <PerfectScrollbar
        style={{ maxHeight: '349px' }}
        options={{ suppressScrollX: true }}
        onScrollY={onScrollY}
      >
        <SRow gutter={[8, 8]} margin="1px">
          {isEmpty(commentsTemplates) ? (
            <SCol span={24}>
              <Empty description={t('templates.empty')} image={Empty.PRESENTED_IMAGE_SIMPLE} />
            </SCol>
          ) : (
            commentsTemplates.map(template => (
              <TemplateComment
                template={template}
                commentState={commentState}
                setCommentState={setCommentState}
                setTemplateState={setTemplateState}
                setEditorState={setEditorState}
                setVisibleSelector={setVisibleSelector}
              />
            ))
          )}
        </SRow>

        {loading && (
          <SCol span={24}>
            <SRow type="flex" justify="center">
              <SCol>
                <SText type="secondary">{t('templates.loadingTemplates')}</SText>
              </SCol>
            </SRow>
          </SCol>
        )}

        {!loading && !hasNextPage && (
          <SCol span={24}>
            <SRow type="flex" justify="center">
              <SCol>
                <SText type="secondary">{t('components.checklistsList.listLoaded')}</SText>
              </SCol>
            </SRow>
          </SCol>
        )}
      </PerfectScrollbar>
    </SCol>
  );
};

export default TemplatesList;
