import React from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { TagsDisplay } from 'components/Tags/TagsDisplay';

const TasksSection = ({ taskDefinitionsByIds, currentReviewTaskDefinitionsIds = [], truncate }) => {
  const tasks = currentReviewTaskDefinitionsIds.map(taskDefinitionId => ({
    taskDefinition: get(taskDefinitionsByIds, taskDefinitionId)
  }));

  return <TagsDisplay tasks={tasks} truncate={truncate} />;
};

const mapStateToProps = state => ({
  taskDefinitionsByIds: state.taskDefinitionsResource.byIds,
  currentReviewTaskDefinitionsIds: state.uiClientInteractionPage.tasks.taskDefinitionsIds
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(TasksSection);
