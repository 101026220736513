import React from 'react';
import { Modal, Select, message, Button, Row, Col, Typography } from 'antd';
import { updateDashboardWidget } from 'redux/ui/widgetSettingsModal/operations';
import {
  updateUiWidgetSettings,
  updateUiWidgetFilters,
  resetUiWidgetFilters,
  resetUiWidgetModal
} from 'redux/ui/widgetSettingsModal/reducer';
import { ChevronDown } from 'react-feather';
import {
  PERMISSIONS,
  CHECKLIST_DEFINITION_STATUS,
  DATE_SELECTOR_TYPE_TO_FILTERS,
  MAX_COUNT_WIDGET_CLIENT_INTERACTIONS
} from 'core/utils/constants';
import { IconButton } from 'components/Buttons/IconButton';
import moment from 'moment';
import { getUsersByUnitIdsWithPermissions, getUnitsWithActiveUsers } from 'redux/selectors/users';
import { connect } from 'react-redux';
import { get, isNil, isEmpty } from 'lodash';
import { getChecklistsDefinitionsByParams } from 'redux/selectors/checklists';
import { Trans, useTranslation } from 'react-i18next';
import Icon from 'components/Icon';
import FilterForm from './FilterForm';
import { StyledSelect, StyledInput } from '../styled';
import {
  requiredChecklistDefinitionsWidgetTypes,
  requiredOperatorIdsWidgetTypes,
  getDateSelectorType
} from '../utils';
import smallWidgets from '../widgetsDefinitions/smallWidgets';
import mediumWidgets from '../widgetsDefinitions/mediumWidgets';
import largeWidgets from '../widgetsDefinitions/largeWidgets';
import extraLargeWidgets from '../widgetsDefinitions/extraLargeWidgets';
import WidgetMeta from './WidgetMeta';

const { Option } = Select;

const SettingsModal = ({
  widgetData,
  unitsByIds,
  reviewers,
  operators,
  checklistDefinitions,
  taskDefinitionsByIds,
  updateDashboardWidget,
  updateUiWidgetSettings,
  updateUiWidgetFilters,
  resetUiWidgetFilters,
  widgetModalData,
  resetUiWidgetModal,
  loadingMeta,
  meta
}) => {
  const { Text } = Typography;
  const { t } = useTranslation();
  const { size } = widgetData;
  const { type } = widgetModalData;

  const widgetsSizesRelations = {
    small: smallWidgets,
    medium: mediumWidgets,
    large: largeWidgets,
    extra_large: extraLargeWidgets
  };

  const availableWidgetsDefinitions = widgetsSizesRelations[size];

  const selectedWidgetDefinition = availableWidgetsDefinitions[type];

  const selectedWidgetAvailableFilters = get(selectedWidgetDefinition, 'availableFilters', []);

  const setWidgetSettings = () => {
    const defaultWidgetSettings = get(selectedWidgetDefinition, 'defaultWidgetSettings', {});

    try {
      const updatedWidget = updateDashboardWidget(
        isEmpty(defaultWidgetSettings)
          ? { ...widgetModalData, sort: '' }
          : { ...widgetModalData, sort: '', ...defaultWidgetSettings }
      );
      updateUiWidgetSettings({ id: '' });
      if (updatedWidget)
        message.success(t('dashboardPage.widget.modal.messages.widgetSuccessfullyUpdated'));
    } catch (error) {
      console.log(error);
      message.error(t('dashboardPage.widget.modal.messages.updateWidgetFailed'));
    }
  };

  const deleteWidgetSettings = () => {
    try {
      updateDashboardWidget({
        id: widgetModalData.id,
        name: '',
        type: '',
        filters: {}
      });
      resetUiWidgetModal();
      message.success(t('dashboardPage.widget.modal.messages.widgetSuccessfullyUpdated'));
    } catch (error) {
      console.log(error);
      message.error(t('dashboardPage.widget.modal.messages.updateWidgetFailed'));
    }
  };

  const setWidgetFilters = ({ widgetType }) => {
    if (Object.keys(extraLargeWidgets).includes(widgetType)) {
      const additionalDateSelector = extraLargeWidgets[widgetType]?.additionalDateSelectorType;

      const conditionalDatesFilters = {
        [DATE_SELECTOR_TYPE_TO_FILTERS[additionalDateSelector]?.timeFrom]: moment()
          .startOf('month')
          .toISOString(true),
        [DATE_SELECTOR_TYPE_TO_FILTERS[additionalDateSelector]?.timeTo]: moment()
          .endOf('month')
          .toISOString(true)
      };

      return {
        isWeekendView: false,
        historyGroupingPeriod: 'day',
        viewMode: extraLargeWidgets[widgetType]?.defaultViewMode,
        viewType: extraLargeWidgets[widgetType]?.defaultViewType,
        [DATE_SELECTOR_TYPE_TO_FILTERS[getDateSelectorType({ widgetType })]?.timeFrom]: moment()
          .startOf('month')
          .toISOString(true),
        [DATE_SELECTOR_TYPE_TO_FILTERS[getDateSelectorType({ widgetType })]?.timeTo]: moment()
          .endOf('month')
          .toISOString(true),
        ...(additionalDateSelector && conditionalDatesFilters)
      };
    }
    return {
      [DATE_SELECTOR_TYPE_TO_FILTERS[getDateSelectorType({ widgetType })]
        ?.symbolicTimeRange]: 'this_month'
    };
  };

  const setDefaultWidgetNames = ({ widgetType }) => {
    if (!Object.keys(extraLargeWidgets).includes(widgetType)) return [];

    const selectedWidget = extraLargeWidgets[widgetType];
    const { defaultViewMode, defaultViewType } = selectedWidget;
    return get(selectedWidget, ['widget_names', defaultViewType, defaultViewMode], []);
  };

  const changeWidgetDataType = value => {
    resetUiWidgetFilters();
    updateUiWidgetSettings({
      type: value,
      filters: {
        ...setWidgetFilters({ widgetType: value })
      },
      widgetNames: setDefaultWidgetNames({ widgetType: value })
    });
  };

  const isDisallowToSave = ({
    widgetType,
    checklistDefinitionsIds,
    loadingMeta,
    clientInteractionsCount
  }) => {
    if (isEmpty(widgetType)) return true;
    if (
      loadingMeta ||
      (clientInteractionsCount >
        MAX_COUNT_WIDGET_CLIENT_INTERACTIONS.MAX_CLIENT_INTERACTIONS_COUNT &&
        widgetType === 'table_reviews_with_scores')
    )
      return true;

    return (
      (requiredChecklistDefinitionsWidgetTypes.includes(widgetType) &&
        isEmpty(checklistDefinitionsIds)) ||
      (requiredOperatorIdsWidgetTypes.includes(widgetType) &&
        isEmpty(widgetModalData.filters.operatorsIds)) ||
      isNil(widgetType)
    );
  };

  return (
    <>
      <IconButton
        tooltip={{ title: t('dashboardPage.widget.modal.tooltip') }}
        button={{
          onClick: () => updateUiWidgetSettings(widgetData),
          size: 'icon',
          icon: <Icon icon={ChevronDown} />
        }}
      />

      <Modal
        title={t('dashboardPage.widget.modal.title')}
        visible={widgetModalData.id === widgetData.id}
        onCancel={() => resetUiWidgetModal()}
        footer={
          <Row>
            <Col span={4}>
              <Button key="clean" type="danger" onClick={() => deleteWidgetSettings()}>
                {t('dashboardPage.widget.modal.buttons.reset')}
              </Button>
            </Col>
            <Col span={20}>
              <Button key="cancel" onClick={() => resetUiWidgetModal()}>
                {t('dashboardPage.widget.modal.buttons.cancel')}
              </Button>
              <Button
                key="save"
                disabled={isDisallowToSave({
                  widgetType: widgetModalData.type,
                  checklistDefinitionsIds: widgetModalData.filters.checklistDefinitionsIds,
                  loadingMeta,
                  clientInteractionsCount: meta?.totalCount
                })}
                type="primary"
                onClick={setWidgetSettings}
              >
                {t('dashboardPage.widget.modal.buttons.save')}
              </Button>
            </Col>
          </Row>
        }
      >
        <>
          <Text strong>{t('dashboardPage.widget.modal.form.type')}</Text>
          <StyledSelect
            placeholder={<Trans i18nKey="dashboardPage.widget.modal.form.typePlaceholder" />}
            value={widgetModalData?.type === '' ? undefined : widgetModalData?.type}
            onChange={changeWidgetDataType}
          >
            {Object.values(availableWidgetsDefinitions).reduce((acc, widgetDefinition) => {
              if (widgetDefinition.outdated) return acc;
              return [
                ...acc,
                <Option key={widgetDefinition.type} value={widgetDefinition.type}>
                  {widgetDefinition.name}
                </Option>
              ];
            }, [])}
          </StyledSelect>

          <Text strong>{t('dashboardPage.widget.modal.form.name')}</Text>
          <StyledInput
            value={widgetModalData.name}
            placeholder={t('dashboardPage.widget.modal.form.namePlaceholder')}
            onChange={e => updateUiWidgetSettings({ name: e.target.value })}
          />
          <Row>
            {selectedWidgetAvailableFilters.map(availableWidgetFiltersData => (
              <FilterForm
                filterData={{
                  filtersDefinition: availableWidgetFiltersData,
                  filtersData: get(widgetData, 'filters', {}),
                  widgetId: widgetData.id,
                  unitsByIds,
                  reviewers,
                  operators,
                  checklistDefinitions,
                  updateUiWidgetFilters,
                  widgetModalData,
                  taskDefinitionsByIds
                }}
              />
            ))}
            {Object.keys(extraLargeWidgets).includes(type) && <WidgetMeta />}
          </Row>
        </>
      </Modal>
    </>
  );
};

const mapStateToProps = state => {
  const unitsWithActiveUsers = getUnitsWithActiveUsers(state);
  const { filters } = state.uiWidgetSettingsModal;
  const { meta, loadingMeta } = state.uiClientInteractions;

  const operators = getUsersByUnitIdsWithPermissions(state, {
    permissions: [PERMISSIONS.CAN_PERFORM_CLIENT_INTERACTION],
    unitIds: isEmpty(filters.unitsIds) ? undefined : filters.unitsIds
  });

  const reviewers = Object.values(
    getUsersByUnitIdsWithPermissions(state, {
      permissions: [PERMISSIONS.CAN_MAKE_REVIEW],
      unitIds: isEmpty(filters.unitsIds) ? undefined : filters.unitsIds
    })
  ).filter(operator => operator.active);

  const checklistDefinitions = getChecklistsDefinitionsByParams(state, {
    params: {
      unitsIds: filters.unitsIds,
      statuses: [
        CHECKLIST_DEFINITION_STATUS.ARCHIVED.value,
        CHECKLIST_DEFINITION_STATUS.PUBLISHED.value
      ]
    }
  });

  return {
    widgetModalData: state.uiWidgetSettingsModal,
    activeDashboardId: state.uiDashboard.activeDashboardId,
    checklistDefinitions,
    taskDefinitionsByIds: state.taskDefinitionsResource.byIds,
    unitsByIds: unitsWithActiveUsers,
    reviewers,
    operators,
    loadingMeta,
    meta
  };
};

const mapDispatchToProps = {
  updateDashboardWidget,
  updateUiWidgetSettings,
  resetUiWidgetModal,
  updateUiWidgetFilters,
  resetUiWidgetFilters
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsModal);
