import { post } from 'redux-bees';

const amoEndpoints = apiPath => ({
  amoRegisterOrganization: {
    method: post,
    path: `${apiPath}/integrations/amocrm/register_organization`
  },
  //   bitrixConnectOrganization: { method: post, path: `${apiPath}/bitrix/connect_organization/:id` },
  amoSyncDataByPeriod: { method: post, path: `${apiPath}/integrations/amocrm/:id/fetch_data` }
});

export default amoEndpoints;
