export default {
  workPlanTasks: {
    taskPlaceholder: 'Task',
    colorizedNumbers: {
      completed: 'Completed reviews count',
      incompleted: 'Incompleted reviews count',
      total: 'Total reviews count'
    },
    tableColumns: {
      reviewsCount: 'Reviews',
      reviewsCount2: 'Reviews',
      completed: 'Completed',
      incompleted: 'Incompleted',
      closeTask: 'Close task',
      stopRepeating: 'Stop repetitions',
      name: 'Task name',
      lastTaskTimeFrom: 'Start Date',
      lastTaskTimeTo: 'End date',
      creator: 'Creator',
      schedule: 'Schedule',
      repeatedCount: 'Executions',
      reviewCreatedAt: 'Date and time of review'
    },
    assignments: {
      closeTask: 'Close task',
      close: 'Finish',
      taskStartedAt: 'Launch date',
      assignmentsCount: 'Number of reviewers',
      completedTaskItemsCount: 'Total count of reviews'
    },
    filters: { createTask: 'Create task' },
    confirmStopRepeating: 'Stop repetitions?',
    confirmClosing: 'Close task?',
    confirmDelete: 'Delete Configuration?'
  }
};
