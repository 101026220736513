import { Col, Empty, Row } from 'antd';
import SCard from 'components/Standard/SCard';
import { ANALYTICS_WIDGETS_NAMES } from 'core/utils/constants';
import { get, intersection, isEmpty, keyBy, orderBy } from 'lodash';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { getUnitsWithActiveUsers } from 'redux/selectors/users';
import { loadTeamAnalytics } from 'redux/ui/teamAnalyticsPage/operations';
import PageFilters from './Components/PageFilters';
import UnitTable from './Components/UnitTable/UnitTable';
import UsersTable from './Components/UsersTable/UsersTable';

const TeamPage = ({ loadTeamAnalytics, unitsWithReviewedUsers, filters, loading, viewType }) => {
  const {
    unitsIds,
    checklistDefinitionsIds,
    clientInteractionTimeFrom,
    clientInteractionTimeTo,
    clientInteractionSymbolicTimeRange
  } = filters;

  useEffect(() => {
    loadTeamAnalytics({ filters });
  }, [
    unitsIds,
    checklistDefinitionsIds,
    clientInteractionTimeFrom,
    clientInteractionTimeTo,
    clientInteractionSymbolicTimeRange
  ]);

  const { t } = useTranslation();

  return (
    <Row gutter={[8, 8]} style={{ margin: '-4px' }}>
      <Helmet>
        <title>{t('pagesMeta.teamPage.title')}</title>
      </Helmet>
      <Col span={24}>
        <PageFilters />
      </Col>
      <Col span={24}>
        {isEmpty(unitsWithReviewedUsers) ? (
          <SCard loading={loading}>
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={t('teamPage.unitTable.empty')}
            />
          </SCard>
        ) : (
          <>
            {viewType === 'employees' ? (
              unitsWithReviewedUsers.map(unit => <UsersTable unit={unit} />)
            ) : (
              <UnitTable />
            )}
          </>
        )}
      </Col>
    </Row>
  );
};

const mapStateToProps = state => {
  const { widgets, loadingWidgets } = state.analyticsWidgets;
  const { filters, viewType } = state.uiTeamAnalyticsPage;
  const reviewsCountWidgets = keyBy(
    get(widgets, `${ANALYTICS_WIDGETS_NAMES.REVIEWS_COUNT_BY_OPERATORS}.current_period`, {}),
    'id'
  );
  const reviewedUsersIds = Object.keys(reviewsCountWidgets);
  const units = getUnitsWithActiveUsers(state);
  const unitsWithReviewedUsers = orderBy(
    units.filter(unit =>
      isEmpty(filters.unitsIds)
        ? intersection(unit.usersIds, reviewedUsersIds).length > 0
        : intersection(unit.usersIds, reviewedUsersIds).length > 0 &&
          filters.unitsIds.includes(unit.id)
    ),
    'name'
  );

  return {
    unitsWithReviewedUsers,
    filters,
    loading:
      state.checklistDefinitionsResource.loading || state.unitsResource.loading || loadingWidgets,
    viewType
  };
};

const mapDispatchToProps = {
  loadTeamAnalytics
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamPage);
