import { Empty, Typography } from 'antd';
import { beatifyFloat } from 'core/utils/convertFloat';
import { valueFormatter } from 'core/utils/valueFormatter';
import en from 'locales/en/apexCharts';
import ru from 'locales/ru/apexCharts';
import es from 'locales/es/apexCharts';
import it from 'locales/it/apexCharts';
import { SYMBOLIC_TIME_RANGE } from 'core/utils/constants';
import { find, get } from 'lodash';
import moment from 'moment';
import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

const CombinedChart = ({
  preferedLocale,
  barData,
  lineData,
  checklistDefinitionMax,
  selectedUserId,
  checklistDefinition,
  filters
}) => {
  const { t } = useTranslation();
  const { Text } = Typography;
  const currentDay = moment().format('DD');

  const month = moment(get(filters, 'clientInteractionTimeFrom')).format('MM');
  const currentMonth = moment().format('MM');

  const isCurrentMonthView =
    currentMonth === month ||
    filters.clientInteractionSymbolicTimeRange === SYMBOLIC_TIME_RANGE.THIS_MONTH;

  const prepareUserData = () => {
    const userData = get(find(barData, { id: selectedUserId }), 'history', {});

    const actualDaysKeys = Object.keys(userData).filter(
      day => moment(day).format('DD') <= currentDay
    );
    return isCurrentMonthView ? actualDaysKeys.map(day => userData[day]) : Object.values(userData);
  };

  const series = [
    {
      name: t(
        'dashboardPage.tableReviewsCountByOperators.viewModes.types.chart.seriesNames.averageScoreByOperator'
      ),
      type: 'column',
      data: prepareUserData()
    },
    {
      name: t(
        'dashboardPage.tableReviewsCountByOperators.viewModes.types.chart.seriesNames.totalAverageScore'
      ),
      type: 'line',
      data: Object.values(lineData)
    }
  ];

  const options = {
    chart: {
      type: 'line',
      stacked: false,
      toolbar: {
        show: false
      },
      offsetY: 8,
      zoom: {
        enabled: false
      },
      defaultLocale: preferedLocale,
      locales: [ru, en, es, it]
    },
    colors: ['var(--primary)', 'var(--lilac_8)'],
    stroke: {
      width: [0, 2, 50],
      curve: 'smooth'
    },
    plotOptions: {
      bar: {
        columnWidth: '50%'
      }
    },

    legend: {
      offsetY: 15,
      height: 32
    },

    fill: {
      gradient: {
        inverseColors: false,
        shade: 'light',
        type: 'vertical',
        opacityFrom: 0.85,
        opacityTo: 0.55,
        stops: [0, 100, 100, 100]
      }
    },

    labels: Object.keys(lineData),
    markers: {
      size: 0
    },

    xaxis: {
      type: 'datetime'
    },

    yaxis: {
      title: {
        text: t('dashboardPage.tableReviewsCountByOperators.viewModes.types.chart.yaxis')
      },
      labels: {
        formatter: value => {
          return beatifyFloat(value, 1);
        },
        offsetX: 17
      },
      min: 0,
      max: checklistDefinitionMax
    },

    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: y => {
          if (typeof y !== 'undefined') {
            return valueFormatter({
              value: y,
              ratingMode: checklistDefinition.ratingMode
            });
          }
          return y;
        }
      }
    }
  };

  const isInsufficientData = Object.keys(lineData).filter(date => lineData[date]).length < 2;

  return (
    <>
      {isInsufficientData ? (
        <>
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={
              <Text type="secondary">{t('dashboardPage.widget.messages.needMoreData')}</Text>
            }
          />
        </>
      ) : (
        <ReactApexChart options={options} series={series} type="line" height={485} />
      )}
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { widgetId } = ownProps;
  return {
    preferedLocale: state.reduxTokenAuth.currentUser.attributes.user['prefered-locale'],
    selectedUserId: get(state, `uiDashboard.chartsView.widgets.${widgetId}.selectedUserId`, {})
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CombinedChart);
