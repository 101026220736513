import { Activity } from 'react-feather';
import { Col, Row, Tooltip, Typography } from 'antd';
import GreenFlag from 'components/Flags/GreenFlag';
import RedFlag from 'components/Flags/RedFlag';
import YellowFlag from 'components/Flags/YellowFlag';
import { Info } from 'components/Info';
import SCard from 'components/Standard/SCard';
import { ZONE_TO_COLOR_RELATIONS } from 'core/utils/constants';
import { beatifyFloat } from 'core/utils/convertFloat';
import { valueFormatter } from 'core/utils/valueFormatter';
import SmallWidget from 'pages/DashboardPage/SmallWidgets/SmallWidget';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { setScoresHistoryChecklistDefinitionId } from 'redux/ui/userAnalyticsPage/reducer';
import styled, { css } from 'styled-components';
import Icon from 'components/Icon';
import SCol from 'components/Standard/SCol';

const { Text } = Typography;

const flagsCss = css`
  && {
    font-size: clamp(16px, 1.5vw, 24px);
  }
`;

export const SmallValue = styled.span`
  font-size: clamp(16px, 1.5vw, 24px);
  font-weight: 500;
  color: ${props => props.color || 'inherit'};
`;

export const Value = styled.span`
  font-size: clamp(24px, 2.5vw, 40px);
  font-weight: 500;
  color: ${props => props.color || 'inherit'};
  overflow: hidden;
  max-width: 100%;
  display: block;

  ${SmallValue} {
    padding: 0 4px;
  }
`;

const ChecklistDefinitionAnalytics = ({
  loading,
  checklistDefinition = {},
  showName = false,
  reviewsCount,
  averageScore,
  colorZones = {},
  ratingFlags = {},
  setScoresHistoryChecklistDefinitionId
}) => {
  const { name, description } = checklistDefinition;
  const { high = 0, medium = 0, low = 0 } = colorZones;
  const { yellow = 0, green = 0, red = 0 } = ratingFlags;
  const highPercentage =
    high && reviewsCount ? `(${beatifyFloat((high / reviewsCount) * 100)}%)` : '';
  const mediumPercentage =
    medium && reviewsCount ? `(${beatifyFloat((medium / reviewsCount) * 100)}%)` : '';
  const lowPercentage = low && reviewsCount ? `(${beatifyFloat((low / reviewsCount) * 100)}%)` : '';
  const { t } = useTranslation();

  const showHistoryModal = () => {
    setScoresHistoryChecklistDefinitionId(checklistDefinition.id);
  };

  return (
    <Row gutter={[8, 8]} style={{ margin: '-4px' }} type="flex">
      {showName && (
        <Col span={24}>
          <SCard bodyPadding="0px">
            <Col span={24}>
              <Row type="flex" justify="space-between" align="middle">
                <Col>
                  <Row
                    gutter={[8, 8]}
                    style={{ margin: '-4px' }}
                    type="flex"
                    justify="start"
                    align="middle"
                  >
                    <Col>
                      <Info tooltip={{ title: description }} disabled={!description} />
                    </Col>
                    <Col>
                      <Text>{name}</Text>
                    </Col>
                  </Row>
                </Col>

                <Col>
                  <Tooltip
                    placement="right"
                    title={t('userAnalyticsPage.widgets.checklistTooltip')}
                  >
                    <Icon icon={Activity} onClick={showHistoryModal} />
                  </Tooltip>
                </Col>
              </Row>
            </Col>
          </SCard>
        </Col>
      )}
      <Col span={24}>
        <Row gutter={[8, 8]} style={{ margin: '-4px' }} justify="space-between" type="flex">
          <Col span={4}>
            <SmallWidget
              name={t('userAnalyticsPage.widgets.reviewsCount.name')}
              loading={loading}
              info={{
                tooltip: { title: t('userAnalyticsPage.widgets.reviewsCount.description') }
              }}
            >
              <Value>{reviewsCount}</Value>
            </SmallWidget>
          </Col>
          <Col span={4}>
            <SmallWidget
              name={t('userAnalyticsPage.widgets.averageScore.name')}
              loading={loading}
              info={{
                tooltip: { title: t('userAnalyticsPage.widgets.averageScore.description') }
              }}
            >
              <Value>
                {valueFormatter({
                  value: averageScore,
                  ratingMode: checklistDefinition.ratingMode
                }) || '0'}
              </Value>
            </SmallWidget>
          </Col>
          <Col span={4}>
            <SmallWidget
              name={t('userAnalyticsPage.widgets.lowPercentage.name')}
              loading={loading}
              headerColor={ZONE_TO_COLOR_RELATIONS.low}
              bodyHeight="inherit"
              info={{
                tooltip: { title: t('userAnalyticsPage.widgets.lowPercentage.description') }
              }}
            >
              <Value>
                {low}
                <SmallValue>{lowPercentage}</SmallValue>
              </Value>
            </SmallWidget>
          </Col>
          <Col span={4}>
            <SmallWidget
              name={t('userAnalyticsPage.widgets.mediumPercentage.name')}
              loading={loading}
              headerColor={ZONE_TO_COLOR_RELATIONS.medium}
              info={{
                tooltip: { title: t('userAnalyticsPage.widgets.mediumPercentage.description') }
              }}
            >
              <Value>
                {medium}
                <SmallValue>{mediumPercentage}</SmallValue>
              </Value>
            </SmallWidget>
          </Col>
          <Col span={4}>
            <SmallWidget
              name={t('userAnalyticsPage.widgets.highPercentage.name')}
              loading={loading}
              headerColor={ZONE_TO_COLOR_RELATIONS.high}
              info={{
                tooltip: { title: t('userAnalyticsPage.widgets.highPercentage.description') }
              }}
            >
              <Value>
                {high}
                <SmallValue>{highPercentage}</SmallValue>
              </Value>
            </SmallWidget>
          </Col>
          <Col span={4}>
            <SmallWidget
              name={t('userAnalyticsPage.widgets.flags.name')}
              loading={loading}
              info={{
                tooltip: {
                  title: t('userAnalyticsPage.widgets.flags.description'),
                  placement: 'topRight'
                }
              }}
            >
              <Row
                type="flex"
                justify="space-around"
                gutter={[8, 8]}
                style={{ margin: '-4px' }}
                align="middle"
              >
                <Col span={8}>
                  <Row>
                    <SCol display="flex" justifyContent="center" span={24}>
                      <RedFlag css={flagsCss} />
                    </SCol>
                    <SCol display="flex" justifyContent="center" span={24}>
                      <Text strong>{red}</Text>
                    </SCol>
                  </Row>
                </Col>
                <Col span={8}>
                  <Row>
                    <SCol display="flex" justifyContent="center" span={24}>
                      <YellowFlag css={flagsCss} />
                    </SCol>
                    <SCol display="flex" justifyContent="center" span={24}>
                      <Text strong>{yellow}</Text>
                    </SCol>
                  </Row>
                </Col>
                <Col span={8}>
                  <Row>
                    <SCol display="flex" justifyContent="center" span={24}>
                      <GreenFlag css={flagsCss} />
                    </SCol>
                    <SCol display="flex" justifyContent="center" span={24}>
                      <Text strong>{green}</Text>
                    </SCol>
                  </Row>
                </Col>
              </Row>
            </SmallWidget>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const mapDispatchToProps = {
  setScoresHistoryChecklistDefinitionId
};

export default connect(null, mapDispatchToProps)(ChecklistDefinitionAnalytics);
