import React from 'react';
import { connect } from 'react-redux';
import { Typography, Row, Col } from 'antd';
import { get, isEmpty, keyBy } from 'lodash';
import SCard from 'components/Standard/SCard';
import { ANALYTICS_WIDGETS_NAMES } from 'core/utils/constants';
import { getUsersByUnitId, getCurrentUser } from 'redux/selectors/users';
import { Info } from 'components/Info';
import { useTranslation } from 'react-i18next';
import STable from 'components/Standard/STable';
import tableColumns from './tableColumns';
import tableRows from './tableRows';

const UsersTable = ({
  unit,
  users,
  loading,
  widgets,
  filters,
  currentUserPermissions,
  currentUserId,
  checklistdefinitionsByIds
}) => {
  const { name } = unit;
  const { Title } = Typography;
  const { t } = useTranslation();

  const checklistColorZoneData = keyBy(
    get(
      widgets,
      `${ANALYTICS_WIDGETS_NAMES.CHECKLIST_DEFINITION_COLOR_ZONES_BY_OPERATORS}.current_period`,
      {}
    ),
    'id'
  );

  const previousChecklistColorZoneData = keyBy(
    get(
      widgets,
      `${ANALYTICS_WIDGETS_NAMES.CHECKLIST_DEFINITION_COLOR_ZONES_BY_OPERATORS}.previous_period`,
      {}
    ),
    'id'
  );

  const averageScoreData = keyBy(
    get(
      widgets,
      `${ANALYTICS_WIDGETS_NAMES.CHECKLIST_DEFINITION_AVERAGE_SCORE_BY_OPERATORS}.current_period`,
      {}
    ),
    'id'
  );

  const previousPeriodAverageScoreData = keyBy(
    get(
      widgets,
      `${ANALYTICS_WIDGETS_NAMES.CHECKLIST_DEFINITION_AVERAGE_SCORE_BY_OPERATORS}.previous_period`,
      {}
    ),
    'id'
  );

  const reviewsCountData = keyBy(
    get(widgets, `${ANALYTICS_WIDGETS_NAMES.REVIEWS_COUNT_BY_OPERATORS}.current_period`, {}),
    'id'
  );

  const previousReviewsCountData = keyBy(
    get(widgets, `${ANALYTICS_WIDGETS_NAMES.REVIEWS_COUNT_BY_OPERATORS}.previous_period`, {}),
    'id'
  );

  const ratingFlagsData = keyBy(
    get(widgets, `${ANALYTICS_WIDGETS_NAMES.RATING_FLAGS_COUNT_BY_OPERATORS}.current_period`, {}),
    'id'
  );

  return (
    <SCard loading={loading}>
      <Row type="flex" align="middle">
        <Col>
          <Title level={4} style={{ display: 'inline' }}>
            {name}
          </Title>
          <Info
            tooltip={{
              title: t('teamPage.unitTable.infoIcons.info')
            }}
            iconProps={{ style: { margin: '0px 0px 8px 8px' } }}
          />
        </Col>
      </Row>
      <STable
        columns={tableColumns({
          currentUserPermissions,
          currentUserId,
          filters,
          checklistdefinitionsByIds
        })}
        dataSource={tableRows({
          checklistColorZoneData,
          previousChecklistColorZoneData,
          reviewsCountData,
          previousReviewsCountData,
          averageScoreData,
          previousPeriodAverageScoreData,
          users,
          ratingFlagsData
        })}
        showSorterTooltip={false}
        pagination={false}
        tableLayout="fixed"
        fixSorterColumns
      />
    </SCard>
  );
};

const mapStateToProps = (state, ownProps) => {
  const unitId = get(ownProps, 'unit.id');
  const { widgets } = state.analyticsWidgets;
  const { filters } = state.uiTeamAnalyticsPage;
  const currentUser = getCurrentUser(state);
  const currentUserId = get(currentUser, 'id', '');
  const currentUserPermissions = get(currentUser, 'role.permissions', []);
  const checklistdefinitionsByIds = state.checklistDefinitionsResource.byIds;

  return {
    widgets,
    loading:
      state.checklistDefinitionsResource.loading || state.unitsResource.loading || isEmpty(widgets),
    users: Object.values(getUsersByUnitId(state, { unitId })),
    units: Object.values(state.unitsResource.byIds),
    filters,
    currentUserPermissions,
    currentUserId,
    checklistdefinitionsByIds
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(UsersTable);
