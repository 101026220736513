import { Trash2 } from 'react-feather';
import { Typography } from 'antd';
import { LEVEL_RANK_TO_ICON } from 'components/UserPreview/LevelIcon';
import { get, isEmpty, isEqual, reduce, some, sortBy, uniqBy } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { saveLevels } from 'redux/ui/levelsPage/operations';
import { addLevel, deleteLevel, setLevelsRoleId, updateLevel } from 'redux/ui/levelsPage/reducer';
import uniqid from 'uniqid';
import Icon from 'components/Icon';
import SButton from 'components/Standard/SButton';
import SRow from 'components/Standard/SRow';
import SCol from 'components/Standard/SCol';
import SInput from 'components/Standard/SInput';
import SModal from 'components/Standard/SModal';

const LevelsModal = () => {
  const { t } = useTranslation();
  const { Text } = Typography;
  const dispatch = useDispatch();

  const roleIdToLevels = useSelector(state => state.uiLevelsPage.roleIdToLevels, isEqual);
  const levelsRoleId = useSelector(state => state.uiLevelsPage.levelsRoleId, isEqual);
  const levelsByIds = get(roleIdToLevels, levelsRoleId, {});
  const role = useSelector(state => get(state.rolesResource.byIds, levelsRoleId, {}), isEqual);
  const loading = useSelector(
    state =>
      state.levelsResource.deleteByIdStarted ||
      state.levelsResource.updateByIdStarted ||
      state.levelsResource.createStarted
  );

  const visible = !isEmpty(levelsRoleId);

  const onSave = useCallback(() => {
    dispatch(saveLevels({ roleId: levelsRoleId, levelsByIds }));
  }, [dispatch, levelsRoleId, levelsByIds]);

  const levels = useMemo(
    () =>
      sortBy(
        reduce(
          levelsByIds,
          (res, level, id) => [...res, { ...level, id, roleId: levelsRoleId }],
          []
        ),
        'rank'
      ),
    [levelsByIds, levelsRoleId]
  );

  const onAddLevel = useCallback(() => {
    dispatch(addLevel({ roleId: levelsRoleId, id: uniqid(), rank: levels.length + 1 }));
  }, [dispatch, levelsRoleId, levels]);

  const renderLevel = level => {
    const onUpdateLevel = e => {
      dispatch(updateLevel({ ...level, name: e.target.value }));
    };

    const onDeleteLevel = async () => {
      dispatch(deleteLevel(level));
    };

    const LevelIcon = LEVEL_RANK_TO_ICON[level.rank];

    return (
      <SRow type="flex" gutter={[8, 8]} align="middle">
        <SCol>
          <LevelIcon />
        </SCol>
        <SCol flex="auto">
          <SInput
            onChange={onUpdateLevel}
            placeholder={t('organizationStructure.tabs.levels.modal.form.namePlaceholder')}
            value={level.name}
          />
        </SCol>
        <SCol>
          <SButton
            icon={<Icon icon={Trash2} />}
            type="link"
            disabled={loading}
            onClick={onDeleteLevel}
            color="var(--red_6)"
          />
        </SCol>
      </SRow>
    );
  };

  const canAddLevel = levels.length < 5;

  const hasSameNames = useMemo(() => uniqBy(levels, 'name').length !== levels.length, [levels]);

  const hasEmptyNames = useMemo(() => some(levels, ({ name = '' }) => isEmpty(name.trim())), [
    levels
  ]);

  const canSave = !hasSameNames && !hasEmptyNames;

  return (
    <SModal
      visible={visible}
      title={`${t('organizationStructure.tabs.levels.modal.title')}: ${role.name}`}
      okText={t('organizationStructure.tabs.levels.modal.buttons.save')}
      cancelText={t('organizationStructure.tabs.levels.modal.buttons.cancel')}
      onOk={onSave}
      okButtonProps={{ disabled: !canSave }}
      destroyOnClose
      onCancel={() => dispatch(setLevelsRoleId(undefined))}
      confirmLoading={loading}
    >
      <SRow gutter={[0, 8]}>
        <SCol span={24}>{levels.map(renderLevel)}</SCol>
        {canAddLevel && (
          <SCol span={24}>
            <SButton type="dashed" block onClick={onAddLevel}>
              {t('organizationStructure.tabs.levels.modal.buttons.addLevel')}
            </SButton>
          </SCol>
        )}
        {hasEmptyNames && (
          <Text>{t('organizationStructure.tabs.levels.modal.messages.hasEmptyNames')}</Text>
        )}
        {!hasEmptyNames && hasSameNames && (
          <Text>{t('organizationStructure.tabs.levels.modal.messages.hasSameNames')}</Text>
        )}
      </SRow>
    </SModal>
  );
};

export default LevelsModal;
