import { createSelector } from '@reduxjs/toolkit';
import { Avatar, Badge, Tooltip, Typography, Row, Col } from 'antd';
import { mapColorToId } from 'core/utils/colors';
import truncateString from 'core/utils/truncateString';
import { get, isEqual } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { variant } from 'styled-system';
import { getCurrentUserPermissions } from 'redux/selectors/users';
import { PERMISSIONS } from 'core/utils/constants';
import ninjaIcon from 'assets/images/ninja-icon.svg';
import Truncated from 'components/Truncated';
import { getUserName, getUserInitials } from './getUserName';
import { LEVEL_RANK_TO_ICON } from './LevelIcon';

const TruncatedCol = Truncated(Col);
const TruncatedRow = Truncated(Row);

const getUserObject = createSelector(
  state => state.usersResource.byIds,
  state => state.reduxTokenAuth.currentUser.attributes,
  (_, props) => props || {},
  (usersByIds, currentUser, { user, userId }) => {
    if (user) {
      return user;
    }

    if (usersByIds[userId]) {
      return usersByIds[userId];
    }

    if (userId === currentUser.id) return { ...currentUser.user, id: currentUser.id };
  }
);

const UserPreview = ({
  user,
  size = 'small',
  userId,
  disabled = false,
  showAvatar,
  showUnit,
  showEmail,
  truncateSize,
  disabledInitials,
  link,
  hidden
}) => {
  const { Text } = Typography;
  const { t } = useTranslation();
  const levelsByIds = useSelector(state => state.levelsResource.byIds, isEqual);

  const shownUser = useSelector(state => getUserObject(state, { user, userId }), isEqual);
  const shownUserUnitName = useSelector(
    state => state.unitsResource.byIds[shownUser?.unitId]?.name
  );
  const shownUserEmail = shownUser?.email;

  const currentUserPermissions = useSelector(state => getCurrentUserPermissions(state) || []);

  // ! надо подумать как написать понятнее
  // * если спятано - проверить наличие права на просмотр, иначе покажи
  const isVisibeReviewerName = hidden
    ? currentUserPermissions.includes(PERMISSIONS.CAN_SEE_REVIEWER_NAME)
    : true;

  if (!shownUser) return t('components.userPreview.userNotInstalled');

  const levelRank = get(levelsByIds, [shownUser.levelId, 'rank']);

  const initials = getUserInitials(shownUser, 2);
  const name = isVisibeReviewerName
    ? getUserName({ user: shownUser })
    : t('constants.hiddenNamePlaceholder');

  const avatarSrc = isVisibeReviewerName
    ? shownUser.avatarUrl || shownUser['avatar-url']
    : ninjaIcon;

  const LevelIcon = LEVEL_RANK_TO_ICON[levelRank];

  const getAdditionalInfo = () => {
    if (showUnit) return shownUserUnitName;
    if (showEmail) return shownUserEmail;
  };

  return (
    <Row type="flex" align="middle" wrap={false}>
      <Col>
        {showAvatar && (
          <Badge count={levelRank && isVisibeReviewerName ? <LevelIcon /> : null}>
            <Tooltip title={disabledInitials ? name : ''}>
              <CustomAvatar
                src={avatarSrc}
                size={size}
                style={{ backgroundColor: avatarSrc ? 'none' : mapColorToId(shownUser.id) }}
              >
                {initials}
              </CustomAvatar>
            </Tooltip>
          </Badge>
        )}
      </Col>
      <TruncatedCol>
        <TruncatedCol>
          {disabled || !isVisibeReviewerName ? (
            <TruncatedRow style={{ width: 'inherit', display: 'block', fontSize: 14 }}>
              <TruncatedCol
                style={{
                  width: 'inherit',
                  height: (showUnit || showEmail) && isVisibeReviewerName ? 20 : 'inherit'
                }}
              >
                <Text>{disabledInitials ? '' : name}</Text>
              </TruncatedCol>
              {(showUnit || showEmail) && isVisibeReviewerName && (
                <TruncatedCol style={{ width: 'inherit' }}>
                  <Text type="secondary">{getAdditionalInfo()}</Text>
                </TruncatedCol>
              )}
            </TruncatedRow>
          ) : (
            <TruncatedRow style={{ width: 'inherit', display: 'block', fontSize: 14 }}>
              <TruncatedCol
                style={{
                  width: 'inherit',
                  height: (showUnit || showEmail) && isVisibeReviewerName ? 20 : 'inherit'
                }}
              >
                {disabled || !isVisibeReviewerName ? (
                  <Text>{truncateSize ? truncateString(name, truncateSize) : name}</Text>
                ) : (
                  <Tooltip title={name}>
                    <Link to={link || `/user/${userId || shownUser.id}/charts`} type="link">
                      {truncateSize ? truncateString(name, truncateSize) : name}
                    </Link>
                  </Tooltip>
                )}
              </TruncatedCol>
              {(showUnit || showEmail) && isVisibeReviewerName && (
                <TruncatedCol style={{ width: 'inherit' }}>
                  <Text type="secondary">{getAdditionalInfo()}</Text>
                </TruncatedCol>
              )}
            </TruncatedRow>
          )}
        </TruncatedCol>
      </TruncatedCol>
    </Row>
  );
};

export const CustomAvatar = styled(Avatar)`
  &.ant-avatar {
    margin-right: 8px !important;
    border: 2px solid white;
    box-shadow: 1px 1px 6px rgba(48, 48, 48, 0.1);
    display: inline-flex;
    align-items: center;
    ${variant({
      prop: 'size',
      variants: {
        extraSmall: {
          width: 20,
          height: 20
        },
        small: {
          width: 32,
          height: 32
        },
        medium: {
          width: 42,
          height: 42
        },
        large: {
          width: 80,
          height: 80
        }
      }
    })};
  }

  &.ant-avatar .ant-avatar-string {
    line-height: 29px !important;
    ${variant({
      prop: 'size',
      variants: {
        extraSmall: {
          fontSize: 8
        },
        small: {
          fontSize: 12
        },
        medium: {
          fontSize: 16
        },
        large: {
          fontSize: 24
        }
      }
    })}
  }

  &.ant-avatar + .anticon {
    position: absolute;
    ${variant({
      prop: 'size',
      variants: {
        extraSmall: {
          top: '5px !important',
          left: '13px !important'
        },
        small: {
          top: '18px !important',
          left: '22px !important'
        },
        large: {
          top: '64px !important',
          left: '64px !important'
        }
      }
    })}
  }
`;

export default UserPreview;
