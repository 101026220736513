import React, { useState, useCallback, useEffect } from 'react';
import { ChevronDown } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { selectUnit } from 'redux/ui/organizationPage/reducer';
import { getRootUnit, getUnitsByIds } from 'redux/selectors/units';
import Icon from 'components/Icon';
import SCard from 'components/Standard/SCard';
import { Col, Input, Row, Typography, Form, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import Modal from 'antd/lib/modal/Modal';
import { unitsResource } from 'redux/resources/units';
import { isEmpty, isEqual } from 'lodash';
import { CustomTree, CustomNode } from './styled';
import NodeContent from './NodeContent';

const { Title } = Typography;

const renderNode = ({ unitId, unitsByIds, isRoot = false, setCreatingUnit, setRenamingUnitId }) => {
  const unit = unitsByIds[unitId];
  if (!unit) return null;

  const childrenIds = Object.values(unitsByIds)
    .filter(({ parentId }) => parentId === unitId)
    .map(unit => unit.id);
  const { id, name } = unit;
  return (
    <CustomNode
      title={
        <NodeContent
          name={name}
          id={id}
          isRoot={isRoot}
          setCreatingUnit={setCreatingUnit}
          setRenamingUnitId={setRenamingUnitId}
        />
      }
      key={id}
    >
      {childrenIds.map(childUnitId =>
        renderNode({ unitsByIds, unitId: childUnitId, setCreatingUnit, setRenamingUnitId })
      )}
    </CustomNode>
  );
};

const UnitsTree = () => {
  const { t } = useTranslation();
  const [creatingUnit, setCreatingUnit] = useState(null);
  const [renamingUnitId, setRenamingUnitId] = useState(null);
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const rootUnitId = useSelector(state => getRootUnit(state)?.id);
  const unitsByIds = useSelector(getUnitsByIds, isEqual);

  const onSelect = useCallback(unitId => dispatch(selectUnit(unitsByIds[unitId[0]])), [dispatch]);

  const handleSubmit = useCallback(async () => {
    try {
      const values = await form.validateFields();
      await dispatch(
        unitsResource.operations.create({ name: values.name, parentId: creatingUnit })
      );
      form.resetFields();
      return setCreatingUnit(false);
    } catch (error) {
      console.log(error);
      setCreatingUnit(false);
    }
  }, [dispatch, creatingUnit]);

  const addUnitToRoot = useCallback(() => setCreatingUnit(rootUnitId), [rootUnitId]);

  const cancelCreatingUnit = useCallback(() => {
    setCreatingUnit(null);
    form.resetFields();
  }, [setCreatingUnit]);

  const cancelRenamingUnit = useCallback(() => {
    setRenamingUnitId(null);
    form.resetFields();
  }, [setRenamingUnitId]);

  const handleRenameSubmit = useCallback(
    async ({ name }) => {
      try {
        await dispatch(unitsResource.operations.updateById({ id: renamingUnitId, name }));
        form.resetFields();
        setRenamingUnitId(null);
      } catch (error) {}
    },
    [dispatch, renamingUnitId]
  );

  useEffect(() => {
    form.resetFields();
  }, [renamingUnitId]);

  return (
    <SCard>
      <Row gutter={[0, 16]}>
        <Col span={24}>
          <Row justify="space-between" align="middle">
            <Col>
              <Title className="no-margin" level={5}>
                Отделы
              </Title>
            </Col>
            <Col>
              <Button type="primary" size="small" onClick={addUnitToRoot}>
                Добавить отдел
              </Button>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <CustomTree
            switcherIcon={<Icon icon={ChevronDown} color="var(--black_4)" />}
            defaultExpandedKeys={[rootUnitId]}
            // ? because antd always returns array
            onSelect={onSelect}
          >
            {renderNode({
              unitId: rootUnitId,
              unitsByIds,
              isRoot: true,
              setCreatingUnit,
              setRenamingUnitId
            })}
          </CustomTree>
        </Col>
      </Row>
      <Modal
        title={t('organizationStructure.tabs.units.modal.title')}
        visible={!isEmpty(creatingUnit)}
        onOk={form.submit}
        okButtonProps={{ loading: unitsByIds[renamingUnitId]?.loading }}
        onCancel={cancelCreatingUnit}
        okText={t('organizationStructure.tabs.units.modal.ok')}
        cancelText={t('organizationStructure.tabs.units.modal.cancel')}
      >
        <Form form={form} scrollToFirstError layout="vertical" onFinish={handleSubmit}>
          <Form.Item
            label={t('organizationStructure.tabs.units.modal.form.name')}
            name="name"
            rules={[
              {
                required: true,
                message: t('organizationStructure.tabs.units.modal.form.messages.errors.enterName'),
                whitespace: true
              }
            ]}
          >
            <Input placeholder={t('organizationStructure.tabs.units.modal.form.namePlaceholder')} />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title={t('organizationStructure.tabs.units.modal.title')}
        visible={!isEmpty(renamingUnitId)}
        onOk={form.submit}
        onCancel={cancelRenamingUnit}
        okText={t('organizationStructure.tabs.units.modal.ok')}
        cancelText={t('organizationStructure.tabs.units.modal.cancel')}
      >
        <Form
          form={form}
          initialValues={{ name: unitsByIds[renamingUnitId]?.name }}
          scrollToFirstError
          layout="vertical"
          onFinish={handleRenameSubmit}
        >
          <Form.Item
            label={t('organizationStructure.tabs.units.modal.form.name')}
            name="name"
            rules={[
              {
                required: true,
                message: t('organizationStructure.tabs.units.modal.form.messages.errors.enterName'),
                whitespace: true
              }
            ]}
          >
            <Input placeholder={t('organizationStructure.tabs.units.modal.form.namePlaceholder')} />
          </Form.Item>
        </Form>
      </Modal>
    </SCard>
  );
};

export default UnitsTree;
