import SCol from 'components/Standard/SCol';
import SRow from 'components/Standard/SRow';
import UserPreview from 'components/UserPreview';
import { isEqual, orderBy } from 'lodash';
import React from 'react';
import moment from 'moment';
import SText from 'components/Standard/SText';
import { APPEALS_STATUSES, APPELLATION_ACTIONS_TYPES } from 'core/utils/constants';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getQuestionsByIds } from 'redux/selectors/checklistItems/checklistItems';
import styled from 'styled-components';
import UploadedFile from 'components/Comments/Editor/UploadedFile';
import DOMPurify from 'dompurify';

const InlineCommentText = styled.span`
  && {
    overflow-wrap: break-word;

    p,
    span {
      margin-bottom: 0;
    }
  }
`;

const Action = ({ action }) => {
  const { t } = useTranslation();
  const questionsByIds = useSelector(getQuestionsByIds, isEqual);
  const appellationCommentsByIds = useSelector(
    state => state.appellationCommentsResource.byIds,
    isEqual
  );
  const uploadedFilesByIds = useSelector(state => state.uploadedFilesResource.byIds, isEqual);
  const actionBodyByType = {
    [APPELLATION_ACTIONS_TYPES.STATUS_CHANGED]: ({ action }) => (
      <SRow>
        <SCol>
          <SText type="secondary">{t('appealsPage.actionsList.changed')}</SText>
          <SText>{` ${t('appealsPage.actionsList.statusBy')} "${t(
            APPEALS_STATUSES[action.valueAfter.status].title
          )}"`}</SText>
        </SCol>
      </SRow>
    ),
    [APPELLATION_ACTIONS_TYPES.QUESTION_OBJECTION_APPROVED]: ({ action }) => (
      <SRow>
        <SCol>
          <SText type="secondary">{t('appealsPage.actionsList.accept')}</SText>
          <SText>{` ${t('appealsPage.actionsList.appealByCritery')} "${questionsByIds[
            action.questionId
          ]?.name || ''}"`}</SText>
        </SCol>
      </SRow>
    ),
    [APPELLATION_ACTIONS_TYPES.QUESTION_OBJECTION_REJECTED]: ({ action }) => (
      <SRow>
        <SCol>
          <SText type="secondary">{t('appealsPage.actionsList.reject')}</SText>
          <SText>{` ${t('appealsPage.actionsList.appealByCritery')} "${questionsByIds[
            action.questionId
          ]?.name || ''}"`}</SText>
        </SCol>
      </SRow>
    ),
    [APPELLATION_ACTIONS_TYPES.APPELLATION_CREATED]: ({ action }) => (
      <SRow>
        <SCol>
          <SText type="secondary">{t('appealsPage.actionsList.create')}</SText>
          <SText>{` ${t('appealsPage.actionsList.appeal')}`}</SText>
        </SCol>
      </SRow>
    ),
    [APPELLATION_ACTIONS_TYPES.WATCHER_ADDED]: ({ action }) => (
      <SRow gutter={[4, 0]}>
        <SCol>
          <SText type="secondary">{t('appealsPage.actionsList.add')}</SText>
          <SText>{` ${t('appealsPage.actionsList.wathcer')} `}</SText>
        </SCol>
        <SCol>
          <UserPreview disabled userId={action.watcherId} />
        </SCol>
      </SRow>
    ),
    [APPELLATION_ACTIONS_TYPES.WATCHER_REMOVED]: ({ action }) => (
      <SRow gutter={[4, 0]}>
        <SCol>
          <SText type="secondary">{t('appealsPage.actionsList.exclude')}</SText>
          <SText>{` ${t('appealsPage.actionsList.viewer')} `}</SText>
        </SCol>
        <SCol>
          <UserPreview disabled userId={action.watcherId} />
        </SCol>
      </SRow>
    ),
    [APPELLATION_ACTIONS_TYPES.GENERAL_APPELLATION_COMMENT_ADDED]: ({ action }) => (
      <SRow gutter={[4, 0]} style={{ marginBottom: '-4px' }}>
        <SCol>
          <InlineCommentText
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(appellationCommentsByIds[action?.commentId]?.text, {
                ALLOWED_ATTR: ['target', 'href']
              })
            }}
          />
        </SCol>
        <SCol span={24}>
          {appellationCommentsByIds[action?.commentId]?.uploadedFilesIds.map(id => (
            <UploadedFile
              style={{ maxWidth: '50%' }}
              uploadedFile={uploadedFilesByIds[id]}
              allFiles={appellationCommentsByIds[action?.commentId]?.uploadedFilesIds.map(
                id => uploadedFilesByIds[id]
              )}
            />
          ))}
        </SCol>
      </SRow>
    ),
    [APPELLATION_ACTIONS_TYPES.APPELLANT_COMMENT_REPLY_ADDED]: ({ action }) => (
      <SRow gutter={[4, 0]} style={{ marginBottom: '-4px' }}>
        <SCol>
          <InlineCommentText
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(appellationCommentsByIds[action?.commentId]?.text, {
                ALLOWED_ATTR: ['target', 'href']
              })
            }}
          />
        </SCol>
        <SCol span={24}>
          {appellationCommentsByIds[action?.commentId]?.uploadedFilesIds.map(id => (
            <UploadedFile
              uploadedFile={uploadedFilesByIds[id]}
              style={{ maxWidth: '50%' }}
              allFiles={appellationCommentsByIds[action?.commentId]?.uploadedFilesIds.map(
                id => uploadedFilesByIds[id]
              )}
            />
          ))}
        </SCol>
      </SRow>
    ),
    default: ({}) => <></>
  };

  const commentWord = {
    [APPELLATION_ACTIONS_TYPES.APPELLANT_COMMENT_REPLY_ADDED]: ` ${t(
      'appealsPage.actionsList.answered'
    )} `,
    [APPELLATION_ACTIONS_TYPES.GENERAL_APPELLATION_COMMENT_ADDED]: ` ${t(
      'appealsPage.actionsList.createComment'
    )} `
  };
  const Body = actionBodyByType[action.actionType] || actionBodyByType.default;
  const isCommentType = [
    APPELLATION_ACTIONS_TYPES.GENERAL_APPELLATION_COMMENT_ADDED,
    APPELLATION_ACTIONS_TYPES.APPELLANT_COMMENT_REPLY_ADDED
  ].includes(action.actionType);
  return (
    <SCol span={24} data-testid="appeal-action-item">
      <SRow gutter={[14, 0]}>
        <SCol>
          <UserPreview size="extraSmall" showAvatar disabled userId={action.creatorId} />
        </SCol>

        <SCol maxWidth="calc(100% - 150px)">
          {isCommentType ? (
            <SText type="secondary">{commentWord[action.actionType]}</SText>
          ) : (
            <Body action={action} />
          )}
        </SCol>

        <SCol>
          <SRow gutter={[12, 0]} flexWrap="nowrap">
            <SCol>
              <SText fontSize="12px">{moment(action.createdAt).format('DD/MM/YYYY')}</SText>
            </SCol>
            <SCol>
              <SText fontSize="12px" color="var(--black_6)">
                {moment(action.createdAt).format('HH:mm')}
              </SText>
            </SCol>
          </SRow>
        </SCol>

        {isCommentType && (
          <SCol span={24} paddingTop="6px">
            <Body action={action} />
          </SCol>
        )}
      </SRow>
    </SCol>
  );
};

const ActionsListContainer = ({ actionsByIds }) => {
  const actions = orderBy(Object.values(actionsByIds), 'createdAt');

  return (
    <SRow gutter={[0, 14]} style={{ marginBottom: '-7px' }}>
      {actions.map(action => (
        <Action action={action} key={action.id} />
      ))}
    </SRow>
  );
};

export default React.memo(ActionsListContainer, isEqual);
