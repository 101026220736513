import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import SRow from 'components/Standard/SRow';
import SCol from 'components/Standard/SCol';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { operations } from 'redux/lists/appellationsListReducer';
import { usersResource } from 'redux/resources/users';
import Filters from './Components/Filters';
import AppealsList from './Components/AppealsList';

const Appeals = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { page, sort, filters } = useSelector(state => state.appellationsList);

  useEffect(() => {
    dispatch(
      usersResource.operations.load({
        include: 'unit',
        pagination: 'false'
      })
    );
    dispatch(
      operations.load({
        page,
        filters,
        sort,
        include: 'review.reviewer,last_action,objections.question,appellant,assigned_to'
      })
    );
  }, [page, sort, filters]);

  return (
    <SRow>
      <Helmet>
        <title>{t('pagesMeta.appealsPage.title')}</title>
      </Helmet>
      <SCol span={24}>
        <SRow>
          <SCol span={24}>
            <Filters />
          </SCol>
          <SCol span={24} padding="16px">
            <AppealsList />
          </SCol>
        </SRow>
      </SCol>
    </SRow>
  );
};

export default Appeals;
