export default {
  organizationStructure: {
    moved: 'Moverse',
    moveToUnit: 'Mover al departamento',
    employeesList: 'Lista de empleados agregados',
    addEmployee: 'Agregar empleado',
    tabs: {
      units: {
        title: 'Estructura de la compañía',
        modal: {
          title: 'Añadir un departamento',
          ok: 'Añadir',
          cancel: 'Cancelar',
          form: {
            name: 'Nombre del departamento',
            namePlaceholder: 'Introduzca el nombre del departamento',
            messages: { errors: { enterName: 'Por favor introduzca el nombre del departamento' } }
          }
        },
        confirmDelete: {
          title: '¿Eliminar el departamento?',
          ok: 'Eliminar',
          cancel: 'Cancelar'
        }
      },
      employees: {
        title: 'Empleados',
        table: {
          columns: {
            name: 'Empleado',
            unit: 'Departamento',
            role: 'Papel',
            level: 'Nivel',
            access: 'Acceso',
            status: 'Estado'
          },
          filters: {
            totalCountEmployees: 'Cantidad de usuarios:',
            employeesSearch: 'Búsqueda',
            unitsIdsPlaceholder: 'Elija en departamento',
            unitsIdsMaxPlaceholder: 'Departamentos:',
            rolesIdsPlaceholder: 'Elija eun papel',
            rolesIdsMaxPlaceholder: 'Papel:',
            statusPlaceholder: 'Elija un estado',
            invitationStatusPlaceholder: 'Elija el nivel de acceso'
          },
          buttons: { addEmployee: 'Agragar usuario' },
          menu: {
            items: {
              activate: 'Activar',
              deactivate: 'Desactivar',
              sendInvite: 'Enviar invitación',
              editEmployee: 'Redactar',
              employeeProfile: 'Pasar al perfil'
            },
            messages: { invitationSended: 'Enviada la invitación' }
          }
        },
        modal: {
          title: 'Ajustes del usuario',
          buttons: {
            sendInvite: 'Enviar la invitación al sistema',
            cancel: 'Cancelar',
            save: 'Guardar'
          },
          messages: {
            employee: 'Usuario',
            created: 'agregado',
            updated: 'actualizado'
          },
          form: {
            fields: {
              lastName: 'Apellido',
              lastNamePlaceholder: 'Introduzca el apellido',
              firstName: 'Nombre y patronímico',
              firstNamePlaceholder: 'Introduzca el nombre y el patronímico',
              email: 'Correo electrónico',
              emailPlaceHolder: 'Introduzca el correo electrónico',
              integrationId: 'Id en el sistema a integrar',
              integrationIdPlaceholder: 'Introduzca el Id en el sistema a integrar',
              role: 'Papel',
              rolePlaceholder: 'Elija un papel',
              unit: 'Departamento',
              unitPlaceHolder: 'Elija un departamento'
            },
            messages: {
              enterLastName: 'Por favor introduzca su apellido',
              enterFirstName: 'Por favor introduzca su nombre y patronímico',
              enterEmail: 'Por favor introduzca su correo electrónico',
              enterValidEmail: 'El correo electrónbico no es correcto',
              enterRole: 'Por favor elija un papel',
              enterUnit: 'Por favor elija un departamento'
            }
          }
        },
        lastActivity: 'Última acción',
        accessLevels: {
          notInvited: 'No se ha enviado la invitación',
          invited: 'La invitación se ha enviado',
          loggedIn: 'Ha accedido',
          noAccess: 'No hay acceso',
          buttons: {
            setLevel: 'Establecer el nivel',
            resetLevel: 'Reiniciar el nivel'
          }
        }
      },
      roles: {
        title: 'Papeles y derechos de acceso',
        item: {
          buttons: {
            setting: 'Ajustar',
            delete: 'Eliminar',
            newRole: 'Nuevo papel',
            addRole: 'Añadir papel'
          },
          confirmDelete: '¿Eliminar el papel?',
          header: 'Nombre del papel'
        },
        modal: {
          title: 'Ajuste del derecho de acceso',
          buttons: {
            update: 'Actualizar',
            save: 'Guardar',
            cancel: 'Cancelar'
          },
          messages: {
            roleSuccessfullyUpdated: 'El papel se ha actualizado correctamente',
            updateRoleFailed: 'No se logró actualizar el papel',
            saveRoleFailed: 'No se logró guardar el papel',
            roleSuccessfullySaved: 'El papel ha sido creado'
          },
          form: {
            nameCategory: 'Nombre del papel',
            generalCategory: 'General',
            profileCategory: 'Perfil personal',
            analyticsCategory: 'Analítica, evaluación y retroalimentación',
            tasksCategory: 'Tareas',
            libraryCategory: 'Biblioteca de llamadas',
            administrateCategory: 'Administrar'
          }
        }
      },
      levels: {
        title: 'Niveles de empleados',
        item: {
          buttons: { setting: 'Ajustar los niveles' },
          header: 'Nombre del papel y del nivel'
        },
        modal: {
          title: 'Ajuste del nivel de los papeles',
          form: { namePlaceholder: 'Introduzca el nombre del nivel' },
          buttons: {
            save: 'Guardar',
            cancel: 'Cancelar',
            addLevel: 'Añadir nivel'
          },
          messages: {
            hasEmptyNames: 'Los nombres de los niveles no pueden estar vacios',
            hasSameNames: 'No puede guardar niveles con cel mismo nombre'
          }
        }
      },
      statuses: {
        title: 'Estado del contacto',
        form: {
          namePlaceholder: 'Introduzca el nombre del estado',
          empty: 'No se han creado estados',
          addStatus: 'Añadir estado',
          save: 'Guardar'
        }
      },
      notifications: {
        title: 'Notificaciones',
        table: {
          title: 'Ajuste de las notificiaciones para los papeles:',
          rolePlaceholder: 'Elija el papel',
          columns: {
            eventType: 'Tema de la notificación',
            emailNotification: 'Notificación por correo electrónico'
          },
          buttons: { setting: 'Ajustar' }
        },
        modal: {
          title: 'Ajeste de notificaciones:',
          isSendEmail: {
            title: '¿Enviar la notificación al correo electrónico?',
            true: 'Si',
            false: 'No'
          }
        }
      },
      connections: {
        title: 'Conexiones',
        table: {
          userHasNoIntegrations: 'Usuario no agregado a integraciones',
          deleteFrom: 'Eliminar de',
          users: 'Usuarios'
        },
        userAccounts: {
          userConnectedToIntegration: 'El usuario está conectado a la integración.',
          selectUserAccount: 'Seleccionar una cuenta',
          downloadingAccounts: 'Cargando cuentas',
          integrationHasNoAccounts: 'Esta integración no tiene cuentas para conectarse'
        }
      }
    }
  }
};
