import React from 'react';
import { connect } from 'react-redux';
import { Typography, Row, Col } from 'antd';
import { get, isEmpty, keyBy } from 'lodash';
import SCard from 'components/Standard/SCard';
import { ANALYTICS_WIDGETS_NAMES } from 'core/utils/constants';
import { getCurrentUser } from 'redux/selectors/users';
import { Info } from 'components/Info';
import { useTranslation } from 'react-i18next';
import STable from 'components/Standard/STable';
import tableColumns from './tableColumns';
import tableRows from './tableRows';

const UnitTable = ({
  units,
  organizationName,
  usersByIds,
  loading,
  widgets,
  filters,
  currentUserPermissions,
  currentUserId,
  checklistDefinitionsByIds
}) => {
  const { Title } = Typography;
  const { t } = useTranslation();

  const checklistColorZoneData = keyBy(
    get(
      widgets,
      `${ANALYTICS_WIDGETS_NAMES.CHECKLIST_DEFINITION_COLOR_ZONES_BY_OPERATORS}.current_period`,
      {}
    ),
    'id'
  );

  const previousChecklistColorZoneData = keyBy(
    get(
      widgets,
      `${ANALYTICS_WIDGETS_NAMES.CHECKLIST_DEFINITION_COLOR_ZONES_BY_OPERATORS}.previous_period`,
      {}
    ),
    'id'
  );

  const averageScoreData = keyBy(
    get(
      widgets,
      `${ANALYTICS_WIDGETS_NAMES.CHECKLIST_DEFINITION_AVERAGE_SCORE_BY_OPERATORS}.current_period`,
      {}
    ),
    'id'
  );

  const previousPeriodAverageScoreData = keyBy(
    get(
      widgets,
      `${ANALYTICS_WIDGETS_NAMES.CHECKLIST_DEFINITION_AVERAGE_SCORE_BY_OPERATORS}.previous_period`,
      {}
    ),
    'id'
  );

  const reviewsCountData = keyBy(
    get(widgets, `${ANALYTICS_WIDGETS_NAMES.REVIEWS_COUNT_BY_OPERATORS}.current_period`, {}),
    'id'
  );

  const previousReviewsCountData = keyBy(
    get(widgets, `${ANALYTICS_WIDGETS_NAMES.REVIEWS_COUNT_BY_OPERATORS}.previous_period`, {}),
    'id'
  );

  const ratingFlagsData = keyBy(
    get(widgets, `${ANALYTICS_WIDGETS_NAMES.RATING_FLAGS_COUNT_BY_OPERATORS}.current_period`, {}),
    'id'
  );

  return (
    <SCard loading={loading}>
      <Row type="flex" align="middle">
        <Col>
          <Title level={4} style={{ display: 'inline' }}>
            {organizationName}
          </Title>
          <Info
            tooltip={{
              title: t('teamPage.unitTable.infoIcons.info')
            }}
            iconProps={{ style: { margin: '0px 0px 8px 8px' } }}
          />
        </Col>
      </Row>
      <STable
        columns={tableColumns({
          currentUserPermissions,
          currentUserId,
          filters,
          checklistDefinitionsByIds
        })}
        dataSource={tableRows({
          checklistColorZoneData,
          previousChecklistColorZoneData,
          reviewsCountData,
          previousReviewsCountData,
          averageScoreData,
          previousPeriodAverageScoreData,
          usersByIds,
          units,
          ratingFlagsData,
          filters
        })}
        showSorterTooltip={false}
        pagination={false}
        tableLayout="fixed"
        fixSorterColumns
      />
    </SCard>
  );
};

const mapStateToProps = state => {
  const organizationName = state.reduxTokenAuth.currentUser.attributes.user.organization.name;
  const { widgets } = state.analyticsWidgets;
  const { filters } = state.uiTeamAnalyticsPage;
  const usersByIds = state.usersResource.byIds;
  const currentUser = getCurrentUser(state);
  const currentUserId = get(currentUser, 'id', '');
  const currentUserPermissions = get(currentUser, 'role.permissions', []);
  const checklistDefinitionsByIds = state.checklistDefinitionsResource.byIds;

  return {
    widgets,
    loading:
      state.checklistDefinitionsResource.loading || state.unitsResource.loading || isEmpty(widgets),
    units: Object.values(state.unitsResource.byIds),
    usersByIds,
    filters,
    currentUserPermissions,
    currentUserId,
    organizationName,
    checklistDefinitionsByIds
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(UnitTable);
