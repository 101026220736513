export default {
  appealsPage: {
    actionsListName: 'Change feed',
    actionsList: {
      changed: 'changed',
      accept: 'accepted',
      reject: 'rejected',
      create: 'filed',
      add: 'добавил',
      exclude: 'removed',
      answered: 'replied',
      createComment: 'left a comment to',
      statusBy: 'status on',
      appealByCritery: 'criterion appeal',
      appeal: 'appeal',
      wathcer: 'observer',
      viewer: 'watcher'
    },
    appealInfo: {
      operator: 'Оператор',
      reviewCreate: 'Evaluated'
    },
    appealStatus: {
      process: 'Process',
      needsInformation: 'Need information',
      withoutAnswer: 'No answer',
      appealInfo: 'Information on the appeal',
      notProcessed: 'Not processed'
    },
    participantsCard: {
      appealed: 'Submit an appeal',
      watchers: 'Watchers',
      addWatcher: 'Add watcher',
      addingWatcher: 'Adding watchers'
    },
    appealsList: {
      appealBy: 'Reviewer',
      changedBy: 'Changed'
    },
    filters: { withCurrentUserRoles: 'Appeals where am I' },
    settings: {
      appealDeadline: 'The maximum period after verification for filing an appeal (days)',
      addOperatorAsWatcher: 'Automatically assign operator as observer to appeal',
      addReviewerAsWatcher: 'Automatically appoint a reviewer as an observer on appeal',
      selectAssignerFromUsersArray: 'Schedule the filed appeal for:',
      selectAssignerFromUsersArrayAdd: 'Select a specific employee',
      appealed: 'Submit an appeal'
    },
    confirmDelete: 'Delete the appeal?',
    analytics: {
      noAnswer: 'No answer',
      pending: 'Not processed',
      tracker: 'Tracker',
      appellationsByReviewers: {
        title: 'Reviewer Appeals'
      },
      appellationsByAppellants: {
        title: 'Appeals by author'
      }
    }
  }
};
