import {
  AppstoreOutlined,
  BookOutlined,
  DashboardOutlined,
  FileDoneOutlined,
  SnippetsOutlined,
  TableOutlined,
  TagOutlined,
  TeamOutlined,
  ExceptionOutlined
} from '@ant-design/icons';
import { Menu } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Zap } from 'react-feather';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import Icon from 'components/Icon';
import WorkPlanTasksMenu from './WorkPlanTasksMenu';

import {
  CalibrationsMenuItem,
  ChecklistMenuItem,
  ClientInterationsMenuItem,
  DashboardMenuItem,
  LibraryMenuItem,
  RightSideItemsDivider,
  SettingsSubMenu,
  TaskDefinitionsMenuItem,
  TeamMenuItem,
  AppealsMenuItem,
  WorkPlanTaskConfigurationsMenuItem
} from './Components/MenuItems';

const StyledLink = styled(Link)`
  && {
    display: flex !important;
    align-items: center;
  }
`;

const AppMenu = () => {
  const location = useLocation();
  const { t } = useTranslation();

  return (
    <>
      <Menu
        theme="dark"
        mode="horizontal"
        style={{ height: '48px', display: 'flex', alignItems: 'center' }}
        selectedKeys={[location.pathname]}
      >
        <DashboardMenuItem key="/dashboards">
          <StyledLink to="/dashboards">
            <DashboardOutlined />
            <span>{t('menu.appMenu.analytics')}</span>
          </StyledLink>
        </DashboardMenuItem>

        <ClientInterationsMenuItem key="/client-interactions">
          <StyledLink to="/client-interactions">
            <TableOutlined />
            <span>{t('menu.appMenu.communications')}</span>
          </StyledLink>
        </ClientInterationsMenuItem>

        <TeamMenuItem key="/team">
          <StyledLink to="/team">
            <TeamOutlined />
            <span>{t('menu.appMenu.team')}</span>
          </StyledLink>
        </TeamMenuItem>

        <AppealsMenuItem key="/appeals">
          <StyledLink to="/appeals">
            <ExceptionOutlined />
            <span>{t('menu.appMenu.appeals')}</span>
          </StyledLink>
        </AppealsMenuItem>

        <LibraryMenuItem key="/library">
          <StyledLink to="/library">
            <BookOutlined />
            <span>{t('menu.appMenu.library')}</span>
          </StyledLink>
        </LibraryMenuItem>

        <RightSideItemsDivider />

        <WorkPlanTasksMenu />

        <SettingsSubMenu
          key="settings"
          title={
            <>
              <AppstoreOutlined />
              <span>{t('menu.appMenu.settings')}</span>
            </>
          }
        >
          <ChecklistMenuItem key="/checklists">
            <StyledLink to="/checklists">
              <FileDoneOutlined />
              <span>{t('menu.appMenu.checklistsSettins')}</span>
            </StyledLink>
          </ChecklistMenuItem>

          <TaskDefinitionsMenuItem key="/settings/triggers">
            <StyledLink to="/settings/triggers">
              <TagOutlined />
              <span>{t('menu.appMenu.tagsSettings')}</span>
            </StyledLink>
          </TaskDefinitionsMenuItem>

          <CalibrationsMenuItem key="/calibrations">
            <StyledLink to="/calibrations">
              <SnippetsOutlined />
              <span>{t('menu.appMenu.calibrations')}</span>
            </StyledLink>
          </CalibrationsMenuItem>

          <WorkPlanTaskConfigurationsMenuItem key="/work-plan/task-configurations">
            <StyledLink to="/work-plan/task-configurations">
              <Icon icon={Zap} />
              <span>{t('menu.appMenu.workPlanTaskConfigurations')}</span>
            </StyledLink>
          </WorkPlanTaskConfigurationsMenuItem>
        </SettingsSubMenu>
      </Menu>
    </>
  );
};

export default AppMenu;
