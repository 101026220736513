import { createSelector } from '@reduxjs/toolkit';
import { pickBy, some, keyBy, intersection, get, reduce } from 'lodash';
import { getRolesByIds } from './roles';
import { getUnitsByIds } from './units';

export const getUsersByIds = state => state.usersResource.byIds;

export const getUsersByUnitId = (state, { unitId }) =>
  pickBy(getUsersByIds(state), user => user.unitId === unitId);

export const getUsersByUnitIds = (state, { unitIds = Object.keys(state.unitsResource.byIds) }) =>
  pickBy(getUsersByIds(state), user => unitIds.includes(user.unitId));

export const getCurrentUser = state => {
  const { isLoading, attributes } = state.reduxTokenAuth.currentUser;
  return { ...attributes.user, id: attributes.id, isLoading };
};

export const getCurrentUserPermissions = state => {
  return get(getCurrentUser(state), 'role.permissions', []);
};

export const getUsersByRoleId = (state, { roleId }) =>
  pickBy(getUsersByIds(state), user => user.roleId === roleId);

export const getUsersByRoleIds = (state, { roleIds = Object.keys(state.rolesResource.byIds) }) =>
  pickBy(getUsersByIds(state), user => roleIds.includes(user.roleId));

export const getUsersWithPermissions = createSelector(
  [getUsersByIds, getRolesByIds, (_, { permissions = [] }) => permissions],
  (users, roles, permissions) =>
    pickBy(users, ({ roleId }) =>
      some(get(roles, `${roleId}.permissions`, {}), permission => permissions.includes(permission))
    )
);

export const getUsersByUnitIdWithPermissions = createSelector(
  [getUsersByUnitId, getUsersWithPermissions],
  (usersByUnit, usersByPermissions) =>
    keyBy(intersection(Object.values(usersByUnit), Object.values(usersByPermissions)), 'id')
);

export const getUsersByUnitIdsWithPermissions = createSelector(
  [getUsersByUnitIds, getUsersWithPermissions],
  (usersByUnits, usersByPermissions) =>
    keyBy(intersection(Object.values(usersByUnits), Object.values(usersByPermissions)), 'id')
);

export const getUsersWithRoleAndUnit = createSelector(
  [getUnitsByIds, getRolesByIds, getUsersByIds],
  (unitsByIds, rolesByIds, usersByIds) => {
    return Object.values(usersByIds).reduce((acc, user) => {
      const unit = unitsByIds[user.unitId];
      const role = rolesByIds[user.roleId];
      return { ...acc, [user.id]: { ...user, role, unit } };
    }, {});
  }
);

export const getUserWithRoleAndUnitById = createSelector(
  [getUnitsByIds, getRolesByIds, getUsersByIds, (_, userId) => userId],
  (unitsByIds, rolesByIds, usersByIds, userId) => {
    const user = usersByIds[userId];
    const unit = unitsByIds[user?.unitId];
    const role = rolesByIds[user?.roleId];
    return user ? { ...user, role, unit } : undefined;
  }
);

export const getUnitsWithActiveUsers = createSelector(
  getUnitsByIds,
  getUsersByIds,
  (unitsByIds, usersByIds) =>
    reduce(
      Object.values(unitsByIds),
      (res, unit) => {
        const users = unit.usersIds?.map(id => get(usersByIds, id, {}));

        return some(users, { active: true }) ? [...res, unit] : res;
      },
      []
    )
);

export const getActiveUsers = createSelector(getUsersByIds, usersByIds =>
  reduce(
    Object.values(usersByIds),
    (acc, user) => {
      if (!user?.active) return acc;
      return [...acc, user];
    },
    []
  )
);
