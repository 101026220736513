import { CloseOutlined } from '@ant-design/icons';
import { Button, Drawer, Modal, Skeleton } from 'antd';
import { CHECKLIST_MANAGER_STATES, COMMUNICATION_TYPES } from 'core/utils/constants';
import { isEmpty, isEqual } from 'lodash';
import CallPage from 'pages/ClientInteractionPage/CallPage';
import ReviewPage from 'pages/ClientInteractionPage/ReviewPage';
import TextCommunicationPage from 'pages/ClientInteractionPage/TextCommunicationPage';
import CustomCommunicationReviewPage from 'pages/CustomCommunicationReviewPage';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCommunication } from 'redux/ui/clientInteractionDrawer/reducer';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import SCard from 'components/Standard/SCard';

const typeToPage = {
  [COMMUNICATION_TYPES.PHONE_CALL]: CallPage,
  review: ReviewPage,
  [COMMUNICATION_TYPES.TEXT]: TextCommunicationPage,
  [COMMUNICATION_TYPES.CUSTOM]: CustomCommunicationReviewPage
};

const fixScroll = visible => {
  const body = document.querySelector('body');

  if (visible) {
    body.classList.add('ant-scrolling-effect');
    body.style =
      'position: relative; width: calc(100% - 17px); overflow: hidden; touch-action: none;';
  } else {
    body.style = 'touch-action: none;';
    body.classList.remove('ant-scrolling-effect');
  }
};

const bodyStyle = {
  background: 'var(--gray-background)',
  padding: '8px',
  marginLeft: '40px',
  minHeight: '100%',
  overflowX: 'hidden'
};

const drawerStyle = { background: 'transparent' };

const headerStyle = { display: 'none' };

const affixTarget = () => {
  // TODO: optimize with memo
  return document.querySelector('.ant-drawer-wrapper-body > .ant-row') || window;
};

const StyledDrawer = styled(Drawer)`
  &&.ant-drawer-right.ant-drawer-open .ant-drawer-content-wrapper {
    box-shadow: none;
  }
  && .ant-drawer-content {
    background: transparent;
  }
`;
const CloseButton = styled(Button)`
  &&.ant-btn {
    position: absolute;
    top: 96px;
    left: 0px;
    line-height: 0.5;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
`;

// TODO: should be moved to ui reducers folders
const getChecklistManagerState = state => state.uiChecklistManager.checklistManagerState;

const getCommunication = state => state.uiClientInteractionDrawer.communication;

const ClientInteractionDrawer = ({ shouldLoad = false }) => {
  const { t } = useTranslation();
  const [showingContent, setShowingContent] = useState(false);
  const dispatch = useDispatch();

  const communication = useSelector(
    getCommunication,
    (next, prev) => isEqual(next, prev) && !next?.loading
  );
  const checklistManagerState = useSelector(getChecklistManagerState, isEqual);

  const isAddingComment = useSelector(state => state.uiClientInteractionPage.isAddingComment);
  const editingCommentId = useSelector(state => state.uiClientInteractionPage.editingCommentId);
  const selectedComment = useSelector(state => state.uiComments.selectedComment);

  const { id, type } = communication;

  const visible = !isEmpty(communication);

  useEffect(() => {
    fixScroll(visible);
  }, [visible]);

  const Page = typeToPage[type];

  const closeDrawer = useCallback(() => {
    if (checklistManagerState === CHECKLIST_MANAGER_STATES.EDITING) {
      return Modal.confirm({
        onOk: () => {
          Modal.destroyAll();
          dispatch(setCommunication({}));
          setShowingContent(false);
        },
        title: t('clientInteractionPage.messages.closeDrawer.title'),
        content: t('clientInteractionPage.messages.closeDrawer.content')
      });
    }

    setShowingContent(false);
    return dispatch(setCommunication({}));
  }, [dispatch, checklistManagerState]);

  return (
    <StyledDrawer
      placement="right"
      width="calc(90% + 40px)"
      closable
      onClose={closeDrawer}
      afterVisibleChange={setShowingContent}
      visible={visible}
      headerStyle={headerStyle}
      bodyStyle={bodyStyle}
      drawerStyle={drawerStyle}
      keyboard={!isAddingComment && !editingCommentId && !selectedComment}
      // destroyOnClose
    >
      <CloseButton icon={<CloseOutlined />} size="large" type="primary" onClick={closeDrawer} />
      {showingContent && Page ? (
        <Page id={id} shouldLoad={shouldLoad} fromDrawer affixTarget={affixTarget} />
      ) : (
        <SCard>
          <Skeleton active />
        </SCard>
      )}
    </StyledDrawer>
  );
};

export default ClientInteractionDrawer;
