export default {
  organizationStructure: {
    moved: 'Move',
    moveToUnit: 'Move to unit',
    employeesList: 'List of added employees',
    addEmployee: 'Add employee',
    tabs: {
      units: {
        title: 'Company structure',
        modal: {
          title: 'Add department',
          ok: 'Add',
          cancel: 'Cancel',
          form: {
            name: 'Department name',
            namePlaceholder: 'Enter department name',
            messages: { errors: { enterName: 'Please enter department name' } }
          }
        },
        confirmDelete: {
          title: 'Delete department?',
          ok: 'Delete',
          cancel: 'Cancel'
        }
      },
      employees: {
        title: 'Employees',
        table: {
          columns: {
            name: 'Employee',
            unit: 'Department',
            role: 'Role',
            level: 'Level',
            access: 'Access',
            status: 'Status'
          },
          filters: {
            totalCountEmployees: 'Total number of users:',
            employeesSearch: 'Search',
            unitsIdsPlaceholder: 'Choose department',
            unitsIdsMaxPlaceholder: 'Departments:',
            rolesIdsPlaceholder: 'Choose role',
            rolesIdsMaxPlaceholder: 'Roles:',
            statusPlaceholder: 'Choose status',
            invitationStatusPlaceholder: 'Choose access level'
          },
          buttons: { addEmployee: 'Add user' },
          menu: {
            items: {
              activate: 'Activate',
              deactivate: 'Deactivate',
              sendInvite: 'Send invite',
              editEmployee: 'Edit',
              employeeProfile: 'Go to profile'
            },
            messages: { invitationSended: 'Invite sent' }
          }
        },
        modal: {
          title: 'User settings',
          buttons: {
            sendInvite: 'Send invitation to the system',
            cancel: 'Cancel',
            save: 'Save'
          },
          messages: {
            employee: 'User',
            created: 'added',
            updated: 'updated'
          },
          form: {
            fields: {
              lastName: 'Last name',
              lastNamePlaceholder: 'Enter last name',
              firstName: 'First name',
              firstNamePlaceholder: 'Enter first name',
              email: 'E-mail',
              emailPlaceHolder: 'Enter e-mail',
              integrationId: 'Integrated system id',
              integrationIdPlaceholder: 'Enter integrated system id',
              role: 'Role',
              rolePlaceholder: 'Choose role',
              unit: 'Department',
              unitPlaceHolder: 'Choose department'
            },
            messages: {
              enterLastName: 'Please enter last name',
              enterFirstName: 'Please enter first name',
              enterEmail: 'Please enter email',
              enterValidEmail: 'Please enter valid email',
              enterRole: 'Please choose role',
              enterUnit: 'Please choose department'
            }
          }
        },
        lastActivity: 'Last activity',
        accessLevels: {
          notInvited: 'Invite not sent',
          invited: 'Invite sent',
          loggedIn: 'Logged in',
          noAccess: 'No access',
          buttons: {
            setLevel: 'Set level',
            resetLevel: 'Reset level'
          }
        }
      },
      roles: {
        title: 'Roles and access rights',
        item: {
          buttons: {
            setting: 'Set',
            delete: 'Delete',
            newRole: 'New role',
            addRole: 'Add role'
          },
          confirmDelete: 'Delete role?',
          header: 'Role name'
        },
        modal: {
          title: 'Access rights setting',
          buttons: {
            update: 'Update',
            save: 'Save',
            cancel: 'Cancel'
          },
          messages: {
            roleSuccessfullyUpdated: 'Role successfully updated',
            updateRoleFailed: 'Failed to update role',
            saveRoleFailed: 'Failed to save role',
            roleSuccessfullySaved: 'Role successfully created'
          },
          form: {
            nameCategory: 'Role name',
            generalCategory: 'General',
            profileCategory: 'Profile',
            analyticsCategory: 'Analytics, evaluation and feedback',
            tasksCategory: 'Tasks',
            libraryCategory: 'Library',
            administrateCategory: 'Administration'
          }
        }
      },
      levels: {
        title: 'Employee levels',
        item: {
          buttons: { setting: 'Set levels' },
          header: 'Role name and levels'
        },
        modal: {
          title: 'Role levels setting',
          form: { namePlaceholder: 'Enter level name' },
          buttons: {
            save: 'Save',
            cancel: 'Cancel',
            addLevel: 'Add level'
          },
          messages: {
            hasEmptyNames: 'Names of levels cannot be blank',
            hasSameNames: 'Cannot save levels with the same name'
          }
        }
      },
      statuses: {
        title: 'Contacts status',
        form: {
          namePlaceholder: 'Status name',
          empty: 'No contact statuses created',
          addStatus: 'Add contact status',
          save: 'Save'
        }
      },
      notifications: {
        title: 'Notifications',
        table: {
          title: 'Notification setting for a role:',
          rolePlaceholder: 'Choose role',
          columns: {
            eventType: 'Notification Subject',
            emailNotification: 'Notification via email'
          },
          buttons: { setting: 'Set' }
        },
        modal: {
          title: 'Notification setting:',
          isSendEmail: {
            title: 'Send notification via email?',
            true: 'Yes',
            false: 'No'
          }
        }
      },
      connections: {
        title: 'Connections',
        table: {
          userHasNoIntegrations: 'This user has no integrations',
          deleteFrom: 'Delete from',
          users: 'Users'
        },
        userAccounts: {
          userConnectedToIntegration: 'User connected to integration',
          selectUserAccount: 'Select user account',
          downloadingAccounts: 'Downloading user accounts',
          integrationHasNoAccounts: 'This integration has no user accounts'
        }
      }
    }
  }
};
