import { Select } from 'antd';
import SCol from 'components/Standard/SCol';
import SInput from 'components/Standard/SInput';
import SRow from 'components/Standard/SRow';
import SSelect from 'components/Standard/SSelect';
import SText from 'components/Standard/SText';
import UserPreview from 'components/UserPreview';
import { CLIENT_INTERACTION_TYPES } from 'core/utils/constants';
import { get, reduce, sum, uniq } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const DISTRIBUTION_TYPE = {
  MANUAL: { type: 'manual', title: 'workPlanTaskConfigurationEditor.distribution.manual' },
  AUTO: { type: 'auto', title: 'workPlanTaskConfigurationEditor.distribution.auto' }
};

const { Option } = Select;

const distributeCommunications = function*(clientInteractionsMaxCount, reviewersIds) {
  const divider = reviewersIds.length;

  let rest = clientInteractionsMaxCount % divider;
  const result = clientInteractionsMaxCount / divider;

  for (let i = 0; i < divider; i++) {
    if (rest-- > 0) {
      yield { userId: reviewersIds[i], value: Math.ceil(result) };
    } else {
      yield { userId: reviewersIds[i], value: Math.floor(result) };
    }
  }
};

const ReviewsWithDistribution = ({
  clientInteractionsTypesConfigs,
  reviewersIds,
  onChange,
  distribution
}) => {
  const { t } = useTranslation();
  // * число распределенных по юзерам коммуникаций
  const [distributedByClientInteractionType, setDistributedByClientInteractionType] = useState(
    distribution || {}
  );
  //  * тип распределения - ручной/автомат
  const [distributionType, setDistributionType] = useState(DISTRIBUTION_TYPE.AUTO.type);

  // * максимум коммуникаций по типу коммуникаций

  // * получить распределение по юзерам исходя из максимума
  const getDistributionByUserIds = () => {
    const distributionByUsersIds = {};
    for (let result of distributeCommunications(100, reviewersIds)) {
      distributionByUsersIds[result.userId] = result.value;
    }
    return distributionByUsersIds;
  };

  // * типы коммуникаций для распределения
  const clientInteractionTypes = uniq(
    Object.values(clientInteractionsTypesConfigs).map(
      communicationTypeFilters => communicationTypeFilters?.clientInteractionType
    )
  );

  const getClientInteractionTypeValidation = clientInteractionType =>
    get(distributedByClientInteractionType, [clientInteractionType, 'totalDistributed'], 100) !==
    100;

  // * подсчёт результата распределения по типу коммуникации
  const getTotalClientInteractionTypeDistribution = clientInteractionType => {
    const invalid = getClientInteractionTypeValidation(clientInteractionType);
    return (
      <>
        <SText>
          {`${t(CLIENT_INTERACTION_TYPES[clientInteractionType.toUpperCase()].title)}: `}
        </SText>
        <SText color={invalid ? 'var(--red_primary)' : undefined}>
          {distributedByClientInteractionType[clientInteractionType]?.totalDistributed}
        </SText>
        <SText>/100%</SText>
      </>
    );
  };

  const calcAndSetDistributions = () => {
    const distributionMatrix = reduce(
      clientInteractionTypes,
      (acc, clientInteractionType) => {
        const distributionByUserIds = getDistributionByUserIds();

        return {
          ...acc,
          [clientInteractionType]: {
            byUserIds: distributionByUserIds,
            totalDistributed: sum(Object.values(distributionByUserIds))
          }
        };
      },
      {}
    );
    setDistributedByClientInteractionType(distributionMatrix);
    onChange(distributionMatrix);
  };

  useEffect(() => {
    setDistributionType(DISTRIBUTION_TYPE.AUTO.type);
    calcAndSetDistributions();
  }, []);

  useEffect(() => {
    if (distributionType === DISTRIBUTION_TYPE.AUTO.type) calcAndSetDistributions();
  }, [reviewersIds]);

  useEffect(() => {
    if (distributionType === DISTRIBUTION_TYPE.AUTO.type) {
      return calcAndSetDistributions();
    }
  }, [distributionType]);

  const updateManuallyDistribution = ({ value, clientInteractionType, userId }) => {
    setDistributionType(DISTRIBUTION_TYPE.MANUAL.type);
    const data = { ...distributedByClientInteractionType[clientInteractionType] };
    data.byUserIds[userId] = value;
    data.totalDistributed = sum(Object.values(data.byUserIds));
    const result = {
      ...distributedByClientInteractionType,
      [clientInteractionType]: data
    };
    setDistributedByClientInteractionType(result);
    onChange(result);
  };

  const getDistributionInputHandler = (clientInteractionType, userId) => e =>
    updateManuallyDistribution({
      clientInteractionType,
      userId,
      value: parseInt(e.target.value || 0)
    });

  const getDistributionValue = (clientInteractionType, userId) =>
    get(distributedByClientInteractionType, [clientInteractionType, 'byUserIds', userId], 0);

  return (
    <SRow>
      <SCol span={24}>
        <SRow gutter={[0, 16]} style={{ marginBottom: '-8px' }}>
          <SCol span={24}>
            <SRow justify="space-between">
              <SCol>
                <SRow align="middle" gutter={[20, 0]}>
                  <SCol>
                    <SText fontSize="20px" fontWeight="500">
                      {t('workPlanTaskConfigurationEditor.distribution.reviewers')}
                    </SText>
                  </SCol>
                  <SCol>
                    <SSelect width="200px" value={distributionType} onChange={setDistributionType}>
                      {Object.values(DISTRIBUTION_TYPE).map(({ type, title }) => (
                        <Option value={type}>{t(title)}</Option>
                      ))}
                    </SSelect>
                  </SCol>
                </SRow>
              </SCol>

              <SCol>
                <SRow gutter={[24, 0]}>
                  {clientInteractionTypes.map(type => (
                    <SCol width="196px">
                      <SText>{getTotalClientInteractionTypeDistribution(type)}</SText>
                    </SCol>
                  ))}
                </SRow>
              </SCol>
            </SRow>
          </SCol>

          <SCol span={24}>
            <SRow gutter={[0, 22]} style={{ marginBottom: '-12px' }}>
              {reviewersIds.map(userId => {
                return (
                  <SCol span={24}>
                    <SRow align="middle" justify="space-between">
                      <SCol>
                        <UserPreview userId={userId} disabled showAvatar />
                      </SCol>

                      <SCol>
                        <SRow align="middle" gutter={[24, 0]}>
                          {clientInteractionTypes.map(type => (
                            <SCol>
                              {/* ant-form-item-has-error */}
                              <SInput
                                width="196px"
                                type="number"
                                min={0}
                                {...(getClientInteractionTypeValidation(type)
                                  ? {
                                      borderColor: 'var(--red_primary)',
                                      outline: 'none',
                                      boxShadow: '0 0 0 2px rgba(245, 34, 45, 0.2)'
                                    }
                                  : {})}
                                onChange={getDistributionInputHandler(type, userId)}
                                value={getDistributionValue(type, userId)}
                              />
                            </SCol>
                          ))}
                        </SRow>
                      </SCol>
                    </SRow>
                  </SCol>
                );
              })}
            </SRow>
          </SCol>
        </SRow>
      </SCol>
    </SRow>
  );
};

export default ReviewsWithDistribution;
