import { setLoadingWidgetId } from 'redux/entities/dashboardPages/reducer';
import { updateDashboardWidget } from 'redux/ui/widgetSettingsModal/operations';

export const getOrderString = ({ orderString }) => {
  if (orderString === 'asc') return 'ascend';
  if (orderString === 'desc') return 'descend';
};

export const onTableChange = async ({
  pagination,
  sorter,
  widgetId,
  tableColumns,
  filters,
  dispatch
}) => {
  // setInitialState({
  //   number: pagination?.current,
  //   size: pagination?.pageSize
  // });

  const columnsKeys = tableColumns.map(item => item.key);
  const { columnKey } = sorter;

  const isNewSort = columnsKeys.includes(columnKey);

  const getSorterString = () =>
    sorter?.order === 'ascend' ? sorter?.columnKey : `-${sorter?.columnKey}`;

  dispatch(setLoadingWidgetId(widgetId));
  await dispatch(
    updateDashboardWidget({
      id: widgetId,
      filters,
      page: {
        number: pagination.current,
        size: pagination.pageSize - 1
      },
      sort: isNewSort ? getSorterString() : ''
    })
  );
  dispatch(setLoadingWidgetId());
};

export const getColorizedColumn = ({ color, component }) => {
  return {
    props: {
      style: {
        background: color,
        border: '2px solid white',
        borderRadius: '4px',
        padding: '5px'
      }
    },
    children: component
  };
};
