import api from 'core/api';
import React from 'react';
import { Trans } from 'react-i18next';
import decamelize from 'decamelize-keys-deep';
import { processJsonApiObject } from 'core/jsonapi';
import { message } from 'antd';
import * as actions from './reducer';

export const getAppellationsSettings = () => {
  return async dispatch => {
    dispatch(actions.startloading());
    try {
      const response = await api.getAppellationsSettings();
      const settings = processJsonApiObject(response.body.data);
      dispatch(actions.settingsLoaded(settings));
      return settings;
    } catch (error) {
      console.log(error);
      dispatch(actions.catchError(error));
    }
  };
};

export const updateAppellationsSettings = ({ ...params }) => {
  return async dispatch => {
    try {
      const response = await api.updateAppellationsSettings(decamelize(params));
      const newSettings = processJsonApiObject(response.body.data);
      if (newSettings) {
        message.success(<Trans i18nKey="messages.success.settingsUpdated" />);
        dispatch(actions.settingsUpdated(newSettings));
      }
      return newSettings;
    } catch (error) {
      console.log(error);
      message.warning(<Trans i18nKey="messages.warning.failedSettingsUpdate" />);
      dispatch(actions.catchError(error));
    }
  };
};
