export default {
  teamPage: {
    unitTable: {
      empty: 'Нет данных',
      infoIcons: {
        info: 'В данной таблице указана сводная аналитика по всем оценкам',
        scoreInfo: 'Средний балл среди всех оценок, по выбранной форме оценки',
        reviewsCountInfo: 'Количество всех проверок, по выбранным формам оценки',
        greenZoneCountInfo: 'Процент проверок, попавших в зеленую зону по выбранным формам оценки',
        allScoresInfo: 'Все оценки в разрезе цветовых зон по выбранным формам оценки'
      },
      columns: {
        employee: 'Сотрудник',
        unit: 'Отдел',
        score: 'Балл',
        reviewsCount: 'Проверки',
        greenZoneCount: 'Отлично',
        allScores: 'Все оценки',
        flags: 'Флаги'
      },
      differenceArrowScoreLiteral: 'балл'
    },
    buttons: {
      exportTeamPageAnalytics: 'Экспорт в Excel',
      units: 'Отделы',
      employees: 'Сотрудники'
    },
    pageFilters: {
      exportModal: {
        title: 'Аналитика экспортирована',
        description: 'Результат экспорта придет на вашу почту через несколько минут'
      },
      messages: {
        exportTableFailed: 'Не удалось экспортировать аналитику'
      },
      filters: {
        unitPlaceholder: 'Выберите отдел',
        unitPlaceholderMax: 'Выбрано',
        unitPlaceholderMax2: 'отдела',
        checklistPlaceholder: 'Выберите форму оценки'
      }
    }
  }
};
