import { Card, Col, Divider, InputNumber, Radio, Row } from 'antd';
import styled from 'styled-components';

export const StyledRadioGroup = styled(Radio.Group)`
  &.ant-radio-group {
    display: flex;
    flex-direction: column;
  }
`;

export const StyledRow = styled(Row)`
  &.ant-row {
    margin-bottom: 24px !important;
  }
`;

export const StyledDivider = styled(Divider)`
  &.ant-divider {
    margin: 12px 0 8px 0;
  }
`;

export const SelectChecklistCol = styled(Col)`
  &.ant-col {
    margin-bottom: 6px;
  }
`;

export const StyledCard = styled(Card)`
  &.ant-card {
    margin: 4px 0;
    border-radius: 4px;
    background-color: ${props => props.color};
  }
  &.ant-card > .ant-card-body {
    padding: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const RateInput = styled(InputNumber)`
  width: 63px !important;
  margin: 0 5px !important;

  .ant-input-number-handler-wrap {
    opacity: 1 !important;
  }
`;

export const RateCol = styled(Col)`
  &.ant-col {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
`;

export const ChecklistCol = styled(Col)`
  &.ant-col {
    margin-top: 8px;
  }
`;

export const DescriptionDiv = styled.div`
  margin-bottom: 16px;
  font-weight: 500;
`;

export const EmptyChecklist = styled(Card)`
  &.ant-card {
    margin-top: 16px;
    border: none;
  }
`;

export const StyledEditTaskDefinitionCard = styled(Card)`
  &.ant-card {
    border: none;
  }

  &.ant-card > .ant-card-body {
    padding: 16px;
  }
`;

export const StyledCreateTaskDefinitionCard = styled(Card)`
  &.ant-card {
    height: 11.4em;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 4px;
  }

  &.ant-card-hoverable:hover {
    box-shadow: none;
  }

  &.ant-card > .ant-card-body > span {
    font-weight: 500;
    margin-left: 8px;
  }
`;

export const StyledTaskDefinitionCard = styled(Card)`
  &.ant-card {
    height: 11.4em;
    border: none;
    border-radius: 4px;
  }

  &.ant-card > .ant-card-actions {
    border-radius: 0 0 4px 4px;
  }
`;
