import { Col, Radio, Row, Tooltip } from 'antd';
import { WIDGET_VIEW_MODES } from 'core/utils/constants';
import { get, sum, isEqual } from 'lodash';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateDashboardWidget } from 'redux/ui/widgetSettingsModal/operations';
import { useTranslation } from 'react-i18next';
import EmptyWidget from '../../Components/EmptyWidget';
import SettingsModal from '../../Components/SettingsModal';
import { LargeWidgetCard, SettingsButtonCol, TimePeriod, WidgetNameCol } from '../../styled';
import WidgetCardDivider from '../../Components/WidgetCardDivider';
import {
  getDateSelectorType,
  widgetInfoTextSetter,
  widgetNameSetter,
  widgetPeriodSetter
} from '../../utils';
import CommentsTable from './Components/CommentsTable';
import PieChart from './Components/PieChart';

const FlagsAndComments = ({ widgetData, loadingAnalytics, loadingWidgetId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id, type, filters, name } = widgetData;
  const widgetValue = useSelector(state => state.dashboardAnalytics.byIds[id], isEqual);
  const viewMode = get(filters, 'viewMode', WIDGET_VIEW_MODES.OPERATORS.value);
  const dateSelectorType = getDateSelectorType({ widgetType: type });

  const ratingFlagsCount = get(widgetValue, 'rating_flags_count', []);
  const ratingFlagsCountByUnits = get(widgetValue, 'rating_flags_count_by_units', []);
  const ratingFlagsCountByOperators = get(widgetValue, 'rating_flags_count_by_operators', []);

  const tableData =
    viewMode === WIDGET_VIEW_MODES.OPERATORS.value
      ? ratingFlagsCountByOperators
      : ratingFlagsCountByUnits;

  const pieData = ratingFlagsCount;

  const isEmptyComments = sum(Object.values(ratingFlagsCount)) < 1;

  return (
    <>
      {loadingWidgetId === id || isEmptyComments ? (
        <EmptyWidget
          key={id}
          widgetData={widgetData}
          loadingAnalytics={loadingAnalytics}
          loadingWidgetId={loadingWidgetId}
        />
      ) : (
        <Col span={24}>
          <LargeWidgetCard>
            <Row type="flex" justify="space-between" align="top">
              <WidgetNameCol span={8}>
                <Row type="flex" gutter={[6, 0]} justify="start">
                  <Col>{widgetInfoTextSetter({ type })}</Col>
                  <Col>
                    <Tooltip title={widgetNameSetter({ name, type })}>
                      <span>{widgetNameSetter({ name, type })}</span>
                    </Tooltip>
                  </Col>
                </Row>

                <TimePeriod type="secondary">
                  {widgetPeriodSetter(filters, dateSelectorType)}
                </TimePeriod>
              </WidgetNameCol>
              <Col span={6}>
                <Row justify="center">
                  <Col>
                    <Radio.Group
                      onChange={e =>
                        dispatch(
                          updateDashboardWidget({
                            id,
                            type,
                            filters: { ...filters, viewMode: e.target.value }
                          })
                        )
                      }
                      value={viewMode}
                    >
                      <Radio.Button value={WIDGET_VIEW_MODES.OPERATORS.value}>
                        {t(WIDGET_VIEW_MODES.OPERATORS.title)}
                      </Radio.Button>
                      <Radio.Button value={WIDGET_VIEW_MODES.UNITS.value}>
                        {t(WIDGET_VIEW_MODES.UNITS.title)}
                      </Radio.Button>
                    </Radio.Group>
                  </Col>
                </Row>
              </Col>
              <SettingsButtonCol span={8}>
                <Row type="flex" align="start" gutter={[12, 0]}>
                  <Col>
                    <SettingsModal widgetData={widgetData} />
                  </Col>
                </Row>
              </SettingsButtonCol>
            </Row>
            <Row type="flex" justify="space-between">
              <Col span={9}>
                <PieChart pieData={pieData} viewMode={viewMode} filters={filters} />
              </Col>
              <Col span={1}>
                <WidgetCardDivider />
              </Col>
              <Col span={14}>
                <CommentsTable tableData={tableData} viewMode={viewMode} filters={filters} />
              </Col>
            </Row>
          </LargeWidgetCard>
        </Col>
      )}
    </>
  );
};

export default FlagsAndComments;
