import Icon from 'components/Icon';
import SButton from 'components/Standard/SButton';
import SCard from 'components/Standard/SCard';
import SCol from 'components/Standard/SCol';
import SRow from 'components/Standard/SRow';
import { CLIENT_INTERACTION_TYPES } from 'core/utils/constants';
import { omit, orderBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Plus } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { customFieldsResource } from 'redux/resources/customFields';
import { rolesResource } from 'redux/resources/roles';
import { statusesResource } from 'redux/resources/status';
import { unitsResource } from 'redux/resources/units';
import uniqid from 'uniqid';
import ClientInteractionFilters from './ClientInteractionFilters';

const defaultClientInteractions = {};

const FiltersSettings = ({ onChange, clientInteractionsConfigs = defaultClientInteractions }) => {
  const { t } = useTranslation();
  const [clientInteractions, setClientInteractions] = useState(clientInteractionsConfigs);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(rolesResource.operations.load({ include: 'levels' }));
    dispatch(statusesResource.operations.load());
    dispatch(customFieldsResource.operations.load());
    dispatch(unitsResource.operations.load());
    dispatch(statusesResource.operations.load());
  }, []);

  const addClientInteraction = () => {
    const id = uniqid();
    const data = {
      ...clientInteractions,
      [id]: {
        id,
        position: Object.values(clientInteractions).length + 1,
        clientInteractionType: CLIENT_INTERACTION_TYPES.PHONE_CALL.value,
        clientInteractionsMaxCount: 0,
        fields: []
      }
    };

    setClientInteractions(data);
    onChange(data);
  };

  const onClientInteractionUpdate = communcation => {
    const data = {
      ...clientInteractions,
      [communcation.id]: { ...clientInteractions[communcation.id], ...communcation }
    };
    setClientInteractions(data);
    onChange(data);
  };

  const onDeleteClientInteraction = id => {
    setClientInteractions(omit(clientInteractions, id));
    onChange(omit(clientInteractions, id));
  };

  return (
    <SRow gutter={[0, 12]} style={{ marginBottom: '-6px' }}>
      {/* <SCol span={24}>
        <Title level={4}>Настройки фильтра</Title>
      </SCol> */}
      <SCol span={24}>
        <SCard bordered shadowed width="100%">
          {orderBy(Object.values(clientInteractions), 'position').map(clientInteractionType => (
            <ClientInteractionFilters
              clientInteractionType={clientInteractionType}
              fields={clientInteractionType.fields}
              onChange={onClientInteractionUpdate}
              onDelete={onDeleteClientInteraction}
            />
          ))}
          <SButton
            type="link"
            padding="0"
            icon={<Icon icon={Plus} />}
            onClick={addClientInteraction}
          >
            {t('workPlanTaskConfigurationEditor.filters.addFilters')}
          </SButton>
        </SCard>
      </SCol>
    </SRow>
  );
};

export default FiltersSettings;
