import { Col, Modal, Row, Typography } from 'antd';
import { isEmpty, isEqual } from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import BackgroundJob from 'pages/BackgroundJob';
import { useSelector, useDispatch } from 'react-redux';
import { Switch, useRouteMatch, Route, useHistory, Redirect, useParams } from 'react-router-dom';
import catWithSpinner from 'assets/video/cat_with_spinner.mp4';
import { integrationsResource } from 'redux/resources/integrations';
import ManageCustomUserAccounts from '../IntegrationSettingsModules/ManageCustomUserAccounts';

const { Title } = Typography;

const CustomIntegrationPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { path } = useRouteMatch();
  const { id: userId, integrationId } = useParams();
  const dispatch = useDispatch();

  const integration = useSelector(
    state => state.integrationsResource.byIds[integrationId] || { id: integrationId },
    isEqual
  );

  const currentBackgroundJob = useSelector(
    state => state.backgroundJob.currentBackgroundJob,
    isEqual
  );

  const handleSelectAccounts = () => {
    history.push(
      `/user/${userId}/integrations-settings/${integration.integrationType}/${integrationId}`
    );
  };

  useEffect(() => {
    if (!integration?.integrationType) {
      dispatch(integrationsResource.operations.loadById({ id: integrationId }));
    }
  }, [integration?.id]);

  return (
    <Row>
      <Col span={24}>
        <Switch>
          <Route path={`${path}/users`}>
            <Row gutter={[0, 20]} style={{ marginBottom: '-10px' }}>
              <Col span={24}>
                <Title level={4}>
                  {t('integrationsSettingsPage.createCustomIntegration.addUsersTitle')}
                </Title>
              </Col>
              <Col span={24}>
                <ManageCustomUserAccounts
                  onOk={handleSelectAccounts}
                  integration={integration}
                  okText={t('general.continue')}
                />
              </Col>
            </Row>
          </Route>

          <Redirect exact from={`${path}`} to={`${path}/users`} />
        </Switch>
      </Col>
      <Modal
        visible={!isEmpty(currentBackgroundJob)}
        footer={null}
        closable={false}
        maskClosable={false}
      >
        {currentBackgroundJob && (
          <BackgroundJob
            text={
              <video autoPlay loop muted playsinline>
                <source src={catWithSpinner} type="video/mp4" />
              </video>
            }
            description={
              <>
                <div>{t('backgroundJob.description')}</div>
              </>
            }
            spinning={false}
            // onDone={onBackgroundJobDone}
            // onFailed={onBackgroundJobFailed}
          />
        )}
      </Modal>
    </Row>
  );
};

export default CustomIntegrationPage;
