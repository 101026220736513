import { DatePicker, Form, Input, message, Modal, Select, Typography } from 'antd';
import ChecklistDefinitionsSelect from 'components/Checklist/ChecklistDefinitionsSelect';
import SpecificUsersSelect from 'components/Inputs/SpecificUsersSelect';
import { getUserName } from 'components/UserPreview/getUserName';
import { CHECKLIST_DEFINITION_STATUS, PERMISSIONS } from 'core/utils/constants';
import { selectSearch } from 'core/utils/selectSearch';
import { debounce, get, has } from 'lodash';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { actions } from 'redux/lists/calibrationSessionsListReducer';
import { calibrationSessionsResource } from 'redux/resources/calibrationSessions';
import { getChecklistsDefinitionsByStatuses } from 'redux/selectors/checklists';
import { getCurrentUser, getUsersWithPermissions } from 'redux/selectors/users';
import {
  resetCalibrationSessionSettingsForm,
  updateCalibrationSessionSettingsForm
} from 'redux/ui/calibrationSessionSettingsModal/reducer';

const CalibrationSessionSettingsModal = ({
  modalForm,
  onSessionCreate,
  currentUserName,
  createSession,
  updateSession,
  users,
  foldersByIds,
  folderItemsByIds,
  checklistDefinitions,
  resetCalibrationSessionSettingsForm,
  isNewCalibrationsSession,
  updateCalibrationSessionSettingsForm
}) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const { Text } = Typography;
  const { TextArea } = Input;
  const { Option } = Select;
  const {
    id,
    name,
    scheduledTime,
    place,
    description,
    checklistDefinitionId,
    membersIds,
    folderId,
    folderItemsIds
  } = modalForm;

  const initialValues = {
    name,
    place,
    scheduledTime: moment(scheduledTime),
    description,
    checklistDefinitionId,
    membersIds,
    folderId,
    folderItemsIds
  };

  useEffect(() => {
    if (id) {
      form.setFieldsValue(initialValues);
    }
  }, [id]);

  const labelCol = { md: { offset: 0, span: 10 } };
  const inputCol = { md: { span: 14 } };

  const createCalibrationSession = async values => {
    try {
      const result = await createSession({
        ...values,
        scheduledTime: moment(values.scheduledTime).toISOString(true)
      });
      if (result.type === 'calibration-sessions') {
        message.success(t('calibrationsPage.messages.sessionSuccessfullyCreated'));
        onSessionCreate(result);
        resetCalibrationSessionSettingsForm();
        form.resetFields();
      }
    } catch (error) {
      console.log(error);
      message.error(t('calibrationsPage.messages.createSessionFailed'));
    }
  };

  const updateCalibrationSession = async values => {
    try {
      const result = await updateSession({
        id,
        ...values,
        scheduledTime: moment(values.scheduledTime).toISOString(true)
      });
      if (result.type === 'calibration-sessions') {
        message.success(t('calibrationsPage.messages.sessionSuccessfullyUpdated'));
        resetCalibrationSessionSettingsForm();
        form.resetFields();
      }
    } catch (error) {
      console.log(error);
      message.error(t('calibrationsPage.messages.updateSessionFailed'));
    }
  };

  const handleSubmit = values => {
    return isNewCalibrationsSession
      ? createCalibrationSession(values)
      : updateCalibrationSession(values);
  };

  const onValuesChange = debounce((changedValues, allValues) => {
    if (has(changedValues, 'folderId')) {
      return updateCalibrationSessionSettingsForm({ ...changedValues, folderItemsIds: undefined });
    }

    return updateCalibrationSessionSettingsForm(changedValues);
  }, 300);

  const selectedFolder = foldersByIds[folderId];

  const folderItems = get(selectedFolder, 'itemsIds', []).reduce((acc, itemId) => {
    const folderItem = folderItemsByIds[itemId];
    if (!folderItem) return acc;
    return [...acc, folderItem];
  }, []);

  return (
    <Modal
      title={t('calibrationsPage.modal.title')}
      visible={id}
      width={620}
      onOk={form.submit}
      onCancel={() => {
        resetCalibrationSessionSettingsForm();
        form.resetFields();
      }}
      okText={t('calibrationsPage.modal.buttons.save')}
      cancelText={t('calibrationsPage.modal.buttons.cancel')}
    >
      <Form
        labelCol={labelCol}
        labelAlign="left"
        wrapperCol={inputCol}
        onFinish={handleSubmit}
        initialValues={initialValues}
        form={form}
        colon={false}
        onValuesChange={onValuesChange}
        scrollToFirstError
      >
        <Form.Item label={t('calibrationsPage.modal.form.fields.organizer')}>
          <Text>{currentUserName}</Text>
        </Form.Item>

        <Form.Item
          label={t('calibrationsPage.modal.form.fields.name')}
          name="name"
          rules={[
            {
              required: true,
              message: t('calibrationsPage.modal.form.messages.requiredName'),
              whitespace: true
            }
          ]}
        >
          <Input placeholder={t('calibrationsPage.modal.form.fields.namePlaceholder')} />
        </Form.Item>

        <Form.Item
          label={t('calibrationsPage.modal.form.fields.scheduledTime')}
          hasFeedback
          name="scheduledTime"
          rules={[
            {
              required: true,
              message: t('calibrationsPage.modal.form.messages.requiredScheduledTime')
            }
          ]}
        >
          <DatePicker format="DD/MM/YYYY" />
        </Form.Item>

        <Form.Item label={t('calibrationsPage.modal.form.fields.place')} name="place">
          <Input placeholder={t('calibrationsPage.modal.form.fields.placePlaceholder')} />
        </Form.Item>

        <Form.Item label={t('calibrationsPage.modal.form.fields.description')} name="description">
          <TextArea rows={3} />
        </Form.Item>

        <Form.Item
          label={t('calibrationsPage.modal.form.fields.checklistDefinition')}
          name="checklistDefinitionId"
          rules={[
            {
              required: true,
              message: t('calibrationsPage.modal.form.messages.requiredСhecklistDefinition')
            }
          ]}
        >
          <ChecklistDefinitionsSelect
            style={{ width: '100%' }}
            placeholder={t('calibrationsPage.modal.form.fields.checklistDefinitionPlaceholder')}
            checklistDefinitions={checklistDefinitions}
            allowClear
            showSearch
            filterOption={(input, option) =>
              selectSearch({ input, option, searchProp: 'searchValue' })
            }
          />
        </Form.Item>

        <Form.Item
          label={t('calibrationsPage.modal.form.fields.members')}
          rules={[
            {
              required: true,
              message: t('calibrationsPage.modal.form.messages.requiredMembers')
            }
          ]}
          name="membersIds"
        >
          <SpecificUsersSelect
            placeholder={t('calibrationsPage.modal.form.fields.membersPlaceholder')}
            mode="multiple"
            style={{ width: '100%' }}
            allowClear
            maxTagCount={0}
            maxTagPlaceholder={selectedKeys =>
              `${t('calibrationsPage.modal.form.fields.members')}: ${selectedKeys.length}`
            }
            optionLabelProp="label"
            filterOption={(input, option) => selectSearch({ input, option, searchProp: 'label' })}
            usersToSelect={users.filter(r => r.active)}
          />
        </Form.Item>

        <Text strong>{t('calibrationsPage.modal.form.communicationsTitle')}</Text>

        <Form.Item
          label={t('calibrationsPage.modal.form.fields.folder')}
          name="folderId"
          rules={[
            {
              required: true,
              message: t('calibrationsPage.modal.form.messages.requiredFolder')
            }
          ]}
        >
          <Select
            style={{ width: '100%' }}
            placeholder={t('calibrationsPage.modal.form.fields.folderPlaceholder')}
            allowClear
          >
            {Object.values(foldersByIds).map(folder => (
              <Option value={folder.id}>{folder.name}</Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label={t('calibrationsPage.modal.form.fields.folderItems')}
          rules={[
            {
              required: true,
              message: t('calibrationsPage.modal.form.messages.requiredFolderItems')
            }
          ]}
          name="folderItemsIds"
        >
          <Select
            style={{ width: '100%' }}
            placeholder={t('calibrationsPage.modal.form.fields.folderItemsPlaceholder')}
            allowClear
            mode="multiple"
            maxTagCount={0}
            maxTagPlaceholder={selectedKeys =>
              `${t('calibrationsPage.modal.form.fields.folderItemsMax')} ${selectedKeys.length}`
            }
          >
            {folderItems.map(item => (
              <Option value={item.id}>{item.name}</Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

const mapStateToProps = state => {
  const modalForm = state.uiCalibrationSessionSettingsModal.form;
  const checklistDefinitions = getChecklistsDefinitionsByStatuses(state, {
    statuses: [CHECKLIST_DEFINITION_STATUS.PUBLISHED.value]
  });
  const users = Object.values(
    getUsersWithPermissions(state, {
      permissions: [PERMISSIONS.CAN_MAKE_REVIEW]
    })
  );
  const foldersByIds = state.foldersResource.byIds;
  const folderItemsByIds = state.folderItemsResource.byIds;
  const calibrationSessionsByIds = state.calibrationSessionsResource.byIds;

  const currentUserName = getUserName({ user: getCurrentUser(state) });
  const isNewCalibrationsSession = !Object.keys(calibrationSessionsByIds).includes(modalForm.id);

  return {
    checklistDefinitions,
    users,
    foldersByIds,
    folderItemsByIds,
    modalForm,
    currentUserName,
    isNewCalibrationsSession
  };
};
const mapDispatchToProps = {
  onSessionCreate: actions.onSessionCreate,
  resetCalibrationSessionSettingsForm,
  updateCalibrationSessionSettingsForm,
  createSession: calibrationSessionsResource.operations.create,
  updateSession: calibrationSessionsResource.operations.updateById
};

export default connect(mapStateToProps, mapDispatchToProps)(CalibrationSessionSettingsModal);
