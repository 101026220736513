export default {
  teamPage: {
    unitTable: {
      empty: 'No hay datos',
      infoIcons: {
        info: 'En esta tabla se refleja la analitica total por todas las evaluaciones',
        scoreInfo: 'Nota media entre todas las evaluaciones de la listas a chequear elegida',
        reviewsCountInfo:
          'Cantidad de tofas la evaluaciones realizadas por la lista a chequear elegida',
        greenZoneCountInfo:
          'Porcentaje de evaluaciones en la zona verde por la lista a chequear elegida',
        allScoresInfo: 'Todas las evaluaciones por zonas de color por la lista a chequear elegida'
      },
      columns: {
        employee: 'Empleado',
        unit: 'Departamento',
        score: 'Nota',
        reviewsCount: 'Evaluaciones',
        greenZoneCount: 'Exelente',
        allScores: 'Todas las evaluaciones',
        flags: 'Banderas'
      },
      differenceArrowScoreLiteral: 'nota'
    },
    buttons: {
      exportTeamPageAnalytics: 'Exportar a Exel',
      units: 'Departamentos',
      employees: 'Personal'
    },
    pageFilters: {
      exportModal: {
        title: 'Analítica exportada',
        description:
          'El resultado de la exportación le llegará a su correo electrónico en unos minutos'
      },
      messages: { exportTableFailed: 'No se logró exportar la analítica' },
      filters: {
        unitPlaceholder: 'Elija un departamento',
        unitPlaceholderMax: 'Elegido',
        unitPlaceholderMax2: 'departamento',
        checklistPlaceholder: 'Elija la lista a chequear'
      }
    }
  }
};
