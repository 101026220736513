import { DeleteOutlined, EditOutlined, MoreOutlined } from '@ant-design/icons';
import {
  Button,
  Checkbox,
  Col,
  Dropdown,
  List,
  Menu,
  message,
  Modal,
  Spin,
  Tooltip,
  Typography
} from 'antd';
import { Info } from 'components/Info';
import { CHECKLIST_DEFINITION_STATUS } from 'core/utils/constants';
import { isEmpty, some, throttle } from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { actions, operations } from 'redux/lists/questionsList';
import { questionsResource } from 'redux/resources/questions';
import { questionToGroupBindingsResource } from 'redux/resources/questionToGroupBindings';
import { getQuestionChecklistDefinitions } from 'redux/selectors/checklistItems/checklistItems';
import { setEditingQuestion } from 'redux/ui/questionModal/reducer';
import styled from 'styled-components';
import UserPreview from 'components/UserPreview';
import SCol from 'components/Standard/SCol';

const { Text } = Typography;
const ListItem = styled(List.Item)`
  && {
    padding: 0;
  }
`;

const QuestionsList = ({
  setEditingQuestion,
  page,
  hasNextPage,
  loading,
  updatePage,
  loadQuestions,
  dataSource,
  questionsByIds,
  deleteQuestion,
  deleteBinding,
  rowSelection = null,
  filters = {},
  allowActions = true,
  listStyles = {},
  clearList,
  currentChecklist
}) => {
  const { t } = useTranslation();
  useEffect(() => {
    clearList();
    return clearList;
  }, []);

  useEffect(() => {
    loadQuestions({
      page,
      filters,
      sort: '-created_at',
      include: 'question-to-group-bindings.question-group.checklist-definition'
    });
  }, [page.number]);

  const onScrollY = throttle(async el => {
    const isOnBottom = (element => {
      return element.clientHeight + element.scrollTop + 20 >= element.scrollHeight;
    })(el);

    if (hasNextPage && !loading && isOnBottom) {
      const newPage = `${parseInt(page.number) + 1}`;
      updatePage({
        number: newPage
      });
    }
  }, 250);

  const tryToDeleteQuestion = async ({ id, checklistDefinitions = [] }) => {
    const question = questionsByIds[id];

    if (
      some(
        checklistDefinitions,
        ({ status }) =>
          status === CHECKLIST_DEFINITION_STATUS.ARCHIVED.value ||
          status === CHECKLIST_DEFINITION_STATUS.PUBLISHED.value
      )
    ) {
      return message.warning(t('components.questionsList.messages.blockedDeleteQuestion'));
    }

    Modal.confirm({
      title: t('components.questionsList.confirmDelete.title'),
      content: t('components.questionsList.confirmDelete.description'),
      okText: t('general.delete'),
      cancelText: t('general.cancel'),
      okType: 'danger',
      onOk: async () => {
        if (!isEmpty(checklistDefinitions)) {
          await Promise.all[question.questionToGroupBindingsIds.map(id => deleteBinding({ id }))];
        }

        await deleteQuestion({ id });
      }
    });
  };

  const handleMenuClick = ({ key, id, checklistDefinitions }) => {
    const actions = {
      edit: () => setEditingQuestion(questionsByIds[id]),
      delete: () => tryToDeleteQuestion({ id, checklistDefinitions })
      //   TODO: add copy
    };

    actions[key]();
  };

  const getMenu = ({ id, checklistDefinitions }) => (
    <Menu onClick={({ key }) => handleMenuClick({ key, id, checklistDefinitions })}>
      <Menu.Item key="edit">
        <EditOutlined />
        {t('components.questionsList.menu.edit')}
      </Menu.Item>

      {/* <Menu.Item key="copy">
        <Icon type="copy" />
        {t('components.questionsList.menu.copy')}
      </Menu.Item> */}

      <Menu.Item key="delete" style={{ color: 'var(--red_primary)' }}>
        <DeleteOutlined />
        {t('components.questionsList.menu.delete')}
      </Menu.Item>
    </Menu>
  );

  const selectable = !isEmpty(rowSelection);

  const renderQuestion = ({ name, text, creatorId, id, checklistDefinitions }) => {
    const questionChecklistsNames = checklistDefinitions.map(
      checklistDefinition => checklistDefinition.name
    );

    const isQuestionInCurrentChecklist =
      currentChecklist &&
      checklistDefinitions
        .map(checklistDefinition => checklistDefinition.id)
        .includes(currentChecklist.id);

    return (
      <ListItem>
        {selectable ? (
          <>
            <Col span={1}>
              <Checkbox
                checked={rowSelection.selected.includes(id) || isQuestionInCurrentChecklist}
                onChange={e =>
                  rowSelection.onSelect(
                    e.target.checked
                      ? [...rowSelection.selected, id]
                      : rowSelection.selected.filter(selectedId => selectedId !== id)
                  )
                }
                disabled={isQuestionInCurrentChecklist}
              />
            </Col>
            <Col span={5}>
              <Text>{name}</Text>
            </Col>
          </>
        ) : (
          <Col span={6}>
            <Text>{name}</Text>
          </Col>
        )}

        <SCol display="flex" justifyContent="center" span={4}>
          <Info tooltip={{ title: text }} disabled={isEmpty(text)} />
        </SCol>
        <Col span={8}>
          <Text>
            {checklistDefinitions[0]
              ? questionChecklistsNames[0]
              : t('components.questionsList.isNotAddedToChecklist')}
          </Text>
          {questionChecklistsNames.length > 1 && (
            <Tooltip
              title={questionChecklistsNames
                .filter(item => item !== questionChecklistsNames[0])
                .join(', ')}
            >
              <Button type="link" style={{ padding: 4 }}>
                {`${t('components.questionsList.and')} ${questionChecklistsNames.length - 1}`}
              </Button>
            </Tooltip>
          )}
        </Col>
        <Col span={4}>
          <UserPreview userId={creatorId} disabled showAvatar />
        </Col>
        <SCol display="flex" justifyContent="flex-end" span={2}>
          {allowActions && (
            <Dropdown overlay={getMenu({ id, checklistDefinitions })} trigger={['click']}>
              <Button icon={<MoreOutlined />} />
            </Dropdown>
          )}
        </SCol>
      </ListItem>
    );
  };

  return (
    <>
      <List.Item style={{ borderBottom: '1px solid var(--gray_4)' }}>
        <Col span={6}>
          <Text strong>{t('components.questionsList.listColumns.name')}</Text>
        </Col>
        <SCol display="flex" justifyContent="center" span={4}>
          <Text strong>{t('components.questionsList.listColumns.description')}</Text>
        </SCol>
        <Col span={8}>
          <Text strong>{t('components.questionsList.listColumns.isAddedToChecklist')}</Text>
        </Col>
        <Col span={4}>
          <Text strong>{t('components.questionsList.listColumns.author')}</Text>
        </Col>
        <SCol display="flex" justify="flex-end" span={2}>
          {allowActions && <Text strong>{t('components.questionsList.listColumns.actions')}</Text>}
        </SCol>
      </List.Item>
      <PerfectScrollbar
        onScrollY={onScrollY}
        options={{ suppressScrollX: true }}
        style={listStyles}
      >
        <List dataSource={dataSource} renderItem={renderQuestion} rowKey="id" />
        <Spin spinning={loading}>
          <List.Item />
        </Spin>
      </PerfectScrollbar>
    </>
  );
};

const mapStateToProps = state => {
  const { ids, loading, page, totalPages } = state.questionsList;
  const { byIds } = state.questionsResource;
  const dataSource = ids.reduce((acc, id) => {
    const question = byIds[id];

    if (!question) return acc;

    const data = {
      ...byIds[id],
      checklistDefinitions: getQuestionChecklistDefinitions(state, question)
    };

    return [...acc, data];
  }, []);

  return {
    dataSource,
    questionsByIds: byIds,
    loading,
    page,
    hasNextPage: totalPages > page.number
  };
};

const mapDispatchToProps = {
  setEditingQuestion,
  updatePage: actions.updatePage,
  clearList: actions.clearList,
  loadQuestions: operations.load,
  updateQuestion: questionsResource.operations.updateById,
  deleteQuestion: questionsResource.operations.deleteById,
  deleteBinding: questionToGroupBindingsResource.operations.deleteById
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(QuestionsList));
