import React from 'react';
import { Trans } from 'react-i18next';
import { WIDGETS_DESCRIPTIONS } from 'core/utils/constants';

export default {
  checklist_definition_average_score_by_units: {
    type: 'checklist_definition_average_score_by_units',
    description: (
      <Trans
        i18nKey={WIDGETS_DESCRIPTIONS.CHECKLIST_DEFINITION_AVERAGE_SCORE_BY_UNITS.description}
      />
    ),
    name: (
      <Trans i18nKey={WIDGETS_DESCRIPTIONS.CHECKLIST_DEFINITION_AVERAGE_SCORE_BY_UNITS.title} />
    ),
    dateSelectorType: 'clientInteractionDateSelector',
    availableSizes: ['medium'],
    availableFilters: [
      {
        type: 'clientInteractionDateSelector'
      },
      {
        type: 'unitsIds'
      },
      {
        type: 'singleChecklistDefinitionId'
      }
    ]
  },
  checklist_definition_average_score_by_operators: {
    type: 'checklist_definition_average_score_by_operators',
    description: (
      <Trans
        i18nKey={WIDGETS_DESCRIPTIONS.CHECKLIST_DEFINITION_AVERAGE_SCORE_BY_OPERATORS.description}
      />
    ),
    name: (
      <Trans i18nKey={WIDGETS_DESCRIPTIONS.CHECKLIST_DEFINITION_AVERAGE_SCORE_BY_OPERATORS.title} />
    ),
    dateSelectorType: 'clientInteractionDateSelector',
    availableSizes: ['medium'],
    availableFilters: [
      {
        type: 'clientInteractionDateSelector'
      },
      {
        type: 'unitsIds'
      },
      {
        type: 'operatorsIds'
      },
      {
        type: 'singleChecklistDefinitionId'
      }
    ]
  },
  checklist_definition_average_score_by_checklist_definitions: {
    type: 'checklist_definition_average_score_by_checklist_definitions',
    description: (
      <Trans
        i18nKey={
          WIDGETS_DESCRIPTIONS.CHECKLIST_DEFINITION_AVERAGE_SCORE_BY_CHECKLIST_DEFINITIONS
            .description
        }
      />
    ),
    name: (
      <Trans
        i18nKey={
          WIDGETS_DESCRIPTIONS.CHECKLIST_DEFINITION_AVERAGE_SCORE_BY_CHECKLIST_DEFINITIONS.title
        }
      />
    ),
    dateSelectorType: 'clientInteractionDateSelector',
    availableSizes: ['medium'],
    availableFilters: [
      {
        type: 'clientInteractionDateSelector'
      },
      {
        type: 'unitsIds'
      },
      {
        type: 'operatorsIds'
      },
      {
        type: 'multipleChecklistDefinitionsIds'
      }
    ]
  },
  phone_calls_count_by_units: {
    type: 'phone_calls_count_by_units',
    description: <Trans i18nKey={WIDGETS_DESCRIPTIONS.PHONE_CALLS_COUNT_BY_UNITS.description} />,
    name: <Trans i18nKey={WIDGETS_DESCRIPTIONS.PHONE_CALLS_COUNT_BY_UNITS.title} />,
    dateSelectorType: 'clientInteractionDateSelector',
    availableSizes: ['medium'],
    availableFilters: [
      {
        type: 'clientInteractionDateSelector'
      },
      {
        type: 'unitsIds'
      },
      {
        type: 'multipleChecklistDefinitionsIds'
      },
      {
        type: 'isReviewed'
      },
      {
        type: 'direction'
      }
    ]
  },
  phone_calls_count_by_operators: {
    type: 'phone_calls_count_by_operators',
    description: (
      <Trans i18nKey={WIDGETS_DESCRIPTIONS.PHONE_CALLS_COUNT_BY_OPERATORS.description} />
    ),
    name: <Trans i18nKey={WIDGETS_DESCRIPTIONS.PHONE_CALLS_COUNT_BY_OPERATORS.title} />,
    dateSelectorType: 'clientInteractionDateSelector',
    availableSizes: ['medium'],
    availableFilters: [
      {
        type: 'clientInteractionDateSelector'
      },
      {
        type: 'unitsIds'
      },
      {
        type: 'operatorsIds'
      },
      {
        type: 'multipleChecklistDefinitionsIds'
      },
      {
        type: 'isReviewed'
      },
      {
        type: 'direction'
      }
    ]
  },
  reviews_count_by_reviewers: {
    type: 'reviews_count_by_reviewers',
    description: <Trans i18nKey={WIDGETS_DESCRIPTIONS.REVIEWS_COUNT_BY_REVIEWERS.description} />,
    name: <Trans i18nKey={WIDGETS_DESCRIPTIONS.REVIEWS_COUNT_BY_REVIEWERS.title} />,
    dateSelectorType: 'reviewDateSelector',
    availableSizes: ['medium'],
    availableFilters: [
      {
        type: 'reviewDateSelector'
      },
      {
        type: 'unitsIds'
      },
      {
        type: 'reviewersIds'
      },
      {
        type: 'multipleChecklistDefinitionsIds'
      }
    ]
  },
  rating_flags_count: {
    type: 'rating_flags_count',
    description: '',
    name: <Trans i18nKey={WIDGETS_DESCRIPTIONS.RATING_FLAGS_COUNT.title} />,
    dateSelectorType: 'reviewDateSelector',
    availableSizes: ['medium'],
    availableFilters: [
      {
        type: 'reviewDateSelector'
      },
      {
        type: 'unitsIds'
      },
      {
        type: 'operatorsIds'
      },
      {
        type: 'multipleChecklistDefinitionsIds'
      }
    ]
  },
  rating_flags_count_by_units: {
    type: 'rating_flags_count_by_units',
    description: '',
    name: <Trans i18nKey={WIDGETS_DESCRIPTIONS.RATING_FLAGS_COUNT_BY_UNITS.title} />,
    dateSelectorType: 'reviewDateSelector',
    availableSizes: ['medium'],
    availableFilters: [
      {
        type: 'reviewDateSelector'
      },
      {
        type: 'unitsIds'
      },
      {
        type: 'multipleChecklistDefinitionsIds'
      }
    ]
  },
  rating_flags_count_by_operators: {
    type: 'rating_flags_count_by_operators',
    description: '',
    name: <Trans i18nKey={WIDGETS_DESCRIPTIONS.RATING_FLAGS_COUNT_BY_OPERATORS.title} />,
    dateSelectorType: 'reviewDateSelector',
    availableSizes: ['medium'],
    availableFilters: [
      {
        type: 'reviewDateSelector'
      },
      {
        type: 'unitsIds'
      },
      {
        type: 'operatorsIds'
      },
      {
        type: 'multipleChecklistDefinitionsIds'
      }
    ]
  },
  labels_count_by_operators: {
    type: 'labels_count_by_operators',
    description: <Trans i18nKey={WIDGETS_DESCRIPTIONS.LABELS_COUNT_BY_OPERATORS.description} />,
    name: <Trans i18nKey={WIDGETS_DESCRIPTIONS.LABELS_COUNT_BY_OPERATORS.title} />,
    dateSelectorType: 'clientInteractionDateSelector',
    availableSizes: ['medium'],
    availableFilters: [
      {
        type: 'clientInteractionDateSelector'
      },
      {
        type: 'unitsIds'
      },
      {
        type: 'operatorsIds'
      },
      {
        type: 'taskDefinitionsIds'
      }
    ]
  },
  labels_count_by_units: {
    type: 'labels_count_by_units',
    description: <Trans i18nKey={WIDGETS_DESCRIPTIONS.LABELS_COUNT_BY_UNITS.description} />,
    name: <Trans i18nKey={WIDGETS_DESCRIPTIONS.LABELS_COUNT_BY_UNITS.title} />,
    dateSelectorType: 'clientInteractionDateSelector',
    availableSizes: ['medium'],
    availableFilters: [
      {
        type: 'clientInteractionDateSelector'
      },
      {
        type: 'unitsIds'
      },
      {
        type: 'taskDefinitionsIds'
      }
    ]
  },
  checklist_definition_questions_average_scores: {
    type: 'checklist_definition_questions_average_scores',
    description: (
      <Trans
        i18nKey={WIDGETS_DESCRIPTIONS.CHECKLIST_DEFINITION_QUESTIONS_AVERAGE_SCORES.description}
      />
    ),
    name: (
      <Trans i18nKey={WIDGETS_DESCRIPTIONS.CHECKLIST_DEFINITION_QUESTIONS_AVERAGE_SCORES.title} />
    ),
    dateSelectorType: 'clientInteractionDateSelector',
    availableSizes: ['medium'],
    availableFilters: [
      {
        type: 'clientInteractionDateSelector'
      },
      {
        type: 'unitsIds'
      },
      {
        type: 'operatorsIds'
      },
      {
        type: 'singleChecklistDefinitionId'
      }
    ]
  },
  checklist_definition_questions_average_scores_by_question_groups: {
    type: 'checklist_definition_questions_average_scores_by_question_groups',
    description: (
      <Trans
        i18nKey={
          WIDGETS_DESCRIPTIONS.CHECKLIST_DEFINITION_QUESTIONS_AVERAGE_SCORES_BY_QUESTION_GROUPS
            .description
        }
      />
    ),
    name: (
      <Trans
        i18nKey={
          WIDGETS_DESCRIPTIONS.CHECKLIST_DEFINITION_QUESTIONS_AVERAGE_SCORES_BY_QUESTION_GROUPS
            .title
        }
      />
    ),
    dateSelectorType: 'clientInteractionDateSelector',
    availableSizes: ['medium'],
    availableFilters: [
      {
        type: 'clientInteractionDateSelector'
      },
      {
        type: 'unitsIds'
      },
      {
        type: 'operatorsIds'
      },
      {
        type: 'singleChecklistDefinitionId'
      }
    ]
  },
  checklist_definition_question_groups_average_scores: {
    type: 'checklist_definition_question_groups_average_scores',
    description: (
      <Trans
        i18nKey={
          WIDGETS_DESCRIPTIONS.CHECKLIST_DEFINITION_QUESTION_GROUPS_AVERAGE_SCORES.description
        }
      />
    ),
    name: (
      <Trans
        i18nKey={WIDGETS_DESCRIPTIONS.CHECKLIST_DEFINITION_QUESTION_GROUPS_AVERAGE_SCORES.title}
      />
    ),

    dateSelectorType: 'clientInteractionDateSelector',
    availableSizes: ['medium'],
    availableFilters: [
      {
        type: 'clientInteractionDateSelector'
      },
      {
        type: 'unitsIds'
      },
      {
        type: 'operatorsIds'
      },
      {
        type: 'singleChecklistDefinitionId'
      }
    ]
  },
  phone_calls_average_duration_by_units: {
    type: 'phone_calls_average_duration_by_units',
    description: (
      <Trans i18nKey={WIDGETS_DESCRIPTIONS.PHONE_CALLS_AVERAGE_DURATION_BY_UNITS.description} />
    ),
    name: <Trans i18nKey={WIDGETS_DESCRIPTIONS.PHONE_CALLS_AVERAGE_DURATION_BY_UNITS.title} />,
    dateSelectorType: 'clientInteractionDateSelector',
    availableSizes: ['medium'],
    availableFilters: [
      {
        type: 'clientInteractionDateSelector'
      },
      {
        type: 'unitsIds'
      },
      {
        type: 'isReviewed'
      },
      {
        type: 'direction'
      }
    ]
  },
  phone_calls_average_duration_by_operators: {
    type: 'phone_calls_average_duration_by_operators',
    description: (
      <Trans i18nKey={WIDGETS_DESCRIPTIONS.PHONE_CALLS_AVERAGE_DURATION_BY_OPERATORS.description} />
    ),
    name: <Trans i18nKey={WIDGETS_DESCRIPTIONS.PHONE_CALLS_AVERAGE_DURATION_BY_OPERATORS.title} />,
    dateSelectorType: 'clientInteractionDateSelector',
    availableSizes: ['medium'],
    availableFilters: [
      {
        type: 'clientInteractionDateSelector'
      },
      {
        type: 'unitsIds'
      },
      {
        type: 'operatorsIds'
      },
      {
        type: 'isReviewed'
      },
      {
        type: 'direction'
      }
    ]
  }
};
