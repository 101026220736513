import { Col, Row } from 'antd';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { loadSessionsData } from 'redux/ui/calibrationSessionSettingsModal/operations';
import SessionSettingsModal from './CalibrationSessionSettingsModal';
import CalibrationsFilters from './CalibrationsFilters';
import CalibrationsList from './tabs/CalibrationsList';
import Folders from './tabs/Folders';

const tabToComponent = {
  folders: Folders
};

const Calibrations = ({ tab, loadSessionsData }) => {
  useEffect(() => {
    loadSessionsData();
  }, []);
  const { t } = useTranslation();

  const Tab = tabToComponent[tab] || CalibrationsList;

  return (
    <Row gutter={[0, 8]} style={{ marginBottom: '-4px' }}>
      <Helmet>
        <title>{t('pagesMeta.calibrationsSessionsPages.calibrationsSessions.title')}</title>
      </Helmet>
      <Col span={24}>
        <CalibrationsFilters tab={tab} />
      </Col>
      <Col span={24}>
        <Tab tab={tab} />
      </Col>
      <SessionSettingsModal />
    </Row>
  );
};

const mapDispatchToProps = {
  loadSessionsData
};

export default withRouter(connect(null, mapDispatchToProps)(Calibrations));
