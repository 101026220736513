import { Col, Form, Input, Row, Select } from 'antd';
import Icon from 'components/Icon';
import SButton from 'components/Standard/SButton';
import SCard from 'components/Standard/SCard';
import { INTEGRATIONS } from 'core/utils/constants';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Trash2 } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { reconnectToAmo } from 'redux/entities/amoIntegration/operations';
import { integrationsResource } from 'redux/resources/integrations';

const NameAndType = ({ onSubmit, onUpdate, canEdit = false, onDelete, integration }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [editing, setEditing] = useState(false);
  const [loadingAvailableIntegrations, setLoadingAvailableIntegrations] = useState(false);
  const [availableIntegrations, setAvailableIntegrations] = useState(
    Object.values(INTEGRATIONS).map(integration => integration.type)
  );
  const loadAvailableIntegrations = async () => {
    setLoadingAvailableIntegrations(true);

    const integrations = await dispatch(integrationsResource.operations.getAvailableIntegrations());
    if (integrations) {
      setAvailableIntegrations(integrations);
    }

    setLoadingAvailableIntegrations(false);
  };

  useEffect(() => {
    loadAvailableIntegrations();
  }, []);

  const handleSubmit = async values => {
    setLoading(true);
    await onSubmit(values);
    setLoading(false);
  };

  const onEdit = async () => {
    if (!editing) return setEditing(true);

    try {
      setLoading(true);
      await onUpdate(form.getFieldsValue());
      setLoading(false);
      setEditing(false);
    } catch (error) {
      console.log(error);
    }
  };

  const requestAmoConnection = () => {
    dispatch(reconnectToAmo());
  };

  const { t } = useTranslation();

  return (
    <SCard>
      <Form layout="vertical" form={form} onFinish={handleSubmit} initialValues={integration}>
        <Row align="middle" justify="space-between">
          <Col>
            <Row align="middle" gutter={[24, 0]}>
              <Col>
                <Form.Item
                  name="integrationType"
                  label={t('integrationsSettingsPage.nameAndType.type')}
                >
                  <Select
                    size="large"
                    placeholder={t('integrationsSettingsPage.nameAndType.integrationType')}
                    style={{ width: '304px' }}
                    disabled={canEdit}
                    loading={loadingAvailableIntegrations}
                  >
                    {availableIntegrations.map(integrationType => (
                      <Select.Option key={integrationType} value={integrationType}>
                        {t(INTEGRATIONS[integrationType].name)}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col>
                <Form.Item name="name" label={t('integrationsSettingsPage.nameAndType.name')}>
                  <Input
                    disabled={canEdit ? !editing : false}
                    size="large"
                    placeholder={t('integrationsSettingsPage.nameAndType.integrationName')}
                    style={{ width: '304px' }}
                  />
                </Form.Item>
              </Col>

              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.integrationType !== currentValues.integrationType
                }
              >
                {({ getFieldValue }) =>
                  getFieldValue('integrationType') === INTEGRATIONS.amocrm.type &&
                  canEdit &&
                  !isEmpty(integration) ? (
                    <Col>
                      <SButton size="big" type="secondary" onClick={requestAmoConnection}>
                        Обновить подключение
                      </SButton>
                    </Col>
                  ) : null
                }
              </Form.Item>

              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.integrationType !== currentValues.integrationType
                }
              >
                {({ getFieldValue }) =>
                  getFieldValue('integrationType') === INTEGRATIONS.bitrix_crm.type &&
                  isEmpty(integration) ? (
                    <Col>
                      <Form.Item
                        name="bitrixDomain"
                        label={t('integrationsSettingsPage.nameAndType.yourB24address')}
                        rules={[
                          {
                            required: true,
                            validator: (_, value) =>
                              value.includes('bitrix24') &&
                              value.match(/^(?:(ftp|http|https):\/\/)?(?:[\w-]+\.)+[a-z]{2,6}$/)
                                ? Promise.resolve()
                                : Promise.reject(
                                    t('registerPage.form.buttons.bitrixPopover.wrongUrl')
                                  )
                          }
                        ]}
                      >
                        <Input
                          placeholder="qolio.bitrix24.ru"
                          disabled={canEdit}
                          size="large"
                          style={{ width: '304px' }}
                        />
                      </Form.Item>
                    </Col>
                  ) : null
                }
              </Form.Item>

              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.integrationType !== currentValues.integrationType
                }
              >
                {({ getFieldValue }) =>
                  [INTEGRATIONS.sipuni.type, INTEGRATIONS.binotel.type].includes(
                    getFieldValue('integrationType')
                  ) ? (
                    <Col>
                      <Form.Item
                        name="clientId"
                        label="Client ID"
                        rules={[
                          {
                            required: true
                          }
                        ]}
                      >
                        <Input
                          disabled={canEdit ? !editing : false}
                          size="large"
                          style={{ width: '304px' }}
                        />
                      </Form.Item>
                    </Col>
                  ) : null
                }
              </Form.Item>

              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.integrationType !== currentValues.integrationType
                }
              >
                {({ getFieldValue }) =>
                  [
                    INTEGRATIONS.sipuni.type,
                    INTEGRATIONS.usedesk.type,
                    INTEGRATIONS.binotel.type
                  ].includes(getFieldValue('integrationType')) ? (
                    <Col>
                      <Form.Item
                        name="clientSecret"
                        label="Client Secret"
                        rules={[
                          {
                            required: true
                          }
                        ]}
                      >
                        <Input
                          disabled={canEdit ? !editing : false}
                          size="large"
                          style={{ width: '304px' }}
                        />
                      </Form.Item>
                    </Col>
                  ) : null
                }
              </Form.Item>
            </Row>
          </Col>
          <Col>
            {canEdit ? (
              <Row align="middle">
                <Col>
                  <SButton
                    type="primary"
                    size="big"
                    width="160px"
                    onClick={onEdit}
                    loading={loading}
                  >
                    {editing ? 'Сохранить' : 'Редактировать'}
                  </SButton>
                </Col>
                <Col>
                  <SButton type="link" icon={<Icon icon={Trash2} />} onClick={onDelete}>
                    {t('integrationsSettingsPage.delete')}
                  </SButton>
                </Col>
              </Row>
            ) : (
              <SButton width="160px" type="primary" htmlType="submit" size="big" loading={loading}>
                {t('integrationsSettingsPage.proceed')}
              </SButton>
            )}
          </Col>
        </Row>
      </Form>
    </SCard>
  );
};

export default NameAndType;
