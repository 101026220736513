import { Affix, Col, Row } from 'antd';
import CommentsPanel from 'components/Comments/CommentsPanel';
import PhoneCallPlayer from 'components/PhoneCallPlayer';
import { pick } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { reviewsResource } from 'redux/resources/reviews';
import { getCommentsFilteredByPermission } from 'redux/selectors/comments';
import { getCurrentUserPermissions } from 'redux/selectors/users';

const ClientInteraction = ({ call, reviewId, comments, commmentsLoading }) => {
  return (
    <Row gutter={[16, 16]}>
      <Affix offsetTop={80} style={{ width: '100%' }}>
        <Col span={24}>
          <PhoneCallPlayer
            call={call}
            comments={comments}
            allowAttachTags={false}
            review={{ id: reviewId }}
          />
        </Col>

        <Col span={24}>
          <CommentsPanel
            reviewId={reviewId}
            comments={comments}
            loading={commmentsLoading}
            showCommentsList
            allowCommenting={false}
            allowActions={false}
            useAffix
          />
        </Col>
      </Affix>
    </Row>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { reviewId } = state.reviewLibraryItemsResource.byIds[ownProps.id];
  const review = state.reviewsResource.byIds[reviewId];
  if (!review || review.loading || state.reviewsResource.loading) {
    return { loading: true, reviewId };
  }
  const currentUserPermissions = getCurrentUserPermissions(state);
  const comments = Object.values(pick(state.commentsResource.byIds, review.commentsIds || []));
  const filteredCommentsByPermission = getCommentsFilteredByPermission({
    currentUserPermissions,
    comments
  });
  const call = state.phoneCallsResource.byIds[review.phoneCallId];

  if (!call) {
    return { loading: true, reviewId };
  }

  return {
    loading: state.reviewsResource.loading,
    commmentsLoading: state.commentsResource.loading,
    reviewId,
    review: state.reviewsResource.byIds[reviewId],
    call,
    comments: filteredCommentsByPermission
  };
};

const mapDispatchToProps = {
  loadReview: reviewsResource.operations.loadById
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientInteraction);
