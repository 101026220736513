export default {
  workPlanTasks: {
    taskPlaceholder: 'Un compito',
    colorizedNumbers: {
      completed: 'Numero di controlli eseguiti',
      incompleted: 'Numero di assegni scaduti',
      total: 'Numero di controlli rimanenti'
    },
    tableColumns: {
      reviewsCount: 'Di valutazioni',
      reviewsCount2: 'Valutazioni',
      completed: 'Eseguita',
      incompleted: 'Sinistra',
      closeTask: 'Completa il compito',
      stopRepeating: 'Interrompi le ripetizioni',
      name: "Nome dell'attività",
      lastTaskTimeFrom: "La data dell'inizio",
      lastTaskTimeTo: 'Data di completamento',
      creator: 'Il creatore',
      schedule: 'Ripetizione',
      repeatedCount: 'Lanci',
      reviewCreatedAt: 'Data e ora della valutazione'
    },
    assignments: {
      closeTask: 'Completa il compito',
      close: 'Finire',
      taskStartedAt: 'Ora di pranzo',
      assignmentsCount: 'Numero di ispettori',
      completedTaskItemsCount: 'Numero totale di controlli'
    },
    filters: { createTask: 'Crea attività' },
    confirmStopRepeating: 'Interrompi le ripetizioni?',
    confirmClosing: 'Completa il compito?',
    confirmDelete: 'Elimina Configurazione?'
  }
};
