import { Form, message, Tooltip, Checkbox, Typography, Divider } from 'antd';
import EditableLabel from 'components/Inputs/EditableLabel';
import { PERMISSIONS } from 'core/utils/constants';
import { get, isEmpty, isEqual, reduce, throttle } from 'lodash';
import React, { useEffect, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { rolesResource } from 'redux/resources/roles';
import { setSelectedRole, updateSelectedRole } from 'redux/ui/rolesAndPermissions/reducer';
import SModal from 'components/Standard/SModal';
import SRow from 'components/Standard/SRow';
import SCol from 'components/Standard/SCol';
import { UserSettingPermissions } from './Permissions/UserSettingPermissions';
import { AnalitycsAndReviewsPermissions } from './Permissions/AnalitycsAndReviewsPermissions';
import { LibraryPermissions } from './Permissions/LibraryPermissions';
import { AdministrationPermissions } from './Permissions/AdministrationPermissions';
import { CommonPermissions } from './Permissions/CommonPermissions';
import { AppealsPermissions } from './Permissions/AppealsPermissions';

const createRole = rolesResource.operations.create;
const updateRole = rolesResource.operations.updateById;

const RoleModal = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { Text } = Typography;

  const selectedRole = useSelector(state => state.uiRolesAndPermissions.selectedRole, isEqual);
  const visible = !isEmpty(selectedRole);
  const initialRole = useSelector(
    state => get(state.rolesResource.byIds, selectedRole?.id, {}),
    isEqual
  );
  const loading = useSelector(
    state =>
      state.rolesResource.createStarted ||
      state.rolesResource.createStarted ||
      state.rolesResource.loading
  );

  const okText = initialRole?.id
    ? t('organizationStructure.tabs.roles.modal.buttons.update')
    : t('organizationStructure.tabs.roles.modal.buttons.save');

  const { basePermissions = [], permissions = [], id } = selectedRole;
  const { permissions: initialPermissions = [] } = initialRole;

  const initialValues = useMemo(
    () =>
      reduce(
        PERMISSIONS,
        (acc, field) => ({ ...acc, [field]: (initialPermissions || []).includes(field) }),
        {}
      ),
    [initialPermissions]
  );

  useEffect(() => {
    if (id) {
      form.setFieldsValue(initialValues);
    }
  }, [id]);

  const onValuesChange = useCallback(
    (_, fields) => {
      const permissions = reduce(
        fields,
        (acc, value, fieldName) => (value ? [...acc, fieldName] : acc),
        []
      );

      dispatch(updateSelectedRole({ permissions }));
    },
    [dispatch]
  );

  const fields = useMemo(
    () => reduce(permissions, (acc, field) => ({ ...acc, [field]: true }), {}),
    [permissions]
  );

  const onUpdate = useCallback(async () => {
    try {
      const role = await dispatch(updateRole(selectedRole));
      if (role) {
        message.success(
          t('organizationStructure.tabs.roles.modal.messages.roleSuccessfullyUpdated')
        );
        dispatch(setSelectedRole({}));
      }
    } catch (error) {
      console.log(error);
      message.error(t('organizationStructure.tabs.roles.modal.messages.updateRoleFailed'));
    }
  }, [dispatch, selectedRole]);

  const onCreate = useCallback(async () => {
    try {
      const role = await dispatch(createRole(selectedRole));
      if (role) {
        message.success(t('organizationStructure.tabs.roles.modal.messages.roleSuccessfullySaved'));
        dispatch(setSelectedRole({}));
      }
    } catch (error) {
      console.log(error);
      message.error(t('organizationStructure.tabs.roles.modal.messages.saveRoleFailed'));
    }
  }, [dispatch, selectedRole]);

  const onOk = throttle(initialRole?.id ? onUpdate : onCreate, 500);

  const onSignInRuleChange = useCallback(
    e => {
      const { checked } = e.target;

      if (!checked) {
        form.setFieldsValue(
          permissions.reduce(
            (acc, field) =>
              field === PERMISSIONS.CAN_PERFORM_CLIENT_INTERACTION ||
              field === PERMISSIONS.CAN_SEE_UNIT_OPERATORS_ANALYTICS_PAGE ||
              field === PERMISSIONS.CAN_ADD_LIBRARY_ITEMS ||
              field === PERMISSIONS.CAN_ACCESS_LIBRARY ||
              basePermissions.includes(field)
                ? acc
                : { ...acc, [field]: false },
            {}
          )
        );
      }
    },
    [permissions]
  );

  const forceCheckbox = useCallback((checked, fieldsToForce = []) => {
    if (checked)
      form.setFieldsValue(fieldsToForce.reduce((acc, field) => ({ ...acc, [field]: true }), {}));
  }, []);

  const getCheckbox = useCallback(
    (fieldName, text, fieldsToForce = [], forced = basePermissions.includes(fieldName)) => (
      <Form.Item name={fieldName} valuePropName="checked" noStyle>
        <Checkbox
          onChange={value => forceCheckbox(value, fieldsToForce)}
          {...(forced || !fields[PERMISSIONS.CAN_SIGN_IN] ? { disabled: true } : {})}
        >
          {text}
        </Checkbox>
      </Form.Item>
    ),
    [fields]
  );

  const getCheckboxWithTooltip = useCallback(
    (
      fieldName,
      text,
      tooltipText,
      fieldsToForce = [],
      forced = basePermissions.includes(fieldName)
    ) => (
      <Form.Item name={fieldName} valuePropName="checked" noStyle>
        <Checkbox
          onChange={e => forceCheckbox(e.target.checked, fieldsToForce)}
          {...(forced || !fields[PERMISSIONS.CAN_SIGN_IN] ? { disabled: true } : {})}
        >
          <Tooltip title={tooltipText} placement="top">
            {text}
          </Tooltip>
        </Checkbox>
      </Form.Item>
    ),
    [fields]
  );

  return (
    <SModal
      title={t('organizationStructure.tabs.roles.modal.title')}
      visible={visible}
      okText={okText}
      cancelText={t('organizationStructure.tabs.roles.modal.buttons.cancel')}
      onOk={onOk}
      okButtonProps={{ disabled: isEqual(selectedRole, initialRole), loading }}
      onCancel={() => {
        form.resetFields();
        dispatch(setSelectedRole({}));
      }}
      width="760px"
      destroyOnClose
    >
      <Form form={form} onValuesChange={onValuesChange} initialValues={initialValues}>
        <SRow>
          <SCol span={8}>
            <Text strong>{t('organizationStructure.tabs.roles.modal.form.nameCategory')}</Text>
            {isEmpty(selectedRole) ? null : (
              <EditableLabel
                value={selectedRole.name}
                onSave={name => dispatch(updateSelectedRole({ name }))}
              />
            )}
          </SCol>
        </SRow>

        <Divider type="horizontal" />

        <CommonPermissions onSignInRuleChange={onSignInRuleChange} />

        <Divider type="horizontal" />

        <UserSettingPermissions fields={fields} getCheckboxWithTooltip={getCheckboxWithTooltip} />

        <Divider type="horizontal" />

        <AnalitycsAndReviewsPermissions
          fields={fields}
          getCheckboxWithTooltip={getCheckboxWithTooltip}
          getCheckbox={getCheckbox}
        />

        <Divider type="horizontal" />

        <AppealsPermissions fields={fields} getCheckbox={getCheckbox} />

        <Divider type="horizontal" />

        <LibraryPermissions fields={fields} getCheckbox={getCheckbox} />

        <Divider type="horizontal" />

        <AdministrationPermissions
          getCheckboxWithTooltip={getCheckboxWithTooltip}
          getCheckbox={getCheckbox}
        />
      </Form>
    </SModal>
  );
};

export default RoleModal;
