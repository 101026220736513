import { DeleteOutlined, EditOutlined, MoreOutlined } from '@ant-design/icons';
import { Button, Col, Dropdown, Menu, message, Modal, Row, Tooltip, Typography } from 'antd';
import { Info } from 'components/Info';
import List from 'components/List';
import { CHECKLIST_DEFINITION_STATUS } from 'core/utils/constants';
import truncateString from 'core/utils/truncateString';
import { isEmpty, keyBy, some, throttle, get } from 'lodash';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { actions, operations } from 'redux/lists/questionsList';
import { questionsResource } from 'redux/resources/questions';
import { questionToGroupBindingsResource } from 'redux/resources/questionToGroupBindings';
import { getQuestionChecklistDefinitions } from 'redux/selectors/checklistItems/checklistItems';
import { setEditingQuestion } from 'redux/ui/questionModal/reducer';
import UserPreview from 'components/UserPreview';

const { Text } = Typography;

const QuestionsPageList = ({
  setEditingQuestion,
  page,
  hasNextPage,
  loading,
  updatePage,
  loadQuestions,
  questions,
  questionsByIds,
  deleteQuestion,
  deleteBinding,
  filters = {},
  clearList
}) => {
  const { t } = useTranslation();
  useEffect(() => {
    clearList();
    return clearList;
  }, []);

  useEffect(() => {
    loadQuestions({
      page,
      filters,
      sort: '-created_at',
      include: 'question-to-group-bindings.question-group.checklist-definition'
    });
  }, [page.number]);

  const onScrollY = throttle(async el => {
    const isOnBottom = (element => {
      return element.clientHeight + element.scrollTop + 20 >= element.scrollHeight;
    })(el);

    if (hasNextPage && !loading && isOnBottom) {
      const newPage = `${parseInt(page.number) + 1}`;
      updatePage({
        number: newPage
      });
    }
  }, 250);

  const tryToDeleteQuestion = async ({ id, checklistDefinitions = [] }) => {
    const question = questionsByIds[id];

    if (
      some(
        checklistDefinitions,
        ({ status }) =>
          status === CHECKLIST_DEFINITION_STATUS.ARCHIVED.value ||
          status === CHECKLIST_DEFINITION_STATUS.PUBLISHED.value
      )
    ) {
      return message.warning(t('components.questionsList.messages.blockedDeleteQuestion'));
    }

    Modal.confirm({
      title: t('components.questionsList.confirmDelete.title'),
      content: t('components.questionsList.confirmDelete.description'),
      okText: t('general.delete'),
      cancelText: t('general.cancel'),
      okType: 'danger',
      onOk: async () => {
        if (!isEmpty(checklistDefinitions)) {
          await Promise.all[question.questionToGroupBindingsIds.map(id => deleteBinding({ id }))];
        }

        await deleteQuestion({ id });
      }
    });
  };

  const handleMenuClick = ({ key, id, checklistDefinitions }) => {
    const actions = {
      edit: () => setEditingQuestion(questionsByIds[id]),
      delete: () => tryToDeleteQuestion({ id, checklistDefinitions })
      //   TODO: add copy
    };

    actions[key]();
  };

  const getMenu = ({ id, checklistDefinitions }) => (
    <Menu onClick={({ key }) => handleMenuClick({ key, id, checklistDefinitions })}>
      <Menu.Item key="edit">
        <EditOutlined />
        {t('components.questionsList.menu.edit')}
      </Menu.Item>

      <Menu.Item key="delete" style={{ color: 'var(--red_primary)' }}>
        <DeleteOutlined />
        {t('components.questionsList.menu.delete')}
      </Menu.Item>
    </Menu>
  );

  const questionChecklistsNames = ({ questionId }) => {
    const questionWithChecklistDefinitionsByIds = keyBy(questions, 'id');

    return get(questionWithChecklistDefinitionsByIds, [questionId, 'checklistDefinitions'], []).map(
      checklistDefinition => checklistDefinition.name
    );
  };

  const columns = {
    name: {
      render: (name, { text }) => (
        <Row type="flex" align="middle" gutter={[4, 4]}>
          <Col>
            <Text strong>{t('components.questionsList.listColumns.name')}</Text>
          </Col>
          <Col>
            <Tooltip title={name}>
              <Text>{truncateString(name, 25)}</Text>
            </Tooltip>
          </Col>
          <Col>{!isEmpty(text) && <Info tooltip={{ title: text }} />}</Col>
        </Row>
      ),
      col: { span: 6 }
    },
    creatorId: {
      render: creatorId => (
        <Row type="flex" align="middle" gutter={[4, 4]}>
          <Col>
            <Text strong>{t('components.questionsList.listColumns.author')}</Text>
          </Col>
          <Col>
            <UserPreview disabled userId={creatorId} showAvatar truncateSize={25} />
          </Col>
        </Row>
      ),
      col: { span: 7 }
    },
    createdAt: {
      render: createdAt => (
        <>
          <Text strong>{t('components.questionsList.listColumns.createdAt')}</Text>
          <Text>{moment(createdAt).format('DD/MM/YYYY')}</Text>
        </>
      ),
      col: { span: 4 }
    },
    status: {
      render: (status, { id, checklistDefinitions }) => (
        <>
          <Text strong>{t('components.questionsList.listColumns.isAddedToChecklist')}</Text>
          <Text>
            {checklistDefinitions[0]
              ? questionChecklistsNames({ questionId: id })[0]
              : t('components.questionsList.isNotAddedToChecklist')}
          </Text>
          {questionChecklistsNames({ questionId: id }).length > 1 && (
            <Tooltip
              title={questionChecklistsNames({ questionId: id })
                .filter(item => item !== questionChecklistsNames({ questionId: id })[0])
                .join(', ')}
            >
              <Button type="link" style={{ padding: 4 }}>
                {`${t('components.questionsList.and')} ${questionChecklistsNames({ questionId: id })
                  .length - 1}`}
              </Button>
            </Tooltip>
          )}
        </>
      ),
      col: { span: 6 }
    },
    menu: {
      render: (checklistdefinition, { id }) => (
        <Row type="flex" align="middle" justify="end">
          <Dropdown overlay={getMenu({ id })} trigger={['click']}>
            <Button icon={<MoreOutlined />} />
          </Dropdown>
        </Row>
      ),
      col: { span: 1 }
    }
  };

  return (
    <Row gutter={[8, 8]} style={{ margin: '-4px' }}>
      <PerfectScrollbar
        onScrollY={onScrollY}
        options={{ suppressScrollX: true }}
        className="list-scroll-container"
      >
        <List dataSource={questions} columns={columns} />
        {loading && (
          <Col span={24}>
            <Row type="flex" justify="center">
              <Col>
                <Text type="secondary">{t('components.questionsList.loading')}</Text>
              </Col>
            </Row>
          </Col>
        )}

        {!loading && !hasNextPage && (
          <Col span={24}>
            <Row type="flex" justify="center">
              <Col>
                <Text type="secondary">{t('components.questionsList.listLoaded')}</Text>
              </Col>
            </Row>
          </Col>
        )}
      </PerfectScrollbar>
    </Row>
  );
};

const mapStateToProps = state => {
  const { ids, loading, page, totalPages } = state.questionsList;
  const { byIds } = state.questionsResource;
  const questions = ids.reduce((acc, id) => {
    const question = byIds[id];

    if (!question) return acc;

    const data = {
      ...byIds[id],
      checklistDefinitions: getQuestionChecklistDefinitions(state, question)
    };

    return [...acc, data];
  }, []);

  return {
    questions,
    questionsByIds: byIds,
    loading,
    page,
    hasNextPage: totalPages > page.number
  };
};

const mapDispatchToProps = {
  setEditingQuestion,
  updatePage: actions.updatePage,
  clearList: actions.clearList,
  loadQuestions: operations.load,
  updateQuestion: questionsResource.operations.updateById,
  deleteQuestion: questionsResource.operations.deleteById,
  deleteBinding: questionToGroupBindingsResource.operations.deleteById
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(QuestionsPageList));
