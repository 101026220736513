import 'braft-editor/dist/index.css';
import AppRoutes from 'core/routes/AppRoutes';
import FacebookPixel from 'core/services/facebookPixel';
import { getIntegrationRoutes } from 'core/services/integrations';
import Intercom from 'core/services/intercom';
import YandexMetrika from 'core/services/yandexMetrika';
import { createBrowserHistory } from 'history';
import React from 'react';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-awesome-lightbox/build/style.css';
import './styles/app-styles.less';
const history = createBrowserHistory({});

const getRoutes = async () => {
  // * we have 2 types of intgration:
  // * 1) app routes + integrated routes (gives full access to webapp alongside with additional pages)
  // * 2) integrated only routes (specific pages (layouts) for this integration)

  // return (
  //   <>
  //     <YandexMetrika history={history} />
  //     <FacebookPixel history={history} />
  //     <Intercom history={history} />
  //     <AppRoutes history={history} integratedRoutes={Routes} />
  //   </>
  // );
  // * returns additional routes(1) or routes component(2)
  const Routes = await getIntegrationRoutes(history);

  return Array.isArray(Routes) ? (
    <>
      <YandexMetrika history={history} />
      <FacebookPixel history={history} />
      <Intercom history={history} />
      <AppRoutes history={history} integratedRoutes={Routes} />
    </>
  ) : (
    <>
      <Intercom history={history} />
      <YandexMetrika history={history} />
      <FacebookPixel history={history} />
      <Routes history={history} />
    </>
  );
};

export default getRoutes;
