export default {
  pagesMeta: {
    loginPage: { title: 'Accedi | qolio.io' },
    registerPage: { title: 'Registrazione | qolio.io' },
    recoverPasswordPage: { title: 'Recupero password | qolio.io' },
    setNewPasswordPage: { title: 'Impostazioni di una nuova password | qolio.io' },
    organizationSettingsPage: { title: "Impostazioni dell'organizzazione | qolio.io" },
    checklistEditorPage: { title: 'Impostazioni modulo di valutazione | qolio.io' },
    checklistQuestionsEditor: { title: 'Impostazioni criteri | qolio.io' },
    checklistsAndQuestionsPage: { title: 'Moduli di valutazione | qolio.io' },
    callPage: { title: 'Chiamata | qolio.io' },
    reviewPage: { title: 'Valutazione | qolio.io' },
    textCommunicationPage: { title: 'Comunicazione testuale | qolio.io' },
    clientInteractionsPage: { title: 'Elenco comunicazioni | qolio.io' },
    calibrationsSessionsPages: {
      calibrationsSessions: { title: 'Sessioni di calibrazione | qolio.io' },
      viewCalibrationSession: { title: 'Sessione corrente | qolio.io' },
      calibrationSessionResult: { title: 'Risultati della sessione' }
    },
    customCommunicationReviewPage: {
      titles: {
        review: 'Valutazione | qolio.io',
        addReview: 'Nuovo controllo | qolio.io'
      }
    },
    dashboardPage: { title: 'Analitica | qolio.io' },
    notFoundPage: { title: 'Pagina non trovata | qolio.io' },
    libraryPage: { title: 'Libreria delle chiamate | qolio.io' },
    organizationStructurePage: {
      tabs: {
        organizationStructure: { title: 'Struttura aziendale | qolio.io' },
        employees: { title: 'Dipendenti | qolio.io' },
        permissionsAndLevels: { title: 'Diritti di accesso e livelli dei dipendenti | qolio.io' },
        notificationsAndStatuses: { title: 'Notifiche e stati dei contatti | qolio.io' }
      }
    },
    integrationsSettingsPage: { title: 'Integrazioni | qolio.io' },
    editTaskDefinitionCardPage: {
      titles: {
        createTag: 'Crea tag | qolio.io',
        updateTag: 'Modifica tag | qolio.io'
      }
    },
    triggersSettingsPage: { title: 'Tag | qolio.io' },
    userSettingsPage: { title: 'Impostazioni utente| qolio.io' },
    teamPage: { title: 'Squadra | qolio.io' },
    uploadCallsPage: { title: 'Caricamento delle chiamate | qolio.io' },
    userAnalyticsPage: { title: 'Area personale | qolio.io' },
    workPlanTaskConfigurationsPage: { title: 'Compiti | qolio.io' },
    appealsPage: { title: 'Ricorsi | qolio.io' },
    appealsSettingsPage: { title: 'Impostazioni ricorsi | qolio.io' }
  }
};
