import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Row } from 'antd';
import { connect } from 'react-redux';
import { isEmpty, get } from 'lodash';
import { loadDashboards } from 'redux/ui/dashboard/operations';
import { toogleVisibleDrawer } from 'redux/ui/dashboard/reducer';
import { COLOR_PALETTE_6 } from 'core/utils/colors';
import { useTranslation } from 'react-i18next';

// * small widgets
import ScoreWidget from './SmallWidgets/ScoreWidget';
import TimeWidget from './SmallWidgets/TimeWidget';

// * medium widgets
import LabelsCountByUnits from './MediumWidgets/LabelsCountByUnits/LabelsCountByUnits';
import LabelsCountByOperators from './MediumWidgets/LabelsCountByOperators/LabelsCountByOperators';
import RatingFlagsCount from './MediumWidgets/RatingFlagsCount/RatingFlagsCount';
import ReviewsCountByReviewers from './MediumWidgets/ReviewsCountByReviewers/ReviewsCountByReviewers';
import RatingFlagsCountByUnits from './MediumWidgets/RatingFlagsCountByUnits/RatingFlagsCountByUnits';
import PhoneCallsCountByOperators from './MediumWidgets/PhoneCallsCountByOperators/PhoneCallsCountByOperators';
import PhoneCallsAverageDurationByUnits from './MediumWidgets/PhoneCallsAverageDurationByUnits/PhoneCallsAverageDurationByUnits';
import PhoneCallsAverageDurationByOperators from './MediumWidgets/PhoneCallsAverageDurationByOperators/PhoneCallsAverageDurationByOperators';
import RatingFlagsCountByOperators from './MediumWidgets/RatingFlagsCountByOperators/RatingFlagsCountByOperators';
import ChecklistDefinitionQuestionsAverageScores from './MediumWidgets/ChecklistDefinitionQuestionsAverageScores/ChecklistDefinitionQuestionsAverageScores';
import ChecklistDefinitionAverageScoreByUnits from './MediumWidgets/ChecklistDefinitionAverageScoreByUnits/ChecklistDefinitionAverageScoreByUnits';
import ChecklistDefinitionAverageScoreByOperators from './MediumWidgets/ChecklistDefinitionAverageScoreByOperators/ChecklistDefinitionAverageScoreByOperators';
import ChecklistDefinitionAverageScoreByChecklistDefinitions from './MediumWidgets/ChecklistDefinitionAverageScoreByChecklistDefinitions/ChecklistDefinitionAverageScoreByChecklistDefinitions';
import ChecklistDefinitionQuestionGroupsAverageScores from './MediumWidgets/ChecklistDefinitionQuestionGroupsAverageScores/ChecklistDefinitionQuestionGroupsAverageScores';
import ChecklistDefinitionQuestionsAverageScoresByQuestionGroups from './MediumWidgets/ChecklistDefinitionQuestionsAverageScoresByQuestionGroups/ChecklistDefinitionQuestionsAverageScoresByQuestionGroups';
import PhoneCallsCountByUnits from './MediumWidgets/PhoneCallsCountByUnits/PhoneCallsCountByUnits';
import ReviewsCountByReviewersHistory from './LargeWidgets/ReviewsCountByReviewersHistory/ReviewsCountByReviewersHistory';
import ChecklistDefinitionAverageScoreByOperatorsHistory from './LargeWidgets/ChecklistDefinitionAverageScoreByOperatorsHistory/ChecklistDefinitionAverageScoreByOperatorsHistory';
import ChecklistDefinitionAverageScoreByUnitsHistory from './LargeWidgets/ChecklistDefinitionAverageScroeByUnitsHistory/ChecklistDefinitionAverageScroeByUnitsHistory';
import AverageScoreByQuestionsHistory from './LargeWidgets/AverageScoreByQuestionsHistory/AverageScoreByQuestionsHistory';
import AverageScoreByQuestionGroupsHistory from './LargeWidgets/AverageScoreByQuestionGroupsHistory/AverageScoreByQuestionGroupsHistory';
import PhoneCallsAverageDurationByOperatorsHistory from './LargeWidgets/PhoneCallsAverageDurationByOperatorsHistory/PhoneCallsAverageDurationByOperatorsHistory';
import PhoneCallsAverageDurationByUnitsHistory from './LargeWidgets/PhoneCallsAverageDurationByUnitsHistory/PhoneCallsAverageDurationByUnitsHistory';
import ClientInteractionsCountByOperatorsHistory from './LargeWidgets/ClientInteractionsCountByOperatorsHistory/ClientInteractionsCountByOperatorsHistory';
import ClientInteractionsCountByUnitsHistory from './LargeWidgets/ClientInteractionsCountByUnitsHistory/ClientInteractionsCountByUnitsHistory';

// * extra_large widgets
import TableReviewsCountByOperators from './ExtraLargeWidgets/TableReviewsCountByOperators/TableReviewsCountByOperators';
import TableChecklistItemsByOperators from './ExtraLargeWidgets/TableChecklistItemsByOperators/TableChecklistItemsByOperators';
import TableChecklistItemsHistory from './ExtraLargeWidgets/TableChecklistItemsHistory/TableChecklistItemsHistory';
import FlagsAndComments from './ExtraLargeWidgets/FlagsAndComments/FlagsAndComments';
import TableChecklistsScoresByOperators from './ExtraLargeWidgets/TableChecklistsScoresByOperators/TableChecklistsScoresByOperators';
import TableReviewsCountByReviewers from './ExtraLargeWidgets/TableReviewsCountByReviewers/TableReviewsCountByReviewers';
import TableReviewsWithScores from './ExtraLargeWidgets/TableReviewsWithScores/TableReviewsWithScores';

// * empty entities
import EmptyWidget from './Components/EmptyWidget';
import EmptyDashboard from './Components/EmptyDashboard';
import EmptyDashboardRows from './Components/EmptyDashboardRows';
import LoadingDashboard from './Components/LoadingDashboard';

import DashboardSettingsDrawer from './DashboardSettingsDrawer/DashboardSettingsDrawer';

const DashboardPage = ({
  loadDashboards,
  dashboardsByIds,
  loading,
  activeDashboardId,
  dashboardRowsByIds,
  dashboardWidgetsByIds,
  toogleVisibleDrawer,
  loadingWidgetId,
  activeDashboardRowsIds
}) => {
  useEffect(() => {
    loadDashboards();
  }, []);

  const { t } = useTranslation();

  const renderWidget = widgetData => {
    const emptyWidgetRenderer = data => (
      <EmptyWidget key={data.id} widgetData={data} loadingWidgetId={loadingWidgetId} />
    );
    const widgetTypeToComponentRenderer = {
      // ! small widgets
      checklist_definition_average_score: data => (
        <ScoreWidget widgetData={data} key={data.id} loadingWidgetId={loadingWidgetId} />
      ),

      phone_calls_count: data => (
        <ScoreWidget widgetData={data} key={data.id} loadingWidgetId={loadingWidgetId} />
      ),

      phone_calls_average_duration: data => (
        <TimeWidget widgetData={data} key={data.id} loadingWidgetId={loadingWidgetId} />
      ),
      total_phone_calls_duration: data => (
        <TimeWidget widgetData={data} key={data.id} loadingWidgetId={loadingWidgetId} />
      ),
      reviewed_client_interactions_duration: data => (
        <TimeWidget widgetData={data} key={data.id} loadingWidgetId={loadingWidgetId} />
      ),
      reviewed_client_interactions_percentage: data => (
        <ScoreWidget widgetData={data} key={data.id} loadingWidgetId={loadingWidgetId} />
      ),
      // ! medium widgets
      checklist_definition_average_score_by_units: data => (
        <ChecklistDefinitionAverageScoreByUnits
          widgetData={data}
          key={data.id}
          colors={['var(--blue_6)']}
          loadingWidgetId={loadingWidgetId}
        />
      ),

      checklist_definition_average_score_by_operators: data => (
        <ChecklistDefinitionAverageScoreByOperators
          widgetData={data}
          key={data.id}
          colors={['var(--green_6)']}
          loadingWidgetId={loadingWidgetId}
          maxValue
        />
      ),

      checklist_definition_average_score_by_checklist_definitions: data => (
        <ChecklistDefinitionAverageScoreByChecklistDefinitions
          widgetData={data}
          key={data.id}
          colors="var(--orange_6)"
          loadingWidgetId={loadingWidgetId}
        />
      ),

      phone_calls_count_by_units: data => (
        <PhoneCallsCountByUnits
          widgetData={data}
          key={data.id}
          colors={['var(--blue_6)']}
          loadingWidgetId={loadingWidgetId}
        />
      ),

      phone_calls_count_by_operators: data => (
        <PhoneCallsCountByOperators
          widgetData={data}
          key={data.id}
          colors={['var(--green_6)']}
          loadingWidgetId={loadingWidgetId}
        />
      ),

      reviews_count_by_reviewers: data => (
        <ReviewsCountByReviewers
          widgetData={data}
          key={data.id}
          colors="var(--green_6)"
          loadingWidgetId={loadingWidgetId}
        />
      ),

      rating_flags_count: data => (
        <RatingFlagsCount widgetData={data} key={data.id} loadingWidgetId={loadingWidgetId} />
      ),

      rating_flags_count_by_units: data => (
        <RatingFlagsCountByUnits
          widgetData={data}
          key={data.id}
          loadingWidgetId={loadingWidgetId}
        />
      ),

      rating_flags_count_by_operators: data => (
        <RatingFlagsCountByOperators
          widgetData={data}
          key={data.id}
          loadingWidgetId={loadingWidgetId}
        />
      ),

      labels_count_by_operators: data => (
        <LabelsCountByOperators widgetData={data} key={data.id} loadingWidgetId={loadingWidgetId} />
      ),

      labels_count_by_units: data => (
        <LabelsCountByUnits widgetData={data} key={data.id} loadingWidgetId={loadingWidgetId} />
      ),

      checklist_definition_questions_average_scores: data => (
        <ChecklistDefinitionQuestionsAverageScores
          widgetData={data}
          key={data.id}
          loadingWidgetId={loadingWidgetId}
          colors="var(--orange_6)"
        />
      ),

      checklist_definition_questions_average_scores_by_question_groups: data => (
        <ChecklistDefinitionQuestionsAverageScoresByQuestionGroups
          widgetData={data}
          key={data.id}
          loadingWidgetId={loadingWidgetId}
          colors={Object.values(COLOR_PALETTE_6)}
        />
      ),

      checklist_definition_question_groups_average_scores: data => (
        <ChecklistDefinitionQuestionGroupsAverageScores
          widgetData={data}
          key={data.id}
          loadingWidgetId={loadingWidgetId}
          colors="var(--orange_6)"
        />
      ),

      phone_calls_average_duration_by_units: data => (
        <PhoneCallsAverageDurationByUnits
          widgetData={data}
          key={data.id}
          colors={['var(--blue_6)']}
          loadingWidgetId={loadingWidgetId}
        />
      ),

      phone_calls_average_duration_by_operators: data => (
        <PhoneCallsAverageDurationByOperators
          widgetData={data}
          key={data.id}
          colors={['var(--green_6)']}
          loadingWidgetId={loadingWidgetId}
        />
      ),

      // ! large widgets
      reviews_count_by_reviewers_history: data => (
        <ReviewsCountByReviewersHistory
          widgetData={data}
          key={data.id}
          loadingWidgetId={loadingWidgetId}
          colors={Object.values(COLOR_PALETTE_6)}
        />
      ),

      checklist_definition_average_score_by_operators_history: data => (
        <ChecklistDefinitionAverageScoreByOperatorsHistory
          widgetData={data}
          key={data.id}
          loadingWidgetId={loadingWidgetId}
          colors={Object.values(COLOR_PALETTE_6)}
        />
      ),

      checklist_definition_average_score_by_units_history: data => (
        <ChecklistDefinitionAverageScoreByUnitsHistory
          widgetData={data}
          key={data.id}
          loadingWidgetId={loadingWidgetId}
          colors={Object.values(COLOR_PALETTE_6)}
        />
      ),

      average_score_by_questions_history: data => (
        <AverageScoreByQuestionsHistory
          widgetData={data}
          key={data.id}
          loadingWidgetId={loadingWidgetId}
          colors={Object.values(COLOR_PALETTE_6)}
        />
      ),

      average_score_by_question_groups_history: data => (
        <AverageScoreByQuestionGroupsHistory
          widgetData={data}
          key={data.id}
          loadingWidgetId={loadingWidgetId}
          colors={Object.values(COLOR_PALETTE_6)}
        />
      ),

      phone_calls_average_duration_by_operators_history: data => (
        <PhoneCallsAverageDurationByOperatorsHistory
          widgetData={data}
          key={data.id}
          loadingWidgetId={loadingWidgetId}
          colors={Object.values(COLOR_PALETTE_6)}
        />
      ),

      phone_calls_average_duration_by_units_history: data => (
        <PhoneCallsAverageDurationByUnitsHistory
          widgetData={data}
          key={data.id}
          loadingWidgetId={loadingWidgetId}
          colors={Object.values(COLOR_PALETTE_6)}
        />
      ),

      client_interactions_count_by_operators_history: data => (
        <ClientInteractionsCountByOperatorsHistory
          widgetData={data}
          key={data.id}
          loadingWidgetId={loadingWidgetId}
          colors={Object.values(COLOR_PALETTE_6)}
        />
      ),

      client_interactions_count_by_units_history: data => (
        <ClientInteractionsCountByUnitsHistory
          widgetData={data}
          key={data.id}
          loadingWidgetId={loadingWidgetId}
          colors={Object.values(COLOR_PALETTE_6)}
        />
      ),

      // ! extra large widgets
      table_reviews_count_by_operators: data => (
        <TableReviewsCountByOperators
          widgetData={data}
          key={data.id}
          loadingWidgetId={loadingWidgetId}
        />
      ),
      table_checklist_items_by_operators: data => (
        <TableChecklistItemsByOperators
          widgetData={data}
          key={data.id}
          loadingWidgetId={loadingWidgetId}
        />
      ),
      table_checklist_items_history: data => (
        <TableChecklistItemsHistory
          widgetData={data}
          key={data.id}
          loadingWidgetId={loadingWidgetId}
        />
      ),
      flags_and_comments: data => (
        <FlagsAndComments widgetData={data} key={data.id} loadingWidgetId={loadingWidgetId} />
      ),
      table_checklists_scores_by_operators: data => (
        <TableChecklistsScoresByOperators
          widgetData={data}
          key={data.id}
          loadingWidgetId={loadingWidgetId}
        />
      ),
      table_reviews_count_by_reviewers: data => (
        <TableReviewsCountByReviewers
          widgetData={data}
          key={data.id}
          loadingWidgetId={loadingWidgetId}
        />
      ),
      table_reviews_with_scores: data => (
        <TableReviewsWithScores widgetData={data} key={data.id} loadingWidgetId={loadingWidgetId} />
      )
    };

    const rendererFunction = get(
      widgetTypeToComponentRenderer,
      widgetData.type,
      emptyWidgetRenderer
    );
    return rendererFunction(widgetData);
  };

  const dashboardStatusToRenderComponent = ({
    loading,
    dashboardsByIds,
    activeDashboardRowsIds
  }) => {
    if (loading) return <LoadingDashboard />;
    if (isEmpty(dashboardsByIds) && isEmpty(activeDashboardRowsIds))
      return <EmptyDashboard loading={loading} />;
    if (isEmpty(activeDashboardRowsIds) && !isEmpty(dashboardsByIds))
      return (
        <EmptyDashboardRows
          loading={loading || !dashboardsByIds[activeDashboardId]}
          toogleVisibleDrawer={toogleVisibleDrawer}
        />
      );
    return (
      <>
        {activeDashboardRowsIds.map(rowId => (
          <Row gutter={[8, 4]} key={rowId} style={{ margin: '4px' }}>
            {get(dashboardRowsByIds, [rowId, 'widgetsIds'], []).map(widgetId =>
              renderWidget(dashboardWidgetsByIds[widgetId])
            )}
          </Row>
        ))}
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>{t('pagesMeta.dashboardPage.title')}</title>
      </Helmet>
      {dashboardStatusToRenderComponent({ loading, dashboardsByIds, activeDashboardRowsIds })}
      {!isEmpty(dashboardsByIds) && <DashboardSettingsDrawer />}
    </>
  );
};

const mapStateToProps = state => {
  const { loadingWidgetId } = state.dashboardAnalytics;
  const dashboardRowsByIds = state.dashboardRowsResource.byIds;
  const { activeDashboardId } = state.uiDashboard;
  const dashboardsByIds = state.dashboardsResource.byIds;

  const activeDashboardRowsIds = get(dashboardsByIds, [activeDashboardId, 'rowsIds'], []).filter(
    row => dashboardRowsByIds[row]
  );

  return {
    loading: state.dashboardAnalytics.loading,
    loadingWidgetId,
    dashboardsByIds,
    activeDashboardId: state.uiDashboard.activeDashboardId,
    dashboardRowsByIds: state.dashboardRowsResource.byIds,
    dashboardWidgetsByIds: state.dashboardWidgetsResource.byIds,
    activeDashboardRowsIds
  };
};

const mapDispatchToProps = {
  loadDashboards,
  toogleVisibleDrawer
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPage);
