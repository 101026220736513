export default {
  checklistSettingsPage: {
    questionsWithoutGroup: 'Preguntas sin grupo',
    checklistSuccessfullyCreated: 'La lista a chequear se ha generado',
    checklistSuccessfullyUpdated: 'La lista a chequear se ha actualizado correctamente',
    updateChecklistFailed: 'Se se pudo actualizar la lista a chequear',
    createChecklistFailed: 'No se pudo generar la lista a chequear',
    breadcrumbs: {
      checklists: 'Lista a chequear',
      checklistSettings: 'Ajustes de la lista a chequear'
    },
    title: 'Ajustes de la lista a chequear',
    info:
      'Señale aquí los parámetros generales de los ajustes de la lista a chequear, los cuales serán utilizados a todas las preguntas de las listas a chequear. Presiones el botón "Guardar", para pasar a la página de formación de grupos y añadir preguntas a la lista a chequear. Usted puede cambiar los datos de ajuste en cualquier momento. Los detalles de todos los ajustes y posibilidades se puede ',
    infoButton: 'leer aquí.',
    form: {
      title: 'Ajustes principales',
      fields: {
        name: 'Nombre',
        nameInfo:
          'Este nombre se refleja al legir la lista a chequear de evaluación o en los bidget analíticos.',
        namePlaceholder: 'Introduzca el nombre de la lista a chequear',
        description: 'Descripción',
        descriptionInfo:
          'Describa con que objetivo usted utiliza esta lista a chequear, que o a quíen quiere evaluar con su ayuda',
        descriptionPlaceholder: 'Ingrese una descripción de la lista de verificación',
        units: 'Departamentos',
        unitsInfo:
          'La lista a chequear estará disponible para evaluar la comunicación solo para los departamentos elegidos.',
        unitsPlaceholder: 'Elija un departamento',
        unitsPlaceholderMax: 'Departamentos:',
        communicationTypes: 'Tipos de comunicación',
        communicationType: 'Tipo de comunicación:',
        communicationTypesPlaceholder: 'Elija el tipo de comunicación',
        communicationTypesPlaceholderMax: 'Tipos de comunicación:',
        communicationTypesInfo:
          'La lista a chequear estará disponible para la evaluación solo para los tipos de comunicación elejidos.',
        ratingMethod: 'Método de evaluación',
        ratingMethodInfo:
          'En la evaluación base cualquier punto de la lista a chequear tiene la misma importancia. Al elegir "Equlibrado" usted deberá señalar el % de significancia de la puntuación dada en la pregunta (punto) en el recuento de la evaluación media de la lista a chequear.',
        ratingCalculation: 'Cáculo de la evaluación',
        ratingCalculationInfo: 'Elija que resultado total quiere obtener por la lista a chequear.',
        ratingMode: 'Reflejar la evaluación',
        ratingModeInfo:
          'Elija cómo quiere ver el resultado final - puntuación alcanzada o el porcentaje del resultado máximo posible',
        additionalSettings: 'Ajustes adicionales',
        scaleType: 'Escala de evaluación',
        scaleTypeInfo:
          'Elija una escala de las existentes o en la "escala libre" señale cuales son las notas que usted necesita en la evaluación de la lista a chequear.',
        valuesDisplayType: 'Reflejado en la lista a chequear',
        valuesDisplayTypeInfo:
          'El reflejo gráfico (mime) de la escala estará disponible solo en la página de evaluación. El resultado final se reflejará en cifras.',
        autofailEnabled: 'Archivo automático de la lista a chequear',
        autofailEnabledInfo:
          'Elija "Si", si quiere agregar preguntas en la lista a chequear, cuya puntuación mínima conducirá a una evaluación automática de la misma. En este caso la evaluación total de toda la lista a chequear será la mínima posible.',
        noAnswerAsMax: 'Observe N/A como máx. puntuación de criterio',
        noAnswerAsMaxInfo:
          'Seleccione "Sí" si desea que los criterios que quedaron sin respuesta se consideren como el valor máximo posible para este criterio'
      },
      messages: { enterName: 'Por favor, introduzca el nombre' },
      alerts: {
        disabledByQuestions: {
          title:
            'Usted no puede cabiar la escala de evaluación mientras que en la lista a chequear haya preguntas con una escala de evaluación asignada'
        },
        colorZones: {
          title: 'Zonas de evaluación',
          descriptionPT1:
            "Como usted eligió el cálculo de la evaluación ' suma de los puntos o Escala de evaluación - La escala libre y el ejuste de las zonas a evaluar estarán activas antes de publicar la lista a chequear.",
          descriptionPT2:
            'Las zonas permiten añadir y ajustar los resultados totales de la lista a chequear por colores, o sea cuando la puntuación total cae en el diapasón elegido se reflejará en el color asignado a esa zona.'
        },
        button: 'Detalles aquí'
      }
    },
    buttons: {
      cancel: 'Cancelar',
      save: 'Guardar'
    }
  }
};
