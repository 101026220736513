import { Select, Typography } from 'antd';
import { getUserName } from 'components/UserPreview/getUserName';
import { concat, get, uniq } from 'lodash';
import React, { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import UserPreview from 'components/UserPreview';

const { Option } = Select;

const defaultValue = [];

const searchInnactiveUsersIds = ({ value, usersByIds }) => {
  return value.filter(userId => !get(usersByIds, `${userId}.active`, false));
};

const getUsers = ({ usersByIds, usersToSelect, value }) => {
  return uniq(
    concat(
      searchInnactiveUsersIds({ value, usersByIds }),
      usersToSelect.map(o => o.id)
    )
  );
};

const SpecificUsersSelect = ({
  placeholder,
  mode,
  onChange,
  filterOption,
  style,
  allowClear,
  maxTagCount,
  maxTagPlaceholder,
  loading,
  disabled,
  optionLabelProp,
  value = defaultValue, // * usersIds form value
  usersToSelect, // * filtered users to display
  hidden
}) => {
  const { t } = useTranslation();
  const usersByIds = useSelector(state => state.usersResource.byIds, shallowEqual);

  const { Text } = Typography;

  const usersToSelectWithInnactiveUsers = useMemo(
    () => getUsers({ usersByIds, usersToSelect, value }),
    [usersByIds, usersToSelect, value]
  );

  return (
    <Select
      placeholder={placeholder}
      mode={mode}
      onChange={onChange}
      filterOption={filterOption}
      style={style}
      allowClear={allowClear}
      maxTagCount={maxTagCount}
      maxTagPlaceholder={maxTagPlaceholder}
      loading={loading}
      disabled={disabled}
      optionLabelProp={optionLabelProp}
      value={value}
    >
      {usersToSelectWithInnactiveUsers.map(userId => {
        const isActiveUser = get(usersByIds, `${userId}.active`, false);
        return (
          <Option key={userId} value={userId} label={getUserName({ user: usersByIds[userId] })}>
            <UserPreview
              user={usersByIds[userId]}
              disabled
              style={{ height: 14 }}
              hidden={hidden}
            />
            {!isActiveUser && <Text strong>{t('constants.userStatuses.disabled')}</Text>}
          </Option>
        );
      })}
    </Select>
  );
};

export default SpecificUsersSelect;
