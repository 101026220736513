import { Button, Layout } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';

const BitrixDetailsLayout = ({ children }) => {
  const { Content } = Layout;
  const history = useHistory();
  return (
    <Layout>
      <Button type="link" onClick={() => history.push('/')} style={{ textAlign: 'left' }}>
        Назад
      </Button>
      <Content style={{ margin: '16px' }}>{children}</Content>
    </Layout>
  );
};

export default BitrixDetailsLayout;
