import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { getCurrentUser } from 'redux/selectors/users';
import { get, isEmpty } from 'lodash';
import { conditionalRouting } from './conditionalRouting';
import LoadingPage from '../ErrorPages/LoadingPage';
import BitrixService from 'core/services/bitrix';
import { useDispatch, useSelector } from 'react-redux';
import { checkUncompleteBitrixInstallation } from 'redux/entities/bitrixIntegration/operations';

const RootPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const currentUser = useSelector(getCurrentUser);

  const currentUserPermissions = get(currentUser, 'role.permissions', []);

  const checkBitrixInstall = async () => {
    if (BitrixService.isIntegrated) {
      await dispatch(checkUncompleteBitrixInstallation({ history }));
    }
  };

  useEffect(() => {
    if (currentUser) {
      checkBitrixInstall();
    }

    const firstRoute = conditionalRouting({ currentUserPermissions, currentUser });

    if (isEmpty(currentUserPermissions) || isEmpty(firstRoute)) {
      history.push('/settings/user');
    } else {
      history.push(firstRoute || '/settings/user');
    }
  }, [currentUserPermissions]);

  return <LoadingPage />;
};

export default RootPage;
