import React from 'react';
import { Col, Row, Typography, Tooltip } from 'antd';
import { get } from 'lodash';
import ColorZonesBar from 'components/Team/ColorZonesBar';
import DifferenceArrow from 'components/DifferenceArrow';
import RedFlag from 'components/Flags/RedFlag';
import YellowFlag from 'components/Flags/YellowFlag';
import GreenFlag from 'components/Flags/GreenFlag';
import StandardComment from 'components/Flags/StandardComment';
import { beatifyFloat } from 'core/utils/convertFloat';
import { ANALYTICS_WIDGETS_NAMES, RATING_MODE } from 'core/utils/constants';
import { Trans } from 'react-i18next';

const Columns = ({ filters, checklistdefinitionsByIds }) => {
  const { Text } = Typography;

  const selectedChecklistDefinition = get(
    checklistdefinitionsByIds,
    filters.checklistDefinitionsIds
  );

  const displayTypeString =
    selectedChecklistDefinition?.ratingMode === RATING_MODE.PERCENTAGE ? (
      '%'
    ) : (
      <Trans i18nKey="teamPage.unitTable.differenceArrowScoreLiteral" />
    );

  return [
    {
      title: <Trans i18nKey="teamPage.unitTable.columns.unit" />,
      dataIndex: 'name',
      key: 'name',
      width: 200,
      ellipsis: true,
      render: text => (
        <Tooltip title={text} placement="topLeft">
          <Text>{text}</Text>
        </Tooltip>
      ),
      sorter: (a, b) => a.name.localeCompare(b.name)
    },
    {
      title: (
        <Tooltip title={<Trans i18nKey="teamPage.unitTable.infoIcons.scoreInfo" />}>
          <Text>
            <Trans i18nKey="teamPage.unitTable.columns.score" />
          </Text>
        </Tooltip>
      ),
      dataIndex: ANALYTICS_WIDGETS_NAMES.CHECKLIST_DEFINITION_AVERAGE_SCORE_BY_OPERATORS,
      key: ANALYTICS_WIDGETS_NAMES.CHECKLIST_DEFINITION_AVERAGE_SCORE_BY_OPERATORS,
      align: 'center',
      width: 100,
      render: (text, record) => (
        <Row type="flex" align="middle" justify="center" gutter={[6, 0]}>
          <Col>
            <Text>
              {filters.checklistDefinitionsIds === 'all' || !filters.checklistDefinitionsIds
                ? 'N/A'
                : `${beatifyFloat(text)}${
                    get(selectedChecklistDefinition, 'ratingMode') === 'percentage' ? '%' : ''
                  }`}
            </Text>
          </Col>
          <Col>
            {filters.checklistDefinitionsIds === 'all' ||
            !filters.checklistDefinitionsIds ? null : (
              <DifferenceArrow
                previousPeriod={record.checklist_definition_average_score_by_operators_previous}
                currentPeriod={text}
                displayTypeString={displayTypeString}
              />
            )}
          </Col>
        </Row>
      ),
      sorter: (a, b) =>
        a[ANALYTICS_WIDGETS_NAMES.CHECKLIST_DEFINITION_AVERAGE_SCORE_BY_OPERATORS] -
        b[ANALYTICS_WIDGETS_NAMES.CHECKLIST_DEFINITION_AVERAGE_SCORE_BY_OPERATORS]
    },
    {
      title: (
        <Tooltip title={<Trans i18nKey="teamPage.unitTable.infoIcons.reviewsCountInfo" />}>
          <Text>
            <Trans i18nKey="teamPage.unitTable.columns.reviewsCount" />
          </Text>
        </Tooltip>
      ),
      dataIndex: ANALYTICS_WIDGETS_NAMES.REVIEWS_COUNT_BY_OPERATORS,
      key: ANALYTICS_WIDGETS_NAMES.REVIEWS_COUNT_BY_OPERATORS,
      align: 'center',
      width: 130,
      sorter: (a, b) =>
        a[ANALYTICS_WIDGETS_NAMES.REVIEWS_COUNT_BY_OPERATORS] -
        b[ANALYTICS_WIDGETS_NAMES.REVIEWS_COUNT_BY_OPERATORS]
    },
    {
      title: (
        <Tooltip title={<Trans i18nKey="teamPage.unitTable.infoIcons.greenZoneCountInfo" />}>
          <Text>
            <Trans i18nKey="teamPage.unitTable.columns.greenZoneCount" />
          </Text>
        </Tooltip>
      ),
      dataIndex: 'greenZonePercentage',
      key: 'greenZonePercentage',
      align: 'center',
      width: 120,
      render: (text, record) => (
        <Row type="flex" align="middle" justify="center" gutter={[6, 0]}>
          <Col>
            <Text>{`${beatifyFloat(text)}%`}</Text>
          </Col>
          <Col>
            <DifferenceArrow
              previousPeriod={record.greenZonePercentagePrevious}
              currentPeriod={text}
              displayTypeString="%"
            />
          </Col>
        </Row>
      ),
      sorter: (a, b) => a.greenZonePercentage - b.greenZonePercentage
    },
    {
      title: (
        <Tooltip title={<Trans i18nKey="teamPage.unitTable.infoIcons.allScoresInfo" />}>
          <Text>
            <Trans i18nKey="teamPage.unitTable.columns.allScores" />
          </Text>
        </Tooltip>
      ),
      key: ANALYTICS_WIDGETS_NAMES.CHECKLIST_DEFINITION_COLOR_ZONES_BY_OPERATORS,
      dataIndex: ANALYTICS_WIDGETS_NAMES.CHECKLIST_DEFINITION_COLOR_ZONES_BY_OPERATORS,
      render: (text, record) => (
        <ColorZonesBar
          data={text}
          reviewsCount={record[ANALYTICS_WIDGETS_NAMES.REVIEWS_COUNT_BY_OPERATORS]}
        />
      ),
      align: 'center'
    },
    {
      title: <StandardComment />,
      key: `${ANALYTICS_WIDGETS_NAMES.RATING_FLAGS_COUNT_BY_OPERATORS}.without_flag`,
      dataIndex: ANALYTICS_WIDGETS_NAMES.RATING_FLAGS_COUNT_BY_OPERATORS,
      width: 85,
      render: text => (
        <Row gutter={[4, 4]} type="flex" justify="center">
          <Col>
            <StandardComment />
          </Col>
          <Col>
            <Text>{`${get(text, 'without_flag', 0)}`}</Text>
          </Col>
        </Row>
      ),
      align: 'center',
      sorter: (a, b) =>
        get(a, `${ANALYTICS_WIDGETS_NAMES.RATING_FLAGS_COUNT_BY_OPERATORS}.without_flag`, 0) -
        get(b, `${ANALYTICS_WIDGETS_NAMES.RATING_FLAGS_COUNT_BY_OPERATORS}.without_flag`, 0)
    },

    {
      title: <RedFlag />,
      key: `${ANALYTICS_WIDGETS_NAMES.RATING_FLAGS_COUNT_BY_OPERATORS}.red`,
      dataIndex: ANALYTICS_WIDGETS_NAMES.RATING_FLAGS_COUNT_BY_OPERATORS,
      width: 85,
      render: text => (
        <Row gutter={[4, 4]} type="flex" justify="center">
          <Col>
            <RedFlag />
          </Col>
          <Col>
            <Text>{`${get(text, 'red', 0)}`}</Text>
          </Col>
        </Row>
      ),
      align: 'center',
      sorter: (a, b) =>
        get(a, `${ANALYTICS_WIDGETS_NAMES.RATING_FLAGS_COUNT_BY_OPERATORS}.red`, 0) -
        get(b, `${ANALYTICS_WIDGETS_NAMES.RATING_FLAGS_COUNT_BY_OPERATORS}.red`, 0)
    },

    {
      title: <YellowFlag />,
      key: `${ANALYTICS_WIDGETS_NAMES.RATING_FLAGS_COUNT_BY_OPERATORS}.yellow`,
      dataIndex: ANALYTICS_WIDGETS_NAMES.RATING_FLAGS_COUNT_BY_OPERATORS,
      width: 85,
      render: text => (
        <Row gutter={[4, 4]} type="flex" justify="center">
          <Col>
            <YellowFlag />
          </Col>
          <Col>
            <Text>{`${get(text, 'yellow', 0)}`}</Text>
          </Col>
        </Row>
      ),
      align: 'center',
      sorter: (a, b) =>
        get(a, `${ANALYTICS_WIDGETS_NAMES.RATING_FLAGS_COUNT_BY_OPERATORS}.yellow`, 0) -
        get(b, `${ANALYTICS_WIDGETS_NAMES.RATING_FLAGS_COUNT_BY_OPERATORS}.yellow`, 0)
    },

    {
      title: <GreenFlag />,
      key: `${ANALYTICS_WIDGETS_NAMES.RATING_FLAGS_COUNT_BY_OPERATORS}.green`,
      dataIndex: ANALYTICS_WIDGETS_NAMES.RATING_FLAGS_COUNT_BY_OPERATORS,
      width: 85,
      render: text => (
        <Row gutter={[4, 4]} type="flex" justify="center">
          <Col>
            <GreenFlag />
          </Col>
          <Col>
            <Text>{`${get(text, 'green', 0)}`}</Text>
          </Col>
        </Row>
      ),
      align: 'center',
      sorter: (a, b) =>
        get(a, `${ANALYTICS_WIDGETS_NAMES.RATING_FLAGS_COUNT_BY_OPERATORS}.green`, 0) -
        get(b, `${ANALYTICS_WIDGETS_NAMES.RATING_FLAGS_COUNT_BY_OPERATORS}.green`, 0)
    }
  ];
};

export default Columns;
