import { FlagFilled, MessageOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import { FLAGS_TO_COLORS_RELATIONS } from 'core/utils/constants';
import { get, isEmpty } from 'lodash';
import React from 'react';
import { utils } from 'react-media-player';
import { useSelector } from 'react-redux';
import shallowEqual from 'react-redux/lib/utils/shallowEqual';
import MessagesBoxes from './MessagesBoxex';
import {
  CommentLabelDivider,
  CommentLabelIcon,
  PlayerDivider,
  PlayerRow,
  PlayerTrack
} from './styled';

const { Text } = Typography;

const CommentLabel = ({ comment, duration, setTime, withMessageBoxes }) => {
  const timestamp = parseFloat(get(comment, 'metadata.timestamp', 0));
  const ratingFlag = get(comment, 'metadata.ratingFlag', 'gray');
  const relativeDuration = (timestamp / duration) * 100;
  const color = get(FLAGS_TO_COLORS_RELATIONS, ratingFlag, FLAGS_TO_COLORS_RELATIONS.without_flag);
  const Icon = CommentLabelIcon(color === 'gray' ? MessageOutlined : FlagFilled);
  return (
    <CommentLabelDivider
      onClick={() => setTime(timestamp)}
      left={`${relativeDuration}%`}
      backgroundColor={color}
      withMessageBoxes={withMessageBoxes}
    >
      <Icon color={color} />
    </CommentLabelDivider>
  );
};

const AudioTrack = ({ comments = [], setTime, messages, startedAt, onMessageClick }) => {
  const { duration, playedSeconds } = useSelector(state => state.uiRecordPlayer, shallowEqual);

  const setCursorPosition = event => {
    event.preventDefault();
    const domComponentRect = document.querySelector('.player-track').getBoundingClientRect();
    const onPlayerX = event.clientX - domComponentRect.left;
    const relativeDuration = onPlayerX / domComponentRect.width;
    setTime(duration * relativeDuration);
  };

  const pointerRelativePosition = (playedSeconds / duration) * 100;

  const withMessageBoxes = !isEmpty(messages);

  return (
    <PlayerRow type="flex" justify="space-around" align="middle">
      <Text>{utils.formatTime(playedSeconds)}</Text>
      <PlayerTrack
        xs={16}
        sm={18}
        md={19}
        lg={20}
        xl={21}
        xxl={22}
        id="row"
        onClick={setCursorPosition}
        withMessageBoxes={withMessageBoxes}
        className="player-track"
      >
        {withMessageBoxes && (
          <MessagesBoxes messages={messages} duration={duration} onMessageClick={onMessageClick} />
        )}
        <PlayerDivider withMessageBoxes={withMessageBoxes} left={`${pointerRelativePosition}%`} />
        {comments
          .filter(c => get(c, 'metadata.timestamp', false))
          .map(comment => (
            <CommentLabel
              key={comment.id}
              comment={comment}
              duration={duration}
              setTime={setTime}
              withMessageBoxes={withMessageBoxes}
            />
          ))}
      </PlayerTrack>
      <Text>{utils.formatTime(duration)}</Text>
    </PlayerRow>
  );
};

export default AudioTrack;
