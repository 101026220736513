import { AlertCircle } from 'react-feather';
import { Popover, Tooltip } from 'antd';
import React from 'react';
import Icon from './Icon';

export const Info = ({ tooltip = {}, popover = null, iconProps, disabled = false }) => {
  if (disabled) {
    return <Icon icon={AlertCircle} {...iconProps} opacity=".5" />;
  }

  if (popover) {
    return (
      <Popover {...popover}>
        <Icon icon={AlertCircle} {...iconProps} />
      </Popover>
    );
  }

  return (
    <Tooltip {...tooltip}>
      <Icon icon={AlertCircle} {...iconProps} />
    </Tooltip>
  );
};
